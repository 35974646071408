import { DialogFooter } from "../ui/dialog";
import { Button } from "../ui/button";
import { APIKeysSection } from "../ConfigureAPIKeysModal";
import { ArrowLeftIcon } from "@heroicons/react/outline";

export function FineTuneStepApiKeys({
  navigateBack,
}: {
  navigateBack: () => void;
}) {
  return (
    <>
      <APIKeysSection />
      <DialogFooter>
        <div className="flex justify-start">
          <Button variant="secondary" onClick={navigateBack}>
            <ArrowLeftIcon className="mr-2 h-4 w-4" />
            Back
          </Button>
        </div>
      </DialogFooter>
    </>
  );
}
