import { URL_PARAM_KEYS } from "@/utils/utils";
import { RefObject, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import SearchBarCollapsed from "./SearchBarCollapsed";
import SearchBarExpanded from "./SearchBarExpanded";
const useReset = (resetCondition: boolean) => {
  const setSearchParams = useSearchParams()[1];
  useEffect(() => {
    return () => {
      setSearchParams((prev) => {
        const params = new URLSearchParams(prev);
        params.delete(URL_PARAM_KEYS.METADATA);
        params.delete(URL_PARAM_KEYS.Q);
        return params;
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetCondition]); // Only reset on unmount when resetCondition changes
};
const SearchBar = ({
  inputRef,
  collapsed,
  requestsOn,
}: {
  inputRef: RefObject<HTMLInputElement>;
  collapsed?: boolean;
  requestsOn: boolean;
}) => {
  useReset(requestsOn);
  if (collapsed) {
    return <SearchBarCollapsed />;
  }
  return <SearchBarExpanded inputRef={inputRef} requestsOn={requestsOn} />;
};

export default SearchBar;
