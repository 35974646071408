import { URL_PARAM_KEYS } from "@/utils/utils";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const useSearchQuery = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const q = searchParams.get(URL_PARAM_KEYS.Q) ?? "";
  const [searchQuery, setSearchQuery] = useState<string>(q);

  useEffect(() => {
    setSearchQuery(q);
  }, [q]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setSearchParams(
        (previous) => {
          const params = new URLSearchParams(previous);
          if (searchQuery === "") {
            params.delete(URL_PARAM_KEYS.Q);
          } else {
            params.set(URL_PARAM_KEYS.Q, searchQuery);
          }

          return params;
        },
        {
          replace: true,
        },
      );
    }, 300);
    return () => clearTimeout(timeout);
  }, [searchQuery, setSearchParams]);

  return [searchQuery, setSearchQuery] as [
    string,
    React.Dispatch<React.SetStateAction<string>>,
  ];
};

export default useSearchQuery;
