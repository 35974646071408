import { useUser } from "@/context/user-context";
import { useEffect, useState } from "react";
import LoadingSpinner from "./LoadingSpinner";

export const UsageMetersAll = () => {
  const userContext = useUser();
  const usage = userContext.usage;

  if (!usage) {
    return (
      <div className="flex w-full flex-row justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  const { prompts, prompts_limit, requests, requests_limit } = usage;

  return (
    <>
      <div className="flex w-full flex-row gap-8">
        {
          <UsageCard
            creditType="Request"
            numerator={requests}
            denominator={requests_limit}
          />
        }
        {
          <UsageCard
            creditType="Prompt"
            numerator={prompts}
            denominator={prompts_limit}
          />
        }
      </div>
    </>
  );
};

const UsageCard = ({
  creditType,
  numerator,
  denominator,
}: {
  creditType: string;
  numerator: number | null;
  denominator: number | null;
}) => {
  const [titleTerm, setTitleTerm] = useState<string>("");
  const [counterTerm, setCounterTerm] = useState<string>("");
  const adjustedNumerator = numerator ?? 0;
  useEffect(() => {
    if (creditType === "Prompt") {
      setTitleTerm("Prompt retrieval usage");
      setCounterTerm("retrievals");
    } else if (creditType === "Request") {
      setTitleTerm("Request usage");
      setCounterTerm("requests");
    } else {
      throw new Error("Invalid credit type");
    }
  }, [creditType]);

  return (
    <div className="mb-4 mt-4 flex w-full flex-col gap-5 rounded-lg border border-gray-300 p-6">
      <div className="flex flex-col gap-1">
        <div className="text-sm text-gray-900">{titleTerm}</div>
        <div className="flex flex-row text-xl font-bold">
          {adjustedNumerator}
          {denominator != null && ` of ${denominator} ${counterTerm}`}
          {denominator == null && ` ${counterTerm}`}
        </div>
      </div>
      {denominator != null && (
        <ProgressBar numerator={numerator} denominator={denominator} />
      )}
    </div>
  );
};

const ProgressBar = ({
  numerator,
  denominator,
}: {
  numerator: number | null;
  denominator: number;
}) => {
  const adjustedNumerator = numerator ?? 0;
  return (
    <div className="h-2.5 w-full rounded-full bg-gray-200">
      <div
        className="h-2.5 rounded-full bg-[#3C82F6]"
        style={{ width: `${(adjustedNumerator / denominator) * 100}%` }}
      ></div>
    </div>
  );
};
