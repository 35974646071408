import { FC } from "react";

interface UserAvatarProps {
  email: string;
}

const UserAvatar: FC<UserAvatarProps> = ({ email }) => {
  const initial = email[0].toUpperCase();

  const getColorFromEmail = (): string => {
    let hash = 0;
    for (let i = 0; i < email.length; i++) {
      hash = email.charCodeAt(i) + ((hash << 5) - hash);
    }
    const hue = hash % 360;
    return `hsl(${hue}, 70%, 70%)`;
  };

  return (
    <div
      className="flex h-8 w-8 items-center justify-center rounded-full text-sm font-semibold text-white"
      style={{ backgroundColor: getColorFromEmail() }}
    >
      {initial}
    </div>
  );
};

export default UserAvatar;
