import React from "react";
import { Outlet } from "react-router-dom";
import { Flip, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { MeticulousRecorder } from "@/components/MeticulousRecorder";
import SidebarSearchLayout from "@/components/SidebarSearchLayout";
import { UserProvider } from "@/context/user-context";
import useActiveWorkspace from "@/hooks/useActiveWorkspace";
import LoadingSpinner from "./LoadingSpinner";

const SidebarSearchLayoutWrapper = () => {
  const workspace = useActiveWorkspace();

  if (workspace === null) {
    return (
      <div className="flex h-screen items-center justify-center">
        <LoadingSpinner size={5} />
      </div>
    );
  }

  return (
    <UserProvider>
      <MeticulousRecorder>
        <SidebarSearchLayout>
          <Outlet />
          <ToastContainer
            autoClose={3000}
            closeOnClick
            draggable
            hideProgressBar
            newestOnTop
            pauseOnFocusLoss
            pauseOnHover
            position="top-right"
            rtl={false}
            transition={Flip}
          />
        </SidebarSearchLayout>
      </MeticulousRecorder>
    </UserProvider>
  );
};

export default SidebarSearchLayoutWrapper;
