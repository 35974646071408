import { ArrowRightLeftIcon } from "lucide-react";

const JSONIndicator = ({
  isJSON,
  onClick,
  disabled,
}: {
  isJSON: boolean;
  onClick: () => void;
  disabled: boolean;
}) => {
  return (
    <button
      onClick={onClick}
      contentEditable={false}
      disabled={disabled}
      className="w-13 absolute right-1 top-0 z-[1] m-2 hidden cursor-pointer select-none items-center justify-center rounded-full bg-gray-200 px-2 py-1 text-[10px] text-gray-400 hover:bg-gray-100 disabled:opacity-50  group-hover/Cell:inline-flex"
    >
      <ArrowRightLeftIcon size={12} className="mr-1" />
      {isJSON ? "JSON" : "TEXT"}
    </button>
  );
};

export default JSONIndicator;
