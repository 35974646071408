import { FC } from "react";
import gorgiasLogo from "@/assets/company_logos/gorgias.svg";
import speakLogo from "@/assets/company_logos/speak.svg";
import blockLogo from "@/assets/company_logos/block-long.png";
import midpageLogo from "@/assets/company_logos/midpage.png";
import serhantLogo from "@/assets/company_logos/serhant.png";
import toplineProLogo from "@/assets/company_logos/toplinepro.png";
import jiminiLogo from "@/assets/company_logos/jimini.svg";
import icon from "@/assets/icon-1.svg";
import * as S from "./Styles";

const Companies: FC = () => {
  const renderMain = () => {
    return (
      <S.CompanyMain>
        <S.Row>
          <S.Column>
            <S.CompanyItem>
              <img alt="Prompt Versioning Icon" src={icon} />
              <h4>Prompt versioning</h4>
              <p>
                Visually edit, A/B test, and deploy prompts. Compare usage and
                latency. Avoid waiting for eng redeploys.
              </p>
            </S.CompanyItem>
          </S.Column>
          <S.Column>
            <S.CompanyItem>
              <img alt="Experts Icon" src={icon} />
              <h4>Collaboration with experts</h4>
              <p>
                Open up prompt iteration to non-technical stakeholders. Read
                logs, find edge-cases, and improve prompts.
              </p>
            </S.CompanyItem>
          </S.Column>
          <S.Column>
            <S.CompanyItem>
              <img alt="Evaluations Icon" src={icon} />
              <h4>Evaluation</h4>
              <p>
                Test prompts against usage history. Compare models. Schedule
                regression tests. Build one-off batch runs.
              </p>
            </S.CompanyItem>
          </S.Column>
        </S.Row>
        <h2>
          Prompt engineer with the whole team. Manage prompts visually in the
          Prompt Registry.
        </h2>
      </S.CompanyMain>
    );
  };

  const renderUpper = () => {
    return (
      <S.CompanyUpper>
        <h2>Trusted by companies like you...</h2>
        <S.CompanyLogos>
          <S.CompanyLogo href="https://gorgias.com/">
            <S.CompanyLogoImg alt="Gorgias logo" src={gorgiasLogo} />
          </S.CompanyLogo>
          <S.CompanyLogo href="https://speak.com/">
            <S.CompanyLogoImg $dim alt="Speak logo" src={speakLogo} />
          </S.CompanyLogo>
          <S.CompanyLogo href="https://blockrenovation.com/">
            <S.CompanyLogoImg
              $dim
              alt="Block Renovation logo"
              src={blockLogo}
              style={{ padding: "0 4px" }}
            />
          </S.CompanyLogo>
          <S.CompanyLogo href="https://midpage.ai/">
            <S.CompanyLogoImg $dim alt="Midpage logo" src={midpageLogo} />
          </S.CompanyLogo>
          <S.CompanyLogo href="https://www.toplinepro.com/">
            <S.CompanyLogoImg
              alt="Topline Pro logo"
              src={toplineProLogo}
              style={{ maxWidth: "90%" }}
            />
          </S.CompanyLogo>
          <S.CompanyLogo href="https://serhant.com/">
            <S.CompanyLogoImg $dim alt="Serhant logo" src={serhantLogo} />
          </S.CompanyLogo>
          <S.CompanyLogo href="https://jiminihealth.com/">
            <S.CompanyLogoImg
              $dim
              alt="Jimini logo"
              src={jiminiLogo}
              style={{ padding: "0 10px" }}
            />
          </S.CompanyLogo>
        </S.CompanyLogos>
      </S.CompanyUpper>
    );
  };

  return (
    <S.Container>
      <S.InnerContainer>
        {renderUpper()}
        {renderMain()}
      </S.InnerContainer>
    </S.Container>
  );
};

export default Companies;
