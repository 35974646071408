import InvalidJsonError from "@/components/InvalidJsonError";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { LOCAL_STORAGE } from "@/constants";
import { InputVariableRow } from "@/types/playground";
import { canParseJson } from "@/utils/jsonParsing";
import { PlusIcon, TrashIcon, XIcon } from "@heroicons/react/outline";
import { TooltipArrow } from "@radix-ui/react-tooltip";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTrigger,
} from "components/ui/dialog";
import { BracesIcon } from "lucide-react";
import { FC, useEffect, useMemo, useState } from "react";

interface InputVariableDialogProps {
  inputVariableRows: InputVariableRow[];
  setInputVariableRows: (rows: InputVariableRow[]) => void;
  variables: string[];
}

const InputVariableDialog: FC<InputVariableDialogProps> = ({
  inputVariableRows,
  setInputVariableRows,
  variables,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [rows, setRows] = useState<InputVariableRow[]>(inputVariableRows);

  useEffect(() => {
    setRows(inputVariableRows);
  }, [inputVariableRows]);

  useEffect(() => {
    const storedRows = localStorage.getItem(LOCAL_STORAGE.INPUT_VARIABLE_ROWS);
    setRows(storedRows ? JSON.parse(storedRows) : []);
  }, [open]);

  const variablesWithoutRows = useMemo((): string[] => {
    const keys = rows.map(({ key }) => key);
    return variables.filter((variable) => !keys.includes(variable));
  }, [rows, variables]);

  const handleAddNewRow = (key?: string) => {
    setRows([...rows, { isJson: false, key: key || "", value: "" }]);
  };

  const handleDelete = (index: number) => {
    const newRows = [...rows];
    newRows.splice(index, 1);
    setRows(newRows);
  };

  const handleInputChange = (index: number, field: string, value: string) => {
    const newRows = [...rows];
    newRows[index] = { ...newRows[index], [field]: value };
    setRows(newRows);
  };

  const handleSubmit = () => {
    setInputVariableRows(rows);
    localStorage.setItem(
      LOCAL_STORAGE.INPUT_VARIABLE_ROWS,
      JSON.stringify(rows),
    );
    setOpen(false);
  };

  const handleToggleTextarea = (index: number) => {
    const newRows = [...rows];
    newRows[index].isJson = !newRows[index].isJson;
    setRows(newRows);
  };

  const isJsonParsable = useMemo(() => {
    const jsonRows = rows.filter(({ isJson }) => isJson);
    return jsonRows.every((row) => canParseJson(row.value));
  }, [rows]);

  const handleClearAll = () => {
    setRows([]);
  };
  const renderDialogFooter = () => {
    return (
      <div className="flex w-full items-center justify-between gap-4">
        {renderVariablesWithoutRows()}
        <div className="flex w-full items-center justify-between">
          <Button
            className="gap-x-1"
            onClick={handleClearAll}
            variant="destructiveOutline"
          >
            <XIcon className="h-4 w-4" />
            Clear All
          </Button>
          <div className="flex items-center gap-x-1">
            <Button onClick={() => handleAddNewRow()} variant="outline">
              <PlusIcon className="h-5 w-5" />
              <div className="ml-1">Add Input Variable</div>
            </Button>
            <Button
              disabled={!isJsonParsable}
              onClick={handleSubmit}
              variant="default"
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const renderTableRows = () => {
    return rows.map((row, index) => (
      <TableRow key={index}>
        <TableCell className="pl-2">
          <input
            className="border-none pl-2 text-sm text-gray-600"
            onChange={(e) => handleInputChange(index, "key", e.target.value)}
            placeholder="Enter key"
            type="text"
            value={row.key}
          />
        </TableCell>
        <TableCell className="pl-2">
          {row.isJson ? (
            <>
              <textarea
                className="h-[150px] w-full border-none pl-2 text-sm text-gray-600"
                onChange={(e) =>
                  handleInputChange(index, "value", e.target.value)
                }
                placeholder="Enter value"
                value={row.value}
              />
              {canParseJson(row.value) ? null : <InvalidJsonError />}
            </>
          ) : (
            <input
              className="w-full border-none pl-2 text-sm text-gray-600"
              onChange={(e) =>
                handleInputChange(index, "value", e.target.value)
              }
              placeholder="Enter value"
              type="text"
              value={row.value}
            />
          )}
        </TableCell>
        <TableCell className="flex gap-x-2">
          <TooltipProvider>
            <Tooltip delayDuration={0}>
              <TooltipTrigger asChild>
                <Button
                  onClick={() => handleToggleTextarea(index)}
                  size="icon"
                  variant="outline"
                >
                  <BracesIcon className="h-4 w-4" />
                </Button>
              </TooltipTrigger>
              <TooltipContent
                className="max-w-sm rounded bg-gray-800 p-2 text-sm text-white"
                side="top"
                sideOffset={10}
              >
                {row.isJson ? "Use as plain text" : "Use as JSON"}
                <TooltipArrow className="fill-current text-gray-800" />
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <Button
            onClick={() => handleDelete(index)}
            size="icon"
            variant="outline"
          >
            <TrashIcon className="h-4 w-4" />
          </Button>
        </TableCell>
      </TableRow>
    ));
  };

  const renderVariablesWithoutRows = () => {
    return (
      <div className="flex space-x-2 overflow-x-auto text-gray-500">
        {variablesWithoutRows.map((variable, index) => (
          <div
            className="flex cursor-pointer items-center rounded-full border border-gray-300 px-2 py-1 text-sm text-gray-800 hover:bg-accent hover:text-accent-foreground hover:text-gray-600"
            key={index}
            onClick={() => handleAddNewRow(variable)}
          >
            <PlusIcon className="h-3 w-3" />
            <div className="ml-1">{variable}</div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <Dialog onOpenChange={setOpen} open={open}>
        <DialogTrigger asChild>
          <Button variant="ghost">
            <BracesIcon className="mr-1 h-4 w-auto" />
            Input Variables {!!rows.length ? `(${rows.length})` : ""}
          </Button>
        </DialogTrigger>
        <DialogContent className="flex max-h-[800px] min-h-[100px] max-w-2xl flex-col">
          <DialogHeader>
            <Label className="text-xl font-bold">Input Variables</Label>
          </DialogHeader>
          <Table>
            <TableHeader className="sticky top-0 z-10 bg-white">
              <TableRow>
                <TableHead className="w-1/3 px-4 font-bold">Key</TableHead>
                <TableHead className="w-2/3 px-4 font-bold">Value</TableHead>
                <TableHead className="w-1/10"></TableHead>
              </TableRow>
            </TableHeader>
            <TableBody className="font-mono text-gray-400">
              {renderTableRows()}
            </TableBody>
          </Table>
          {renderDialogFooter()}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default InputVariableDialog;
