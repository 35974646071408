import { Switch as HeadlessSwitch, SwitchProps } from "@headlessui/react";

interface LockableSwitchProps extends SwitchProps<"input"> {
  lockedState?: boolean | null;
}

export const Switch = ({ lockedState, ...props }: LockableSwitchProps) => {
  const isLocked = lockedState !== null && lockedState !== undefined;
  const isChecked = isLocked ? lockedState : props.checked;

  return (
    <HeadlessSwitch
      {...props}
      checked={isChecked}
      disabled={isLocked}
      className={`${isChecked ? "bg-blue-500" : "bg-blue-600"}
          relative inline-flex h-6 w-12 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75 ${
            isLocked ? "bg-gray-500" : ""
          }`}
    >
      <span
        aria-hidden="true"
        className={`${isChecked ? "translate-x-6" : "translate-x-0"}
            pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
      />
    </HeadlessSwitch>
  );
};
