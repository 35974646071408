import { ReactNode } from "react";

import NotFoundMessage from "@/components/ui/not-found";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { useWorkspaces } from "@/queries";

const Content = ({ children }: { children: ReactNode }) => {
  const authContext = useAuth();
  const userContext = useUser();

  const { data: workspaces, isLoading } = useWorkspaces(
    authContext?.userToken!,
  );

  const accessibleWorkspaceIds =
    workspaces?.workspaces.map((workspace: any) => workspace.id) ?? [];

  const isWorkspaceAccessible =
    userContext.activeWorkspaceId &&
    accessibleWorkspaceIds.includes(userContext.activeWorkspaceId);

  if (!isLoading && !isWorkspaceAccessible)
    return (
      <NotFoundMessage
        title="Workspace not found"
        subtitle="The Workspace you are looking for does not exist or you do not have access to it."
        backLinkMessage="Go Home"
        backLink="/"
      />
    );

  return <div className="h-full px-4 sm:px-6 md:px-0">{children}</div>;
};

export default Content;
