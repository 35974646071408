export const getDuration = (start: string, end: string): string => {
  const startTime = new Date(start).getTime();
  const endTime = new Date(end).getTime();
  const durationMs = endTime - startTime;

  if (durationMs < 1000) {
    return `${durationMs}ms`;
  } else if (durationMs < 60000) {
    return `${Math.floor(durationMs / 1000)}s`;
  } else {
    const minutes = Math.floor(durationMs / 60000);
    return `${minutes}m`;
  }
};
