import { Dispatch, SetStateAction, useState } from "react";

export const usePagination = () => {
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  return [page, setPage, pageSize, setPageSize] as [
    number,
    Dispatch<SetStateAction<number>>,
    number,
    Dispatch<SetStateAction<number>>,
  ];
};
