import { FC } from "react";
import { Container, InnerContainer, Text } from "./Styles";
import speakLogo from "@/assets/company_logos/navy_logos/speak.svg";
import parentlabLogo from "@/assets/company_logos/navy_logos/parentlab.svg";
import gorgiasLogo from "@/assets/company_logos/navy_logos/gorgias.svg";
import ellipsisLogo from "@/assets/company_logos/navy_logos/ellipsis_cropped.png";
import granolaLogo from "@/assets/company_logos/navy_logos/granola.svg";
import openaiLogo from "@/assets/company_logos/navy_logos/openai.svg";
import twilioLogo from "@/assets/company_logos/navy_logos/twilio.svg";
import geminiLogo from "@/assets/company_logos/navy_logos/gemini.svg";
import harveyLogo from "@/assets/company_logos/navy_logos/harvey.svg";
import jiminiLogo from "@/assets/company_logos/navy_logos/jimini.svg";
import seungJae from "@/assets/landing_quotes/seungjae.jpg";
import johnGilmore from "@/assets/landing_quotes/johngilmore.jpg";
import victorDuprez from "@/assets/landing_quotes/victorduprez.jpg";
import loganKilpatrick from "@/assets/landing_quotes/logankilpatrick.jpg";
import gregBaugues from "@/assets/landing_quotes/gregBauges.jpg";
import nickBradford from "@/assets/landing_quotes/nickbradford.jpg";
import chrisPedregal from "@/assets/landing_quotes/chrispedregal.jpg";
import amanKishore from "@/assets/landing_quotes/amankishore.jpg";
import nadavRakocz from "@/assets/landing_quotes/nadavrakocz.jpg";

const quotes = [
  {
    quote: `Using PromptLayer, I completed many months' worth of work in a single week. It empowered me to drastically scale our content creation process, going from curriculum outlines to app-ready content that users could engage with immediately.`,
    author: "Seung Jae Cha",
    company: "Product Lead at Speak",
    logo: speakLogo,
    authorLogo: seungJae,
  },
  {
    quote: `PromptLayer has been a game-changer for us. It has allowed our content team to rapidly iterate on prompts, find the right tone, and address edge cases, all without burdening our engineers. This has been critical for creating an AI that truly connects with and supports our users.`,
    author: "John Gilmore",
    company: "VP of Operations at ParentLab",
    logo: parentlabLogo,
    authorLogo: johnGilmore,
  },
  {
    quote: `We iterate on prompts 10s of times every single day. It would be impossible to do this in a SAFE way without PromptLayer.`,
    author: "Victor Duprez",
    company: "Director of Engineering at Gorgias",
    logo: gorgiasLogo,
    authorLogo: victorDuprez,
  },
  {
    quote: `PromptLayer is an example of a company that is solving a different lens of the problem, managing all your different prompts as they come in. The thing that I'm most excited about today is evals… I think it's a fundamental challenge for people using LLMs. When a new model comes out whether it's from OpenAI or some other provider, I don't know as the end user of that model how it's going to impact my use case. And, really, the only way to do that is to have a bunch of robust evals that you go and build…`,
    author: "Logan Kilpatrick",
    company: "Gemini and OpenAI",
    logo: geminiLogo,
    secondLogo: openaiLogo,
    authorLogo: loganKilpatrick,
  },
  {
    quote: `Getting started with LLM APIs is easy. Moving to production and scale is hard. PromptLayer gives me out-of-the-box tooling to iterate, evaluate, monitor, and multisource my LLM-based apps, so I can spend less time building infrastructure. And just like how Wordpress allowed anyone to publish on the web, PromptLayer empowers non-developers and subject matter experts to iterate and improve on prompts without touching the code.`,
    author: "Greg Baugues",
    company: "Former Director of Developer Relations at Twilio",
    logo: twilioLogo,
    authorLogo: gregBaugues,
  },
  {
    quote: `PromptLayer has become indispensable to our iteration process. Using the Prompt Registry, our team of mental health experts create tests, evaluate responses, and directly make edits to prompts without any engineering support. Even though our team is mostly non-technical, they use PromptLayer to improve the AI based on their personal clinical experience.`,
    author: "Nadav Rakocz",
    company: "AI Researcher at Jimini Health",
    logo: jiminiLogo,
    authorLogo: nadavRakocz,
  },
  {
    quote: `The team at PromptLayer has built a seriously impressive platform for prompt engineering. Their prompt CMS does a great job of allowing non-technical stakeholders to actually become the prompt engineers. It's the key that brings analytics, observability, and evals together for easy iteration.`,
    author: "Aman Kishore",
    company: "Founder at MirageML (aqd Harvey)",
    logo: harveyLogo,
    authorLogo: amanKishore,
  },
  {
    quote: `If I'm at my desk and see that somebody's workflow went bad, it takes only 3 or 4 clicks. I go to PromptLayer, filter by the workflow ID, and I'm in. The information density means my time to being productive is really really good.`,
    author: "Nick Bradford",
    company: "Founder & CTO at Ellipsis",
    logo: ellipsisLogo,
    authorLogo: nickBradford,
  },
  {
    quote: `It takes a lot of work to build a good AI notepad like Granola. Especially because there is no ground-truth to compare against. PromptLayer makes it easy to version and build custom evals for your prompts.`,
    author: "Chris Pedregal",
    company: "CEO at Granola",
    logo: granolaLogo,
    authorLogo: chrisPedregal,
  },
];

interface QuoteCardProps {
  quote: string;
  author: string;
  company: string;
  logo: string;
  secondLogo?: any;
  authorLogo?: any;
}

const QuoteCard: FC<QuoteCardProps> = ({
  quote,
  author,
  company,
  logo,
  authorLogo,
  secondLogo,
}) => {
  return (
    <div className="group flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white px-4 pb-4 pt-10 transition-shadow duration-300 hover:border-gray-300 hover:shadow-md">
      <div className="mb-6 flex h-[30px] items-center gap-x-4 bg-white">
        {secondLogo && (
          <div className="mt-3 flex h-full items-center border-r border-gray-200 pr-4">
            <img
              src={secondLogo}
              alt={`${company} logo`}
              className="h-full object-contain"
            />
          </div>
        )}
        <div className="flex h-full items-center">
          <img
            src={logo}
            alt={`${company} logo`}
            className="h-full object-contain"
          />
        </div>
      </div>
      <div className="flex flex-grow flex-col justify-between">
        <p className="pb-2 text-base font-light leading-relaxed text-gray-900">
          {quote}
        </p>
        <div className="mt-auto flex items-center gap-3">
          <div className="flex h-10 w-10 items-center justify-center overflow-hidden rounded-full">
            {authorLogo ? (
              <img
                src={authorLogo}
                alt={`${author} logo`}
                className="h-full w-full object-cover grayscale group-hover:grayscale-0"
              />
            ) : (
              <div className="flex h-full w-full items-center justify-center bg-gray-900 text-white">
                {author.charAt(0)}
              </div>
            )}
          </div>
          <div>
            <p className="m-0 text-sm font-normal text-gray-900">{author}</p>
            <p className="m-0 text-xs font-light text-gray-500">{company}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const Quotes: FC = () => {
  return (
    <Container>
      <InnerContainer>
        <Text>
          <h2>What users are saying</h2>
        </Text>
        <div className="mx-2 mt-10 grid grid-cols-1 gap-4 md:grid-cols-3">
          {quotes.map((quote, index) => (
            <QuoteCard key={index} {...quote} />
          ))}
        </div>
      </InnerContainer>
    </Container>
  );
};

export default Quotes;
