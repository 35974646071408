import evaluation from "@/assets/hero/evaluation.png";
import backtest from "@/assets/platform_details/backtest.png";
import { PlatformGenericLayout } from "@/components/MarketingLayout/Platform/PlatformGenericLayout/PlatformGenericLayout";
import {
  PlayIcon,
  ClipboardCheckIcon,
  ViewBoardsIcon,
  TableIcon,
  LinkIcon,
  LightningBoltIcon,
} from "@heroicons/react/outline";
import * as S from "./Styles";

const features = [
  {
    icon: PlayIcon,
    title: "Automatic Triggering",
    description:
      "Automatically trigger evaluations on each new prompt version, via the API, or ad-hoc on the UI.",
  },
  {
    icon: LinkIcon,
    title: "Simple Backtests",
    description:
      "Connect evaluation pipelines to production history to run historical backtests.",
  },
  {
    icon: ViewBoardsIcon,
    title: "Model Comparison",
    description:
      "Compare and contrast different models in a side-by-side view, easily identifying the best performer.",
  },
  {
    icon: TableIcon,
    title: "Flexible Evaluation Columns",
    description:
      "Choose from over 20 column types, from basic comparisons to LLM assertions and custom webhooks.",
  },
  {
    icon: ClipboardCheckIcon,
    title: "Comprehensive Scorecards",
    description:
      "Create score cards with multiple metrics to fit your evaluation needs.",
  },
  {
    icon: LightningBoltIcon,
    title: "Easy yet Powerful",
    description:
      "Simple to start, flexible for any use case or team skill level.",
  },
];

const heroInfo = {
  title: (
    <>
      <span>Flexible Evaluations</span>
      <br /> Assess your results
    </>
  ),
  subtitle: (
    <>
      Create an evaluation to understand model performance and improve it.
      <br />
      Built for the novice and expert alike. Complex LLM evaluations made
      simple.
    </>
  ),
  image: evaluation,
  promoImage: backtest,
};

const promoFeatures = [
  {
    title: "Maximum Coverage",
    description:
      "Whether you want to test for hallucinations or classifcation, our evaluation system can handle it.",
  },
  {
    title: "Extreme Flexibility",
    description:
      "We provide both out of the box evaluations and tools to create your own.",
  },
  {
    title: "Easy to Understand",
    description:
      "Our evaluation system is built to satisy both ML experts and non-techical users.",
  },
  {
    title: "Seamless Integration",
    description:
      "Connect your evaluations to your prompts and datasets to set up an easy CI/CD process. Think Github Actions.",
  },
];
function Evaluations() {
  return (
    <S.Container>
      <PlatformGenericLayout
        heroInfo={heroInfo}
        featureTitle={"Use-Case Driven Evaluations"}
        features={features}
        promoTitle={"Increase your LLM"}
        promoHighlight={"application performance"}
        promoDescription={
          "Create evaluations to understand how your models are performing. Judge both qualitative and quantitative aspects of performance. Our evaluation system is designed to be flexible for any use case or team skill level."
        }
        promoFeatures={promoFeatures}
      />
    </S.Container>
  );
}

export default Evaluations;
