import { ColumnType, ReportColumn } from "@/types/evaluate";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

export const ColumnStateContext = createContext<any>({
  isColumnCollapsed: (columnName: string) => false,
  toggleColumn: () => {},
});

const useInitialColumnState = (columns: ReportColumn[]) => {
  const initialColumnState = useMemo(
    () =>
      columns.reduce(
        (acc: { [key: string]: boolean }, colum: ReportColumn, i) => {
          if (colum.id)
            acc[colum.id] =
              colum.column_type === ColumnType.DATASET &&
              columns.length > 5 &&
              i < columns.length - 5;
          return acc;
        },
        {},
      ),
    [columns],
  );
  if (columns.length === 0) return {};

  return initialColumnState;
};

export const useCollapsibleColumnContext = () => {
  return useContext(ColumnStateContext);
};

const useCollapsibleColumns = (columns: ReportColumn[], loading = false) => {
  const initialColumnStates = useInitialColumnState(columns);
  const [columnStates, setColumnStates] = useState<{ [key: string]: boolean }>(
    initialColumnStates,
  );

  const prevInitialColumnStates = useRef(initialColumnStates);

  useEffect(() => {
    if (
      Object.keys(initialColumnStates).length > 0 &&
      prevInitialColumnStates.current !== initialColumnStates
    )
      setColumnStates(initialColumnStates);
  }, [initialColumnStates]);

  const isColumnCollapsed = useCallback(
    (column: string) => columnStates[column] === true,
    [columnStates],
  );

  const toggleColumn = useCallback((column: string) => {
    setColumnStates((prev) => ({
      ...prev,
      [column]: !prev[column],
    }));
  }, []);

  const ColumnStateProvider = useCallback(
    ({ children }: { children: React.ReactNode }) => {
      return (
        <ColumnStateContext.Provider
          value={{
            isColumnCollapsed,
            toggleColumn,
            columnStates,
          }}
        >
          {children}
        </ColumnStateContext.Provider>
      );
    },
    [columnStates, isColumnCollapsed, toggleColumn],
  );

  const totalHiddenColumns = useMemo(() => {
    return columns.reduce((acc: number, column: ReportColumn, i) => {
      if (column?.id && columnStates[column.id] === true) {
        acc++;
      }
      return acc;
    }, 0);
  }, [columns, columnStates]);

  const showAllColumns = useCallback(() => {
    setColumnStates({});
  }, [setColumnStates]);

  return {
    ColumnStateProvider,
    isColumnCollapsed,
    toggleColumn,
    totalHiddenColumns,
    showAllColumns,
  };
};

export default useCollapsibleColumns;
