import React from "react";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";

const InvalidJsonError = () => {
  return (
    <div className="flex items-center space-x-1 text-xs text-red-500">
      <ExclamationTriangleIcon className="h-4 w-4" />
      <span>JSON is invalid</span>
    </div>
  );
};

export default InvalidJsonError;
