import { PlatformGenericLayout } from "@/components/MarketingLayout/Platform/PlatformGenericLayout/PlatformGenericLayout";
import * as S from "./Styles";
import registry from "@/assets/hero/prompt_management.png";
import newVersionImage from "@/assets/platform_details/new_version.png";

import {
  ClockIcon,
  CubeTransparentIcon,
  CubeIcon,
  ChartBarIcon,
  ChatIcon,
  TagIcon,
  SwitchHorizontalIcon,
  BeakerIcon,
  TemplateIcon,
} from "@heroicons/react/outline";

const features = [
  {
    icon: ClockIcon,
    title: "Version Control",
    description:
      "Version your prompts and easily compare differences between versions.",
  },
  {
    icon: CubeTransparentIcon,
    title: "Model-Agnostic Blueprints",
    description:
      "Create model-agnostic prompt blueprints that adapt to any LLM model.",
  },
  {
    icon: CubeIcon,
    title: "Interactive Function Builder",
    description:
      "Build functions interactively without the need for complex JSON Schema.",
  },
  {
    icon: ChartBarIcon,
    title: "Usage Analytics",
    description:
      "Track cost, latency, usage and feedback for each prompt version to optimize performance.",
  },
  {
    icon: ChatIcon,
    title: "Collaborative Features",
    description:
      "Use commit messages and comments to collaborate effectively with your team.",
  },
  {
    icon: TagIcon,
    title: "Release Labels",
    description:
      "Manage environments like production and development with labeled prompt versions.",
  },
  {
    icon: SwitchHorizontalIcon,
    title: "A/B Testing",
    description:
      "Conduct A/B tests based on user segments to optimize prompt performance.",
  },
  {
    icon: BeakerIcon,
    title: "Automated Testing",
    description:
      "Run automatic regression tests or specific evaluation pipelines after creating a new version.",
  },
  {
    icon: TemplateIcon,
    title: "Flexible Templating",
    description:
      "Use Jinja2 or f-string syntax to create templates and import snippets.",
  },
];
const heroInfo = {
  title: (
    <>
      <span>Collaborative Prompting</span>
      <br /> Manage your prompts
    </>
  ),
  subtitle: (
    <>
      A collaborative model–agnostic prompt management platform.
      <br />
      Version prompts, tools, and model parameters in our CMS.
    </>
  ),
  image: registry,
  promoImage: newVersionImage,
};

const promoFeatures = [
  {
    title: "Visualize your Prompts",
    description:
      "Prompts describe the business logic of your LLM applications, they should not be hidden in code.",
  },
  {
    title: "Let everyone Contribute",
    description:
      "Key stakeholders do not need to be developers, empower them to contribute and lead your prompts.",
  },
  {
    title: "Decouple your Workflow",
    description:
      "Separate your prompt logic from your codebase, allowing for faster iteration and more flexibility.",
  },
  {
    title: "Automate your Testing",
    description:
      "Ship confidently with automated testing and evaluation pipelines on your prompt template.",
  },
];

function PromptManagement() {
  return (
    <S.Container>
      <PlatformGenericLayout
        heroInfo={heroInfo}
        featureTitle={"Prompt Management that Empowers"}
        features={features}
        promoTitle={"Enable Everyone to"}
        promoHighlight={"Iterate Faster"}
        promoDescription={
          "Using a prompt management system like PromptLayer enables both technical and non-technical stakeholders to collaborate. Our Prompt Registry is a CMS for your business logic."
        }
        promoFeatures={promoFeatures}
      />
    </S.Container>
  );
}

export default PromptManagement;
