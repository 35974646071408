import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { MathOperatorConfiguration, ReportColumn } from "@/types/evaluate";
import { formatInputVariable } from "@/utils/evaluate";
import { useState } from "react";
import { ModalStep } from "../ModalRouter";

const MathOperatorBuilder = ({
  columnData,
  availableColumns,
  navigatePrevious,
  saveColumnAction,
  editable,
}: {
  columnData: Partial<ReportColumn>;
  availableColumns: ReportColumn[];
  navigatePrevious: () => void;
  saveColumnAction: (newColumnDataArg: ReportColumn) => void;
  editable: boolean;
}) => {
  const [name, setName] = useState<string | null>(columnData.name || null);
  const [operator, setOperator] = useState<string | null>(
    columnData.configuration?.operator ?? null,
  );
  const [column, setColumn] = useState<string | null>(
    columnData.configuration?.column || null,
  );
  const [value, setValue] = useState<number | null>(
    columnData.configuration?.value ?? null,
  );

  const [dataIsValid, setDataIsValid] = useState<{
    name: boolean;
    operator: boolean;
    column: boolean;
    value: boolean;
  }>({
    name: true,
    operator: true,
    column: true,
    value: true,
  });

  const saveEndpointAction = () => {
    if (!name) {
      setDataIsValid({
        name: false,
        operator: true,
        column: true,
        value: true,
      });
      return;
    } else if (!operator) {
      setDataIsValid({
        name: true,
        operator: false,
        column: true,
        value: true,
      });
      return;
    } else if (!column) {
      setDataIsValid({
        name: true,
        operator: true,
        column: false,
        value: true,
      });
      return;
    } else if (!value) {
      setDataIsValid({
        name: true,
        operator: true,
        column: true,
        value: false,
      });
      return;
    }

    setDataIsValid({ name: true, operator: true, column: true, value: true });

    saveColumnAction({
      ...columnData,
      name: name,
      configuration: {
        operator,
        column,
        value,
      } as MathOperatorConfiguration,
    } as ReportColumn);
  };

  return (
    <ModalStep
      navigatePrevious={navigatePrevious}
      navigateNext={saveEndpointAction}
      nextButtonText={editable ? "Save Step" : "Done"}
    >
      <div className="grid grid-cols-3 items-center gap-4">
        <div className="col-span-3">
          <div className="text-lg font-semibold">
            Configure Comparison Operator
          </div>
          <div className="max-w-md text-sm text-gray-500">
            This step will apply a comparison operator to a column in your
            report
          </div>
        </div>
        <label htmlFor="name" className="col-span-1">
          Column name:
        </label>
        <input
          id="name"
          className={`col-span-2 rounded border ${
            !dataIsValid.name ? "border-red-500" : "border-gray-300"
          } px-2 py-1 disabled:cursor-not-allowed disabled:bg-gray-50`}
          value={name || ""}
          onChange={(e) => setName(e.target.value)}
          disabled={!editable}
        />
        {!dataIsValid.name && (
          <>
            <div className="col-span-1 -mt-4">&nbsp;</div>
            <div className="col-span-2 -mt-4 text-left text-xs text-red-500">
              Name is required
            </div>
          </>
        )}
        <div className="col-span-3 grid grid-cols-3 items-center gap-2">
          <div className="flex flex-col items-center">
            <DropdownMenu>
              <DropdownMenuTrigger disabled={!editable} className="w-full">
                {column || (
                  <span className="font-normal text-gray-500">Column...</span>
                )}
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                {availableColumns.map((column) => (
                  <DropdownMenuItem
                    key={column.name}
                    onSelect={() => setColumn(column.name)}
                  >
                    {formatInputVariable(column.column_type, column.name)}
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
            {!dataIsValid.column && (
              <div className="text-left text-xs text-red-500">
                Column is required
              </div>
            )}
          </div>
          <div className="flex flex-col items-center">
            <DropdownMenu>
              <DropdownMenuTrigger className="w-full">
                {types.find((t) => t.value === operator)?.label || (
                  <span className="font-normal text-gray-500">Operator...</span>
                )}
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                {types.map((type) => (
                  <DropdownMenuItem
                    key={type.value}
                    onSelect={() => setOperator(type.value)}
                  >
                    {type.label}
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
            {!dataIsValid.operator && (
              <div className="text-left text-xs text-red-500">
                Operator is required
              </div>
            )}
          </div>
          <div className="col-span-1 flex flex-col items-center">
            <input
              id="value"
              placeholder="Value"
              className={`w-full rounded border font-normal ${
                !dataIsValid.value ? "border-red-500" : "border-gray-300"
              } px-2 py-1.5 disabled:cursor-not-allowed disabled:bg-gray-50`}
              value={value || ""}
              type="number"
              onChange={(e) => setValue(parseInt(e.target.value))}
              disabled={!editable}
            />
            {!dataIsValid.value && (
              <div className="text-left text-xs text-red-500">
                Value is required
              </div>
            )}
          </div>
        </div>
      </div>
    </ModalStep>
  );
};

const types = [
  { value: "lt", label: "<" },
  { value: "le", label: "<=" },
  { value: "gt", label: ">" },
  { value: "ge", label: ">=" },
] as const;

export default MathOperatorBuilder;
