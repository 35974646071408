import { CheckIcon, DocumentDuplicateIcon } from "@heroicons/react/outline";
import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import SyntaxHighlighter from "react-syntax-highlighter";
import { githubGist } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { highlightRequestVariables } from "./InputVariableHighlighting/highlightRequestVariables";

export const TextCopyBlock = ({
  text,
  code = false,
  highlight = false,
  inputVariables,
  forceMonoSpace = false,
}: {
  text: string;
  code?: boolean;
  highlight?: boolean;
  inputVariables?: Record<string, string>;
  forceMonoSpace?: boolean;
}) => {
  const [isCopied, setIsCopied] = useState(false);

  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const copyButton = (
    <CopyToClipboard text={text} onCopy={onCopyText}>
      <span className="cursor-pointer">
        {isCopied ? (
          <CheckIcon className="h-5 w-auto" />
        ) : (
          <DocumentDuplicateIcon className="h-5 w-auto" />
        )}
      </span>
    </CopyToClipboard>
  );

  const codeBlock = code ? (
    <div className="w-full">
      <span className="absolute right-1 top-1 z-10 text-gray-600 hover:text-gray-500">
        {copyButton}
      </span>
      <SyntaxHighlighter
        language="javascript"
        style={githubGist}
        lineProps={{
          style: { wordBreak: "break-all", whiteSpace: "pre-wrap" },
        }}
        wrapLines={true}
      >
        {text}
      </SyntaxHighlighter>
    </div>
  ) : (
    <pre className="z-10 h-80 w-full whitespace-pre-wrap text-gray-900">
      <div
        className={`relative w-full overflow-visible ${
          forceMonoSpace ? "font-mono" : "font-sans"
        }`}
      >
        <span className="absolute right-0 top-0 z-10 text-gray-600 hover:text-gray-500">
          {copyButton}
        </span>
        <span className={highlight && !forceMonoSpace ? "bg-blue-100 " : ""}>
          {inputVariables
            ? highlightRequestVariables(text, inputVariables)
            : text}
        </span>
      </div>
    </pre>
  );

  return (
    <div className="relative h-full overflow-y-auto rounded-sm border border-gray-300 bg-white p-2">
      {codeBlock}
    </div>
  );
};
