import { ReportCell } from "@/types/evaluate";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer";

export const CompareDiffCell = ({ cell }: { cell: ReportCell }) => {
  const sourceValue =
    typeof cell.value.value.source === "string"
      ? cell.value.value.source
      : JSON.stringify(cell.value.value.source);
  const targetValue =
    typeof cell.value.value.target === "string"
      ? cell.value.value.target
      : JSON.stringify(cell.value.value.target);

  return (
    <div
      className={`group relative -mx-2 -mt-3  max-h-96  min-w-[400px] overflow-visible`}
    >
      <ReactDiffViewer
        oldValue={sourceValue}
        newValue={targetValue}
        splitView={false}
        hideLineNumbers={true}
        showDiffOnly={true}
        extraLinesSurroundingDiff={2}
        compareMethod={DiffMethod.WORDS}
      />
    </div>
  );
};
