const fonts = {
  family: {
    marketing: "NoiGroteskTrial, sans-serif",
  },
  weight: {
    light: "300",
    regular: "400",
    medium: "500",
    semiBold: "600",
    bold: "700",
    black: "800",
  },
};

export default fonts;
