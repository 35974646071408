import {
  CogIcon,
  ExclamationCircleIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { ENDPOINTS } from "@/api/application-api";
import { Breadcrumbs } from "@/components/Breadcrumbs";
import ConditionalModal from "@/components/ConditionalModal";
import DeleteReleaseLabelGroupModal from "@/components/DeleteReleaseLabelGroupModal";
import EditPromptRegistryObjectsModal from "@/components/EditPromptRegistryObjectsModal";
import { Button } from "@/components/ui/button";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { ToastType } from "@/enums";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  usePromptRegistry,
  usePromptVersions,
  useSetReleaseLabelGroup,
} from "@/queries";
import {
  Conditional,
  DynamicReleaseLabel,
  OperationConditional,
} from "@/types/conditionals";
import { ReleaseLabelGroupReadDetailResponse } from "@/types/release-label-groups";
import { authHeader } from "@/utils/headers";
import { displayErrorToast, displayToast } from "@/utils/toast";
import ReleaseLabelTable from "./ReleaseLabelTable";
import ExitPageConfirmation from "../ui/exit-page-confirmation";
import { ListPromptVersion } from "@/types";
import LoadingSpinner from "../LoadingSpinner";
import { SplitIcon } from "lucide-react";

export const CONDITIONAL_OPERATOR_TO_STRING: { [key: string]: string } = {
  "=": "=",
  "!=": "!=",
  in: "in",
  not_in: "not in",
};

interface DynamicReleaseLabelCluster {
  [key: number]: DynamicReleaseLabel[];
}

const ReleaseLabelGroup = () => {
  const [conditionals, setConditionals] = useState<Conditional[]>([]);
  const [editingConditional, setEditingConditional] =
    useState<OperationConditional | null>(null);
  const [editingConditionalIndex, setEditingConditionalIndex] = useState<
    number | null
  >(null);
  const [isConditionalModalOpen, setConditionalModalOpen] = useState(false);
  const [
    isDeleteReleaseLabelGroupModalOpen,
    setDeleteReleaseLabelGroupModalOpen,
  ] = useState(false);
  const [
    isEditPromptRegistryObjectsModalOpen,
    setEditPromptRegistryObjectsModalOpen,
  ] = useState(false);
  const [originalConditionals, setOriginalConditionals] = useState<
    Conditional[]
  >([]);
  const [promptLabelName, setPromptLabelName] = useState<string>("");
  const { releaseLabelGroupId } = useParams();
  const authContext = useAuth();
  const userToken = authContext!.userToken;
  const userContext = useUser();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { mutateAsync: setReleaseLabelGroup } = useSetReleaseLabelGroup(
    userToken!,
    userContext.activeWorkspaceId!,
    releaseLabelGroupId!,
  );
  const [activeTab, setActiveTab] = useState<number | null>(null);
  const [selectedPromptVersion, setSelectedPromptVersion] =
    useState<ListPromptVersion | null>(null);

  useEffect(() => {
    if (conditionals.length > 0 && !activeTab) {
      const firstId =
        conditionals[0].dynamic_release_labels[0]?.prompt_registry_id;
      setActiveTab(firstId);
    }
  }, [conditionals, activeTab]);

  useEffect(() => {
    if (!releaseLabelGroupId) return;

    (async () => {
      const url = new URL(
        `${ENDPOINTS.release_label_groups}/${releaseLabelGroupId.toString()}`,
      );

      const response = await fetch(url.toString(), {
        headers: authHeader(userToken!),
      });

      const {
        release_label_group,
        success,
      }: ReleaseLabelGroupReadDetailResponse = await response.json();

      if (success) {
        setConditionals(release_label_group.conditionals);
        setOriginalConditionals(release_label_group.conditionals);
        setPromptLabelName(release_label_group.prompt_label_name);
      } else {
        displayErrorToast("Error fetching A/B Release data");
      }
    })();
  }, [releaseLabelGroupId, userToken]);

  const hasChanges = useMemo(() => {
    return (
      JSON.stringify(conditionals) !== JSON.stringify(originalConditionals)
    );
  }, [conditionals, originalConditionals]);

  const handleAddConditionalButtonClick = () => {
    setEditingConditional(null);
    setEditingConditionalIndex(null);
    setConditionalModalOpen(true);
  };

  const handleDeleteConditionalIconClick = (conditional: Conditional) => {
    if (conditional.is_default) {
      setDeleteReleaseLabelGroupModalOpen(true);
      return;
    }

    setConditionals((prevConditionals) =>
      prevConditionals.filter(
        (prevConditional) =>
          !(
            "field" in prevConditional &&
            prevConditional.field === conditional.field &&
            prevConditional.operator === conditional.operator &&
            prevConditional.value === conditional.value
          ),
      ),
    );
  };

  const handleEditConditionalIconClick = (
    conditional: OperationConditional,
    index: number,
  ) => {
    setEditingConditional(conditional);
    setEditingConditionalIndex(index);
    setConditionalModalOpen(true);
  };

  const handleSaveButtonClick = async () => {
    if (conditionals && releaseLabelGroupId) {
      try {
        const { success, message } = await setReleaseLabelGroup(conditionals);
        if (success) {
          displayToast("A/B release updated successfully", ToastType.success);
          setOriginalConditionals(conditionals);
          setErrorMessage(null);
        } else {
          const pydanticError: {
            loc: string[];
            msg: string;
            type: string;
          }[] = message;
          setErrorMessage(`Error: ${pydanticError[0].msg}`);
          displayErrorToast("Error updating the A/B Release");
        }
      } catch (error) {
        console.error("Failed to save the release label group:", error);
      }
    }
  };

  const onConditionalModalSubmit = (conditional: OperationConditional) => {
    if (editingConditional) {
      const updatedConditionals = [...conditionals];
      updatedConditionals[editingConditionalIndex!] = conditional;
      setConditionals(updatedConditionals);
      return;
    }

    setConditionals([conditional, ...conditionals]);
  };

  const promptRegistryIds = useMemo(() => {
    const ids = new Set<number>();
    conditionals.forEach((conditional) => {
      conditional.dynamic_release_labels.forEach((label) => {
        ids.add(label.prompt_registry_id);
      });
    });
    return Array.from(ids);
  }, [conditionals]);

  const renderTabs = () => (
    <div className="my-3 flex border-b">
      <div className="mx-2 flex items-center justify-center">
        <div className="rounded-sm border border-gray-200 bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600">
          {promptLabelName}
        </div>
      </div>
      <div className="flex flex-row overflow-x-auto">
        {promptRegistryIds.map((id) => (
          <button
            key={id}
            className={`min-w-fit px-4 py-2 text-sm ${
              activeTab === id
                ? "border-b-2 border-blue-500 text-blue-500"
                : "text-gray-500"
            }`}
            onClick={() => setActiveTab(id)}
          >
            <PromptRegistryName id={id} />
          </button>
        ))}
      </div>
      <Button
        className="ml-auto px-4 py-2 text-sm text-gray-500 hover:text-gray-700"
        onClick={() => setEditPromptRegistryObjectsModalOpen(true)}
        variant="ghost"
        size="sm"
      >
        <CogIcon className="mr-2 inline-block h-4 w-4" />
        Configure
      </Button>
    </div>
  );

  const renderConditionals = () => {
    const baseCluster: DynamicReleaseLabelCluster = promptRegistryIds.reduce(
      (acc: DynamicReleaseLabelCluster, promptRegistryId: number) => {
        acc[promptRegistryId] = [];
        return acc;
      },
      {},
    );

    return conditionals.map((conditional: Conditional, index: number) => {
      const filteredLabels = conditional.dynamic_release_labels.filter(
        (label) => label.prompt_registry_id === activeTab,
      );

      const clustersGroupedByPromptRegistryId: DynamicReleaseLabelCluster =
        filteredLabels.reduce(
          (acc: DynamicReleaseLabelCluster, obj: DynamicReleaseLabel) => {
            const promptRegistryId = obj.prompt_registry_id;
            if (!acc[promptRegistryId]) acc[promptRegistryId] = [];
            acc[promptRegistryId].push(obj);
            return acc;
          },
          {},
        );

      const finalRowClusters: DynamicReleaseLabelCluster = {
        ...baseCluster,
        ...clustersGroupedByPromptRegistryId,
      };

      const promptRegistryIds: number[] = Object.keys(finalRowClusters).map(
        (key) => Number(key),
      );

      const sortedGroups: DynamicReleaseLabelCluster = promptRegistryIds
        .filter((id) => activeTab === id)
        .sort((a: any, b: any) => a - b)
        .reduce((acc: any, key) => {
          acc[key] = clustersGroupedByPromptRegistryId[key] || [];
          return acc;
        }, {});

      const innerTds = Object.entries(sortedGroups).map(
        ([promptRegistryId, dynamicReleaseLabels]: [
          string,
          DynamicReleaseLabel[],
        ]) => (
          <td className="border px-4 py-4 text-xs" key={promptRegistryId}>
            <ReleaseLabelTable
              conditional={conditional}
              dynamicReleaseLabels={dynamicReleaseLabels}
              promptLabelName={promptLabelName}
              promptRegistryId={promptRegistryId}
              setConditionals={setConditionals}
            />
          </td>
        ),
      );

      if (conditionals.length === 1) {
        return innerTds;
      }

      return (
        <React.Fragment
          key={
            conditional.is_default
              ? "default"
              : `${conditional.field}-${conditional.operator}-${conditional.value}`
          }
        >
          <tr>
            <td
              className="border bg-gray-100 px-4 py-2"
              colSpan={promptRegistryIds.length}
            >
              <div className="flex justify-between">
                <div className="flex items-center">
                  {conditional.is_default ? (
                    <span className="text-sm font-semibold uppercase text-gray-700">
                      Default
                    </span>
                  ) : (
                    <div className="flex items-center">
                      <span className="text-sm font-semibold uppercase text-gray-700">
                        If
                      </span>
                      <div className="mx-2 rounded-sm border border-gray-300 bg-gray-200 px-2 py-1 font-mono text-xs text-gray-600">
                        {`${conditional.field} ${
                          CONDITIONAL_OPERATOR_TO_STRING[conditional.operator]
                        } ${
                          conditional.operator === "in" ||
                          conditional.operator === "not_in"
                            ? `[${conditional.value}]`
                            : conditional.value
                        }`}
                      </div>
                    </div>
                  )}
                  {filteredLabels.length === 0 && (
                    <span className="ml-3 text-sm text-red-600">
                      (minimum of 1 version required)
                    </span>
                  )}
                </div>
                <div className="flex items-center space-x-2">
                  {!conditional.is_default && (
                    <>
                      <PencilIcon
                        className="h-5 w-5 cursor-pointer text-gray-500 hover:text-gray-700"
                        onClick={() =>
                          handleEditConditionalIconClick(conditional, index)
                        }
                      />
                      <TrashIcon
                        className="h-5 w-5 cursor-pointer text-gray-500 hover:text-gray-700"
                        onClick={() =>
                          handleDeleteConditionalIconClick(conditional)
                        }
                      />
                    </>
                  )}
                </div>
              </div>
            </td>
          </tr>
          <tr>{innerTds}</tr>
        </React.Fragment>
      );
    });
  };

  const [initialIsNewReleaseLabelGroup, setInitialIsNewReleaseLabelGroup] =
    useState<boolean | null>(null);

  useEffect(() => {
    if (conditionals.length > 0 && promptRegistryIds.length > 0) {
      const isNewReleaseLabelGroup = (() => {
        return (
          (promptRegistryIds.length === 1 &&
            conditionals.length === 1 &&
            conditionals[0]?.dynamic_release_labels.length === 1) ||
          (conditionals.length === 1 &&
            conditionals[0]?.dynamic_release_labels.every(
              (label) => label.percentage === 100,
            ) &&
            conditionals[0]?.dynamic_release_labels.length ===
              promptRegistryIds.length)
        );
      })();

      if (
        initialIsNewReleaseLabelGroup === null ||
        initialIsNewReleaseLabelGroup === true
      ) {
        setInitialIsNewReleaseLabelGroup(isNewReleaseLabelGroup);
      }
    }
  }, [conditionals, initialIsNewReleaseLabelGroup, promptRegistryIds]);

  const handleInitialVersionAdd = ({
    promptRegistryId,
  }: {
    promptRegistryId: number;
  }) => {
    if (!selectedPromptVersion) {
      displayErrorToast("Please select a prompt version before saving.");
      return;
    }

    const newDynamicReleaseLabel = {
      is_real_label: false,
      percentage: 0,
      prompt_registry_id: promptRegistryId,
      prompt_version_id: selectedPromptVersion.id,
      prompt_version_number: selectedPromptVersion.number,
    };

    const updatedConditionals = conditionals.map((conditional, index) => {
      if (index === 0) {
        return {
          ...conditional,
          dynamic_release_labels: [
            ...conditional.dynamic_release_labels,
            newDynamicReleaseLabel,
          ],
        };
      }
      return conditional;
    });

    setConditionals(updatedConditionals);
  };

  if (promptRegistryIds.length === 0) {
    // Still loading
    return (
      <>
        <div className="px-1 pb-8 pt-1">
          <Breadcrumbs
            items={["Registry", "A/B Releases", promptLabelName]}
            navigateUrl={`/workspace/${userContext?.activeWorkspaceId}/ab-releases`}
            pageTitle={`Dynamic Release Label`}
            pageSubtitle="Configure the prompt template retrieved when fetching this release label. Build segmentation rules to dynamically select the prompt version for different users."
          />
        </div>
        <div className="border-gray-2000 mx-auto mt-6 max-w-2xl rounded-lg border bg-gray-50 p-8 shadow-lg">
          <div className="text-center">
            <LoadingSpinner size={10} />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="px-1 pb-8 pt-1">
        <Breadcrumbs
          items={["Registry", "A/B Releases", promptLabelName]}
          navigateUrl={`/workspace/${userContext?.activeWorkspaceId}/ab-releases`}
          pageTitle={`Dynamic Release Label`}
          pageSubtitle="Configure the prompt template retrieved when fetching this release label. Build segmentation rules to dynamically select the prompt version for different users."
        />
        {initialIsNewReleaseLabelGroup ? (
          <div className="mx-auto mt-6 max-w-2xl rounded-lg border border-gray-200 bg-gray-50 p-8 shadow-lg">
            <div className="text-left">
              <div className="mb-4 flex items-center">
                <div className="mr-4 rounded-md border border-blue-200 bg-gradient-to-tl from-blue-100 to-white p-3 shadow-inner shadow-sm">
                  <SplitIcon className="h-6 w-6 text-blue-700" />
                </div>
              </div>
              <h1 className="mb-2 text-2xl font-bold text-gray-900">
                New A/B Release
              </h1>
              <p className="mb-6 text-base text-gray-600">
                Configure your split release. PromptLayer will randomly select
                one of the chosen prompt versions each time you make a request.
              </p>
              <div className="mb-4 rounded-md border border-gray-300 bg-gray-200 p-3">
                <p className="font-base text-sm text-gray-700">
                  Everytime you fetch{" "}
                  <span className="font-semibold text-blue-600">
                    <PromptRegistryNameSpan id={promptRegistryIds[0]} /> (with
                    the label "{promptLabelName}")
                  </span>{" "}
                  PromptLayer will randomly select one of the following:
                </p>
              </div>
              <div>
                <div className="mb-4 py-4">
                  <div className="mb-3 flex items-center justify-between text-gray-700">
                    <span className="text-sm font-medium text-gray-400">
                      Current configuration
                    </span>
                    <span className="inline-flex items-center rounded-md border border-blue-300 bg-blue-50 px-4 py-2 text-xs font-medium text-blue-800 shadow-sm">
                      Version #
                      {
                        conditionals[0].dynamic_release_labels.filter(
                          (label) =>
                            label.prompt_registry_id === promptRegistryIds[0],
                        )[0].prompt_version_number
                      }
                    </span>
                  </div>
                  <div className="flex items-center text-gray-700">
                    <span className="mr-2 text-sm font-medium text-gray-400">
                      Select another prompt version
                    </span>
                    <div className="flex-1">
                      <div className="ml-auto w-full max-w-[300px]">
                        <ChoosePromptVersion
                          promptRegistryId={promptRegistryIds[0]}
                          conditional={conditionals[0]}
                          selectedPromptVersion={selectedPromptVersion}
                          setSelectedPromptVersion={setSelectedPromptVersion}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-center border-t border-gray-200 pt-8">
                <Button
                  onClick={() =>
                    handleInitialVersionAdd({
                      promptRegistryId: promptRegistryIds[0],
                    })
                  }
                  disabled={!selectedPromptVersion}
                  className="rounded-md bg-blue-600 px-24 py-3 text-sm text-white transition duration-300 hover:bg-blue-700"
                >
                  Build Experiment
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <>
            {renderTabs()}
            <div className="mt-6 overflow-x-auto">
              <table className="w-full table-auto">
                <tbody>{renderConditionals()}</tbody>
              </table>
            </div>
            <div className="mt-4 flex items-center justify-between">
              <Button
                onClick={handleAddConditionalButtonClick}
                variant="secondaryLightOutline"
              >
                Add new Segment
              </Button>
              <div>
                <Button disabled={!hasChanges} onClick={handleSaveButtonClick}>
                  Save
                </Button>
              </div>
            </div>
          </>
        )}
        {errorMessage && (
          <div className="mt-4">
            <div className="mx-auto flex max-w-lg items-center rounded-md border border-red-200 bg-red-100 p-4 text-red-700">
              <ExclamationCircleIcon className="mr-2 h-6 w-6 text-red-700" />
              {errorMessage}
            </div>
          </div>
        )}
      </div>
      {isConditionalModalOpen && (
        <ConditionalModal
          editingConditional={editingConditional}
          onConditionalModalSubmit={onConditionalModalSubmit}
          setOpen={setConditionalModalOpen}
        />
      )}
      {isDeleteReleaseLabelGroupModalOpen && (
        <DeleteReleaseLabelGroupModal
          open={isDeleteReleaseLabelGroupModalOpen}
          releaseLabelGroupId={parseInt(releaseLabelGroupId!, 10)}
          setOpen={setDeleteReleaseLabelGroupModalOpen}
        />
      )}
      {isEditPromptRegistryObjectsModalOpen && (
        <EditPromptRegistryObjectsModal
          initialPromptRegistryIds={promptRegistryIds}
          isOpen={isEditPromptRegistryObjectsModalOpen}
          promptLabelName={promptLabelName}
          setConditionals={setConditionals}
          setOpen={setEditPromptRegistryObjectsModalOpen}
        />
      )}
      <ExitPageConfirmation
        when={hasChanges}
        message="You have unsaved changes. Are you sure you want to leave?"
        execute={() => console.log("Leaving page with unsaved changes")}
      />
    </>
  );
};

function ChoosePromptVersion({
  promptRegistryId,
  conditional,
  selectedPromptVersion,
  setSelectedPromptVersion,
}: {
  promptRegistryId: number;
  conditional: Conditional;
  selectedPromptVersion: ListPromptVersion | null;
  setSelectedPromptVersion: (promptVersion: ListPromptVersion) => void;
}) {
  const auth = useAuth();
  const userToken = auth?.userToken!;
  const userContext = useUser();
  const originalPromptRegistryId = promptRegistryId;

  const promptVersionsQuery = usePromptVersions(userToken, {
    workspaceId: userContext.activeWorkspaceId!,
    promptRegistryId: originalPromptRegistryId,
    perPage: Number.MAX_SAFE_INTEGER,
  });

  const promptVersionOptions =
    promptVersionsQuery.data?.pages.flatMap((page) => page.items) ?? [];

  const getPromptVersionNames = (promptVersion: ListPromptVersion) => {
    const namesList =
      promptVersion.labels.length > 0
        ? promptVersion.labels.map((label) => label.name)
        : [];
    return namesList.map((label) => (
      <span
        key={label}
        className="mx-1 inline-block rounded-sm border border-gray-200 px-2 py-0.5 text-xs font-medium text-gray-500"
      >
        {label}
      </span>
    ));
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="w-full">
        {selectedPromptVersion ? (
          <span>
            Version {selectedPromptVersion?.number}
            {getPromptVersionNames(selectedPromptVersion)}
          </span>
        ) : (
          <span className="text-gray-400">Select version...</span>
        )}
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {promptVersionOptions.map((promptVersion) =>
          conditional.dynamic_release_labels.some(
            (label) => label.prompt_version_id === promptVersion.id,
          ) ? (
            <DropdownMenuItem key={promptVersion.id} disabled>
              Version {promptVersion.number} (already added){" "}
              {getPromptVersionNames(promptVersion)}
            </DropdownMenuItem>
          ) : (
            <DropdownMenuItem
              key={promptVersion.id}
              onSelect={() => setSelectedPromptVersion(promptVersion)}
            >
              Version {promptVersion.number}{" "}
              {getPromptVersionNames(promptVersion)}
            </DropdownMenuItem>
          ),
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function usePromptRegistryName(promptRegistryId: number) {
  const auth = useAuth();
  const userToken = auth?.userToken!;
  const promptRegistryQuery = usePromptRegistry(userToken, promptRegistryId);
  const promptRegistry = promptRegistryQuery.data;
  return promptRegistry?.prompt_name;
}

export function PromptRegistryNameSpan({ id }: { id: number }) {
  return <span>{usePromptRegistryName(id)}</span>;
}

export function PromptRegistryName({ id }: { id: number }) {
  return <div>{usePromptRegistryName(id)}</div>;
}

export default ReleaseLabelGroup;
