import styled from "styled-components";

import { fonts } from "styles";
import heroShape from "@/assets/hero-shape.png";

export const Button = styled.button`
  background: #5167f0;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(50, 62, 135, 0.19);
  color: #ffffff;
  display: inline-block;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.01em;
  padding: 10px 40px;
  transition: 0.2s all ease;

  &:hover {
    background: #6b7ef5;
  }

  @media (max-width: 991px) {
    font-size: 16px;
    padding: 8px 30px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 18px;
    padding: 8px 35px;
  }
`;

export const Container = styled.section`
  background-image: url(${heroShape});
  background-position: 60% 70%;
  background-repeat: no-repeat;
  background-size: 410px;
  overflow: hidden;
  padding-left: calc((100% - 1150px) / 2);
  padding-top: 78px;

  @media (min-width: 992px) and (max-width: 1199px) {
    background-position: 60% 65%;
    background-size: 320px;
    padding-left: calc((100% - 960px) / 2);
    padding-top: 54px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    background-position: 60% 67%;
    background-size: 240px;
    padding-left: calc((100% - 720px) / 2);
    padding-top: 35px;
  }

  @media (max-width: 767px) {
    background-position: 60% 67%;
    background-size: 240px;
    padding-left: calc((100% - 540px) / 2);
    padding-top: 35px;
  }

  @media (max-width: 575px) {
    padding-left: 0;
  }
`;

export const H2 = styled.div`
  color: #090b1c;
  font-size: 66px;
  font-weight: ${fonts.weight.medium};
  letter-spacing: -0.02em;
  line-height: 1.2;
  margin: 0 0 0.5rem;
  max-width: 590px;
  width: 100%;

  @media (max-width: 991px) {
    font-size: 36px;
    max-width: 340px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 48px;
  }

  @media (min-width: 1200px) {
    font-size: 62px;
  }
`;

export const HeroLeft = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 16px 50px;

  @media (max-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

export const HeroLeftDescription = styled.div`
  color: #666666;
  font-size: 21px;
  font-weight: 400;
  letter-spacing: -0.01em;
  line-height: 1.5;
  margin: 15px 0 21px;
  max-width: 400px;
  width: 100%;

  @media (max-width: 1300px) {
    font-size: 21px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 18px;
    max-width: 300px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 15px;
    max-width: 340px;
  }

  @media (max-width: 767px) {
    font-size: 16px;
    max-width: 300px;
  }
`;

export const HeroRight = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  padding-bottom: 32px;

  @media (max-width: 575px) {
    padding-left: 16px;
  }

  @media (max-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding-bottom: 30px;
  }
`;

export const HeroRightImg = styled.img`
  border-radius: 8px 0 0 8px;
  box-shadow:
    0 7px 20px rgba(0, 0, 0, 0.05),
    0 7px 30px rgba(0, 0, 0, 0.05);
  max-width: 100%;
  vertical-align: middle;
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
