import { ReportColumn } from "@/types/evaluate";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { DivButton } from "../../button";
import { Input } from "../../input";
import { useSearch } from "../Search/search-context";

interface FilterInputProps {
  column: ReportColumn;
  onClose: () => void;
}

const FilterInput: React.FC<FilterInputProps> = React.memo(
  ({ column, onClose }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const debounceTimerRef = useRef<NodeJS.Timeout | null>(null);
    const {
      updateFilter,
      removeFilter,
      currentFilterInput,
      setCurrentFilterInput,
    } = useSearch();
    const [localFilterValue, setLocalFilterValue] = useState(
      currentFilterInput &&
        currentFilterInput?.column_id === column.id?.toString()
        ? currentFilterInput.value
        : "",
    );

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          containerRef.current &&
          !containerRef.current.contains(event.target as Node)
        ) {
          if (localFilterValue === "") {
            removeFilter(column.id?.toString() || "", localFilterValue);
          }
          setCurrentFilterInput(null);
          onClose();
        }
      };

      const handleEscapeKey = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
          if (localFilterValue === "") {
            removeFilter(column.id?.toString() || "", localFilterValue);
          }
          setCurrentFilterInput(null);
          onClose();
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("keydown", handleEscapeKey);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
        document.removeEventListener("keydown", handleEscapeKey);
      };
    }, [
      onClose,
      localFilterValue,
      removeFilter,
      column.id,
      setCurrentFilterInput,
    ]);

    useEffect(() => {
      let timeoutId: NodeJS.Timeout;
      if (inputRef.current) {
        timeoutId = setTimeout(
          () => inputRef.current && inputRef.current.focus(),
          10,
        );
      }
      return () => clearTimeout(timeoutId);
    }, []);

    const handleFilterChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setLocalFilterValue(value);

        if (debounceTimerRef.current) {
          clearTimeout(debounceTimerRef.current);
        }

        debounceTimerRef.current = setTimeout(() => {
          setCurrentFilterInput({
            column_id: column.id?.toString() || "",
            value,
          });
        }, 80);
      },
      [column.id, setCurrentFilterInput],
    );

    const handleKeyDown = useCallback(
      (e: React.KeyboardEvent<HTMLInputElement>) => {
        e.stopPropagation();
        if (e.key === "Enter") {
          e.preventDefault();
          if (debounceTimerRef.current) {
            clearTimeout(debounceTimerRef.current);
          }
          debounceTimerRef.current = setTimeout(() => {
            if (localFilterValue !== "") {
              updateFilter(column.id?.toString() || "", localFilterValue);
            } else {
              removeFilter(column.id?.toString() || "", localFilterValue);
            }
            setCurrentFilterInput(null);
            onClose();
          }, 80);
        } else if (e.key === "Escape") {
          e.preventDefault();
          if (localFilterValue === "") {
            removeFilter(column.id?.toString() || "", localFilterValue);
          }
          setCurrentFilterInput(null);
          onClose();
        }
      },
      [
        column.id,
        localFilterValue,
        updateFilter,
        removeFilter,
        setCurrentFilterInput,
        onClose,
      ],
    );

    return (
      <div
        className="absolute z-20 mt-2 min-w-[350px] rounded-md border border-gray-300 bg-white p-2 shadow-lg"
        ref={containerRef}
        onClick={(e) => e.stopPropagation()}
      >
        <span className="mb-1 block font-light">Column must contain: </span>
        <div className="flex gap-x-1">
          <Input
            type="text"
            ref={inputRef}
            value={localFilterValue}
            onChange={handleFilterChange}
            onKeyDown={(e) => {
              e.stopPropagation();
              handleKeyDown(e);
            }}
            placeholder="Filter column value..."
            className="flex-grow rounded-l-md border border-gray-300 px-2 py-1 font-normal focus:border-blue-500 focus:outline-none"
            autoFocus
          />
          <DivButton
            onClick={() => {
              if (debounceTimerRef.current) {
                clearTimeout(debounceTimerRef.current);
              }
              debounceTimerRef.current = setTimeout(() => {
                if (localFilterValue !== "") {
                  updateFilter(column.id?.toString() || "", localFilterValue);
                } else {
                  removeFilter(column.id?.toString() || "", localFilterValue);
                }
                setCurrentFilterInput(null);
                onClose();
              }, 80);
            }}
            variant={"default"}
          >
            Filter
          </DivButton>
        </div>
      </div>
    );
  },
);

export default FilterInput;
