import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { LightAsync as SyntaxHighlighter } from "react-syntax-highlighter";
import { dracula } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { CheckIcon, DocumentDuplicateIcon } from "@heroicons/react/outline";

const CodeCopyBlock = ({ code }: { code: string }) => {
  const [isCopied, setIsCopied] = useState(false);

  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 1000);
  };

  return (
    <div className="max-h-90 mt-2 h-full overflow-x-hidden px-2">
      <div className="relative h-full w-full">
        <CopyToClipboard text={code} onCopy={onCopyText}>
          <span className="absolute right-1 top-1 cursor-pointer text-gray-200 hover:text-gray-100">
            {isCopied ? (
              <CheckIcon className="h-5 w-auto" />
            ) : (
              <DocumentDuplicateIcon className="h-5 w-auto" />
            )}
          </span>
        </CopyToClipboard>
        <SyntaxHighlighter
          customStyle={{
            borderRadius: "0.25rem",
            padding: "15px 45px",
          }}
          language="python"
          style={dracula}
        >
          {code}
        </SyntaxHighlighter>
      </div>
    </div>
  );
};

export default CodeCopyBlock;
