import { createContext, Dispatch, SetStateAction, useState } from "react";

const PageContext = createContext<
  | {
      title: string | null;
      setTitle: Dispatch<SetStateAction<string | null>>;
    }
  | undefined
>(undefined);

const PageProvider = (props: React.PropsWithChildren<{}>) => {
  const [title, setTitle] = useState<string | null>(null);

  return (
    <PageContext.Provider
      value={{
        title: title,
        setTitle: setTitle,
      }}
      {...props}
    />
  );
};

export { PageProvider };
