import { Button } from "@/components/ui/button";

import { HumanConfiguration, ReportCell, ReportColumn } from "@/types/evaluate";
import { useState } from "react";
import { usePatchReportCell } from "../hooks/usePatchReportCell";
import { HumanInputUIComponent } from "./HumanUIComponent";

export const HumanInputCell = ({
  cell,
  column,
  isLastColumn,
}: {
  cell: ReportCell;
  column: ReportColumn;
  isLastColumn: boolean;
}) => {
  const config: HumanConfiguration = column.configuration;
  const dataType = config?.data_type;
  const uiComponent = config?.ui_element.component;
  const [value, setValue] = useState<any>(cell.value?.value);
  const [open, setOpen] = useState(
    !!cell.value?.value || cell.value?.value === 0,
  );

  const { patchReportCell, isLoading } = usePatchReportCell(cell?.id);

  return (
    <div className={"px-2 py-1"}>
      <div className="my-2 text-center">
        <HumanInputUIComponent
          uiComponent={uiComponent}
          dataType={dataType}
          config={config}
          value={value}
          setValue={setValue}
          open={open}
          setOpen={setOpen}
          isLastColumn={isLastColumn}
        />
      </div>
      {open && (
        <div className="flex justify-center">
          <Button
            size="sm"
            variant="outline"
            onClick={() => {
              patchReportCell({
                value: value,
              });
            }}
            isLoading={isLoading}
          >
            Save
          </Button>
        </div>
      )}
    </div>
  );
};
