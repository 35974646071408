import * as SliderPrimative from "@radix-ui/react-slider";

export const Slider = ({
  setScore,
  currentScore,
  resetScore,
}: {
  setScore: (value: number[]) => void;
  currentScore: number;
  resetScore: boolean;
}) => (
  <SliderPrimative.Root
    className="relative flex h-[20px] w-4/6 items-center"
    defaultValue={[50]}
    max={100}
    step={1}
    aria-label="Volume"
    value={[currentScore]}
    onValueChange={(value) => setScore(value)}
    disabled={resetScore}
  >
    <SliderPrimative.Track className="relative h-[10px] flex-1 rounded-full bg-gray-200">
      <SliderPrimative.Range
        className={`absolute ${
          resetScore ? "bg-gray-200" : "bg-blue-500"
        } h-[10px] rounded-l-full rounded-r-[500rem]`}
      />
    </SliderPrimative.Track>
    <SliderPrimative.Thumb className="block h-[20px] w-[20px] rounded-full bg-white drop-shadow-md" />
  </SliderPrimative.Root>
);
