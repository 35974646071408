import { FC } from "react";
import { Link } from "react-router-dom";
import logo from "@/assets/logo/logo.png";
import OutlineButton from "@/components/MarketingLayout/OutlineButton/OutlineButton";
import * as S from "./Styles";

interface LeftMenuProps {
  menuOpen: boolean;
  setMenuOpen: (value: boolean) => void;
}

const LeftMenu: FC<LeftMenuProps> = ({ menuOpen, setMenuOpen }) => {
  const handleNavItemClick = () => {
    setMenuOpen(false);
  };

  return (
    <S.LeftMenu
      aria-labelledby="offcanvasExampleLabel"
      className={menuOpen ? "show" : ""}
      id="offcanvasExample"
      role="dialog"
    >
      <S.LeftMenuHeader>
        <Link onClick={handleNavItemClick} to="/">
          <S.Logo alt="PromptLayer logo" src={logo} />
        </Link>
        <S.XIcon onClick={() => setMenuOpen(false)} />
      </S.LeftMenuHeader>
      <S.Bottom>
        <S.NavItems>
          <S.NavItem>
            <a href="https://docs.promptlayer.com/">Docs</a>
          </S.NavItem>
          <S.NavItem>
            <a href="https://blog.promptlayer.com/">Blog</a>
          </S.NavItem>
          <S.NavItem onClick={handleNavItemClick}>
            <Link to="/latency">Latency Graph</Link>
          </S.NavItem>
          <S.NavItem>
            <a href="https://promptlayer.com/jobs">Jobs</a>
          </S.NavItem>
          <S.NavItem onClick={handleNavItemClick}>
            <Link to="/login">Log In</Link>
          </S.NavItem>
          <S.NavItem onClick={handleNavItemClick}>
            <a
              href="mailto:hello@promptlayer.com"
              rel="noreferrer"
              target="_blank"
            >
              <OutlineButton>Contact us</OutlineButton>
            </a>
          </S.NavItem>
        </S.NavItems>
      </S.Bottom>
    </S.LeftMenu>
  );
};

export default LeftMenu;
