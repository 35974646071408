import { FC, FormEvent, KeyboardEvent, useRef, useState } from "react";
import { Send } from "lucide-react";

interface CommentFormProps {
  inThread: boolean;
  onClick?: () => void;
  onSubmit: (text: string) => void;
  placeholder?: string;
}

const CommentForm: FC<CommentFormProps> = ({
  inThread,
  onClick,
  onSubmit,
  placeholder = "Type a comment...",
}) => {
  const [inputValue, setInputValue] = useState("");
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  };

  const handleSubmit = (e?: FormEvent) => {
    e?.preventDefault();
    if (inputValue.trim()) {
      onSubmit(inputValue.trim());
      setInputValue("");
      if (textareaRef.current) {
        textareaRef.current.style.height = "auto";
      }
    }
  };

  return (
    <form onClick={onClick} onSubmit={handleSubmit}>
      <div className="relative">
        <textarea
          className={`w-full resize-none overflow-hidden rounded-lg border-none py-2.5 pl-2 pr-10 text-sm focus:outline-none focus:ring-0`}
          onChange={(e) => {
            setInputValue(e.target.value);
            adjustTextareaHeight();
          }}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          ref={textareaRef}
          rows={1}
          value={inputValue}
        />
        <button
          className={`absolute bottom-3.5 right-1.5 rounded-full p-1.5 transition-colors ${
            inputValue.trim()
              ? "bg-blue-500 text-white hover:bg-blue-600"
              : "cursor-not-allowed bg-gray-300 text-gray-500"
          }`}
          disabled={!inputValue.trim()}
          type="submit"
        >
          <Send className="h-3.5 w-3.5 stroke-2 text-white" />
        </button>
      </div>
    </form>
  );
};

export default CommentForm;
