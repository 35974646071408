import { FC, ReactNode } from "react";
import Card from "./Card";

type CardDetails = {
  buttonText: string;
  description: string;
  detailComponent: ReactNode;
  heading: string;
  iconComponent: ReactNode;
  subheading: string;
};

type CardContainerProps = {
  handleCardClick: (id: string) => void;
  leftCardDetails: CardDetails;
  rightCardDetails: CardDetails;
  selectedCardHeading: string | null;
};

const CardContainer: FC<CardContainerProps> = ({
  handleCardClick,
  leftCardDetails,
  rightCardDetails,
  selectedCardHeading,
}) => {
  const renderBottom = () => {
    let componentToRender = null;

    if (selectedCardHeading === leftCardDetails.heading) {
      componentToRender = leftCardDetails.detailComponent;
    } else if (selectedCardHeading === rightCardDetails.heading) {
      componentToRender = rightCardDetails.detailComponent;
    }

    if (!componentToRender) return null;

    return <div className="mt-8">{componentToRender}</div>;
  };

  const renderCard = (cardDetails: CardDetails) => {
    const { buttonText, description, heading, iconComponent, subheading } =
      cardDetails;
    return (
      <Card
        buttonText={buttonText}
        description={description}
        heading={heading}
        iconComponent={iconComponent}
        onClick={() => handleCardClick(heading)}
        isSelected={heading === selectedCardHeading}
        subheading={subheading}
      />
    );
  };

  return (
    <>
      <div className="mb-4 mt-8 flex w-full gap-x-8">
        <div className="w-1/2">{renderCard(leftCardDetails)}</div>
        <div className="w-1/2">{renderCard(rightCardDetails)}</div>
      </div>
      {renderBottom()}
    </>
  );
};

export default CardContainer;
