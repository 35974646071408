import { FC, useMemo, useState } from "react";
import { TrashIcon } from "@heroicons/react/outline";
import { Button } from "@/components/ui/button";
import AddWorkspaceMemberModal from "@/components/AddWorkspaceMemberModal";
import DeleteWorkspaceModal from "@/components/DeleteWorkspaceModal";
import DeleteWorkspaceMemberModal from "@/components/DeleteWorkspaceMemberModal";
import { TEAM_WORKSPACE_MEMBER_LIMIT } from "@/constants";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import useActiveWorkspace from "@/hooks/useActiveWorkspace";
import useIsActiveWorkspaceAdmin from "@/hooks/useIsActiveWorkspaceAdmin";
import { WorkspaceMember } from "@/types/workspaceMembers";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip";
import { TooltipArrow } from "@radix-ui/react-tooltip";

const Workspace: FC = () => {
  const [isAddMemberModalOpen, setAddMemberModalOpen] = useState(false);
  const [isDeleteWorkspaceMemberModalOpen, setDeleteWorkspaceMemberModalOpen] =
    useState(false);
  const [isDeleteWorkspaceModalOpen, setDeleteWorkspaceModalOpen] =
    useState(false);
  const [memberToDelete, setMemberToDelete] = useState<WorkspaceMember>();
  const activeWorkspace = useActiveWorkspace();
  const authContext = useAuth();
  const isActiveWorkspaceAdmin = useIsActiveWorkspaceAdmin();
  const userContext = useUser();

  const { activeWorkspaceIsDefault, activeWorkspaceName } = useMemo(() => {
    const activeWorkspace = userContext.workspaces.find(
      (workspace: any) => workspace.id === userContext?.activeWorkspaceId,
    );

    return {
      activeWorkspaceIsDefault: activeWorkspace?.is_default,
      activeWorkspaceName: activeWorkspace?.name || "",
    };
  }, [userContext?.activeWorkspaceId, userContext.workspaces]);

  const workspaceMemberCount = useMemo(() => {
    return userContext.workspaceMembers.filter(
      ({ workspace_id }: WorkspaceMember) =>
        workspace_id === userContext?.activeWorkspaceId,
    ).length;
  }, [userContext?.activeWorkspaceId, userContext.workspaceMembers]);

  const handleDeleteWorkspaceButtonClick = () => {
    setDeleteWorkspaceModalOpen(true);
  };

  const handleInviteButtonClick = () => {
    setAddMemberModalOpen(true);
  };

  const handleRemoveMemberLinkClick = (member: WorkspaceMember) => {
    setMemberToDelete(member);
    setDeleteWorkspaceMemberModalOpen(true);
  };

  const renderDeleteWorkspaceButton = () => {
    if (activeWorkspaceIsDefault || !isActiveWorkspaceAdmin) return null;

    return (
      <div className="flex items-center pt-4">
        <Button
          className="inline-flex items-center"
          onClick={handleDeleteWorkspaceButtonClick}
          size="sm"
          variant="destructiveOutline"
        >
          <TrashIcon className="h-4 w-auto pr-1" aria-hidden="true" />
          Delete Workspace
        </Button>
      </div>
    );
  };

  const renderInviteButton = () => {
    if (
      activeWorkspace &&
      (!!authContext?.user?.is_pl_admin || !activeWorkspace.admin_is_free)
    ) {
      if (isActiveWorkspaceAdmin) {
        return (
          <Button onClick={handleInviteButtonClick} size="sm">
            Invite
          </Button>
        );
      } else {
        return (
          <span className="ml-4 text-sm text-gray-500">
            Contact the admin to add members
          </span>
        );
      }
    } else {
      return (
        <TooltipProvider>
          <Tooltip delayDuration={0}>
            <TooltipTrigger asChild>
              <div>
                <Button disabled size="sm">
                  Invite
                </Button>
              </div>
            </TooltipTrigger>
            <TooltipContent side="top" className="bg-gray-800 text-white">
              Upgrade your plan to invite more members
              <TooltipArrow className="fill-gray-800" />
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      );
    }
  };

  const renderMemberCountIndicator = () => {
    if (activeWorkspace && activeWorkspace.admin_billing_plan_name === "team") {
      return (
        <span className="ml-2 text-sm text-gray-400">
          {workspaceMemberCount}/{TEAM_WORKSPACE_MEMBER_LIMIT}
        </span>
      );
    } else if (activeWorkspace && !activeWorkspace.admin_is_free) {
      return (
        <span className="ml-2 text-sm text-gray-400">
          {workspaceMemberCount}
        </span>
      );
    } else {
      return null;
    }
  };

  const renderMembersSection = () => {
    return (
      <div className="mt-2 px-2">
        <div className="flex items-center justify-between">
          <div className="flex items-baseline">
            <h1 className="text-lg font-semibold">Members</h1>
            {renderMemberCountIndicator()}
          </div>
          {renderInviteButton()}
        </div>
        <div className="mt-2 w-full border-t border-gray-200 pt-4">
          <ul className="space-y-2">{renderWorkspaceMembers()}</ul>
        </div>
      </div>
    );
  };

  const renderRemoveMemberLink = (member: WorkspaceMember) => {
    const isSelf = member.user_id === authContext?.user?.id;

    if (!isActiveWorkspaceAdmin && !isSelf) return;
    if (member.is_admin) return;

    return (
      <span
        className="cursor-pointer text-red-600 hover:text-red-800"
        onClick={() => handleRemoveMemberLinkClick(member)}
      >
        {isSelf ? "Leave" : "Remove"}
      </span>
    );
  };

  const renderWorkspaceMembers = () => {
    return userContext.workspaceMembers
      .filter(
        (member: WorkspaceMember) =>
          member.workspace_id === userContext?.activeWorkspaceId,
      )
      .map((member: WorkspaceMember) => (
        <li
          className="flex items-center justify-between rounded-md bg-gray-50 p-2 hover:bg-gray-100"
          key={member.id}
        >
          <div className="flex items-center space-x-2">
            <span className="text-gray-700">{member.email}</span>
            {member.is_admin && (
              <span className="inline-block rounded-full bg-blue-500 px-2 text-xs text-white">
                Admin
              </span>
            )}
          </div>
          {renderRemoveMemberLink(member)}
        </li>
      ));
  };

  return (
    <div>
      {renderDeleteWorkspaceButton()}
      {renderMembersSection()}
      {isAddMemberModalOpen && (
        <AddWorkspaceMemberModal setOpen={setAddMemberModalOpen} />
      )}
      {isDeleteWorkspaceMemberModalOpen && (
        <DeleteWorkspaceMemberModal
          member={memberToDelete}
          open={isDeleteWorkspaceMemberModalOpen}
          setOpen={setDeleteWorkspaceMemberModalOpen}
        />
      )}
      {isDeleteWorkspaceModalOpen && (
        <DeleteWorkspaceModal
          open={isDeleteWorkspaceModalOpen}
          setOpen={setDeleteWorkspaceModalOpen}
          workspaceName={activeWorkspaceName}
        />
      )}
    </div>
  );
};

export default Workspace;
