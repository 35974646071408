import { memo, useMemo } from "react";
import { toNormalCase } from "@/utils/strings";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import {
  ChartBarIcon,
  CurrencyDollarIcon,
  FireIcon,
  LightningBoltIcon,
  SparklesIcon,
} from "@heroicons/react/solid";

const StatIcon: Record<string, (props: any) => JSX.Element> = {
  averageLatency: LightningBoltIcon,
  averageScore: FireIcon,
  totalCost: CurrencyDollarIcon,
  totalRequests: ChartBarIcon,
  totalTokens: SparklesIcon,
};

interface StatBodyProps {
  label: string;
  id: string;
  value: string;
}
const StatBody = memo(({ label, id, value }: StatBodyProps) => {
  const Icon = StatIcon[id];
  return (
    <div
      className={`flex w-full flex-row items-center justify-between overflow-auto rounded-md border  border-gray-300 p-4 text-left`}
    >
      <div>
        <div className="text-xs ">{label}</div>
        <div className="text-2xl font-medium">{value}</div>
      </div>
      <div
        className={`flex items-center justify-center rounded-full bg-gray-100 p-3 text-gray-400 `}
      >
        {Icon && <Icon className={"h-6 w-6"} />}
      </div>
    </div>
  );
});

interface StatDataProps {
  tooltipContent?: string | null;
  id: string;
  label: string;
  value: any;
}

export const useStatBoxes = (stats: { [key: string]: any }) => {
  const isPendingData = useMemo(
    () =>
      (stats &&
        !Object.values(stats).find(
          (stat) => typeof stat === "number" && stat !== 0,
        )) ||
      !stats,
    [stats],
  );

  const statData = useMemo(
    () =>
      stats &&
      Object.keys(stats)
        .slice(0, 4)
        .map((key) => {
          if (isPendingData)
            return {
              id: key,
              label: toNormalCase(key),
              value: "-",
            };
          const suffix = key === "averageLatency" ? "s" : "";

          let value = stats[key],
            tooltipContent = null;

          if (key === "totalCost")
            value = Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
            }).format(value);

          if (key === "totalTokens" || key === "totalRequests")
            value = value.toLocaleString();
          else if (key === "averageScore") {
            value = value as Record<string, number>;
            tooltipContent = Object.entries(value)
              .filter((score) => score[0] !== "default")
              .map(
                ([scoreName, scoreValue]) =>
                  `${scoreName}: ${Math.round(scoreValue as number)}`,
              )
              .join(", ");
            value = Math.round(Number(value.default));
          } else if (typeof value === "number") value = value.toFixed(2);

          return {
            id: key,
            label: toNormalCase(key),
            value: `${value || "-"}${suffix}`,
            tooltipContent,
          };
        }),
    [isPendingData, stats],
  );

  return (
    <div className="grid grid-cols-1 gap-4 lg:grid-cols-4">
      {statData?.map(({ id, label, value, tooltipContent }: StatDataProps) => {
        if (tooltipContent && id === "averageScore") {
          return (
            <TooltipPrimitive.Provider key={id} delayDuration={100}>
              <TooltipPrimitive.Root>
                <TooltipPrimitive.Trigger>
                  <StatBody label={label} id={id} value={value} />
                </TooltipPrimitive.Trigger>
                <TooltipPrimitive.Portal>
                  <TooltipPrimitive.Content>
                    <div className="max-h-36 max-w-lg overflow-scroll rounded-md bg-white p-8 shadow-md">
                      <div className="mb-2 border-b border-gray-200 pb-2 text-xs font-semibold uppercase tracking-wider text-gray-800">
                        Other average scores
                      </div>
                      {tooltipContent
                        .split(", ")
                        .map((score: string, index: number) => {
                          const [scoreName, scoreValue] = score.split(": ");
                          return (
                            <div
                              key={index}
                              className="font-mono text-sm text-gray-700"
                            >
                              {`${scoreName}: ${scoreValue}`}
                            </div>
                          );
                        })}
                    </div>
                  </TooltipPrimitive.Content>
                </TooltipPrimitive.Portal>
              </TooltipPrimitive.Root>
            </TooltipPrimitive.Provider>
          );
        }
        return <StatBody key={id} label={label} id={id} value={value} />;
      })}
    </div>
  );
};
