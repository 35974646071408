import styled from "styled-components";

export const Container = styled.div`
  background: #f2f5f5;
  padding: 90px 0;
`;

export const InnerContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 50px;
  justify-content: space-around;
  margin: 0 auto;
  width: 100%;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    flex-direction: column;
    gap: 0;
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1150px;
  }
`;

export const Text = styled.div`
  padding: 0 16px;
  width: 100%;

  h2 {
    color: #090b1c;
    font-size: 38px;
    font-weight: 200;
    letter-spacing: -0.02em;
    line-height: 1.2;
    margin: 0;
    width: 100%;
    text-align: center;

    @media (min-width: 767px) {
      font-size: 42px;
    }
  }

  @media (min-width: 992px) {
    max-width: 725px;
  }
`;
