import { FC, useState } from "react";
import Feature from "@/components/Home/Features/Feature";
import { Button } from "@/components/ui/button";
import CodeCopyBlock from "./CodeCopyBlock";
import BasicRequestExample from "./BasicRequestExample";
import { ApiKeyModal } from "@/components/ApiKeyModal";
import { Switch } from "@headlessui/react";

type InstallingFeatureProps = {
  handleCloseClick: () => void;
};

const InstallingFeature: FC<InstallingFeatureProps> = ({
  handleCloseClick,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState("OpenAI");

  return (
    <>
      <Feature onCloseClick={handleCloseClick}>
        <div className="grid grid-cols-3">
          <div className="col-span-1 ml-6">
            <h2 className="mt-2 text-2xl font-medium">
              Installing PromptLayer
            </h2>
            <p className="mt-2 text-base text-gray-500">
              PromptLayer is available through our{" "}
              <a
                className="text-blue-500 hover:text-blue-400"
                href="https://docs.promptlayer.com/languages/python"
              >
                Python SDK
              </a>
              ,{" "}
              <a
                className="text-blue-500 hover:text-blue-400"
                href="https://docs.promptlayer.com/languages/javascript"
              >
                JavaScript SDK
              </a>
              , or just our plain old{" "}
              <a
                className="text-blue-500 hover:text-blue-400"
                href="https://docs.promptlayer.com/reference/rest-api-reference"
              >
                REST API
              </a>
              . We are compatible with all major LLM providers, such as OpenAI,
              Anthropic, and Cohere.
              <br />
              <br />
              Attach scores, key-value metadata, or group associations to
              requests{" "}
              <a
                className="text-blue-500 hover:text-blue-400"
                href="https://docs.promptlayer.com/features/prompt-history/request-id"
              >
                using the request ID
              </a>
              .{" "}
            </p>
            <a
              href="https://docs.promptlayer.com/quickstart"
              rel="noreferrer"
              target="_blank"
            >
              <Button className="mt-4" variant="secondaryLightOutline">
                Read the Docs
              </Button>
            </a>
          </div>
          <div className="col-span-2 flex flex-col gap-y-4 py-4">
            <div className="mx-auto max-w-full border-b border-gray-400 px-8 pb-4">
              <Switch.Group as="div" className="space-x-4">
                <Switch.Label>OpenAI</Switch.Label>
                <Switch
                  checked={selectedOption === "Anthropic"}
                  onChange={() =>
                    setSelectedOption(
                      selectedOption === "Anthropic" ? "OpenAI" : "Anthropic",
                    )
                  }
                  className={`${
                    selectedOption === "Anthropic"
                      ? "bg-blue-500"
                      : "bg-blue-600"
                  } relative inline-flex h-6 w-11 items-center rounded-full`}
                >
                  <span className="sr-only">
                    Choose between OpenAI and Anthropic
                  </span>
                  <span
                    className={`${
                      selectedOption === "Anthropic"
                        ? "translate-x-6"
                        : "translate-x-1"
                    } inline-block h-4 w-4 transform rounded-full bg-white`}
                  />
                </Switch>
                <Switch.Label>Anthropic</Switch.Label>
              </Switch.Group>
            </div>
            <div className="mx-auto max-w-full">
              <CodeCopyBlock
                code={
                  selectedOption === "OpenAI"
                    ? `pip install promptlayer --upgrade\npip install openai --upgrade`
                    : `pip install promptlayer --upgrade\npip install anthropic --upgrade`
                }
              />
            </div>
            <div className="mx-auto max-w-full">
              <Button
                onClick={() => setShowModal(true)}
                variant={"outline"}
                size={"default"}
              >
                Generate New Api Key
              </Button>
            </div>
            <div className="w-full px-6">
              <BasicRequestExample selectedOption={selectedOption} />
            </div>
          </div>
        </div>
      </Feature>
      <ApiKeyModal showModal={showModal} setShowModal={setShowModal} />
    </>
  );
};

export default InstallingFeature;
