import {
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
} from "../ui/dialog";
import { Button } from "../ui/button";
import LoadingSpinner from "../LoadingSpinner";
import { PlusIcon, SearchIcon } from "@heroicons/react/outline";
import { useFineTunedModels } from "@/queries";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { fineTuneJobStatus } from "./fineTuneJobStatus";
import { useState } from "react";

export function FineTuneJobsList({
  navigateTrainNewModel,
  navigateJobInfoModel,
}: {
  navigateTrainNewModel: () => void;
  navigateJobInfoModel: (jobId: string) => void;
}) {
  const auth = useAuth();
  const userContext = useUser();
  const userToken = auth?.userToken || "";

  const [searchQuery, setSearchQuery] = useState("");

  const { data: models, isLoading } = useFineTunedModels(
    userToken,
    userContext?.activeWorkspaceId!,
  );

  const modelStatusElement = (modelStatus: string) => {
    const { color, icon, status } = fineTuneJobStatus(modelStatus);
    return (
      <>
        {icon}
        <span className={`${color} pl-1`}>{status}</span>
      </>
    );
  };

  const fineTuneModelsList = models?.fine_tuned_models
    ?.filter((model: any) =>
      (model.model_suffix || "")
        .toLowerCase()
        .includes(searchQuery.toLowerCase()),
    )
    ?.sort(
      (a: any, b: any) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
    );

  return (
    <>
      <DialogHeader>
        <DialogTitle>Fine-Tuning</DialogTitle>
        <DialogDescription>
          Fine-tuning allows you to train a model directly on your own data.
        </DialogDescription>
      </DialogHeader>
      <Button
        variant="default"
        className="mx-auto max-w-max"
        onClick={navigateTrainNewModel}
      >
        Train new Model
        <PlusIcon className="ml-1 h-4 w-4" />
      </Button>
      <div className="border-b pb-2">
        <div className="flex items-center">
          <div className="relative mt-3 flex-1 rounded-sm px-1">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <SearchIcon
                className="h-5 w-auto text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              type="text"
              name="search"
              id="search"
              className="block w-full rounded-sm border-0 border-transparent pl-10 focus:border-transparent focus:ring-0 sm:text-sm"
              placeholder="Search fine-tune jobs..."
            />
          </div>
        </div>
      </div>
      <div>
        {isLoading ? (
          <div className="text-center">
            <LoadingSpinner />
          </div>
        ) : (
          <div className="mt-3 grid max-h-[350px] grid-cols-2 gap-2 overflow-y-scroll">
            {fineTuneModelsList.map((model: any) => (
              <div
                className="shadow-xs cursor-pointer rounded-md border bg-white p-3 transition-shadow duration-100 hover:border-gray-300 hover:bg-gray-50 hover:shadow-sm"
                onClick={() => navigateJobInfoModel(model.id)}
                key={model.id}
              >
                <div className="flex flex-col">
                  <div className="flex w-full">
                    <div className="justify-left flex flex-1 text-xs text-gray-600">
                      <div className="flex flex-row items-center gap-y-1">
                        {modelStatusElement(model.status)}
                      </div>
                    </div>
                    <div className="flex justify-end">
                      <p className="text-xs font-light text-gray-500">
                        {new Date(model.created_at).toLocaleDateString()}
                      </p>
                    </div>
                  </div>
                  <h2 className="pt-3 text-sm font-medium text-gray-700">
                    <span className="pr-1">{model.model_suffix}</span>
                    <span className="text-xs font-light text-gray-500">
                      {model.base_model_name}
                    </span>
                  </h2>
                </div>
              </div>
            ))}
            {fineTuneModelsList.length === 0 && (
              <div className="text-center text-gray-500">
                No fine-tune jobs found.
              </div>
            )}
          </div>
        )}
      </div>
      <DialogFooter>
        {/* <Button onClick={() => console.log("export")}>Export</Button>
                <DialogTrigger> */}
        {/* This button closes the modal */}
        {/* <Button variant="secondary">Cancel</Button>
                </DialogTrigger> */}
      </DialogFooter>
    </>
  );
}
