import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ModelConfig, ModelConfigMap, modelConfigs } from "@/modelConfig";
import { openai } from "@/schemas";
import {
  ChatFunctionCall,
  FunctionsType,
  Message,
  ChatTemplate as TChatTemplate,
  TemplateFormat,
} from "@/types";
import { InputVariableRow } from "@/types/playground";
import { ChatTemplate } from "@/types/prompt-blueprint";
import {
  copyTextToClipboard,
  getSavedTemplateFormatOrDefault,
  getStringContent,
  setSavedTemplateFormat,
} from "@/utils/utils";
import { Disclosure } from "@headlessui/react";
import {
  ChevronUpIcon,
  ExclamationCircleIcon,
  ExclamationIcon,
  PlusIcon,
} from "@heroicons/react/solid";
import React, { useCallback, useEffect, useMemo } from "react";
import { FunctionDialog } from "../FunctionsModal/function-dialog";
import InputVariableDialog from "../InputVariableDialog";
import { FUNCTION_MODEL_PARAMS } from "../ModelProviderSelection/CustomParameters";
import ButtonCopy from "../button-copy";
import ButtonMaximize from "../button-maximize";
import { Dialog, DialogContent, DialogTrigger } from "../ui/dialog";
import ExitPageConfirmation from "../ui/exit-page-confirmation";
import { AdvancedControlsSection } from "./AdvancedControlsSection";
import ChatMessageEditor from "./ChatMessageEditor";
import { TemplateTypeSwitch } from "./TemplateTypeSwitch";
import {
  ModelSelection,
  PlaygroundConfig,
  PromptTemplateParams,
  PromptTemplateType,
  TEMPLATE_FORMATS,
} from "./types";

export type PromptTemplateComposerProps = {
  config: PlaygroundConfig;
  errorMessage?: string;
  inputVariableParser: (
    promptData: string | TChatTemplate,
    templateFormat: TemplateFormat,
  ) => void;
  inputVariableRows: InputVariableRow[];
  invalidPromptIndex: number | null;
  invalidPromptTemplate: boolean;
  modelOptions: ModelConfigMap;
  parsedInputVariables: string[];
  patchConfig: (patch: Partial<PlaygroundConfig>) => void;
  runRequestButton: React.ReactNode;
  setInputVariableRows: (inputVariableRows: InputVariableRow[]) => void;
  showTemplateTypeSwitch?: boolean;
  submitAction?: () => void;
  userSubmitted?: boolean;
  warningMessage?: string;
};

export const PromptTemplateComposer = ({
  config: initialConfig,
  errorMessage,
  inputVariableParser,
  inputVariableRows,
  invalidPromptTemplate,
  modelOptions,
  parsedInputVariables,
  patchConfig: initialPatchConfig,
  runRequestButton,
  setInputVariableRows,
  showTemplateTypeSwitch = true,
  submitAction,
  userSubmitted,
  warningMessage,
}: PromptTemplateComposerProps) => {
  const variables = useMemo(
    () => parsedInputVariables || [],
    [parsedInputVariables],
  );

  // Chat-type config
  const config = React.useMemo(
    () =>
      initialConfig.type === "chat"
        ? completionToChatConfig(initialConfig)
        : initialConfig,
    [initialConfig],
  );

  const handleParseInputVariables = useCallback(() => {
    const templateFormat = initialConfig.templateFormat as TemplateFormat;

    if (initialConfig.type === "chat") {
      const updatedMessages = initialConfig.messages.map((message) => {
        message.template_format = templateFormat;
        return message;
      });

      const chatTemplate: ChatTemplate = {
        type: "chat",
        input_variables: variables,
        messages: updatedMessages,
      };

      inputVariableParser(chatTemplate, templateFormat);
    } else {
      inputVariableParser(initialConfig.template, templateFormat);
    }
  }, [
    initialConfig.messages,
    initialConfig.template,
    initialConfig.templateFormat,
    initialConfig.type,
    inputVariableParser,
    variables,
  ]);

  useEffect(() => {
    const debounceHandleParseInputVariables = setTimeout(() => {
      handleParseInputVariables();
    }, 500);

    return () => clearTimeout(debounceHandleParseInputVariables);
  }, [handleParseInputVariables]);

  // Completion-type config
  const patchConfig = React.useCallback(
    (patch: Partial<PlaygroundConfig>) =>
      initialConfig.type === "chat"
        ? initialPatchConfig(chatToCompletionConfig({ ...config, ...patch }))
        : initialPatchConfig(patch),
    [config, initialConfig.type, initialPatchConfig],
  );

  const [isEditing, setIsEditing] = React.useState(false);

  const updateConfig = React.useCallback(
    (property: keyof PlaygroundConfig) => (value: any) =>
      patchConfig({ [property]: value } as Partial<PlaygroundConfig>),
    [patchConfig],
  );

  const updateModel = React.useCallback(
    (model: ModelSelection) => {
      patchConfig({
        model,
        params: getModelParams(config.params, config.type, model, true),
      } as Partial<PlaygroundConfig>);
    },
    [patchConfig, config.params, config.type],
  );

  // A centralized and memoized function to update the values of our patchConfig
  // By defining a centralized function, we eliminate the need for copious amounts of useState
  const updateConfigWithEvent = React.useCallback(
    (property: keyof PlaygroundConfig) => (event: any) =>
      patchConfig({
        [property]: event.currentTarget.value,
      } as Partial<PlaygroundConfig>),
    [patchConfig],
  );

  const deleteMessage = React.useCallback(
    (index: number) => () => {
      const messages = config.messages.filter((_, i) => i !== index);
      patchConfig({ messages });
    },
    [config.messages, patchConfig],
  );

  const updateKeyInMessage = React.useCallback(
    (index: number) => (updateFunc: (message: Message) => Message) => {
      const messages = [...config.messages];
      messages[index] = updateFunc(messages[index]);
      patchConfig({ messages });
    },
    [config.messages, patchConfig],
  );

  const toggleConfigType = React.useCallback(() => {
    if (config.type === "chat") {
      // Select "completion" type and set default model
      initialPatchConfig({
        type: "completion",
        model: ["openai", "gpt-3.5-turbo-instruct"],
      } as Partial<PlaygroundConfig>);
    } else {
      // Select "chat" type
      initialPatchConfig({
        type: "chat",
        model: ["openai", "gpt-3.5-turbo"],
      } as Partial<PlaygroundConfig>);
    }
  }, [config, initialPatchConfig]);

  const setFunctions = React.useCallback(
    (functions: openai._Function[]) => {
      // @ts-ignore
      patchConfig({ params: { ...config.params, functions } });
    },
    [config.params, patchConfig],
  );

  const setFunctionCall = React.useCallback(
    (functionCall: ChatFunctionCall) => {
      patchConfig({
        // @ts-ignore
        params: { ...config.params, function_call: functionCall },
      });
    },
    [config.params, patchConfig],
  );

  const upsertFunction = React.useCallback(
    (fn: openai._Function) => {
      const existingFunctions =
        config.params.functions ?? ([] as openai._Function[]);
      const existingFunctionIndex = existingFunctions.findIndex(
        (func) => func.name === fn.name,
      );
      if (existingFunctionIndex >= 0) {
        existingFunctions[existingFunctionIndex] = fn;
        config.params.functions = existingFunctions;
      } else {
        config.params.functions = [...existingFunctions, fn];
      }
      patchConfig({ params: config.params });
    },
    [config.params, patchConfig],
  );

  const selectedModelConfig = React.useMemo(() => {
    const modelProviderKey = config.model[0] ?? "openai";
    const modelKey =
      config.model[1] ??
      (config.type === "chat" ? "gpt-3.5-turbo" : "gpt-3.5-turbo-instruct");

    let modelProvider;
    if (modelOptions.hasOwnProperty(modelProviderKey)) {
      modelProvider = modelOptions[modelProviderKey];
    } else {
      modelProvider = modelOptions["openai"];
    }

    let modelConfig;
    if (modelProvider.hasOwnProperty(modelKey)) {
      modelConfig = modelProvider[modelKey];
    } else {
      if (config.type === "chat") {
        modelConfig = modelOptions["openai"]["gpt-3.5-turbo"];
      } else {
        modelConfig = modelOptions["openai"]["gpt-3.5-turbo-instruct"];
      }
    }
    return modelConfig;
  }, [config.model, config.type, modelOptions]);

  const onKeyDownCommandEnterListener = (event: any) => {
    if (event.key === "Enter" && (event.metaKey || event.ctrlKey)) {
      if (submitAction) {
        event.preventDefault();
        submitAction();
      }
    }
  };

  const prevValues = React.useRef({ config });

  React.useEffect(() => {
    if (!isEditing && prevValues.current.config !== config) {
      setIsEditing(true);
      prevValues.current = { config };
    }
  }, [config, isEditing, setIsEditing]);

  const renderBottomBar = () => {
    return (
      <div className="flex justify-between border px-4 py-2">
        {invalidPromptTemplate ? (
          <div className="flex items-center space-x-1">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
            <span className="text-red-500">Variable Parsing Error</span>
          </div>
        ) : (
          <InputVariableDialog
            inputVariableRows={inputVariableRows}
            setInputVariableRows={setInputVariableRows}
            variables={variables}
          />
        )}

        <div className="flex space-x-2">
          {config.type === "chat" && (
            <FunctionDialog
              onSubmit={upsertFunction}
              functions={
                config.params.functions?.map((f, i) => ({
                  ...f,
                  id: `function_${i}`,
                })) ?? []
              }
              functionCall={config.params.function_call ?? "auto"}
              setFunctions={setFunctions}
              setFunctionCall={setFunctionCall}
              functionsType={config.functionsType}
              setFunctionsType={(functionsType) => {
                patchConfig({ functionsType });
              }}
            />
          )}
          {runRequestButton}
        </div>
      </div>
    );
  };

  return (
    <div className="flex h-5/6 flex-1 flex-col">
      {showTemplateTypeSwitch && (
        <TemplateTypeSwitch onChange={toggleConfigType} value={config.type} />
      )}
      {/* Warning Message */}
      {warningMessage && (
        <div
          className="relative mx-auto mb-4 max-w-xl rounded border border-yellow-400 px-2 py-2 text-center text-yellow-700"
          role="alert"
        >
          <strong className="pr-2 font-bold">Warning!</strong>
          <span className="block sm:inline">{warningMessage}</span>
        </div>
      )}
      <div className="flex flex-1 flex-col rounded-lg border border-gray-300 shadow-sm">
        {/* Row: Template Format */}
        <div className="flex justify-end border-b">
          <DropdownMenu>
            <DropdownMenuTrigger className="border-0 text-sm focus:ring-0">
              {config.templateFormat}
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              {TEMPLATE_FORMATS.map((format) => (
                <DropdownMenuItem
                  className={`w-full text-center ${
                    config.templateFormat === format ? "bg-gray-100" : ""
                  }`}
                  key={format}
                  onSelect={(e) => {
                    const syntheticEvent = {
                      currentTarget: { value: format },
                    };
                    updateConfigWithEvent("templateFormat")(syntheticEvent);
                    setSavedTemplateFormat(
                      (e.target as HTMLDivElement).innerText as TemplateFormat,
                    );
                  }}
                >
                  {format}
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>

        {/* Row: Template Text */}
        <div className={`flex flex-1 p-2`}>
          {config.type === "chat" ? (
            <div className="flex flex-1">
              <div className="flex flex-1 flex-col gap-2 border p-2">
                <div className="flex flex-row justify-between">
                  <div className="text-sm font-bold">SYSTEM</div>
                  <Dialog>
                    <DialogTrigger asChild>
                      <ButtonMaximize />
                    </DialogTrigger>
                    <DialogContent className="flex h-full max-w-full flex-col">
                      <div className="text-sm font-bold">SYSTEM</div>
                      <textarea
                        placeholder="You are a helpful assistant"
                        value={config.template}
                        onChange={updateConfigWithEvent("template")}
                        className="h-full rounded-none text-sm disabled:cursor-auto disabled:opacity-100 "
                      />
                    </DialogContent>
                  </Dialog>
                </div>
                <textarea
                  placeholder="You are a helpful assistant"
                  value={config.template}
                  onChange={updateConfigWithEvent("template")}
                  className="flex min-h-[300px] flex-1 resize-none border-0 p-0 text-sm placeholder-gray-500 focus:ring-0"
                />
                <div className="flex justify-end">
                  <ButtonCopy
                    onClick={async () =>
                      await copyTextToClipboard(config.template)
                    }
                  />
                </div>
              </div>
              <div className="ml-4 flex flex-1 flex-col items-start">
                {config.messages.map((message, index) => (
                  <ChatMessageEditor
                    key={`message_${index}`}
                    message={message}
                    messageKey={`message_${index}`}
                    onKeyDown={onKeyDownCommandEnterListener}
                    onPressDelete={deleteMessage(index)}
                    updateKeyInMessage={updateKeyInMessage(index)}
                  />
                ))}
                <button
                  className="flex items-center p-4 hover:text-blue-500"
                  type="button"
                  onClick={() =>
                    patchConfig({
                      messages: [
                        ...config.messages,
                        {
                          role: "user",
                          content: [{ type: "text", text: "" }],
                          input_variables: [],
                          template_format: "f-string",
                        },
                      ],
                    })
                  }
                >
                  <PlusIcon className="mr-1 h-4 w-4" />
                  <span className="text-sm">New Message</span>
                </button>
              </div>
            </div>
          ) : (
            <textarea
              placeholder="Write a prompt..."
              onKeyDown={onKeyDownCommandEnterListener}
              value={config.template}
              onChange={updateConfigWithEvent("template")}
              className="flex min-h-[300px] flex-1 resize-none border-0 py-0 placeholder-gray-500 focus:ring-0"
            />
          )}
        </div>

        {/* Row: Collapsible Advanced Options */}
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex flex-row items-end justify-end px-4 py-2">
                <span className="text-sm text-blue-500">
                  Show advanced controls
                </span>
                <ChevronUpIcon
                  className={`${
                    open ? "rotate-180 transform" : ""
                  } h-5 w-5 text-blue-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="bg-gray-50 px-4 py-3">
                <AdvancedControlsSection
                  config={config}
                  updateConfig={updateConfig}
                  modelConfigs={modelConfigs}
                  updateModel={updateModel}
                  selectedModelConfig={selectedModelConfig}
                />
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        {/* Row: Bottom Bar */}
        {renderBottomBar()}

        {/* Error Message */}
        {errorMessage && (
          <div className="px-4 py-3 text-center text-red-700" role="alert">
            <div className="mx-auto mt-2 w-3/4 text-left text-sm text-red-700">
              <div className="whitespace-pre-wrap break-words rounded border border-red-200 bg-red-100 p-2 font-mono">
                <span className="pr-2 font-medium">
                  <ExclamationIcon className="mr-1 inline-block h-4 w-4" />
                  Error -
                </span>
                {errorMessage}
              </div>
            </div>
          </div>
        )}
      </div>
      <ExitPageConfirmation
        when={isEditing && !userSubmitted}
        execute={() => setIsEditing(false)}
        message="Are you sure you want to leave this page? All your progress will be lost."
      />
    </div>
  );
};

export const EMPTY_CONFIG: PlaygroundConfig = {
  functionsType: "functions",
  messages: [],
  model: ["openai", "gpt-3.5-turbo"],
  params: getModelDefaultParams(modelConfigs.openai["gpt-3.5-turbo"]),
  provider_base_url_name: null,
  template: "",
  templateFormat: getSavedTemplateFormatOrDefault(),
  type: "chat",
};

function getModelDefaultParams(
  modelConfig: ModelConfig,
): Record<string, number> {
  return Object.keys(modelConfig.params).reduce(
    (acc, key) => ({
      ...acc,
      [key]: modelConfig.params[key].default,
    }),
    {},
  );
}

export function getModelParams(
  existingParams: PromptTemplateParams,
  type: PromptTemplateType,
  model: ModelSelection,
  removeCustomParams?: boolean,
  functionsType: FunctionsType = "functions",
): Record<string, any> {
  let params: Record<string, any> = existingParams;

  // Add required parameters if they aren't included
  if (model[0] in modelConfigs && model[1] in modelConfigs[model[0]]) {
    const paramConfigs = modelConfigs[model[0]][model[1]].params;
    Object.keys(paramConfigs).forEach((key) => {
      if (paramConfigs[key].required && !(key in params)) {
        params[key] = paramConfigs[key].default;
      }
      if (paramConfigs[key]?.isJSON && params[key]) {
        try {
          params[key] = JSON.parse(params[key]);
        } catch (error) {
          console.error(`Error parsing JSON for key ${key}:`, error);
        }
      }
    });

    // Remove custom params when set
    if (removeCustomParams) {
      Object.keys(params).forEach((key) => {
        if (!(key in paramConfigs || FUNCTION_MODEL_PARAMS.includes(key))) {
          delete params[key];
        }
      });
    }
  } else {
    if (removeCustomParams) {
      // If the model is unknown, remove all params when set
      params = {};
    }
  }

  // Include `functions` and `function_call` only if type === 'chat'
  if (
    type === "chat" &&
    existingParams.functions &&
    existingParams.functions.length > 0
  ) {
    const tool_choice =
      functionsType === "tools"
        ? existingParams.function_call
          ? typeof existingParams.function_call === "string"
            ? existingParams.function_call
            : { type: "function", function: existingParams.function_call }
          : "auto"
        : undefined;
    params = {
      ...params,
      functions:
        functionsType === "functions" ? existingParams.functions : undefined,
      function_call:
        functionsType === "functions"
          ? existingParams.function_call
          : undefined,
      tools:
        functionsType === "tools"
          ? existingParams.functions.map((f) => ({
              type: "function",
              function: f,
            }))
          : undefined,
      tool_choice,
    };
  } else {
    if ("functions" in params) {
      delete params.functions;
    }
    if ("function_call" in params) {
      delete params.function_call;
    }
  }

  return params;
}

export const INITIAL_ERROR_STATE: string = "";

function completionToChatConfig(config: PlaygroundConfig): PlaygroundConfig {
  let template = "";
  if (config.messages.length === 0) {
    return {
      ...config,
      messages: config.messages,
      template,
    };
  }

  // check if first message is a system message
  // if it is, convert it to the template,
  // otherwise, pass in an empty string to the template and add the system message to the messages array
  const [systemMessage, ...messages] = config.messages;

  if (systemMessage?.role !== "system") {
    messages.unshift(systemMessage);
  } else {
    template = getStringContent(systemMessage);
  }

  return {
    ...config,
    messages,
    template,
  };
}

function chatToCompletionConfig(config: PlaygroundConfig): PlaygroundConfig {
  const messages: Message[] = [
    {
      role: "system",
      content: [{ type: "text", text: config.template }],
      input_variables: [],
      template_format: "f-string",
    },
    ...config.messages,
  ];
  return {
    ...config,
    template: "",
    messages,
  };
}
