import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  setDelete: () => void;
  labelName: string;
};

export const DeleteVersionLabel = (props: Props) => (
  <Dialog open={props.open} onOpenChange={props.setOpen}>
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Are you sure you want to remove this label?</DialogTitle>
      </DialogHeader>
      <DialogDescription>
        Release label "
        <span className="font-mono font-semibold">{`${props.labelName}`}</span>"
        will be removed.
      </DialogDescription>
      <DeleteVersionLabelForm
        {...props}
        onSuccess={() => props.setOpen(false)}
      />
    </DialogContent>
  </Dialog>
);

const DeleteVersionLabelForm = (
  props: Props & {
    onSuccess: () => void;
  },
) => {
  const onSubmit = async () => {
    props.setDelete();
    props.onSuccess();
  };

  return (
    <>
      <DialogFooter className="mt-5">
        <Button
          type="button"
          onClick={() => props.setOpen(false)}
          variant="outline"
        >
          Cancel
        </Button>
        <Button type="submit" onClick={onSubmit} variant="destructive">
          Remove Label
        </Button>
      </DialogFooter>
    </>
  );
};
