import styled from "styled-components";

export const TextLink = styled.span`
  color: #5167f0;
  text-decoration: none;
  transition: color 0.2s ease;
  font-size: 16px;

  @media (min-width: 576px) {
    font-size: 18px;
  }

  &:hover {
    color: #6b7ef5;
  }
`;

export const Container = styled.div`
  background: #090b1c;
  padding: 0 0 100px 0;

  @media (max-width: 575px) {
    padding: 60px 0;
  }
`;

export const InnerContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column-reverse;
  gap: 50px;
  justify-content: space-around;
  margin: 0 16px;
  max-width: 100%;

  @media (min-width: 576px) {
    max-width: 540px;
    margin: 0 auto;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    flex-direction: row;
    gap: 0;
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1150px;
  }
`;

export const Content = styled.div`
  width: 100%;
  padding: 0 24px;

  @media (max-width: 575px) {
    padding: 0;
  }
`;

export const Card = styled.div<{ backgroundImage: string }>`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  padding: 48px 64px;
  box-shadow:
    0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  gap: 48px;
  background-image: url(${(props) => props.backgroundImage});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  max-width: 900px;
  margin: 0 auto;

  @media (max-width: 575px) {
    gap: 24px;
    padding: 32px 24px;

    background-image: none;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 32rem;
  padding-bottom: 16px;

  h2 {
    font-size: 2.25rem;
    font-weight: 500;
    padding-bottom: 8px;
    line-height: 1.2;
  }

  p {
    font-size: 1rem;
  }

  @media (max-width: 575px) {
    h2 {
      font-size: 1.75rem;
    }
  }
`;

export const LogoContent = styled.div`
  flex-shrink: 0;
  margin-left: auto;

  @media (max-width: 768px) {
    margin-right: auto;
  }
`;

export const LogoWrapper = styled.div`
  width: 180px;
  display: flex;
  justify-content: center;

  img {
    width: 180px;
    height: auto;
  }
`;

export const LogoText = styled.div`
  font-size: 1.125rem;
  font-weight: 600;
  text-align: center;
  padding-top: 16px;
`;
