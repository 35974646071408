export const authHeader = (userToken: string) => {
  return {
    Authorization: `Bearer ${userToken}`,
  };
};

export const jsonAuthHeaders = (userToken: string) => {
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${userToken}`,
  };
};
