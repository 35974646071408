import openAiLogo from "@/assets/openai.svg";
import { useAuth } from "@/context/auth-context";
import { useFineTunedModelInfo } from "@/queries";
import { ArrowLeftIcon, ExclamationCircleIcon } from "@heroicons/react/outline";
import LoadingSpinner from "../LoadingSpinner";
import UrlSearchParamsDisplay from "../UrlSearchParamsDisplay";
import { Button } from "../ui/button";
import { CopyButton } from "../ui/copy-button";
import {
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import { fineTuneJobStatus } from "./fineTuneJobStatus";

export function FineTuneJobInfo({
  navigateFineTuneHome,
  jobId,
}: {
  navigateFineTuneHome: () => void;
  jobId: string;
}) {
  const authContext = useAuth();
  const userToken = authContext?.userToken || "";

  const { data: jobInfo, isLoading } = useFineTunedModelInfo(userToken, jobId);

  if (isLoading) return <LoadingSpinner />;

  if (!jobInfo) return null;

  const openAiDashboardLink = jobInfo.openai_fine_tuned_job_id
    ? `https://platform.openai.com/finetune/${jobInfo.openai_fine_tuned_job_id}?filter=all`
    : null;

  const { color, icon, status } = fineTuneJobStatus(jobInfo?.status || "");

  return (
    <>
      <DialogHeader>
        <DialogTitle>Fine Tuning Job</DialogTitle>
        <DialogDescription>
          Information about your fine tuning job.
        </DialogDescription>
      </DialogHeader>
      {openAiDashboardLink && (
        <div className="text-center">
          <Button
            variant="secondaryOutline"
            onClick={() => window.open(openAiDashboardLink, "_blank")}
          >
            <img src={openAiLogo} alt="OpenAI Logo" className="mr-2 h-4 w-4" />
            View in OpenAI Dashboard
          </Button>
        </div>
      )}
      <div>
        {status !== "failed" && (
          <div className="mb-4 flex items-center justify-center">
            {icon}
            <span className={`font-semibold ${color} pl-2`}>{status}</span>
          </div>
        )}

        {jobInfo.name && (
          <div className="flex flex-col">
            <div className="mx-3 mx-auto flex max-w-[400px] rounded-md border border-gray-300 bg-white px-4 py-2 font-mono text-gray-800">
              <div className="w-full overflow-hidden truncate whitespace-nowrap border-0 pr-1 text-sm">
                {jobInfo.name}
              </div>
              <CopyButton text={jobInfo.name} />
            </div>
            <small className="text-center text-gray-500">
              Fine-Tuned Model Name
            </small>
          </div>
        )}

        {jobInfo.error_message && (
          <div className="flex flex-row items-start justify-center rounded-md border border-red-100 bg-red-50 p-2">
            <ExclamationCircleIcon className="mx-2 mt-1 h-5 w-5 flex-shrink-0 text-red-500" />
            <p className="overflow-auto text-left font-mono text-xs text-red-500">
              {jobInfo.error_message}
            </p>
          </div>
        )}
        <div className="mt-4 py-4">
          <h2 className="pb-2 text-base font-bold text-gray-700">
            Fine-Tune Details
          </h2>
          <div className="grid grid-cols-4 gap-2 text-sm text-gray-500">
            <div className="col-span-1">
              <span className="font-semibold">Base model:</span>
            </div>
            <div className="col-span-3 font-mono text-gray-700">
              {jobInfo.base_model_name}
            </div>
            <div className="col-span-1">
              <span className="font-semibold">Model suffix:</span>
            </div>
            <div className="col-span-3 font-mono text-gray-700">
              {jobInfo.model_suffix || (
                <span className="italic text-gray-300">None</span>
              )}
            </div>

            <div className="col-span-1">
              <span className="font-semibold">Created at:</span>
            </div>
            <div className="col-span-3 text-gray-700">{jobInfo.created_at}</div>

            {status !== "queued" && (
              <>
                <div className="col-span-1">
                  <span className="font-semibold">Trained on:</span>
                </div>
                <div className="col-span-3 text-gray-700">
                  {jobInfo.training_requests_count} requests
                </div>
              </>
            )}
          </div>
        </div>
        <UrlSearchParamsDisplay
          title={"Training Search Filters"}
          tags_and={jobInfo?.request_query_params?.tags_and}
          metadata_and={jobInfo?.request_query_params?.metadata_and}
          starred={jobInfo?.request_query_params?.starred}
          q={jobInfo?.request_query_params?.q}
          scores={jobInfo?.request_query_params?.scores || []}
          start_time={jobInfo?.request_query_params?.start_time}
          end_time={jobInfo?.request_query_params?.end_time}
          limit={jobInfo?.request_query_params?.limit}
          prompt_template={jobInfo?.request_query_params?.prompt_template}
        />
      </div>
      <DialogFooter>
        <div className="justify-left flex w-full">
          <Button variant="secondary" onClick={navigateFineTuneHome}>
            <ArrowLeftIcon className="mr-1 h-4 w-4" />
            Back
          </Button>
        </div>
      </DialogFooter>
    </>
  );
}
