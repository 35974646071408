import { CreateCommitMessage, PromptParameters } from "@/components";
import ChatTemplate from "@/components/ChatTemplate";
import { MetadataDialog } from "@/components/metadata-dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useAuth } from "@/context/auth-context";
import { useGetReport, usePromptVersions } from "@/queries";
import {
  FunctionsType,
  Message,
  Model,
  ChatTemplate as TChatTemplate,
  TemplateFormat,
  templateFormats,
} from "@/types";
import { PromptVersion } from "@/types/apiGetters";
import { Report } from "@/types/evaluate";
import { PromptRegistry } from "@/types/prompt-registry";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import { Button } from "../../components/ui/button";
import { useUser } from "../../context/user-context";
import {
  getFunctionsType,
  getSavedTemplateFormatOrDefault,
  getStringContent,
  getSystemMessage,
  getTemplateFormat,
  setSavedTemplateFormat,
} from "../../utils/utils";
import { FunctionDialog } from "../FunctionsModal/function-dialog";
import { DefineInputVariablesMessage } from "../define-input-variables";
import ExitPageConfirmation from "../ui/exit-page-confirmation";
import { ChooseSnippetTemplateModal } from "./ChooseSnippetTemplateModal";

type Mode = "completion" | "chat";

type PromptTemplateEditorProps = {
  editMode: boolean;
  errorMessage: string | null;
  loadingCreate: boolean;
  mode: Mode;
  setError: React.Dispatch<React.SetStateAction<string | null>>;
  startingPromptTemplate?: PromptRegistry;
  startingPromptTemplateVersion?: PromptVersion;
  submitTemplate: (
    name: string,
    prompt: string | TChatTemplate,
    inputVariables: string[],
    metadata: { [key: string]: any },
    templateFormat: TemplateFormat,
    commit_message: string,
    source_report_id: number | null,
    model: Model | null,
    provider_base_url_name: string | null,
  ) => void;
  inputVariableParser: (
    promptData: string | TChatTemplate,
    templateFormat: TemplateFormat,
  ) => void;
  parsedInputVariables: string[];
  invalidPromptTemplate: boolean;
  invalidPromptMessage: string;
  invalidPromptIndex: number | null;
  promptVersion: PromptVersion | null;
  newPromptVersionForm: boolean;
  setNewPromptVersionForm: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function PromptTemplateEditor({
  editMode,
  errorMessage,
  loadingCreate,
  mode,
  setError,
  startingPromptTemplate,
  startingPromptTemplateVersion,
  submitTemplate,
  inputVariableParser,
  parsedInputVariables,
  invalidPromptTemplate,
  invalidPromptMessage,
  invalidPromptIndex,
  promptVersion,
  newPromptVersionForm,
  setNewPromptVersionForm,
}: PromptTemplateEditorProps) {
  const isChat = mode === "chat";
  const navigate = useNavigate();
  const userContext = useUser();
  const [metadata, setMetadata] = useState<{ [key: string]: any }>(
    Object.fromEntries(
      Object.entries(startingPromptTemplateVersion?.metadata || {}).filter(
        ([key]) => key !== "model",
      ),
    ),
  );

  const authContext = useAuth();
  const [isEditing, setIsEditing] = useState(false);

  const [messageTemplates, setMessageTemplates] = useState<Array<Message>>(
    isChat
      ? startingPromptTemplateVersion?.prompt_template?.type === "chat"
        ? startingPromptTemplateVersion?.prompt_template?.messages.slice(1)
        : []
      : [],
  );

  const startingTemplateFormat: TemplateFormat = useMemo(
    () => getSavedTemplateFormatOrDefault(),
    [],
  );

  const [templateFormat, setTemplateFormat] = useState<TemplateFormat>(
    startingPromptTemplateVersion
      ? getTemplateFormat(startingPromptTemplateVersion?.prompt_template)
      : startingTemplateFormat,
  );

  const [functionsType, setFunctionsType] = useState<FunctionsType>(
    startingPromptTemplateVersion
      ? startingPromptTemplateVersion?.prompt_template.type === "chat"
        ? getFunctionsType(startingPromptTemplateVersion.prompt_template)
        : "functions"
      : "functions",
  );
  const [functions, setFunctions] = useState(
    (startingPromptTemplateVersion
      ? startingPromptTemplateVersion.prompt_template.type === "chat"
        ? startingPromptTemplateVersion?.prompt_template.functions ||
          startingPromptTemplateVersion?.prompt_template.tools?.map((tool) => ({
            ...tool.function,
          })) ||
          []
        : []
      : []
    ).map((f, index) => ({ ...f, id: `function-${index}` })),
  );
  const [functionCall, setFunctionCall] = useState(
    startingPromptTemplateVersion
      ? startingPromptTemplateVersion?.prompt_template.type === "chat"
        ? startingPromptTemplateVersion.prompt_template.function_call
          ? startingPromptTemplateVersion.prompt_template.function_call
          : startingPromptTemplateVersion.prompt_template.tool_choice
          ? typeof startingPromptTemplateVersion.prompt_template.tool_choice ===
            "string"
            ? startingPromptTemplateVersion.prompt_template.tool_choice
            : startingPromptTemplateVersion.prompt_template.tool_choice.function
          : "none"
        : "none"
      : "none",
  );
  const [commitMessage, setCommitMessage] = useState("");

  const promptTemplateVersionsQuery = usePromptVersions(
    authContext!.userToken!,
    {
      promptRegistryId: startingPromptTemplate?.id,
      workspaceId: userContext.activeWorkspaceId!,
      perPage: Number.MAX_SAFE_INTEGER,
    },
  );
  const promptTemplateVersions = startingPromptTemplate?.id
    ? promptTemplateVersionsQuery.data?.pages.flatMap((page) => page.items) ||
      []
    : [];
  const previousReportId =
    promptTemplateVersions.find((version) => version.report_id != null)
      ?.report_id || null;

  const { data: reportMetadata } = useGetReport(
    authContext!.userToken!,
    previousReportId || null,
  ) as {
    data: Report | null;
    isLoading: boolean;
  };
  const previousBlueprintId = reportMetadata?.parent_report_id;
  const [sourceReportId, setSourceReportId] = useState<number | null>(
    previousBlueprintId || null,
  );
  const [model, setModel] = useState<Model | null>(
    startingPromptTemplateVersion?.metadata?.model
      ? {
          provider: startingPromptTemplateVersion.metadata.model.provider,
          name: startingPromptTemplateVersion.metadata.model.name,
          parameters: startingPromptTemplateVersion.metadata.model.parameters,
        }
      : null,
  );
  const [openTemplateLink, setOpenTemplateLink] = useState(false);
  let startingTemplate = "";
  if (startingPromptTemplateVersion?.prompt_template.type === "chat") {
    const systemMessage = getSystemMessage(
      startingPromptTemplateVersion.prompt_template,
    );
    if (systemMessage) {
      startingTemplate = getStringContent(systemMessage);
    }
  } else {
    if (startingPromptTemplateVersion?.prompt_template) {
      startingTemplate = getStringContent(
        startingPromptTemplateVersion.prompt_template,
      );
    }
  }
  const [title, setTitle] = useState(startingPromptTemplate?.prompt_name ?? "");
  const [template, setTemplate] = useState(startingTemplate);

  const [selectedProviderBaseURLName, setSelectedProviderBaseURLName] =
    useState<string | null>(
      startingPromptTemplateVersion?.provider_base_url_name || null,
    );

  const variables = useMemo(
    () => parsedInputVariables || [],
    [parsedInputVariables],
  );

  useEffect(() => {
    setSourceReportId(previousBlueprintId || null);
  }, [previousBlueprintId]);

  useEffect(() => {
    setMessageTemplates((messageTemplates) =>
      messageTemplates.map((messageTemplate) => {
        messageTemplate.template_format = templateFormat;
        return messageTemplate;
      }),
    );
  }, [setMessageTemplates, templateFormat]);

  const createChatPromptTemplate = useMemo((): TChatTemplate => {
    return {
      type: "chat",
      input_variables: variables,
      function_call: functionsType === "functions" ? functionCall : undefined,
      messages: [
        {
          role: "system",
          content: [{ type: "text", text: template }],
          template_format: templateFormat,
        },
        ...messageTemplates,
      ],
      functions: functionsType === "functions" ? functions : undefined,
      tools:
        functionsType === "tools"
          ? functions.map((f) => ({ type: "function", function: f }))
          : undefined,
      tool_choice:
        functionsType === "tools"
          ? typeof functionCall === "string"
            ? functionCall
            : {
                type: "function",
                function: functionCall,
              }
          : undefined,
    };
  }, [
    variables,
    functionCall,
    functions,
    messageTemplates,
    template,
    templateFormat,
    functionsType,
  ]);

  const handleCreateTemplate = useCallback(() => {
    if (isChat) {
      return submitTemplate(
        title,
        createChatPromptTemplate,
        variables,
        metadata,
        templateFormat,
        commitMessage,
        sourceReportId,
        model,
        selectedProviderBaseURLName,
      );
    } else {
      return submitTemplate(
        title,
        template,
        variables,
        metadata,
        templateFormat,
        commitMessage,
        sourceReportId,
        model,
        selectedProviderBaseURLName,
      );
    }
  }, [
    isChat,
    createChatPromptTemplate,
    metadata,
    template,
    templateFormat,
    title,
    commitMessage,
    sourceReportId,
    submitTemplate,
    model,
    variables,
    selectedProviderBaseURLName,
  ]);

  const handleParseInputVariables = useCallback(() => {
    if (isChat) {
      inputVariableParser(createChatPromptTemplate, templateFormat);
    } else {
      inputVariableParser(template, templateFormat);
    }
  }, [
    isChat,
    template,
    templateFormat,
    createChatPromptTemplate,
    inputVariableParser,
  ]);

  useEffect(() => {
    // Debounce setup to avoid calling the function too frequently
    const debounceHandleParseInputVariables = setTimeout(() => {
      handleParseInputVariables();
    }, 500);

    return () => clearTimeout(debounceHandleParseInputVariables);
  }, [
    templateFormat,
    template,
    functionCall,
    functions,
    messageTemplates,
    template,
    templateFormat,
    functionsType,
    handleParseInputVariables,
  ]);

  useEffect(() => {
    handleParseInputVariables();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const messageToString = (m: Message) => {
    if (m.role === "assistant") {
      if (m.function_call) return m.function_call.arguments;
    }
    return getStringContent(m);
  };

  const getStartingTemplate = useCallback(() => {
    if (!startingPromptTemplate || !startingPromptTemplateVersion) {
      return "";
    } else if (startingPromptTemplateVersion.prompt_template.type === "chat") {
      return startingPromptTemplateVersion?.prompt_template.messages
        .map(messageToString)
        .join("\n---\n");
    } else {
      return getStringContent(startingPromptTemplateVersion.prompt_template);
    }
  }, [startingPromptTemplate, startingPromptTemplateVersion]);

  const getNewTemplate = useCallback(() => {
    if (isChat) {
      // For some reason, systemMessage isn't updated in messageTemplates.
      const systemMessage = template ? template + "\n---\n" : "";
      return (
        systemMessage + messageTemplates?.map(messageToString).join("\n---\n")
      );
    } else {
      return typeof template === "string" ? template : "";
    }
  }, [isChat, messageTemplates, template]);

  const prevValues = useRef({
    messageTemplates,
    template,
    templateFormat,
    title,
    functions,
    functionCall,
    metadata,
    model,
  });

  const justSavedNewVersion = useRef(false);

  useEffect(() => {
    if (
      promptVersion &&
      promptVersion?.id !== startingPromptTemplateVersion?.id
    ) {
      // A new version has been saved
      justSavedNewVersion.current = true;
      setIsEditing(false);
    } else if (!justSavedNewVersion.current) {
      // Here we decide wether the user should be warned about unsaved changes
      const hasUnsavedChanges =
        JSON.stringify(prevValues.current.messageTemplates) !==
          JSON.stringify(messageTemplates) ||
        ((startingPromptTemplateVersion?.prompt_template.type === "completion"
          ? getStringContent(startingPromptTemplateVersion.prompt_template) !==
            template
          : "") &&
          prevValues.current.template !== template) ||
        (startingTemplateFormat !== templateFormat &&
          prevValues.current.templateFormat !== templateFormat) ||
        (startingPromptTemplate?.prompt_name !== title &&
          title !== "" &&
          prevValues.current.title !== title) ||
        ((startingPromptTemplateVersion?.prompt_template.type === "chat"
          ? startingPromptTemplateVersion?.prompt_template?.functions !==
            functions
          : "") &&
          functions.length > 0 &&
          prevValues.current.functions !== functions) ||
        ((startingPromptTemplateVersion?.prompt_template.type === "chat"
          ? startingPromptTemplateVersion?.prompt_template?.function_call !==
            functionCall
          : "") &&
          prevValues.current.functionCall !== functionCall) ||
        prevValues.current.metadata !== metadata ||
        (startingPromptTemplateVersion?.metadata?.model !== model &&
          prevValues.current.model !== model);

      if (hasUnsavedChanges) {
        setIsEditing(true);
      }
    }

    justSavedNewVersion.current = false;
    prevValues.current = {
      messageTemplates,
      template,
      templateFormat,
      title,
      functions,
      functionCall,
      metadata,
      model,
    };
  }, [
    startingPromptTemplateVersion,
    startingPromptTemplate,
    startingTemplateFormat,
    messageTemplates,
    template,
    templateFormat,
    title,
    functions,
    functionCall,
    metadata,
    model,
    promptVersion,
  ]);

  const [cursorPosition, setCursorPosition] = useState<number | null>(null);

  const handleKeyUp = (event: React.KeyboardEvent) => {
    if (event.key === "@") {
      const newCursorPosition = (event.target as HTMLInputElement)
        .selectionStart;
      setCursorPosition(newCursorPosition);
      setOpenTemplateLink(true);
    }
  };

  return (
    <div className="flex h-full flex-col overflow-auto">
      {/* Error Message */}
      {errorMessage && (
        <div
          className="relative mx-auto mb-4 max-w-xl rounded border border-red-400 px-2 py-2 text-center text-red-700"
          role="alert"
        >
          <strong className="pr-2 font-bold">Error!</strong>
          <span className="block sm:inline">{errorMessage}</span>
        </div>
      )}
      <div className="relative flex h-full flex-col rounded-lg border border-gray-300 shadow-sm focus-within:border-blue-500 focus-within:ring-1 focus-within:ring-blue-500">
        <div className={`relative ${isChat && "border-b border-gray-300"}`}>
          <label htmlFor="title" className="sr-only">
            Title
          </label>
          <input
            type="text"
            name="title"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="block w-full rounded-lg border-0 pt-2.5 text-lg font-medium placeholder-gray-500 focus:ring-0"
            placeholder="Title"
            disabled={editMode}
          />
          <div className="absolute inset-y-0 right-0 mr-1 flex items-center">
            <DropdownMenu>
              <DropdownMenuTrigger className="border-0 text-sm focus:ring-0">
                {templateFormat}
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                {templateFormats.map((format) => (
                  <DropdownMenuItem
                    className={`w-full text-center ${
                      templateFormat === format ? "bg-gray-100" : ""
                    }`}
                    key={format}
                    onSelect={() => {
                      setTemplateFormat(format);
                      setSavedTemplateFormat(format);
                    }}
                  >
                    {format}
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
        {isChat ? (
          <div className="h-full overflow-hidden ">
            <ChatTemplate
              messageTemplates={messageTemplates}
              setMessageTemplates={setMessageTemplates}
              setTemplate={setTemplate}
              template={template}
              templateFormat={templateFormat}
              invalidSnippets={startingPromptTemplateVersion?.invalid_snippets}
              invalidPromptTemplate={invalidPromptTemplate}
              invalidPromptIndex={invalidPromptIndex}
              sourcedSnippets={
                startingPromptTemplateVersion?.sourced_snippets || []
              }
            />
          </div>
        ) : (
          <>
            <label htmlFor="prompt" className="sr-only">
              Prompt
            </label>
            <textarea
              rows={20}
              name="prompt"
              id="prompt"
              value={template}
              onChange={(e) => setTemplate(e.target.value)}
              onKeyUp={handleKeyUp}
              className="block h-full w-full resize-none border-0 py-0 placeholder-gray-500 focus:ring-0"
              placeholder="Write a prompt..."
            />
          </>
        )}
        <div className="flex items-center justify-between border-t border-gray-200 p-2 sm:px-3">
          <div className="flex items-center space-x-3 overflow-auto">
            {invalidPromptTemplate ? (
              <div className="flex items-center space-x-1">
                <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
                <span className="text-red-500">Variable Parsing Error</span>
              </div>
            ) : (
              <>
                <div className="text-md flex-shrink-0 text-gray-700">
                  {variables.length === 0 ? (
                    <DefineInputVariablesMessage
                      templateFormat={templateFormat}
                    />
                  ) : (
                    "Input Variables:"
                  )}
                </div>
                <div className="flex space-x-2 overflow-x-scroll whitespace-nowrap text-gray-500">
                  {variables.map((variable, index) => (
                    <div
                      className="rounded-full border border-gray-300 px-2 py-1 text-center text-sm"
                      key={index}
                      aria-label={variable}
                      title={variable}
                    >
                      {variable.length > 15
                        ? `${variable.substring(0, 15)}...`
                        : variable}
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
          <div className="flex flex-shrink-0 gap-1">
            <ChooseSnippetTemplateModal
              currentPromptText={template}
              setCurrentPromptText={setTemplate}
              open={openTemplateLink}
              setOpen={setOpenTemplateLink}
              cursorPosition={cursorPosition}
            />
            <MetadataDialog
              metadata={metadata}
              setMetadata={setMetadata}
              setError={setError}
              error={errorMessage}
            />
            <PromptParameters
              initialProviderBaseURLName={
                startingPromptTemplateVersion &&
                startingPromptTemplateVersion.provider_base_url_name
                  ? startingPromptTemplateVersion.provider_base_url_name
                  : null
              }
              isChat={isChat}
              model={model}
              selectedProviderBaseURLName={selectedProviderBaseURLName}
              setModel={setModel}
              setSelectedProviderBaseURLName={setSelectedProviderBaseURLName}
            />
            {isChat && (
              <FunctionDialog
                onSubmit={(newFunction) =>
                  setFunctions((prev) => {
                    const index = prev.findIndex(
                      (func) => func.id === newFunction.id,
                    );
                    if (index !== -1) {
                      prev[index] = newFunction;
                    } else {
                      prev = prev.concat([newFunction]);
                    }
                    return [...prev];
                  })
                }
                functions={functions}
                functionCall={functionCall}
                setFunctions={setFunctions}
                setFunctionCall={setFunctionCall}
                functionsType={functionsType}
                setFunctionsType={setFunctionsType}
              />
            )}
            {
              <Button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  if (editMode) {
                    navigate(
                      `/workspace/${userContext?.activeWorkspaceId}/prompt/${startingPromptTemplate?.id}${
                        startingPromptTemplateVersion
                          ? `/version/${startingPromptTemplateVersion.number}`
                          : ""
                      }`,
                    );
                  } else {
                    navigate(
                      `/workspace/${userContext?.activeWorkspaceId}/prompt/`,
                    );
                  }
                }}
                variant="destructiveOutline"
                className={`text-sm`}
              >
                {editMode ? "Cancel Edit" : "Cancel"}
              </Button>
            }
            <button
              type="submit"
              disabled={loadingCreate}
              onClick={(e) => {
                e.preventDefault();
                setNewPromptVersionForm(true);
              }}
              className={`inline-flex items-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50`}
            >
              {loadingCreate ? (
                <LoadingSpinner size={5} />
              ) : editMode ? (
                "Update Template"
              ) : (
                "Create Template"
              )}
            </button>
            <CreateCommitMessage
              open={newPromptVersionForm}
              promptTemplateName={title}
              setOpen={setNewPromptVersionForm}
              setCommitMessage={setCommitMessage}
              setSourceReportId={setSourceReportId}
              handleSubmit={handleCreateTemplate}
              getStartingTemplate={getStartingTemplate}
              getNewTemplate={getNewTemplate}
              previousBlueprintId={previousBlueprintId || null}
              promptVersion={promptVersion}
            />
          </div>
        </div>
      </div>
      <ExitPageConfirmation
        when={isEditing}
        execute={() => setIsEditing(false)}
        message="Are you sure you want to leave this page? You have unsaved changes."
      />
    </div>
  );
}
