import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { KeyIcon } from "@heroicons/react/outline";
import { resetPassword } from "@/api/application-api";
import AuthSubmitButton from "@/components/AuthSubmitButton";
import { parseErrorMessage } from "@/utils/errors";

const resetPasswordSchema = z.object({
  email: z
    .string()
    .email({ message: "Invalid email format" })
    .nonempty({ message: "Email is required" }),
  password: z.string().nonempty({ message: "Password is required" }),
  confirmPassword: z
    .string()
    .nonempty({ message: "Please confirm your password" }),
});

const ResetPassword: FC = () => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const emailFromURL = decodeURIComponent(urlParams.get("email") || "");

  const form = useForm({
    defaultValues: {
      email: emailFromURL,
      password: "",
      confirmPassword: "",
    },
    resolver: zodResolver(resetPasswordSchema),
  });

  const onSubmit = async (values: any) => {
    if (values.password !== values.confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    setLoading(true);

    try {
      const response: any = await resetPassword({
        code: urlParams.get("twilio_code") || "",
        email: values.email,
        password: values.password,
      });

      if (response.ok) {
        navigate("/login");
        return;
      }

      const { message } = await response.json();
      setError(
        typeof message === "string"
          ? message
          : parseErrorMessage(message) ||
              "There was an error resetting your password. Please try again later.",
      );
    } catch (err) {
      setError("Something went wrong. Please try again later.");
    }

    setLoading(false);
  };

  return (
    <div className="mx-auto max-w-lg gap-8 rounded-sm border bg-gray-50 px-12 py-5 text-left">
      <div className="flex items-center justify-center py-3 text-center text-lg font-semibold text-gray-500">
        <KeyIcon className="mr-2 h-6 w-6" aria-hidden="true" />
        Reset Password
      </div>
      <form
        className="flex flex-col gap-3 py-3"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <input
          className="cursor-not-allowed rounded-sm border border-gray-400 bg-gray-200 py-3"
          type="text"
          placeholder="Enter email"
          {...form.register("email")}
          readOnly
        />
        {form.formState.errors.email && (
          <div className="text-sm text-red-500">
            {form.formState.errors.email.message}
          </div>
        )}
        <input
          className="rounded-sm border border-gray-400 py-3"
          type="password"
          placeholder="Enter new password"
          {...form.register("password")}
        />
        {form.formState.errors.password && (
          <div className="text-sm text-red-500">
            {form.formState.errors.password.message}
          </div>
        )}
        <input
          className="rounded-sm border border-gray-400 py-3"
          type="password"
          placeholder="Confirm new password"
          {...form.register("confirmPassword")}
        />
        {form.formState.errors.confirmPassword && (
          <div className="text-sm text-red-500">
            {form.formState.errors.confirmPassword.message}
          </div>
        )}
        {error && (
          <div className="py-2 text-center text-sm text-red-500">{error}</div>
        )}
        <div className="flex flex-col items-center gap-2">
          <AuthSubmitButton loading={loading} text="Reset Password" />
          <Link
            className="mt-2 inline-block text-sm text-blue-500 hover:text-blue-400"
            to="/login"
          >
            Back to Login
          </Link>
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;
