import { z } from "zod";

const newPromptVersion = z.object({
  message: z.string().max(72).optional().default(""),
  source_report_id: z.number().optional().nullable(),
});

type NewPromptVersion = z.infer<typeof newPromptVersion>;

export { NewPromptVersion, newPromptVersion };
