import { FC } from "react";
import { Button } from "@/components/ui/button";
import Feature from "./Feature";
import promptsGetGif from "@/assets/home_guide/promptsget.gif";

type DeployingPromptsFeatureProps = {
  handleCloseClick: () => void;
};

const DeployingPromptsFeature: FC<DeployingPromptsFeatureProps> = ({
  handleCloseClick,
}) => {
  return (
    <Feature onCloseClick={handleCloseClick}>
      <div className="grid grid-cols-3 gap-x-8">
        <div className="col-span-1 py-3">
          <img
            alt="Feature"
            className="w-full rounded-lg"
            src={promptsGetGif}
          />
        </div>
        <div className="col-span-2">
          <h2 className="mt-6 text-2xl font-medium">
            Using{" "}
            <span className="bg-gray-900 p-1 font-mono text-xl text-gray-100">
              promptlayer.templates.get
            </span>
          </h2>
          <p className="mt-2 max-w-[600px] text-base text-gray-500">
            After creating a prompt template, you can fetch it programmatically
            using our API.
            <br />
            <br />
            Use the Prompt Registry as a CMS to avoid blocking prompt changes on
            code releases. Pull down the latest template or tag a version as
            "prod".
          </p>
          <a
            href="https://docs.promptlayer.com/features/prompt-registry#getting-a-template"
            rel="noreferrer"
            target="_blank"
          >
            <Button className="mt-4" variant="secondaryLightOutline">
              Read the Docs
            </Button>
          </a>
        </div>
      </div>
    </Feature>
  );
};

export default DeployingPromptsFeature;
