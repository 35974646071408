import { FC } from "react";
import evaluationsFeature from "@/assets/features/evaluations-feature.png";
import historyFeature from "@/assets/features/history-feature.png";
import registryFeature from "@/assets/features/registry-feature.png";
import monitoringFeature from "@/assets/features/monitoring.png";
import Community from "./Community/Community";
import Companies from "./Companies/Companies";
import Feature from "./Feature/Feature";
import Hero from "./Hero/Hero";
import Integrations from "./Integrations/Integrations";
import Press from "./Press/Press";
import Teams from "./Teams/Teams";
import Tutorials from "./Tutorials/Tutorials";
import CaseStudies from "./CaseStudies/CaseStudies";
import Privacy from "./Privacy/Privacy";
import * as S from "./Styles";
import Quotes from "./Quotes/Quotes";

const GenericLanding: FC = () => {
  return (
    <S.Container>
      <Hero />
      <Companies />
      <CaseStudies />
      <Feature
        title="Prompt with experts"
        description="Building good AI is about understanding your users. That's why subject matter experts are the best prompt engineers."
        linkHref="https://docs.promptlayer.com/why-promptlayer/prompt-management"
        linkText="Why use a prompt CMS?"
        items={[
          {
            title: "No-code prompt editor",
            subtitle: "Update and test prompts directly from the dashboard.",
          },
          {
            title: "Include non-technical stakeholders",
            subtitle:
              "Enable product, marketing, and content teams to edit prompts directly.",
          },
          {
            title: "Avoid engineer bottlenecks",
            subtitle: "Decouple eng releases from prompt deploys.",
          },
        ]}
        featuredImage={historyFeature}
      />
      <Feature
        title="Version prompts"
        description="Edit and deploy prompt versions visually using our dashboard. No coding required."
        linkHref="/create-account"
        linkText="Get started for free"
        items={[
          {
            title: "Organize versions",
            subtitle:
              "Comment, write notes, diff versions, and roll back changes.",
          },
          {
            title: "Deploy new prompts",
            subtitle: "Publish new prompts interactively for prod and dev.",
          },
          {
            title: "Clean up your repo",
            subtitle: "Prompts shouldn't be scattered through your codebase.",
          },
          {
            title: "A/B test prompts",
            subtitle:
              "Release new prompt versions gradually and compare metrics.",
          },
        ]}
        featuredImage={registryFeature}
        reverseColumns
      />
      <Feature
        title="Evaluate iteratively"
        description="Rigorously test prompts before deploying, with the help of human and AI graders."
        linkHref="https://docs.promptlayer.com/features/evaluations/overview"
        linkText="Learn more"
        items={[
          {
            title: "Historical backtests",
            subtitle:
              "See how new prompt versions fair against historical data.",
          },
          {
            title: "Regression tests",
            subtitle: "Trigger evals to run every time a prompt is updated.",
          },
          {
            title: "Compare models",
            subtitle: "Test prompts against different models and parameters.",
          },
          {
            title: "One-off bulk jobs",
            subtitle: "Run prompt pipelines against a batch of test inputs.",
          },
        ]}
        featuredImage={evaluationsFeature}
      />
      <Feature
        title="Monitor usage"
        description="Understand how your LLM application is being used, by whom, and how often. No need to jump back and forth to Mixpanel or Datadog."
        linkHref="https://docs.promptlayer.com/why-promptlayer/analytics"
        linkText="See how it works"
        items={[
          {
            title: "Cost, latency stats",
            subtitle: "View high level stats about your LLM usage.",
          },
          {
            title: "Latency trends",
            subtitle:
              "Understand latency trends over time, by feature, and by model.",
          },
          {
            title: "Jump to bug report",
            subtitle: "Quickly find execution logs for a given user.",
          },
        ]}
        featuredImage={monitoringFeature}
        reverseColumns
      />
      <Quotes />
      <Teams />
      <Privacy />
      <Tutorials />
      <Integrations />
      <Community />
      <Press />
    </S.Container>
  );
};

export default GenericLanding;
