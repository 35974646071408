import { PlusIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";

import { DescriptionItemParamForm } from "./InteractiveFunctionEditor/DescriptionItemParamForm";
import {
  getItemDescriptionState,
  handleAddRow,
  handleItemSwitch,
  handleJsonToFormParamChange,
  handleParameterChange,
  handleRemoveRow,
  parseParams,
} from "./InteractiveFunctionEditor/InteractiveFunctionEditorUtils";
import { RegularParamForm } from "./InteractiveFunctionEditor/RegularParamForm";
import { InteractiveEditorSwitch } from "./InteractiveFunctionEditor/Switch";
import {
  ItemDescription,
  ItemProperties,
} from "./InteractiveFunctionEditor/Types";

export const InteractiveFunctionParametersEditor = ({
  onChange,
  initialValue,
  depth = 0,
  outerType,
}: {
  onChange: (value: any) => void;
  initialValue: any;
  depth?: number;
  outerType?: string;
}) => {
  const [isItemDescription, setIsItemDescription] = useState<
    boolean | undefined
  >(getItemDescriptionState(initialValue, depth));

  const [parameters, setParameters] = useState<any>(
    parseParams(initialValue, depth, isItemDescription, outerType),
  );

  useEffect(() => {
    handleJsonToFormParamChange(
      parameters,
      onChange,
      depth,
      isItemDescription,
      outerType,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameters]);

  return (
    <div className="px-3">
      <InteractiveEditorSwitch
        depth={depth}
        isItemDescription={isItemDescription}
        setIsItemDescription={setIsItemDescription}
        handleItemSwitch={handleItemSwitch}
        setParameters={setParameters}
        onChange={onChange}
        outerType={outerType}
      />
      <div className={`${depth === 0 ? "max-h-[150px] overflow-y-auto" : ""}`}>
        {isItemDescription ? (
          <DescriptionItemParamForm
            parameters={parameters}
            setParameters={setParameters}
            handleParameterChange={handleParameterChange}
            isItemDescription={isItemDescription}
          />
        ) : (
          <>
            <div className="grid grid-cols-8 items-center gap-4 rounded-md border border-gray-200 bg-gray-50 p-1 text-xs text-gray-700">
              <div className="col-span-1"></div>
              {depth === 0 && (
                <div className="col-span-1 text-center">Required</div>
              )}
              <div className="col-span-2">Name</div>
              <div className="col-span-2">Type</div>
              <div className="col-span-2">Title</div>
            </div>
            {parameters.map((parameter: any, index: any) => (
              <RegularParamForm
                key={index}
                index={index}
                parameter={parameter}
                parameters={parameters}
                setParameters={setParameters}
                handleParameterChange={handleParameterChange}
                handleRemoveRow={handleRemoveRow}
                depth={depth}
                isItemDescription={isItemDescription}
              >
                {parameter.type === "array" && (
                  <div className="col-span-6 col-start-3">
                    {depth < 2 ? (
                      <InteractiveFunctionParametersEditor
                        onChange={(val) =>
                          handleParameterChange(
                            index,
                            "items",
                            val,
                            parameters,
                            setParameters,
                            isItemDescription,
                          )
                        }
                        initialValue={(() => {
                          if (parameter?.items?.hasOwnProperty("properties")) {
                            return (parameter.items as ItemProperties)
                              .properties;
                          } else {
                            return parameter.items as ItemDescription;
                          }
                        })()}
                        depth={depth + 1}
                      />
                    ) : (
                      <div className="text-xs text-gray-500">
                        Max property depth reached. Switch to <b>JSON</b> mode
                        to see the full schema.
                      </div>
                    )}
                  </div>
                )}
                {parameter.type === "object" && (
                  <div className="col-span-6 col-start-3">
                    {depth < 2 ? (
                      <InteractiveFunctionParametersEditor
                        onChange={(val) =>
                          handleParameterChange(
                            index,
                            "properties",
                            val,
                            parameters,
                            setParameters,
                            isItemDescription,
                          )
                        }
                        initialValue={parameter.properties}
                        depth={depth + 1}
                        outerType={parameter.type}
                      />
                    ) : (
                      <div className="text-xs text-gray-500">
                        Max property depth reached. Switch to <b>JSON</b> mode
                        to see the full schema.
                      </div>
                    )}
                  </div>
                )}
              </RegularParamForm>
            ))}
            <div className="flex justify-end">
              <div
                className="mt-4 inline-flex cursor-pointer items-center rounded-sm border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                onClick={() => handleAddRow(parameters, setParameters)}
              >
                <PlusIcon className="mr-2 h-5 w-5" />
                Add Parameter
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
