import { FC } from "react";
import { ArrowRightIcon, ExternalLinkIcon } from "@heroicons/react/outline";
import whiteSpeakLogo from "@/assets/company_logos/all_white_logos/speak.png";
import whiteParentLabLogo from "@/assets/company_logos/all_white_logos/parentlab.png";
import whiteEllipsisLogo from "@/assets/company_logos/all_white_logos/ellipsis.png";
// import whiteMeticulateLogo from "@/assets/company_logos/all_white_logos/meticulate.png";
import whiteGorgiasLogo from "@/assets/company_logos/all_white_logos/gorgias.png";

interface CaseStudyCardProps {
  image: string;
  altText: string;
  title: string;
  link: string;
  description: string;
  isSecondary: boolean;
}

const CaseStudyCard: FC<CaseStudyCardProps> = ({
  image,
  altText,
  title,
  link,
  description,
  isSecondary,
}) => {
  return (
    <div
      className={`flex h-full min-h-[350px] rounded-none py-1 pr-2 text-white md:rounded-lg ${
        isSecondary ? "bg-gray-900" : "bg-[rgba(10,13,26,1)]"
      }`}
    >
      <div className="flex w-1/3 flex-none items-center justify-center border-r border-gray-50/10 px-4">
        <img
          src={image}
          alt={altText}
          className={`mx-auto w-[125px] grayscale filter ${
            isSecondary ? "opacity-50" : ""
          }`}
        />
      </div>
      <div className="mb-2 flex flex-grow flex-col gap-3 p-6">
        <div className="flex flex-grow flex-col items-center gap-2 pt-4">
          <h2
            className={`text-xl font-normal capitalize leading-tight ${
              isSecondary ? "text-gray-500" : ""
            }`}
          >
            {title}
          </h2>
          <p
            className={`text-sm font-light ${
              isSecondary ? "text-gray-600" : "text-gray-400"
            }`}
          >
            {description}
          </p>
        </div>
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className="block"
        >
          <div
            className={`text-sm font-medium ${
              isSecondary ? "text-gray-500" : "text-blue-500"
            }`}
          >
            Read full case study
            <ArrowRightIcon className="ml-1 inline h-4 w-4" />
          </div>
        </a>
      </div>
    </div>
  );
};

const caseStudies = [
  {
    image: whiteGorgiasLogo,
    altText: "Gorgias team",
    title: "Gorgias Scaled Customer Support Automation 20x with LLMs",
    description:
      "Gorgias is using PromptLayer to build an autonomous prompt engineering team, enabling them to scale their AI-powered helpdesk to millions of shoppers. They use PromptLayer every single day to store and version control prompts, run evaluations on regression and backtest datasets, and review logs to identify issues.",
    link: "https://blog.promptlayer.com/gorgias-uses-promptlayer-to-automate-customer-support-at-scale-7c129fbdbb8d",
    company: "Gorgias",
  },
  {
    image: whiteSpeakLogo,
    altText: "PromptLayer team",
    title: "How Speak Empowers Non-Technical Teams with Prompt Engineering",
    description:
      "PromptLayer empowers non-technical teams to iterate on AI features independently, saving engineering time and costs. See how Speak compressed months of curriculum development into a single week and launched in 10 new markets with PromptLayer.",
    link: "https://blog.promptlayer.com/how-speak-empowers-non-technical-teams-with-prompt-engineering-and-promptlayer-21fd2935cd56",
    company: "Speak",
  },
  {
    image: whiteParentLabLogo,
    altText: "PromptLayer CMS",
    title:
      "ParentLab Builds Highly Personalized AI Interactions with PromptLayer",
    description:
      "PromptLayer enabled ParentLab to craft personalized AI interactions 10x faster, with 700 prompt revisions in 6 months, saving 400+ engineering hours. Prompts are deployed and updated solely by teams of non-technical domain experts.",
    link: "https://blog.promptlayer.com/how-promptlayer-enables-non-technical-prompt-engineering-at-parentlab-0b084cc9fde1",
    company: "ParentLab",
  },
  {
    image: whiteEllipsisLogo,
    altText: "PromptLayer team",
    title: "How Ellipsis uses PromptLayer to Debug LLM Agents",
    description:
      "PromptLayer empowered Ellipsis to scale from 0 to 500K+ requests within 6 months while reducing debugging time by 75%. Engineers can now identify and resolve complex LLM agent issues in just 3 clicks, avoiding hundreds of hours building an in-house solution.",
    link: "https://blog.promptlayer.com/how-ellipsis-uses-promptlayer-to-debug-llm-agents-6e66c9b1cf9d",
    company: "Ellipsis",
  },
  // {
  //   image: whiteMeticulateLogo,
  //   altText: "Meticulate team",
  //   title: "From Zero to 1.5M Requests: How PromptLayer Powered a Viral Launch",
  //   description:
  //     "PromptLayer powered Meticulate's viral launch, enabling them to scale from zero to 1.5 million requests in 24 hours. By providing critical observability for debugging complex agent-based workflows, PromptLayer saved countless engineering hours and accelerated Meticulate's path to a successful MVP.",
  //   link: "https://blog.promptlayer.com/from-zero-to-1-5-million-requests-how-promptlayer-powered-meticulates-viral-launch-4b333b85a879",
  //   company: "Meticulate",
  // },
];

const CaseStudies: FC = () => {
  return (
    <div className="bg-[#f2f5f5] py-10">
      <div className="mx-auto flex w-full max-w-[540px] flex-col items-center justify-around gap-2 pb-6 md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1150px]">
        <div className="w-full px-3 md:px-0">
          <a href="/case-studies">
            <h2 className="m-0 w-full pb-6 text-left text-4xl font-medium leading-[1.2] tracking-[-0.02em] text-[#090b1c] hover:text-gray-700">
              Case Studies
              <ExternalLinkIcon className="ml-2 inline h-6 w-6" />
            </h2>
          </a>
        </div>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          {caseStudies.map((study, index) => (
            <div key={index} className="h-full">
              <CaseStudyCard
                image={study.image}
                altText={study.altText}
                title={study.title}
                description={study.description}
                link={study.link}
                isSecondary={false}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CaseStudies;
