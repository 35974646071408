import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ParseValueConfiguration, ReportColumn } from "@/types/evaluate";
import { formatInputVariable } from "@/utils/evaluate";
import { useState } from "react";
import { ModalStep } from "../ModalRouter";

const ParseValueBuilder = ({
  columnData,
  availableColumns,
  navigatePrevious,
  saveColumnAction,
  editable,
}: {
  columnData: Partial<ReportColumn>;
  availableColumns: ReportColumn[];
  navigatePrevious: () => void;
  saveColumnAction: (newColumnDataArg: ReportColumn) => void;
  editable: boolean;
}) => {
  const [name, setName] = useState<string | null>(columnData.name || null);
  const [type, setType] = useState<string | null>(
    columnData.configuration?.type ?? null,
  );
  const [column, setColumn] = useState<string | null>(
    columnData.configuration?.column || null,
  );

  const [dataIsValid, setDataIsValid] = useState<{
    name: boolean;
    type: boolean;
    column: boolean;
  }>({
    name: true,
    type: true,
    column: true,
  });

  const saveEndpointAction = () => {
    if (!name) {
      setDataIsValid({ name: false, type: true, column: true });
      return;
    } else if (!type) {
      setDataIsValid({ name: true, type: false, column: true });
      return;
    } else if (!column) {
      setDataIsValid({ name: true, type: true, column: false });
      return;
    }

    setDataIsValid({ name: true, type: true, column: true });

    saveColumnAction({
      ...columnData,
      name: name,
      configuration: {
        type,
        column,
      } as ParseValueConfiguration,
    } as ReportColumn);
  };

  return (
    <ModalStep
      navigatePrevious={navigatePrevious}
      navigateNext={saveEndpointAction}
      nextButtonText={editable ? "Save Step" : "Done"}
    >
      <div className="grid grid-cols-3 items-center gap-4">
        <div className="col-span-3">
          <div className="text-lg font-semibold">Configure Parse Value</div>
          <div className="max-w-md text-sm text-gray-500">
            This step will parse a value from a column into the desired type.
          </div>
        </div>
        <label htmlFor="name" className="col-span-1">
          Column name:
        </label>
        <input
          id="name"
          className={`col-span-2 rounded border ${
            !dataIsValid.name ? "border-red-500" : "border-gray-300"
          } px-2 py-1 disabled:cursor-not-allowed disabled:bg-gray-50`}
          value={name || ""}
          onChange={(e) => setName(e.target.value)}
          disabled={!editable}
        />
        {!dataIsValid.name && (
          <>
            <div className="col-span-1 -mt-4">&nbsp;</div>
            <div className="col-span-2 -mt-4 text-left text-xs text-red-500">
              Name is required
            </div>
          </>
        )}

        <label htmlFor="json-path" className="col-span-1">
          Type:
        </label>
        <DropdownMenu>
          <DropdownMenuTrigger className="col-span-2 w-full">
            {types.find((t) => t.value === type)?.label || (
              <span className="font-normal text-gray-500">
                Select a type...
              </span>
            )}
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {types.map((type) => (
              <DropdownMenuItem
                key={type.value}
                onSelect={() => setType(type.value)}
              >
                {type.label}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
        {!dataIsValid.type && (
          <>
            <div className="col-span-1 -mt-4">&nbsp;</div>
            <div className="col-span-2 -mt-4 text-left text-xs text-red-500">
              Type is required
            </div>
          </>
        )}

        <label htmlFor="json-path" className="col-span-1">
          Column:
        </label>
        <DropdownMenu>
          <DropdownMenuTrigger
            disabled={!editable}
            className="col-span-2 w-full"
          >
            {column || (
              <span className="font-normal text-gray-500">
                Select a column...
              </span>
            )}
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {availableColumns.map((column) => (
              <DropdownMenuItem
                key={column.name}
                onSelect={() => setColumn(column.name)}
              >
                {formatInputVariable(column.column_type, column.name)}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
        {!dataIsValid.type && (
          <>
            <div className="col-span-1 -mt-4">&nbsp;</div>
            <div className="col-span-2 -mt-4 text-left text-xs text-red-500">
              Column is required
            </div>
          </>
        )}
      </div>
    </ModalStep>
  );
};

const types = [
  { value: "string", label: "String" },
  { value: "number", label: "Number" },
  { value: "boolean", label: "Boolean" },
  { value: "object", label: "JSON" },
] as const;

export default ParseValueBuilder;
