import { Message } from "@/types";
import { getStringContent } from "@/utils/utils";
import { ExclamationCircleIcon, TerminalIcon } from "@heroicons/react/outline";
import { CopyButton } from "../ui/copy-button";

const TRUNCATE_PREVIEW_LENGTH = 100;

export const ActionCallAndResponse = ({
  componentIsOpen,
  highlight,
  message,
}: {
  componentIsOpen: boolean;
  highlight: boolean;
  message: Message;
}) => {
  if (message.role !== "assistant") return null;
  const content = getStringContent(message);
  return (
    <>
      {!componentIsOpen ? (
        message.tool_calls ? (
          <>
            <div className="group-hover:inherit pt-2 text-left">
              {content.length > TRUNCATE_PREVIEW_LENGTH
                ? content.substring(0, TRUNCATE_PREVIEW_LENGTH) + "..."
                : content}
            </div>
            <div className="group-hover:inherit pt-2 text-left font-mono">
              <TerminalIcon className="mr-1 inline h-4 w-4 text-gray-500" />
              {message.tool_calls.map((tool_call, index: number) => (
                <div key={index}>
                  {`${tool_call.function.name} <- ${tool_call.function.arguments}`.substring(
                    0,
                    TRUNCATE_PREVIEW_LENGTH,
                  )}
                  {tool_call.function.name.length +
                    tool_call.function.arguments.length >
                  TRUNCATE_PREVIEW_LENGTH
                    ? "..."
                    : ""}
                  <br />
                </div>
              ))}
            </div>
          </>
        ) : (
          <>
            <div className="group-hover:inherit pt-2 text-left">
              {content.length > TRUNCATE_PREVIEW_LENGTH
                ? content.substring(0, TRUNCATE_PREVIEW_LENGTH) + "..."
                : content}
            </div>
            <div className="group-hover:inherit pt-2 text-left font-mono">
              <TerminalIcon className="mr-1 inline h-4 w-4 text-gray-500" />
              {message.function_call
                ? `${message.function_call.name} <- ${message.function_call.arguments}`.substring(
                    0,
                    TRUNCATE_PREVIEW_LENGTH,
                  ) +
                  (message.function_call.name.length +
                    message.function_call.arguments.length >
                  TRUNCATE_PREVIEW_LENGTH
                    ? "..."
                    : "")
                : null}
            </div>
          </>
        )
      ) : (
        <>
          <div className="flex justify-between pb-3">
            <div className="w-full">
              <span className={highlight ? "bg-blue-100 " : ""}>
                {content ? (
                  content
                ) : (
                  <div className="flex items-center">
                    <ExclamationCircleIcon className="mr-1 h-5 w-5 text-gray-400" />
                    <span className="text-gray-400">Invalid format.</span>
                  </div>
                )}
              </span>
            </div>
            <div>
              <CopyButton text={JSON.stringify(content)} />
            </div>
          </div>
          {message.tool_calls ? (
            <div className="font-mono">
              {message.tool_calls?.map((tool_call, index) => (
                <div key={index}>
                  <div className="flex items-center font-medium text-gray-900">
                    <TerminalIcon className="mr-2 inline h-4 w-4" />
                    <span>{tool_call.function.name}</span>
                  </div>
                  <div className="pl-2 pt-1 text-gray-600">
                    <div className="font-mono">
                      <div className="font-medium text-gray-900">
                        <div className="flex items-start justify-between">
                          <div className="w-full">
                            {tool_call.function.arguments}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div className="pl-2 pt-1 text-gray-600">
                <div className="font-mono">
                  <div className="font-medium text-gray-900">
                    <div className="flex items-start justify-between">
                      <CopyButton text={JSON.stringify(message.tool_calls)} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="font-mono">
              <div className="flex items-center font-medium text-gray-900">
                <TerminalIcon className="mr-2 inline h-4 w-4" />
                <span>{message.function_call?.name}</span>
              </div>
              <div className="pl-2 pt-1 text-gray-600">
                <div className="font-mono">
                  <div className="font-medium text-gray-900">
                    <div className="flex items-start justify-between">
                      <div className="w-full">
                        {message.function_call?.arguments}
                      </div>
                      <div>
                        <CopyButton
                          text={message.function_call?.arguments ?? ""}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
