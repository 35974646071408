import { useContext, useEffect, useMemo, useState } from "react";
import { TagsDropdownContext } from "./TagsDropdown";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";

const NavigationButton = ({ forward }: { forward?: boolean }) => {
  const Icon = forward ? ChevronRightIcon : ChevronLeftIcon;
  const { traverse, activePage, pages } = useContext(TagsDropdownContext);
  const disabled = useMemo(
    () => pages === 0 || (forward ? activePage === pages : activePage === 1),
    [activePage, pages, forward],
  );

  return (
    <button
      className={"group"}
      disabled={disabled}
      onClick={() => traverse(!!forward)}
    >
      <Icon className="h-4 w-4 text-gray-400 hover:text-gray-500 group-disabled:text-gray-200 group-disabled:hover:text-gray-200" />
    </button>
  );
};

const useCachedData = () => {
  const { activePage, pages, loading } = useContext(TagsDropdownContext);

  const [cachedPageData, setCachedPageData] = useState<{
    activePage: number;
    pages: number;
  }>({ activePage: activePage, pages: pages });

  useEffect(() => {
    if (!loading) setCachedPageData({ activePage: activePage, pages: pages });
  }, [activePage, loading, pages]);
  return cachedPageData;
};

const TagsDropdownPaginatedFooter = () => {
  const { activePage, pages } = useCachedData();

  const isEmpty = useMemo(
    () => activePage === 1 && pages === 0,
    [activePage, pages],
  );

  return (
    <div className="flex w-full justify-between px-2 pb-2 ">
      <NavigationButton />
      {!isEmpty && (
        <span className="text-xs text-gray-500">
          <b>{activePage}</b> / {pages}
        </span>
      )}
      <NavigationButton forward={true} />
    </div>
  );
};

export default TagsDropdownPaginatedFooter;
