import { useUser } from "@/context/user-context";
import { useMemo } from "react";

// Returns the active workspace object, null if loading, or undefined if not in a workspace
const useActiveWorkspace = () => {
  const userContext = useUser();

  return useMemo(() => {
    const activeWorkspaceId = userContext?.activeWorkspaceId;
    const workspaces = userContext.workspaces;
    if (!activeWorkspaceId || !workspaces.length) return null;
    return workspaces.find(({ id }) => id === activeWorkspaceId);
  }, [userContext?.activeWorkspaceId, userContext.workspaces]);
};

export default useActiveWorkspace;
