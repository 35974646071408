import TemplateSelectDropdown from "@/components/TemplateSelectDropdown";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { usePromptVersions } from "@/queries";
import { CheckIcon } from "@heroicons/react/outline";
import { FC } from "react";

interface PromptTemplateFieldProps {
  promptTemplate:
    | { id: number; name: string; version_numbers?: number[] }
    | undefined;
  setPromptTemplate: (
    template:
      | { id: number; name: string; version_numbers?: number[] }
      | undefined,
  ) => void;
  promptTemplatesIsLoading: boolean;
  promptTemplates: any[];
}

const PromptTemplateField: FC<PromptTemplateFieldProps> = ({
  promptTemplate,
  setPromptTemplate,
  promptTemplatesIsLoading,
  promptTemplates,
}) => {
  const authContext = useAuth();
  const userContext = useUser();
  const userToken = authContext!.userToken!;
  const workspaceId = userContext?.activeWorkspaceId!;

  const promptVersions = usePromptVersions(userToken, {
    workspaceId,
    promptRegistryId: promptTemplate?.id,
    perPage: Number.MAX_SAFE_INTEGER,
  });

  const versions =
    promptVersions?.data?.pages.flatMap((page) => page.items) || [];

  const renderPromptTemplateVersionsField = () => {
    if (!promptTemplate) return null;

    const versionNumberOptions = versions
      .map((version) => Number(version.number))
      .sort((a, b) => a - b);

    const handleAddOrRemoveVersion = (version: number) => {
      if (!promptTemplate.version_numbers) {
        setPromptTemplate({
          ...promptTemplate,
          version_numbers: [version],
        });
      } else {
        const versionIndex = promptTemplate.version_numbers.indexOf(version);
        let updatedVersions = [...promptTemplate.version_numbers];
        if (versionIndex === -1) {
          updatedVersions.push(version);
        } else {
          updatedVersions.splice(versionIndex, 1);
        }
        setPromptTemplate({
          ...promptTemplate,
          version_numbers: updatedVersions,
        });
      }
    };

    return (
      <>
        <label
          className="text-left text-sm font-semibold text-gray-500"
          htmlFor="prompt-template-versions"
        >
          Prompt Template Versions:
        </label>
        <DropdownMenu>
          <DropdownMenuTrigger className="col-span-2 w-full">
            {!promptTemplate?.version_numbers ||
            promptTemplate.version_numbers.length === 0
              ? "Using all versions by default..."
              : promptTemplate.version_numbers.join(", ")}
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {versionNumberOptions.map((version, index) => (
              <DropdownMenuItem
                key={index}
                onSelect={() => handleAddOrRemoveVersion(version)}
              >
                {promptTemplate.version_numbers?.includes(version) ? (
                  <CheckIcon className="mr-1 h-4 w-auto" />
                ) : null}
                {version}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </>
    );
  };

  return (
    <>
      <label
        className="text-left text-sm font-semibold text-gray-500"
        htmlFor="prompt-template"
      >
        Prompt Template:
      </label>
      <TemplateSelectDropdown
        dropdownTriggerclassName="col-span-2 w-full"
        selectedTemplateName={
          promptTemplatesIsLoading
            ? "Loading..."
            : promptTemplate?.name && promptTemplate.name.length > 30
            ? promptTemplate.name.substring(0, 30) + "..."
            : promptTemplate?.name
        }
        handleTemplateSelection={({ id, name }) =>
          setPromptTemplate({
            id,
            name,
          })
        }
        disabled={promptTemplatesIsLoading || promptTemplates?.length === 0}
      />
      {renderPromptTemplateVersionsField()}
    </>
  );
};

export default PromptTemplateField;
