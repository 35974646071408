import { XIcon } from "@heroicons/react/solid";
import { ImageIcon } from "@radix-ui/react-icons";
import {
  FormEvent,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";

import LoadingSpinner from "@/components/LoadingSpinner";
import { ToolCalls } from "@/components/ToolCalls";
import ButtonCopy from "@/components/button-copy";
import ButtonMaximize from "@/components/button-maximize";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import { useAuth } from "@/context/auth-context";
import { useUpload } from "@/queries";
import { Message, roles } from "@/types";
import { classNames } from "@/utils/strings";
import { copyTextToClipboard, getStringContent } from "@/utils/utils";

type ChatMessageEditorProps = {
  message: Message;
  messageKey: string;
  onKeyDown: (event: any) => void;
  onPressDelete: () => void;
  updateKeyInMessage: (updateFunc: (message: Message) => Message) => void;
};

const ChatMessageEditor = ({
  message,
  messageKey,
  onKeyDown,
  onPressDelete,
  updateKeyInMessage,
}: ChatMessageEditorProps) => {
  const [expanded, toggleExpanded] = useReducer((s) => !s, false);
  const [isDropping, setIsDropping] = useState(false);
  const auth = useAuth();
  const upload = useUpload();
  const isFirstMessage = messageKey === `message_0`;
  const ref = useRef<HTMLTextAreaElement>(null);
  const isRoleUser = message.role === "user";

  const handleUpdateKey = useCallback(
    (updateFunc: (message: Message, value: string) => Message) =>
      (event: any) => {
        updateKeyInMessage((message) =>
          updateFunc(message, event.currentTarget.value),
        );
      },
    [updateKeyInMessage],
  );

  const handleRoleUpdate = (role: Message["role"]) =>
    updateKeyInMessage(() => ({
      role,
      input_variables: [],
      content: [],
      template_format: "f-string",
      name: "",
      tool_call_id: "",
    }));

  useEffect(() => {
    if (ref.current) {
      ref.current.style.height = "auto";
      ref.current.style.height = ref.current.scrollHeight + "px";
    }
  }, [ref]);

  const onInput = useCallback(
    (e: FormEvent<HTMLTextAreaElement> | undefined) => {
      if (e && !expanded) {
        e.currentTarget.style.height = "auto";
        e.currentTarget.style.height = e.currentTarget.scrollHeight + "px";
      }
    },
    [expanded],
  );

  const renderMessageBody = () => {
    if (message.role === "function") {
      /*
      Function type message. Example:
        {
          "content": "[('Iron Maiden', 213), ('U2', 135)]",
          "name": "ask_database",
          "role": "function"
        }
      */
      return (
        <Textarea
          ref={ref}
          onInput={onInput}
          className={`font-mono ${expanded && "h-full"} rounded-none`}
          placeholder='{"location": "Boston, MA", "temperature": "72"}'
          value={getStringContent(message)}
          onKeyDown={onKeyDown || null}
          onChange={handleUpdateKey((message, value) => ({
            ...message,
            content: [{ type: "text", text: value }],
          }))}
        />
      );
    } else if (message.role === "assistant") {
      /*
      Assistant type message. Example:
        {
          "content": null,
          "function_call": {
            "arguments": "{...}",
            "name": "ask_database"
          },
          "role": "assistant"
        }
        or
        {
          "content": "The Ultimate Travel Bucket List",
          "role": "assistant"
        },
      */

      // Check if message is of type AssistantFunctionCallMessage or AssistantStandardMessage
      if (message.function_call) {
        return (
          <div className="space-y-2">
            <Textarea
              ref={ref}
              onInput={onInput}
              className={`font-mono ${expanded && "h-full"} rounded-none`}
              placeholder='{"location": "Boston, MA", "temperature": "72"}'
              value={message.function_call?.arguments ?? ""}
              onKeyDown={onKeyDown || null}
              onChange={handleUpdateKey((message, value) => ({
                ...message,
                function_call:
                  message.role === "assistant"
                    ? {
                        arguments: value,
                        name: message.function_call?.name ?? "",
                      }
                    : undefined,
              }))}
            />
            <Textarea
              ref={ref}
              onInput={onInput}
              className={`${expanded && "h-full"} relative z-10 rounded-none`}
              placeholder={
                "Optional assistant string message with the function call"
              }
              value={getStringContent(message)}
              onKeyDown={onKeyDown || null}
              onChange={handleUpdateKey((message, value) => ({
                ...message,
                content: [{ type: "text", text: value }],
              }))}
            />
            <ToolCalls
              message={message}
              setMessage={(newMessage) => updateKeyInMessage(() => newMessage)}
            />
          </div>
        );
      } else {
        return (
          <div className={`flex ${expanded && "h-full"} flex-col gap-2`}>
            <Textarea
              ref={ref}
              onInput={onInput}
              className={`${expanded && "h-full"} rounded-none`}
              value={getStringContent(message)}
              onKeyDown={onKeyDown || null}
              onChange={handleUpdateKey((message, value) => ({
                ...message,
                content: [{ type: "text", text: value }],
              }))}
            />
            <ToolCalls
              message={message}
              setMessage={(newMessage) => updateKeyInMessage(() => newMessage)}
            />
          </div>
        );
      }
    } else if (message.role === "tool") {
      return (
        <Textarea
          ref={ref}
          onInput={onInput}
          className={`font-mono ${expanded && "h-full"} rounded-none`}
          placeholder='{"location": "Boston, MA", "temperature": "72"}'
          value={getStringContent(message)}
          onKeyDown={onKeyDown || null}
          onChange={handleUpdateKey((message, value) => ({
            ...message,
            content: [{ type: "text", text: value }],
          }))}
        />
      );
    } else {
      /*
      User type message. Example:
        {
          "content": "What's your name?",
          "role": "user"
        },
      */
      return (
        <div className={`${expanded && "h-full"}`}>
          <Textarea
            ref={ref}
            onInput={(e) => {
              if (!expanded) {
                e.currentTarget.style.height = "auto";
                e.currentTarget.style.height =
                  e.currentTarget.scrollHeight + "px";
              }
            }}
            className={`${expanded && "h-full"} rounded-none`}
            value={getStringContent(message)}
            onKeyDown={onKeyDown || null}
            onChange={(e) => {
              const value = e.currentTarget.value;
              // we are not supporting multiple texts, so when text is updated
              // we assume that the user only updates the first text
              updateKeyInMessage(() => ({
                ...message,
                name: message.role === "placeholder" ? value : "",
                content: [
                  ...(message.content?.filter(
                    (content) => content.type !== "text",
                  ) ?? []),
                  { type: "text", text: value },
                ],
              }));
            }}
          />
          <div
            className={`mt-1 text-xs text-gray-500 ${
              message.role === "placeholder" ? "" : "hidden"
            }`}
          >
            This placeholder is used to inject one or more messages into the
            prompt
          </div>
        </div>
      );
    }
  };

  const messageForm = (
    <div className={`${expanded && "h-full"}`}>
      <div className="flex flex-col gap-2">
        <div className="flex flex-row justify-between">
          {/* Select Role */}
          <Select
            defaultValue={isFirstMessage ? "user" : message.role}
            onValueChange={handleRoleUpdate}
            value={message.role}
          >
            <SelectTrigger className="rounded-none border border-gray-200 bg-white font-bold uppercase shadow-none group-hover:border-gray-300 group-hover:text-black">
              <SelectValue placeholder="Select a role" />
            </SelectTrigger>
            <SelectContent className="rounded-none bg-white capitalize">
              <SelectGroup>
                <SelectLabel>Roles</SelectLabel>
                {roles.map((role) => (
                  <SelectItem key={role} value={role}>
                    {role}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
          {message.role === "function" && (
            <input
              className="ml-2 h-9 rounded-none border-gray-200 font-mono text-sm focus:ring-0 group-hover:border-gray-300"
              type="text"
              placeholder="Function Name"
              value={message.name ?? ""}
              onKeyDown={onKeyDown || null}
              onChange={handleUpdateKey((message, name) => ({
                ...message,
                name,
              }))}
            />
          )}
          {(message.role === "assistant" || message.role === "tool") && (
            <input
              className="ml-2 h-9 rounded-none border-gray-200 font-mono text-sm focus:ring-0 group-hover:border-gray-300"
              type="text"
              placeholder={
                message.role === "tool" ? "Tool Call ID" : "Function Name"
              }
              value={
                message.role === "tool"
                  ? message.tool_call_id
                  : message.function_call?.name ?? ""
              }
              onKeyDown={onKeyDown || null}
              onChange={handleUpdateKey((message, name) => ({
                ...message,
                content:
                  message.role === "tool"
                    ? message.content ?? []
                    : name
                    ? []
                    : message.content ?? [],
                function_call:
                  message.role === "assistant"
                    ? {
                        arguments: message.function_call?.arguments ?? "",
                        name,
                      }
                    : undefined,
                tool_call_id: message.role === "tool" ? name : "",
              }))}
            />
          )}
          {/* Delete Message */}
          {!expanded && (
            <button
              className="ml-2 text-gray-400 hover:text-gray-500"
              type="button"
              onClick={onPressDelete}
            >
              <XIcon className="h-4 w-4" />
            </button>
          )}
        </div>
      </div>
      <div className="flex h-5/6 flex-col pt-2">{renderMessageBody()}</div>
    </div>
  );

  const updateImageUrl = (url: string) => {
    updateKeyInMessage(() => ({
      ...message,
      content:
        message.content?.concat({
          type: "image_url",
          image_url: { url },
        }) ?? [],
    }));
  };

  const handleImage = async (file?: File) => {
    if (!file || !auth?.userToken) return;
    upload.mutate(
      { userToken: auth.userToken, file },
      {
        onSuccess: (data) => {
          if (!data) return;
          updateImageUrl(data.file_url);
        },
      },
    );
  };

  const handleRemoveImage = (index: number) => {
    updateKeyInMessage(() => ({
      ...message,
      content: message.content?.filter((_, i) => i !== index) ?? [],
    }));
  };

  return (
    <div
      onDragOver={(e) => {
        if (isRoleUser) {
          e.preventDefault();
          setIsDropping(true);
        }
      }}
      onDragLeave={(e) => {
        if (isRoleUser) {
          e.preventDefault();
          setIsDropping(false);
        }
      }}
      onDrop={(e) => {
        if (isRoleUser) {
          e.preventDefault();
          const file = e.dataTransfer.files?.[0];
          handleImage(file);
          setIsDropping(false);
        }
      }}
      className={`${classNames("w-full rounded-md border-2 px-2 pt-4 ")} ${
        isDropping
          ? "border-2 border-dashed border-blue-600 bg-blue-100"
          : "border-transparent bg-white"
      }`}
    >
      {messageForm}
      <div className="grid grid-cols-4 gap-2 py-1">
        {isRoleUser && message.content instanceof Array
          ? message.content.map((content, index) =>
              content.type === "image_url" && content.image_url.url ? (
                <div key={index} className="relative h-40 w-full">
                  <button
                    className="absolute right-1 top-1 rounded-full bg-gray-700 p-1 text-white hover:bg-gray-500"
                    onClick={() => handleRemoveImage(index)}
                  >
                    <XIcon className="h-4 w-4" />
                  </button>
                  <img
                    src={content.image_url.url}
                    className="h-full w-full rounded-md object-cover"
                    alt="User uploaded"
                  />
                </div>
              ) : null,
            )
          : null}
      </div>
      <div
        className={`flex items-center border-b pb-1 ${
          isRoleUser ? "justify-between" : "justify-end"
        }`}
      >
        {isRoleUser ? (
          <div className="flex items-center gap-2">
            <Button variant="link" className="p-0" asChild>
              <Label className="cursor-pointer" htmlFor="image">
                {upload.isLoading ? (
                  <LoadingSpinner size={5} />
                ) : (
                  <ImageIcon className="h-5 w-5" />
                )}
                <Input
                  id="image"
                  type="file"
                  accept="image/*"
                  className="sr-only"
                  onChange={(e) => {
                    handleImage(e.target.files?.[0]);
                  }}
                />
              </Label>
            </Button>
            <div className="text-xs text-gray-500">Click or drag to upload</div>
          </div>
        ) : null}
        <div className={`flex items-center gap-2`}>
          <ButtonCopy
            onClick={async () => {
              let copyText = "";
              if (message.role === "assistant") {
                if (message.function_call) {
                  copyText = message.function_call.arguments;
                } else {
                  copyText = getStringContent(message);
                }
              } else {
                copyText = getStringContent(message);
              }
              await copyTextToClipboard(copyText);
            }}
          />
          <Dialog open={expanded} onOpenChange={toggleExpanded}>
            <DialogTrigger asChild>
              <ButtonMaximize />
            </DialogTrigger>
            <DialogContent className="group h-full max-w-full">
              {messageForm}
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default ChatMessageEditor;
