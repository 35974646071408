import { AuthContextType } from "@/context/auth-context";
import { jsonAuthHeaders } from "@/utils/headers";

export const adminLogin = async ({
  admin_email,
  password,
  target_user_email,
}: {
  admin_email: string;
  password: string;
  target_user_email: string;
}): Promise<Response> => {
  return fetch(ENDPOINTS.admin_login, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      admin_email,
      password,
      target_user_email,
    }),
  });
};

export const createAccount = async ({
  email,
  password,
  gclid,
}: {
  email: string;
  password: string;
  gclid?: string;
}): Promise<Response> => {
  return fetch(ENDPOINTS.create_account, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
      password,
      gclid,
    }),
  });
};

export const generateNewApiKey = (
  authContext: AuthContextType,
  workspaceId: number,
) => {
  if (!authContext) {
    return Promise.resolve(
      new Response(null, { status: 401, statusText: "Unauthorized" }),
    );
  }

  const userToken = authContext.userToken;

  return fetch(ENDPOINTS.create_api_key, {
    method: "POST",
    headers: jsonAuthHeaders(userToken!),
    body: JSON.stringify({
      workspace_id: workspaceId,
    }),
  });
};

export const login = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<Response> => {
  return fetch(ENDPOINTS.login, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
      password,
    }),
  });
};

export const forgotPassword = async ({
  email,
}: {
  email: string;
}): Promise<Response> => {
  return fetch(ENDPOINTS.forgot_password, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
    }),
  });
};

export const resetPassword = async ({
  code,
  email,
  password,
}: {
  code: string;
  email: string;
  password: string;
}): Promise<Response> => {
  return fetch(ENDPOINTS.reset_password, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      code,
      email,
      password,
    }),
  });
};

export const toggleSharableRequest = (
  authContext: AuthContextType,
  id: number,
  newVal: boolean,
) => {
  const userToken = authContext.userToken;
  return fetch(ENDPOINTS.toggle_sharable_request, {
    method: "POST",
    headers: jsonAuthHeaders(userToken!),
    body: JSON.stringify({
      request_id: id,
      new_value: newVal,
    }),
  });
};

export const MeticulousProjectId =
  process.env.REACT_APP_METICULOUS_PROJECT_ID ||
  "CCPRmTSb6x60EJmZcuGb5CDc5rxQg45ISY0wTNRx";

export const API_URL =
  process.env.REACT_APP_API_URL_OVERRIDE || "https://api.promptlayer.com";

export const API_URL_WS =
  process.env.REACT_APP_API_URL_WS_OVERRIDE || "wss://api.promptlayer.com";

export const ENDPOINTS = {
  add_request_log_to_dataset: `${API_URL}/add-request-log-to-dataset`,
  get_api_keys: `${API_URL}/api-keys`,
  admin_login: `${API_URL}/admin-login`,
  available_release_labels: `${API_URL}/available-release-labels`,
  branch_prompt_template_version: `${API_URL}/branch-prompt-template-version`,
  comments: `${API_URL}/comments`,
  create_account: `${API_URL}/create-account`,
  create_api_key: `${API_URL}/create-api-key`,
  create_application: `${API_URL}/create-application`,
  create_individual_run: `${API_URL}/create-individual-run`,
  create_stripe_checkout_session: `${API_URL}/create-stripe-checkout-session`,
  create_test_dataset: `${API_URL}/test-dataset-from-file`,
  dataset_column_to_json: `${API_URL}/dataset-column-to-json`,
  dataset_columns: `${API_URL}/dataset-columns`,
  dataset_groups: `${API_URL}/dataset-groups`,
  dataset_info: `${API_URL}/dataset-info`,
  dataset_rows: `${API_URL}/dataset-rows`,
  dataset_rows_from_filter_params: `${API_URL}/dataset-rows-from-filter-params`,
  datasets: `${API_URL}/datasets`,
  delete_api_key: `${API_URL}/delete-api-key`,
  delete_prompt_template: `${API_URL}/delete-prompt-template`,
  delete_user: `${API_URL}/delete-user`,
  draft_dataset_information: `${API_URL}/draft-dataset-information`,
  draft_dataset_state_from_dataset: `${API_URL}/draft-dataset-state-from-dataset`,
  duplicate_prompt_template_to_workspace: `${API_URL}/duplicate-prompt-template-to-workspace`,
  dynamic_release_labels_by_prompt_registry: `${API_URL}/dynamic-release-labels-by-prompt-registry`,
  final_reports: `${API_URL}/final-reports`,
  fine_tuned_job: `${API_URL}/fine-tuned-job`,
  fine_tuned_model: `${API_URL}/fine-tuned-model`,
  folders: `${API_URL}/folders`,
  forgot_password: `${API_URL}/forgot-password`,
  get_global_latency_public: `${API_URL}/get-global-latency-public`,
  get_group: `${API_URL}/get-group`,
  get_groups_per_request: `${API_URL}/get-groups-per-request`,
  get_intercom_verification: `${API_URL}/get-intercom-verification`,
  get_provider_api_key_status: `${API_URL}/get-provider-api-key-status`,
  get_request_counts_for_versions: `${API_URL}/get-request-counts-for-versions`,
  get_request_stats_for_prompt: `${API_URL}/get-request-stats-for-prompt`,
  get_requests: `${API_URL}/get-requests`,
  get_requests_for_prompt: `${API_URL}/get-requests-for-prompt`,
  get_shared_request: `${API_URL}/get-shared-request`,
  get_user: `${API_URL}/get-user`,
  get_user_by_email: `${API_URL}/get-user-by-email`,
  get_user_subscription_status: `${API_URL}/get-user-subscription-status`,
  individual_run_requests: `${API_URL}/individual-run-requests`,
  list_applications: `${API_URL}/list-applications`,
  list_users: `${API_URL}/list-users`,
  login: `${API_URL}/login`,
  metadata_fields: `${API_URL}/metadata-fields`,
  move_prompt_template: `${API_URL}/move-prompt-template`,
  parse_input_variables: `${API_URL}/prompt-templates/parse-input-variables`,
  playground_config_from_prompt_version: `${API_URL}/playground-config-from-prompt-version`,
  playground_config_from_request_log: `${API_URL}/playground-config-from-request-log`,
  prompt_labels: `${API_URL}/prompt-labels`,
  prompt_registry_objects: `${API_URL}/prompt-registry-objects`,
  prompt_registry_tags: `${API_URL}/prompt-registry/tags`,
  prompt_templates: `${API_URL}/prompt-templates`,
  prompt_versions: `${API_URL}/prompt-versions`,
  prompts: `${API_URL}/prompts`,
  provider_base_urls: `${API_URL}/provider-base-urls`,
  release_label_groups: `${API_URL}/release-label-groups`,
  report_cells: `${API_URL}/report-cells`,
  report_columns: `${API_URL}/report-columns`,
  reports: `${API_URL}/reports`,
  request_stats_by_metadata_field: `${API_URL}/request-stats-by-metadata-field`,
  request_stats_by_prompt: `${API_URL}/request-stats-by-prompt`,
  request_stats_by_tag: `${API_URL}/request-stats-by-tag`,
  reset_password: `${API_URL}/reset-password`,
  save_dataset: `${API_URL}/save-dataset`,
  score_names: `${API_URL}/score-names`,
  set_or_remove_azure_keys: `${API_URL}/set-or-remove-azure-keys`,
  set_or_remove_bedrock_keys: `${API_URL}/set-or-remove-bedrock-keys`,
  set_or_remove_provider_api_key: `${API_URL}/set-or-remove-provider-api-key`,
  set_user_to_free_plan: `${API_URL}/set-user-to-free-plan`,
  spans: `${API_URL}/spans`,
  statistics_page: `${API_URL}/statistics-page`,
  tags: `${API_URL}/tags`,
  threads: `${API_URL}/threads`,
  toggle_sharable_request: `${API_URL}/toggle-sharable-request`,
  toggle_starred_request: `${API_URL}/toggle-starred-request`,
  trace: `${API_URL}/trace`,
  track_metadata: `${API_URL}/rest/track-metadata`,
  track_score: `${API_URL}/track-score`,
  update_tags: `${API_URL}/update-tags`,
  upload: `${API_URL}/upload`,
  usage: `${API_URL}/usage`,
  webhooks: `${API_URL}/webhooks`,
  workspace_invite: `${API_URL}/workspace-invite`,
  workspace_members: `${API_URL}/workspace-members`,
  workspaces: `${API_URL}/workspaces`,
  ws: `${API_URL_WS}`,
  ws_token_request: `${API_URL}/ws-token-request`,
  backtests: `${API_URL}/backtests`,
  ab_tests: `${API_URL}/ab-tests`,
  verify_dynamic_release_label_existence: `${API_URL}/verify-dynamic-release-label-existence`,
  file_upload_multipart: `${API_URL}/file-upload-multipart`,
};
