import styled from "styled-components";

export const Column = styled.div`
  flex: 0 0 100%;
  max-width: 100%;
  min-height: 1px;
  padding: 0 15px;
  position: relative;
  width: 100%;

  @media (min-width: 769px) {
    flex: 0 0 33%;
    max-width: 33%;
  }
`;

export const CompanyItem = styled.div`
  margin-top: 42px;
  max-width: 285px;
  width: 100%;

  h4 {
    color: #000000;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.01em;
    margin: 12px 0 6px;
  }

  p {
    color: #666666;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.01em;
    margin: 0;
  }
`;

export const CompanyLogo = styled.a`
  display: inline-block;
  margin-right: 30px;
  transition: 0.2s all ease;

  @media (max-width: 575px) {
    margin: 0;
    padding-bottom: 15px;
    padding-right: 15px;
    width: 33.333%;
  }
`;

export const CompanyLogoImg = styled.img<{ $dim?: boolean }>`
  filter: grayscale(100%);
  max-width: 84%;
  opacity: ${({ $dim }) => ($dim ? "40%" : "50%")};
  transition: all 0.2s ease-in-out;
  width: 100px;

  &:hover {
    opacity: ${({ $dim }) => ($dim ? "70%" : "100%")};
  }

  @media (max-width: 767px) {
    max-width: 90%;
  }
`;

export const CompanyLogos = styled.div`
  align-items: center;
  display: flex;
  padding-top: 10px;

  @media (max-width: 575px) {
    flex-wrap: wrap;
  }
`;

export const CompanyMain = styled.div`
  h2 {
    color: #090b1c;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.01em;
    padding-top: 60px;
  }
`;

export const CompanyUpper = styled.div`
  h2 {
    color: #666666;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.01em;
    margin-bottom: 8px;
  }

  @media (max-width: 768px) {
    margin-top: 30px;
  }
`;

export const Container = styled.section`
  background: #eff0f8;
  margin-top: -90px;
  padding: 60px 0 73px;
`;

export const InnerContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1150px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`;
