import { FC } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "@/assets/logo/logo.png";
import discordLogo from "@/assets/social_icons/discord.png";
import githubLogo from "@/assets/social_icons/github.png";
import twitterLogo from "@/assets/social_icons/twitter.png";
import * as S from "./Styles";

const Footer: FC = () => {
  const { pathname } = useLocation();

  const renderCopyright = () => {
    return (
      <S.Copyright>
        <p>
          © Copyright {new Date().getFullYear()} Magniv, Inc. All rights
          reserved.
        </p>
      </S.Copyright>
    );
  };

  const renderFooterMain = () => {
    return (
      <S.FooterMain>
        <S.FooterLeft>
          <Link to="/">
            <S.Logo alt="PromptLayer logo" src={logo} />
          </Link>
          <p>Made in NYC 🗽</p>
          <a href="mailto:hello@promptlayer.com">hello@promptlayer.com</a>
        </S.FooterLeft>
        <S.FooterItem>
          <h4>Usage</h4>
          <ul>
            <li>
              <a href="https://docs.promptlayer.com">Docs</a>
            </li>
            <li>
              <a href="https://promptlayer.com/latency">GPT Latency Graph</a>
            </li>
            <li>
              <a href="https://blog.promptlayer.com/">Blog</a>
            </li>
            <li>
              <a href="https://promptlayer.com/case-studies">Case Studies</a>
            </li>
          </ul>
        </S.FooterItem>
        <S.FooterItem>
          <h4>Company</h4>
          <ul>
            <li>
              <a href="mailto:hello@promptlayer.com">Email Us</a>
            </li>
            <li>
              <a href="https://discord.gg/DBAhQbW39S">Discord</a>
            </li>
            {/* <li>
              <a href="https://promptlayer.com/jobs">Jobs</a>
            </li> */}
          </ul>
        </S.FooterItem>
        <S.FooterItem>
          <h4>Follow Us</h4>
          <S.SocialIcons>
            <li>
              <a href="https://twitter.com/promptlayer">
                <img alt="Twitter logo" src={twitterLogo} />
              </a>
            </li>
            <li>
              <a href="https://github.com/MagnivOrg/prompt-layer-library">
                <img alt="Github logo" src={githubLogo} />
              </a>
            </li>
            <li>
              <a href="https://discord.gg/DBAhQbW39S">
                <img alt="Discord logo" src={discordLogo} />
              </a>
            </li>
          </S.SocialIcons>
        </S.FooterItem>
      </S.FooterMain>
    );
  };

  const renderFooterUpper = () => {
    if (pathname !== "/" && pathname !== "/case-studies") return null;

    return (
      <S.FooterUpper>
        <h2>
          The first platform built for <span>prompt engineering</span>
        </h2>
        <Link to="/create-account">
          <S.Button>Start for free</S.Button>
        </Link>
      </S.FooterUpper>
    );
  };

  return (
    <S.Container>
      <S.InnerContainer>
        {renderFooterUpper()}
        {renderFooterMain()}
        {renderCopyright()}
      </S.InnerContainer>
    </S.Container>
  );
};

export default Footer;
