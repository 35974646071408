import { Switch } from "../../switch";

export const InteractiveEditorSwitch = ({
  depth,
  isItemDescription,
  setIsItemDescription,
  handleItemSwitch,
  setParameters,
  onChange,
  outerType,
}: {
  depth: number;
  isItemDescription?: boolean;
  setIsItemDescription: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >;
  handleItemSwitch: (
    description: boolean,
    setParameters: (params: any[]) => void,
    onChange: (params: any) => void,
  ) => void;
  setParameters: (params: any[]) => void;
  onChange: (params: any) => void;
  outerType?: string;
}) => {
  if (depth === 0) return null;

  if (outerType === "object") {
    setIsItemDescription(false);
    return null;
  }

  return (
    <div className="flex items-center justify-center gap-4 pb-2 text-xs">
      <span>Composition</span>
      <Switch
        checked={isItemDescription}
        onChange={() => {
          setIsItemDescription(!isItemDescription);
          handleItemSwitch(!isItemDescription, setParameters, onChange);
        }}
        className="h-4 w-4"
      />
      <span>Description</span>
    </div>
  );
};
