import LoadingSpinner from "@/components/LoadingSpinner";
import { CheckIcon, ClockIcon, XCircleIcon } from "@heroicons/react/outline";
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from "@radix-ui/react-tooltip";

const statusIcons = {
  completed: <CheckIcon className="h-5 w-5 text-green-500" />,
  running: <LoadingSpinner size={5} />,
  queued: <ClockIcon className="h-6 w-6 cursor-default text-gray-300" />,
  failed: <XCircleIcon className="h-6 w-6 cursor-default text-red-500" />,
  loading: <LoadingSpinner size={5} />,
};

export const StatusCell = ({
  status,
}: {
  status: keyof typeof statusIcons;
}) => (
  <div className="flex justify-center">
    <Tooltip delayDuration={0}>
      <TooltipTrigger>{statusIcons[status]}</TooltipTrigger>
      <TooltipContent
        className="rounded bg-gray-800 px-2 py-1 text-sm text-white"
        sideOffset={5}
      >
        {status.charAt(0).toUpperCase() + status.slice(1)}
        <TooltipArrow className="fill-current text-gray-800" />
      </TooltipContent>
    </Tooltip>
  </div>
);
