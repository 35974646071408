import { Button } from "@/components/ui/button";
import { HumanConfiguration, SliderUIElement } from "@/types/evaluate";

export const HumanInputUIComponent = ({
  uiComponent,
  dataType,
  config,
  value,
  setValue,
  open,
  setOpen,
  isLastColumn,
}: {
  uiComponent: string;
  dataType: string;
  config: HumanConfiguration;
  value: any | null;
  setValue: (value: any) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
  isLastColumn: boolean;
}) => {
  if (!open) {
    return (
      <div className="flex items-center justify-center gap-x-2">
        <Button
          size="sm"
          variant="outline"
          onClick={() => setOpen(true)}
          className="text-gray-500 hover:text-gray-600"
        >
          {isLastColumn ? "Enter Value" : "Enter Value to Continue"}
        </Button>
      </div>
    );
  }

  if (uiComponent === "text") {
    if (dataType === "number") {
      return (
        <input
          className={`col-span-2 w-full rounded border border-gray-300 px-2 py-1 font-mono`}
          value={value}
          type="number"
          onChange={(e) => setValue(Number(e.target.value))}
        />
      );
    } else {
      return (
        <textarea
          className={`col-span-2 w-full rounded border border-gray-300 px-2 py-1 text-sm`}
          value={value}
          rows={4}
          onChange={(e) => setValue(e.target.value)}
        />
      );
    }
  } else if (uiComponent === "slider") {
    const sliderConfig = config.ui_element as SliderUIElement;
    const min = sliderConfig.min || 0;
    const max = sliderConfig.max || 100;
    return (
      <div>
        <div className="flex items-center justify-center gap-x-2 text-xs text-gray-500">
          <span>{min}</span>
          <input
            type="range"
            id="slider"
            className={`rounded border ${
              !value && value !== 0
                ? "opacity-10 hover:opacity-90"
                : "border-gray-300"
            } cursor-pointer py-1`}
            min={min}
            max={max}
            defaultValue={50}
            value={value}
            onChange={(e) => setValue(Number(e.target.value))}
          />
          <span>{max}</span>
        </div>
      </div>
    );
  }
  return <div>Unknown UI component</div>;
};
