import styled from "styled-components";

export const Container = styled.button`
  border-radius: 4px;
  border: 1px solid rgba(81, 103, 240, 0.4);
  color: #5167f0;
  display: inline-block;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.02em;
  padding: 8px 20px;
  transition: 0.2s all ease;

  &:hover {
    background-color: #5167f0;
    color: #ffffff;
  }
`;
