import "./App.css";
import { ArrowTopRightIcon } from "@radix-ui/react-icons";
import ellipsisThumbnail from "@/assets/case-studies/thumbnails/ellipsis.png";
import gorgiasThumbnail from "@/assets/case-studies/thumbnails/gorgias.png";
import meticulateThumbnail from "@/assets/case-studies/thumbnails/meticulate.png";
import parentLabThumbnail from "@/assets/case-studies/thumbnails/parent-lab.png";
import speakThumbnail from "@/assets/case-studies/thumbnails/speak.png";
import parentLabLogo from "@/assets/company_logos/parentlab.svg";
import graphic from "@/assets/case-studies/graphic.png";

import navySpeakLogo from "@/assets/company_logos/navy_logos/speak.svg";
import navyAppSumoLogo from "@/assets/company_logos/navy_logos/appsumo.svg";
import navyRogoLogo from "@/assets/company_logos/navy_logos/rogo.png";
import navyGorgiasLogo from "@/assets/company_logos/navy_logos/gorgias.svg";
import navySystemLogo from "@/assets/company_logos/navy_logos/system.svg";

type CaseStudyItem = {
  title: string;
  thumbnail: string;
  url: string;
  company: string;
};

const caseStudies: CaseStudyItem[] = [
  {
    title: "Gorgias Uses PromptLayer to Automate Customer Support at Scale",
    thumbnail: gorgiasThumbnail,
    company: "Gorgias",
    url: "https://blog.promptlayer.com/gorgias-uses-promptlayer-to-automate-customer-support-at-scale-7c129fbdbb8d",
  },
  {
    title: "How Speak Empowers Non-Technical Teams with Prompt Engineering",
    thumbnail: speakThumbnail,
    company: "Speak",
    url: "https://blog.promptlayer.com/how-speak-empowers-non-technical-teams-with-prompt-engineering-and-promptlayer-21fd2935cd56",
  },
  {
    title:
      "How ParentLab Builds Highly Personalized AI with Non-Technical Prompt Engineers",
    thumbnail: parentLabThumbnail,
    company: "ParentLab",
    url: "https://blog.promptlayer.com/how-promptlayer-enables-non-technical-prompt-engineering-at-parentlab-0b084cc9fde1",
  },
  {
    title: "How Ellipsis uses PromptLayer to Debug LLM Agents",
    thumbnail: ellipsisThumbnail,
    company: "Ellipsis",
    url: "https://blog.promptlayer.com/how-ellipsis-uses-promptlayer-to-debug-llm-agents-6e66c9b1cf9d",
  },
  {
    title: "From Zero to 1.5M Requests: How PromptLayer Powered a Viral Launch",
    thumbnail: meticulateThumbnail,
    company: "Meticulate",
    url: "https://blog.promptlayer.com/from-zero-to-1-5-million-requests-how-promptlayer-powered-meticulates-viral-launch-4b333b85a879",
  },
];

const CaseStudyCard: React.FC<{
  image: string;
  altText: string;
  title: string;
  link: string;
  company: string;
}> = ({ image, altText, title, link, company }) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="group flex h-full min-h-[350px] flex-col overflow-hidden"
    >
      <div className="flex flex-grow flex-col justify-between gap-y-2 pb-4">
        <div>
          <p className="pb-2 text-sm uppercase text-gray-500 group-hover:text-gray-400">
            {company}
          </p>
          <div className="flex flex-row">
            <h2 className="text-xl font-semibold text-gray-900 group-hover:text-gray-600">
              {title}
            </h2>
            <div className="flex justify-end pb-2 pl-2">
              <div className="mt-auto inline-flex items-center bg-blue-500 p-2 group-hover:bg-blue-600">
                <ArrowTopRightIcon className="h-4 w-4 text-white" />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-auto flex-shrink-0">
          <img
            src={image}
            alt={altText}
            className="h-48 w-full rounded-md object-cover transition-transform duration-300 group-hover:scale-105"
          />
        </div>
      </div>
    </a>
  );
};

export default function CaseStudiesPage() {
  return (
    <div className="relative overflow-hidden sm:mx-1">
      <div className="mx-auto max-w-4xl text-left">
        <div className="mx-4 flex flex-row justify-between sm:mx-0">
          <div className="pt-8 sm:pt-10">
            <h1 className="text-3xl font-medium tracking-wide text-gray-900 sm:leading-8 md:text-6xl">
              Case Studies
            </h1>
            <p className="mt-4 max-w-sm text-lg text-gray-500">
              Learn how teams are using PromptLayer to bring AGI to their
              verticals.
            </p>
          </div>
          <div className="hidden flex-shrink-0 py-4 sm:block">
            <img src={graphic} alt="Graphic" className="h-auto w-[400px]" />
          </div>
        </div>

        {/* Logos */}
        <div className="flex flex-row flex-wrap justify-center gap-x-8 gap-y-4 overflow-x-auto px-8 pt-8 sm:flex-nowrap sm:py-12">
          <a
            href="https://blog.promptlayer.com/gorgias-uses-promptlayer-to-automate-customer-support-at-scale-7c129fbdbb8d"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={navyGorgiasLogo}
              alt="Gorgias Logo"
              className="h-[30px] w-auto min-w-0"
            />
          </a>
          <a
            href="https://blog.promptlayer.com/how-speak-empowers-non-technical-teams-with-prompt-engineering-and-promptlayer-21fd2935cd56"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={navySpeakLogo}
              alt="Speak Logo"
              className="h-[30px] w-auto min-w-0"
            />
          </a>
          <img
            src={navyRogoLogo}
            alt="Rogo Logo"
            className="h-[30px] w-auto min-w-0"
          />
          <img
            src={navyAppSumoLogo}
            alt="AppSumo Logo"
            className="h-[25px] w-auto min-w-0"
          />
          <img
            src={navySystemLogo}
            alt="System Logo"
            className="h-[30px] w-auto min-w-0"
          />
        </div>

        {/* Quote */}
        <div className="mx-2 my-6 rounded-lg border border-orange-50 bg-orange-50/30 p-8 shadow-xl sm:mx-0 sm:border-0">
          <div className="flex flex-col items-center justify-between gap-x-2 pb-12 sm:flex-row">
            <img src={parentLabLogo} alt="Logo 1" className="h-9 flex-none" />
            <button
              className="mt-3 h-10 flex-none rounded-md bg-[#0a0d1a] px-4 py-2 text-white shadow-md hover:bg-[#0a0d1a]/90 focus:outline-none focus:ring-2 focus:ring-[#0a0d1a] focus:ring-offset-2 sm:mt-0"
              onClick={() =>
                window.open(
                  "https://blog.promptlayer.com/how-promptlayer-enables-non-technical-prompt-engineering-at-parentlab-0b084cc9fde1",
                  "_blank",
                )
              }
            >
              Read Case Study
            </button>
          </div>
          <p className="max-w-[90%] text-xl font-semibold text-gray-900 sm:text-3xl">
            <span className="text-5xl text-orange-500">“</span>
            <br />
            There is a relationship between my background as an educator and
            prompt engineering. I want to get the AI to talk like a teacher."
          </p>
        </div>

        {/* Case Studies Grid */}
        <div className="mx-5 mb-[280px] mt-14 grid grid-cols-1 gap-x-8 gap-y-8 sm:mx-0 md:grid-cols-2 lg:grid-cols-3">
          {caseStudies.map((study, index) => (
            <CaseStudyCard
              image={study.thumbnail}
              altText={study.title}
              title={study.title}
              company={study.company}
              link={study.url}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
