import { zodResolver } from "@hookform/resolvers/zod";
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { createAccount } from "./api/application-api";
import AuthSubmitButton from "./components/AuthSubmitButton";
import { useAuth } from "./context/auth-context";
import { useGclid } from "./hooks/useGclidTracking";

const createAccountSchema = z.object({
  email: z
    .string()
    .email({ message: "Invalid email format" })
    .nonempty({ message: "Email is required" }),
  password: z
    .string()
    .nonempty({ message: "Password is required" })
    .regex(
      /^(?=\S{6,100}$)(?=.*?\d)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[^A-Za-z\s0-9])/,
      {
        message:
          "Password must be at least 6 characters, have 1 digit, 1 lowercase, 1 uppercase, and 1 special character",
      },
    ),
  verifyPassword: z
    .string()
    .nonempty({ message: "Please verify your password" }),
});

declare global {
  interface Window {
    gtag: (
      type: "event",
      eventAction: string,
      eventParams: Record<string, any>,
    ) => void;
  }
}

const CreateAccount: FC = () => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const auth = useAuth();
  const navigate = useNavigate();
  const gclid = useGclid();

  useEffect(() => {
    if (auth?.userToken) navigate("/");
    window.gtag("event", "conversion", {
      send_to: "AW-10967408975/QuNCCO7DgckZEM_C1e0o",
    });
  }, [auth, navigate]);

  const form = useForm({
    defaultValues: {
      email: "",
      password: "",
      verifyPassword: "",
    },
    resolver: zodResolver(createAccountSchema),
  });

  const onSubmit = async (values: any) => {
    setLoading(true);

    try {
      if (values.password !== values.verifyPassword) {
        setError("Passwords do not match");
        return;
      }

      const response: any = await createAccount({
        email: values.email,
        password: values.password,
        gclid,
      });

      if (response.ok) {
        const { access_token, user } = await response.json();
        auth?.login(access_token, user);
        navigate("/");
        return;
      }

      const { message } = await response.json();
      setError(
        typeof message === "string"
          ? message
          : "Unexpected server response format. Please try again later.",
      );
    } catch (err) {
      console.error(err);
      setError("Something went wrong. Please try again later.");
    }

    setLoading(false);
  };

  return (
    <div className="mx-auto max-w-lg gap-8 rounded-sm border bg-gray-50 px-12 py-5 text-left">
      <div className="text-md py-3 text-center font-normal text-black">
        Create an Account
      </div>
      <form
        className="flex flex-col gap-3 py-3"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <input
          className="rounded-sm border border-gray-400 py-3"
          type="text"
          placeholder="Email"
          {...form.register("email")}
        />
        {form.formState.errors.email && (
          <div className="text-sm text-red-500">
            {form.formState.errors.email.message}
          </div>
        )}
        <input
          className="rounded-sm border border-gray-400 py-3"
          type="password"
          placeholder="Password"
          {...form.register("password")}
        />
        {form.formState.errors.password && (
          <div className="text-sm text-red-500">
            {form.formState.errors.password.message}
          </div>
        )}
        <input
          className="rounded-sm border border-gray-400 py-3"
          type="password"
          placeholder="Verify Password"
          {...form.register("verifyPassword")}
        />
        {form.formState.errors.verifyPassword && (
          <div className="text-sm text-red-500">
            {form.formState.errors.verifyPassword.message}
          </div>
        )}
        {error ? (
          <div className="py-2 text-center text-sm text-black">
            <span className="rounded-md bg-red-200 px-3 py-1">{error}</span>
          </div>
        ) : null}
        <AuthSubmitButton loading={loading} text="Create Account" />
      </form>
      <div className="text-center font-light text-gray-800">
        Already have an account?{" "}
        <button
          className="text-blue-500 hover:text-blue-400"
          onClick={() => navigate("/login")}
        >
          Login.
        </button>
      </div>
    </div>
  );
};

export default CreateAccount;
