import { FC } from "react";
import { CopyBlock, googlecode } from "react-code-blocks";

const code = `from promptlayer import PromptLayer
promptlayer_client = PromptLayer()

# Swap out 'from openai import OpenAI'
OpenAI = promptlayer_client.openai.OpenAI

client = OpenAI()
completion = client.chat.completions.create(
  model="gpt-3.5-turbo",
  messages=[
    {"role": "system", "content": "You are an AI."},
    {"role": "user", "content": "Compose a poem please."}
  ],
  pl_tags=["getting-started"]
)
print(completion.choices[0].message)`;

const anthropicCode = `from promptlayer import PromptLayer
promptlayer_client = PromptLayer()

# Swap out 'from anthropic import Anthropic'
Anthropic = promptlayer_client.anthropic.Anthropic

anthropic = Anthropic()
completion = anthropic.completions.create(
    model="claude-2",
    max_tokens_to_sample=300,
    prompt=f"{anthropic.HUMAN_PROMPT} Compose a poem please.{anthropic.AI_PROMPT}",
    pl_tags=["getting-started"]
)
print(completion.completion)
`;

interface BasicRequestExampleProps {
  selectedOption: string;
}

const BasicRequestExample: FC<BasicRequestExampleProps> = ({
  selectedOption,
}) => {
  const currentCode = selectedOption === "OpenAI" ? code : anthropicCode;

  return (
    <>
      <div className="mx-auto w-full px-2 text-left font-mono">
        <CopyBlock
          codeBlock
          language="python"
          showLineNumbers={false}
          text={currentCode}
          theme={googlecode}
          wrapLongLines={true}
        />
      </div>
    </>
  );
};

export default BasicRequestExample;
