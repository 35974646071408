import { ToolChoice } from "@/types";

const getOptionalParameters = (declaration: any): any[] => {
  const requiredParameters = declaration?.parameters?.required || [];
  const allParameters = Object.keys(declaration?.parameters?.properties || {});
  const optionalParameters = allParameters.filter(
    (param) => !requiredParameters.includes(param),
  );
  return optionalParameters;
};

interface ToolDeclaration {
  type: string;
  [key: string]: any;
}

export const ToolDeclarationsFormatter = ({
  toolDeclarations,
  toolChoice,
}: {
  toolDeclarations: ToolDeclaration[];
  toolChoice?: ToolChoice;
}) => {
  return (
    <div className="space-y-2">
      {toolDeclarations.map((declaration, index) => (
        <div key={index} className="pl-2">
          <h2 className="text-gray-900">{`${
            declaration.type.charAt(0).toUpperCase() + declaration.type.slice(1)
          }s`}</h2>
          <hr />
          <div className="pl-5 pt-2">
            {declaration.type === "function" ? (
              <>
                <div className="font-medium text-gray-900">
                  {declaration[declaration.type]?.name}
                </div>
                <div className="pl-2 pt-1 font-sans text-sm italic text-gray-500">
                  {declaration[declaration.type]?.description}
                </div>
                <div className="pl-2 pt-1 text-gray-600">
                  {declaration[declaration.type]?.parameters?.type ===
                  "object" ? (
                    <>
                      {declaration[declaration.type]?.parameters?.required
                        ?.length > 0 && (
                        <>
                          <div className="font-sans text-sm text-gray-900">
                            Required Parameters:
                          </div>
                          <ul className="py-1 pl-4">
                            {declaration[
                              declaration.type
                            ]?.parameters?.required.map((param: string) => (
                              <li key={param} className="pb-1 text-sm">
                                <span className="font-semibold text-gray-500">{`${param} (${declaration[
                                  declaration.type
                                ]?.parameters?.properties[param]
                                  ?.type})`}</span>
                                {declaration[declaration.type]?.parameters
                                  ?.properties[param]?.enum && (
                                  <span>
                                    {" "}
                                    <span className="font-mono text-sm font-semibold text-gray-500">
                                      [
                                      {declaration[
                                        declaration.type
                                      ]?.parameters?.properties[
                                        param
                                      ].enum.join(", ")}
                                      ]
                                    </span>
                                  </span>
                                )}
                                {declaration[declaration.type]?.parameters
                                  ?.properties[param]?.description && (
                                  <span className="font-sans text-sm italic text-gray-500">
                                    :{" "}
                                    {declaration[
                                      declaration.type
                                    ]?.parameters?.properties[
                                      param
                                    ]?.description.trim()}
                                  </span>
                                )}
                              </li>
                            ))}
                          </ul>
                        </>
                      )}
                      {getOptionalParameters(declaration[declaration.type])
                        .length > 0 && (
                        <>
                          <div className="font-sans text-sm text-gray-900">
                            Optional Parameters:
                          </div>
                          <ul className="py-1 pl-4">
                            {getOptionalParameters(
                              declaration[declaration.type],
                            ).map((key: string) => {
                              const value =
                                declaration[declaration.type]?.parameters
                                  ?.properties[key];
                              return (
                                <li key={key} className="pb-1 text-sm">
                                  <span className="font-semibold text-gray-500">{`${key} (${value?.type})`}</span>
                                  {value?.enum && (
                                    <span>
                                      {" "}
                                      <span className="font-mono text-sm font-semibold text-gray-500">
                                        [{value.enum.join(", ")}]
                                      </span>
                                    </span>
                                  )}
                                  {value?.description && (
                                    <span className="font-sans text-sm italic text-gray-500">
                                      : {value?.description?.trim()}
                                    </span>
                                  )}
                                </li>
                              );
                            })}
                          </ul>
                        </>
                      )}
                    </>
                  ) : (
                    <div>
                      {JSON.stringify(
                        declaration[declaration.type],
                        undefined,
                        2,
                      )}
                    </div>
                  )}
                </div>
              </>
            ) : (
              <div className="font-medium text-gray-900">
                {declaration[declaration.type]?.name}
              </div>
            )}
          </div>
        </div>
      ))}

      {toolChoice ? (
        <div className="font-medium text-gray-700">
          {"tool_choice: "}
          {typeof toolChoice === "string" ? (
            <span className="italic">{toolChoice}</span>
          ) : (
            <span className="font-mono">{toolChoice.function.name}</span>
          )}
        </div>
      ) : null}
    </div>
  );
};
