import UrlSearchParamsDisplay from "@/components/UrlSearchParamsDisplay";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { ToastType } from "@/enums";
import {
  useDatasetRowsFromFilterParams,
  usePromptRegistryObjects,
} from "@/queries";
import { displayToast } from "@/utils/toast";
import { parseUrlParams } from "@/utils/utils";
import { FC, useState } from "react";
import LimitField from "./ui/fields/LimitField";
import PromptTemplateField from "./ui/fields/PromptTemplateField";

type DatasetRowsFromFilterParamsModalProps = {
  dataset_id: number;
  setOpen: (isOpen: boolean) => void;
};

const DatasetRowsFromFilterParamsModal: FC<
  DatasetRowsFromFilterParamsModalProps
> = ({ dataset_id, setOpen }) => {
  const [limit, setLimit] = useState<number | undefined>(undefined);
  const [promptTemplate, setPromptTemplate] = useState<
    { id: number; name: string; version_numbers?: number[] } | undefined
  >(undefined);
  const authContext = useAuth();
  const userContext = useUser();
  const userToken = authContext!.userToken!;
  const workspaceId = userContext?.activeWorkspaceId!;

  const { mutateAsync: datasetRowsFromFilterParams, isLoading } =
    useDatasetRowsFromFilterParams(userToken!);

  const promptRegistryObjects = usePromptRegistryObjects(userToken, {
    workspaceId,
    perPage: Number.MAX_SAFE_INTEGER,
  });

  const promptTemplates =
    promptRegistryObjects?.data?.pages.flatMap((page) => page.items) || [];
  const promptTemplatesIsLoading = promptRegistryObjects.isLoading;

  const urlParams = new URLSearchParams(window.location.search);

  const { tags, metadata, favorites, query, scores, startTime, endTime } =
    parseUrlParams(urlParams);

  const handleClick = async () => {
    let queryParams: { [key: string]: any } = {};

    if (query) {
      queryParams["q"] = query;
    }

    if (tags.length > 0) {
      queryParams["tags"] = tags;
    }

    if (metadata.length > 0) {
      queryParams["metadata"] = metadata;
    }

    if (scores.length > 0) {
      queryParams["scores"] = scores.map((score) => ({
        name: score.name,
        operator: score.operator,
        value: score.value,
      }));
    }

    if (favorites.length > 0 && favorites[0] === "true") {
      queryParams["starred"] = true;
    }

    await datasetRowsFromFilterParams({
      dataset_id,
      start_time: startTime || undefined,
      end_time: endTime || undefined,
      limit: limit || undefined,
      metadata_and: metadata.map((md) => ({ key: md.key, value: md.value })),
      prompt_template: promptTemplate || undefined,
      q: queryParams.q,
      scores: queryParams.scores,
      tags_and: queryParams.tags,
      starred: queryParams.starred,
    });

    setOpen(false);
    displayToast("Requests added", ToastType.success);
  };

  return (
    <Dialog onOpenChange={setOpen} open={true}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Request History Filter Params</DialogTitle>
        </DialogHeader>
        <div className="grid max-w-lg grid-cols-3 items-center gap-y-2 py-2">
          <LimitField setLimit={setLimit} />
          <PromptTemplateField
            promptTemplate={promptTemplate}
            setPromptTemplate={setPromptTemplate}
            promptTemplatesIsLoading={promptTemplatesIsLoading}
            promptTemplates={promptTemplates}
          />
        </div>
        <div className="my-3 rounded-sm border border-gray-200 bg-gray-100 px-4">
          <UrlSearchParamsDisplay
            end_time={endTime || undefined}
            limit={limit}
            metadata_and={metadata}
            prompt_template={promptTemplate}
            q={query || undefined}
            scores={scores}
            starred={favorites.length > 0 ? favorites[0] === "true" : undefined}
            start_time={startTime || undefined}
            tags_and={tags}
            title="Sidebar Search Filters"
          />
        </div>
        <Button isLoading={isLoading} onClick={handleClick}>
          Add Requests
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default DatasetRowsFromFilterParamsModal;
