import { FC, ReactNode, useEffect, useRef, useState } from "react";
import { XIcon } from "@heroicons/react/solid";

type FeatureProps = {
  children: ReactNode;
  onCloseClick: () => void;
};

const Feature: FC<FeatureProps> = ({ children, onCloseClick }) => {
  const [isVisible, setIsVisible] = useState(false);
  const featureRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  useEffect(() => {
    if (isVisible && featureRef.current) {
      setTimeout(() => {
        featureRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 150);
    }
  }, [isVisible]);

  return (
    <div
      ref={featureRef}
      className={`relative transform rounded-lg bg-gray-100 p-6 transition-all duration-100 ease-in-out ${
        isVisible ? "translate-y-0 opacity-100" : "-translate-y-10 opacity-0"
      }`}
    >
      <div className="absolute right-4 top-4">
        <XIcon
          className="h-8 w-8 cursor-pointer rounded-full p-1 text-gray-400 hover:bg-gray-200"
          onClick={onCloseClick}
          style={{ transition: "background-color 0.2s ease" }}
        />
      </div>
      {children}
    </div>
  );
};

export default Feature;
