import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { openai } from "@/schemas";
import JSON5 from "json5";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Switch } from "../switch";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";
import { InteractiveFunctionParametersEditor } from "./InteractiveFunctionParametersEditor";

type IdentifiableFunction = openai._Function & { id: string };

type Props = {
  onSubmit: (values: IdentifiableFunction) => void;
  onCancel: () => void;
  functionNames: string[];
  isEditing: boolean;
  initialValues?: IdentifiableFunction;
};

const defaultFormValues: openai._Function = {
  name: "",
  description: "",
  parameters: {
    type: "object",
    properties: {},
  },
};

export const FunctionForm = ({
  onSubmit,
  onCancel,
  functionNames,
  isEditing,
  initialValues,
}: Props) => {
  const [mode, setMode] = useState<"json" | "interactive">("interactive");
  const initialJson = initialValues ?? defaultFormValues;
  const form = useForm<any>({
    defaultValues: { ...initialJson, json: initialJson },
  });

  const handleUpdateMode = useCallback(
    (newMode: "json" | "interactive") => {
      setMode(newMode);
      const formValues = form.getValues();
      if (newMode === "interactive") {
        // Switching from JSON to interactive
        let formattedJson: any = {};
        try {
          if (typeof formValues.json === "string") {
            formattedJson = JSON5.parse(formValues.json);
          } else {
            formattedJson = formValues.json;
          }
          Object.keys(formattedJson).forEach((key) => {
            form.setValue(key, formattedJson[key]);
          });
        } catch (error) {
          console.log(error, formValues.json);
          return;
        }
      } else {
        // Switching from interactive to JSON
        form.setValue("json", {
          name: formValues.name,
          description: formValues.description,
          parameters: formValues.parameters,
        });
      }
    },
    [form, setMode],
  );

  useEffect(() => {
    handleUpdateMode("interactive");
  }, [handleUpdateMode]);

  const handleSubmit = (values: any) => {
    let functionSchema = values.json;
    if (mode === "interactive") {
      functionSchema = {
        name: values.name,
        description: values.description,
        parameters: values.parameters,
        id: values.id,
      };
    } else if (typeof values.json === "string") {
      try {
        functionSchema = JSON5.parse(values.json);
      } catch (error) {
        alert("JSON schema invalid!");
        return;
      }
    }

    if (functionNames.includes(functionSchema.name) && !isEditing) {
      if (
        !window.confirm(
          "A function with this name already exists. Are you sure you want to overwrite it?",
        )
      ) {
        return;
      }
    }
    functionSchema.parameters.type = "object";

    onSubmit(functionSchema);
    form.reset(defaultFormValues);
  };

  const placeholderJson = `{
  "name": "get_weather",
  "description": "Determine weather in my location",
  "parameters": {
    "type": "object",
    "properties": {}
  }
}`;

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-8">
        <div className="flex items-center justify-center">
          <div className="flex items-center gap-4 rounded-md border border-gray-300 bg-gray-50 p-2 text-sm shadow-sm">
            <div>Interactive</div>
            <Switch
              checked={mode === "json"}
              onChange={(checked) =>
                handleUpdateMode(checked ? "json" : "interactive")
              }
            />
            <div>JSON</div>
          </div>
        </div>
        {mode === "json" ? (
          <FormField
            control={form.control}
            name="json"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  Schema
                  <a
                    href="https://json-schema.org/understanding-json-schema/"
                    target="_blank"
                    rel="noreferrer"
                    className="ml-1 underline"
                  >
                    (JSONSchema)
                  </a>
                </FormLabel>
                <FormControl>
                  <div>
                    <Textarea
                      className="font-mono"
                      placeholder={placeholderJson}
                      {...field}
                      rows={10}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                      value={
                        typeof field.value === "string"
                          ? field.value
                          : JSON.stringify(field.value, null, 2)
                      }
                    />
                  </div>
                </FormControl>
                <FormDescription>
                  JSON Schema object with the function name, description, and
                  parameters.
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
        ) : (
          <>
            <div className="flex flex-col gap-4">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Function Name</FormLabel>
                    <FormControl>
                      <Input autoFocus placeholder="my_function" {...field} />
                    </FormControl>
                    <FormDescription>
                      Name of function, max length 64.
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Description</FormLabel>
                    <FormControl>
                      <Textarea
                        placeholder="Description"
                        rows={3}
                        {...field}
                        value={field.value ?? ""}
                      />
                    </FormControl>
                    <FormDescription>
                      A description of what the function does, used by the model
                      to choose when and how to call the function. (Optional)
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              control={form.control}
              name="parameters"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Parameters</FormLabel>
                  <FormControl>
                    <div>
                      <InteractiveFunctionParametersEditor
                        onChange={field.onChange}
                        initialValue={field.value}
                      />
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </>
        )}
        <div className="flex space-x-4">
          <Button type="submit">Add function</Button>
          <Button variant="outline" onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </form>
    </Form>
  );
};
