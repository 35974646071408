import React from "react";
import { Tooltip } from "../Tooltip";
import { createValueToKeyMap, filterDamagingInputVarPairs } from "./utils";

/**
 * This function is used to highlight the matching text in a given template. It will take
 * the inputVariables and replace them in the template with a tooltip that shows the variable name.
 * It takes three parameters: text, template, and inputVariables.
 *
 * @param {string} text - The text to be highlighted.
 * @param {Record<string, string>} inputVariables - The variables to be replaced in the template.
 *
 * @returns {React.ReactNode} - Returns a formatted React Node with highlighted text if the input is valid.
 */
export const highlightRequestVariables = (
  text: string,
  inputVariables: Record<string, string>,
  maxCycles: number = 100, // Default maximum cycles
): React.ReactNode => {
  try {
    const filteredInputVariables = Object.fromEntries(
      filterDamagingInputVarPairs(inputVariables),
    );
    const valueToKeyMap = createValueToKeyMap(filteredInputVariables);
    const formatted: React.ReactNode[] = [];

    let lastIndex = 0;

    let escapedKeys: string[];
    escapedKeys = Object.keys(valueToKeyMap).map((key) =>
      key.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"),
    );
    const regex = new RegExp(escapedKeys.join("|"), "g");

    let match: RegExpExecArray | null;
    let cycles = 0;

    while ((match = regex.exec(text)) !== null && cycles < maxCycles) {
      const offset = match.index;
      if (lastIndex < offset) {
        formatted.push(text.substring(lastIndex, offset));
      }
      const variableName = valueToKeyMap[match[0]];
      const key = `${variableName}${offset}`;
      formatted.push(
        <Tooltip key={key} tipHeader={variableName} className="z-20">
          <span className="font-bold text-blue-500">{match[0]}</span>
        </Tooltip>,
      );

      lastIndex = offset + match[0].length;
      cycles++;
    }

    if (lastIndex < text.length) {
      formatted.push(text.substring(lastIndex));
    }

    return <div>{formatted}</div>;
  } catch (error) {
    console.error("Error escaping keys:", error);
    return <div>{text}</div>;
  }
};
