import { Message } from "@/types";
import { TerminalIcon } from "@heroicons/react/outline";
import { CopyButton } from "../ui/copy-button";
import { formatJson } from "@/utils/utils";

const TRUNCATE_PREVIEW_LENGTH = 100;

export const ActionCall = ({
  componentIsOpen,
  message,
}: {
  componentIsOpen: boolean;
  message: Message;
}) => {
  if (message.role !== "assistant") return null;

  return (
    <>
      {!componentIsOpen ? (
        message.tool_calls ? (
          <div className="group-hover:inherit pt-2 text-left font-mono">
            {message.tool_calls?.map((tool_call, index: number) => (
              <div key={index} className="flex items-center">
                <TerminalIcon className="mr-1 h-4 w-4 text-gray-500" />
                <span>
                  {`${tool_call.function.name} <- ${tool_call.function.arguments}`.substring(
                    0,
                    TRUNCATE_PREVIEW_LENGTH,
                  )}
                  {tool_call.function.name.length +
                    tool_call.function.arguments.length >
                  TRUNCATE_PREVIEW_LENGTH
                    ? "..."
                    : ""}
                </span>
              </div>
            ))}
          </div>
        ) : (
          <div className="group-hover:inherit pt-2 text-left font-mono">
            <TerminalIcon className="mr-1 inline h-4 w-4 text-gray-500" />
            {message.function_call
              ? `${message.function_call.name} <- ${message.function_call.arguments}`.substring(
                  0,
                  TRUNCATE_PREVIEW_LENGTH,
                ) +
                (message.function_call.name.length +
                  message.function_call.arguments.length >
                TRUNCATE_PREVIEW_LENGTH
                  ? "..."
                  : "")
              : null}
          </div>
        )
      ) : message.tool_calls ? (
        message.tool_calls.map((tool_call) => (
          <div className="font-mono">
            <div className="flex items-center font-medium text-gray-900">
              <TerminalIcon className="mr-2 inline h-4 w-4" />
              <span>{tool_call.function.name}</span>
            </div>
            <div className="pl-2 pt-1 text-gray-600">
              <div className="font-mono">
                <div className="font-medium text-gray-900">
                  <div className="flex items-start justify-between">
                    <div className="w-full">
                      {formatJson(tool_call.function.arguments)}
                    </div>
                    <div>
                      <CopyButton text={tool_call.function.arguments} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="font-mono">
          <div className="flex items-center font-medium text-gray-900">
            <TerminalIcon className="mr-2 inline h-4 w-4" />
            <span>{message.function_call?.name}</span>
          </div>
          <div className="pl-2 pt-1 text-gray-600">
            <div className="font-mono">
              <div className="font-medium text-gray-900">
                <div className="flex items-start justify-between">
                  <div className="w-full">
                    <div className="w-full">
                      {formatJson(message.function_call?.arguments || "")}
                    </div>
                  </div>
                  <div>
                    <CopyButton text={message.function_call?.arguments ?? ""} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
