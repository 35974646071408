import React, { useState, useMemo } from "react";
import abstractGraphic from "@/assets/evaluate/abstract-graphic-empty-gray.png";
import spreadsheetGraphic from "@/assets/evaluate/spreadsheet.png";
import LoadingSpinner from "@/components/LoadingSpinner";
import { Button } from "@/components/ui/button";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { useGetBlueprints, useGetReports } from "@/queries";
import { Report } from "@/types/evaluate";
import {
  InformationCircleIcon,
  PlusIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/outline";
import { LockClosedIcon } from "@heroicons/react/solid";
import {
  MagicWandIcon,
  ActivityLogIcon,
  MagnifyingGlassIcon,
} from "@radix-ui/react-icons";
import { Gamepad2Icon, TrophyIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { BlueprintCard, ReportCard } from "./NavigationCards";
import { Breadcrumbs } from "../Breadcrumbs";

const Header = () => {
  const userContext = useUser();

  return (
    <div className="bg-white p-1">
      <Breadcrumbs
        items={["Evaluate"]}
        navigateUrl={`/workspace/${userContext?.activeWorkspaceId}/home/`}
        pageTitle="Evaluate"
        pageSubtitle="Score your prompt templates and run batch pipelines."
      />
    </div>
  );
};

const ItemsHeader = ({
  headerIcon,
  header,
  subheader,
  children,
  empty,
  emptyMessage,
  actionButton,
  searchValue,
  onSearchChange,
  currentPage,
  onPrevPage,
  onNextPage,
  itemsLength,
  itemsPerPage,
}: {
  headerIcon?: React.ReactNode;
  header: string;
  subheader: string;
  children: React.ReactNode;
  empty?: boolean;
  emptyMessage?: string | React.ReactNode;
  actionButton?: React.ReactNode;
  searchValue: string;
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  currentPage: number;
  onPrevPage: () => void;
  onNextPage: () => void;
  itemsLength: number;
  itemsPerPage: number;
}) => {
  return (
    <div>
      <div className="flex py-2">
        <div className="flex-1">
          <h1 className="text-lg text-gray-900">
            {headerIcon}
            {header}
          </h1>
          <h2 className="text-sm text-gray-500">{subheader}</h2>
        </div>
        {actionButton}
      </div>
      <div className="border-b py-2">
        <div className="flex items-center">
          <div className="flex flex-1 items-center rounded-sm px-1">
            <MagnifyingGlassIcon
              className="mr-2 h-5 w-auto text-gray-400"
              aria-hidden="true"
            />
            <input
              type="text"
              placeholder={`Search ${header.toLowerCase()}...`}
              value={searchValue}
              onChange={onSearchChange}
              className="block w-full rounded-sm border-0 border-transparent focus:border-transparent focus:ring-0 sm:text-sm"
            />
          </div>
          {(currentPage !== 1 || currentPage * itemsPerPage < itemsLength) && (
            <div className="flex items-center space-x-1 px-1">
              <button
                onClick={onPrevPage}
                disabled={currentPage === 1}
                className={`rounded-full border p-1.5 ${
                  currentPage === 1 ? "bg-gray-200 opacity-50" : "bg-white"
                }`}
              >
                <ChevronLeftIcon className="h-4 w-4" />
              </button>
              <button
                onClick={onNextPage}
                disabled={currentPage * itemsPerPage >= itemsLength}
                className={`rounded-full border p-1.5 ${
                  currentPage * itemsPerPage >= itemsLength
                    ? "bg-gray-200 opacity-50"
                    : "bg-white"
                }`}
              >
                <ChevronRightIcon className="h-4 w-4" />
              </button>
            </div>
          )}
        </div>
      </div>
      {empty &&
        (typeof emptyMessage === "string" ? (
          <div className="flex items-center justify-center pb-8 pt-2">
            <span className="text-sm text-gray-500">{emptyMessage}</span>
          </div>
        ) : (
          emptyMessage
        ))}
      {children}
    </div>
  );
};

const Evaluate = () => {
  const auth = useAuth();
  const userContext = useUser();
  const navigate = useNavigate();
  const userToken = auth!.userToken!;
  const [searchTermBlueprints, setSearchTermBlueprints] = useState("");
  const [searchTermBatchRuns, setSearchTermBatchRuns] = useState("");
  const [searchTermTriggered, setSearchTermTriggered] = useState("");
  const [currentPageBlueprints, setCurrentPageBlueprints] = useState(1);
  const [currentPageBatchRuns, setCurrentPageBatchRuns] = useState(1);
  const [currentPageTriggered, setCurrentPageTriggered] = useState(1);
  const itemsPerPage = 12;

  const { data: blueprintsData, isLoading: blueprintsLoading } =
    useGetBlueprints(userToken, userContext?.activeWorkspaceId);

  const blueprintListSorted: Report[] = useMemo(() => {
    const returnedBlueprints: Report[] = blueprintsData?.reports || [];
    return returnedBlueprints
      .sort(
        (a, b) =>
          new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime(),
      )
      .filter((report) =>
        report.name.toLowerCase().includes(searchTermBlueprints.toLowerCase()),
      );
  }, [blueprintsData, searchTermBlueprints]);

  const { data: reportsData, isLoading: reportsLoading } = useGetReports(
    userToken,
    userContext?.activeWorkspaceId,
  );

  const reportsListSorted: Report[] = useMemo(() => {
    const returnedReports: Report[] = reportsData?.reports || [];
    return returnedReports
      .sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
      )
      .filter((report) =>
        report.name.toLowerCase().includes(searchTermBatchRuns.toLowerCase()),
      );
  }, [reportsData, searchTermBatchRuns]);

  const reportListWithoutTriggered: Report[] = reportsListSorted
    .filter(
      (item: Report) =>
        item?.prompt_registry_id === undefined &&
        item?.prompt_version_number === undefined,
    )
    .filter((report) =>
      report.name.toLowerCase().includes(searchTermBatchRuns.toLowerCase()),
    );

  const triggeredReportsListSorted: Report[] = reportsListSorted
    .filter(
      (item: Report) =>
        item?.prompt_registry_id !== undefined &&
        item?.prompt_version_number !== undefined,
    )
    .filter((report) =>
      report.name.toLowerCase().includes(searchTermTriggered.toLowerCase()),
    );

  const handleSearchChange =
    (
      setSearchTerm: React.Dispatch<React.SetStateAction<string>>,
      setCurrentPage: React.Dispatch<React.SetStateAction<number>>,
    ) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(e.target.value);
      setCurrentPage(1); // Reset to first page on search
    };

  const paginate = (items: Report[], currentPage: number) => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    return items.slice(start, end);
  };

  const nextPage =
    (setCurrentPage: React.Dispatch<React.SetStateAction<number>>) => () => {
      setCurrentPage((prev) => prev + 1);
    };

  const prevPage =
    (setCurrentPage: React.Dispatch<React.SetStateAction<number>>) => () => {
      setCurrentPage((prev) => prev - 1);
    };

  if (blueprintsLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <LoadingSpinner size={12} />
      </div>
    );
  }

  const emptyGraphic = (
    <div className="mt-2 flex w-full rounded-md border border-gray-100 bg-gray-50 px-16 py-6 shadow-md">
      <div className="w-1/2 space-y-3 rounded-md py-10 pr-8">
        <div className="text-3xl font-medium text-gray-900">
          How good are your prompts?
        </div>
        <div className="text-base text-gray-600">
          Build flexible pipelines to batch run prompts, compare results, and
          chain them together.
        </div>
        <div className="space-y-6 py-4">
          {[
            {
              heading: "Score your prompts",
              description:
                "Generate scores using golden datasets, human graders, and AI evaluators.",
              icon: (
                <TrophyIcon className="h-5 w-5 rounded-full bg-blue-600 p-1 text-white" />
              ),
            },
            {
              heading: "One-off bulk jobs",
              description:
                "Tinker & iterate on your prompts using input datasets.",
              icon: (
                <Gamepad2Icon className="h-5 w-5 rounded-full bg-blue-600 p-1 text-white" />
              ),
            },
            {
              heading: "Regression tests",
              description:
                "Trigger evaluation pipelines to run every time a prompt template is updated.",
              icon: (
                <MagicWandIcon className="h-5 w-5 rounded-full bg-blue-600 p-1 text-white" />
              ),
            },
          ].map((item, index) => (
            <div className="flex space-x-3" key={`preview-${index}`}>
              <div>{item.icon}</div>
              <div className="flex flex-col gap-y-2">
                <div className="-mt-1 text-base font-medium text-gray-800">
                  {item.heading}
                </div>
                <div className="text-sm font-light text-gray-600">
                  {item.description}
                </div>
              </div>
            </div>
          ))}
        </div>

        <Button
          onClick={() =>
            navigate(
              `/workspace/${userContext?.activeWorkspaceId}/evaluate/create-blueprint`,
            )
          }
          size="xl"
        >
          New Batch Run
        </Button>
        <a
          href="https://docs.promptlayer.com/features/evaluations/overview"
          target="_blank"
          rel="noopener noreferrer"
          className="block text-sm text-gray-500 hover:text-gray-400"
          aria-label="Learn more about evaluations on the documentation"
        >
          <InformationCircleIcon className="-mt-1 mr-1 inline-block h-4 w-auto" />
          Learn more on the docs.
        </a>
      </div>
      <div className="flex w-1/2 items-center justify-center">
        <img
          src={spreadsheetGraphic}
          alt="Spreadsheet Graphic"
          className="h-auto w-full md:w-3/4"
        />
      </div>
    </div>
  );

  const noReports =
    blueprintsData &&
    blueprintsData.reports &&
    blueprintsData.reports.length === 0;

  return (
    <>
      <Header />
      <div className="space-y-4 px-1 py-4">
        {noReports ? (
          emptyGraphic
        ) : (
          <>
            <ItemsHeader
              header="Pipelines"
              subheader="Build templates to evaluate and run prompts in batch."
              actionButton={
                <div className="px-1 pt-2">
                  <Button
                    onClick={() =>
                      navigate(
                        `/workspace/${userContext?.activeWorkspaceId}/evaluate/create-blueprint`,
                      )
                    }
                    size={"lg"}
                  >
                    <PlusIcon className="mr-1 inline-block h-4 w-auto text-white" />
                    New Pipeline
                  </Button>
                </div>
              }
              searchValue={searchTermBlueprints}
              onSearchChange={handleSearchChange(
                setSearchTermBlueprints,
                setCurrentPageBlueprints,
              )}
              currentPage={currentPageBlueprints}
              onPrevPage={prevPage(setCurrentPageBlueprints)}
              onNextPage={nextPage(setCurrentPageBlueprints)}
              itemsLength={blueprintListSorted.length}
              itemsPerPage={itemsPerPage}
            >
              <div className="grid grid-cols-4 gap-4 py-2">
                {blueprintsLoading ? (
                  <LoadingSpinner size={5} />
                ) : (
                  <>
                    {paginate(blueprintListSorted, currentPageBlueprints).map(
                      (item: Report) => (
                        <BlueprintCard key={item.id} report={item} />
                      ),
                    )}
                  </>
                )}
              </div>
            </ItemsHeader>
            <div className="flex w-full flex-row pt-4">
              <div className="w-1/2 pr-4">
                <ItemsHeader
                  headerIcon={
                    <LockClosedIcon className="-mt-1 mr-1 inline h-4 w-auto text-gray-800" />
                  }
                  header="Batch Runs"
                  subheader="Results of running a pipeline fully against a dataset."
                  empty={
                    !reportsLoading && reportListWithoutTriggered.length === 0
                  }
                  emptyMessage={
                    <div className="flex w-full flex-col space-y-6 rounded-md p-4 text-center">
                      <div className="text-sm text-gray-500">
                        Open a preview to generate a full batch run.
                      </div>
                      <div className="flex items-center justify-center">
                        <img
                          src={abstractGraphic}
                          alt="Abstract Graphic"
                          className="h-auto w-[200px]"
                        />
                      </div>
                    </div>
                  }
                  searchValue={searchTermBatchRuns}
                  onSearchChange={handleSearchChange(
                    setSearchTermBatchRuns,
                    setCurrentPageBatchRuns,
                  )}
                  currentPage={currentPageBatchRuns}
                  onPrevPage={prevPage(setCurrentPageBatchRuns)}
                  onNextPage={nextPage(setCurrentPageBatchRuns)}
                  itemsLength={reportListWithoutTriggered.length}
                  itemsPerPage={itemsPerPage}
                >
                  <div className="flex w-full flex-col py-2">
                    {reportsLoading ? (
                      <LoadingSpinner size={5} />
                    ) : (
                      <>
                        {paginate(
                          reportListWithoutTriggered,
                          currentPageBatchRuns,
                        ).map((item: Report) => (
                          <ReportCard key={item.id} report={item} />
                        ))}
                      </>
                    )}
                  </div>
                </ItemsHeader>
              </div>
              <div className="w-1/2 pl-4">
                <ItemsHeader
                  header="Triggered Prompt Evaluations"
                  headerIcon={
                    <ActivityLogIcon className="-mt-1 mr-1 inline h-4 w-auto text-gray-800" />
                  }
                  subheader="Batch runs triggered from prompt template updates."
                  empty={
                    !reportsLoading && triggeredReportsListSorted.length === 0
                  }
                  emptyMessage={
                    <div className="flex w-full flex-col space-y-6 rounded-md p-4 text-center">
                      <div className="text-sm text-gray-500">
                        Trigger a prompt evaluation after updating a prompt
                        template and get your results here.
                      </div>
                      <div className="flex items-center justify-center">
                        <img
                          src={abstractGraphic}
                          alt="Abstract Graphic"
                          className="h-auto w-[200px]"
                        />
                      </div>
                    </div>
                  }
                  searchValue={searchTermTriggered}
                  onSearchChange={handleSearchChange(
                    setSearchTermTriggered,
                    setCurrentPageTriggered,
                  )}
                  currentPage={currentPageTriggered}
                  onPrevPage={prevPage(setCurrentPageTriggered)}
                  onNextPage={nextPage(setCurrentPageTriggered)}
                  itemsLength={triggeredReportsListSorted.length}
                  itemsPerPage={itemsPerPage}
                >
                  <div className="flex w-full flex-col py-2">
                    {reportsLoading ? (
                      <LoadingSpinner size={5} />
                    ) : (
                      <>
                        {paginate(
                          triggeredReportsListSorted,
                          currentPageTriggered,
                        ).map((item: Report) => (
                          <ReportCard key={item.id} report={item} />
                        ))}
                      </>
                    )}
                  </div>
                </ItemsHeader>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Evaluate;
