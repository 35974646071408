import { useAuth } from "@/context/auth-context";
import {
  useDatasetInfo,
  useGetReport,
  useGetReportCells,
  useGetReportColumns,
} from "@/queries";
import { ColumnType, Report, ReportCell, ReportColumn } from "@/types/evaluate";
import { getRowIds, sortColumns } from "@/utils/evaluate";
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import HeaderCell from "./TableComponents/HeaderCell";
import RowCell from "./TableComponents/RowCell/";

export interface SortConfigItem {
  columnId: number;
  direction: "asc" | "desc" | null;
}

const ReportContext = createContext<any>(null);

interface ReportProviderProps extends PropsWithChildren<{}> {
  reportId: number | string;
  editable: boolean;
}

function ReportProvider({ ...props }: ReportProviderProps) {
  const userToken = useAuth()?.userToken || "";

  // getters
  const reportId =
    typeof props.reportId === "string"
      ? Number(props.reportId)
      : props.reportId;

  // Get report metadata
  const { data: report, isLoading: reportIsLoading } = useGetReport(
    userToken,
    reportId,
  ) as {
    data: Report;
    isLoading: boolean;
  };

  const { data: datasetInfo } = useDatasetInfo(userToken, report?.dataset_id);

  // Get report columns
  const { data: reportColumns, isLoading: reportColumnsIsLoading } =
    useGetReportColumns(userToken, reportId) as {
      data: ReportColumn[] | null;
      isLoading: boolean;
    };

  // Get report cells
  const { data: reportCells, isLoading: reportCellsIsLoading } =
    useGetReportCells(userToken, reportId) as {
      data: ReportCell[] | null;
      isLoading: boolean;
    };

  const getCellByRowAndColumn = useCallback(
    (rowId: number, columnId: number) => {
      return reportCells?.find(
        (cell: ReportCell) =>
          cell.dataset_row_id === rowId && cell.report_column_id === columnId,
      );
    },
    [reportCells],
  );

  // These table helpers are calculated in the context so that
  // the table doesn't have to re-render when cells are updated

  // Building columns
  const sortedColumns = useMemo(
    () => sortColumns(reportColumns || []),
    [reportColumns],
  );
  const lastColumnId = useMemo(() => {
    const lastColumn = sortedColumns[sortedColumns.length - 1];
    return lastColumn?.id || -1;
  }, [sortedColumns]);

  const headerCellsList = useMemo(
    () =>
      sortedColumns.map((column: ReportColumn) => {
        return () =>
          HeaderCell({
            column,
            reportId,
            availableColumns: sortedColumns,
            isEditable:
              props.editable && column.column_type !== ColumnType.DATASET,
          });
      }),
    [sortedColumns, reportId, props.editable],
  );
  const tableColumnsMap = useMemo(() => {
    const columnCellsMap: Record<number, ReportColumn> = {};
    sortedColumns.forEach((column: ReportColumn) => {
      const columnId: number = column.id || -1;
      columnCellsMap[columnId] = column;
    });
    return columnCellsMap;
  }, [sortedColumns]);
  const getColumnById = useCallback(
    (columnId: number) => {
      return tableColumnsMap[columnId];
    },
    [tableColumnsMap],
  );

  // Building cells
  const rowIds = useMemo(() => getRowIds(reportCells || []), [reportCells]); // rowIds
  const [rowIdsMemoized, setRowIdsMemoized] = useState<number[]>([]);
  useEffect(() => {
    if (rowIds && !rowIds.every((id, index) => id === rowIdsMemoized[index])) {
      setRowIdsMemoized(rowIds);
    }
  }, [rowIds, rowIdsMemoized]);

  const tableCellsMap = useMemo(() => {
    const rowColumnCellsMap: any = {};
    rowIdsMemoized.forEach((rowId: number) =>
      sortedColumns.forEach((column: ReportColumn) => {
        const mapId = `${rowId}-${column.id}`;
        const columnId: number = column.id || -1;
        rowColumnCellsMap[mapId] = () => (
          <RowCell rowId={rowId} columnId={columnId} />
        );
      }),
    );
    return rowColumnCellsMap;
  }, [rowIdsMemoized, sortedColumns]);

  const [filters, setFilters] = useState<{
    [columnId: string]: string[];
  }>({});

  const [sortConfig, setSortConfig] = useState<SortConfigItem[]>([]);

  const [currentFilterInput, setCurrentFilterInput] = useState<{
    column_id: string;
    value: string;
  } | null>(null);
  // Update the filteredRowIds calculation
  const filteredRowIds = useMemo(() => {
    if (Object.keys(filters).length === 0 && !currentFilterInput)
      return rowIdsMemoized;
    return rowIdsMemoized.filter((rowId) => {
      return (
        Object.entries(filters).every(([columnId, filterValues]) => {
          const cell = getCellByRowAndColumn(rowId, parseInt(columnId));
          if (!cell || !cell.display_value) return false;
          const cellValue =
            cell.display_value.value?.toString().toLowerCase() || "";
          return filterValues.every((filterValue) =>
            cellValue.includes(filterValue.toLowerCase()),
          );
        }) &&
        (!currentFilterInput ||
          (
            getCellByRowAndColumn(rowId, parseInt(currentFilterInput.column_id))
              ?.display_value?.value?.toString()
              .toLowerCase() || ""
          ).includes(currentFilterInput.value.toLowerCase()))
      );
    });
  }, [rowIdsMemoized, filters, currentFilterInput, getCellByRowAndColumn]);

  const sortedRowIds = useMemo(() => {
    if (sortConfig.length === 0) {
      return filteredRowIds;
    }

    return [...filteredRowIds].sort((a, b) => {
      for (const { columnId, direction } of sortConfig) {
        const cellA = getCellByRowAndColumn(a, columnId);
        const cellB = getCellByRowAndColumn(b, columnId);

        if (!cellA || !cellB) continue;

        const valueA = cellA.display_value?.value;
        const valueB = cellB.display_value?.value;

        if (valueA === valueB) continue;

        if (valueA === null || valueA === undefined)
          return direction === "asc" ? -1 : 1;
        if (valueB === null || valueB === undefined)
          return direction === "asc" ? 1 : -1;

        const comparison = valueA < valueB ? -1 : 1;
        return direction === "asc" ? comparison : -comparison;
      }

      return 0;
    });
  }, [filteredRowIds, sortConfig, getCellByRowAndColumn]);

  const sortColumn = useCallback(
    (columnId: number, direction: "asc" | "desc" | null) => {
      setSortConfig((prevConfig) => {
        const columnIndex = prevConfig.findIndex(
          (item) => item.columnId === columnId,
        );

        if (columnIndex === -1) {
          return [...prevConfig, { columnId, direction }];
        } else {
          const newConfig = prevConfig.filter(
            (item) => item.columnId !== columnId,
          );
          if (direction !== null) newConfig.push({ columnId, direction });
          return newConfig;
        }
      });
    },
    [],
  );

  return (
    <ReportContext.Provider
      value={{
        columnsCount: sortedColumns.length,
        datasetInfo,
        getCellByRowAndColumn,
        getColumnById,
        headerCellsList,
        isEditable: props.editable,
        lastColumnId,
        reportCells,
        reportCellsIsLoading,
        reportColumns,
        reportColumnsIsLoading,
        filters,
        sortConfig,
        sortColumn,
        sortedRowIds,
        setFilters,
        setCurrentFilterInput,
        currentFilterInput,
        filteredRowIds,
        reportId,
        reportIsLoading,
        reportMetadata: report,
        rowIds: rowIdsMemoized,
        sortedColumns,
        tableCellsMap,
      }}
      {...props}
    />
  );
}

const useReportContext = () => useContext(ReportContext);

export { ReportProvider, useReportContext };
