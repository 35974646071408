import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { openai, prompt } from "@/schemas";
import { ChatFunctionCall, FunctionsType, functionsTypes } from "@/types";
import { PlusIcon, TerminalIcon } from "@heroicons/react/outline";
import { Link } from "lucide-react";
import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTrigger,
} from "../ui/dialog";
import { FunctionsList } from "./FunctionsList";
import { FunctionForm } from "./function-form";

type IdentifiableFunction = openai._Function & { id: string };

type Props = {
  triggerTitle?: string;
  functions: IdentifiableFunction[];
  onSubmit?: (values: IdentifiableFunction) => void;
  setFunctions?: (functions: IdentifiableFunction[]) => void;
  functionCall?: ChatFunctionCall;
  setFunctionCall?: (functionCall: ChatFunctionCall) => void;
  buttonClassNames?: string;
  functionsType?: FunctionsType;
  setFunctionsType?: (functionsType: FunctionsType) => void;
};

export const FunctionDialog = ({
  triggerTitle,
  functions,
  onSubmit,
  setFunctions,
  functionCall,
  setFunctionCall,
  buttonClassNames,
  functionsType = "functions",
  setFunctionsType,
}: Props) => {
  const [open, setOpen] = useState(false);
  const [isAddingFunction, setIsAddingFunction] = useState(false);
  const [editingFunction, setEditingFunction] =
    useState<IdentifiableFunction | null>(null);

  const isEditing = !!setFunctions && !!onSubmit;
  const functionCallValue =
    typeof functionCall === "string" ? functionCall : functionCall?.name || "";
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline" className={buttonClassNames}>
          <TerminalIcon className="mr-1 inline h-4 w-4" />
          {triggerTitle ? triggerTitle : "Functions"}{" "}
          {functions.length ? `(${functions.length})` : ""}
        </Button>
      </DialogTrigger>
      <DialogContent className="flex max-h-[800px] min-h-[600px] max-w-6xl flex-col overflow-auto">
        {isAddingFunction || editingFunction ? (
          <NewFunctionDialog
            onSubmit={(values) => {
              setEditingFunction(null);
              onSubmit!(values);
              setIsAddingFunction(false);
            }}
            onCancel={() => {
              setIsAddingFunction(false);
              setEditingFunction(null);
            }}
            functions={functions}
            previousFunction={editingFunction}
          />
        ) : (
          <>
            <DialogHeader className="text-center text-xl">
              <div className="flex flex-col">
                <Label className="text-xl font-bold">{`${
                  isEditing ? "Setup " : ""
                }Function Calls`}</Label>
                <p className="text-sm text-gray-500">
                  <a
                    href="https://platform.openai.com/docs/guides/gpt/function-calling"
                    target="_blank"
                    rel="noreferrer"
                    className="text-sm text-gray-500 hover:text-gray-400"
                  >
                    Learn more on the OpenAI docs
                    <Link className="inline h-4 w-4 pl-1" />
                  </a>
                </p>
              </div>
            </DialogHeader>
            <div className="flex flex-col">
              <div className="flex items-center gap-2">
                <Label className="font-bold">Functions Type: </Label>
                <Select
                  value={functionsType}
                  onValueChange={(type: FunctionsType) =>
                    setFunctionsType?.(type)
                  }
                  disabled={!setFunctionsType}
                >
                  <SelectTrigger className="max-w-xs capitalize">
                    <SelectValue placeholder="Functions Type" />
                  </SelectTrigger>
                  <SelectContent>
                    {functionsTypes.map((type) => (
                      <SelectItem
                        key={type}
                        value={type}
                        className="capitalize"
                      >
                        {type}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <div
                className={`flex flex-row items-center gap-x-2 pt-2 text-sm ${
                  isEditing ? "pb-3" : "pb-1"
                }`}
              >
                <div className="font-mono">
                  {functionsType === "functions"
                    ? "function_call"
                    : "tool_choice"}
                  :{" "}
                </div>
                <div>
                  {isEditing ? (
                    <SelectFunctionCall
                      {...{ functionCall, setFunctionCall, functions }}
                    />
                  ) : (
                    <div className="border-gray-500 font-mono text-sm text-gray-700">
                      <TerminalIcon className="mr-1 inline h-4 w-4" />
                      {functionCallValue}
                    </div>
                  )}
                </div>
              </div>
              <p className="text-xs text-gray-400">
                {`Controls how the model responds to function calls, with options for "none" (model doesn't call a function), "auto" (model can choose between end-user or function call), and specifying a function forces the model to call it.`}
                <a
                  href="https://platform.openai.com/docs/api-reference/chat/create#chat/create-function_call"
                  target="_blank"
                  rel="noreferrer"
                  className="pl-1 text-xs text-blue-400 hover:text-blue-300"
                >
                  Learn more here.
                </a>
              </p>
            </div>
            <div>
              <div className="mt-3 flex items-center pb-1">
                <Label className="text-md flex-1 text-gray-800">
                  Function definitions:
                </Label>
                {isEditing && (
                  <Button
                    size="sm"
                    onClick={() => setIsAddingFunction(!isAddingFunction)}
                  >
                    <PlusIcon className="mr-2 inline h-4 w-4" />
                    Add new function
                  </Button>
                )}
              </div>
              <FunctionsList
                functions={functions}
                setFunctions={setFunctions}
                setEditingFunction={setEditingFunction}
                isEditing={isEditing}
                setIsAddingFunction={setIsAddingFunction}
              />
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

const NewFunctionDialog = ({
  onSubmit,
  onCancel,
  functions,
  previousFunction,
}: {
  onSubmit: (values: IdentifiableFunction) => void;
  onCancel: () => void;
  functions: IdentifiableFunction[];
  previousFunction: IdentifiableFunction | null;
}) => {
  return (
    <div>
      <DialogHeader className="text-center text-xl">
        <div className="flex flex-col">
          <Label className="text-xl font-bold">
            {previousFunction ? "Edit Function" : "Add New Function"}
          </Label>
          <p className="max-w-md text-sm text-gray-500">
            Define a structured function that the model can call.
            <a
              href="https://platform.openai.com/docs/guides/gpt/function-calling"
              target="_blank"
              rel="noreferrer"
              className="pl-1 text-sm text-gray-500 hover:text-gray-400"
            >
              Learn more <Link className="inline h-4 w-4 pl-1" />
            </a>
          </p>
        </div>
      </DialogHeader>
      <div className="pt-6">
        <FunctionForm
          onCancel={onCancel}
          onSubmit={onSubmit}
          functionNames={functions.map((func) => func.name)}
          isEditing={!!previousFunction}
          initialValues={previousFunction || undefined}
        />
      </div>
    </div>
  );
};

const SelectFunctionCall = ({
  functionCall,
  setFunctionCall,
  functions,
}: Pick<Props, "functionCall" | "setFunctionCall" | "functions">) => {
  const functionCallValue =
    typeof functionCall === "string" ? functionCall : functionCall?.name || "";
  return (
    <Select
      value={functionCallValue}
      onValueChange={(functionCall) => {
        if (["auto", "none"].includes(functionCall)) {
          setFunctionCall!(
            functionCall as (typeof prompt.functionCall)[number],
          );
        } else {
          setFunctionCall!({
            name: functionCall,
          });
        }
      }}
    >
      <SelectTrigger className="max-w-xs rounded-sm py-3">
        <SelectValue placeholder="Select function call" />
      </SelectTrigger>
      <SelectContent className="rounded-sm">
        <SelectGroup>
          {prompt.functionCall.map((functionCall) => (
            <SelectItem key={functionCall} value={functionCall}>
              {functionCall}
            </SelectItem>
          ))}
          {functions.map((_function) => (
            <SelectItem
              key={_function.name}
              value={_function.name}
              className="font-mono"
            >
              {`>_ `}
              {_function.name}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};
