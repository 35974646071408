export default function NotFound() {
  return (
    <div className="mx-auto max-w-lg gap-8 rounded-sm bg-gray-100 px-12 py-10 text-left">
      <h1 className="text-3xl font-light">Error: 404</h1>
      <h2 className="pt-2 text-sm font-normal">
        Page not found. Try refreshing or{" "}
        <a
          className="text-blue-500 hover:text-blue-600 hover:underline"
          href="/home"
        >
          returning home
        </a>
        .
      </h2>
    </div>
  );
}
