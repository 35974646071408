import FilledButton from "@/components/MarketingLayout/Platform/PlatformGenericLayout/FilledButton/FilledButton";
import { FC } from "react";
import * as S from "./Styles";

const Hero: FC<{ heroInfo: any }> = ({ heroInfo }) => {
  return (
    <S.Container>
      <S.Content>
        <S.Title>{heroInfo.title}</S.Title>
        <S.Subtitle>{heroInfo.subtitle}</S.Subtitle>
        <a
          href="mailto:hello@promptlayer.com?subject=Demo Request"
          style={{ textDecoration: "none" }}
        >
          <FilledButton>Request a demo</FilledButton>
        </a>
        <S.HeroImg imageUrl={heroInfo.image} />
      </S.Content>
    </S.Container>
  );
};

export default Hero;
