import { ReactNode } from "react";
import "./App.css";

function Footer() {
  return (
    <main className="px-4 pt-4">
      <div className="mx-auto mt-4 w-full text-center sm:mt-16">
        <div className="my-auto flex-1 pb-2 pt-5 text-center">
          <span className="text-sm tracking-wide text-gray-600">
            <span className="pl-3 pr-2">
              © Copyright {new Date().getFullYear()} All rights reserved. Made
              in NYC.
            </span>
            <span className="pr-2">&middot;</span>
            <a
              target="_blank"
              rel="noreferrer"
              href="mailto:hello@promptlayer.com"
            >
              Contact us
            </a>
            <br />
          </span>
        </div>
      </div>
    </main>
  );
}

export default function LoginLayout({ children }: { children: ReactNode }) {
  return (
    <div className="relative flex h-full flex-col overflow-hidden">
      <div className="mx-auto px-4 pt-10 sm:px-6">
        <a href="/">
          <span className="text-2xl font-medium tracking-wide text-gray-900">
            {"PromptLayer"}
          </span>
        </a>
      </div>
      <main className="mt-16 w-full flex-1 px-4 text-center sm:pb-8">
        {children}
      </main>
      <Footer />
    </div>
  );
}
