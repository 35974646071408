import { FC } from "react";
import { Link } from "react-router-dom";
import teamLight from "@/assets/team-light.png";
import * as S from "./Styles";

const Teams: FC = () => {
  return (
    <S.Container>
      <S.InnerContainer>
        <S.Text>
          {/* <h2>Iterate visually without engineers</h2> */}
          <h2>Collaborate without engineering</h2>
          <p>
            Move your prompts out of code and serve them from our CMS. Enable
            subject matter experts, like PMs or content writers, to edit and
            test prompt versions all through the PromptLayer dashboard.
            {/* Move your prompts out of your codebase and into our CMS. Enable subject matter experts to edit, evaluate, and deploy new prompt versions all through the PromptLayer dashboard. */}
            {/* LLM application business logic (aka the prompts) belong in a CMS. We allow subject matter experts to edit, evaluate, and deploy new prompt versions all without touching code. */}
          </p>
          <Link to="/create-account">
            <S.Button>Signup for free</S.Button>
          </Link>
        </S.Text>
        <S.ImageWrapper>
          <S.Image
            src={teamLight}
            alt="PromptLayer allows team collaboration"
          />
        </S.ImageWrapper>
      </S.InnerContainer>
    </S.Container>
  );
};

export default Teams;
