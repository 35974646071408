import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  CakeIcon,
  CollectionIcon,
  DotsHorizontalIcon,
  HomeIcon,
  StarIcon,
  UserIcon,
} from "@heroicons/react/outline";
import moment from "moment";

import NotFound from "@/NotFound";
import DropdownButton from "@/components/DropdownButton";
import RequestDisplay from "@/components/RequestDisplay";
import SignupModal from "@/components/SignupModal";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { useSharedRequest } from "@/queries";

function ContentNavbar({
  isRequestOwner,
  isLoggedIn,
  requestInfo,
}: {
  isRequestOwner: boolean;
  isLoggedIn: boolean;
  requestInfo: any;
}) {
  const navigate = useNavigate();

  const loginUrl = "/home";

  return (
    <div
      className={`sticky top-0 flex h-16 flex-shrink-0 border-b border-gray-200 bg-white md:border-0`}
    >
      {requestInfo ? (
        <div className="flex flex-1 justify-between px-4 md:px-0">
          <div className="flex flex-1 items-center space-x-8 text-gray-800 hover:text-gray-700 md:mr-6">
            <span
              className="cursor-pointer text-xl font-bold tracking-wide hover:text-gray-600"
              onClick={() => navigate("/")}
            >
              <CakeIcon className="-mt-1 mr-2 inline h-5 w-5" />
              PromptLayer
            </span>
          </div>
          <div className="flex items-center space-x-8 text-gray-800 hover:text-gray-700 md:mr-6">
            <h1
              className="hidden py-1 text-sm text-gray-500 md:block"
              title={requestInfo.request_start_time}
            >
              Logged {moment.utc(requestInfo.request_start_time).fromNow()}
            </h1>
            {isLoggedIn ? null : (
              <h1 className="py-1 text-sm">
                <span
                  onClick={() => navigate(loginUrl)}
                  className="cursor-pointer rounded-sm hover:bg-blue-50 hover:text-gray-600 md:border md:border-gray-400 md:px-3 md:py-2 md:shadow-md"
                >
                  Create an Account
                </span>
              </h1>
            )}
            <h1 className="text-md cursor-pointer py-1">
              <DropdownButton
                dropdownItems={
                  !isLoggedIn
                    ? [
                        {
                          name: "Sign in",
                          icon: UserIcon,
                          onClick: () => {
                            navigate(loginUrl);
                          },
                        },
                        {
                          name: "Save to my Library",
                          icon: StarIcon,
                          onClick: () => {
                            navigate(loginUrl);
                          },
                        },
                        {
                          name: "Go Home",
                          icon: HomeIcon,
                          onClick: () => {
                            navigate("/");
                          },
                        },
                      ]
                    : isRequestOwner
                    ? [
                        {
                          name: "Open in Dashboard",
                          icon: CollectionIcon,
                          onClick: () => {
                            navigate(
                              `/workspace/${requestInfo.workspace_id}/request/${requestInfo.id}`,
                            );
                          },
                        },
                        {
                          name: "Go Home",
                          icon: HomeIcon,
                          onClick: () => {
                            navigate("/");
                          },
                        },
                      ]
                    : [
                        {
                          name: "Go Home",
                          icon: HomeIcon,
                          onClick: () => {
                            navigate("/");
                          },
                        },
                      ]
                }
              >
                <DotsHorizontalIcon className="inline h-6" />
              </DropdownButton>
            </h1>
          </div>
        </div>
      ) : (
        <div className="flex flex-1 justify-between px-4 md:px-0"></div>
      )}
    </div>
  );
}

function Content({ children }: { children: React.ReactNode }) {
  return (
    <div className="py-6">
      <div className="px-4 sm:px-6 md:px-0">{children}</div>
    </div>
  );
}

export default function SharedRequestInfo() {
  const navigate = useNavigate();

  // Check if logged in
  const auth = useAuth();
  const userContext = useUser();
  const isLoggedIn = !!auth?.userToken && !!userContext.user;
  const userId = userContext.user?.id;

  const [showSignupModal, setShowSignupModal] = useState(false);

  useEffect(() => {
    if (!isLoggedIn) {
      setShowSignupModal(true);
    }
  }, [isLoggedIn]);

  // Get request from hash
  const { shareHash } = useParams();

  const {
    data: requestInfo,
    error,
    isLoading,
  } = useSharedRequest(shareHash || "");

  if (isLoading) return <div>Loading...</div>;

  if (error) {
    const unauthorized = (error as Error).message === "401";
    return (
      <div className="flex h-full w-full flex-col rounded-2xl border-l border-gray-200 bg-white md:px-8">
        <main className="w-full flex-1 overflow-y-hidden">
          <div className="h-full overflow-y-scroll md:mx-8">
            <Content>
              {unauthorized ? (
                <div className="mx-auto max-w-lg gap-8 rounded-sm bg-gray-100 px-12 py-10 text-left">
                  <h1 className="text-3xl font-light">
                    This prompt is private 🚫🍰
                  </h1>
                  <h2 className="pt-2 text-sm font-normal">
                    The owner of this prompt needs to enable sharing. Try asking
                    the person who sent you this link.
                  </h2>
                  <div className="mt-8">
                    <span
                      className="cursor-pointer border border-gray-400 bg-white px-3 py-2 shadow-md hover:bg-blue-50"
                      onClick={() => navigate("/")}
                    >
                      Go Home
                    </span>
                  </div>
                </div>
              ) : (
                <NotFound />
              )}
            </Content>
          </div>
        </main>
      </div>
    );
  }

  return (
    <div className="flex h-full w-full flex-col rounded-2xl border-l border-gray-200 bg-white md:px-8">
      {showSignupModal ? <SignupModal /> : null}
      <ContentNavbar
        isRequestOwner={userId === requestInfo?.user_id}
        isLoggedIn={isLoggedIn}
        requestInfo={requestInfo}
      />
      <main className="w-full flex-1 overflow-y-hidden">
        <div className="h-full overflow-y-scroll md:mx-8">
          <Content>
            <RequestDisplay
              requestInfo={requestInfo}
              requestId={requestInfo?.id}
            />
          </Content>
        </div>
      </main>
    </div>
  );
}
