import { Navigate } from "react-router-dom";
import LoadingSpinner from "./components/LoadingSpinner";
import { useAuth } from "./context/auth-context";
import { useUser } from "./context/user-context";
import { useWorkspaces } from "./queries";

export const ChooseWorkspaceAndRedirect = () => {
  const auth = useAuth();
  const user = useUser();
  const workspacesQuery = useWorkspaces(auth?.userToken!);
  if (!auth?.userToken) return <Navigate to="/login" />;
  const workspaces = workspacesQuery.data?.workspaces;
  if (workspacesQuery.isLoading)
    return (
      <div className="flex h-full items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  if (!workspaces) return <Navigate to="/login" />;
  if (workspaces.length === 0) return <Navigate to="/login" />;
  if (user.activeWorkspaceId) {
    const workspaceIds = workspaces.map((workspace) => workspace.id);
    if (workspaceIds.includes(user.activeWorkspaceId))
      return <Navigate to={`/workspace/${user.activeWorkspaceId}/home`} />;
  }
  const activeWorkspace =
    workspaces.find(({ is_default }) => is_default) || workspaces[0];
  return <Navigate to={`/workspace/${activeWorkspace.id}/home`} />;
};
