import { useUser } from "@/context/user-context";
import { cn } from "@/lib/utils";
import { useMemo } from "react";
import { NavLink, useLocation } from "react-router-dom";

export default function RegistryTabs() {
  const userContext = useUser();
  const location = useLocation();

  const navigationTabs = useMemo(() => {
    return [
      {
        label: "Prompts",
        href: `/workspace/${userContext?.activeWorkspaceId}/prompt`,
      },
      {
        label: "A/B Releases",
        href: `/workspace/${userContext?.activeWorkspaceId}/ab-releases`,
      },
    ];
  }, [userContext.activeWorkspaceId]);

  return (
    <div className="mt-4 flex gap-x-4 border-b">
      {navigationTabs.map((link) => (
        <NavLink
          key={link.label}
          to={link.href}
          className={({ isActive }) =>
            cn(
              "inline-flex cursor-pointer items-center justify-center whitespace-nowrap px-1 py-1.5 text-sm font-medium",
              isActive ||
                (link.label === "Prompts" &&
                  location.pathname.includes("/prompt-folder"))
                ? "border-b-2 border-b-blue-500 text-foreground"
                : "text-muted-foreground hover:border-b",
              "hover:border-b-blue-500 hover:text-blue-500",
            )
          }
        >
          {link.label}
        </NavLink>
      ))}
    </div>
  );
}
