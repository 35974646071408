import styled from "styled-components";

export const Column = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  width: 100%;

  @media (min-width: 992px) {
    width: 50%;
  }

  @media (min-width: 1200px) {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

export const Container = styled.section`
  background: #ffffff;
  margin: 100px 0 96px;
  position: relative;
`;

export const FeaturedImage = styled.img`
  max-width: 100%;
  width: 100%;
`;

export const FeatureTopText = styled.div`
  border-bottom: 1px solid rgba(204, 204, 204, 0.4);
  margin-top: 40px;
  padding-bottom: 30px;

  h2 {
    color: #090b1c;
    font-size: 55px;
    font-weight: 500;
    letter-spacing: -0.02em;

    @media (min-width: 992px) and (max-width: 1199px) {
      font-size: 46px;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 50px;
    }

    @media (max-width: 767px) {
      font-size: 46px;
    }

    @media (max-width: 575px) {
      font-size: 36px;
    }
  }

  p {
    color: #666666;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: -0.01em;
    margin: 22px 0 30px;
    max-width: 335px;
    width: 100%;
  }

  @media (min-width: 992px) {
    margin-top: 0;
  }
`;

export const Item = styled.div`
  align-items: flex-start;
  display: flex;
  margin-top: 30px;
`;

export const ItemText = styled.div`
  display: flex;
  flex: 1;

  h4 {
    color: #000000;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.01em;

    span {
      color: #a3a3a2;
      display: block;
      font-size: 17px;
      font-weight: 400;
      padding-top: 3px;
    }
  }
`;

export const Items = styled.div`
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 30px;
  width: 100%;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }

  @media (min-width: 992px) {
    margin-bottom: 0;
  }
`;

export const PieChartIcon = styled.img`
  margin-right: 12px;
  max-width: 24px;
  position: relative;
  top: 2px;
`;

export const RightIcon = styled.img`
  display: inline-block;
  margin-left: 8px;
  max-width: 15px;
`;

export const Row = styled.section<{ $reverseColumns?: boolean }>`
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  margin: 0 auto;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    flex-direction: ${({ $reverseColumns }) =>
      $reverseColumns ? "row-reverse" : "row"};
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1150px;
  }
`;

export const StyledLink = styled.a`
  color: #000000;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.01em;
  transition: 0.2s all ease;
`;
