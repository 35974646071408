import { ItemDescription, ItemProperties, Properties } from "./Types";

export const parseParams = (
  unparsedParams: any,
  depth: number,
  isItemDescription: boolean | undefined,
  type?: string,
) => {
  if (
    !unparsedParams ||
    typeof unparsedParams !== "object" ||
    (Object.keys(unparsedParams.properties || {}).length === 0 &&
      Object.keys(unparsedParams).length === 0)
  ) {
    return [];
  }

  if (depth === 0) {
    if (unparsedParams?.properties) {
      return Object.keys(unparsedParams.properties).map((key: string) => {
        const isTypeArray = unparsedParams.properties[key]?.type === "array";
        const isTypeObject = unparsedParams.properties[key]?.type === "object";
        let output;
        if (isTypeObject) {
          // Parse top level params -- objects
          output = {
            name: key,
            type: unparsedParams.properties[key]?.type,
            description: unparsedParams.properties[key]?.description,
            title: unparsedParams.properties[key]?.title,
            required: unparsedParams.required?.includes(key) ?? false,
            properties: unparsedParams.properties[key]?.properties ?? undefined,
          };
        } else if (isTypeArray) {
          // Parse top level params -- arrays
          output = {
            name: key,
            type: unparsedParams.properties[key]?.type,
            description: unparsedParams.properties[key]?.description,
            title: unparsedParams.properties[key]?.title,
            required: unparsedParams.required?.includes(key) ?? false,
            items: unparsedParams.properties[key]?.items ?? undefined,
          };
        } else {
          // Parse top level params
          output = {
            name: key,
            type: unparsedParams.properties[key]?.type,
            description: unparsedParams.properties[key]?.description,
            title: unparsedParams.properties[key]?.title,
            required: unparsedParams.required?.includes(key) ?? false,
          };
        }
        return output;
      });
    } else {
      return [];
    }
  } else {
    if (!isItemDescription) {
      // Parse item composition
      if (type === "object") {
        // Parse item composition -- object
        return Object.keys(unparsedParams).map((key: string) => {
          const isTypeObject = unparsedParams[key]?.type === "object";
          return {
            name: key,
            type: unparsedParams[key]?.type,
            description: unparsedParams[key]?.description,
            title: unparsedParams[key]?.title,
            ...(isTypeObject
              ? { properties: unparsedParams[key].properties }
              : {}),
          };
        });
      } else {
        // Parse item composition -- array
        return Object.keys(unparsedParams).map((key: string) => {
          const isTypeArray = unparsedParams[key]?.type === "array";
          return {
            name: key,
            type: unparsedParams[key]?.type,
            description: unparsedParams[key]?.description,
            title: unparsedParams[key]?.title,
            required: Array.isArray(unparsedParams.required)
              ? unparsedParams.required.includes(key)
              : false,
            items: isTypeArray ? unparsedParams[key]?.items : undefined,
            ...(isTypeArray
              ? {}
              : { properties: unparsedParams[key].properties }),
          };
        });
      }
    } else {
      // Parse item description (array)
      return Object.keys(unparsedParams).map((key) => {
        return {
          name: key,
          type: unparsedParams[key]?.type,
          description: unparsedParams[key]?.description,
          title: unparsedParams[key]?.title,
        };
      });
    }
  }
};

export const getItemDescriptionState = (initialValue: any, depth: number) => {
  // This function allows us to calculate if a parameter is an item description or an item composition

  // A description state is needed to decide if a parameter is an item description or an item composition
  // It might be none of those, in which case the parameter is a regular parameter
  // If the depth is 0, then the parameter is a regular parameter
  // if the depth is 1, then the parameter can be a regular, a description, or a composition

  if (depth === 0) {
    return undefined;
  }

  if (
    typeof initialValue === "object" &&
    "type" in initialValue &&
    initialValue.type === "object"
  ) {
    // its an item composition
    return false;
  }
  if (
    typeof initialValue === "object" &&
    "type" in initialValue &&
    initialValue.type !== "object"
  ) {
    // its an item description
    return true;
  }
};

export const handleParameterChange = <K extends keyof Properties>(
  index: number,
  key: K,
  val: Properties[K] | ItemProperties[K],
  parameters: any[],
  setParameters: (params: any[]) => void,
  isItemDescription?: boolean,
) => {
  const newParameters: any = [...parameters];

  if (key === "type" && val === "array") {
    newParameters[index][key] = val;
    newParameters[index]["items"] = {
      properties: {
        "": {
          type: "string",
          description: "",
          title: "",
        },
      },
      type: "object",
    };
  } else if (key === "type" && val === "object") {
    newParameters[index][key] = val;
    newParameters[index]["properties"] = {
      "": {
        type: "string",
        description: "",
        title: "",
      },
    };
  } else if (isItemDescription) {
    newParameters[0][key] = val;
    if (newParameters.length > 1) {
      newParameters[1][key] = val;
    }
  } else if (!isItemDescription) {
    newParameters[index][key] = val;
  } else {
    newParameters[index][key] = val;
  }
  setParameters(newParameters);
};

export const handleItemSwitch = (
  description: boolean,
  setParameters: (params: any[]) => void,
  onChange: (params: any) => void,
) => {
  if (description) {
    setParameters([
      {
        type: "string",
        description: "",
      } as ItemDescription,
    ]);
  } else {
    onChange({
      properties: {
        "": {
          name: "",
          type: "string",
          description: "",
          title: "",
        },
      },
      type: "object",
    });
  }
};

export const handleRemoveRow = (
  index: number,
  parameters: any[],
  setParameters: (params: any[]) => void,
) => {
  const newParameters = [...parameters];
  newParameters.splice(index, 1);
  setParameters(newParameters);
};

export const handleAddRow = (
  parameters: any[],
  setParameters: (params: any[]) => void,
) => {
  setParameters([
    ...parameters,
    { name: "", type: "string", description: "", title: "", required: false },
  ]);
};

export const handleJsonToFormParamChange = (
  parameters: any[],
  onChange: (params: any) => void,
  depth: number,
  isItemDescription?: boolean,
  outerType?: string,
) => {
  let newProperties: any = {};
  parameters.forEach((parameter: any) => {
    if (parameter.type === "array") {
      newProperties[parameter.name] = {
        type: parameter.type,
        title: parameter.title,
        items: parameter.items,
      };
    } else if (parameter.type === "object") {
      newProperties[parameter.name] = {
        type: parameter.type,
        title: parameter.title,
        properties: parameter.properties,
      };
    } else if (!isItemDescription) {
      newProperties[parameter.name] = {
        type: parameter.type,
        description: parameter.description,
        title: parameter.title,
      };
    } else {
      newProperties[parameter.name] = {
        type: parameter.type,
        description: parameter.description,
        title: parameter.title,
      };
    }
  });
  if (depth === 0) {
    onChange({
      properties: newProperties,
      required: parameters
        .filter((p: any) => p.required)
        .map((p: any) => p.name),
    });
  } else {
    if (isItemDescription) {
      onChange(newProperties);
    } else if (!isItemDescription && outerType !== "object") {
      onChange({
        properties: newProperties,
      });
    } else {
      onChange(newProperties);
    }
  }
};
