import { ChooseTemplateModal } from "@/components/ChooseTemplateModal";
import { PromptTemplateConfiguration } from "@/types/evaluate";

const ChooseTemplateStep = ({
  navigateAway,
  configuration,
  setConfiguration,
  editable,
}: {
  navigateAway: () => void;
  configuration: Partial<PromptTemplateConfiguration>;
  setConfiguration: (
    data:
      | Partial<PromptTemplateConfiguration>
      | ((
          prevState: Partial<PromptTemplateConfiguration>,
        ) => Partial<PromptTemplateConfiguration>),
  ) => void;
  editable: boolean;
}) => {
  return (
    <ChooseTemplateModal
      navigateAway={navigateAway}
      configuration={configuration}
      setConfiguration={setConfiguration}
      editable={editable}
    />
  );
};

export default ChooseTemplateStep;
