import { FC } from "react";

import { Comment as TComment } from "@/types/comments";
import { formatRelativeTime } from "@/utils/dates";

interface CommentProps {
  comment: TComment;
}

const Comment: FC<CommentProps> = ({ comment }) => {
  return (
    <div className="flex-grow">
      <div className="flex items-center">
        <p className="text-sm font-semibold">{comment.user_email}</p>
        <p className="ml-2 text-xs text-gray-400">
          {formatRelativeTime(comment.created_at)}
        </p>
      </div>
      <p className="whitespace-pre-wrap text-sm">{comment.text}</p>
    </div>
  );
};

export default Comment;
