import { Dispatch, FC, SetStateAction } from "react";
import { useChannel, useConnectionStateListener } from "ably/react";

import { ENDPOINTS } from "@/api/application-api";
import { PlaygroundConfig } from "@/components/Playground";
import { WEBSOCKET_MESSAGE_NAME } from "@/constants";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { IndividualRun } from "@/types/metadata";
import { WebSocketMessage } from "@/types/websockets";
import { authHeader } from "@/utils/headers";

export interface PlaygroundWebSocketListenerProps {
  latestIndividualRunRequestId: number | null;
  setConfig: Dispatch<SetStateAction<PlaygroundConfig>>;
  setError: Dispatch<SetStateAction<string>>;
  setLatestIndividualRunResponse: Dispatch<
    SetStateAction<IndividualRun | null>
  >;
}

export const PlaygroundWebSocketListener: FC<
  PlaygroundWebSocketListenerProps
> = ({
  latestIndividualRunRequestId,
  setConfig,
  setError,
  setLatestIndividualRunResponse,
}) => {
  const authContext = useAuth();
  const { user } = useUser();

  useConnectionStateListener("connected", () => {
    console.log("Connected to Ably!");
  });

  const handleWebSocketMessage =
    (handler: (payload: any) => void) => (message: WebSocketMessage) => {
      const { data: websocketData } = message;
      if (!websocketData) return;

      const { individual_run_request_id, payload } = JSON.parse(websocketData);

      if (individual_run_request_id !== latestIndividualRunRequestId) return;

      handler(payload);
    };

  useChannel(
    `user:${user.id}`,
    WEBSOCKET_MESSAGE_NAME.APPEND_TO_TEMPLATE,
    handleWebSocketMessage((payload) => {
      setConfig((prevConfig) => ({
        ...prevConfig,
        template: prevConfig.template + payload.template,
      }));
    }),
  );

  useChannel(
    `user:${user.id}`,
    WEBSOCKET_MESSAGE_NAME.CREATE_NEW_MESSAGE,
    handleWebSocketMessage((payload) => {
      setConfig((prevConfig) => ({
        ...prevConfig,
        messages: [...prevConfig.messages, payload.message],
      }));
    }),
  );

  useChannel(
    `user:${user.id}`,
    WEBSOCKET_MESSAGE_NAME.INDIVIDUAL_RUN_COMPLETE,
    handleWebSocketMessage(async () => {
      const url = new URL(
        `${ENDPOINTS.individual_run_requests}/${latestIndividualRunRequestId}`,
      );

      const response = await fetch(url.toString(), {
        headers: authHeader(authContext!.userToken!),
      });

      const {
        individual_run: individualRun,
        success,
      }: {
        individual_run: IndividualRun;
        success: boolean;
      } = await response.json();

      if (success) {
        setLatestIndividualRunResponse(individualRun);

        if (individualRun.status === "failed") {
          setError(individualRun.status_message!);
          return;
        }

        const prompt_version = individualRun.request?.prompt_version;
        if (!prompt_version) return;

        const parsed_prompt_template = prompt_version.prompt_template;
        const type = parsed_prompt_template.type;

        setConfig((prevConfig) => {
          if (type === "chat") {
            const originalMessages =
              individualRun.prompt_blueprint.prompt_template.messages;
            const newParsedMessages = parsed_prompt_template.messages;
            const lastParsedMessage =
              newParsedMessages[newParsedMessages.length - 1];
            return {
              ...prevConfig,
              messages: [...originalMessages, lastParsedMessage],
            };
          } else {
            const originalPrompt =
              individualRun.prompt_blueprint.prompt_template.content[0].text;
            const responseString = individualRun.request?.response_string;
            return {
              ...prevConfig,
              template: originalPrompt + responseString,
            };
          }
        });
      } else {
        setError("Error fetching individual run request");
      }
    }),
  );

  useChannel(
    `user:${user.id}`,
    WEBSOCKET_MESSAGE_NAME.UPDATE_LAST_MESSAGE,
    handleWebSocketMessage((payload) => {
      setConfig((prevConfig) => {
        const updatedMessages = [...prevConfig.messages];
        if (updatedMessages.length > 0) {
          updatedMessages[updatedMessages.length - 1] = payload.message;
        }
        return {
          ...prevConfig,
          messages: updatedMessages,
        };
      });
    }),
  );

  return null;
};
