import { FunctionMessage, ToolMessage } from "@/types";
import { formatJson, getStringContent } from "@/utils/utils";
import { CopyButton } from "../ui/copy-button";

export const ActionResponse = ({
  componentIsOpen,
  message,
}: {
  componentIsOpen: boolean;
  message: ToolMessage | FunctionMessage;
}) => {
  let content = getStringContent(message);
  if (componentIsOpen) content = formatJson(content);

  return (
    <>
      {!componentIsOpen ? (
        <div className="group-hover:inherit pt-2 text-left">
          {content && content.length > 200
            ? content.substring(0, 200) + "..."
            : content}
        </div>
      ) : (
        <div className="font-mono">
          <div className="font-medium text-gray-900">
            <div className="flex items-start justify-between">
              <div className="w-full">{content}</div>
              <div>
                <CopyButton text={content} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
