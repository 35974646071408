import { useCallback } from "react";
import { useAuth } from "@/context/auth-context";
import {
  ChatTemplate,
  PromptBlueprint,
  Template,
  TemplateFormat,
} from "@/types";

const useInputVariableParser = (
  parsePromptTemplateInputVariables: (...args: any[]) => any,
  parsedInputVariables: string[],
  setParsedInputVariables: (arg: string[]) => void,
  setInvalidPromptTemplate: (arg: boolean) => void,
  setInvalidPromptMessage: (arg: string) => void,
  setInvalidPromptIndex: (arg: number) => void,
) => {
  const authContext = useAuth();

  return useCallback(
    async (
      promptData: string | ChatTemplate,
      templateFormat: TemplateFormat,
    ) => {
      const inputVariablePlaceholder: string[] = [];

      const prompt_template: Template =
        typeof promptData === "string"
          ? {
              type: "completion",
              content: [{ type: "text", text: promptData }],
              input_variables: inputVariablePlaceholder,
              template_format: templateFormat,
            }
          : {
              ...promptData,
              input_variables: inputVariablePlaceholder,
            };

      const prompt_blueprint: PromptBlueprint = {
        prompt_template,
        provider_base_url_name: null,
      };

      parsePromptTemplateInputVariables(
        {
          prompt_version: prompt_blueprint,
          userToken: authContext!.userToken!,
        },
        {
          onSuccess: (data: any) => {
            // get the status code
            if (data && !data.success) {
              setInvalidPromptTemplate(true);
              const fullMessage = data.message[0].msg;
              const locationOfIndex = fullMessage.indexOf("index:");
              const message = fullMessage
                .substring(
                  fullMessage.indexOf("msg:") + 4,
                  locationOfIndex !== -1 ? locationOfIndex : undefined,
                )
                .trim();
              let index = fullMessage.substring(locationOfIndex + 6).trim();
              index = index ? parseInt(index) : undefined;
              setInvalidPromptMessage(message);
              setInvalidPromptIndex(index);
              return;
            } else {
              setInvalidPromptTemplate(false);
              setInvalidPromptMessage("");
              setInvalidPromptIndex(-1);
            }
            const sortedInputVariables = (data.input_variables || []).sort();
            if (
              sortedInputVariables.join(",") !== parsedInputVariables.join(",")
            ) {
              setParsedInputVariables(sortedInputVariables);
            }
          },
          onError: (error: any) => {
            console.error(error);
          },
        },
      );
    },
    [
      authContext,
      parsePromptTemplateInputVariables,
      parsedInputVariables,
      setParsedInputVariables,
      setInvalidPromptTemplate,
      setInvalidPromptMessage,
      setInvalidPromptIndex,
    ],
  );
};

export default useInputVariableParser;
