import { FC, Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { DotsVerticalIcon } from "@heroicons/react/outline";

interface MenuItem {
  text: string;
  onClick: (e: any) => void;
}

interface DropdownMenuProps {
  menuItems: MenuItem[];
  iconSize?: string;
}

const DropdownMenu: FC<DropdownMenuProps> = ({ menuItems, iconSize }) => {
  const handleDropdownClick = (e: any) => {
    e.stopPropagation();
  };

  const renderMenuItem = (active: boolean, menuItem: MenuItem) => {
    return (
      <div
        className={`${
          active ? "bg-gray-100" : ""
        } block cursor-pointer px-4 py-2`}
        onClick={menuItem.onClick}
      >
        {menuItem.text}
      </div>
    );
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button
        className="rounded-full p-1 hover:bg-gray-200"
        onClick={handleDropdownClick}
      >
        <DotsVerticalIcon
          aria-hidden="true"
          className={`h-${iconSize || "6"} w-${iconSize || "6"} text-gray-500`}
        />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className="absolute right-0 z-10 w-48 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          onClick={handleDropdownClick}
        >
          {menuItems.map((menuItem, index) => (
            <Menu.Item key={index}>
              {({ active }) => renderMenuItem(active, menuItem)}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default DropdownMenu;
