import { ValidationError } from "@/types/errors";

export const parseErrorMessage = (
  errors: any | ValidationError[],
): string | null => {
  if (!Array.isArray(errors) || errors.length === 0) return null;

  if (typeof errors[0].msg === "string") {
    return errors[0].msg.replace("Value error, ", "");
  }

  return (errors as ValidationError[])
    .map((error) => {
      const location = error.loc.join(" > ");
      return `Error in ${location}: ${error.msg}`;
    })
    .join("\n");
};
