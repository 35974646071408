import styled from "styled-components";

export const Button = styled.button`
  background: #5167f0;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(50, 62, 135, 0.19);
  color: #ffffff;
  display: inline-block;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.01em;
  padding: 10px 40px;
  transition: 0.2s all ease;

  &:hover {
    background: #6b7ef5;
  }

  @media (max-width: 575px) {
    font-size: 18px;
    padding: 8px 35px 10px;
  }
`;

export const Container = styled.div`
  background: #090b1c;
  padding: 90px 0;
`;

export const Image = styled.img`
  max-width: 100%;
  width: 100%;
`;

export const ImageWrapper = styled.div`
  max-width: 100%;
  padding: 0 12px;

  @media (min-width: 992px) {
    max-width: 50%;
  }
`;

export const InnerContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column-reverse;
  gap: 50px;
  justify-content: space-around;
  margin: 0 auto;
  width: 100%;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    flex-direction: row;
    gap: 0;
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1150px;
  }
`;

export const Text = styled.div`
  padding: 0 16px;
  width: 100%;

  h2 {
    color: white;
    font-size: 38px;
    font-weight: 500;
    letter-spacing: -0.02em;
    line-height: 1.2;
    margin: 0;
    width: 100%;

    @media (min-width: 767px) {
      font-size: 52px;
      max-width: 345px;
    }

    @media (min-width: 992px) {
      font-size: 56px;
    }
  }

  p {
    color: #d8dbf0;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.01em;
    margin: 20px 0 26px;

    @media (min-width: 576px) {
      font-size: 18px;
    }
  }

  @media (min-width: 992px) {
    max-width: 425px;
  }
`;
