import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Form,
  FormDescription,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import useDefaultWorkspace from "@/hooks/useDefaultWorkspace";
import { useDeleteWorkspace } from "@/queries";

type DeleteWorkspaceModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  workspaceName: string;
};

const DeleteWorkspaceForm = (props: DeleteWorkspaceModalProps) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const authContext = useAuth();
  const userContext = useUser();
  const defaultWorkspace = useDefaultWorkspace();
  const deleteWorkspace = useDeleteWorkspace(authContext!.userToken!);
  const form = useForm({
    defaultValues: {
      workspace_name: "",
    },
  });

  const onSubmit = async () => {
    try {
      await deleteWorkspace.mutateAsync(userContext?.activeWorkspaceId!);
      userContext!.setWorkspaceId(defaultWorkspace!.id);
      props.setOpen(false);
    } catch (error: unknown) {
      console.log(error);
    }
  };

  return (
    <>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            name="workspace_name"
            render={({ field }) => (
              <FormItem>
                <FormDescription className="text-gray-700">
                  This action cannot be undone.
                </FormDescription>
                <Input
                  className="italic"
                  placeholder={props.workspaceName}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    setIsDisabled(e.target.value !== props.workspaceName);
                  }}
                />
                <FormDescription>
                  To confirm, type <b>{props.workspaceName}</b> in the box
                  above.
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <DialogFooter className="mt-5">
            <Button
              type="button"
              onClick={() => props.setOpen(false)}
              className="bg-transparent text-sm"
              variant="outline"
            >
              Cancel
            </Button>
            <Button type="submit" variant="destructive" disabled={isDisabled}>
              Confirm Deletion
            </Button>
          </DialogFooter>
        </form>
      </Form>
    </>
  );
};

const DeleteWorkspaceModal: FC<DeleteWorkspaceModalProps> = (props) => (
  <Dialog open={props.open} onOpenChange={props.setOpen}>
    <DialogContent>
      <DialogHeader>
        <DialogTitle>
          Are you sure you want to delete this workspace?
        </DialogTitle>
      </DialogHeader>
      <DeleteWorkspaceForm {...props} />
    </DialogContent>
  </Dialog>
);

export default DeleteWorkspaceModal;
