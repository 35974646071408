export const GroupInfoBlock = ({ groupId }: { groupId: string }) => {
  return (
    <div className="mx-auto w-full max-w-lg rounded-sm border border-gray-200 bg-gray-50 px-4 pt-4 text-gray-700">
      <div className="grid min-h-[120px] grid-cols-7 justify-between">
        <div className="col-span-4 flex flex-col items-start justify-start">
          <div className="text-md break-all font-light">Group #{groupId}</div>
        </div>
      </div>
    </div>
  );
};
