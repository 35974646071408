import { InlineEdit } from "@/components";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { useEditPromptTemplate } from "@/queries";
import { BasePromptTemplate, ListPromptVersion } from "@/types";
import { PromptRegistry } from "@/types/prompt-registry";
import {
  EyeIcon,
  PencilIcon,
  PlayIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import { TooltipArrow } from "@radix-ui/react-tooltip";
import { Stethoscope } from "lucide-react";
import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/ui/button";
import PromptTemplateTagsEditor from "./PromptTemplateTagsEditor";

interface PromptTemplateHeaderProps {
  generatePlaygroundUrl: () => string;
  prompt: PromptRegistry;
  promptId: string;
  promptName: string;
  promptTemplateVersions: ListPromptVersion[]; // Replace 'any' with the actual type of the templates
  setOpenDeleteForm: (open: boolean) => void;
  setShowVersionList: (show: boolean) => void;
  showVersionList: boolean;
  templateFormat: string;
  version?: ListPromptVersion;
  total: number;
  templateTags: Array<string>;
  isSnippetAvailable?: boolean;
  isXrayMode?: boolean;
  setIsXrayMode: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PromptTemplateHeader: React.FC<PromptTemplateHeaderProps> = ({
  generatePlaygroundUrl,
  prompt,
  promptId,
  promptName,
  promptTemplateVersions,
  setOpenDeleteForm,
  setShowVersionList,
  showVersionList,
  templateFormat,
  version,
  total,
  templateTags,
  isSnippetAvailable = false,
  isXrayMode = false,
  setIsXrayMode,
}) => {
  const tags = prompt.tags;
  const navigate = useNavigate();
  const authContext = useAuth();
  const userContext = useUser();
  const editPromptTemplate = useEditPromptTemplate();

  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(promptId);
    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
  };

  const handleEditPromptTemplate = (basePromptTemplate: BasePromptTemplate) => {
    editPromptTemplate.mutate({
      prompt_id: Number(promptId),
      userToken: authContext?.userToken!,
      basePromptTemplate,
      activeWorkspaceId: userContext?.activeWorkspaceId!,
    });
  };

  return (
    <div className="flex">
      <div className="flex-1">
        <div className="flex items-center space-x-1">
          <InlineEdit.Root
            initialValue={promptName}
            onEdit={(prompt_name) => {
              handleEditPromptTemplate({
                prompt_name,
                tags,
              });
            }}
          >
            <InlineEdit.Trigger>
              <h1
                className={`cursor-text ${
                  promptName.length > 30 ? "text-2xl" : "text-3xl"
                } break-words font-semibold`}
              >
                {promptName}
              </h1>
            </InlineEdit.Trigger>
            <InlineEdit.Input className="py-1 text-base" />
            <InlineEdit.Rename>Rename</InlineEdit.Rename>
            <InlineEdit.Cancel>Cancel</InlineEdit.Cancel>
          </InlineEdit.Root>
          <div className="-mt-1 px-2">
            <PromptTemplateTagsEditor
              templateTags={templateTags}
              tags={tags}
              setTags={(tags) => {
                handleEditPromptTemplate({
                  prompt_name: promptName,
                  tags,
                });
              }}
            />
          </div>
        </div>
        <div className="flex items-center justify-between pb-3 xl:pb-8">
          <div className="flex items-center space-x-2">
            <h4 className="text-sm text-gray-600" title={version?.created_at}>
              {promptTemplateVersions.length > 1
                ? "Last Updated: "
                : "Created: "}
              {version?.created_at
                ? moment(version?.created_at).fromNow()
                : null}
            </h4>
            <div className="h-1 w-1 rounded-full bg-gray-300"></div>
            <span
              className="group cursor-pointer text-sm text-gray-600"
              onClick={handleCopy}
            >
              <span className={!copied ? "group-hover:hidden" : ""}>
                {copied ? "Copied to clipboard ✅" : `ID: ${promptId}`}
              </span>
              <span className={`hidden ${copied ? "" : "group-hover:block"}`}>
                Click to Copy...
              </span>
            </span>
            <div className="h-1 w-1 rounded-full bg-gray-300"></div>
            <span className="text-sm text-gray-600">
              Version {version?.number} of {total}
            </span>
            <div className="h-1 w-1 rounded-full bg-gray-300"></div>
            <div className="text-sm text-gray-600">{templateFormat}</div>
            {!showVersionList && (
              <>
                <div className="h-1 w-1 rounded-full bg-gray-300"></div>
                <div onClick={() => setShowVersionList(true)}>
                  <Button
                    onClick={() => setShowVersionList(false)}
                    variant={"link"}
                    size="sm"
                  >
                    <EyeIcon className="mr-1 h-4 w-4" />
                    Show Previous Versions
                  </Button>
                </div>
              </>
            )}
          </div>
          <div className="flex gap-x-2.5">
            {/* Not in the edit page */}
            <Button
              onClick={() => setOpenDeleteForm(true)}
              variant="destructiveOutline"
              size="sm"
              className="inline-flex items-center"
            >
              <TrashIcon className="h-4 w-auto pr-1" aria-hidden="true" />
              Delete
            </Button>
            <Button
              onClick={() => navigate(generatePlaygroundUrl())}
              variant="secondaryOutline"
              size="sm"
              className="inline-flex items-center"
            >
              <PlayIcon className="h-4 w-auto pr-1" aria-hidden="true" />
              Playground
            </Button>
            <XrayButton
              isSnippetAvailable={isSnippetAvailable}
              isXrayMode={isXrayMode}
              setIsXrayMode={setIsXrayMode}
            />
            <Button
              onClick={() =>
                navigate(
                  `/workspace/${userContext?.activeWorkspaceId}/prompt/${promptId}/${version?.number}/edit`,
                )
              }
              variant="outline"
              size="sm"
              className="inline-flex items-center"
            >
              <PencilIcon className="h-4 w-auto pr-1" aria-hidden="true" />
              Edit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const XrayButton = ({
  isSnippetAvailable = false,
  isXrayMode = false,
  setIsXrayMode,
}: {
  isSnippetAvailable?: boolean;
  isXrayMode?: boolean;
  setIsXrayMode: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  if (!isSnippetAvailable) return null;

  return (
    <TooltipProvider>
      <Tooltip delayDuration={0}>
        <TooltipTrigger asChild>
          <Button
            onClick={() => setIsXrayMode((prev: boolean) => !prev)}
            variant={isXrayMode ? "default" : "outline"}
            size="sm"
            className={`inline-flex items-center ${
              isXrayMode ? "border border-blue-500" : ""
            }`}
          >
            <Stethoscope className="h-4 w-auto pr-1" aria-hidden="true" />
            X-Ray
          </Button>
        </TooltipTrigger>
        <TooltipContent
          className="rounded bg-gray-800 p-2 text-sm text-white"
          side="top"
          sideOffset={10}
        >
          Expand or hide all snippets
          <TooltipArrow className="fill-current text-gray-800" />
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
