import { PlatformGenericLayout } from "@/components/MarketingLayout/Platform/PlatformGenericLayout/PlatformGenericLayout";
import * as S from "./Styles";
import {
  ClockIcon,
  FilterIcon,
  PlusCircleIcon,
  UsersIcon,
  SparklesIcon,
  ClipboardCheckIcon,
} from "@heroicons/react/outline";
import dataset from "@/assets/hero/dataset_management.png";
import datasetHistory from "@/assets/platform_details/dataset_history.png";

const features = [
  {
    icon: ClockIcon,
    title: "Version Control",
    description:
      "Version your datasets and maintain a history of changes as you iterate.",
  },
  {
    icon: FilterIcon,
    title: "Custom Dataset Creation",
    description:
      "Create datasets from production data using custom filters like score, prompt versions, and more.",
  },
  {
    icon: PlusCircleIcon,
    title: "Request Integration",
    description:
      "Add individual requests from production data to your datasets.",
  },
  {
    icon: UsersIcon,
    title: "Collaborative Editing",
    description:
      "Edit datasets easily amongst team members in a collaborative environment.",
  },
  {
    icon: SparklesIcon,
    title: "Synthetic Datasets (Coming Soon)",
    description:
      "Generate new datasets and augment existing ones with synthetic data.",
  },
  {
    icon: ClipboardCheckIcon,
    title: "Human Grading (Coming Soon)",
    description:
      "Invite human graders to grade your datasets for quality assurance.",
  },
];

const heroInfo = {
  title: (
    <>
      <span>Dataset Management</span> <br /> Curate your data
    </>
  ),
  subtitle: (
    <>
      Build, maintain, and manage all of your datasets in one place. <br />
      Create datasets from scratch or import them from production data.
    </>
  ),
  image: dataset,
  promoImage: datasetHistory,
};

const promoFeatures = [
  {
    title: "Bootstrap your Dataset",
    description:
      "Leverage your production data to make your application better.",
  },
  {
    title: "Locate the Edge Cases",
    description:
      "Use datasets to keep track of edge cases and possible regression points.",
  },
  {
    title: "Iteratively build datasets",
    description:
      "Add production requests to a dataset one at a time. Edit ground truth values and version.",
  },
  {
    title: "Collaborate with you Team",
    description:
      "Share datasets with your team and work together to improve them.",
  },
];

function DatasetManagement() {
  return (
    <S.Container>
      <PlatformGenericLayout
        heroInfo={heroInfo}
        featureTitle={"Dataset Management that Works"}
        features={features}
        promoTitle={"Capture the value of"}
        promoHighlight={"your data"}
        promoDescription={
          "Stay organized and help your team move quicker by managing all of your datasets in one place. Create datasets from scratch or import them from production data."
        }
        promoFeatures={promoFeatures}
      />
    </S.Container>
  );
}

export default DatasetManagement;
