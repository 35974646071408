import { useMemo } from "react";

import { useUser } from "@/context/user-context";

const useProviderBaseURLNameOptions = (existingValue?: string | null) => {
  const userContext = useUser();
  const providerBaseURLs = userContext.providerBaseURLs;

  return useMemo(() => {
    const existingValueArray = existingValue ? [existingValue] : [];
    const names = providerBaseURLs.map(({ name }) => name);
    const uniqueValuesSet = new Set([...existingValueArray, ...names]);
    const uniqueValuesArray = Array.from(uniqueValuesSet);
    return !!uniqueValuesArray.length ? ["", ...uniqueValuesArray] : [];
  }, [existingValue, providerBaseURLs]);
};

export default useProviderBaseURLNameOptions;
