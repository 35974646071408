import FilledButton from "@/components/MarketingLayout/Platform/PlatformGenericLayout/FilledButton/FilledButton";
import React from "react";
import * as S from "./Styles";

const FeatureGrid: React.FC<{ featureTitle: string; features: any[] }> = ({
  featureTitle,
  features,
}) => {
  return (
    <S.FeatureContainer>
      <S.FeatureHeading>{featureTitle}</S.FeatureHeading>
      <S.FeatureGridContainer>
        <S.FeatureGridWrapper>
          {features.map((feature, index) => (
            <S.FeatureCardWrapper key={index}>
              <S.FeatureCardHeader>
                <S.FeatureCardIcon>
                  <feature.icon className="h-5 w-auto text-indigo-500" />
                </S.FeatureCardIcon>
                <S.FeatureCardTitle>{feature.title}</S.FeatureCardTitle>
              </S.FeatureCardHeader>
              <S.FeatureCardDescription>
                {feature.description}
              </S.FeatureCardDescription>
            </S.FeatureCardWrapper>
          ))}
        </S.FeatureGridWrapper>
      </S.FeatureGridContainer>
      <a
        href="mailto:hello@promptlayer.com?subject=Demo Request"
        style={{ textDecoration: "none" }}
      >
        <FilledButton>Request a demo</FilledButton>
      </a>
    </S.FeatureContainer>
  );
};

export default FeatureGrid;
