import { SortingState } from "@tanstack/react-table";
import { useCallback, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import LoadingSpinner from "./components/LoadingSpinner";
import { PromptTemplatePage } from "./components/PromptTemplate/PromptTemplatePage";
import NotFoundMessage from "./components/ui/not-found";
import { useAuth } from "./context/auth-context";
import { useDeletePromptTemplate, usePromptRegistry } from "./queries";

export default function PromptTemplate() {
  const auth = useAuth();
  const userToken = auth!.userToken || "";
  const deletePrompt = useDeletePromptTemplate(userToken);
  const { promptId, versionId = undefined } = useParams();
  const params = useSearchParams()[0];
  const getPageFromQueryParam = useCallback(() => {
    const page = params.get("page");
    return page ? Number(page) : 1;
  }, [params]);
  const [page, setPage] = useState(getPageFromQueryParam());

  const getPageSizeFromQueryParam = useCallback(() => {
    const per_page = params.get("per_page");
    return per_page ? Number(per_page) : 5;
  }, [params]);
  const [pageSize, setPageSize] = useState(getPageSizeFromQueryParam());

  const getSortingFromQueryParam = useCallback((): SortingState => {
    const sortBy = params.get("sortBy");
    const sortOrder = params.get("sortOrder");
    return sortBy && sortOrder
      ? [{ id: sortBy, desc: sortOrder === "desc" }]
      : [{ id: "request_start_time", desc: true }];
  }, [params]);
  const [sorting, setSorting] = useState<SortingState>(
    getSortingFromQueryParam(),
  );

  const promptRegistryQuery = usePromptRegistry(userToken, Number(promptId));
  const promptRegistry = promptRegistryQuery.data;

  if (!promptId) {
    return (
      <NotFoundMessage
        title="Template not found"
        subtitle="We couldn't find the prompt template you were looking for."
        backLink="/prompt"
        backLinkMessage="Back to Prompt Registry"
      />
    );
  }

  if (promptRegistryQuery.isLoading) {
    return (
      <div className="p-5">
        <LoadingSpinner size={5} />
      </div>
    );
  }

  if (!promptRegistry) {
    return (
      <NotFoundMessage
        title="Template not found"
        subtitle="We couldn't find the prompt template you were looking for."
        backLink="/prompt"
        backLinkMessage="Back to Prompt Registry"
      />
    );
  }

  return (
    <PromptTemplatePage
      promptId={promptId!}
      versionId={versionId}
      sorting={sorting}
      pageSize={pageSize}
      page={page}
      setPage={setPage}
      setPageSize={setPageSize}
      setSorting={setSorting}
      deletePrompt={deletePrompt}
      promptRegistry={promptRegistry}
    />
  );
}
