import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Slider } from "@/components/ui/slider";
import { useUser } from "@/context/user-context";
import useProviderBaseURLNameOptions from "@/hooks/useProviderBaseURLNameOptions";
import {
  ParamConfig,
  defaultModelsChat,
  defaultModelsCompletion,
  modelConfigs,
  useAccessibleModels,
} from "@/modelConfig";
import { Model } from "@/types";
import { CheckIcon, DuplicateIcon } from "@heroicons/react/outline";
import { SlidersHorizontal } from "lucide-react";
import { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { SelectModelDropdown } from "./ui/custom-dropdown";

type Props = {
  initialProviderBaseURLName: string | null;
  model: Model | null;
  selectedProviderBaseURLName: string | null;
  setModel?: (model: Model | null) => void;
  setSelectedProviderBaseURLName: (providerBaseURLName: string | null) => void;
  isChat?: boolean;
};

const hasCustomParameters = (model: Model | null) => {
  return model && model.parameters;
};
const getModelConfig = (model: Model, isChat: boolean) => {
  const provider = model.provider;
  const providerExists = modelConfigs && modelConfigs[provider];

  if (!providerExists)
    return {
      metadata: {},
      params: {},
    };

  const modelExists =
    providerExists &&
    model &&
    model.name &&
    !!modelConfigs[provider][model.name];
  let name = model.name;

  if (!modelExists) {
    name = isChat
      ? defaultModelsChat[provider]
      : defaultModelsCompletion[provider];
  }

  return modelConfigs[provider][name];
};

export function PromptParameters(props: Props) {
  const isEditable = !!props.setModel;
  const customParametersSet = hasCustomParameters(props.model);
  const handleProviderChange = (provider: Model["provider"]) => {
    const defaultModelName = props.isChat
      ? defaultModelsChat[provider]
      : defaultModelsCompletion[provider];
    const defaultModelConfig = modelConfigs[provider][defaultModelName];
    const defaultParameters = Object.fromEntries(
      Object.entries(defaultModelConfig.params).map(([key, value]) => [
        key,
        value.default,
      ]),
    );

    props.setModel?.({
      provider: provider,
      name: defaultModelName,
      parameters: defaultParameters,
    });
  };

  const clearParameters = () => {
    props.setModel?.(null);
  };

  const handleParameterChange = (
    name: string,
    value: string | number | object,
  ) => {
    if (props.model) {
      const valid_params = Object.keys(
        getModelConfig(props.model, props.isChat || false).params,
      );

      const updatedParameters = {
        ...props.model.parameters,
        [name]: value,
      };

      const filteredParameters = Object.keys(updatedParameters)
        .filter((key) => valid_params.includes(key))
        .reduce((acc: any, key) => {
          acc[key] = updatedParameters[key];
          return acc;
        }, {});

      props.setModel?.({
        ...props.model,
        parameters: filteredParameters,
      });
    }
  };

  const getSelector = (key: string, value: any) => {
    switch (value.selectorType) {
      case "slider":
        return (
          <Slider
            id={key}
            name={key}
            min={0.0}
            max={2.0}
            step={0.01}
            className="col-span-1 h-8"
            label={props.model?.parameters?.[key] ?? 1}
            value={[props.model?.parameters?.[key]]}
            onValueChange={(value) => {
              handleParameterChange(key, value[0]);
            }}
          />
        );
      case "input":
        return (
          <>
            {isEditable ? (
              <Input
                type="number"
                id={key}
                name={key}
                min={value?.range ? value.range[0] : undefined}
                max={value?.range ? value.range[1] : undefined}
                value={props.model?.parameters?.[key] || value.default}
                className="col-span-1 h-8"
                onChange={(e) => {
                  handleParameterChange(key, Number(e.target.value));
                }}
              />
            ) : (
              <NonEditableInput
                value={props.model?.parameters?.[key] || value.default}
              />
            )}
          </>
        );
      case "dropdown":
        return (
          <>
            {isEditable ? (
              <Select
                value={
                  JSON.stringify(props.model?.parameters?.[key]) ||
                  value.default
                }
                onValueChange={(value) => {
                  handleParameterChange(key, JSON.parse(value));
                }}
              >
                <SelectTrigger
                  id={key}
                  name={key}
                  className="w-full rounded-lg border border-gray-300 px-2 py-1 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                >
                  <SelectValue placeholder="Select option" />
                </SelectTrigger>
                <SelectContent>
                  {Object.entries(value?.options || {}).map(
                    ([optionKey, optionValue]) => (
                      <SelectItem
                        key={optionKey}
                        value={JSON.stringify(optionValue)}
                      >
                        {optionKey}
                      </SelectItem>
                    ),
                  )}
                </SelectContent>
              </Select>
            ) : (
              <NonEditableInput
                value={
                  Object.keys(value?.options || {}).find(
                    (optionKey) =>
                      JSON.stringify(value?.options[optionKey]) ===
                      JSON.stringify(props.model?.parameters?.[key]),
                  ) || value.default
                }
              />
            )}
          </>
        );
      default:
        return null;
    }
  };

  const providerBaseURLNameOptions = useProviderBaseURLNameOptions(
    props.initialProviderBaseURLName,
  );

  const renderProviderBaseURLRow = () => {
    if (!providerBaseURLNameOptions.length) return null;

    let value = props.selectedProviderBaseURLName === "" ? "" : undefined;

    if (value === undefined) {
      value =
        props.selectedProviderBaseURLName ||
        props.initialProviderBaseURLName ||
        "";
    }

    const italicStyling = value === "" ? "italic text-gray-500" : "";

    return (
      <>
        <Label className="col-span-2" htmlFor="providerBaseURL">
          Provider Base URL
        </Label>
        <div className="col-span-2">
          {isEditable ? (
            <Select
              onValueChange={(providerName) => {
                props.setSelectedProviderBaseURLName(providerName);
              }}
              value={value}
            >
              <SelectTrigger
                className={`col-span-2 ${italicStyling}`}
                id="providerBaseURL"
              >
                <SelectValue placeholder="Default" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {providerBaseURLNameOptions.map((providerName) => (
                    <SelectItem
                      className={
                        providerName === "" ? "italic text-gray-500" : ""
                      }
                      key={providerName}
                      value={providerName}
                    >
                      {providerName || "Default"}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          ) : (
            <NonEditableInput
              value={props.initialProviderBaseURLName || "Default"}
            />
          )}
        </div>
      </>
    );
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="ghost">
          <div
            className={`relative flex flex-row items-center ${
              customParametersSet
                ? "after:absolute after:-right-2 after:-top-0 after:h-2 after:w-2 after:rounded-full after:bg-blue-500"
                : ""
            }`}
          >
            <SlidersHorizontal className="mr-2 h-4 w-4" />
            Parameters
          </div>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-100">
        <div className="grid gap-4">
          <div className="space-y-2">
            <h4 className="font-medium leading-none">Parameters</h4>
            <p className="text-sm text-muted-foreground">
              Configure model parameters.{" "}
              <a
                className="text-blue-500 hover:text-blue-400"
                target="_blank"
                rel="noreferrer"
                href="https://docs.promptlayer.com/features/prompt-registry#metadata-2"
              >
                Learn more.
              </a>
            </p>
          </div>
          <div className="w-full">
            {props.model ? (
              <>
                <div className="grid grid-cols-4 items-center gap-4 pt-4">
                  <Label className="col-span-2" htmlFor="provider">
                    Model Provider
                  </Label>
                  <div className="col-span-2">
                    {isEditable ? (
                      <Select
                        value={props.model.provider}
                        onValueChange={handleProviderChange}
                      >
                        <SelectTrigger id="provider" className="col-span-2">
                          <SelectValue placeholder="Select provider" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup>
                            {Object.keys(modelConfigs).map((provider) => (
                              <SelectItem key={provider} value={provider}>
                                {provider}
                              </SelectItem>
                            ))}
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    ) : (
                      <NonEditableInput value={props.model.provider} />
                    )}
                  </div>

                  <Label className="col-span-2" htmlFor="model">
                    Model Name
                  </Label>
                  <div className="col-span-2">
                    {isEditable ? (
                      <SelectModel {...props} />
                    ) : (
                      <NonEditableInput value={props.model.name} />
                    )}
                  </div>

                  {/* Row: Provider base URL */}
                  {renderProviderBaseURLRow()}
                </div>
                {Object.entries(
                  getModelConfig(props.model, props.isChat || false).params,
                ).map(([key, value]: [string, ParamConfig]) => (
                  <div
                    key={key}
                    className="grid grid-cols-2 items-center gap-4 pt-4"
                  >
                    <Label htmlFor={key}>{value.name}</Label>
                    {getSelector(key, value)}
                  </div>
                ))}
                {props.setModel ? (
                  <div className="flex items-center justify-center pt-4">
                    <Button
                      type="button"
                      onClick={clearParameters}
                      variant="destructiveOutline"
                    >
                      Clear Parameters
                    </Button>
                  </div>
                ) : null}
              </>
            ) : (
              <>
                {props.setModel ? (
                  <div className="flex items-center justify-center">
                    <Button
                      type="button"
                      variant="outline"
                      onClick={() => {
                        handleProviderChange("openai");
                      }}
                    >
                      Set Parameters
                    </Button>
                  </div>
                ) : (
                  <p className="text-sm italic text-gray-400">
                    No parameters set for this prompt version
                  </p>
                )}
              </>
            )}
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
}

function SelectModel(props: Props) {
  const userContext = useUser();
  const userId = userContext.user?.id;
  const modelConfig = useAccessibleModels(userId);
  const models = modelConfig[props.model?.provider ?? "openai"];

  // If provider is unknown, models will be undefined
  const modelNames = Object.keys(models || {});

  return (
    <SelectModelDropdown
      model={props.model?.name || null}
      setModel={(name) => {
        // When the provider is unknown models is undefined
        if (!models || !name || !props.model) return;

        const defaultParameters = Object.fromEntries(
          Object.entries(models[name]?.params || {}).map(([key, value]) => [
            key,
            value.default,
          ]),
        );

        props.setModel?.({
          provider: props.model.provider,
          name: name,
          parameters: defaultParameters,
        });
      }}
      options={modelNames}
    />
  );
}

function NonEditableInput({ value }: { value: string }) {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 700);
  };

  return (
    <div>
      <div className="relative">
        <Input
          type="text"
          value={value}
          className="col-span-2 h-8 py-1 pr-10 disabled:cursor-default disabled:opacity-80"
          readOnly
          disabled
        />
        <CopyToClipboard text={value} onCopy={handleCopy}>
          <button
            type="button"
            className="absolute inset-y-0 right-0 flex items-center pr-2 text-gray-400 hover:text-gray-600"
          >
            {copied ? (
              <CheckIcon className="h-5 w-5" />
            ) : (
              <DuplicateIcon className="h-5 w-5" />
            )}
          </button>
        </CopyToClipboard>
      </div>
    </div>
  );
}
