import { flexRender, Table as TableType } from "@tanstack/react-table";

import LoadingSpinner from "@/components/LoadingSpinner";
import {
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TableWithoutWrapper,
} from "@/components/ui/table";
import React, { useContext } from "react";
import { DatasetEditorContext } from "../DatasetEditor";
import DatasetViewerTableCell from "./DatasetViewerTableCell";

interface DatasetViewerTableProps<TData> {
  emptyMessage: any;
  height?: string;
  isLoading?: boolean;
  table: TableType<TData>;
}

const DatasetViewerTable = <TData,>({
  emptyMessage,
  height,
  isLoading = true,
  table,
}: DatasetViewerTableProps<TData>) => {
  const hasRows = !!table.getRowModel().rows?.length;
  const allRows = table.getRowModel().rows;
  const { isDraft, isUploadLoading } = useContext(DatasetEditorContext);

  return (
    <div
      className={`${
        height ? `h-[${height}]` : ""
      } mb-4 overflow-x-auto overflow-y-auto rounded-lg`}
    >
      <TableWithoutWrapper className="min-w-full">
        <TableHeader className="sticky top-0  bg-gray-100 ">
          {hasRows &&
            table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      key={header.id}
                      className={`${
                        isDraft && "last-of-type:bg-gray-100"
                      } select-none break-words border border-t-0 bg-white px-4 first-of-type:border-l-0 last-of-type:border-r-0 hover:bg-gray-50`}
                    >
                      <div>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                      </div>
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
        </TableHeader>
        <TableBody className="overflow-y-auto">
          {hasRows && !isUploadLoading ? (
            allRows.map((row) => (
              <TableRow
                key={row.id}
                className="group"
                data-state={row.getIsSelected() && "selected"}
              >
                {row.getVisibleCells().map((cell, index) => {
                  return (
                    <DatasetViewerTableCell
                      index={index}
                      key={cell.id}
                      cell={cell}
                      readOnly={!isDraft}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </DatasetViewerTableCell>
                  );
                })}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell
                colSpan={table.getAllColumns().length}
                className="h-24 text-center "
              >
                <p>
                  {!hasRows || isLoading || isUploadLoading ? (
                    <LoadingSpinner size={5} />
                  ) : (
                    emptyMessage
                  )}
                </p>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </TableWithoutWrapper>
    </div>
  );
};

export default React.memo(DatasetViewerTable);
