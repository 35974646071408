import { TrashIcon } from "lucide-react";
import { useContext } from "react";

import { DatasetEditorContext } from "..";
import { Button } from "@/components/ui/button";

const DeleteButton = () => {
  const {
    isDraft,
    isSavingDataset,
    handleDeleteClick,
    isDeletingDataset,
    isDeletingDatasetGroup,
  } = useContext(DatasetEditorContext);

  if (!isDraft || isSavingDataset) return null;

  return (
    <Button
      className="inline-flex items-center"
      isLoading={isDeletingDataset || isDeletingDatasetGroup}
      onClick={handleDeleteClick}
      size="sm"
      variant="destructiveOutline"
    >
      <TrashIcon aria-hidden="true" className="h-4 w-auto pr-1" />
      Discard Draft
    </Button>
  );
};

export default DeleteButton;
