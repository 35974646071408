import { FC } from "react";
import tournament from "@/assets/tutorials/tournament.jpg";
import youtube from "@/assets/tutorials/youtube.jpg";
import claudeTalk from "@/assets/tutorials/claude_talk.jpg";
import commitMessage from "@/assets/tutorials/commit_message.png";
import * as S from "./Styles";

interface TutorialCardProps {
  image: string;
  altText: string;
  tag: string;
  title: string;
  date: string;
  link: string;
}
const TutorialCard: FC<TutorialCardProps> = ({
  image,
  altText,
  tag,
  title,
  date,
  link,
}) => {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <S.TutorialCardContainer>
        <S.TutorialCardImageWrapper>
          <S.TutorialCardImage src={image} alt={altText} />
        </S.TutorialCardImageWrapper>
        <S.TutorialCardContent>
          <S.TutorialCardTag>{tag}</S.TutorialCardTag>
          <S.TutorialCardTitle>{title}</S.TutorialCardTitle>
          <S.TutorialCardDate>{date}</S.TutorialCardDate>
        </S.TutorialCardContent>
      </S.TutorialCardContainer>
    </a>
  );
};

const Tutorials: FC = () => {
  return (
    <S.Container>
      <S.InnerContainer>
        <S.Text>
          <h2>Insights and tutorials from the PromptLayer team</h2>
          <a
            href="https://blog.promptlayer.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <S.Button>See all tutorials and insights</S.Button>
          </a>
        </S.Text>
        <S.TutorialCardGrid>
          <TutorialCard
            image={commitMessage}
            altText="PromptLayer CMS"
            tag="Best practices"
            title="Prompt Management and collaboration using a CMS"
            date="Mar 7, 2024"
            link="https://blog.promptlayer.com/scalable-prompt-management-and-collaboration-fff28af39b9b"
          />
          <TutorialCard
            image={tournament}
            altText="PromptLayer team"
            tag="Prompt Engineering"
            title="Our Favorite Prompts from the Tournament"
            date="Apr 4, 2024"
            link="https://blog.promptlayer.com/our-favorite-prompts-from-the-tournament-b9d99464c1dc"
          />
          <TutorialCard
            image={claudeTalk}
            altText="PromptLayer team"
            tag="Best practices"
            title="Prompting tips for Anthropic Claude"
            date="Jul 31, 2024"
            link="https://blog.promptlayer.com/prompt-engineering-with-anthropic-claude-5399da57461d"
            // https://blog.promptlayer.com/building-chatgpt-from-scratch-the-right-way-ef82e771886e"
          />
          <TutorialCard
            image={youtube}
            altText="PromptLayer team"
            tag="LLM Dev"
            title="Prompt Engineering Youtube Tutorial Series"
            date="Apr 15, 2024"
            link="https://www.youtube.com/watch?v=u4LMdo-2EP4&list=PL6gx4Cwl9DGDLqIXStz_Zrk2utoTgrsfW"
          />
        </S.TutorialCardGrid>
      </S.InnerContainer>
    </S.Container>
  );
};

export default Tutorials;
