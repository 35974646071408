import {
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
} from "../ui/dialog";
import { Button } from "../ui/button";
import searchFiltersGif from "@/assets/search_filters.gif";

export function FineTuneStepHowToFilter({
  navigateFineTuneHome,
  navigateNextStep,
}: {
  navigateFineTuneHome: () => void;
  navigateNextStep: () => void;
}) {
  return (
    <>
      <DialogHeader>
        <DialogTitle>Select Requests</DialogTitle>
        <DialogDescription>
          Use the sidebar search, filters, and tags to select the data you want
          to use to train a new model.
        </DialogDescription>
      </DialogHeader>
      <div>
        <img
          src={searchFiltersGif}
          className="mx-auto my-2 h-[350px]"
          alt="Search and filters sidebar gif"
        />
      </div>
      <DialogFooter>
        <Button variant="secondary" onClick={navigateFineTuneHome}>
          Back
        </Button>
        <Button onClick={navigateNextStep}>Next</Button>
      </DialogFooter>
    </>
  );
}
