import { FC } from "react";
import pieChartIcon from "@/assets/pie-chart.svg";
import rightIcon from "@/assets/right.svg";
import * as S from "./Styles";

interface FeatureProps {
  title: string;
  description: string;
  linkHref: string;
  linkText: string;
  items: {
    title: string;
    subtitle: string;
  }[];
  featuredImage: string;
  reverseColumns?: boolean;
}

const Feature: FC<FeatureProps> = ({
  title,
  description,
  linkHref,
  linkText,
  items,
  featuredImage,
  reverseColumns = false,
}) => {
  const renderItems = () => {
    return (
      <S.Items>
        {items.map((item, index) => (
          <S.Item key={index}>
            <S.PieChartIcon alt="Pie chart icon" src={pieChartIcon} />
            <S.ItemText>
              <h4>
                {item.title}
                <br />
                <span>{item.subtitle}</span>
              </h4>
            </S.ItemText>
          </S.Item>
        ))}
      </S.Items>
    );
  };

  return (
    <S.Container>
      <S.Row $reverseColumns={reverseColumns}>
        <S.Column>
          <S.FeatureTopText>
            <h2>{title}</h2>
            <p>{description}</p>
            <S.StyledLink href={linkHref}>
              {linkText} <S.RightIcon alt="Right icon" src={rightIcon} />
            </S.StyledLink>
          </S.FeatureTopText>
          {renderItems()}
        </S.Column>
        <S.Column>
          <S.FeaturedImage alt="Featured image" src={featuredImage} />
        </S.Column>
      </S.Row>
    </S.Container>
  );
};

export default Feature;
