import LoadingSpinner from "@/components/LoadingSpinner";
import { Toggle } from "@/components/Toggle";
import AnimatedCheckIcon from "@/components/ui/animated-check-icon";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Form,
  FormDescription,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import {
  useCreateABtest,
  useCreateBacktest,
  useGetBlueprints,
  usePromptLabels,
  useRequestCountsForVersions,
} from "@/queries";
import { promptLabel } from "@/schemas";
import {
  NewPromptVersion,
  newPromptVersion,
} from "@/schemas/new-prompt-version";
import { PromptVersion } from "@/types/apiGetters";
import { Report } from "@/types/evaluate";
import { displayErrorToast } from "@/utils/toast";
import { LockClosedIcon } from "@heroicons/react/outline";
import { zodResolver } from "@hookform/resolvers/zod";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import { BarChart2, Split } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import useActiveWorkspace from "@/hooks/useActiveWorkspace";

enum RedirectParamEnum {
  backTest,
  abTest,
}

// Checks if a report pipeline uses promptTemplate:latest in one of the steps.
const reportColumnsIncludePromptTemplateLatestVersion = (
  reportId: number | undefined | null,
  reports: Report[],
  promptTemplateName: string,
) => {
  const report: Report | undefined = reports.find(
    (report) => report.id === reportId,
  );
  if (!report) return false;
  for (const column of report.columns) {
    if (
      column.column_type === "PROMPT_TEMPLATE" &&
      column.configuration.template.name === promptTemplateName
    ) {
      return column.configuration.template.version_number === null;
    }
  }
  return false;
};

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  setCommitMessage: (message: string) => void;
  setSourceReportId: (id: number | null) => void;
  handleSubmit: () => void;
  getStartingTemplate: () => string;
  getNewTemplate: () => string;
  previousBlueprintId: number | null;
  promptTemplateName: string;
  promptVersion: PromptVersion | null;
};

export const CreateCommitMessage = (props: Props) => {
  const [showTestModal, setShowTestModal] = useState(false);
  const navigate = useNavigate();
  const userContext = useUser();
  const workspaceId = userContext.activeWorkspaceId!;

  const handleClose = (redirect?: RedirectParamEnum, id?: number) => {
    if (
      showTestModal &&
      props.promptVersion?.id &&
      props.promptVersion?.prompt_id
    ) {
      setShowTestModal(false);
      if (redirect === RedirectParamEnum.backTest && id) {
        navigate(`/workspace/${workspaceId}/evaluate/reports/${id}`);
      } else if (redirect === RedirectParamEnum.abTest && id) {
        navigate(`/workspace/${workspaceId}/ab-releases/${id}`);
      } else {
        navigate(
          `/workspace/${workspaceId}/prompt/${props.promptVersion.prompt_id}/version/${props.promptVersion?.number}`,
        );
      }
    }
    props.setOpen(false);
  };

  const handleSavePromptVersion = async () => {
    try {
      setShowTestModal(true);
      await props.handleSubmit();
    } catch (error) {
      console.log("🚀 ~ handleSavePromptVersion ~ error:", error);
      handleClose();
    }
  };

  return (
    <Dialog open={props.open} onOpenChange={() => handleClose()}>
      <DialogContent className="max-h-[90%] max-w-2xl overflow-y-scroll">
        {showTestModal &&
        props.promptVersion?.id &&
        props.promptVersion?.prompt_id ? (
          <TestModal
            {...props}
            promptId={props.promptVersion?.prompt_id?.toString()}
            onClose={handleClose}
          />
        ) : (
          <NewPromptVersionForm
            {...props}
            onSuccess={handleSavePromptVersion}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

const NewPromptVersionForm = (
  props: Props & {
    onSuccess: () => void;
  },
) => {
  const [evaluationEdit, setEvaluationEdit] = useState(false);
  const [runEvaluation, setRunEvaluation] = useState(
    props.previousBlueprintId ? true : false,
  );

  const form = useForm<NewPromptVersion>({
    resolver: zodResolver(newPromptVersion),
    defaultValues: {
      source_report_id: props.previousBlueprintId || null,
    },
  });

  const authContext = useAuth();
  const userContext = useUser();
  const userToken = authContext?.userToken || "";
  const workspaceId = userContext.activeWorkspaceId;
  const activeWorkspace = useActiveWorkspace();
  const userCanCreateReport = !activeWorkspace?.admin_is_free;
  const {
    data: blueprintsListData,
    isLoading: blueprintsListIsLoading,
  }: { data: { reports: Report[] } | undefined; isLoading: boolean } =
    useGetBlueprints(userToken, workspaceId, true);
  const blueprintsList =
    blueprintsListData?.reports.sort(
      (a, b) =>
        new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime(),
    ) || [];

  const handleOpenEditEvaluation = () => {
    setEvaluationEdit(true);
    const startingValue = props.previousBlueprintId;
    props.setSourceReportId(startingValue);
    form.setValue("source_report_id", startingValue);
  };

  const handleCloseEditEvaluation = () => {
    setEvaluationEdit(false);
  };

  const handleRunEvaluationToggle = (val: boolean) => {
    setRunEvaluation(val);
    if (val) {
      const startingValue = props.previousBlueprintId;
      props.setSourceReportId(startingValue);
      form.setValue("source_report_id", startingValue);
    } else {
      props.setSourceReportId(null);
      form.setValue("source_report_id", null);
    }
  };

  const onSubmit = async (values: NewPromptVersion) => {
    props.onSuccess();
  };

  const diff = () => {
    const startingTemplate = props.getStartingTemplate();
    const newTemplate = props.getNewTemplate();
    return { startingTemplate, newTemplate };
  };

  const { startingTemplate, newTemplate } = diff();

  let blueprintsDropdown: string | React.ReactElement = "";
  if (blueprintsListIsLoading) {
    blueprintsDropdown = <span className="text-gray-500">Loading...</span>;
  } else if (form.watch("source_report_id")) {
    blueprintsDropdown =
      blueprintsList.find(
        (blueprint) => blueprint.id === form.watch("source_report_id"),
      )?.name || "?";
  } else {
    blueprintsDropdown = (
      <span className="text-gray-500">Select a pipeline...</span>
    );
  }

  return (
    <>
      <DialogHeader>
        <DialogTitle>Save New Prompt Version</DialogTitle>
      </DialogHeader>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <>
            <FormField
              name="message"
              render={({ field }) => (
                <FormItem>
                  <FormDescription className="text-gray-700">
                    Prompt version change message (Optional)
                  </FormDescription>
                  <Input
                    placeholder="Commit message"
                    {...field}
                    onChange={(e) => props.setCommitMessage(e.target.value)}
                  />
                  <div className="mx-2 !mt-4 rounded-sm border border-gray-300 bg-gray-50 px-3 py-2 shadow-sm">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-2">
                        <p className="text-sm text-gray-700">
                          Do you want to run an evaluation?
                        </p>
                        <span
                          className={`rounded-full px-3 py-1 text-xs ${
                            runEvaluation
                              ? "bg-blue-600 text-white"
                              : "bg-gray-300 text-gray-100"
                          }`}
                        >
                          {runEvaluation ? "On" : "Off"}
                        </span>
                        {runEvaluation && (
                          <span className="truncate text-xs text-gray-400">
                            {blueprintsDropdown}
                          </span>
                        )}
                      </div>
                      <h1
                        className={`cursor-pointer text-xs text-blue-600 hover:text-blue-500`}
                        onClick={() => {
                          if (evaluationEdit) {
                            handleCloseEditEvaluation();
                          } else {
                            handleOpenEditEvaluation();
                          }
                        }}
                      >
                        {evaluationEdit ? "Hide" : "Edit"}
                      </h1>
                    </div>
                    {evaluationEdit && (
                      <>
                        <FormField
                          name="source_report_id"
                          render={({ field }) => (
                            <>
                              {userCanCreateReport && (
                                <div
                                  className={`relative my-4 grid grid-cols-3 items-center gap-y-4 border-t border-gray-200 px-3 pt-6 text-sm text-gray-600`}
                                >
                                  <label
                                    htmlFor="preview-name"
                                    className="col-span-1"
                                  >
                                    Trigger evaluation:
                                  </label>
                                  <div className="col-span-2 flex items-center">
                                    <Toggle
                                      enabled={runEvaluation}
                                      setEnabled={handleRunEvaluationToggle}
                                    />
                                  </div>
                                  {runEvaluation && (
                                    <>
                                      <label
                                        htmlFor="preview-name"
                                        className="col-span-1"
                                      >
                                        Evaluation Pipeline:
                                      </label>
                                      <div className="col-span-2">
                                        {!blueprintsListIsLoading &&
                                        blueprintsList.length === 0 ? (
                                          <span className="text-gray-500">
                                            No evaluation pipelines available.
                                            Go to{" "}
                                            <a
                                              href="/evaluate"
                                              target="_blank"
                                              className="text-blue-500 hover:text-blue-400"
                                            >
                                              Evaluate
                                            </a>{" "}
                                            to create one.
                                          </span>
                                        ) : (
                                          <>
                                            <DropdownMenu>
                                              <DropdownMenuTrigger className="w-full">
                                                {blueprintsDropdown}
                                              </DropdownMenuTrigger>
                                              <DropdownMenuContent>
                                                {blueprintsList.map(
                                                  (blueprint) => (
                                                    <DropdownMenuItem
                                                      key={blueprint.id}
                                                      onSelect={() => {
                                                        form.setValue(
                                                          "source_report_id",
                                                          blueprint.id,
                                                        );
                                                        props.setSourceReportId(
                                                          blueprint.id,
                                                        );
                                                      }}
                                                    >
                                                      {blueprint.name}
                                                    </DropdownMenuItem>
                                                  ),
                                                )}
                                              </DropdownMenuContent>
                                            </DropdownMenu>
                                            {!reportColumnsIncludePromptTemplateLatestVersion(
                                              form.watch("source_report_id"),
                                              blueprintsList,
                                              props.promptTemplateName,
                                            ) && (
                                              <div className="py-3 text-sm font-normal text-red-600">
                                                <ExclamationTriangleIcon className="mr-1 inline-block h-4 w-4" />
                                                <strong className="font-bold">
                                                  Warning:
                                                </strong>{" "}
                                                This pipeline will not run the
                                                prompt you are about to save.
                                                Make sure there is a step that
                                                uses the "latest" version of
                                                this prompt template.{" "}
                                                <a
                                                  href="https://docs.promptlayer.com/features/faq#why-doesnt-my-evaluation-report-use-the-newest-version-of-my-prompt"
                                                  className="text-blue-500 hover:text-blue-400"
                                                  target="_blank"
                                                  rel="noreferrer"
                                                >
                                                  Learn how.
                                                </a>
                                              </div>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    </>
                                  )}
                                </div>
                              )}
                              {!userCanCreateReport && (
                                <div className="m-4 rounded-md border border-gray-200 bg-gray-50 p-4">
                                  <p className="pb-2 text-lg font-semibold">
                                    <LockClosedIcon className="mr-1 inline-block h-4 w-4" />
                                    Upgrade Required
                                  </p>
                                  <p className="text-md">
                                    To generate a full evaluation report, your
                                    workspace admin must upgrade your payment
                                    plan to "Startup" or "Team". Do this in
                                    settings on the top right corner of your
                                    screen.
                                  </p>
                                </div>
                              )}
                            </>
                          )}
                        />
                      </>
                    )}
                  </div>

                  {startingTemplate !== "" &&
                    startingTemplate !== newTemplate && (
                      <div className="max-h-96 overflow-y-scroll break-all px-3 py-5">
                        <ReactDiffViewer
                          oldValue={startingTemplate}
                          newValue={newTemplate}
                          splitView={false}
                          hideLineNumbers={true}
                          showDiffOnly={true}
                          extraLinesSurroundingDiff={2}
                          compareMethod={DiffMethod.SENTENCES}
                        />
                      </div>
                    )}
                  <FormMessage />
                </FormItem>
              )}
            />
            <DialogFooter className="mt-5">
              <Button
                type="button"
                onClick={() => props.setOpen(false)}
                className="bg-transparent text-sm"
                variant="outline"
              >
                Cancel
              </Button>
              <Button type="submit">Next</Button>
            </DialogFooter>
          </>
        </form>
      </Form>
    </>
  );
};

const TestModal = (
  props: Props & {
    promptId: string;
    onClose: (redirect?: RedirectParamEnum, id?: number) => void;
  },
) => {
  const [isBackTestClicked, setIsBacktestClicked] = useState(false);
  const [abTestClicked, setABTestClicked] = useState(false);
  const authContext = useAuth();
  const userContext = useUser();
  const userToken = authContext?.userToken!;
  const workspaceId = userContext.activeWorkspaceId!;

  const requestCounts = useRequestCountsForVersions(
    props.promptId || "",
    userToken || "",
  );
  const requestCountsData: number = useMemo(() => {
    if (requestCounts.data) {
      return Object.values(
        requestCounts.data as Record<number, number>,
      )?.reduce((acc: number, val: number) => acc + val, 0);
    }
    return 0;
  }, [requestCounts.data]);

  const { data } = usePromptLabels(
    userToken!,
    userContext.activeWorkspaceId!,
    parseInt(props.promptId, 10),
  );

  const availablePromptLabelsList = useMemo(() => {
    if (!data) return [];
    return data;
  }, [data]);

  const createBacktest = useCreateBacktest(userToken);
  const createABtest = useCreateABtest(userToken);

  const handleBackTestClick = () => {
    setIsBacktestClicked(true);
    if (requestCountsData > 0 && props.promptVersion?.id) {
      createBacktest.mutate(
        {
          promptVersionId: props.promptVersion.id,
          workspaceId: workspaceId,
        },
        {
          onSuccess: (res) => {
            setIsBacktestClicked(false);
            if (res?.success && res?.report_id) {
              props.onClose(RedirectParamEnum.backTest, res?.report_id);
            } else {
              props.onClose();
            }
          },
          onError: (err) => {
            setIsBacktestClicked(false);
            props.onClose();
            displayErrorToast("There's a problem with the backtest request");
          },
        },
      );
    }
  };

  const handleABTestClick = (selectedLabelId: number | null = null) => {
    if (availablePromptLabelsList?.length > 0 && !selectedLabelId) {
      setABTestClicked(true);
    } else if (props.promptVersion?.id && props?.promptId) {
      const payload = {
        promptVersionId: props.promptVersion.id,
        promptId: parseInt(props.promptId, 10),
        labelId: selectedLabelId ?? undefined,
        workspaceId: workspaceId,
      };
      createABtest.mutate(payload, {
        onSuccess: (res) => {
          if (res?.success) {
            props.onClose(
              RedirectParamEnum.abTest,
              res?.release_label_group?.id,
            );
          } else {
            props.onClose();
            displayErrorToast(
              res?.message ?? "There's a problem with the AB Test request",
            );
          }
        },
        onError: (err) => {
          props.onClose();
          displayErrorToast("There's a problem with the AB Test request");
        },
        onSettled: () => setABTestClicked(false),
      });
    } else {
      displayErrorToast("There's a problem with the AB Test request");
      props.onClose();
    }
  };

  return (
    <>
      <DialogHeader>
        <DialogTitle>
          <div className="my-8 flex flex-col items-center gap-y-4">
            <AnimatedCheckIcon />
            <h2 className="text-2xl font-bold text-gray-800">
              Prompt Template Saved
            </h2>
          </div>
        </DialogTitle>
      </DialogHeader>

      <div className="mb-8 flex justify-between space-x-6">
        {createABtest?.isLoading || createBacktest?.isLoading ? (
          <div className="flex w-full justify-center">
            <LoadingSpinner />
          </div>
        ) : isBackTestClicked && requestCountsData <= 0 ? (
          <p className="text-center text-sm text-gray-600">
            You don't have any request log history
          </p>
        ) : (
          <>
            <button
              onClick={handleBackTestClick}
              className="flex flex-1 flex-col items-center justify-center rounded-lg border-2 border-blue-200 p-6 text-blue-800 transition duration-300 hover:bg-blue-50"
            >
              <BarChart2 className="mb-2 h-12 w-12" />
              <span className="mb-2 font-semibold">Run a backtest</span>
              <p className="text-center text-sm text-gray-600">
                Evaluate performance with historical data
              </p>
            </button>
            <button
              onClick={() => handleABTestClick()}
              className="flex aspect-square flex-1 flex-col items-center justify-center rounded-lg border-2 border-purple-200 p-6 text-purple-800 transition duration-300 hover:bg-purple-50"
            >
              <Split className="mb-2 h-12 w-12" />
              <span className="mb-2 font-semibold">Start A/B test</span>
              <p className="text-center text-sm text-gray-600">
                Compare performance with current version
              </p>
            </button>
          </>
        )}
      </div>

      <DialogFooter className="mt-5">
        <Button
          type="button"
          onClick={() => props.onClose()}
          className="bg-transparent text-sm"
          variant="outline"
        >
          Close
        </Button>
      </DialogFooter>
      <SelectLabel
        isOpen={abTestClicked}
        setOpen={setABTestClicked}
        onSubmit={handleABTestClick}
        availablePromptLabelsList={availablePromptLabelsList}
      />
    </>
  );
};

const SelectLabel = ({
  isOpen,
  onSubmit,
  setOpen,
  availablePromptLabelsList,
}: {
  isOpen: boolean;
  onSubmit: (selectedLabelId: number) => void;
  setOpen: (isOpen: boolean) => void;
  availablePromptLabelsList: promptLabel.Response[] | any[];
}) => {
  const [selectedReleaseLabel, setSelectedReleaseLabel] = useState<
    number | null
  >(null);

  useEffect(() => {
    // Reset selected release label when modal is closed
    if (!isOpen) {
      setSelectedReleaseLabel(null);
    }
  }, [isOpen]);

  const handleSubmit = () => {
    try {
      onSubmit(selectedReleaseLabel!);
      setOpen(false);
    } catch (error: unknown) {
      console.error(error);
    }
  };

  const selectedLabelName =
    availablePromptLabelsList.find((label) => label.id === selectedReleaseLabel)
      ?.name ?? "";

  const renderContent = () => {
    return (
      <div className="space-y-2">
        <label
          htmlFor="release-label-select"
          className="block text-sm font-medium text-gray-700"
        >
          Release Label
        </label>
        <DropdownMenu>
          <DropdownMenuTrigger className="w-full rounded-md border border-gray-300 bg-white px-3 py-2 text-left shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500">
            {selectedLabelName || (
              <span className="text-gray-500">Select a release label</span>
            )}
          </DropdownMenuTrigger>
          <DropdownMenuContent className="mt-1 w-full">
            {availablePromptLabelsList?.map((promptLabel) => (
              <DropdownMenuItem
                key={promptLabel.id}
                onSelect={() => setSelectedReleaseLabel(promptLabel.id)}
                className="cursor-pointer px-3 py-2 hover:bg-blue-50"
              >
                {promptLabel.name}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    );
  };

  return (
    <Dialog onOpenChange={setOpen} open={isOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Select Release Label</DialogTitle>
          <DialogDescription>
            <span className="max-w-md">
              Choose a release label to build an experiment on top of. The
              release label must already be configured on one of your prompt
              templates.
            </span>
          </DialogDescription>
        </DialogHeader>
        {renderContent()}
        <DialogFooter>
          <Button onClick={() => setOpen(false)} variant="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit}>Submit</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
