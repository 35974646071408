import React, { useEffect, useRef, useState } from "react";
import {
  defaultModelsChat,
  defaultModelsCompletion,
  ModelConfig,
  ModelConfigMap,
} from "@/modelConfig";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import useProviderBaseURLNameOptions from "@/hooks/useProviderBaseURLNameOptions";
import { ModelSelection, PlaygroundConfig } from "./types";
import { SelectModelFromConfigsDropdown } from "../ModelProviderSelection/SelectModelFromConfigsDropdown";
import { SelectProviderFromConfigs } from "../ModelProviderSelection/SelectProviderFromConfigs";
import AdvancedControls from "../ModelProviderSelection/AdvancedControls";

export function AdvancedControlsSection({
  config,
  updateConfig,
  modelConfigs,
  updateModel,
  selectedModelConfig,
}: {
  config: PlaygroundConfig;
  updateConfig: (property: keyof PlaygroundConfig) => (value: any) => void;
  modelConfigs: ModelConfigMap;
  updateModel: (model: ModelSelection) => void;
  selectedModelConfig: ModelConfig;
}) {
  const isChat = config.type === "chat";
  const selectedProvider = config.model[0];
  const selectedModel = config.model[1];

  const [knownModelParameters, setKnownModelParameters] = useState<
    { paramKey: string; paramValue: number }[]
  >([]);
  const [customParameters, setCustomParameters] = useState<
    { paramKey: string; paramValue: number; id: number }[]
  >([]);

  const configParamsRef = useRef(config.params);
  const modelConfigsRef = useRef(modelConfigs);

  useEffect(() => {
    configParamsRef.current = config.params;
    modelConfigsRef.current = modelConfigs;
  });

  // At component load, sort through params in config.params
  useEffect(() => {
    // Selected model changed
    const isUnkownModel = !(
      selectedProvider in modelConfigsRef.current &&
      selectedModel in modelConfigsRef.current[selectedProvider] &&
      modelConfigsRef.current[selectedProvider][selectedModel]
    );

    const validConfigParams: { [key: string]: number } = Object.keys(
      configParamsRef.current,
    ).reduce((obj: { [key: string]: number }, key: string) => {
      obj[key] = configParamsRef.current[key];
      return obj;
    }, {});

    if (isUnkownModel) {
      // Move all params to customParameters
      const newCustomParameters: {
        paramKey: string;
        paramValue: number;
        id: number;
      }[] = [];
      for (const key in validConfigParams) {
        newCustomParameters.push({
          paramKey: key,
          paramValue: validConfigParams[key],
          id: Math.random(),
        });
      }
      setCustomParameters(newCustomParameters);
    } else {
      // Move known params to knownModelParameters, and unknown params to customParameters
      const chosenModelConfig: ModelConfig =
        modelConfigsRef.current[selectedProvider][selectedModel];

      const newKnownModelParameters: {
        paramKey: string;
        paramValue: number;
      }[] = [];
      const newCustomParameters: {
        paramKey: string;
        paramValue: number;
        id: number;
      }[] = [];

      for (const key in validConfigParams) {
        if (key in chosenModelConfig.params) {
          newKnownModelParameters.push({
            paramKey: key,
            paramValue: validConfigParams[key],
          });
        } else {
          newCustomParameters.push({
            paramKey: key,
            paramValue: validConfigParams[key],
            id: Math.random(),
          });
        }
      }
      setKnownModelParameters(newKnownModelParameters);
      setCustomParameters(newCustomParameters);
    }
  }, [selectedModel, selectedProvider]);

  const handleSetCustomParameters = (
    newCustomParameters: { paramKey: string; paramValue: number; id: number }[],
  ) => {
    let newParams = config.params;

    // Remove old customParameters
    customParameters.forEach((item) => {
      delete newParams[item.paramKey];
    });

    // Add new customParameters
    newCustomParameters.forEach((item) => {
      newParams[item.paramKey] = item.paramValue;
    });

    updateConfig("params")({
      ...newParams,
    });
    setCustomParameters(newCustomParameters);
  };

  const handleUpdateKnownParameters = (paramKey: string, paramValue: any) => {
    let updatedKnownModelParameters: { [key: string]: any } =
      knownModelParameters.reduce((obj: { [key: string]: any }, item) => {
        obj[item.paramKey] = item.paramValue;
        return obj;
      }, {});
    updatedKnownModelParameters[paramKey] = paramValue;
    updateConfig("params")({
      ...config.params,
      ...updatedKnownModelParameters,
    });
    setKnownModelParameters(
      Object.keys(updatedKnownModelParameters).map((key) => {
        return { paramKey: key, paramValue: updatedKnownModelParameters[key] };
      }),
    );
  };

  const providerBaseURLNameOptions = useProviderBaseURLNameOptions(
    config.provider_base_url_name,
  );

  const renderProviderBaseURLRow = () => {
    if (!providerBaseURLNameOptions.length) return null;

    const italicStyling =
      config.provider_base_url_name === "" ? "italic text-gray-500" : "";

    return (
      <div className="flex flex-col bg-gray-50 py-4 text-gray-500">
        <label className="block pb-4 text-xs font-medium">
          Provider Base URL
        </label>
        <div className="relative">
          <Select
            onValueChange={(providerName: string) => {
              updateConfig("provider_base_url_name")(providerName);
            }}
            value={config.provider_base_url_name || ""}
          >
            <SelectTrigger
              className={`flex items-center justify-between break-all rounded-sm border border-gray-300 bg-white py-2 pl-4 pr-2 text-sm font-medium text-gray-800 shadow-sm hover:bg-gray-50 ${italicStyling}`}
            >
              <SelectValue placeholder="Default" />
            </SelectTrigger>
            <SelectContent>
              {providerBaseURLNameOptions.map((providerName) => (
                <SelectItem
                  className={providerName === "" ? "italic text-gray-500" : ""}
                  key={providerName}
                  value={providerName}
                >
                  {providerName || "Default"}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>
    );
  };

  return (
    <>
      {/* Row: Provider */}
      <div className="flex flex-col bg-gray-50 py-4 text-gray-500">
        <label className="block pb-4 text-xs font-medium">LLM Provider</label>
        <div className="relative">
          <SelectProviderFromConfigs
            selectedProvider={selectedProvider}
            handleSelectProvider={(provider: string) => {
              const defaultModelForProvider = isChat
                ? defaultModelsChat[provider]
                : defaultModelsCompletion[provider];
              const newSelection: ModelSelection = [
                provider,
                defaultModelForProvider,
              ];
              updateModel(newSelection);
            }}
            modelConfigs={modelConfigs}
          />
        </div>
      </div>

      {/* Row: Model */}
      <div className="flex flex-col bg-gray-50 py-4 text-gray-500">
        <label className="block pb-4 text-xs font-medium">Model</label>
        <div className="relative">
          <SelectModelFromConfigsDropdown
            provider={selectedProvider}
            model={selectedModel}
            setModel={(model: string) => {
              const newSelection: ModelSelection = [config.model[0], model];
              updateModel(newSelection);
            }}
            filterOptions={{ is_chat: isChat }}
          />
        </div>
      </div>

      {/* Row: Provider base URL */}
      {renderProviderBaseURLRow()}

      {/* Row: Model Params */}
      {!!selectedModelConfig && (
        <ParameterControls
          selectedModel={selectedModel}
          selectedProvider={selectedProvider}
          updateParameterConfig={(paramKey: string, paramValue: any) => {
            updateConfig("params")({
              ...config.params,
              [paramKey]: paramValue,
            });
          }}
          handleUpdateKnownParameters={handleUpdateKnownParameters}
          modelConfigs={modelConfigs}
          customParameters={customParameters}
          setCustomParameters={handleSetCustomParameters}
          knownModelParameters={knownModelParameters}
        />
      )}
    </>
  );
}

function ParameterControls({
  updateParameterConfig,
  selectedModel,
  selectedProvider,
  modelConfigs,
  customParameters,
  setCustomParameters,
  knownModelParameters,
  handleUpdateKnownParameters,
}: {
  updateParameterConfig: (paramKey: string, paramValue: any) => void;
  selectedModel: string;
  selectedProvider: string;
  modelConfigs: ModelConfigMap;
  customParameters: { paramKey: string; paramValue: number; id: number }[];
  setCustomParameters: (
    newCustomParameters: { paramKey: string; paramValue: number; id: number }[],
  ) => void;
  knownModelParameters: { paramKey: string; paramValue: number }[];
  handleUpdateKnownParameters: (paramKey: string, paramValue: any) => void;
}) {
  const handleModelParamChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    updateParameterConfig(event.target.name, Number(event.target.value));
    handleUpdateKnownParameters(event.target.name, Number(event.target.value));
  };

  const handleModelParamChangeDropDown = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    updateParameterConfig(event.target.name, JSON.parse(event.target.value));
    handleUpdateKnownParameters(
      event.target.name,
      JSON.parse(event.target.value),
    );
  };

  const handleUpdateCandidate = (key: string, value: any) => {
    updateParameterConfig(key, value);
    handleUpdateKnownParameters(key, value);
  };

  const modelParamsObject = knownModelParameters.reduce(
    (obj: { [key: string]: number | string }, item) => {
      obj[item.paramKey] = item.paramValue;
      return obj;
    },
    {},
  );

  return (
    <AdvancedControls
      isCustomModel={
        !(
          selectedProvider in modelConfigs &&
          selectedModel in modelConfigs[selectedProvider] &&
          modelConfigs[selectedProvider][selectedModel]
        )
      }
      selectedProvider={selectedProvider}
      selectedModel={selectedModel}
      showRateLimitDelay={false}
      showChatTypeToggle={false}
      modelParams={modelParamsObject}
      customParameters={customParameters}
      setCustomParameters={setCustomParameters}
      modelConfigs={modelConfigs}
      handleModelParamChange={handleModelParamChange}
      handleModelParamChangeDropDown={handleModelParamChangeDropDown}
      updateCandidate={handleUpdateCandidate}
    />
  );
}
