import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { useTags } from "@/queries";
import { Tag } from "@/types";
import { classNames } from "@/utils/strings";
import { Menu, Transition } from "@headlessui/react";
import { TagIcon } from "@heroicons/react/outline";
import { StarIcon as StarIconSolid, XIcon } from "@heroicons/react/solid";
import {
  Fragment,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import LoadingSpinner from "./LoadingSpinner";
import TagsDropdownPaginatedFooter from "./TagsDropdownPaginatedFooter";
import { DivButton } from "./ui/button";

const TagEntryDropdown = ({
  toggleTag,
  selectedTags,
  setShowFavorites,
  showFavorites,
  clearFilters,
}: {
  toggleTag: (tag: string) => void;
  selectedTags: string[];
  setShowFavorites: (show: boolean) => void;
  showFavorites: boolean;
  clearFilters: () => void;
  currentPage: number;
}) => {
  const { pageContent, loading, searchTerm, setSearchTerm, pages } =
    useContext(TagsDropdownContext);
  const isSelected = (tag: string) => selectedTags.includes(tag);

  const [viewportContent, setViewportContent] = useState(pageContent);

  useEffect(() => {
    if (pageContent.length > 0 && viewportContent !== pageContent)
      setViewportContent(pageContent);
  }, [pageContent, viewportContent]);

  useEffect(() => {
    if (!loading && pageContent !== viewportContent) {
      setViewportContent(pageContent);
    }
  }, [loading, pageContent, viewportContent]);

  const filteredTags = viewportContent.map(({ name }) => name) || [];

  const EVAL_TAG_PREFIXES = ["column-", "evaluate-", "test-run:"];
  const userTags = filteredTags
    .filter(
      (tag) => !EVAL_TAG_PREFIXES.some((prefix) => tag.startsWith(prefix)),
    )
    .sort();
  const evalTags = filteredTags
    .filter((tag) => EVAL_TAG_PREFIXES.some((prefix) => tag.startsWith(prefix)))
    .sort();

  return (
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div className="py-2">
          <div className="mb-1 flex border-b pb-2">
            <div className="flex-1">
              <h1 className="px-3 text-sm font-semibold text-gray-900">
                Filter by tags:
              </h1>
            </div>
            <div className="">
              <h1
                className="mr-2 cursor-pointer text-sm text-blue-600 hover:text-blue-500"
                onClick={clearFilters}
              >
                Clear
              </h1>
            </div>
          </div>
          <div className="mb-2 flex items-center border-b pb-2">
            <input
              type="text"
              placeholder="Search tags..."
              className="mx-2 mt-1 box-border  w-full flex-1 rounded-md border-gray-300 py-1 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            {searchTerm && (
              <button
                className="mt-1 pr-2 text-gray-500 hover:text-gray-700 focus:outline-none"
                onClick={() => setSearchTerm("")}
              >
                <XIcon className="h-4 w-auto" aria-hidden="true" />
              </button>
            )}
          </div>
          <div className="max-h-80 overflow-y-auto">
            <Menu.Item key={"__pl__is_starred"}>
              <div
                className="flex cursor-pointer space-x-2 px-3 py-1 hover:bg-gray-50"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFavorites(!showFavorites);
                }}
              >
                <div className="flex h-5 items-center">
                  <input
                    id={"__pl__is_starred"}
                    type="checkbox"
                    checked={showFavorites}
                    readOnly
                    className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                </div>
                <div
                  className={classNames(
                    showFavorites
                      ? "font-semibold text-gray-900"
                      : "text-gray-700",
                    "block flex-1 text-sm",
                  )}
                >
                  <StarIconSolid
                    className="-mt-0.5 inline h-4 w-auto pr-1 text-yellow-500"
                    aria-hidden="true"
                  />
                  Favorites
                </div>
              </div>
            </Menu.Item>
            {loading && (
              <Menu.Item key={"__pl__loading"}>
                <div className="flex  cursor-pointer  justify-center space-x-2  px-3 py-1">
                  <LoadingSpinner />
                </div>
              </Menu.Item>
            )}
            {userTags.map((tag: string) => (
              <Menu.Item key={tag}>
                <div
                  className={`flex ${
                    loading ? "opacity-0" : "opacity-100"
                  } cursor-pointer space-x-2 px-3 py-1 transition-opacity ease-in-out hover:bg-gray-50`}
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleTag(tag);
                  }}
                >
                  <div className="flex h-5 items-center">
                    <input
                      id={tag}
                      type="checkbox"
                      checked={isSelected(tag)}
                      readOnly
                      className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    />
                  </div>
                  <div
                    className={classNames(
                      isSelected(tag)
                        ? "font-semibold text-gray-900"
                        : "text-gray-700",
                      "block flex-1 text-sm",
                    )}
                  >
                    {tag}
                  </div>
                </div>
              </Menu.Item>
            ))}
            {evalTags.map((tag: string) => (
              <Menu.Item key={tag}>
                <div
                  className={`flex ${
                    loading ? "opacity-0" : "opacity-100"
                  } cursor-pointer space-x-2 px-3 py-1 transition-opacity ease-in-out hover:bg-gray-50`}
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleTag(tag);
                  }}
                >
                  <div className="flex h-5 items-center">
                    <input
                      id={tag}
                      type="checkbox"
                      checked={isSelected(tag)}
                      readOnly
                      className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    />
                  </div>
                  <div
                    className={classNames(
                      isSelected(tag)
                        ? "font-semibold text-gray-900"
                        : "italic text-gray-500",
                      "block flex-1 text-sm",
                    )}
                  >
                    {tag}
                  </div>
                </div>
              </Menu.Item>
            ))}
            {pages === 0 && !loading && (
              <div className="px-3 py-2 text-xs text-gray-500">
                No tags found. Try adding the argument pl_tags to your requests.
                <pre className="pt-3">
                  ...
                  <br />
                  pl_tags = ["tag1", "v1"]
                  <br />
                  ...
                </pre>
              </div>
            )}
          </div>
        </div>
        <TagsDropdownPaginatedFooter />
      </Menu.Items>
    </Transition>
  );
};

export const TagsDropdownContext = createContext({
  traverse: (() => {}) as (forward?: boolean) => void,
  activePage: 1,
  pages: 0,
  pageContent: [] as Tag[],
  loading: false,
  searchTerm: "",
  setSearchTerm: (val: string) => {},
});

export const TagsDropdown = ({
  toggleTag,
  selectedTags,
  setShowFavorites,
  showFavorites,
  clearFilters,
  buttonVariant,
}: {
  toggleTag: (tag: string) => void;
  selectedTags: string[];
  setShowFavorites: (show: boolean) => void;
  showFavorites: boolean;
  clearFilters: () => void;
  buttonVariant?: "default" | "secondary" | "outline";
}) => {
  const isOneTagSelected = selectedTags.length > 0 || showFavorites;
  const auth = useAuth(),
    user = useUser();
  const userToken = auth?.userToken;
  const activeWorkspaceId = user.activeWorkspaceId;

  const [activePage, setActivePage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const prevSearchTerm = useRef(searchTerm);

  const {
    data: { items: currentPage, page, pages },
    isLoading,
    isFetching,
  } = useTags(userToken!, activeWorkspaceId!, activePage, searchTerm);

  useEffect(() => {
    if (currentPage !== 1 && prevSearchTerm.current !== searchTerm) {
      setActivePage(1);
      prevSearchTerm.current = searchTerm;
    }
  }, [currentPage, searchTerm]);

  const traverse = useCallback(
    (forward = true) => {
      if (forward && activePage < pages) setActivePage(activePage + 1);
      if (!forward && activePage > 1) setActivePage(activePage - 1);
    },
    [activePage, pages],
  );

  return (
    <TagsDropdownContext.Provider
      value={{
        traverse,
        activePage,
        pageContent: currentPage || [],
        pages,
        loading: isLoading || isFetching,
        setSearchTerm,
        searchTerm,
      }}
    >
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button>
            <DivButton
              variant={
                isOneTagSelected
                  ? "default"
                  : buttonVariant && typeof buttonVariant === "string"
                  ? buttonVariant
                  : "secondary"
              }
            >
              <TagIcon className="mr-1 h-4 w-auto" aria-hidden="true" />
              Tags
            </DivButton>
          </Menu.Button>
          {isOneTagSelected && (
            <span className="absolute -right-1 -top-1 flex h-3 w-3">
              <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-sky-400 opacity-75"></span>
              <span className="inline-flex h-3 w-3 rounded-full bg-sky-500"></span>
            </span>
          )}
        </div>
        <TagEntryDropdown
          currentPage={page}
          toggleTag={toggleTag}
          selectedTags={selectedTags}
          setShowFavorites={setShowFavorites}
          showFavorites={showFavorites}
          clearFilters={clearFilters}
        />
      </Menu>
    </TagsDropdownContext.Provider>
  );
};
