export const responseIsFunctionOrTool = (request: any): boolean => {
  if (typeof request.response_text === "object") {
    if (request.response_text !== null && "parts" in request.response_text)
      return false;
    return (
      !!request.response_text?.function_call?.name ||
      !!request.response_text?.tool_calls
    );
  }
  return false;
};
