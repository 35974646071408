import NotFound from "@/NotFound";
import GenericLanding from "@/components/GenericLanding/GenericLanding";
import Footer from "@/components/MarketingLayout/Footer/Footer";
import Header from "@/components/MarketingLayout/Header/Header";
import LeftMenu from "@/components/MarketingLayout/LeftMenu/LeftMenu";
import Features from "@/components/MarketingLayout/Platform/PlatformGenericLayout/Features/Features";
import Hero from "@/components/MarketingLayout/Platform/PlatformGenericLayout/Hero/Hero";
import { FC, useState } from "react";
import { Route, Routes } from "react-router-dom";

import PromoSection from "../PromoSection";
import * as S from "./Styles";

interface LayoutWrapperProps {
  heroInfo: {
    title: JSX.Element;
    subtitle: JSX.Element;
    image: string;
    promoImage: string;
  };
  featureTitle: string;
  features: { icon: React.ElementType; title: string; description: string }[];
  promoTitle: string;
  promoHighlight: string;
  promoDescription: string;
  promoFeatures: { title: string; description: string }[];
  children: React.ReactNode;
}

export const LayoutWrapper: FC<LayoutWrapperProps> = ({
  heroInfo,
  featureTitle,
  features,
  promoTitle,
  promoHighlight,
  promoDescription,
  promoFeatures,
  children,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <S.Container>
      <Header menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <LeftMenu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <Hero heroInfo={heroInfo} />
      <Features featureTitle={featureTitle} features={features} />
      <PromoSection
        features={promoFeatures}
        title={promoTitle}
        titleHighlight={promoHighlight}
        description={promoDescription}
        imageSrc={heroInfo.promoImage}
        buttonText={"Request a Demo"}
      />
      <Footer />
    </S.Container>
  );
};

export const PlatformGenericLayout: FC<{
  heroInfo: LayoutWrapperProps["heroInfo"];
  featureTitle: LayoutWrapperProps["featureTitle"];
  features: LayoutWrapperProps["features"];
  promoTitle: LayoutWrapperProps["promoTitle"];
  promoHighlight: LayoutWrapperProps["promoHighlight"];
  promoDescription: LayoutWrapperProps["promoDescription"];
  promoFeatures: LayoutWrapperProps["promoFeatures"];
}> = ({
  heroInfo,
  featureTitle,
  features,
  promoTitle,
  promoHighlight,
  promoDescription,
  promoFeatures,
}) => {
  const renderMainContent = () => {
    return (
      <Routes>
        <Route path="/" element={<GenericLanding />} />
        <Route
          path="*"
          element={
            <S.NotFoundWrapper>
              <NotFound />
            </S.NotFoundWrapper>
          }
        />
      </Routes>
    );
  };

  return (
    <LayoutWrapper
      heroInfo={heroInfo}
      featureTitle={featureTitle}
      features={features}
      promoTitle={promoTitle}
      promoHighlight={promoHighlight}
      promoDescription={promoDescription}
      promoFeatures={promoFeatures}
    >
      {renderMainContent()}
    </LayoutWrapper>
  );
};
