import { FC } from "react";
import * as S from "./Styles";

type FilledButtonProps = {
  children: string;
};

const FilledButton: FC<FilledButtonProps> = ({ children }) => {
  return <S.Container>{children}</S.Container>;
};

export default FilledButton;
