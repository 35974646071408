import {
  ArrowLeftIcon,
  DocumentAddIcon,
  DocumentReportIcon,
  DownloadIcon,
} from "@heroicons/react/outline";
import { FormEvent, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Breadcrumbs } from "@/components/Breadcrumbs";
import DatasetGroupSelectorModal from "@/components/DatasetGroupSelectorModal";
import { Button, DivButton } from "@/components/ui/button";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { useCreateBlueprint } from "@/queries";

const Header = () => {
  const userContext = useUser();
  return (
    <div className="bg-white p-1">
      <Breadcrumbs
        items={["Evaluate", "New Pipeline"]}
        navigateUrl={`/workspace/${userContext?.activeWorkspaceId}/evaluate`}
      />
      <div className="flex pt-4">
        <div className="flex-1 pt-4">
          <h1 className="text-2xl text-gray-900">Create a new pipeline</h1>
          <h2 className="pt-1 text-lg text-gray-500">
            Connect a dataset of input variables to start evaluating your
            prompts.&nbsp;
            <a
              href="https://docs.promptlayer.com/features/evaluations/examples"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:text-blue-400"
            >
              See some examples on the docs.
            </a>
          </h2>
          <Link
            className="text-blue-500 hover:text-blue-400"
            to={`/workspace/${userContext?.activeWorkspaceId}/evaluate`}
          >
            <ArrowLeftIcon
              className="-mt-1 mr-1 inline h-4 w-auto"
              aria-hidden="true"
            />
            Go back
          </Link>
        </div>
      </div>
    </div>
  );
};

const CreateBlueprint = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [isDatasetGroupSelectorModalOpen, setDatasetGroupSelectorModalOpen] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [selectedDatasetGroupId, setSelectedDatasetGroupId] = useState<
    number | null
  >(null);
  const auth = useAuth();
  const navigate = useNavigate();
  const userContext = useUser();
  const userToken = auth!.userToken!;
  const createBlueprintMutation = useCreateBlueprint(userToken);

  const selectedDatasetGroupName = useMemo((): string | undefined => {
    const _datasetGroup = userContext.newDatasetGroups.find(
      (datasetGroup) => datasetGroup.id === selectedDatasetGroupId,
    );
    return _datasetGroup?.name;
  }, [selectedDatasetGroupId, userContext.newDatasetGroups]);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (!name || !selectedDatasetGroupId) {
      setErrorMessage("Please fill in all fields.");
      return;
    } else {
      setLoading(true);
      const blueprint = await createBlueprintMutation.mutateAsync({
        dataset_group_id: selectedDatasetGroupId,
        name,
      });
      setLoading(false);
      if (!blueprint || !blueprint.success) {
        const pydanticError: {
          loc: string[];
          msg: string;
          type: string;
        }[] = blueprint.message;
        setErrorMessage(
          `Error: ${pydanticError[0].msg}: ${pydanticError[0].loc[0]}`,
        );
      } else {
        navigate(
          `/workspace/${userContext?.activeWorkspaceId}/evaluate/blueprints/${blueprint.report_id}`,
        );
      }
    }
  };

  const DATASET_EXAMPLES: {
    name: string;
    description: string;
    icon: JSX.Element;
    fileName: string;
  }[] = [
    {
      name: "Names",
      description:
        "A dataset of 15 names from around the world. Use it to evaluate onboarding flows.",
      icon: <DocumentReportIcon className="h-5 w-5 text-gray-500" />,
      fileName: "names-dataset-example.csv",
    },
    {
      name: "Q & A",
      description:
        "Evaluation dataset for a chatbot. Includes 20 questions and ground-truth answers.",
      icon: <DocumentReportIcon className="h-5 w-5 text-gray-500" />,
      fileName: "q-and-a-dataset-example.csv",
    },
    {
      name: "Conversations",
      description:
        "A few example conversations between a customer and a support agent. Includes the action taken.",
      icon: <DocumentReportIcon className="h-5 w-5 text-gray-500" />,
      fileName: "conversation-dataset-example.csv",
    },
  ];

  return (
    <>
      <Header />
      <div className="flex flex-col">
        <div className="w-full">
          <div className="mt-4 pb-2 font-medium text-gray-700">Try it now:</div>
          <div className="grid w-full grid-cols-1 lg:grid-cols-2 lg:gap-x-8">
            <form
              onSubmit={handleSubmit}
              className="mb-6 w-full rounded-sm bg-gray-100 p-5"
            >
              <div className="grid grid-cols-2 gap-x-3">
                <div className="">
                  <label
                    className="mb-2 block text-sm font-medium text-gray-500"
                    htmlFor="name"
                  >
                    Name
                  </label>
                  <input
                    className="w-full max-w-xl appearance-none rounded border border-gray-200 px-3 py-2 leading-tight text-gray-700 placeholder-gray-400 focus:outline-none"
                    placeholder="Pipeline name"
                    id="name"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="">
                  <label
                    className="mb-2 block text-sm font-medium text-gray-500"
                    htmlFor="dataset"
                  >
                    Dataset
                  </label>
                  <DivButton
                    className="text-blue w-full rounded p-2 disabled:cursor-not-allowed"
                    onClick={() => setDatasetGroupSelectorModalOpen(true)}
                    variant={
                      selectedDatasetGroupName
                        ? "secondaryOutline"
                        : "secondary"
                    }
                  >
                    <div className="flex items-center justify-center">
                      {selectedDatasetGroupName ? (
                        <>
                          <div className="flex justify-center">
                            <DocumentReportIcon
                              className="mr-2 inline h-5 w-auto"
                              aria-hidden="true"
                            />
                          </div>
                          <span>Selected:</span>
                          <span className="mx-2 font-bold">
                            {selectedDatasetGroupName}
                          </span>
                        </>
                      ) : (
                        <>
                          <div className="flex justify-center text-gray-400">
                            <DocumentAddIcon className="mr-2 inline h-5 w-auto" />
                          </div>
                          <span className="text-gray-400">
                            Select or Upload
                          </span>
                        </>
                      )}
                    </div>
                  </DivButton>
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div className="flex w-full justify-center pb-2 pt-6">
                  <Button
                    className="w-1/2"
                    disabled={false}
                    isLoading={loading}
                    onClick={handleSubmit}
                    size="lg"
                  >
                    Create Pipeline
                  </Button>
                </div>
              </div>
              {errorMessage && (
                <div className="mt-4 rounded-sm border border-red-200 bg-red-50 p-2 text-sm text-red-500">
                  {errorMessage}
                </div>
              )}
            </form>
            <div className="w-full">
              <div className="mt-8 max-w-sm rounded-sm border-l-4 border-blue-700 bg-gray-200 px-3 py-2 text-sm text-gray-700">
                A dataset is a collection of test cases. Each case contains a
                set of input variables that will be injected into your prompts.
                Learn more{" "}
                <a
                  href="https://docs.promptlayer.com/features/evaluations/datasets"
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue-700 hover:text-blue-500"
                >
                  here
                </a>
                .
              </div>
            </div>
          </div>
        </div>
        <div className="w-full">
          <div className="mt-4 pb-2 font-medium text-gray-700">
            Dataset examples:
          </div>
          <div className="flex w-full gap-x-6">
            {DATASET_EXAMPLES.map((dataset, idx) => (
              <div
                key={idx}
                className="flex w-[300px] flex-col gap-y-1 rounded-md border border-gray-200 p-4"
              >
                <div className="flex">
                  <div className="flex-1">
                    <div className="-mt-1 mb-2">{dataset.icon}</div>
                    <div>{dataset.name}</div>
                  </div>
                  <a
                    href={`${process.env.PUBLIC_URL}/datasets/${dataset.fileName}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button size="sm" variant="outline" className="gap-x-1">
                      <DownloadIcon className="h-4 w-4" />
                      Download
                    </Button>
                  </a>
                </div>
                <div className="pr-8 text-sm font-light text-gray-500">
                  {dataset.description}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {isDatasetGroupSelectorModalOpen && (
        <DatasetGroupSelectorModal
          handleDatasetGroupIdClick={setSelectedDatasetGroupId}
          setOpen={setDatasetGroupSelectorModalOpen}
        />
      )}
    </>
  );
};

export default CreateBlueprint;
