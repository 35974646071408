import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Message, Role, roles, TemplateFormat } from "@/types";

export const getMessageTemplate = (
  role: Role,
  templateFormat: TemplateFormat,
): Message => {
  const isRoleFunction = role === "function";
  if (isRoleFunction)
    return {
      role,
      content: [{ type: "text", text: "" }],
      input_variables: [],
      name: "",
      template_format: "f-string",
    };
  if (role === "placeholder")
    return {
      role,
      name: "",
      content: [],
      input_variables: [],
      template_format: "f-string",
    };
  return {
    role,
    content: [{ type: "text", text: "" }],
    input_variables: [],
    template_format: templateFormat,
    tool_call_id: "",
  };
};

const SelectRole = ({
  messageTemplate,
  onChange,
  templateFormat,
}: {
  messageTemplate: Message;
  onChange: (messageTemplate: Message) => void;
  templateFormat: TemplateFormat;
}) => (
  <Select
    value={messageTemplate.role}
    onValueChange={(role: Role) => {
      const newMessageTemplate = getMessageTemplate(role, templateFormat);
      onChange(newMessageTemplate);
    }}
  >
    <SelectTrigger className="rounded-none border border-gray-200 bg-white font-bold capitalize shadow-none group-hover:border-gray-300 group-hover:text-black">
      <SelectValue placeholder="Select a role" />
    </SelectTrigger>
    <SelectContent className="rounded-none bg-white capitalize">
      <SelectGroup>
        <SelectLabel>Roles</SelectLabel>
        {roles.map((role) => (
          <SelectItem key={role} value={role}>
            {role}
          </SelectItem>
        ))}
      </SelectGroup>
    </SelectContent>
  </Select>
);

export default SelectRole;
