import { useEffect } from "react";
import { XIcon } from "@heroicons/react/outline";
import { Button } from "@/components/ui/button";
import { TextCopyBlock } from "./TextCopyBlock";

export default function CodeBlockModal({
  text,
  isCode,
  showModal,
  setShowModal,
  highlight = false,
  inputVariables,
  forceMonoSpace = false,
}: {
  text: string;
  isCode: boolean;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  highlight?: boolean;
  inputVariables?: any;
  forceMonoSpace?: boolean;
}) {
  // Close on escape key
  useEffect(() => {
    const closeOnEscapeKeyDown = (e: KeyboardEvent) => {
      if ((e.charCode || e.keyCode) === 27) {
        setShowModal(false);
      }
    };
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    return () => {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
  }, [setShowModal]);

  if (!showModal) {
    return null;
  }

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none focus:outline-none">
        <div className="relative mx-auto h-full w-full p-1 md:p-5">
          {/*content*/}
          <div className="relative flex h-full w-full flex-col rounded-md border-0 bg-white shadow-lg outline-none focus:outline-none">
            <div className="sm:flex sm:flex-row-reverse ">
              <XIcon
                className="mr-3 mt-3 h-5 w-auto flex-shrink-0 cursor-pointer text-gray-500 hover:text-gray-700"
                aria-hidden="true"
                onClick={() => setShowModal(false)}
              />
            </div>
            <div className="flex-1 overflow-y-scroll rounded-md bg-white px-4 pb-4 sm:my-5 sm:pb-8">
              <TextCopyBlock
                text={text}
                code={isCode}
                highlight={highlight}
                inputVariables={inputVariables}
                forceMonoSpace={forceMonoSpace}
              />
            </div>

            {/*footer*/}
            <div className="rounded-md p-4 sm:flex sm:flex-row-reverse sm:px-6">
              <Button onClick={() => setShowModal(false)}>Close</Button>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed inset-0 z-40 bg-black opacity-75"></div>
    </>
  );
}
