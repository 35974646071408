import { Popover } from "@headlessui/react";
import { memo } from "react";

import FilterByTags from "./FilterByTags";
import useInputRef from "./hooks/useInputRef";
import useSearchQuery from "./hooks/useSearchQuery";

const SearchInput = memo(
  ({
    inputRef,
    placeholder,
  }: {
    inputRef: React.RefObject<HTMLInputElement>;
    placeholder?: string;
  }) => {
    const [searchQuery, setSearchQuery] = useSearchQuery();
    useInputRef(inputRef);

    return (
      <Popover.Button
        as="input"
        type="text"
        name="search"
        id="search"
        autoComplete="off"
        placeholder={placeholder || "Search..."}
        value={searchQuery}
        onSubmit={() => {
          inputRef.current?.blur();
        }}
        onChange={(e) => setSearchQuery(e.target.value)}
        onKeyDown={(e) => {
          if (e.code === "Space") {
            e.preventDefault();
            setSearchQuery((previous) => previous + " ");
          }
        }}
        className="block w-full rounded-md border-gray-300 pr-12 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
      />
    );
  },
);

const RequestsSearchWithTags = ({
  inputRef,
}: {
  inputRef: React.RefObject<HTMLInputElement>;
}) => {
  return (
    <>
      <div className="relative flex flex-1 items-center">
        <SearchInput inputRef={inputRef} />
      </div>
      <FilterByTags />
    </>
  );
};

const SearchBarInput = ({
  inputRef,
  requestsOn,
}: {
  inputRef: React.RefObject<HTMLInputElement>;
  requestsOn?: boolean;
}) => {
  return (
    <div className="mt-1 flex items-center gap-1">
      {(requestsOn && <RequestsSearchWithTags inputRef={inputRef} />) || (
        <SearchInput inputRef={inputRef} placeholder="Enter a trace ID..." />
      )}
    </div>
  );
};

export default memo(SearchBarInput);
