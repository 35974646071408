import { FC, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ExclamationCircleIcon,
  PlusIcon,
  XIcon,
} from "@heroicons/react/outline";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from "@/components/ui/dropdown-menu";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { ToastType } from "@/enums";
import { useIntersectionObserver } from "@/hooks";
import {
  useCreateReleaseLabelGroup,
  usePromptRegistryObjects,
} from "@/queries";
import { displayToast } from "@/utils/toast";
import LoadingSpinner from "./LoadingSpinner";
import moment from "moment";

type SelectPromptRegistryObjectsModalProps = {
  isOpen: boolean;
  selectedPromptLabelName: string;
  setOpen: (isOpen: boolean) => void;
};

const SelectPromptRegistryObjectsModal: FC<
  SelectPromptRegistryObjectsModalProps
> = ({ isOpen, selectedPromptLabelName, setOpen }) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [selectedPromptRegistryIds, setSelectedPromptRegistryIds] = useState<
    number[]
  >([]);
  const [selectedPromptRegistry, setSelectedPromptRegistry] =
    useState<any>(null);
  const authContext = useAuth();
  const navigate = useNavigate();
  const userToken = authContext!.userToken;
  const userContext = useUser();
  const { mutateAsync: createReleaseLabelGroup, isLoading } =
    useCreateReleaseLabelGroup(userToken!);
  const ref = useRef<HTMLDivElement>(null);
  const entry = useIntersectionObserver(ref, {});
  const promptRegistryObjects = usePromptRegistryObjects(userToken!, {
    workspaceId: userContext.activeWorkspaceId!,
    promptLabelName: selectedPromptLabelName,
  });

  const promptRegistryObjectsList =
    promptRegistryObjects.data?.pages.flatMap((page) => page.items) || [];

  if (entry?.isIntersecting) {
    promptRegistryObjects.fetchNextPage();
  }

  const handleAddRegistry = () => {
    if (selectedPromptRegistry) {
      setSelectedPromptRegistryIds((prev) => [
        ...prev,
        selectedPromptRegistry.id,
      ]);
      setSelectedPromptRegistry(null);
    }
  };

  const handleRemoveRegistry = (id: number) => {
    setSelectedPromptRegistryIds((prev) => prev.filter((item) => item !== id));
  };

  const handleSubmit = async () => {
    try {
      const response = await createReleaseLabelGroup({
        prompt_label_name: selectedPromptLabelName,
        prompt_registry_ids: selectedPromptRegistryIds,
        workspace_id: userContext.activeWorkspaceId!,
      });

      if (response.success) {
        displayToast("A/B release created!", ToastType.success);
        navigate(
          `/workspace/${userContext.activeWorkspaceId!}/ab-releases/${
            response.release_label_group.id
          }`,
        );
      } else {
        setErrorMessage(response.error || "Error creating A/B release");
      }
    } catch (error: unknown) {
      setErrorMessage("Network error or bad server response");
    }
  };

  return (
    <Dialog onOpenChange={setOpen} open={isOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add Prompt Templates</DialogTitle>
          <DialogDescription>
            Given the release label you just selected, the following are prompt
            templates that have it applied. Select the ones to include in this
            experiment.
          </DialogDescription>
        </DialogHeader>
        <div className="space-y-4">
          {selectedPromptRegistryIds.length > 0 && (
            <div className="grid grid-cols-3 gap-3">
              {promptRegistryObjectsList
                .filter((registry) =>
                  selectedPromptRegistryIds.includes(registry.id),
                )
                .map((registry) => (
                  <div
                    key={registry.id}
                    className="group relative cursor-pointer break-words rounded-md border border-gray-200 bg-white p-3 duration-200 ease-in-out hover:bg-red-50"
                    onClick={() => handleRemoveRegistry(registry.id)}
                  >
                    <div className="absolute right-1 top-1">
                      <XIcon className="hidden h-4 w-4 group-hover:block group-hover:text-red-500" />
                    </div>
                    <h3 className="mb-1 truncate text-sm font-semibold text-gray-800">
                      {registry.prompt_name}
                    </h3>
                    <p className="text-xs text-gray-500">
                      {moment(registry.last_version_created_at).format(
                        "MMMM DD, YYYY",
                      )}
                    </p>
                  </div>
                ))}
            </div>
          )}
          <div className="space-y-2 pb-2">
            <label
              htmlFor="prompt-template-select"
              className="block text-sm font-medium text-gray-700"
            >
              Available Prompt Templates
            </label>
            <div className="flex items-center space-x-2">
              <DropdownMenu>
                <DropdownMenuTrigger className="w-full rounded-md border border-gray-300 bg-white px-3 py-2 text-left shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500">
                  {selectedPromptRegistry?.prompt_name || (
                    <span className="text-gray-500">
                      Select a prompt template
                    </span>
                  )}
                </DropdownMenuTrigger>
                <DropdownMenuContent className="mt-1 w-full">
                  {promptRegistryObjectsList
                    .filter(
                      (registry) =>
                        !selectedPromptRegistryIds.includes(registry.id),
                    )
                    .map((registry) => (
                      <DropdownMenuItem
                        key={registry.id}
                        onSelect={() => setSelectedPromptRegistry(registry)}
                        className="cursor-pointer px-3 py-2 hover:bg-blue-50"
                      >
                        {registry.prompt_name}
                      </DropdownMenuItem>
                    ))}
                  {promptRegistryObjectsList.filter(
                    (registry) =>
                      !selectedPromptRegistryIds.includes(registry.id),
                  ).length === 0 && (
                    <DropdownMenuItem className="cursor-not-allowed px-3 py-2 italic text-gray-400">
                      All prompt templates have been added.
                    </DropdownMenuItem>
                  )}
                </DropdownMenuContent>
              </DropdownMenu>
              <Button
                onClick={handleAddRegistry}
                disabled={!selectedPromptRegistry}
              >
                <PlusIcon className="h-5 w-5" />
              </Button>
            </div>
          </div>
        </div>
        {promptRegistryObjects.hasNextPage && (
          <div ref={ref} className="mt-4 flex justify-center py-2">
            <LoadingSpinner size={5} />
          </div>
        )}
        {errorMessage && (
          <div className="mt-6 max-h-[500px] overflow-y-auto rounded-md border border-red-300 bg-red-50 p-4">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <ExclamationCircleIcon
                  className="h-6 w-6 text-red-500"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-4">
                <h3 className="text-lg font-medium text-red-800">Error</h3>
                <div className="mt-2 text-base text-red-700">
                  <p>{errorMessage}</p>
                </div>
              </div>
            </div>
          </div>
        )}
        <DialogFooter>
          <Button variant="secondary" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            isLoading={isLoading}
            onClick={handleSubmit}
            disabled={selectedPromptRegistryIds.length === 0}
          >
            Build Experiment
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default SelectPromptRegistryObjectsModal;
