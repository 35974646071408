import { ENDPOINTS } from "@/api/application-api";
import { LOCAL_STORAGE, STRIPE_PLAN_DETAILS } from "@/constants";
import {
  useDatasetGroups,
  useFolders,
  useIntercomVerification,
  usePromptLayerApiKeys,
  useProviderBaseURLs,
  useRequests,
  useSpanFeed,
  useUsage,
  useUserSubscriptionStatus,
  useWebhook,
  useWorkspaceMembers,
  useWorkspaces,
} from "@/queries";
import { DatasetGroupRead } from "@/types/dataset-groups";
import { PromptLayerApiKey } from "@/types/promptLayerApiKeys";
import { ProviderBaseURL } from "@/types/provider-base-urls";
import { Request } from "@/types/requests";
import { Span } from "@/types/spans";
import { Usage } from "@/types/usage";
import { Workspace } from "@/types/workspaces";
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "./auth-context";

type UserContextType = {
  fetchNextRequestsPage: () => void;
  folders: any[];
  getRequest: (id: number | string) => Request;
  hasNextRequestsPage?: boolean;
  isFetchingRequests: boolean;
  hasNextTracesPage?: boolean;
  isFetchingTraces: boolean;
  fetchNextTracesPage: () => void;
  manuallyRefetchRequests: () => void;
  manuallyRefetchTraces: () => void;
  newDatasetGroups: DatasetGroupRead[];
  promptLayerWorkspaceApiKeys: PromptLayerApiKey[];
  providerBaseURLs: ProviderBaseURL[];
  requests: any[];
  requestsLoading: boolean;
  setMetadataFields: (metadataFields: any) => void;
  setPromptTemplate: (promptTemplate: { name: string } | null) => void;
  setScores: (scores: any) => void;
  setStartTimeParam: (startTime: Date | null) => void;
  setEndTimeParam: (endTime: Date | null) => void;
  setDefaultDateRangeLimitIsOn: (defaultDateRangeLimitIsOn: boolean) => void;
  setQ: (q: string) => void;
  setSelectedTags: (selectedTags: any) => void;
  setShowFavorites: (showFavorites: boolean) => void;
  starredRequests: any[];
  stripePlanDetails: any;
  stripePlanName: string;
  subscriptionStatus: any;
  totalRequestsFromFilters: number;
  usage: Usage;
  user: any;
  userIsFreePlan: boolean;
  workspaceMembers: any[];
  workspaceWebhook: any;
  workspaces: Workspace[];
  workspacePlanName: string | undefined;
  activeWorkspace: Workspace | undefined;
  activeWorkspaceId: number | null;
  setWorkspaceId: (workspaceId: number) => void;
  spans: Span[];
};

const getInitialActiveWorkspaceId = () => {
  const localStorageActiveWorkspaceId = localStorage.getItem(
    LOCAL_STORAGE.ACTIVE_WORKSPACE_ID,
  );
  return localStorageActiveWorkspaceId
    ? parseInt(localStorageActiveWorkspaceId)
    : null;
};

const UserContext = createContext<UserContextType | null>(null);

function UserProvider(props: PropsWithChildren<{}>) {
  const auth = useAuth();
  const userToken = auth!.userToken;
  const navigate = useNavigate();

  useEffect(() => {
    if (!userToken) return;

    fetch(ENDPOINTS.get_user, {
      headers: { Authorization: `Bearer ${userToken}` },
    })
      .then((res) => (res.ok ? res.json() : Promise.reject()))
      .catch(() => auth?.logout());
  }, [auth, userToken]);

  const intercomVerification = useIntercomVerification(userToken!);

  const [selectedTags, setSelectedTags] = useState([]);
  const [activeWorkspaceId, setActiveWorkspaceId] = useState<number | null>(
    getInitialActiveWorkspaceId(),
  );
  const [showFavorites, setShowFavorites] = useState(false);
  const [q, setQ] = useState<string>("");
  const [metadataFields, setMetadataFields] = useState<
    Array<{ key: string; value: string }>
  >([]);
  const [scores, setScores] = useState<
    Array<{ name: string; operator: string; value: number }>
  >([]);
  const [promptTemplate, setPromptTemplate] = useState<{
    name: string;
  } | null>(null);
  const [startTimeParam, setStartTimeParam] = useState<Date | null>(null);
  const [endTimeParam, setEndTimeParam] = useState<Date | null>(null);
  const [defaultDateRangeLimitIsOn, setDefaultDateRangeLimitIsOn] =
    useState(true);

  const datasetGroupsQuery = useDatasetGroups(userToken!, activeWorkspaceId!);
  const foldersQuery = useFolders(userToken!, activeWorkspaceId!);
  const promptLayerApiKeysQuery = usePromptLayerApiKeys(
    userToken!,
    activeWorkspaceId!,
  );
  const providerBaseURLsQuery = useProviderBaseURLs(
    userToken!,
    activeWorkspaceId!,
  );
  const usageQuery = useUsage(userToken!, activeWorkspaceId!);
  const workspacesQuery = useWorkspaces(userToken!);
  const workspaceMembersQuery = useWorkspaceMembers(
    userToken!,
    activeWorkspaceId!,
  );
  const workspaceWebhookQuery = useWebhook(userToken!, activeWorkspaceId!);

  const activeWorkspace = useMemo(
    () =>
      workspacesQuery.data?.workspaces?.find(
        (workspace) => workspace?.id === activeWorkspaceId!,
      ),
    [workspacesQuery.data, activeWorkspaceId],
  );
  const params = useParams();

  useEffect(() => {
    if (
      params &&
      !isNaN(parseInt(params?.workspaceId!)) &&
      activeWorkspaceId !== parseInt(params?.workspaceId!)
    ) {
      setWorkspaceId(parseInt(params.workspaceId!));
    }
  }, [activeWorkspaceId, params, navigate]);

  const subscriptionStatus = useUserSubscriptionStatus(userToken!);

  const userIsFreePlan =
    !subscriptionStatus?.isLoading &&
    (subscriptionStatus?.data?.plan_name === "free" ||
      !subscriptionStatus?.data?.subscribed);

  // Using the plan_name
  const stripePlanName = subscriptionStatus?.data?.plan_name;
  const stripePlanDetails = STRIPE_PLAN_DETAILS[stripePlanName];

  // Workspace plan details for meticolous recorder
  const workspacePlanName = activeWorkspace?.admin_billing_plan_name;

  // if defaultDateRangeLimitIsOn is true, set the start time to 14 days ago and end time to null
  let computedStartTime: Date | null = defaultDateRangeLimitIsOn
    ? new Date(
        new Date(Date.now() - 14 * 24 * 60 * 60 * 1000)
          .toISOString()
          .split("T")[0],
      )
    : null;
  let computedEndTime: Date | null = null;
  // if startTime or endTime exists, use both those values
  if (startTimeParam || endTimeParam) {
    computedStartTime = startTimeParam;
    computedEndTime = endTimeParam;
  }

  const requestsQuery = useRequests(
    userToken!,
    {
      q,
      scores,
      selectedTags,
      starred: showFavorites,
      metadataFields,
      startTime: computedStartTime?.toISOString(),
      endTime: computedEndTime?.toISOString(),
      promptTemplate,
    },
    activeWorkspaceId!,
  );

  const spansQuery = useSpanFeed({
    userToken: userToken!,
    searchParams: {
      trace_id: q,
      ...(metadataFields && metadataFields.length > 0
        ? metadataFields.map(({ key, value }) => ({
            attribute_key: key,
            attribute_value: value,
          }))
        : [{}])[0],
    },
    workspace_id: activeWorkspaceId!,
    parent_id: "none",
  });

  const spans = useMemo(
    () => spansQuery.data?.pages?.map((page) => page.spans).flat() ?? [],
    [spansQuery.data],
  );

  const requestsLoading = requestsQuery.isLoading;

  const requests = useMemo(
    () =>
      requestsQuery.data?.pages
        ?.map(
          (page) =>
            page.items?.map((item: any) => {
              if (item?.metadata?.[0] === null) {
                item.metadata = null;
              }
              return item;
            }),
        )
        .flat() ?? [],
    [requestsQuery.data],
  );

  const totalRequestsFromFilters = useMemo(
    () => requestsQuery.data?.pages?.[0]?.total ?? 0,
    [requestsQuery.data],
  );

  const safeRequests = useMemo(
    () =>
      requests
        .filter((request) => request !== null && request !== undefined)
        .map((request) => ({
          ...request,
          tags: request.tags ? [...request.tags] : [],
        })),
    [requests],
  );

  const starredRequests = useMemo(
    () =>
      safeRequests
        .filter((request) => request.is_starred)
        .map((request) => request.id),
    [safeRequests],
  );

  if (window.location.hostname === "promptlayer.com" && auth?.user) {
    // @ts-ignore
    window.Intercom("update", {
      user_id: auth.user?.id,
      email: auth.user?.email,
      user_hash: intercomVerification.data?.intercom_verification,
    });
  }

  const reqIdToRequest = safeRequests.reduce((acc, request) => {
    acc[request.id] = request;
    return acc;
  }, {});

  const setWorkspaceId = (workspaceId: number) => {
    setActiveWorkspaceId(workspaceId);
    localStorage.setItem(
      LOCAL_STORAGE.ACTIVE_WORKSPACE_ID,
      workspaceId.toString(),
    );
  };

  return (
    <UserContext.Provider
      value={{
        manuallyRefetchTraces: spansQuery.refetch,
        fetchNextTracesPage: spansQuery.fetchNextPage,
        hasNextTracesPage: spansQuery.hasNextPage,
        isFetchingTraces: spansQuery.isFetching,
        fetchNextRequestsPage: requestsQuery.fetchNextPage,
        folders: foldersQuery.data?.folders ?? [],
        getRequest: (id: number | string) => reqIdToRequest[id],
        hasNextRequestsPage: requestsQuery.hasNextPage,
        isFetchingRequests: requestsQuery.isFetching,
        manuallyRefetchRequests: requestsQuery.refetch,
        newDatasetGroups: datasetGroupsQuery.data?.dataset_groups ?? [],
        promptLayerWorkspaceApiKeys:
          promptLayerApiKeysQuery.data?.api_keys ?? [],
        providerBaseURLs: providerBaseURLsQuery.data?.provider_base_urls ?? [],
        requests: safeRequests,
        requestsLoading: requestsLoading,
        spans: spans,
        setMetadataFields,
        setPromptTemplate,
        setScores,
        setStartTimeParam,
        setEndTimeParam,
        setDefaultDateRangeLimitIsOn,
        setQ,
        setSelectedTags,
        setShowFavorites,
        starredRequests: starredRequests,
        stripePlanDetails,
        stripePlanName,
        subscriptionStatus,
        totalRequestsFromFilters,
        usage: usageQuery.data,
        user: auth?.user,
        userIsFreePlan,
        workspaceMembers: workspaceMembersQuery.data?.workspace_members ?? [],
        workspaceWebhook: workspaceWebhookQuery.data?.webhook ?? null,
        workspaces: workspacesQuery.data?.workspaces ?? [],
        workspacePlanName,
        activeWorkspace: activeWorkspace,
        setWorkspaceId,
        activeWorkspaceId: activeWorkspaceId,
      }}
      {...props}
    />
  );
}

const useUser = () => {
  const user = useContext(UserContext);
  if (!user) throw new Error("useUser must be used within a UserProvider");
  return user;
};

export { UserProvider, useUser };
