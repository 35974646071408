import { useReportContext } from "@/components/Evaluate/report-context";
import { useAuth } from "@/context/auth-context";

import { useRetryReportCell as useRetryReportCellQuery } from "@/queries";

export const useRetryReportCell = (cellId: number) => {
  const authContext = useAuth();
  const reportContext = useReportContext();
  const { mutate, isLoading } = useRetryReportCellQuery(
    authContext!.userToken!,
    reportContext?.report?.id,
    cellId,
  );

  return { retryReportCell: mutate, isLoading };
};
