import { FC } from "react";
import { Input } from "../input";

interface LimitFieldProps {
  setLimit: (limit: number) => void;
}

const LimitField: FC<LimitFieldProps> = ({ setLimit }) => {
  return (
    <>
      <label
        className="text-left text-sm font-semibold text-gray-500"
        htmlFor="limit"
      >
        Limit:
      </label>
      <Input
        className="col-span-2 rounded-md border border-gray-300 px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
        id="limit"
        onChange={(e) => setLimit(Number(e.target.value))}
        type="number"
      />
    </>
  );
};

export default LimitField;
