import { useAuth } from "@/context/auth-context";
import { useGroupRequests } from "@/queries";
import { Request } from "@/types/requests";
import { useRef } from "react";
import { responseIsFunctionOrTool } from "../utils/logUtils";
import { GroupInfoBlock } from "./GroupInfoBlock";
import LoadingSpinner from "./LoadingSpinner";
import { SidebarRequestLogCard } from "./SidebarRequestLogCard";

export const GroupContent = ({ groupId }: { groupId: string }) => {
  const authContext = useAuth();
  const requests = useGroupRequests(groupId, authContext?.userToken || "");

  const data = requests?.data ?? [];

  const noRequests = data.cardinality === 0;
  const noRequestsFound = noRequests && !requests.isLoading;
  const ref = useRef<HTMLDivElement | null>(null);

  return (
    <div className="items-center justify-center space-y-2 px-20 pb-10 pt-10">
      <GroupInfoBlock groupId={groupId} />
      {!requests.isLoading &&
        data.requests.map((item: Request, idx: number) => (
          <div className="mx-auto max-w-xs" key={idx}>
            <SidebarRequestLogCard
              key={idx}
              active={false}
              logId={item.id}
              descriptor={item.engine || item.function_name}
              startTime={item.request_start_time}
              tags={item.tags}
              starred={item.is_starred}
              prompt={item.prompt_string}
              responseIsFunction={responseIsFunctionOrTool(item)}
              response={item.response_string}
              type={item.type}
              cardinality={item.cardinality}
            />
          </div>
        ))}
      {(groupId === "" || requests.isLoading) && (
        <div ref={ref} className="flex justify-center py-2">
          <LoadingSpinner size={5} />
        </div>
      )}
      {noRequestsFound && (
        <div className="flex justify-center py-2">
          <p className="text-gray-500">This group is empty.</p>
        </div>
      )}
    </div>
  );
};
