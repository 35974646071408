import { Dispatch, FC, SetStateAction } from "react";
import { useChannel, useConnectionStateListener } from "ably/react";

import { WEBSOCKET_MESSAGE_NAME } from "@/constants";
import { useUser } from "@/context/user-context";
import { WebSocketMessage } from "@/types/websockets";

interface ThreadsWebSocketListenerProps {
  latestCommentDate: string | null;
  promptRegistryId: number;
  setLatestCommentDate: Dispatch<SetStateAction<string | null>>;
}

const ThreadsWebSocketListener: FC<ThreadsWebSocketListenerProps> = ({
  latestCommentDate,
  promptRegistryId,
  setLatestCommentDate,
}) => {
  const { user } = useUser();

  useConnectionStateListener("connected", () => {
    console.log("Connected to Ably!");
  });

  useChannel(
    `user:${user.id}`,
    WEBSOCKET_MESSAGE_NAME.NEW_THREAD_COMMENT,
    (message: WebSocketMessage) => {
      const { data: websocketData } = message;
      if (!websocketData) return;

      const { comment_created_date, prompt_registry_id } =
        JSON.parse(websocketData);

      if (promptRegistryId !== prompt_registry_id) return;

      if (!latestCommentDate || latestCommentDate < comment_created_date) {
        setLatestCommentDate(comment_created_date);
      }
    },
  );

  return null;
};

export default ThreadsWebSocketListener;
