import { useMemo } from "react";
import { DatasetRead } from "@/types/datasets";

const useDatasets = (
  datasets: DatasetRead[],
  datasetId: string | undefined,
) => {
  const sortedDatasets = useMemo(() => {
    return [...datasets].sort((a, b) => {
      // Drafts always on top!
      if (a.version_number === -1) return -1;
      if (b.version_number === -1) return 1;
      return b.version_number - a.version_number;
    });
  }, [datasets]);

  const selectedDataset = useMemo(
    () => datasets.find(({ id }) => String(id) === datasetId),
    [datasets, datasetId],
  );

  const currentVersion: number | undefined = useMemo(
    () => selectedDataset?.version_number,
    [selectedDataset?.version_number],
  );

  return {
    currentVersion,
    sortedDatasets,
  };
};

export default useDatasets;
