import { TrashIcon } from "lucide-react";
import { useContext } from "react";

import { Button } from "@/components/ui/button";
import { useAuth } from "@/context/auth-context";
import { ToastType } from "@/enums";
import { useDeleteDatasetRows } from "@/queries";
import { displayErrorToast, displayToast } from "@/utils/toast";
import { useParams } from "react-router-dom";
import { DatasetEditorContext } from "..";

const DeleteAllRowsButton = () => {
  const { datasetId } = useParams();
  const authContext = useAuth();
  const userToken = authContext!.userToken;
  const {
    isDraft,
    isSavingDataset,
    isDeletingDataset,
    isDeletingDatasetGroup,
    rows,
  } = useContext(DatasetEditorContext);
  const deleteAllRows = useDeleteDatasetRows(userToken!);

  if (!isDraft || isSavingDataset) return null;

  const handleDeleteClick = async () => {
    if (datasetId) {
      try {
        await deleteAllRows.mutate(datasetId);
        displayToast("All rows deleted successfully", ToastType.success);
      } catch (error) {
        displayErrorToast("Failed to delete all rows");
      }
    }
  };

  const isRowsDataAvailable =
    rows?.filter((row: any) => row?.dataset_row_id > -1).length > 0 || false;

  return (
    <Button
      className="inline-flex items-center"
      isLoading={deleteAllRows.isLoading}
      disabled={
        isDeletingDataset || isDeletingDatasetGroup || !isRowsDataAvailable
      }
      onClick={handleDeleteClick}
      size="sm"
      variant="destructiveOutline"
    >
      <TrashIcon aria-hidden="true" className="h-4 w-auto pr-1" />
      Delete All Rows
    </Button>
  );
};

export default DeleteAllRowsButton;
