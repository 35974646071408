import { DivButton } from "@/components/ui/button";
import { ReportCell } from "@/types/evaluate";
import { RefreshIcon } from "@heroicons/react/outline";
import { StatusCell } from "./StatusCell";

export const FailedCell = ({
  cell,
  retryReportCell,
  retryReportCellIsLoading,
}: {
  cell: ReportCell;
  retryReportCell: () => void;
  retryReportCellIsLoading: boolean;
}) => (
  <div className="flex flex-col justify-center text-center">
    <StatusCell status="failed" />
    <div className="mx-auto w-full  break-all px-3 py-1 text-xs text-red-500">
      Error: {cell.error_message}
    </div>
    <div className="">
      <DivButton
        size="sm"
        variant="link"
        className="cursor-pointer"
        onClick={retryReportCell}
        isLoading={retryReportCellIsLoading}
      >
        <RefreshIcon className="mr-1 h-4 w-4" />
        Retry
      </DivButton>
    </div>
  </div>
);
