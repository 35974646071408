import { FC, ReactNode } from "react";
import { TrendingUpIcon } from "@heroicons/react/solid";
import { Button } from "@/components/ui/button";

type CardProps = {
  buttonText: string;
  description: string;
  heading: string;
  iconComponent: ReactNode;
  isSelected: boolean;
  onClick: () => void;
  subheading: string;
};

const Card: FC<CardProps> = ({
  buttonText,
  description,
  heading,
  iconComponent,
  isSelected,
  onClick,
  subheading,
}) => {
  return (
    <div
      className={`h-full cursor-pointer rounded-md border-2 p-6 shadow-md transition duration-100 ease-in-out ${
        isSelected
          ? "border-blue-500"
          : "border-gray-50 hover:border-blue-50 hover:shadow-lg"
      }`}
      onClick={onClick}
    >
      <div className="mb-4 flex items-center">
        <div
          className="mr-4 inline-flex items-center justify-center rounded-full bg-blue-100 p-2"
          style={{ width: "48px", height: "48px" }}
        >
          {iconComponent}
        </div>
        <div>
          <h2 className="text-xl font-medium">{heading}</h2>
          <div className="text-gray-500">
            <TrendingUpIcon
              aria-hidden="true"
              className="mr-2 inline-block h-4 w-4"
            />
            <span className="text-base font-light">{subheading}</span>
          </div>
        </div>
      </div>
      <p className="mb-4 font-light text-gray-500">{description}</p>
      <Button variant="secondaryLight">{buttonText}</Button>
    </div>
  );
};

export default Card;
