import logo from "@/assets/logo/logo.png";
import NavigationMenu from "@/components/MarketingLayout/NavigationMenu/NavigationMenu";
import OutlineButton from "@/components/MarketingLayout/OutlineButton/OutlineButton";
import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as S from "./Styles";

interface HeaderProps {
  menuOpen: boolean;
  setMenuOpen: (value: boolean) => void;
}

const Header: FC<HeaderProps> = ({ menuOpen, setMenuOpen }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const toggleMenu = () => setMenuOpen(!menuOpen);

  const handleScroll = () => {
    const rootDiv = document.getElementById("root");
    if (rootDiv) {
      setIsScrolled(rootDiv.scrollTop > 0);
    }
  };

  useEffect(() => {
    const rootDiv = document.getElementById("root");
    if (rootDiv) {
      rootDiv.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (rootDiv) {
        rootDiv.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <S.Container $isScrolled={isScrolled}>
      <S.Content>
        <Link to="/">
          <S.Logo alt="PromptLayer logo" src={logo} />
        </Link>
        <S.NavItems>
          <NavigationMenu />
        </S.NavItems>
        <S.RightItems>
          <a
            target="_blank"
            rel="noreferrer"
            href="mailto:hello@promptlayer.com"
          >
            Contact Us
          </a>
          <Link to="/login">
            <OutlineButton>Log In</OutlineButton>
          </Link>
        </S.RightItems>
        <S.MenuButton onClick={toggleMenu}>
          <S.MenuIcon />
        </S.MenuButton>
      </S.Content>
    </S.Container>
  );
};

export default Header;
