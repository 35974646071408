import { FC, useRef, useState } from "react";
import {
  ExclamationCircleIcon,
  PlusIcon,
  XIcon,
} from "@heroicons/react/outline";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from "@/components/ui/dropdown-menu";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { useIntersectionObserver } from "@/hooks";
import { usePromptRegistryObjects, usePromptVersions } from "@/queries";
import { Conditional, DynamicReleaseLabel } from "@/types/conditionals";
import LoadingSpinner from "./LoadingSpinner";

type EditPromptRegistryObjectsModalProps = {
  initialPromptRegistryIds: number[];
  isOpen: boolean;
  promptLabelName: string;
  setConditionals: (conditionals: any) => void;
  setOpen: (isOpen: boolean) => void;
};

const EditPromptRegistryObjectsModal: FC<
  EditPromptRegistryObjectsModalProps
> = ({
  initialPromptRegistryIds,
  isOpen,
  promptLabelName,
  setConditionals,
  setOpen,
}) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [selectedPromptRegistryIds, setSelectedPromptRegistryIds] = useState<
    number[]
  >(initialPromptRegistryIds);
  const [selectedPromptRegistry, setSelectedPromptRegistry] =
    useState<any>(null);
  const authContext = useAuth();
  const userToken = authContext!.userToken!;
  const userContext = useUser();
  const workspaceId = userContext.activeWorkspaceId!;
  const ref = useRef<HTMLDivElement>(null);
  const entry = useIntersectionObserver(ref, {});
  const promptRegistryObjects = usePromptRegistryObjects(userToken, {
    workspaceId,
    promptLabelName,
  });
  const [hasChanges, setHasChanges] = useState(false);

  const promptVersionsQuery = usePromptVersions(userToken, {
    workspaceId,
    label: promptLabelName,
    perPage: Number.MAX_SAFE_INTEGER,
  });

  const promptVersions =
    promptVersionsQuery.data?.pages.flatMap((page) => page.items) || [];
  const promptRegistryObjectsList =
    promptRegistryObjects.data?.pages.flatMap((page) => page.items) || [];

  if (entry?.isIntersecting) {
    promptRegistryObjects.fetchNextPage();
  }

  const getPromptVersionWithLabel = (promptRegistryId: number) => {
    const promptRegistry = promptRegistryObjectsList.find(
      (template) => template.id === promptRegistryId,
    );

    if (promptRegistry) {
      const versions = promptVersions.filter(
        (version) => version.prompt_id === promptRegistry.id,
      );
      for (const version of versions) {
        if (version.labels.some((label) => label.name === promptLabelName)) {
          return version;
        }
      }
    }

    throw new Error("Error finding prompt version.");
  };

  const handleAddRegistry = () => {
    if (selectedPromptRegistry) {
      setSelectedPromptRegistryIds((prev) => [
        ...prev,
        selectedPromptRegistry.id,
      ]);
      setSelectedPromptRegistry(null);
      setHasChanges(true);
    }
  };

  const handleRemoveRegistry = (id: number) => {
    setSelectedPromptRegistryIds((prev) => prev.filter((item) => item !== id));
    setHasChanges(true);
  };

  const handleSubmit = () => {
    try {
      const initialPromptRegistryIdsSet = new Set(initialPromptRegistryIds);
      const selectedPromptRegistryIdsSet = new Set(selectedPromptRegistryIds);

      const newPromptRegistryIds = Array.from(
        selectedPromptRegistryIdsSet,
      ).filter((id) => !initialPromptRegistryIdsSet.has(id));

      const deletedPromptRegistryIds = Array.from(
        initialPromptRegistryIdsSet,
      ).filter((id) => !selectedPromptRegistryIdsSet.has(id));

      setConditionals((prevConditionals: Conditional[]) =>
        prevConditionals.map((conditional) => ({
          ...conditional,
          dynamic_release_labels: conditional.dynamic_release_labels.filter(
            (label) =>
              !deletedPromptRegistryIds.includes(label.prompt_registry_id),
          ),
        })),
      );

      setConditionals((prevConditionals: Conditional[]) =>
        prevConditionals.map((conditional) => {
          if (conditional.is_default) {
            const newDynamicReleaseLabels: DynamicReleaseLabel[] =
              newPromptRegistryIds.map((promptRegistryId) => {
                const promptVersion =
                  getPromptVersionWithLabel(promptRegistryId);

                return {
                  is_real_label: true,
                  percentage: 100,
                  prompt_registry_id: promptRegistryId,
                  prompt_version_id: promptVersion.id,
                  prompt_version_number: promptVersion.number,
                };
              });

            return {
              ...conditional,
              dynamic_release_labels: [
                ...conditional.dynamic_release_labels,
                ...newDynamicReleaseLabels,
              ],
            };
          }

          return conditional;
        }),
      );

      setOpen(false);
    } catch (error: unknown) {
      setErrorMessage("Error updating prompt templates");
    }
  };

  const isLoadingPromptRegistryObjects = promptRegistryObjects.hasNextPage;

  return (
    <Dialog onOpenChange={setOpen} open={isOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit Prompt Templates</DialogTitle>
          <DialogDescription>
            Select the prompt templates to include in this experiment.
          </DialogDescription>
        </DialogHeader>
        <div className="space-y-4">
          {isLoadingPromptRegistryObjects && (
            <div ref={ref} className="mt-4 flex justify-center py-2">
              <LoadingSpinner size={5} />
            </div>
          )}
          {!isLoadingPromptRegistryObjects &&
            selectedPromptRegistryIds.length > 0 && (
              <div className="flex max-h-[400px] flex-wrap gap-2 overflow-y-auto">
                {promptRegistryObjectsList
                  .filter((registry) =>
                    selectedPromptRegistryIds.includes(registry.id),
                  )
                  .map((registry) => (
                    <div
                      key={registry.id}
                      className="group flex items-center rounded-full border border-gray-200 bg-gray-100 px-3 py-1 text-xs transition-colors duration-200 hover:bg-gray-200"
                    >
                      <span className="mr-0.5 break-all">
                        {registry.prompt_name}
                      </span>
                      <button
                        onClick={() => handleRemoveRegistry(registry.id)}
                        className="p-0.75 ml-2 flex-shrink-0 rounded-full transition-colors duration-200 hover:bg-gray-300"
                      >
                        <XIcon className="h-3.5 w-3.5 text-gray-400 transition-colors duration-200 group-hover:text-red-500" />
                      </button>
                    </div>
                  ))}
              </div>
            )}
          <div className="space-y-2 pb-2">
            <label
              htmlFor="prompt-template-select"
              className="block text-sm font-medium text-gray-700"
            >
              Available Prompt Templates
            </label>
            <div className="flex items-center space-x-2">
              <DropdownMenu>
                <DropdownMenuTrigger className="w-full rounded-md border border-gray-300 bg-white px-3 py-2 text-left shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500">
                  {selectedPromptRegistry?.prompt_name || (
                    <span className="text-gray-500">
                      Select a prompt template
                    </span>
                  )}
                </DropdownMenuTrigger>
                <DropdownMenuContent className="mt-1 w-full">
                  {promptRegistryObjectsList
                    .filter(
                      (registry) =>
                        !selectedPromptRegistryIds.includes(registry.id),
                    )
                    .map((registry) => (
                      <DropdownMenuItem
                        key={registry.id}
                        onSelect={() => setSelectedPromptRegistry(registry)}
                        className="cursor-pointer px-3 py-2 hover:bg-blue-50"
                      >
                        {registry.prompt_name}
                      </DropdownMenuItem>
                    ))}
                  {promptRegistryObjectsList.filter(
                    (registry) =>
                      !selectedPromptRegistryIds.includes(registry.id),
                  ).length === 0 && (
                    <DropdownMenuItem className="cursor-not-allowed px-3 py-2 italic text-gray-400">
                      All prompt templates have been added.
                    </DropdownMenuItem>
                  )}
                </DropdownMenuContent>
              </DropdownMenu>
              <Button
                onClick={handleAddRegistry}
                disabled={!selectedPromptRegistry}
              >
                <PlusIcon className="h-5 w-5" />
              </Button>
            </div>
          </div>
        </div>
        {isLoadingPromptRegistryObjects && (
          <div ref={ref} className="mt-4 flex justify-center py-2">
            <LoadingSpinner size={5} />
          </div>
        )}
        {errorMessage && (
          <div className="mt-6 max-h-[500px] overflow-y-auto rounded-md border border-red-300 bg-red-50 p-4">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <ExclamationCircleIcon
                  className="h-6 w-6 text-red-500"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-4">
                <h3 className="text-lg font-medium text-red-800">Error</h3>
                <div className="mt-2 text-base text-red-700">
                  <p>{errorMessage}</p>
                </div>
              </div>
            </div>
          </div>
        )}
        <DialogFooter>
          <Button variant="secondary" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={selectedPromptRegistryIds.length === 0 || !hasChanges}
          >
            Update Experiment
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default EditPromptRegistryObjectsModal;
