import { FC } from "react";
import { Link } from "react-router-dom";
import shape1 from "@/assets/shape-1.png";
import shape2 from "@/assets/shape-2.png";
import * as S from "./Styles";

const Community: FC = () => {
  return (
    <S.Container>
      <S.Shape1 alt="shape1" src={shape1} />
      <S.Shape2 alt="shape2" src={shape2} />
      <S.Content>
        <h2 className="pt-8">Prompt engineering pioneers</h2>
        <p>
          We are building a community for the real builders of AI: the prompt
          engineers. They come in all shapes and all sizes. Lawyers, doctors,
          educators, and even software engineers.
        </p>
        <Link to="/create-account">
          <S.Button>Get started 🍰</S.Button>
        </Link>
      </S.Content>
    </S.Container>
  );
};

export default Community;
