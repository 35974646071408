import { ResponseRequest } from "@/types/metadata";
import { LinkIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import { Button } from "../ui/button";
import { useUser } from "@/context/user-context";

type RequestLinkButtonProps = {
  request: ResponseRequest;
};

export function RequestLinkButton({ request }: RequestLinkButtonProps) {
  const price = request.price ? request.price.toFixed(5) : null;
  const userContext = useUser();

  return (
    <div className="flex justify-center">
      <Link
        to={`/workspace/${userContext?.activeWorkspaceId}/request/${request.id}`}
        target="_blank"
      >
        <Button variant="outline" size="lg">
          <LinkIcon className="mr-1 inline h-4 w-auto text-blue-500" />
          Go to New Request
          {price && (
            <div className="pl-1 text-sm text-gray-500">(${price})</div>
          )}
        </Button>
      </Link>
    </div>
  );
}
