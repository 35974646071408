import { unstable_useBlocker as useBlocker } from "react-router-dom";

interface ExitPageConfirmationProps {
  when: boolean;
  message: string;
  execute: () => void;
}

const ExitPageConfirmation = (props: ExitPageConfirmationProps) => {
  useBlocker((transition) => {
    if (
      props.when &&
      !(
        // This cases should not trigger the exit page confirmation
        // Chat mode switch - template edit
        (
          (transition.currentLocation.pathname.includes("create-prompt") &&
            transition.nextLocation.pathname.includes("create-prompt")) ||
          // Chat mode switch - playground
          (transition.currentLocation.pathname.includes("playground") &&
            transition.nextLocation.pathname.includes("playground")) ||
          // Submit creation - template creation
          (transition.currentLocation.pathname.includes("create-prompt") &&
            transition.nextLocation.pathname.includes("prompt/") &&
            !isNaN(
              Number(
                transition.nextLocation.pathname
                  .split("prompt/")[1]
                  .split("/")[0],
              ),
            ) &&
            Number(
              transition.nextLocation.pathname
                .split("prompt/")[1]
                .split("/")[0],
            ) !== 0) ||
          // Cancel edition - template edit
          (transition.currentLocation.pathname.includes("prompt") &&
            transition.nextLocation.pathname.includes("prompt/") &&
            !isNaN(
              Number(
                transition.nextLocation.pathname
                  .split("prompt/")[1]
                  .split("/")[0],
              ),
            ) &&
            Number(
              transition.nextLocation.pathname
                .split("prompt/")[1]
                .split("/")[0],
            ) !== 0)
        )
      )
    ) {
      if (window.confirm(props.message)) {
        props.execute();
        return false;
      } else {
        return true;
      }
    }
    props.execute();
    return false;
  });
  return <div key={props.message} />;
};
export default ExitPageConfirmation;
