export const buttonStyle = (role: string, open: boolean) => {
  const standardized = role.toLowerCase();
  let styles = ``;
  if (standardized === "assistant") {
    styles += `bg-gray-100 text-gray-800`;
  } else {
    styles += `bg-white text-gray-600`;
  }
  return styles;
};
