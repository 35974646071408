import React, { FC, ReactNode, useEffect, useState } from "react";
import { AblyProvider, ChannelProvider } from "ably/react";
import * as Ably from "ably";

import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { useWSTokenRequest } from "@/queries";
import { displayErrorToast } from "@/utils/toast";

interface UserChannelProviderProps {
  children: ReactNode;
}

const UserChannelProvider: FC<UserChannelProviderProps> = ({ children }) => {
  const [client, setClient] = useState<Ably.Realtime | null>(null);
  const authContext = useAuth();
  const { user } = useUser();
  const userToken = authContext!.userToken;
  const { data, isError } = useWSTokenRequest(userToken!);

  useEffect(() => {
    if (!isError) return;
    displayErrorToast("Error fetching WebSocket token");
  }, [isError]);

  useEffect(() => {
    if (!client && data?.token_details) {
      try {
        const _client: Ably.Realtime = new Ably.Realtime({
          authCallback: () => {},
          clientId: user.id.toString(),
          token: data.token_details.token,
        });
        setClient(_client);
      } catch {
        displayErrorToast("Error connecting to WebSocket");
      }
    }
  }, [client, data, user.id]);

  if (!client) return null;

  return (
    <AblyProvider client={client}>
      <ChannelProvider channelName={`user:${user.id}`}>
        {children}
      </ChannelProvider>
    </AblyProvider>
  );
};

export default UserChannelProvider;
