import { useMemo } from "react";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";

function useIsActiveWorkspaceAdmin() {
  const authContext = useAuth();
  const userContext = useUser();

  return useMemo(() => {
    const workspaceMember = userContext.workspaceMembers.find(
      (workspaceMember: any) =>
        workspaceMember.workspace_id === userContext?.activeWorkspaceId &&
        workspaceMember.user_id === authContext!.user!.id,
    );

    return workspaceMember?.is_admin || false;
  }, [authContext, userContext]);
}

export default useIsActiveWorkspaceAdmin;
