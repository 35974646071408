import React from "react";
import FilledButton from "../PlatformGenericLayout/FilledButton/FilledButton";
import * as S from "./Styles";
const icon = require("../../../../assets/hero/icon3.png");

interface Feature {
  title: React.ReactNode;
  description: React.ReactNode;
}

interface GenericPromoSectionProps {
  title: React.ReactNode;
  titleHighlight?: React.ReactNode;
  description: React.ReactNode;
  imageSrc: string;
  features: Feature[];
  buttonText: React.ReactNode;
  onButtonClick?: () => void;
  className?: string;
}

const PromoSection: React.FC<GenericPromoSectionProps> = ({
  title,
  titleHighlight,
  description,
  imageSrc,
  features,
  buttonText,
  onButtonClick,
  className = "",
}) => {
  return (
    <section className={`bg-gray-50 pb-20 pt-12 ${className}`}>
      <S.InnerContainer>
        <div className="flex flex-col items-center justify-between md:flex-row">
          <div className="mb-6 w-full md:mb-0 md:w-1/2">
            <h2 className="mb-4 max-w-xl text-3xl font-medium md:text-5xl">
              {title}{" "}
              {titleHighlight && (
                <span className="text-[#5167f0]">{titleHighlight}</span>
              )}
            </h2>
            <p className="mb-6 max-w-xl font-light">{description}</p>
          </div>
          <div className="mb-20 flex w-full items-center justify-center pb-1 md:w-1/2">
            <img
              src={icon}
              alt="Feature icon"
              className="pointer-events-none mx-auto h-auto max-h-[200px] w-full max-w-[400px] select-none rounded-lg"
            />
          </div>
        </div>
        <div className="flex w-full flex-col gap-10 md:flex-row md:gap-10">
          <div className="mb-4 h-[300px] w-full flex-shrink-0 overflow-hidden md:h-[500px] md:w-1/2">
            <img
              src={imageSrc}
              alt="Feature"
              draggable={false}
              className="h-full w-full object-cover object-left-top"
            />
          </div>
          <div className="flex h-[500px] w-full flex-col gap-4 md:w-1/2">
            <div className="grid h-full flex-grow grid-cols-1 overflow-y-auto sm:grid-cols-2">
              {features.map((feature, index) => (
                <div key={index} className="rounded-lg p-4">
                  <h3 className="mb-2 text-2xl font-medium">{feature.title}</h3>
                  <p className="font-light text-gray-500">
                    {feature.description}
                  </p>
                </div>
              ))}
            </div>
            <div>
              <a
                href="mailto:hello@promptlayer.com?subject=Demo Request"
                style={{ textDecoration: "none" }}
              >
                <FilledButton>{String(buttonText)}</FilledButton>
              </a>
            </div>
          </div>
        </div>
      </S.InnerContainer>
    </section>
  );
};

export default PromoSection;
