import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import useActiveWorkspace from "@/hooks/useActiveWorkspace";
import { LockClosedIcon } from "@heroicons/react/outline";
import { ArrowRightCircle } from "lucide-react";
import moment from "moment";
import { ReactNode, useState } from "react";

const RunReportModal = ({
  children,
  handleBuildReport,
  blueprintName,
  createFinalReportIsLoading,
}: {
  children: ReactNode;
  handleBuildReport: (reportName: string) => void;
  blueprintName: string;
  createFinalReportIsLoading: boolean;
}) => {
  const activeWorkspace = useActiveWorkspace();
  const [open, setOpen] = useState(false);
  const [reportName, setReportName] = useState("");
  const defaultName = `${blueprintName} (${moment().format("MM/DD/YY")})`;

  const userCanCreateReport = !activeWorkspace?.admin_is_free;

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>{children}</DialogTrigger>
      <DialogContent>
        <div className="p-2">
          <h2 className="text-lg font-semibold">Create Full Batch Run</h2>
          <p className="mb-4 text-sm text-gray-500">
            The pipeline will batch execute on every row of your dataset. It
            will no longer be editable.
          </p>
          {userCanCreateReport ? (
            <>
              <div className="mb-4">
                <label
                  className="mb-2 block text-sm font-bold text-gray-700"
                  htmlFor="reportName"
                >
                  Name
                </label>
                <input
                  className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                  id="reportName"
                  type="text"
                  placeholder={defaultName}
                  value={reportName}
                  onChange={(e) => setReportName(e.target.value)}
                />
              </div>
              <Button
                variant={"default"}
                disabled={!userCanCreateReport}
                onClick={() => handleBuildReport(reportName || defaultName)}
                isLoading={createFinalReportIsLoading}
              >
                Create
                <ArrowRightCircle className="ml-1 inline-block h-4 w-4" />
              </Button>
            </>
          ) : (
            <div className="border-l border-gray-400 p-4">
              <p className="pb-2 text-lg font-semibold">
                <LockClosedIcon className="mr-1 inline-block h-4 w-4" />
                Upgrade Required
              </p>
              <p className="text-md">
                To generate a full evaluation report, your workspace admin must
                upgrade your payment plan to "Startup" or "Team". Do this in
                settings on the top right corner of your screen.
              </p>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default RunReportModal;
