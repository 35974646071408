import { ExclamationIcon } from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/solid";
import { ImageIcon } from "@radix-ui/react-icons";
import { Label } from "@radix-ui/react-label";
import { Terminal } from "lucide-react";
import { useEffect, useRef, useState } from "react";

import { ButtonCopy, ButtonMaximize, ToolCalls } from "@/components";
import EditImageVariableModal from "@/components/EditImageVariableModal";
import ImageVariableModal from "@/components/ImageVariableModal";
import LoadingSpinner from "@/components/LoadingSpinner";
import { ChooseSnippetTemplateModal } from "@/components/PromptTemplate/ChooseSnippetTemplateModal";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { useAuth } from "@/context/auth-context";
import { useUpload } from "@/queries";
import { Message, TemplateFormat } from "@/types";
import { PromptVersionSnippet } from "@/types/apiGetters";
import { HighlightSnippets } from "@/utils/HighlightSnippets";
import {
  copyTextToClipboard,
  getImageVariable,
  getStringContent,
} from "@/utils/utils";
import SelectRole from "./SelectRole";

const getFunctionName = (messageTemplate: Message): string => {
  const isRoleFunction = messageTemplate.role === "function";
  const isFunctionCall =
    "function_call" in messageTemplate && !!messageTemplate.function_call;
  if (isRoleFunction) return messageTemplate.name ?? "";
  if (isFunctionCall) return messageTemplate.function_call?.name ?? "";
  return "";
};

const handleChangeTemplate = (
  text: string,
  messageTemplate: Message,
): Message => {
  if (messageTemplate.role === "assistant") {
    if (messageTemplate.function_call)
      return {
        ...messageTemplate,
        function_call: { ...messageTemplate.function_call, arguments: text },
      };
  }
  if (messageTemplate.role === "tool")
    return { ...messageTemplate, content: [{ type: "text", text }] };
  if (messageTemplate.role === "placeholder")
    return { ...messageTemplate, name: text };
  const imageUrlContent = messageTemplate.content?.find(
    (content) => content.type === "image_url",
  );
  if (messageTemplate.role === "user" && imageUrlContent)
    return {
      ...messageTemplate,
      content: [imageUrlContent, { type: "text", text }],
    };
  return {
    ...messageTemplate,
    content: [{ type: "text", text }],
  };
};

const handleChangeName = (name: string, messageTemplate: Message): Message => {
  if (messageTemplate.role === "assistant") {
    if (name) {
      return {
        ...messageTemplate,
        function_call: messageTemplate.function_call
          ? { ...messageTemplate.function_call, name }
          : {
              name,
              arguments: "",
            },
      };
    }
    return { ...messageTemplate, function_call: undefined };
  }
  if (messageTemplate.role === "tool")
    return { ...messageTemplate, tool_call_id: name };
  return { ...messageTemplate, name };
};

const MessagePromptTemplate = ({
  messageTemplate,
  renderedMessageTemplate,
  onChange,
  onRemove,
  edit,
  templateFormat,
  invalidSnippets,
  highlightInvalid,
  sourcedSnippets = [],
  isXrayMode = false,
}: {
  messageTemplate: Message;
  renderedMessageTemplate?: Message | null;
  onChange: (messageTemplate: Message) => void;
  onRemove: () => void;
  edit: boolean;
  templateFormat: TemplateFormat;
  invalidSnippets?: string[];
  highlightInvalid?: boolean | false;
  sourcedSnippets?: Array<PromptVersionSnippet>;
  isXrayMode?: boolean;
}) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLTextAreaElement>(null);
  const isRoleFunction = messageTemplate.role === "function";
  const isFunctionCall =
    "function_call" in messageTemplate && !!messageTemplate.function_call;
  const isRoleTool = messageTemplate.role === "tool";
  const isRoleAssistant = messageTemplate.role === "assistant";
  const inputName = edit && (isRoleFunction || isRoleAssistant || isRoleTool);
  let templatePlaceHolder = "Type @ to use a prompt snippet...";
  if (isRoleFunction) {
    templatePlaceHolder = '{"location": "Boston, MA", "temperature": "72"}';
  }
  if (isFunctionCall) {
    templatePlaceHolder = '{"location": "Boston, MA"}';
  }
  if (messageTemplate.role === "placeholder")
    templatePlaceHolder = "Name the placeholder variable...";
  if (!edit) {
    templatePlaceHolder = "";
  }

  const getContent = () => {
    if (messageTemplate.role === "assistant") {
      if (messageTemplate.function_call)
        return messageTemplate.function_call.arguments;
    }
    if (messageTemplate.role === "placeholder") return messageTemplate.name;
    return getStringContent(messageTemplate);
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.style.height = "auto";
      ref.current.style.height = ref.current.scrollHeight + "px";
    }
  }, [ref]);

  const handleKeyUp = (event: React.KeyboardEvent) => {
    if (event.key === "@" && messageTemplate.role !== "placeholder") {
      const newCursorPosition = (event.target as HTMLInputElement)
        .selectionStart;
      setCursorPosition(newCursorPosition || 0);
      setOpenTemplateLink(true);
    }
  };

  const [openTemplateLink, setOpenTemplateLink] = useState(false);

  const [cursorPosition, setCursorPosition] = useState(0);
  const isRoleUser = messageTemplate.role === "user";
  const auth = useAuth();
  const upload = useUpload();

  const updateImageUrl = (url: string) => {
    const newMessageTemplate: Message = {
      ...messageTemplate,
      content: [
        ...(messageTemplate.content ?? []),
        {
          type: "image_url",
          image_url: { url },
        },
      ],
    };
    onChange(newMessageTemplate);
  };

  const handleImageVariable = (imageVariable: string) => {
    const newMessageTemplate = structuredClone(messageTemplate);
    const content = newMessageTemplate.content ?? [];
    const imageContent = content.find(
      (content) => content.type === "image_url" && !!content.image_variable,
    );
    if (imageContent) {
      content.splice(content.indexOf(imageContent), 1);
    }
    if (imageVariable) {
      content.push({
        type: "image_url",
        image_url: { url: "" },
        image_variable: imageVariable,
      });
    }
    newMessageTemplate.content = content;
    onChange(newMessageTemplate);
  };

  const handleRemoveImage = (index: number) => {
    const newContent = messageTemplate.content?.filter((_, i) => i !== index);
    if (newContent !== undefined) {
      const newMessageTemplate = {
        ...messageTemplate,
        content: newContent,
      };
      onChange(newMessageTemplate);
    }
  };
  const handleImage = async (file?: File) => {
    if (!file || !auth?.userToken) return;
    upload.mutate(
      { userToken: auth.userToken, file },
      {
        onSuccess: (data) => {
          if (!data) return;
          updateImageUrl(data.file_url);
        },
      },
    );
  };

  const messageForm = (
    <div className="flex h-full flex-col gap-2 overflow-hidden p-1">
      <div className="flex items-center group-data-[state=open]:max-w-fit">
        {edit && (
          <SelectRole
            messageTemplate={messageTemplate}
            onChange={onChange}
            templateFormat={templateFormat}
          />
        )}
        {inputName && (
          <Input
            placeholder={isRoleTool ? "Tool Call ID" : "Function Name"}
            className="ml-2 w-48 rounded-none border border-gray-200 font-mono group-hover:border-gray-300" //Added margin left for space between elements
            value={
              isRoleTool
                ? messageTemplate.tool_call_id
                : getFunctionName(messageTemplate)
            }
            onChange={(e) => {
              const newMessageTemplate = handleChangeName(
                e.target.value,
                messageTemplate,
              );
              onChange(newMessageTemplate);
            }}
          />
        )}
        {edit && (
          <button
            className="ml-2 text-gray-400 hover:text-gray-500 group-data-[state=open]:hidden"
            type="button"
            onClick={onRemove}
          >
            <XIcon className="h-4 w-4" />
          </button>
        )}
      </div>
      {edit && messageTemplate.role === "placeholder" ? (
        <Input
          onInput={(e) => {
            if (!open) {
              e.currentTarget.style.height = "auto";
              e.currentTarget.style.height =
                e.currentTarget.scrollHeight + "px";
            }
          }}
          className={`h-full w-[75%] rounded-none bg-white px-3 py-3 font-mono ${
            highlightInvalid
              ? "border-2 border-red-400"
              : "border-gray-200 group-hover:border-gray-300"
          }`}
          placeholder={templatePlaceHolder}
          onKeyUp={handleKeyUp}
          value={getContent()}
          onChange={(e) => {
            const newMessageTemplate = handleChangeTemplate(
              e.target.value,
              messageTemplate,
            );
            onChange(newMessageTemplate);
          }}
        />
      ) : edit ? (
        <>
          <Textarea
            ref={ref}
            onInput={(e) => {
              if (!open) {
                e.currentTarget.style.height = "auto";
                e.currentTarget.style.height =
                  e.currentTarget.scrollHeight + "px";
              }
            }}
            className={`${
              isRoleFunction || isFunctionCall || isRoleTool ? "font-mono" : ""
            } h-full rounded-none bg-white ${
              highlightInvalid
                ? "border-2 border-red-400"
                : "border-gray-200 group-hover:border-gray-300"
            }`}
            placeholder={templatePlaceHolder}
            onKeyUp={handleKeyUp}
            value={getContent()}
            onChange={(e) => {
              const newMessageTemplate = handleChangeTemplate(
                e.target.value,
                messageTemplate,
              );
              onChange(newMessageTemplate);
            }}
          />
          {isRoleAssistant ? (
            <ToolCalls
              message={messageTemplate}
              setMessage={onChange}
              sourcedSnippets={sourcedSnippets}
            />
          ) : null}
          {isRoleUser ? (
            <div className="flex items-center gap-2">
              <Button variant="link" className="p-0" asChild>
                <Label className="cursor-pointer" htmlFor="image">
                  {upload.isLoading ? (
                    <LoadingSpinner size={5} />
                  ) : (
                    <ImageIcon className="h-5 w-5" />
                  )}
                  <Input
                    id="image"
                    type="file"
                    accept="image/*"
                    className="sr-only"
                    onChange={(e) => {
                      handleImage(e.target.files?.[0]);
                    }}
                  />
                </Label>
              </Button>
              <div className="text-xs text-gray-500">
                Click or drag to upload
              </div>
              <div className="py-0.5">
                <EditImageVariableModal
                  imageVariable={getImageVariable(messageTemplate)}
                  onSubmit={handleImageVariable}
                />
              </div>
            </div>
          ) : null}
        </>
      ) : (
        <Chat
          message={messageTemplate}
          open={open}
          invalidSnippets={invalidSnippets}
          sourcedSnippets={sourcedSnippets}
          isXrayMode={isXrayMode}
          renderedTemplate={
            renderedMessageTemplate
              ? getStringContent(renderedMessageTemplate)
              : ""
          }
        />
      )}
      <div
        className={`text-xs text-gray-500 ${
          messageTemplate.role === "placeholder" ? "" : "hidden"
        }`}
      >
        This placeholder is used to inject one or more messages into the prompt
      </div>
      <div className="grid grid-cols-4 gap-2 py-1">
        {isRoleUser && messageTemplate.content
          ? messageTemplate.content.map((content, index) =>
              content.type === "image_url" ? (
                <div
                  key={index}
                  className={`w-full1 relative h-40 ${
                    content.image_url.url ? "" : "hidden"
                  }`}
                >
                  {edit && (
                    <button
                      className="absolute right-1 top-1 rounded-full bg-gray-700 p-1 text-white hover:bg-gray-500"
                      onClick={() => handleRemoveImage(index)}
                    >
                      <XIcon className="h-4 w-4" />
                    </button>
                  )}
                  <img
                    src={content.image_url.url}
                    className="h-full w-full rounded-md object-cover"
                    alt="User uploaded"
                  />
                </div>
              ) : null,
            )
          : null}
      </div>

      <ChooseSnippetTemplateModal
        currentPromptText={getContent()}
        setCurrentPromptText={(value: string) => {
          const newMessageTemplate = handleChangeTemplate(
            value,
            messageTemplate,
          );
          onChange(newMessageTemplate);
        }}
        open={openTemplateLink}
        setOpen={setOpenTemplateLink}
        cursorPosition={cursorPosition}
      />
    </div>
  );

  const renderImageVariableIcon = () => {
    const imageVariable = getImageVariable(messageTemplate);
    return imageVariable ? (
      <ImageVariableModal imageVariable={imageVariable}>
        <div className="flex items-center gap-1">
          <div className="flex items-center gap-2 rounded-md bg-gray-100 px-3 py-1 text-sm text-gray-800">
            <ImageIcon className="h-4 w-4" />
            {imageVariable}
          </div>
        </div>
      </ImageVariableModal>
    ) : null;
  };

  return (
    <div className={`group flex flex-col border-b p-2 hover:bg-gray-50`}>
      <div className="flex flex-col gap-2">
        {messageForm}
        <div className="flex justify-end gap-2">
          {edit ? null : renderImageVariableIcon()}
          <div className="flex items-center">
            <ButtonCopy
              onClick={async () => await copyTextToClipboard(getContent())}
            />
          </div>
          <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>
              <ButtonMaximize />
            </DialogTrigger>
            <DialogContent className="group h-full max-w-full">
              {messageForm}
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

function Chat({
  message,
  open,
  invalidSnippets,
  sourcedSnippets = [],
  isXrayMode = false,
  renderedTemplate = null,
}: {
  message: Message;
  open: boolean;
  invalidSnippets?: string[];
  sourcedSnippets?: Array<PromptVersionSnippet>;
  isXrayMode?: boolean;
  renderedTemplate?: string | null;
}) {
  const parseFunctionArguments = (functionArguments: string | undefined) => {
    try {
      return JSON.stringify(
        JSON.parse(functionArguments ?? "{}"),
        undefined,
        2,
      );
    } catch (e) {
      const unparsableText = functionArguments ?? "{}";
      return (
        <>
          {message.role === "assistant" && (
            <div className="mb-2 italic text-red-500">
              <ExclamationIcon className="mr-2 inline-block h-4 w-4" />
              Error parsing below JSON
            </div>
          )}
          <div
            className={
              message.role === "assistant" ? "text-gray-600" : "text-gray-700"
            }
          >
            {unparsableText}
          </div>
        </>
      );
    }
  };

  let content;
  const isAssistantFunctionCall =
    message.role === "assistant" && message.function_call;
  if (message.role === "assistant") {
    if (message.function_call) {
      content = parseFunctionArguments(message.function_call.arguments);
    } else if (message.tool_calls) {
      content = (
        <ToolCalls message={message} sourcedSnippets={sourcedSnippets} />
      );
    } else {
      content = getStringContent(message);
    }
  } else if (message.role === "tool") {
    content = (
      <div>
        <div className="font-mono font-bold">
          <Terminal className="mr-1 inline-block h-4 w-4" />
          {message.name ?? "Tool"}
        </div>
        <div className="whitespace-pre-wrap font-mono">
          {parseFunctionArguments(getStringContent(message))}
        </div>
      </div>
    );
  } else if (message.role === "placeholder") {
    content = message.name;
  } else {
    content = getStringContent(message);
  }
  return (
    <div className={`flex flex-col gap-2 text-sm ${open && "h-full"}`}>
      <div className={`font-bold ${open ? "text-base" : "text-sm"} uppercase`}>
        <span
          className={`${
            !open && "group-hover:border-b group-hover:border-gray-600"
          }`}
        >
          {message.role}
        </span>
      </div>
      <div
        className={`flex gap-2 ${
          message.role === "function" || isAssistantFunctionCall
            ? "whitespace-pre-wrap font-mono"
            : ""
        } ${open ? "h-full rounded-none border border-gray-200" : ""}"}`}
      >
        <div className={`flex flex-col gap-2 ${open && "p-2 px-3"}`}>
          {(isAssistantFunctionCall || message.role === "function") && (
            <div className="font-mono font-bold">
              <Terminal className="mr-1 inline-block h-4 w-4" />
              {message.role === "assistant"
                ? message.function_call?.name
                : message.name}
            </div>
          )}
          <div
            className={`whitespace-pre-wrap ${
              (isAssistantFunctionCall || message.role === "function") && "pl-1"
            } ${
              message.role === "placeholder"
                ? "rounded bg-gray-100 px-2 font-mono"
                : ""
            }`}
          >
            {message.role === "tool" ||
            (message.role === "assistant" && message.tool_calls) ? (
              content
            ) : typeof content === "string" ? (
              <HighlightSnippets
                template={content}
                mode="view"
                invalidSnippets={invalidSnippets}
                sourcedSnippets={sourcedSnippets}
                isXrayMode={isXrayMode}
                renderedTemplate={renderedTemplate}
              />
            ) : (
              content
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MessagePromptTemplate;
