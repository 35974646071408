/**
 * Escapes special characters in a string for use in a regular expression.
 * @param {string} string - The string to escape.
 * @returns {string} The escaped string.
 */
/* export const escapeRegExp = (string: string): string => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}; 

TODO: Re-enable this function.
RIght now its causing troubles with foreign characters (e.g. Japanase)
*/

/**
 * Creates a map of escaped variable values to their original keys (variable names).
 * @param {Record<string, string>} inputVariables - The input variables to map.
 * @returns {Record<string, string>} The map of escaped values to original keys.
 */
export const createValueToKeyMap = (
  inputVariables: Record<string, string>,
): Record<string, string> => {
  const sortedVariables = Object.entries(inputVariables).sort(
    (a, b) => b[1].length - a[1].length,
  );

  return sortedVariables.reduce(
    (acc, [key, value]) => {
      acc[value] = key;
      return acc;
    },
    {} as Record<string, string>,
  );
};

export const filterDamagingInputVarPairs = (
  inputVariables: Record<string, string>,
): [string, string][] => {
  // Till a better solution is aggreed, we'll filter out the problematic cases here.
  // Empty value input var:
  const output = Object.entries(inputVariables).filter(([key, value]) => {
    return value !== "";
  });
  return output;
};
