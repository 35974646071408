import { useMemo } from "react";

const range = (start: number, end: number) => {
  let length = end - start + 1;
  /*
    Create an array of certain length and set the elements within it from
    start value to end value.
  */
  return Array.from({ length }, (_, idx) => idx + start);
};

interface UsePaginationInput {
  totalCount: number;
  pageSize: number;
}

export const usePagination = ({ totalCount, pageSize }: UsePaginationInput) => {
  const paginationRange = useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / pageSize);

    // Always show all pages without considering sibling count or dots
    return range(1, totalPageCount);
  }, [totalCount, pageSize]);

  return paginationRange;
};
