import { FC } from "react";
import { Link } from "react-router-dom";
import registry from "@/assets/hero_screenshots/hero.png";
import * as S from "./Styles";

const Hero: FC = () => {
  return (
    <S.Container>
      <S.Row>
        <S.HeroLeft>
          <S.H2>The cleanest way to prompt engineer</S.H2>
          <S.HeroLeftDescription>
            Edit, evaluate, deploy & repeat. Manage and monitor prompts with
            your whole team, including non-technical stakeholders.
          </S.HeroLeftDescription>
          <Link to="/create-account">
            <S.Button>Start for free 🍰</S.Button>
          </Link>
        </S.HeroLeft>
        <S.HeroRight>
          <S.HeroRightImg alt="Registry Screenshot" src={registry} />
        </S.HeroRight>
      </S.Row>
    </S.Container>
  );
};

export default Hero;
