export const handleResponse = async (response: Response) => {
  const isJson = response.headers
    .get("content-type")
    ?.includes("application/json");

  // Clone the response before reading it to avoid 'body stream already read' error
  const clonedResponse = isJson ? await response.clone().json() : null;

  if (!response.ok) {
    const errorMessage = clonedResponse
      ? clonedResponse.message
      : "Unknown error";

    if (response.status === 401 && errorMessage === "Invalid token") {
      window.location.href = "/logout";
      return Promise.reject();
    }
  }

  return response.json();
};
