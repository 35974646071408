import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/outline";

import { Button } from "@/components/ui/button";
import { ComposerHeader } from "@/components/ComposerHeader";
import {
  EMPTY_CONFIG,
  getModelParams,
  INITIAL_ERROR_STATE,
  ModelSelection,
  PlaygroundConfig,
  PlaygroundWebSocketListener,
  PromptTemplateComposer,
  RequestLinkButton,
} from "@/components/Playground";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { LOCAL_STORAGE } from "@/constants";
import useInputVariableParser from "@/hooks/useInputVariableParser";
import { modelConfigs, useAccessibleModels } from "@/modelConfig";
import {
  getPlaygroundConfigFromPromptVersion,
  getPlaygroundConfigFromRequestLog,
  useCreateIndividualRun,
  useParsePromptTemplateInputVariables,
} from "@/queries";
import { Message } from "@/types";
import { IndividualRun, MutationError } from "@/types/metadata";
import { InputVariableRow } from "@/types/playground";
import {
  areValidJsonInputVariables,
  configCanRun,
  isObjectOrArray,
} from "@/utils/playground";
import { getTemplateFormat, keepRelevantSearchParams } from "@/utils/utils";

export function Playground() {
  const [urlSearchParams] = useSearchParams();
  const [config, setConfig] = useState<PlaygroundConfig>(EMPTY_CONFIG);
  const [error, setError] = useState(INITIAL_ERROR_STATE);
  const [flashError, setFlashError] = useState(false);
  const [, setInvalidPromptMessage] = useState("");
  const [initializationComplete, setInitializationComplete] = useState(false);
  const [invalidPromptTemplate, setInvalidPromptTemplate] = useState(false);
  const [invalidPromptIndex, setInvalidPromptIndex] = useState<number | null>(
    null,
  );
  const [inputVariableRows, setInputVariableRows] = useState<
    InputVariableRow[]
  >(() => {
    const storedRows = localStorage.getItem(LOCAL_STORAGE.INPUT_VARIABLE_ROWS);
    return storedRows ? JSON.parse(storedRows) : [];
  });
  const [latestIndividualRunRequestId, setLatestIndividualRunRequestId] =
    useState<number | null>(null);
  const [latestIndividualRunResponse, setLatestIndividualRunResponse] =
    useState<IndividualRun | null>(null);
  const [parsedInputVariables, setParsedInputVariables] = useState<string[]>(
    [],
  );
  const [userSubmitted, setUserSubmitted] = useState(false);
  const authContext = useAuth();
  const userToken = authContext?.userToken || "";
  const userContext = useUser();
  const userId = userContext.user?.id;
  const accessibleModels = useAccessibleModels(userId);
  const { mutate: parsePromptTemplateInputVariables } =
    useParsePromptTemplateInputVariables();

  // TODO: Refactor this to use useQuery
  useEffect(() => {
    const promptVersionId = urlSearchParams.get("prompt_version_id");
    const requestLogId = urlSearchParams.get("request_log_id");

    if (!promptVersionId && !requestLogId) {
      setInitializationComplete(true);
      return;
    }

    const fetchPlaygroundConfig = async () => {
      try {
        let response;

        if (requestLogId) {
          response = await getPlaygroundConfigFromRequestLog(
            userToken,
            requestLogId,
          );
        } else if (promptVersionId) {
          response = await getPlaygroundConfigFromPromptVersion(
            userToken,
            promptVersionId,
          );
        }

        if (response.success) {
          const playgroundConfig = response["playground_config"];
          let modelSelection: ModelSelection = playgroundConfig.model;

          if (!(modelSelection[0] in modelConfigs)) {
            modelSelection = [
              "openai",
              playgroundConfig.type === "chat"
                ? "gpt-3.5-turbo"
                : "gpt-3.5-turbo-instruct",
            ];
          }

          let messages: Message[] = playgroundConfig.messages;

          // If a prompt template exists, replace those rendered messages with the prompt template messages (which include the raw variables)
          if (playgroundConfig.prompt_template?.messages) {
            const promptTemplateMessages =
              playgroundConfig.prompt_template.messages;

            const nonPromptTemplateMessages = messages.slice(
              promptTemplateMessages.length,
            );

            messages = [
              ...promptTemplateMessages,
              ...nonPromptTemplateMessages,
            ];
          }

          setConfig({
            ...playgroundConfig,
            messages,
            model: modelSelection,
            templateFormat: playgroundConfig.prompt_template
              ? getTemplateFormat(playgroundConfig.prompt_template)
              : "f-string",
          });

          if (
            areValidJsonInputVariables(playgroundConfig.prompt_input_variables)
          ) {
            const rows = Object.entries(
              playgroundConfig.prompt_input_variables,
            ).map(([key, value]) => {
              const stringValue: string = isObjectOrArray(value)
                ? JSON.stringify(value)
                : (value as string);

              return {
                isJson: isObjectOrArray(value),
                key,
                value: stringValue,
              };
            });

            setInputVariableRows(rows);
            localStorage.setItem(
              LOCAL_STORAGE.INPUT_VARIABLE_ROWS,
              JSON.stringify(rows),
            );
          }
        }
      } catch (error) {
        console.error("Error fetching playground config:", error);
      } finally {
        setInitializationComplete(true);
      }
    };

    fetchPlaygroundConfig();
  }, [urlSearchParams, userToken]);

  useEffect(() => {
    if (error) {
      setFlashError(true);
      setTimeout(() => {
        setFlashError(false);
      }, 1000);
    }
  }, [error]);

  const patchConfig = useCallback((patch: Partial<PlaygroundConfig>) => {
    setConfig((prevState: PlaygroundConfig) => ({ ...prevState, ...patch }));
  }, []);

  const createIndividualRunMutation = useCreateIndividualRun(userToken);

  const formattedInputVariables = useMemo(() => {
    return inputVariableRows.reduce(
      (
        acc: {
          [key: string]: any;
        },
        { isJson, key, value },
      ) => {
        acc[key] = value;

        if (isJson) {
          try {
            acc[key] = JSON.parse(value);
          } catch {
            console.error("Error parsing input variable");
          }
        }

        return acc;
      },
      {},
    );
  }, [inputVariableRows]);

  const handleRunRequest = useCallback(() => {
    setUserSubmitted(true);
    setError(INITIAL_ERROR_STATE);

    const provider = config.model[0];
    const model = config.model[1];
    const isChatConfig = config.type === "chat";

    // Check if chat & completion mismatch
    if (provider in modelConfigs && model in modelConfigs[provider]) {
      const isChatModel = modelConfigs[provider][model].metadata.isChat;

      if (isChatModel !== isChatConfig) {
        setTimeout(
          () =>
            setError(
              `Model mismatch. Model is ${
                isChatModel ? "chat" : "completion"
              }-type, but prompt is ${isChatConfig ? "chat" : "completion"}.`,
            ),
          100,
        );
        return;
      }
    }

    const template = isChatConfig
      ? {
          input_variables: [],
          messages: config.messages,
          type: "chat" as "chat",
        }
      : {
          content: [
            {
              text: config.template,
              type: "text" as "text",
            },
          ],
          input_variables: [],
          template_format: config.templateFormat,
          type: "completion" as "completion",
        };

    const modelParams = getModelParams(
      config.params,
      config.type,
      config.model,
      false,
      config.functionsType,
    );

    const functionToolData: any = {};
    const functionToolKeys = [
      "functions",
      "function_call",
      "tools",
      "tool_choice",
    ];

    functionToolKeys.forEach((key) => {
      if (modelParams.hasOwnProperty(key)) {
        functionToolData[key] = modelParams[key];
        delete modelParams[key];
      }
    });

    createIndividualRunMutation.mutate(
      {
        input_variables: formattedInputVariables,
        prompt_blueprint: {
          provider_base_url_name: config.provider_base_url_name || null,
          prompt_template: { ...template, ...functionToolData },
          metadata: {
            model: {
              provider: config.model[0],
              name: config.model[1],
              parameters: modelParams,
            },
          },
        },
        workspace_id: userContext?.activeWorkspaceId!,
      },
      {
        onError: (error) => {
          const mutationError = error as MutationError;
          setError(
            mutationError.message ||
              "Whoops, something seems to have gone wrong.",
          );
        },
        onSuccess: (data) => {
          if (data.success === false) {
            setError(
              data.message || "Whoops, something seems to have gone wrong.",
            );
            return;
          }

          setLatestIndividualRunRequestId(data.id);
        },
      },
    );
  }, [
    config,
    createIndividualRunMutation,
    formattedInputVariables,
    setLatestIndividualRunRequestId,
    userContext?.activeWorkspaceId,
  ]);

  let navigationPathList = ["Playground"];
  let navigationBackPath = `/workspace/${userContext?.activeWorkspaceId}/home/`;
  let subtitle: any = "Run individual LLM requests.";

  if (config.requestId || config.promptTemplateId) {
    let subtitleText = "Run individual LLM requests.";

    if (config.requestId) {
      navigationPathList = ["History", "Request Playground"];
      navigationBackPath = `/workspace/${userContext?.activeWorkspaceId}/request/${config.requestId}`;
      subtitleText = "Re-run request from history.";
    } else if (config.promptTemplateId && config.promptTemplateVersionNumber) {
      navigationPathList = [
        "Registry",
        "Prompts",
        `Version #${config.promptTemplateVersionNumber}`,
      ];
      navigationBackPath = `/workspace/${userContext?.activeWorkspaceId}/prompt/${config.promptTemplateId}/version/${config.promptTemplateVersionNumber}`;
      subtitleText = "Run prompt template from registry.";
    } else if (config.promptTemplateId) {
      navigationPathList = ["Registry", "Prompts"];
      navigationBackPath = `/workspace/${userContext?.activeWorkspaceId}/prompt/${config.promptTemplateId}`;
      subtitleText = "Run prompt template from registry.";
    }

    const preservedParams = keepRelevantSearchParams(urlSearchParams);
    navigationBackPath += `?${preservedParams.toString()}`;

    subtitle = (
      <>
        {subtitleText}
        <br />
        <Link
          className="text-blue-500 hover:text-blue-400"
          to={navigationBackPath}
        >
          <ArrowLeftIcon
            className="-mt-1 mr-1 inline h-4 w-auto"
            aria-hidden="true"
          />
          Go back
        </Link>
      </>
    );
  }

  const renderRequestLinkButton = () => {
    if (
      !createIndividualRunMutation.isSuccess ||
      !!error ||
      !latestIndividualRunResponse ||
      !latestIndividualRunResponse.request ||
      latestIndividualRunRequestId !== latestIndividualRunResponse.id
    ) {
      return null;
    }

    return <RequestLinkButton request={latestIndividualRunResponse.request} />;
  };

  const renderRunRequestButton = () => {
    let isLoading = true;

    if (
      !createIndividualRunMutation.isLoading &&
      (!latestIndividualRunRequestId ||
        latestIndividualRunRequestId === latestIndividualRunResponse?.id)
    ) {
      isLoading = false;
    }

    return (
      <Button
        className={`text-sm ${flashError && "bg-red-300"}`}
        disabled={!configCanRun(config)}
        isLoading={isLoading}
        onClick={handleRunRequest}
      >
        Run Request
      </Button>
    );
  };

  const inputVariableParser = useInputVariableParser(
    parsePromptTemplateInputVariables,
    parsedInputVariables,
    setParsedInputVariables,
    setInvalidPromptTemplate,
    setInvalidPromptMessage,
    setInvalidPromptIndex,
  );

  return (
    <div className="bg-white px-1 pb-8 pt-1">
      <ComposerHeader
        title="Playground"
        subtitle={subtitle}
        navigationBackPath={navigationBackPath}
        navigationPathList={navigationPathList}
      />
      <PlaygroundWebSocketListener
        latestIndividualRunRequestId={latestIndividualRunRequestId}
        setConfig={setConfig}
        setError={setError}
        setLatestIndividualRunResponse={setLatestIndividualRunResponse}
      />
      {renderRequestLinkButton()}
      {initializationComplete && (
        <PromptTemplateComposer
          config={config}
          errorMessage={error}
          inputVariableParser={inputVariableParser}
          inputVariableRows={inputVariableRows}
          invalidPromptIndex={invalidPromptIndex}
          invalidPromptTemplate={invalidPromptTemplate}
          modelOptions={accessibleModels}
          parsedInputVariables={parsedInputVariables}
          patchConfig={patchConfig}
          runRequestButton={renderRunRequestButton()}
          setInputVariableRows={setInputVariableRows}
          submitAction={handleRunRequest}
          userSubmitted={userSubmitted}
        />
      )}
    </div>
  );
}
