import { Switch } from "@/components";
import { Breadcrumbs } from "@/components/Breadcrumbs";
import useInputVariableParser from "@/hooks/useInputVariableParser";
import {
  useCreatePromptTemplate,
  useCreatePromptVersion,
  useParsePromptTemplateInputVariables,
} from "@/queries";
import {
  BasePromptTemplate,
  ChatTemplate,
  Metadata,
  Model,
  Template,
  TemplateFormat,
} from "@/types";
import { Folder } from "@/types/folders";
import { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import PromptTemplateEditor from "./components/PromptTemplate/PromptTemplateEditor";
import { useAuth } from "./context/auth-context";
import { useUser } from "./context/user-context";
import { PromptVersion } from "./types/apiGetters";
import { PromptRegistry } from "./types/prompt-registry";
import { ErrorResponse } from "./types/response";
import { parseErrorMessage } from "./utils/errors";

type Mode = "completion" | "chat";

export default function CreatePromptTemplate({
  startingPromptTemplate,
  startingPromptTemplateVersion,
  editMode = false,
}: {
  startingPromptTemplate?: PromptRegistry;
  startingPromptTemplateVersion?: PromptVersion;
  editMode?: boolean;
}) {
  const [newPromptVersion, setNewPromptVersion] =
    useState<PromptVersion | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [invalidPromptTemplate, setInvalidPromptTemplate] = useState(false);
  const [invalidPromptMessage, setInvalidPromptMessage] = useState("");
  const [invalidPromptIndex, setInvalidPromptIndex] = useState<number | null>(
    null,
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [parsedInputVariables, setParsedInputVariables] = useState<string[]>(
    [],
  );
  const [newPromptVersionForm, setNewPromptVersionForm] = useState(false);
  const mode = editMode
    ? startingPromptTemplateVersion?.prompt_template.type === "chat"
      ? "chat"
      : "completion"
    : ((searchParams.get("mode") ?? "chat") as Mode);
  const authContext = useAuth();
  const createPromptTemplate = useCreatePromptTemplate(authContext?.userToken!);
  const { mutate: parsePromptTemplateInputVariables } =
    useParsePromptTemplateInputVariables();
  const createPromptVersion = useCreatePromptVersion(authContext?.userToken!);
  const userContext = useUser();

  const [navElements, navLink] = useMemo(() => {
    const getFolderName = (): string | null => {
      const folderId = searchParams.get("folder");
      if (!folderId) return null;
      const folder = userContext.folders.find(
        (folder: Folder) => folder.id === parseInt(folderId, 10),
      );
      return folder ? folder.name : null;
    };

    const folderName = getFolderName();
    let baseNavLink = `/workspace/${userContext?.activeWorkspaceId}/prompt`;
    let elements: string[] = ["Registry", "Prompts"];

    if (startingPromptTemplate) {
      baseNavLink += `/${startingPromptTemplate.id}`;
      elements.push(startingPromptTemplate.prompt_name);
    }

    if (startingPromptTemplate && startingPromptTemplateVersion) {
      baseNavLink += `/version/${startingPromptTemplateVersion.number}`;
      elements.push(`Version #${startingPromptTemplateVersion.number}`);
    }

    if (folderName) {
      elements.push(folderName);
    }

    return [elements, baseNavLink];
  }, [
    userContext,
    startingPromptTemplate,
    startingPromptTemplateVersion,
    searchParams,
  ]);

  const submitTemplate = async (
    prompt_name: string,
    promptData: string | ChatTemplate,
    inputVariables: string[],
    metadata: Metadata,
    templateFormat: TemplateFormat,
    commit_message: string,
    source_report_id: number | null,
    model: Model | null,
    provider_base_url_name: string | null,
  ) => {
    if (!prompt_name) {
      setError("Please enter a title for your prompt template");
      setNewPromptVersionForm(false);
      return;
    } else if (!promptData) {
      setError("You must provide a template string");
      setNewPromptVersionForm(false);
      return;
    } else if (loadingCreate) {
      return;
    }

    setLoadingCreate(true);
    setError(null);

    if (model) {
      metadata.model = model;
    }
    // filter non null values
    metadata = Object.entries(metadata).reduce(
      (acc, [key, value]) => {
        if (value) {
          acc[key] = value;
        }
        return acc;
      },
      {} as Record<string, unknown>,
    );

    const folderId = searchParams.get("folder");
    const prompt_template: Template =
      typeof promptData === "string"
        ? {
            type: "completion",
            content: [{ type: "text", text: promptData }],
            input_variables: inputVariables,
            template_format: templateFormat,
          }
        : {
            ...promptData,
            input_variables: inputVariables,
          };

    const onSuccess = (data: PromptVersion | ErrorResponse) => {
      // There was an error (there is no "success" key when the request actually succeeds)
      if ("success" in data) {
        const message =
          typeof data.message === "string"
            ? data.message
            : parseErrorMessage(data.message);
        setError(message);
        setLoadingCreate(false);
        setNewPromptVersionForm(false);
        return;
      }

      setNewPromptVersion(data);
      return;
    };

    const onError = (error: any) => {
      const errorMessage = parseErrorMessage(error?.message);
      setError(errorMessage);
      setLoadingCreate(false);
      setNewPromptVersionForm(false);
      return;
    };

    const basePromptTemplate: BasePromptTemplate = {
      prompt_name,
      folder_id: folderId ? parseInt(folderId, 10) : undefined,
      workspace_id: userContext.activeWorkspaceId!,
    };

    if (editMode) {
      createPromptVersion.mutate(
        {
          provider_base_url_name: provider_base_url_name || null,
          prompt_id: startingPromptTemplate?.id!,
          prompt_template,
          commit_message,
          metadata,
          report_id: source_report_id ?? undefined,
        },
        {
          onSuccess,
          onError,
        },
      );
      return;
    }

    createPromptTemplate.mutate(
      {
        prompt_template: {
          ...basePromptTemplate,
        },
        prompt_version: {
          provider_base_url_name: provider_base_url_name || null,
          prompt_template,
          commit_message,
          metadata,
          report_id: source_report_id ?? undefined,
        },
      },
      {
        onSuccess: (data) => {
          if ("success" in data) {
            onSuccess(data);
          } else {
            onSuccess(data.prompt_version);
          }
          return;
        },
        onError: () => setNewPromptVersionForm(false),
      },
    );
  };

  const inputVariableParser = useInputVariableParser(
    parsePromptTemplateInputVariables,
    parsedInputVariables,
    setParsedInputVariables,
    setInvalidPromptTemplate,
    setInvalidPromptMessage,
    setInvalidPromptIndex,
  );

  return (
    <div className="h-full p-1">
      <div className="flex h-full w-full flex-col">
        <Breadcrumbs items={navElements} navigateUrl={navLink} />
        <div className="pt-4">
          <h1 className="text-2xl text-gray-900">
            {editMode ? "Edit Template" : "Create a New Prompt Template"}
          </h1>
          <div className="text-md max-w-2xl pb-1 pt-1 text-gray-500">
            Learn more about templates on our{" "}
            <a
              className="text-blue-500 hover:text-blue-400"
              target="_blank"
              href="https://docs.promptlayer.com/features/prompt-registry"
              rel="noreferrer"
            >
              docs
            </a>
            .
          </div>
        </div>
        {!editMode && (
          <div className="flex items-center justify-center gap-4 pb-2 pt-6">
            <div>Chat</div>
            <Switch
              checked={mode === "completion"}
              onChange={(checked) =>
                setSearchParams((prev) => {
                  prev.set("mode", checked ? "completion" : "chat");
                  return prev;
                })
              }
            />
            <div>Completion</div>{" "}
          </div>
        )}
        <PromptTemplateEditor
          startingPromptTemplate={startingPromptTemplate}
          startingPromptTemplateVersion={startingPromptTemplateVersion}
          submitTemplate={submitTemplate}
          inputVariableParser={inputVariableParser}
          parsedInputVariables={parsedInputVariables}
          invalidPromptTemplate={invalidPromptTemplate}
          invalidPromptMessage={invalidPromptMessage}
          invalidPromptIndex={invalidPromptIndex}
          loadingCreate={loadingCreate}
          errorMessage={error}
          setError={setError}
          editMode={editMode}
          mode={mode}
          promptVersion={newPromptVersion}
          newPromptVersionForm={newPromptVersionForm}
          setNewPromptVersionForm={setNewPromptVersionForm}
        />
      </div>
    </div>
  );
}
