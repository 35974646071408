import NotFound from "@/NotFound";
import GenericLanding from "@/components/GenericLanding/GenericLanding";
import { FC, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import LeftMenu from "./LeftMenu/LeftMenu";
import * as S from "./Styles";

interface LayoutWrapperProps {
  children: React.ReactNode;
}

export const LayoutWrapper: FC<LayoutWrapperProps> = ({ children }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <S.Container>
      <Header menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <LeftMenu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      {children}
      <Footer />
    </S.Container>
  );
};

export const MarketingLayout: FC = () => {
  const renderMainContent = () => {
    return (
      <Routes>
        <Route path="/" element={<GenericLanding />} />
        <Route
          path="*"
          element={
            <S.NotFoundWrapper>
              <NotFound />
            </S.NotFoundWrapper>
          }
        />
      </Routes>
    );
  };

  return <LayoutWrapper>{renderMainContent()}</LayoutWrapper>;
};
