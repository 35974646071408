import { z } from "zod";

const response = z.object({
  id: z.number(),
  name: z.string(),
  created_at: z.string(),
  prompt_version_id: z.number().positive(),
  prompt_id: z.number().positive(),
});

const create = z.object({
  name: z.string().min(1).default(""),
  prompt_id: z.number().positive(),
  promptLabel: response.optional(),
});

type Create = z.infer<typeof create>;
type Response = z.infer<typeof response>;

export { Create, Response, create, response };
