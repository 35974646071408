import { TableCell as Cell } from "../../table";

interface CellProps {
  placeholder?: boolean;
  children?: React.ReactNode;
  className?: string;
  [key: string]: any;
}

const TableCell = ({
  children,
  placeholder,
  className,
  ...props
}: CellProps) => {
  return (
    <Cell
      className={`${
        className || ""
      } group/Cell relative h-[50px] max-h-[200px] min-h-[50px] max-w-full overflow-y-auto border-r px-6 py-2 focus:z-[1] group-hover:bg-gray-50 ${
        placeholder
          ? "bg-gray-100 group-hover:border-gray-300 group-hover:bg-gray-200"
          : "bg-white"
      }   p-0 text-xs first-of-type:border-l-0 last-of-type:border-r-0`}
      {...props}
    >
      {children}
    </Cell>
  );
};

export default TableCell;
