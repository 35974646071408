import { InlineEdit } from "@/components";
import { useAuth } from "@/context/auth-context";
import { ToastType } from "@/enums";
import { useEditDatasetGroup } from "@/queries";
import { displayErrorToast, displayToast } from "@/utils/toast";
import { useCallback } from "react";
import { useParams } from "react-router-dom";

function RenameDatasetGroup({ name }: { name: string }) {
  const { datasetGroupId } = useParams();
  const authContext = useAuth();
  const userToken = authContext!.userToken;
  const editDatasetGroup = useEditDatasetGroup(userToken!);

  const handleDatasetGroupNameEdit = useCallback(
    async (name: string) => {
      const response = await editDatasetGroup.mutateAsync({
        dataset_group_id: parseInt(datasetGroupId!, 10),
        name,
      });

      if (response.success) {
        displayToast("Dataset name updated", ToastType.success);
      } else {
        displayErrorToast(
          response.error || "There was an error updating the dataset name",
        );
      }
    },
    [datasetGroupId, editDatasetGroup],
  );
  return (
    <InlineEdit.Root
      initialValue={name || ""}
      onEdit={handleDatasetGroupNameEdit}
      className="mb-3"
    >
      <InlineEdit.Trigger>
        <h1 className={`w-fit cursor-text text-4xl font-bold`}>{name}</h1>
      </InlineEdit.Trigger>
      <InlineEdit.Input className="py-1 text-base" />
      <InlineEdit.Rename>Rename</InlineEdit.Rename>
      <InlineEdit.Cancel>Cancel</InlineEdit.Cancel>
    </InlineEdit.Root>
  );
}

export default RenameDatasetGroup;
