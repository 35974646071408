import DeleteAllRowsButton from "@/components/DatasetEditor/Header/DeleteAllRowsButton";
import { useContext, useMemo } from "react";
import { DatasetEditorContext } from "..";
import AddFromRequestHistoryButton from "./AddFromRequestHistoryButton";
import ArchiveButton from "./ArchiveButton";
import DeleteButton from "./DeleteButton";
import DownloadButton from "./DownloadButton";
import EditButton from "./EditButton";
import FilterParamsButton from "./FilterParamsButton";
import RenameDatasetGroup from "./RenameDatasetGroup";
import UploadDataButton from "./UploadDataButton";
import VersionHistoryMenu from "./VersionHistoryMenu";
import useDatasets from "./VersionHistoryMenu/hooks/useDatasets";

const Header = ({
  datasetId,
  isLoading,
}: {
  datasetId: string | undefined;
  isLoading: boolean;
}) => {
  const {
    datasetGroup,
    datasets,
    activeDataset,
    isDraft,
    handleDeleteDatasetGroup,
  } = useContext(DatasetEditorContext);

  const { currentVersion, sortedDatasets } = useDatasets(datasets, datasetId);

  const isArchived = useMemo(() => !!datasetGroup?.is_deleted, [datasetGroup]);

  return (
    <div
      className={`mx-auto  rounded-lg ${
        isDraft && "bg-orange-100 px-6 pb-6"
      } mb-2 pt-6 `}
    >
      <RenameDatasetGroup name={datasetGroup?.name || ""} />
      <div className="flex flex-col items-start justify-between gap-2.5 sm:flex-row sm:flex-wrap sm:items-center">
        <div className="z-10 flex flex-col items-start gap-y-2.5 sm:flex-row sm:items-center sm:gap-x-4">
          {!isLoading && isDraft && (
            <span className={`rounded-full bg-orange-200 px-2 py-1 text-xs`}>
              You are in Draft Mode
            </span>
          )}
          {!isLoading && (
            <VersionHistoryMenu
              currentVersion={currentVersion}
              sortedDatasets={sortedDatasets}
              datasets={datasets}
              isDraft={isDraft}
            />
          )}
          {!isLoading && isArchived && !isDraft && (
            <ArchiveButton onDelete={handleDeleteDatasetGroup} />
          )}
        </div>

        <div
          className={`flex ${
            (isDraft && "w-full") || "w-fit"
          } flex-col items-start gap-2.5 whitespace-nowrap sm:flex-col sm:items-start sm:gap-x-2.5  md:flex-row lg:flex-row lg:justify-between`}
        >
          {!isLoading && isDraft && (
            <div className="flex gap-2.5">
              <AddFromRequestHistoryButton />
              <UploadDataButton />
            </div>
          )}
          <div
            className={`${
              !isDraft && "ml-auto"
            } flex flex-col gap-2.5 sm:flex-row sm:gap-x-2.5 md:flex-col lg:flex-row`}
          >
            {!isArchived && !isLoading && !isDraft && (
              <ArchiveButton onDelete={handleDeleteDatasetGroup} />
            )}
            <FilterParamsButton activeDataset={activeDataset} />
            <DownloadButton />
            <DeleteAllRowsButton />
            <DeleteButton />
            <EditButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
