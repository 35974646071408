import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Paperclip } from "lucide-react";

interface ImageVariableProps {
  children: React.ReactNode;
  imageVariable: string;
  onOpenChange?: (open: boolean) => void;
  open?: boolean;
}

function ImageVariableModal({
  children,
  imageVariable,
  onOpenChange,
  open,
}: ImageVariableProps) {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogTrigger>
        <Button variant="link" className="relative" size="tinyIcon">
          <Paperclip className="h-4 w-4 text-primary hover:text-blue-400" />
          {imageVariable ? (
            <div className="absolute right-0.5 top-0.5 h-1.5 w-1.5 rounded-full bg-red-500" />
          ) : null}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Image Variable</DialogTitle>
          <DialogDescription>
            Set this input variable to dynamically load an array of images.
            Variable value must be an array of either valid image urls or base64
            images
          </DialogDescription>
        </DialogHeader>
        {children}
      </DialogContent>
    </Dialog>
  );
}

export default ImageVariableModal;
