import { useCallback, useRef } from "react";
import {
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { MARKETING_NAVIGATION } from "@/constants";
import { ChevronDown } from "lucide-react";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const NavigationMenu: React.FC = () => {
  const menuItems = MARKETING_NAVIGATION;
  return (
    <nav className="flex gap-10 self-end whitespace-nowrap text-lg tracking-tight">
      {menuItems.map((item, index) =>
        item.dropdownItems ? (
          <DropdownMenu
            key={index}
            label={item.label}
            items={item.dropdownItems}
          />
        ) : (
          <MenuItem key={index} label={item.label} link={item.route} />
        ),
      )}
    </nav>
  );
};

interface MenuItemProps {
  label: string;
  link?: string;
}

const MenuItem: React.FC<MenuItemProps> = ({ label, link }) => {
  return (
    <a href={link} className="text-black">
      {label}
    </a>
  );
};

interface DropdownItemProps {
  label: string;
  icon: any;
  route: string;
}

interface DropdownMenuProps {
  label: string;
  items: DropdownItemProps[];
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({ label, items }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { pathname } = useLocation();
  const timeoutRef = useRef<number | null>(null);

  const isActive = (link: string) =>
    link === pathname || (link !== "/" && pathname.startsWith(link));

  const handleMouseEnter = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setIsOpen(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    timeoutRef.current = window.setTimeout(() => {
      setIsOpen(false);
    }, 300); // Adjust this delay as needed
  }, []);

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <PopoverTrigger asChild>
          <button
            className="flex items-center gap-2 text-black"
            aria-haspopup="true"
            aria-expanded={isOpen}
          >
            {label}
            <ChevronDown />
          </button>
        </PopoverTrigger>
        <PopoverContent className="z-[999] border-none shadow-[0px_0px_8px_rgba(0,0,0,0.09)]">
          <PopoverArrow />
          <ul className="rounded-lg bg-white">
            {items.map((item, index) => (
              <Link to={item.route} key={index}>
                <li
                  className={`flex items-center gap-3.5 p-2.5 ${
                    isActive(item.route)
                      ? "bg-gray-100 text-indigo-500"
                      : "text-stone-500 hover:bg-gray-100 hover:text-indigo-500"
                  }`}
                >
                  <item.icon
                    className={`mr-2 h-5 w-auto ${
                      isActive(item.route) ? "text-indigo-500" : ""
                    }`}
                  />
                  <span>{item.label}</span>
                </li>
              </Link>
            ))}
          </ul>
        </PopoverContent>
      </div>
    </Popover>
  );
};

export default NavigationMenu;
