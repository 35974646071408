import { ChatTemplate, Message } from "@/types";
import { getStringContent } from "@/utils/utils";
import { ActionCall } from "./ActionCall";
import { ActionCallAndResponse } from "./ActionCallAndResponse";
import { ActionDeclaration } from "./ActionDeclaration";
import { ActionResponse } from "./ActionResponse";
import { NotAction } from "./NotAction";

export const MessageDisplay = ({
  componentIsOpen,
  highlightedContent,
  highlight,
  message,
  chatTemplate,
}: {
  componentIsOpen: boolean;
  highlightedContent: string | React.ReactNode | null;
  highlight: boolean;
  message?: Message;
  chatTemplate: ChatTemplate;
}) => {
  const content = message ? getStringContent(message) : "";
  const isFunctionCall =
    message?.role === "assistant" && !!message.function_call && !content;
  const isToolCall =
    message?.role === "assistant" &&
    !!message.tool_calls &&
    message.tool_calls.length > 0 &&
    !content;
  const isFunctionCallAndResponse =
    message?.role === "assistant" &&
    !!message.function_call &&
    !!message.content;

  const isToolCallAndResponse =
    message?.role === "assistant" &&
    !!message.tool_calls &&
    message.tool_calls.length > 0 &&
    !!message.content;
  const isToolResponse = message?.role === "tool";
  const isFunctionResponse = message?.role === "function";
  const tools = chatTemplate.tools;
  const functions = chatTemplate.functions;
  const isToolDeclarations = !!tools && tools.length > 0;
  const isFunctionDeclarations = !!functions && functions.length > 0;

  const renderContent = () => {
    if (isFunctionCall || isToolCall) {
      return <ActionCall componentIsOpen={componentIsOpen} message={message} />;
    } else if (isFunctionCallAndResponse || isToolCallAndResponse) {
      return (
        <ActionCallAndResponse
          componentIsOpen={componentIsOpen}
          highlight={highlight}
          message={message}
        />
      );
    } else if (isToolResponse || isFunctionResponse) {
      return (
        <ActionResponse componentIsOpen={componentIsOpen} message={message} />
      );
    } else if (
      (isToolDeclarations || isFunctionDeclarations) &&
      message === undefined
    ) {
      return (
        <ActionDeclaration
          componentIsOpen={componentIsOpen}
          chatTemplate={chatTemplate}
          message={message}
        />
      );
    } else {
      return (
        <NotAction
          componentIsOpen={componentIsOpen}
          highlight={highlight}
          highlightedContent={highlightedContent}
          message={message}
        />
      );
    }
  };

  return <>{renderContent()}</>;
};
