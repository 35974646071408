import { useNavigate } from "react-router-dom";

import RequestDisplay from "@/components/RequestDisplay";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { useRequest } from "@/queries";
import LoadingSpinner from "./LoadingSpinner";

export const RequestContent = ({ requestId }: { requestId: string }) => {
  const userContext = useUser();
  const auth = useAuth();
  const navigate = useNavigate();
  let requestInfo = userContext.getRequest(requestId);
  const request = useRequest(
    requestId,
    auth?.userToken || "",
    true,
    userContext?.activeWorkspaceId!,
  );
  requestInfo = requestInfo ?? request.data?.items?.[0];

  if (requestId === "null") {
    console.log("Navigating to home");
    navigate("/");
  }

  if (!requestInfo && request.isLoading) {
    return <LoadingSpinner size={5} />;
  }

  return <RequestDisplay requestId={requestId} requestInfo={requestInfo} />;
};
