import { Arrow, Content, Portal, Root, Trigger } from "@radix-ui/react-popover";
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from "react";

import { cn } from "@/lib/utils";

const Popover = Root;

const PopoverTrigger = Trigger;

const PopoverContent = forwardRef<
  ElementRef<typeof Content>,
  ComponentPropsWithoutRef<typeof Content>
>(({ className, align = "center", sideOffset = 4, ...props }, ref) => (
  <Portal>
    <Content
      ref={ref}
      align={align}
      sideOffset={sideOffset}
      className={cn(
        "z-50 w-72 rounded-md border bg-popover p-4 text-popover-foreground shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
        className,
      )}
      {...props}
    />
  </Portal>
));
PopoverContent.displayName = Content.displayName;

const PopoverArrow = forwardRef<
  ElementRef<typeof Arrow>,
  ComponentPropsWithoutRef<typeof Arrow>
>(({ className, ...props }, ref) => (
  <Arrow
    ref={ref}
    className={cn(" fill-white shadow-md", className)}
    {...props}
  />
));

export { Popover, PopoverArrow, PopoverContent, PopoverTrigger };
