import { Breadcrumbs } from "./Breadcrumbs";

type ComposerHeaderProps = {
  title: string;
  subtitle?: any;
  navigationBackPath?: string;
  navigationPathList?: Array<string>;
};

export const ComposerHeader = ({
  title,
  subtitle,
  navigationBackPath,
  navigationPathList,
}: ComposerHeaderProps) => {
  return (
    <>
      {navigationPathList && (
        <Breadcrumbs
          items={navigationPathList}
          navigateUrl={navigationBackPath ?? "/"}
          pageTitle={title}
          pageSubtitle={subtitle}
        />
      )}
    </>
  );
};
