import ToastifyStyle from "@/styles/components/ToastifyStyle";
import TimeAgo from "javascript-time-ago";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import AppProviders from "./context/app-context";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

import en from "javascript-time-ago/locale/en.json";

TimeAgo.addDefaultLocale(en);

const loadAndStartEarlyNetworkRequestRecorder = () => {
  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      resolve({});
    }, 10_000);

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src =
      "https://snippet.meticulous.ai/record/v1/network-recorder.bundle.js";
    script.onload = function () {
      clearTimeout(timeoutId);
      resolve({});
    };
    script.onerror = () => {
      clearTimeout(timeoutId);
      reject("Meticulous recorder failed to initialise.");
    };
    document.head.appendChild(script);
  });
};

async function startApp() {
  // Initalise app after the Meticulous recorder is ready, e.g.
  const container = document.getElementById("root") as HTMLElement;
  const root = createRoot(container);
  // Meticulous recorder wrapper
  loadAndStartEarlyNetworkRequestRecorder()
    .then(() => {
      root.render(
        <React.StrictMode>
          <AppProviders>
            <ToastifyStyle />
            <App />
          </AppProviders>
        </React.StrictMode>,
      );
    })
    .catch((err) => {
      console.error(err);
    });
}

startApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
