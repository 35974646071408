import { useState } from "react";
import { XIcon } from "@heroicons/react/solid";
import { ImageIcon } from "@radix-ui/react-icons";

import ImageVariableModal from "@/components/ImageVariableModal";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";

interface EditImageVariableProps {
  imageVariable: string;
  onSubmit: (imageVariable: string) => void;
}

const EditImageVariableModal = ({
  imageVariable,
  onSubmit,
}: EditImageVariableProps) => {
  const [open, setOpen] = useState(false);

  const handleSubmit = (newImageVariable: string) => {
    onSubmit(newImageVariable);
    if (!newImageVariable) {
      setOpen(false);
    }
  };

  const renderImageVariableDisplay = () => {
    return (
      <div className="flex gap-1">
        <div className="flex items-center gap-2 rounded-md bg-gray-100 px-3 py-1 text-sm text-gray-800">
          <ImageIcon className="h-4 w-4" />
          <div>{imageVariable}</div>
          <Button
            variant="destructiveLink"
            className="text-red-500"
            onClick={() => {
              onSubmit("");
            }}
          >
            <XIcon className="h-4 w-4" />
          </Button>
        </div>
      </div>
    );
  };

  const renderImageVariableForm = () => {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          const formData = new FormData(e.currentTarget);
          const imageVariable = formData.get("imageVariable") as string;
          handleSubmit(imageVariable);
        }}
        className={`flex flex-col gap-4 ${imageVariable ? "hidden" : ""}`}
      >
        <Input
          placeholder="Variable name"
          name="imageVariable"
          defaultValue={imageVariable}
        />
        <div className="flex justify-end">
          <Button type="submit">Save</Button>
        </div>
      </form>
    );
  };

  return (
    <ImageVariableModal
      imageVariable={imageVariable}
      onOpenChange={setOpen}
      open={open}
    >
      {imageVariable ? renderImageVariableDisplay() : renderImageVariableForm()}
    </ImageVariableModal>
  );
};

export default EditImageVariableModal;
