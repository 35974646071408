import { cn } from "@/lib/utils";
import { forwardRef, useState } from "react";

export default forwardRef<HTMLButtonElement, React.ComponentProps<"button">>(
  ({ children, className, ...props }, ref) => {
    const [isCopied, setIsCopied] = useState(false);
    return (
      <button
        ref={ref}
        className={cn(
          "rounded-md bg-gray-100 px-1.5 py-0.5 text-xs text-gray-500 hover:bg-gray-200 hover:text-gray-700",
          className,
        )}
        {...props}
        onClick={(event) => {
          props.onClick?.(event);
          setIsCopied(true);
          setTimeout(() => setIsCopied(false), 2000);
        }}
      >
        {isCopied ? "Copied!" : "Copy"}
      </button>
    );
  },
);
