import { Table } from "@tanstack/react-table";
import {
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
} from "lucide-react";

import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

interface Props<TData> {
  table: Table<TData>;
  pages: number;
  total: number;
  page: number;
  pageSize: number;
  pageSizeOptions: number[];
  setPageIndex: (pageIndex: number) => void;
  setPageSize: (pageSize: number, page?: number) => void;
}

const TablePagination = <TData,>({
  table,
  pages,
  total,
  page,
  pageSize,
  pageSizeOptions,
  setPageIndex,
  setPageSize,
}: Props<TData>) => {
  return !total || total <= Math.min(...pageSizeOptions) ? null : (
    <div className="flex items-center justify-between px-2">
      <div className="flex items-center space-x-6 lg:space-x-8">
        <div className="flex items-center space-x-2">
          <p className="text-sm font-medium">Rows per page</p>

          <Select
            value={pageSize.toString()}
            onValueChange={(value) => {
              if (parseInt(value) !== pageSize) {
                table.setPageSize(Number(value));
                setPageSize(Number(value), 1);
              }
            }}
          >
            <SelectTrigger className="h-8 w-[70px]">
              <SelectValue placeholder={table.getState().pagination.pageSize} />
            </SelectTrigger>
            <SelectContent side="top">
              {pageSizeOptions.map((pageSize) => (
                <SelectItem key={pageSize} value={`${pageSize}`}>
                  {pageSize}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="flex w-[100px] items-center justify-center text-sm font-medium">
          Page {page} of {pages}
        </div>
        <div className="flex items-center space-x-2">
          <Button
            variant="outline"
            className="hidden h-8 w-8 p-0 lg:flex"
            onClick={() => setPageIndex(1)}
            disabled={page === 1}
          >
            <span className="sr-only fixed">Go to first page</span>
            <ChevronsLeft className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            className="h-8 w-8 p-0"
            onClick={() => setPageIndex(page - 1)}
            disabled={page === 1}
          >
            <span className="sr-only fixed">Go to previous page</span>
            <ChevronLeft className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            className="h-8 w-8 p-0"
            onClick={() => {
              setPageIndex(page + 1);
            }}
            disabled={page === pages}
          >
            <span className="sr-only fixed">Go to next page</span>
            <ChevronRight className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            className="hidden h-8 w-8 p-0 lg:flex"
            onClick={() => setPageIndex(pages)}
            disabled={page === pages}
          >
            <span className="sr-only fixed">Go to last page</span>
            <ChevronsRight className="h-4 w-4" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export { TablePagination };
