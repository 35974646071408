import FineTuneModal from "@/components/FineTuneModal/FineTuneModal";
import { useUser } from "@/context/user-context";
import { AdjustmentsIcon, DocumentReportIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";

const SearchBarCollapsed = () => {
  const user = useUser();

  return (
    <div className="flex w-full flex-col items-center gap-y-2 pt-2 text-xs text-gray-600">
      <FineTuneModal>
        <div className="flex cursor-pointer flex-col items-center rounded-md p-2 hover:bg-gray-200 hover:text-gray-800">
          <AdjustmentsIcon className="h-6 w-6" />
          <div>Fine-Tune</div>
        </div>
      </FineTuneModal>
      <Link to={`/workspace/${user?.activeWorkspaceId}/datasets`}>
        <div
          className={`flex cursor-pointer flex-col items-center rounded-md p-2 hover:bg-gray-200 hover:text-gray-800 ${
            window.location.pathname.includes(
              `/workspace/${user?.activeWorkspaceId}/datasets`,
            )
              ? "bg-gray-200 text-gray-800"
              : ""
          }`}
        >
          <DocumentReportIcon className="h-6 w-6" />
          <div>Datasets</div>
        </div>
      </Link>
    </div>
  );
};

export default SearchBarCollapsed;
