import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { useFineTunedJob, usePromptRegistryObjects } from "@/queries";
import { URL_PARAM_KEYS, parseUrlParams } from "@/utils/utils";
import { ExclamationCircleIcon, LinkIcon } from "@heroicons/react/outline";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import UrlSearchParamsDisplay from "../UrlSearchParamsDisplay";
import { Button } from "../ui/button";
import {
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import LimitField from "../ui/fields/LimitField";
import PromptTemplateField from "../ui/fields/PromptTemplateField";
import { Input } from "../ui/input";

const FINE_TUNE_BASE_MODEL_OPTIONS = [
  { name: "gpt-3.5-turbo-0125", provider: "openai" },
  { name: "gpt-3.5-turbo-1106", provider: "openai" },
  { name: "gpt-3.5-turbo-0613", provider: "openai" },
  { name: "gpt-4-0613", provider: "openai" },
  { name: "gpt-4o-2024-08-06", provider: "openai" },
  { name: "gpt-4o-mini-2024-07-18", provider: "openai" },
];

export function FineTuneStepKickOff({
  navigateFineTuneHome,
  navigateNextStep,
  navigateApiKeys,
  navigateJobInfoModel,
}: {
  navigateFineTuneHome: () => void;
  navigateNextStep: () => void;
  navigateApiKeys: () => void;
  navigateJobInfoModel: (jobId: string, filters: any) => void;
}) {
  const urlParams = useMemo(
    () => new URLSearchParams(window.location.search),
    [],
  );
  const { tags, metadata, favorites, query, scores } =
    parseUrlParams(urlParams);

  const auth = useAuth();
  const userContext = useUser();
  const userToken = auth?.userToken || "";
  const [errorMessage, setErrorMessage] = useState("");

  const [selectedBaseModel, setSelectedBaseModel] = useState(
    FINE_TUNE_BASE_MODEL_OPTIONS[0].name,
  );
  const [selectedModelSuffix, setSelectedModelSuffix] = useState<string>("");
  const [limit, setLimit] = useState<number | undefined>(undefined);
  const [promptTemplate, setPromptTemplate] = useState<
    { id: number; name: string; version_numbers?: number[] } | undefined
  >(undefined);

  const { mutate, data, isLoading, isSuccess } = useFineTunedJob(userToken);

  const [searchParams] = useSearchParams();
  const startTime = searchParams.get(URL_PARAM_KEYS.START_TIME)
    ? new Date(String(searchParams.get(URL_PARAM_KEYS.START_TIME)))
    : undefined;
  const endTime = searchParams.get(URL_PARAM_KEYS.END_TIME)
    ? new Date(String(searchParams.get(URL_PARAM_KEYS.END_TIME)))
    : undefined;

  const promptRegistryObjects = usePromptRegistryObjects(userToken, {
    workspaceId: userContext?.activeWorkspaceId!,
    perPage: Number.MAX_SAFE_INTEGER,
  });

  const promptTemplates =
    promptRegistryObjects?.data?.pages.flatMap((page) => page.items) || [];
  const promptTemplatesIsLoading = promptRegistryObjects.isLoading;

  const handleFineTuneJob = () => {
    let queryParams: { [key: string]: any } = {};
    if (query) queryParams["q"] = query;
    if (tags.length > 0) queryParams["tags_and"] = tags;
    if (metadata.length > 0) queryParams["metadata_and"] = metadata;
    if (scores.length > 0) queryParams["scores"] = scores;
    if (favorites === "true") queryParams["starred"] = favorites;
    if (limit) queryParams["limit"] = limit;
    if (promptTemplate) queryParams["prompt_template"] = promptTemplate;
    if (startTime) queryParams["start_time"] = startTime;
    if (endTime) queryParams["end_time"] = endTime;

    mutate({
      model_suffix: selectedModelSuffix.length > 0 ? selectedModelSuffix : null,
      base_model_name: selectedBaseModel,
      request_query_params: queryParams,
      workspace_id: userContext?.activeWorkspaceId!,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      if (data?.success) {
        const jobId = data?.id;
        const filters = {
          tags,
          metadata,
          favorites,
          query,
          scores,
          limit,
          promptTemplate,
        };
        navigateJobInfoModel(jobId, filters);
      } else {
        let err = data?.message || "Unknown error. Please try again.";
        if (typeof err === "string") {
          setErrorMessage(err);
        } else {
          try {
            setErrorMessage(
              `Validation error for ${err[0]["loc"]}. ${err[0]["msg"]}`,
            );
          } catch (e) {
            setErrorMessage(JSON.stringify(err));
          }
        }
      }
    }
  }, [
    isLoading,
    data,
    navigateJobInfoModel,
    setErrorMessage,
    isSuccess,
    tags,
    metadata,
    favorites,
    query,
    scores,
    limit,
    promptTemplate,
  ]);

  return (
    <>
      <DialogHeader>
        <DialogTitle>Train a New Model</DialogTitle>
        <DialogDescription>
          Fine-tune a new model off of your PromptLayer request data.
          <br />
          <LinkIcon className="mr-1 inline-block h-4 w-4" />
          <a
            className="hover:text-gray-400"
            href="https://platform.openai.com/docs/guides/fine-tuning"
            target="_blank"
            rel="noreferrer"
          >
            Learn more on OpenAI docs.
          </a>
        </DialogDescription>
      </DialogHeader>
      <div className="rounded-sm border border-gray-100 bg-gray-50 px-4">
        <UrlSearchParamsDisplay
          limit={limit}
          metadata_and={metadata}
          prompt_template={promptTemplate}
          q={query || undefined}
          scores={scores}
          starred={favorites === "true"}
          tags_and={tags}
          start_time={startTime}
          end_time={endTime}
        />
      </div>
      <div className="flex flex-col gap-y-2">
        <div className="flex flex-col">
          <label
            htmlFor="model-suffix"
            className="mb-2 text-left text-sm font-semibold text-gray-500"
          >
            Model Suffix:
          </label>
          <Input
            id="model-suffix"
            type="text"
            value={selectedModelSuffix}
            onChange={(e) => setSelectedModelSuffix(e.target.value)}
            placeholder="my_model_name"
            className="col-span-2 rounded-md border border-gray-300 px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="flex flex-col">
          <label
            htmlFor="base-model-selector"
            className="mb-2 text-left text-sm font-semibold text-gray-500"
          >
            Select Base Model:
          </label>
          <Select
            value={selectedBaseModel}
            onValueChange={(value) => setSelectedBaseModel(value)}
          >
            <SelectTrigger
              id="base-model-selector"
              className="col-span-2 rounded-md border border-gray-300 px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              {FINE_TUNE_BASE_MODEL_OPTIONS.map((option) => (
                <SelectItem key={option.name} value={option.name}>
                  {`${option.provider}:${option.name}`}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <LimitField setLimit={setLimit} />
        <PromptTemplateField
          promptTemplate={promptTemplate}
          setPromptTemplate={setPromptTemplate}
          promptTemplatesIsLoading={promptTemplatesIsLoading}
          promptTemplates={promptTemplates}
        />
      </div>
      <Button variant="link" onClick={navigateApiKeys}>
        Configure API Keys
      </Button>
      {errorMessage && (
        <div className="flex flex-row items-start justify-center rounded-md border border-red-100 bg-red-50 p-2">
          <ExclamationCircleIcon className="mx-2 mt-1 h-5 w-5 flex-shrink-0 text-red-500" />
          <p className="text-md overflow-auto text-left text-red-500">
            Error: {errorMessage}
          </p>
        </div>
      )}
      <DialogFooter>
        <Button variant="secondary" onClick={navigateFineTuneHome}>
          Cancel
        </Button>
        {isLoading ? (
          <Button disabled>Loading...</Button>
        ) : (
          <Button onClick={handleFineTuneJob}>Ready to Train!</Button>
        )}
      </DialogFooter>
    </>
  );
}
