import { PlatformGenericLayout } from "@/components/MarketingLayout/Platform/PlatformGenericLayout/PlatformGenericLayout";
import * as S from "./Styles";
import {
  ChartBarIcon,
  ClipboardListIcon,
  DocumentSearchIcon,
  ServerIcon,
  ChartPieIcon,
  LightningBoltIcon,
} from "@heroicons/react/outline";
import observability from "@/assets/hero/observability.png";
import searchUserId from "@/assets/platform_details/search_userid.png";

const features = [
  {
    icon: ChartBarIcon,
    title: "Universal Model Tracking",
    description: "Track usage from any model with elegant data visualization.",
  },
  {
    icon: ClipboardListIcon,
    title: "Complete Metadata and Analytics",
    description:
      "Monitor and Analyze latency, scores, tokens, and custom key-value pairs.",
  },
  {
    icon: DocumentSearchIcon,
    title: "Prompt-Specific Insights",
    description:
      "See which prompts and input variables were used for a request.",
  },
  {
    icon: ServerIcon,
    title: "Full Span Support",
    description:
      "Utilize OpenTelemetry for end-to-end function tracking around LLM calls.",
  },
  {
    icon: ChartPieIcon,
    title: "Fine-Tune Models",
    description:
      "Use historical data to fine-tune models and improve performance.",
  },
  {
    icon: LightningBoltIcon,
    title: "High-Performance Solution",
    description: "Non-proxy design supporting millions of requests daily.",
  },
];
const heroInfo = {
  title: (
    <>
      <span>Complete Observability</span> <br /> Monitor your LLMs
    </>
  ),
  subtitle: (
    <>
      Observe requests, spans, cost and latency in real-time to monitor your
      LLMs.
      <br />
      Understand how your LLMs are performing and where they can be improved.
    </>
  ),
  image: observability,
  promoImage: searchUserId,
};
const promoFeatures = [
  {
    title: "Real-Time Auditing",
    description:
      "Keep an eye on your LLMs with real-time monitoring of requests, spans, cost, and latency.",
  },
  {
    title: "Track Analytics",
    description:
      "Track metrics based on metadata, prompts, time, and model types.",
  },
  {
    title: "Locate Bottlenecks",
    description:
      "Understand where your Prompts are not performing as expected and why.",
  },
  {
    title: "Optimize Costs",
    description: "Understand the cost of your LLMs and how to optimize them.",
  },
];
function Observability() {
  return (
    <S.Container>
      <PlatformGenericLayout
        heroInfo={heroInfo}
        featureTitle={"Observability that Illuminates"}
        features={features}
        promoTitle={"Unravel the mystery of"}
        promoHighlight={"your LLMs"}
        promoDescription={
          "Understand how your users are interacting with your LLMs. Monitor requests, spans, cost, and latency in real-time. Use advanced analytics to analyze metrics."
        }
        promoFeatures={promoFeatures}
      />
    </S.Container>
  );
}

export default Observability;
