export const classNames = (...classes: string[]): string => {
  return classes.filter(Boolean).join(" ");
};

export const toNormalCase = (str: string): string => {
  return str
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, (str) => str.toUpperCase());
};

export const truncate = (str: string, size: number): string => {
  return str.length <= size ? str : `${str.slice(0, size)}...`;
};

export function forceJSONString(input: any, spaces: number = 2): string {
  try {
    let parsed: any;

    // If input is a string, try to parse it as JSON
    if (typeof input === "string") {
      try {
        parsed = JSON.parse(input);
      } catch {
        // If parsing fails, return the original string
        return input;
      }
    } else {
      parsed = input;
    }

    // Custom replacer function to handle circular references
    const seen = new WeakSet();
    const replacer = (key: string, value: any) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return "[Circular]";
        }
        seen.add(value);
      }
      return value;
    };

    // Stringify the input with indentation
    let jsonString = JSON.stringify(parsed, replacer, spaces);

    // Unescape special characters
    jsonString = jsonString
      .replace(/\\n/g, "\n")
      .replace(/\\r/g, "\r")
      .replace(/\\t/g, "\t")
      .replace(/\\"/g, '"')
      .replace(/\\\\/g, "\\");

    // Remove quotes from keys
    jsonString = jsonString.replace(/"([^"]+)":/g, "$1:");

    return jsonString;
  } catch (error) {
    // Handle any unexpected errors
    console.error("Error in stringifyWithIndentation:", error);
    return String(input);
  }
}
