import { forceJSONString } from "@/utils/strings";
import { TerminalIcon } from "@heroicons/react/outline";

export const truncate = (str: string, maxLength: number = 20000) => {
  return str.length > maxLength ? str.substr(0, maxLength - 1) + "..." : str;
};

export const renderFunctionDetails = (name: string, args: string) => (
  <>
    <div className="flex flex-row items-center justify-center pb-1">
      <TerminalIcon className="mr-1 inline-block h-4 w-4" />
      {name}
    </div>
    <pre className=" whitespace-pre-wrap break-words text-gray-700">
      <code>{forceJSONString(args)}</code>
    </pre>
  </>
);
