import { KeyIcon } from "@heroicons/react/outline";
import { zodResolver } from "@hookform/resolvers/zod";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { adminLogin } from "./api/application-api";
import AuthSubmitButton from "./components/AuthSubmitButton";
import { useAuth } from "./context/auth-context";
import { getWorkspaces } from "./queries";

const adminLoginSchema = z.object({
  admin_email: z
    .string()
    .email({ message: "Invalid email format" })
    .nonempty({ message: "Admin email is required" }),
  password: z.string().nonempty({ message: "Password is required" }),
  target_user_email: z
    .string()
    .email({ message: "Invalid email format" })
    .nonempty({ message: "Target user email is required" }),
});

const AdminLogin: FC = () => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const auth = useAuth();
  const navigate = useNavigate();

  const form = useForm({
    defaultValues: {
      admin_email: "",
      password: "",
      target_user_email: "",
    },
    resolver: zodResolver(adminLoginSchema),
  });

  const onSubmit = async (values: any) => {
    setLoading(true);

    try {
      const response: any = await adminLogin({
        admin_email: values.admin_email,
        password: values.password,
        target_user_email: values.target_user_email,
      });

      if (response.ok) {
        const { access_token, user } = await response.json();
        const { workspaces } = await getWorkspaces(access_token);
        if (workspaces.length === 0) {
          setError("The target user does not have access to any workspaces.");
          return;
        }
        const activeWorkspace =
          workspaces.find(({ is_default }) => is_default) || workspaces[0];
        auth?.login(access_token, user);
        navigate(`/workspace/${activeWorkspace.id}/home`);
        return;
      }

      const { message } = await response.json();
      setError(
        typeof message === "string"
          ? message
          : "Unexpected server response format. Please try again later.",
      );
    } catch (err) {
      console.error(err);
      setError("Something went wrong. Please try again later.");
    }

    setLoading(false);
  };

  return (
    <>
      <div className="mx-auto max-w-lg gap-8 rounded-sm border bg-gray-50 px-12 py-5 text-left">
        <div className="text-center text-lg font-light text-gray-500">
          <KeyIcon className="my-4 inline h-5 w-5" aria-hidden="true" />
        </div>
        <form
          className="flex flex-col gap-3 py-3"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <input
            className="rounded-sm border border-gray-400 px-2 py-3"
            type="text"
            placeholder="Enter admin email"
            {...form.register("admin_email")}
          />
          {form.formState.errors.admin_email && (
            <div className="text-sm text-red-500">
              {form.formState.errors.admin_email.message}
            </div>
          )}
          <input
            className="rounded-sm border border-gray-400 px-2 py-3"
            type="password"
            placeholder="Enter admin password"
            {...form.register("password")}
          />
          {form.formState.errors.password && (
            <div className="text-sm text-red-500">
              {form.formState.errors.password.message}
            </div>
          )}
          <input
            className="rounded-sm border border-gray-400 px-2 py-3"
            type="text"
            placeholder="Enter target user email"
            {...form.register("target_user_email")}
          />
          {form.formState.errors.target_user_email && (
            <div className="text-sm text-red-500">
              {form.formState.errors.target_user_email.message}
            </div>
          )}
          {error ? (
            <div className="py-2 text-center text-sm text-black">
              <span className="rounded-md bg-red-200 px-3 py-1">{error}</span>
            </div>
          ) : null}
          <AuthSubmitButton loading={loading} text="Admin Login" />
        </form>
        <div className="text-center font-light text-gray-800">
          Not an admin?{" "}
          <button
            className="text-blue-500 hover:text-blue-400"
            onClick={() => navigate("/login")}
          >
            Go to regular login.
          </button>
        </div>
      </div>
    </>
  );
};

export default AdminLogin;
