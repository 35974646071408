import { ReactNode, FC } from "react";
import { useNavigate } from "react-router-dom";

import { ToastType } from "@/enums";
import * as S from "./Styles";

export interface ToastProps {
  children: ReactNode;
  type: ToastType;
  url?: string;
}

const Toast: FC<ToastProps> = ({ children, type = ToastType.error, url }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (!url) return;
    navigate(url);
  };

  return (
    <S.Container onClick={handleClick} type={type}>
      <S.Text>{children}</S.Text>
    </S.Container>
  );
};

export default Toast;
