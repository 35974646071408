import { FC } from "react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useAuth } from "@/context/auth-context";
import { ToastType } from "@/enums";
import { useDeletePromptLayerApiKey } from "@/queries";
import { displayToast } from "@/utils/toast";
import { useUser } from "@/context/user-context";

export type DeletePromptLayerApiKeyModalProps = {
  open: boolean;
  promptLayerApiKeyId: number;
  setOpen: (open: boolean) => void;
};

const DeletePromptLayerApiKeyModal: FC<DeletePromptLayerApiKeyModalProps> = ({
  promptLayerApiKeyId,
  open,
  setOpen,
}) => {
  const authContext = useAuth();
  const userContext = useUser();
  const workspaceId = userContext?.activeWorkspaceId!;

  const deletePromptLayerApiKey = useDeletePromptLayerApiKey(
    authContext!.userToken!,
    workspaceId,
  );

  const handleClick = async () => {
    try {
      await deletePromptLayerApiKey.mutateAsync(promptLayerApiKeyId);
      displayToast("PromptLayer API key deleted", ToastType.success);
      setOpen(false);
    } catch (error: unknown) {
      console.error("Error deleting PromptLayer API Key:", error);
      displayToast("Error deleting PromptLayer API Key", ToastType.error);
    }
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            Are you sure you want to delete this API key?
          </DialogTitle>
        </DialogHeader>
        <div className="text-gray-700">This action cannot be undone.</div>
        <DialogFooter className="mt-5">
          <Button
            className="bg-transparent text-sm"
            onClick={() => setOpen(false)}
            type="button"
            variant="outline"
          >
            Cancel
          </Button>
          <Button onClick={handleClick} variant="destructive">
            Delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default DeletePromptLayerApiKeyModal;
