import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { ReportColumn } from "@/types/evaluate";
import { PlusIcon, XIcon } from "@heroicons/react/outline";
import { ReactNode, useMemo, useState } from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useConfigureReportScoreCard } from "@/queries";
import { useAuth } from "@/context/auth-context";
import { useReportContext } from "./report-context";
import LoadingSpinner from "../LoadingSpinner";

const ConfigureScoreCardModal = ({ children }: { children: ReactNode }) => {
  const reportContext = useReportContext();
  const [open, setOpen] = useState(false);

  if (reportContext.reportColumnsIsLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>{children}</DialogTrigger>
      <DialogContent>
        <ConfigureScoreCardModalHelper setOpen={setOpen} children={children} />
      </DialogContent>
    </Dialog>
  );
};

const ConfigureScoreCardModalHelper = ({
  children,
  setOpen,
}: {
  children: ReactNode;
  setOpen: (open: boolean) => void;
}) => {
  const reportContext = useReportContext();
  const availableColumns: ReportColumn[] = useMemo(
    () => reportContext.sortedColumns || [],
    [reportContext.sortedColumns],
  );
  const reportId = useMemo(
    () => reportContext.reportId,
    [reportContext.reportId],
  );

  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    availableColumns
      .filter((column) => column.is_part_of_score)
      .map((column) => column.name),
  );

  const userToken = useAuth()?.userToken || "";
  const { mutate: configureScoreCard, isLoading: configureScoreCardIsLoading } =
    useConfigureReportScoreCard(userToken || "", reportId);

  const handleConfigureScoreCard = () => {
    configureScoreCard(selectedColumns, {
      onSuccess: () => {
        setOpen(false); // Close the modal on success
      },
      onError: (error) => {
        console.error("Error saving scorecard configuration:", error);
      },
    });
  };

  const notYetChosenColumns = useMemo(() => {
    return availableColumns.filter(
      (column) => !selectedColumns.includes(column.name),
    );
  }, [selectedColumns, availableColumns]);

  const addColumnName = () => {
    setSelectedColumns([...selectedColumns, ""]);
  };

  const removeColumnName = (index: number) => {
    const newColumnNames = [...selectedColumns];
    newColumnNames.splice(index, 1);
    setSelectedColumns(newColumnNames);
  };

  const updateColumnName = (index: number, newName: string) => {
    const newColumnNames = [...selectedColumns];
    newColumnNames[index] = newName;
    setSelectedColumns(newColumnNames);
  };

  return (
    <div className="p-2">
      <h2 className="text-lg font-semibold">Configure Scorecard</h2>
      <p className="mb-4 text-sm text-gray-500">
        Decide which columns to include in the evaluation scorecard. By default,
        score will be calculated from the last column. All cells must be either
        booleans or numbers.
      </p>
      <div className="flex flex-col gap-y-1">
        <div className="grid grid-cols-3 gap-x-4 pb-6 pt-2">
          <div className="col-span-1">Score columns:</div>
          <div className="col-span-2 flex flex-col gap-y-1">
            {selectedColumns.map((columnName, index) => (
              <div
                key={index}
                className="flex items-center justify-between gap-x-1"
              >
                <DropdownMenu>
                  <DropdownMenuTrigger className="w-full">
                    {columnName || (
                      <span className="font-normal text-gray-500">
                        Select a column...
                      </span>
                    )}
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    {notYetChosenColumns.map((column) => (
                      <DropdownMenuItem
                        key={column.name}
                        onSelect={() => updateColumnName(index, column.name)}
                      >
                        {column.name}
                      </DropdownMenuItem>
                    ))}
                  </DropdownMenuContent>
                </DropdownMenu>
                <XIcon
                  onClick={() => removeColumnName(index)}
                  className="h-5 w-5 cursor-pointer text-red-500"
                />
              </div>
            ))}

            <div className="py-1">
              <Button
                variant="secondaryLightOutline"
                size="sm"
                onClick={addColumnName}
              >
                Add column
                <PlusIcon className="ml-1 h-4 w-4" />
              </Button>
            </div>
          </div>
        </div>
        <Button
          variant={"default"}
          onClick={handleConfigureScoreCard}
          isLoading={configureScoreCardIsLoading}
        >
          Configure
        </Button>
      </div>
    </div>
  );
};

export default ConfigureScoreCardModal;
