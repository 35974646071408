import { useState } from "react";
import {
  CloudUploadIcon,
  QuestionMarkCircleIcon,
  FilmIcon,
  ExternalLinkIcon,
  LightBulbIcon,
} from "@heroicons/react/outline";
import clouds from "@/assets/clouds.png";
import CardContainer from "./CardContainer";
import {
  BatchFeature,
  PromptTemplateFeature,
  InstallingFeature,
  DeployingPromptsFeature,
} from "./Features";

export default function Home() {
  const [selectedCardHeading, setSelectedCardHeading] = useState<string | null>(
    null,
  );

  const handleCardClick = (heading: string) => {
    if (heading === selectedCardHeading) {
      setSelectedCardHeading(null);
    } else {
      setSelectedCardHeading(heading);
    }
  };

  const handleCloseClick = () => {
    setSelectedCardHeading(null);
  };

  const renderBanner = () => {
    return (
      <div className="relative mt-8 flex min-h-[160px] items-center justify-between overflow-visible rounded-lg bg-gray-100 p-4">
        <div className="self-start py-3">
          <h1 className="text-2xl font-medium">Welcome to PromptLayer 🍰</h1>
          <p className="mt-2 text-base font-light italic text-gray-500">
            Tell your friends you're a Prompt Engineer now!
          </p>
          <a
            className="mt-2 inline-block font-medium text-black underline"
            href="https://docs.promptlayer.com/"
            rel="noreferrer"
            target="_blank"
          >
            Read our docs
          </a>
          <ExternalLinkIcon
            aria-hidden="true"
            className="ml-2 inline-block h-4 w-4"
          />
        </div>
        <img
          alt="Clouds"
          className="absolute bottom-0 right-32"
          src={clouds}
          width={400}
        />
      </div>
    );
  };

  return (
    <div className="py-6">
      {renderBanner()}
      <div className="px-2">
        <CardContainer
          handleCardClick={handleCardClick}
          leftCardDetails={{
            buttonText: "Learn more",
            description:
              "Visually edit prompts in our dashboard. Compare versions and see when they are used.",
            detailComponent: (
              <PromptTemplateFeature handleCloseClick={handleCloseClick} />
            ),
            heading: "Create a prompt template",
            iconComponent: (
              <LightBulbIcon
                aria-hidden="true"
                className="h-6 w-6 text-blue-700"
              />
            ),
            subheading: "140k+ templates created",
          }}
          rightCardDetails={{
            buttonText: "Learn more",
            description:
              "Programmatically fetch your templates. Publish new prompts through our dashboard.",
            detailComponent: (
              <DeployingPromptsFeature handleCloseClick={handleCloseClick} />
            ),
            heading: "Deploy templates to production",
            iconComponent: (
              <CloudUploadIcon
                aria-hidden="true"
                className="h-6 w-6 text-blue-700"
              />
            ),
            subheading: "Avoid being blocked by eng releases",
          }}
          selectedCardHeading={selectedCardHeading}
        />

        <CardContainer
          handleCardClick={handleCardClick}
          leftCardDetails={{
            buttonText: "Learn more",
            description:
              "Setup PromptLayer with one line of code. Save all LLM requests and easily search for old ones.",
            detailComponent: (
              <InstallingFeature handleCloseClick={handleCloseClick} />
            ),
            heading: "Log your first request",
            iconComponent: (
              <FilmIcon aria-hidden="true" className="h-6 w-6 text-blue-700" />
            ),
            subheading: "40M+ requests logged",
          }}
          rightCardDetails={{
            buttonText: "Learn more",
            description:
              "Batch run prompts against sample input datasets. Find the best model and prompt for your use case.",
            detailComponent: (
              <BatchFeature handleCloseClick={handleCloseClick} />
            ),
            heading: "Evaluate your prompts",
            subheading: "900k+ tests run",
            iconComponent: (
              <QuestionMarkCircleIcon
                aria-hidden="true"
                className="h-6 w-6 text-blue-700"
              />
            ),
          }}
          selectedCardHeading={selectedCardHeading}
        />
      </div>
    </div>
  );
}
