import { FC, ReactNode, useEffect } from "react";
import { useUser } from "@/context/user-context";
import { tryLoadAndStartRecorder } from "@alwaysmeticulous/recorder-loader";
import { MeticulousProjectId } from "@/api/application-api";

type MeticulousRecorderProps = {
  children: ReactNode;
};

const isProduction = () => {
  return window.location.hostname.indexOf("promptlayer.com") > -1;
};

interface EarlyNetworkRecorderWindow {
  __meticulous?: {
    earlyNetworkRecorder?: {
      stop?: () => Promise<void>;
    };
  };
}

export const MeticulousRecorder: FC<MeticulousRecorderProps> = ({
  children,
}) => {
  const userContext = useUser();
  useEffect(() => {
    if (
      !userContext.workspacePlanName ||
      userContext.subscriptionStatus?.isLoading
    ) {
      return;
    }

    if (
      // both user and workspace are on free plan
      userContext.workspacePlanName === "free" &&
      userContext.userIsFreePlan
    ) {
      tryLoadAndStartRecorder({
        projectId: MeticulousProjectId,
        isProduction: isProduction(),
      });
      console.log("Starting the recorder");
    } else {
      (
        window as EarlyNetworkRecorderWindow
      ).__meticulous?.earlyNetworkRecorder?.stop?.();
      console.log("Stopping the recorder");
    }
  }, [
    userContext.userIsFreePlan,
    userContext.workspacePlanName,
    userContext.subscriptionStatus?.isLoading,
  ]);

  return <>{children}</>;
};
