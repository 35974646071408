import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { FC } from "react";
import { useAuth } from "@/context/auth-context";
import { ToastType } from "@/enums";
import { useDeleteFolder } from "@/queries";
import { Folder } from "@/types/folders";
import { displayToast } from "@/utils/toast";

type DeleteFolderModalProps = {
  folder: Folder;
  open: boolean;
  setOpen: (open: boolean) => void;
};

const DeleteFolderModal: FC<DeleteFolderModalProps> = ({
  folder,
  open,
  setOpen,
}: DeleteFolderModalProps) => {
  const authContext = useAuth();
  const deleteFolder = useDeleteFolder(authContext!.userToken!);

  const handleClick = async () => {
    try {
      await deleteFolder.mutateAsync(folder.id);
      displayToast("Folder deleted", ToastType.success);
      setOpen(false);
    } catch (error: unknown) {
      console.log(error);
    }
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            Are you sure you want to delete this folder?
          </DialogTitle>
        </DialogHeader>
        <div className="text-gray-700">
          Deleting this folder will move its contained prompt templates to home.
          This action cannot be undone.
        </div>
        <DialogFooter className="mt-5">
          <Button
            className="bg-transparent text-sm"
            onClick={() => setOpen(false)}
            type="button"
            variant="outline"
          >
            Cancel
          </Button>
          <Button onClick={handleClick} variant="destructive">
            Delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteFolderModal;
