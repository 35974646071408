import { Button } from "@/components/ui/button";
import { AssistantMessage, ToolCall } from "@/types";
import { PromptVersionSnippet } from "@/types/apiGetters";

import { Cross2Icon } from "@radix-ui/react-icons";
import { Terminal } from "lucide-react";
import { BaseSyntheticEvent, useCallback, useMemo } from "react";
import { Input } from "./ui/input";
import { Textarea } from "./ui/textarea";
const parseDirtyJSON = (text: string) => {
  try {
    return JSON.parse(text || "{}");
  } catch (e) {
    return text;
  }
};
function AddToolCall({
  setToolCall,
}: {
  setToolCall: (toolCall: ToolCall) => void;
}) {
  return (
    <div>
      <Button
        onClick={() =>
          setToolCall({
            id: Math.random().toString(36).substring(7),
            type: "function",
            function: {
              name: "",
              arguments: "",
            },
          })
        }
        variant={"outline"}
        size={"sm"}
      >
        + Tool Call
      </Button>
    </div>
  );
}

export type Props = {
  message: AssistantMessage;
  setMessage?: (message: AssistantMessage) => void;
  sourcedSnippets?: Array<PromptVersionSnippet>;
};

export const ToolCalls = ({ message, setMessage }: Props) => {
  const edit = setMessage !== undefined;

  const setToolCall = useCallback(
    (toolCall: ToolCall) => {
      const newMessage = structuredClone(message);
      if (newMessage.tool_calls) {
        newMessage.tool_calls.push(toolCall);
      } else {
        newMessage.tool_calls = [toolCall];
      }

      if (setMessage) setMessage(newMessage);
    },
    [message, setMessage],
  );

  const hasTools = useMemo(
    () => message.tool_calls && message.tool_calls.length > 0,
    [message.tool_calls],
  );

  const handleChange = useCallback(
    (index: number) => (e: BaseSyntheticEvent) => {
      const key: keyof ToolCall = e.target.name;
      const tool: ToolCall | undefined = message.tool_calls![index];
      if (tool) {
        if (key.includes("function.")) {
          const functionKey = key.split(
            "function.",
          )[1] as keyof ToolCall["function"];
          tool["function"][functionKey] = e.target.value;
        } else tool[key] = e.target.value;
      }
      if (setMessage) setMessage(message);
    },
    [message, setMessage],
  );

  return (
    <div
      className={`${
        edit && "relative ml-9 mr-3 pl-4"
      }  box-border flex h-fit w-auto flex-col justify-center rounded-lg ${
        edit &&
        "before:min-h-3 before:absolute before:-left-5  before:bottom-4 before:-z-0  before:inline-block before:h-full before:w-9 before:border-b-2 before:border-l-2 before:border-gray-200 before:content-['']"
      } ${
        (hasTools && edit && "bg-gray-50 py-4 before:bottom-8") || "-mb-1 mt-2 "
      } `}
    >
      {hasTools && (
        <div className={`mb-1 w-full ${edit && "px-4"}`}>
          {message.tool_calls?.map((toolCall, index) => {
            const onChange = handleChange(index);
            return (
              <div key={`tool_${index}`}>
                <div className="flex items-center justify-between">
                  <div className="font-mono font-bold">
                    <Terminal className="mr-1 inline-block h-4 w-4" />
                    {!edit && toolCall.function.name}
                  </div>

                  {edit ? (
                    <Button
                      variant="destructiveLink"
                      onClick={() => {
                        const newMessage = structuredClone(message);
                        newMessage.tool_calls = newMessage.tool_calls?.filter(
                          (_, curIndex) => curIndex !== index,
                        );
                        if (setMessage) setMessage(newMessage);
                      }}
                    >
                      <Cross2Icon />
                    </Button>
                  ) : null}
                </div>

                <div className="mb-4">
                  {!edit ? (
                    <div className="font-mono ">
                      {JSON.stringify(
                        {
                          id: toolCall.id,
                          arguments: parseDirtyJSON(
                            toolCall.function.arguments,
                          ),
                        },
                        undefined,
                        2,
                      )}
                    </div>
                  ) : (
                    <>
                      <div className="mb-2 flex w-full flex-row">
                        <Input
                          className=" h-9 flex-1 rounded-none border-gray-200 font-mono text-sm focus:ring-0 group-hover:border-gray-300"
                          placeholder="Tool Id"
                          name="id"
                          onChange={onChange}
                          value={toolCall.id}
                        />
                        <Input
                          className=" ml-2 h-9 flex-1 rounded-none border-gray-200 font-mono text-sm focus:ring-0 group-hover:border-gray-300"
                          placeholder="Name"
                          onChange={onChange}
                          name="function.name"
                          value={toolCall.function.name}
                        />
                      </div>
                      <Textarea
                        className=" h-9 w-full rounded-none border-gray-200 font-mono text-sm focus:ring-0 group-hover:border-gray-300"
                        placeholder={"Arguments"}
                        onChange={onChange}
                        name="function.arguments"
                        value={toolCall.function.arguments}
                      />
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
      {edit && <AddToolCall setToolCall={setToolCall} />}
    </div>
  );
};
