import styled from "styled-components";
import { XIcon as UXIcon } from "@heroicons/react/outline";

export const Bottom = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 1rem 1rem;
`;

export const LeftMenu = styled.div`
  background-clip: padding-box;
  background-color: #fff;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  max-width: 100%;
  outline: 0;
  position: fixed;
  top: 0;
  transform: translateX(-100%);
  transition:
    transform 0.3s ease-in-out,
    visibility 0.3s ease-in-out;
  visibility: hidden;
  width: 320px;
  z-index: 1050;

  &.show {
    transform: none;
    visibility: visible;
  }
`;

export const LeftMenuHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 24px 20px 10px;
`;

export const Logo = styled.img`
  max-width: 154px;
`;

export const NavItem = styled.li`
  padding: 10px 10px;

  a {
    font-size: 18px;

    &:hover {
      color: #666666;
    }
  }
`;

export const NavItems = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
`;

export const XIcon = styled(UXIcon)`
  cursor: pointer;
  transition: 0.1s all ease;
  width: 40px;

  &:hover {
    opacity: 0.8;
  }
`;
