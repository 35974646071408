import styled from "styled-components";

import { ToastType } from "@/enums";

export const Container = styled.div<{ type: ToastType }>`
  background-color: ${({ type }) => {
    if (type === ToastType.success) return "#16a34a";
    if (type === ToastType.warning) return "#d97706";
    return "#dc2626";
  }};
  display: flex;
  padding: 12px;
`;

export const Text = styled.span`
  align-items: center;
  color: #fff;
  display: flex;
`;
