import { FC, FormEvent, useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useAuth } from "@/context/auth-context";
import useActiveWorkspace from "@/hooks/useActiveWorkspace";
import { useCreateFolder, useEditFolder } from "@/queries";
import { Folder } from "@/types/folders";
import { displayToast } from "@/utils/toast";
import { ToastType } from "@/enums";

type FolderModalProps = {
  folder?: Folder;
  setOpen: (isOpen: boolean) => void;
};

const FolderModal: FC<FolderModalProps> = ({ folder, setOpen }) => {
  const [name, setName] = useState("");
  const activeWorkspace = useActiveWorkspace();
  const authContext = useAuth();
  const userToken = authContext!.userToken;
  const createFolder = useCreateFolder(userToken!);
  const editFolder = useEditFolder(userToken!);

  useEffect(() => {
    if (folder) {
      setName(folder.name);
    }
  }, [folder]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      if (!folder) {
        await createFolder.mutateAsync({
          name,
          workspace_id: activeWorkspace!.id,
        });
        displayToast("Folder created", ToastType.success);
      } else {
        await editFolder.mutateAsync({
          folder_id: folder.id,
          name,
        });
        displayToast("Folder updated", ToastType.success);
      }
      setOpen(false);
    } catch (error: unknown) {
      console.log(error);
    }
  };

  const renderForm = () => {
    return (
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label
            className="block text-sm font-medium text-gray-700"
            htmlFor="name"
          >
            Name
          </label>
          <input
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            id="name"
            name="name"
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter folder name"
            required
            type="text"
            value={name}
          />
        </div>
        <div className="mt-4 text-center">
          <button
            className="inline-flex justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            type="submit"
          >
            Submit
          </button>
        </div>
      </form>
    );
  };

  return (
    <Dialog open={true} onOpenChange={handleClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{folder ? "Rename" : "Create"} Folder</DialogTitle>
        </DialogHeader>
        {renderForm()}
      </DialogContent>
    </Dialog>
  );
};

export default FolderModal;
