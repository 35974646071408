import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import React from "react";

export const Tooltip = ({
  tipHeader,
  tipBody,
  tipTrigger,
  children,
  side = "top",
  disabled = false,
  className = "",
}: {
  tipHeader?: React.ReactNode;
  tipBody?: React.ReactNode;
  tipTrigger?: React.ReactNode;
  children: React.ReactNode;
  side?: "top" | "left" | "right" | "bottom" | undefined;
  disabled?: boolean;
  className?: string;
}) => {
  return (
    <TooltipPrimitive.Provider delayDuration={0}>
      <TooltipPrimitive.Root delayDuration={0}>
        <div className={`inline-block items-center`}>
          <TooltipPrimitive.Content
            side={side}
            className={`${
              disabled && `hidden`
            } flex flex-col rounded bg-gray-800 px-3 py-2 ${className} `}
          >
            {tipHeader && (
              <h1 className={"text-3xs whitespace-nowrap text-gray-100"}>
                {tipHeader}
              </h1>
            )}
            {tipBody && <p className={"text-3xs text-gray-400"}>{tipBody}</p>}
            {tipTrigger}
            <TooltipPrimitive.Arrow className="fill-gray-800" />
          </TooltipPrimitive.Content>
          <TooltipPrimitive.Trigger asChild>
            {children}
          </TooltipPrimitive.Trigger>
        </div>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  );
};
