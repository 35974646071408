import { useMemo } from "react";

const useDynamicWidth = (value?: string) => {
  // Assign a minimum width to the cell based on the length of the content
  // Column then expands to largest cell min-width
  const cellWidth = useMemo(() => {
    const contentLength = value?.length;

    if (!contentLength || contentLength < 50) {
      return "min-w-[125px] ";
    } else if (contentLength < 100) {
      return "min-w-[200px]";
    } else {
      return "min-w-[300px]";
    }
  }, [value]);

  return cellWidth;
};

export default useDynamicWidth;
