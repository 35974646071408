import { useReportContext } from "@/components/Evaluate/report-context";
import { useAuth } from "@/context/auth-context";

import { usePatchReportCell as usePatchReportCellQuery } from "@/queries";

export const usePatchReportCell = (cellId: number) => {
  const authContext = useAuth();
  const reportContext = useReportContext();
  const { mutate, isLoading } = usePatchReportCellQuery(
    authContext!.userToken!,
    reportContext?.report?.id,
    cellId,
  );

  return { patchReportCell: mutate, isLoading };
};
