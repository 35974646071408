import { URL_PARAM_KEYS } from "@/utils/utils";

export const handleAddMetadataToSearchParams = (
  searchParams: URLSearchParams,
  setSearchParams: (
    params:
      | URLSearchParams
      | ((prevParams: URLSearchParams) => URLSearchParams),
  ) => void,
  key: string,
  value: string,
) => {
  const metadataParam = searchParams.get(URL_PARAM_KEYS.METADATA) ?? "[]";
  const metadataFields = JSON.parse(metadataParam) as Array<{
    key: string;
    value: string;
  }>;
  setSearchParams((previous) => {
    previous.set(
      URL_PARAM_KEYS.METADATA,
      JSON.stringify([...metadataFields, { key, value }]),
    );
    return previous;
  });
};
