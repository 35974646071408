import { Line } from "react-chartjs-2";
import { useQuery } from "@tanstack/react-query";
import type { ChartOptions } from "chart.js";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
} from "chart.js";
import "./App.css";
import { ENDPOINTS } from "@/api/application-api";
import LoadingSpinner from "@/components/LoadingSpinner";

const DASHBOARD = "/home";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  ArcElement,
  BarElement,
);

type ResultItem = {
  day: string;
  gpt_4: number | null;
  gpt_3_5_turbo: number | null;
  text_davinci_003: number | null;
  claude_2_0: number | null;
  gpt_3_5_turbo_0613: number | null;
  gpt_3_5_turbo_16k: number | null;
  gpt_3_5_turbo_16k_0613: number | null;
  gpt_4_0314: number | null;
  gpt_4_0613: number | null;
};
const LineChart = ({ data }: { data: ResultItem[] }) => {
  const chartData = {
    labels: data?.map((item: ResultItem) => item.day),
    datasets: [
      {
        label: "GPT-4",
        data: data?.map((item: ResultItem) => item.gpt_4),
        borderColor: "#9333EA", // Tailwind's purple-500
        backgroundColor: "rgba(147, 51, 234, 0.1)",
        pointBackgroundColor: "#9333EA",
      },
      {
        label: "GPT-3.5 Turbo",
        data: data?.map((item: ResultItem) => item.gpt_3_5_turbo),
        borderColor: "#22D3EE", // Tailwind's lightBlue-500
        backgroundColor: "rgba(34, 211, 238, 0.1)",
        pointBackgroundColor: "#22D3EE",
      },
      {
        label: "GPT-3.5 Turbo 16k",
        data: data?.map((item: ResultItem) => item.gpt_3_5_turbo_16k),
        borderColor: "#6B7280", // Tailwind's coolGray-500
        backgroundColor: "rgba(107, 114, 128, 0.1)",
        pointBackgroundColor: "#6B7280",
      },
    ],
  };
  const options: ChartOptions<"line"> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
    },
    scales: {
      x: {
        grid: {
          // display: false,
          color: "rgba(128, 128, 128, 0.1)",
        },
        title: {
          display: true,
          text: "Day",
        },
      },
      y: {
        grid: {
          color: "rgba(128, 128, 128, 0.1)",
        },
        title: {
          display: true,
          text: "Average Latency (s)",
        },
      },
    },
  };

  return (
    <div className="mx-auto mt-10 max-w-7xl">
      <div className="mx-auto w-full lg:w-4/5">
        <div style={{ height: "50vh" }}>
          <Line data={chartData} options={options} />
        </div>
      </div>
    </div>
  );
};

export default function Latency() {
  const { data: chartData, isLoading: loading } = useQuery(
    [ENDPOINTS.get_global_latency_public],
    () => fetch(ENDPOINTS.get_global_latency_public).then((res) => res.json()),
  );
  return (
    <div
      className="relative overflow-hidden"
      style={{
        fontFamily:
          '"HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif',
      }}
    >
      <div className="mx-auto max-w-7xl pt-8 text-center sm:pt-10">
        <h1 className="text-4xl font-normal leading-8 tracking-wide text-gray-700 sm:text-3xl md:text-4xl">
          GPT Latency
        </h1>
        <div
          className="mx-auto mt-3 max-w-md px-4 text-base font-normal tracking-wide text-gray-500 sm:px-0 sm:text-lg md:mt-5 md:max-w-xl md:text-lg"
          style={{ lineHeight: 1.6 }}
        >
          Understand OpenAI slowdowns using average user latency data.
        </div>
        <div className="2xl:mt-18 mx-auto mt-10 max-w-7xl">
          {loading || !chartData ? (
            <div className="pb-10 pt-5">
              <LoadingSpinner size={14} />
            </div>
          ) : chartData.success === false ? (
            <div>Data is loading, please come back in five minutes.</div>
          ) : (
            <LineChart data={chartData} />
          )}
        </div>
        <div className="mx-auto mb-28 mt-10 max-w-md 2xl:mt-16">
          <div
            className="mx-auto mt-3 px-4 text-base font-normal tracking-wide text-gray-500 sm:px-0 sm:text-lg md:mt-5 md:text-lg"
            style={{ lineHeight: 1.6 }}
          >
            PromptLayer helps you track LLM performance, lower latency, and
            manage spend.
          </div>
          <div className="mt-12">
            <a
              href={DASHBOARD}
              className="border-1 text-md hover:border-black-500 group rounded-sm border border-gray-400 bg-white px-10 py-5 tracking-wide text-gray-900 drop-shadow-md hover:bg-gray-50 hover:text-black"
            >
              <span className="group-hover:bg-blue-100">
                Signup for free 🍰
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
