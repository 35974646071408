import { FC } from "react";
import * as S from "./Styles";
import soc2Logo from "@/assets/SOC 2.png";
import backgroundGraphic from "@/assets/privacy/background-graphic.png";

const Privacy: FC = () => {
  return (
    <S.Container>
      <S.InnerContainer>
        <S.Content>
          <S.Card backgroundImage={backgroundGraphic}>
            <S.TextContent>
              <h2>A high bar for privacy and security</h2>
              <p>
                Our customers work with extremely sensitive data. We take that
                responsibility seriously, and our security measures exceed
                industry standards. We are SOC 2 Type 2 compliant.
              </p>
              <a href="/privacy_policy.pdf">
                <S.TextLink>Privacy Policy</S.TextLink>
              </a>
            </S.TextContent>
            <S.LogoContent>
              <S.LogoWrapper>
                <img src={soc2Logo} alt="SOC 2 Logo" />
              </S.LogoWrapper>
              <S.LogoText>SOC 2 Type 2</S.LogoText>
            </S.LogoContent>
          </S.Card>
        </S.Content>
      </S.InnerContainer>
    </S.Container>
  );
};

export default Privacy;
