import { FC, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useAuth } from "@/context/auth-context";
import { useSetDraftDatasetStateFromDataset } from "@/queries";
import { DatasetRead } from "@/types/datasets";

type OverwriteDraftDatasetModalProps = {
  datasets: DatasetRead[];
  setOpen: (isOpen: boolean) => void;
};

const OverwriteDraftDatasetModal: FC<OverwriteDraftDatasetModalProps> = ({
  datasets,
  setOpen,
}) => {
  const { datasetId, datasetGroupId, workspaceId } = useParams();
  const authContext = useAuth();
  const navigate = useNavigate();
  const userToken = authContext!.userToken;
  const { mutateAsync: setDraftDatasetStateFromDataset, isLoading } =
    useSetDraftDatasetStateFromDataset(userToken!);

  const draftDatasetId = useMemo(() => {
    const draftDataset = datasets.find(
      ({ version_number }: DatasetRead) => version_number === -1,
    );
    return draftDataset?.id;
  }, [datasets]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOverwriteClick = async () => {
    if (!draftDatasetId) return;

    await setDraftDatasetStateFromDataset({
      dataset_id: parseInt(datasetId!, 10),
    });
    navigate(
      `/workspace/${workspaceId}/dataset-groups/${datasetGroupId}/dataset/${draftDatasetId}`,
    );
    setOpen(false);
  };

  return (
    <Dialog open={true} onOpenChange={handleClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Confirm Overwrite</DialogTitle>
        </DialogHeader>
        <>
          <p className="text-sm text-gray-700">
            There is already data in your draft dataset. Are you sure you want
            to overwrite this data?
          </p>
          <div className="mt-2 flex items-center justify-center">
            <Button variant={"outline"} onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button
              className="ml-2"
              isLoading={isLoading}
              onClick={handleOverwriteClick}
            >
              Yes, overwrite
            </Button>
          </div>
        </>
      </DialogContent>
    </Dialog>
  );
};

export default OverwriteDraftDatasetModal;
