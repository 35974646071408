import { json } from "@/schemas";
import { removeTrailingCommas } from "@/utils/utils";
import { z } from "zod";

const functionCall = z.object({
  name: z.string(),
  arguments: z.string(),
});

const roles = ["user", "assistant", "system", "function"] as const;

const message = z
  .object({
    role: z.enum(roles),
    function_call: functionCall.nullable().default(null),
    content: z.string().default(""),
    name: z
      .string()
      .max(64)
      .regex(/^[a-zA-Z0-9_]*/)
      .nullable()
      .default(null),
  })
  .refine((value) => (value.role === "function" ? value.name !== null : true), {
    message: "role function requires name",
  });

const _function = z
  .object({
    name: z.string().min(1).default(""),
    description: z.string().default(""),
    parameters: json.schema
      .default({ type: "object", properties: {} })
      .refine((value) => {
        if (typeof value === "object") return true;
        try {
          JSON.parse(removeTrailingCommas(value.toString()));
          return true;
        } catch {
          return false;
        }
      })
      .transform((value) => {
        if (typeof value === "object") return value;
        return JSON.parse(removeTrailingCommas(value.toString()));
      }),
  })
  .refine((value) => !["auto", "none"].includes(value.name), {
    path: ["name"],
    message: "function name cannot be auto or none",
  });

type _Function = z.infer<typeof _function>;
type Role = (typeof roles)[number];

export { Role, _Function, _function, message, roles };
