import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { useMemo } from "react";

const useDefaultWorkspace = () => {
  const auth = useAuth();
  const userContext = useUser();

  return useMemo(() => {
    const workspaces = userContext.workspaces;
    const workspaceMembers = userContext.workspaceMembers;

    if (!workspaces.length || !workspaceMembers.length) return null;

    const adminWorkspaceIds = workspaceMembers
      .filter(({ is_admin, user_id }) => is_admin && user_id === auth?.user?.id)
      .map(({ workspace_id }) => workspace_id);

    return workspaces.find(
      ({ id, is_default }) => is_default && adminWorkspaceIds.includes(id),
    );
  }, [auth, userContext.workspaces, userContext.workspaceMembers]);
};

export default useDefaultWorkspace;
