import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ColumnType, ReportColumn } from "@/types/evaluate";

export const ChooseColumnType = ({
  patchColumnData,
  columnData,
  editable,
}: {
  patchColumnData: (
    data:
      | Partial<ReportColumn>
      | ((prevState: Partial<ReportColumn>) => Partial<ReportColumn>),
  ) => void;
  columnData: Partial<ReportColumn>;
  editable: boolean;
}) => {
  const columnTypeDisplayNameMap: Record<string, string> = {
    [ColumnType.PROMPT_TEMPLATE]: "Prompt Template",
    [ColumnType.ENDPOINT]: "Custom API Endpoint",
    [ColumnType.HUMAN]: "Human Input",
    [ColumnType.COMPARE]: "Equality Comparison",
    [ColumnType.CONTAINS]: "Contains Value",
    [ColumnType.REGEX]: "Regex Match",
    [ColumnType.ABSOLUTE_NUMERIC_DISTANCE]: "Absolute Numeric Distance",
    [ColumnType.JSON_PATH]: "JSON Extraction",
    [ColumnType.PARSE_VALUE]: "Parse Value",
    [ColumnType.LLM_ASSERTION]: "Run LLM Assertion",
    [ColumnType.VARIABLE]: "Static Value",
    [ColumnType.COALESCE]: "Coalesce",
    [ColumnType.ASSERT_VALID]: "Type Validation",
    [ColumnType.COSINE_SIMILARITY]: "Cosine Similarity",
    [ColumnType.COUNT]: "Count",
    [ColumnType.MATH_OPERATOR]: "Comparison Operator",
    [ColumnType.XML_PATH]: "XML Extraction",
  };

  const columnTypeDescriptions: Record<string, string> = {
    [ColumnType.PROMPT_TEMPLATE]: "✨ Run a prompt through an LLM.",
    [ColumnType.ENDPOINT]: "🔗 Send data to your URL endpoint.",
    [ColumnType.HUMAN]: "✍️ Let a human fill in the data.",
    [ColumnType.COMPARE]: "",
    [ColumnType.COALESCE]: "",
    [ColumnType.ABSOLUTE_NUMERIC_DISTANCE]: "",
    [ColumnType.REGEX]: "",
    [ColumnType.JSON_PATH]: "",
    [ColumnType.PARSE_VALUE]: "",
    [ColumnType.CONTAINS]: "",
    [ColumnType.LLM_ASSERTION]: "",
    [ColumnType.VARIABLE]: "",
    [ColumnType.ASSERT_VALID]: "",
    [ColumnType.COSINE_SIMILARITY]: "",
    [ColumnType.COUNT]: "",
    [ColumnType.MATH_OPERATOR]: "",
    [ColumnType.XML_PATH]: "",
  };

  const primaryColumnTypes = [
    ColumnType.PROMPT_TEMPLATE,
    ColumnType.ENDPOINT,
    ColumnType.HUMAN,
  ];

  const simpleEvalColumnTypes = [
    ColumnType.COMPARE,
    ColumnType.CONTAINS,
    ColumnType.REGEX,
    ColumnType.ABSOLUTE_NUMERIC_DISTANCE,
  ];

  const dataManipulationColumnTypes = [
    ColumnType.JSON_PATH,
    ColumnType.PARSE_VALUE,
    ColumnType.VARIABLE,
    ColumnType.COALESCE,
    ColumnType.ASSERT_VALID,
    ColumnType.COUNT,
    ColumnType.MATH_OPERATOR,
    ColumnType.XML_PATH,
  ];

  const llmEvalColumnTypes = [
    ColumnType.LLM_ASSERTION,
    ColumnType.COSINE_SIMILARITY,
  ];

  const currentChoiceDisplayName = columnData?.column_type
    ? columnTypeDisplayNameMap[columnData?.column_type]
    : null;

  const columnDropdownItem = (key: string, value: string, styles?: string) => (
    <DropdownMenuItem
      key={key}
      onSelect={() => patchColumnData({ column_type: key as ColumnType })}
    >
      <div className={`${styles} flex flex-col`}>
        <div>{value}</div>
        <div className="text-xs text-gray-500">
          {columnTypeDescriptions[key]}
        </div>
      </div>
    </DropdownMenuItem>
  );

  return (
    <div>
      <div className="text-lg font-medium">Choose step type</div>
      <div className="grid grid-cols-3 items-center gap-4 py-4">
        <div className="text-md col-span-1">Type:</div>
        <div className="col-span-2">
          <DropdownMenu>
            <DropdownMenuTrigger disabled={!editable} className="w-full">
              {currentChoiceDisplayName || (
                <span className="font-normal text-gray-500">
                  Select step type...
                </span>
              )}
            </DropdownMenuTrigger>
            <DropdownMenuContent className="overflow-au">
              {Object.entries(columnTypeDisplayNameMap)
                .filter(([key]) =>
                  primaryColumnTypes.includes(key as ColumnType),
                )
                .map(([key, value]) => columnDropdownItem(key, value))}
              <div className="mt-1 px-2 py-1 text-xs font-medium text-gray-500 ">
                Simple Evals
              </div>
              {Object.entries(columnTypeDisplayNameMap)
                .filter(([key]) =>
                  simpleEvalColumnTypes.includes(key as ColumnType),
                )
                .map(([key, value]) => columnDropdownItem(key, value, "pl-2"))}
              <div className="mt-1 px-2 py-1 text-xs font-medium text-gray-500 ">
                LLM Evals
              </div>
              {Object.entries(columnTypeDisplayNameMap)
                .filter(([key]) =>
                  llmEvalColumnTypes.includes(key as ColumnType),
                )
                .map(([key, value]) => columnDropdownItem(key, value, "pl-2"))}
              <div className="mt-1 px-2 py-1 text-xs font-medium text-gray-500 ">
                Helper Functions
              </div>
              {Object.entries(columnTypeDisplayNameMap)
                .filter(([key]) =>
                  dataManipulationColumnTypes.includes(key as ColumnType),
                )
                .map(([key, value]) => columnDropdownItem(key, value, "pl-2"))}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
    </div>
  );
};
