import { openai } from "@/schemas";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { MenuIcon, PencilIcon, XIcon } from "@heroicons/react/outline";
import { FunctionDefinitionBox } from "./FunctionDefinitionBox";
type IdentifiableFunction = openai._Function & { id: string };
type FunctionsListProps = {
  functions: IdentifiableFunction[];
  isEditing: boolean;
  setEditingFunction: (editingFunction: IdentifiableFunction | null) => void;
  setFunctions?: ((functions: IdentifiableFunction[]) => void) | undefined;
  setIsAddingFunction: (isAdding: boolean) => void;
};

const FunctionElement = ({
  _function,
  isEditing,
  setEditingFunction,
  setIsAddingFunction,
  draggableIcon,
  onNavigateToFunction,
}: {
  _function: IdentifiableFunction;
  isEditing: boolean;
  setEditingFunction: (editingFunction: IdentifiableFunction | null) => void;
  setIsAddingFunction: (isAdding: boolean) => void;
  draggableIcon: JSX.Element;
  onNavigateToFunction: () => void;
}) => (
  <div className="flex items-center gap-x-4 pb-2">
    {isEditing ? draggableIcon : null}
    <div className="flex-1">
      <FunctionDefinitionBox
        functionName={_function.name}
        functionDeclaration={_function}
      />
    </div>
    {isEditing ? (
      <>
        <button
          onClick={() => {
            setEditingFunction(_function);
            setIsAddingFunction(true);
          }}
          className="text-gray-600 hover:text-gray-500"
        >
          <PencilIcon className="h-4 w-4" />
        </button>
        <button
          onClick={onNavigateToFunction}
          className="text-red-400 hover:text-red-500"
        >
          <XIcon className="h-4 w-4" />
        </button>
      </>
    ) : null}
  </div>
);

export const FunctionsList = ({
  functions,
  isEditing,
  setEditingFunction,
  setFunctions,
  setIsAddingFunction,
}: FunctionsListProps) => {
  const countFunctions = functions.length;

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const items = Array.from(functions);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    if (setFunctions !== undefined) {
      // Only undefined when `isEditing` is false
      setFunctions(items);
    }
  };

  if (countFunctions === 0) {
    return (
      <div className="pb-3 pt-1 text-sm italic text-gray-500">
        No functions defined
      </div>
    );
  } else {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="functions">
          {(provided: any) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="py-2"
            >
              {functions.map((_function, index) => (
                <Draggable
                  key={`${_function.name}-${index}`}
                  draggableId={_function.name}
                  index={index}
                >
                  {(provided: any) => (
                    <div ref={provided.innerRef} {...provided.draggableProps}>
                      <FunctionElement
                        _function={_function}
                        isEditing={isEditing}
                        setEditingFunction={setEditingFunction}
                        setIsAddingFunction={setIsAddingFunction}
                        onNavigateToFunction={() =>
                          setFunctions!(functions.filter((_, i) => i !== index))
                        }
                        draggableIcon={
                          <div {...provided.dragHandleProps}>
                            <MenuIcon className="h-4 w-4 cursor-pointer text-gray-400" />
                          </div>
                        }
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
};
