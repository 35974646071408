import { useEffect, useRef } from "react";

const AnimatedCheckIcon: React.FC = () => {
  const circleRef = useRef<SVGPathElement | null>(null);
  const checkRef = useRef<SVGPathElement | null>(null);

  useEffect(() => {
    if (circleRef.current && checkRef.current) {
      const circlePath: SVGPathElement = circleRef.current;
      const checkPath: SVGPathElement = checkRef.current;

      circlePath.style.strokeDasharray = circlePath.getTotalLength().toString();
      circlePath.style.strokeDashoffset = circlePath
        .getTotalLength()
        .toString();

      checkPath.style.strokeDasharray = checkPath.getTotalLength().toString();
      checkPath.style.strokeDashoffset = checkPath.getTotalLength().toString();

      setTimeout(() => {
        circlePath.style.transition = "stroke-dashoffset 0.5s ease-in-out";
        circlePath.style.strokeDashoffset = "0";

        setTimeout(() => {
          checkPath.style.transition = "stroke-dashoffset 0.3s ease-in-out";
          checkPath.style.strokeDashoffset = "0";
        }, 500);
      }, 100);
    }
  }, []);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 24 24"
      fill="none"
      stroke="#22c55e"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="animated-check-icon"
    >
      <path
        ref={circleRef}
        d="M22 11.08V12a10 10 0 1 1-5.93-9.14"
        className="circle"
      />
      <path ref={checkRef} d="m9 11 3 3L22 4" className="check" />
    </svg>
  );
};

export default AnimatedCheckIcon;
