import { z } from "zod";

const literal = z.union([z.string(), z.number(), z.boolean(), z.null()]);
type Literal = z.infer<typeof literal>;
type Json = Literal | { [key: string]: Json } | Json[];
const schema: z.ZodType<Json> = z.lazy(() =>
  z.union([literal, z.array(schema), z.record(schema)]),
);

export { schema };
