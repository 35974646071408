import { Button, ButtonProps } from "@/components/ui/button";
import { Input as BaseInput, InputProps } from "@/components/ui/input";
import * as React from "react";
import { cn } from "../lib/utils";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  initialValue: string;
  onEdit?: (value: string) => void;
}

const InlineEditContext = React.createContext<{
  value: string;
  setValue: (value: string) => void;
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
  onEdit?: (value: string) => void;
} | null>(null);

const useInlineEdit = () => {
  const context = React.useContext(InlineEditContext);
  if (!context) {
    throw new Error("useInlineEdit should be used within InlineEditProvider");
  }
  return context;
};

const Rename = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const { setIsEditing, isEditing, onEdit, value } = useInlineEdit();
    if (!isEditing) return null;
    return (
      <Button
        ref={ref}
        size="sm"
        {...props}
        onClick={() => {
          setIsEditing(false);
          onEdit?.(value);
        }}
      />
    );
  },
);

const Cancel = React.forwardRef<React.ElementRef<"button">, ButtonProps>(
  (props, ref) => {
    const { setIsEditing, isEditing } = useInlineEdit();
    if (!isEditing) return null;
    return (
      <Button
        ref={ref}
        size="sm"
        variant="outline"
        {...props}
        onClick={() => {
          setIsEditing(false);
        }}
      />
    );
  },
);

const Input = React.forwardRef<React.ElementRef<"input">, InputProps>(
  (props, ref) => {
    const { value, setValue, isEditing, setIsEditing, onEdit } =
      useInlineEdit();
    if (!isEditing) return null;

    return (
      <BaseInput
        ref={ref}
        autoFocus
        {...props}
        value={value}
        onChange={(event) => setValue(event.target.value)}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            setIsEditing(false);
            onEdit?.(value);
          }
          if (event.key === "Escape") {
            event.preventDefault();
            setIsEditing(false);
          }
        }}
      />
    );
  },
);

const Trigger = React.forwardRef<
  React.ElementRef<"div">,
  React.HTMLAttributes<HTMLDivElement>
>(({ onDoubleClick, className, ...props }, ref) => {
  const { isEditing, setIsEditing } = useInlineEdit();
  if (isEditing) return null;
  return (
    <div
      ref={ref}
      {...props}
      onDoubleClick={() => {
        setIsEditing(true);
      }}
    />
  );
});

const Root = React.forwardRef<HTMLDivElement, Props>(
  ({ initialValue, onEdit, className, children, ...props }, ref) => {
    const [value, setValue] = React.useState(initialValue);
    const [isEditing, setIsEditing] = React.useState(false);

    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    return (
      <InlineEditContext.Provider
        value={{ value, setValue, isEditing, setIsEditing, onEdit }}
      >
        <div
          ref={ref}
          className={cn("flex items-center space-x-2 px-1", className)}
          {...props}
        >
          {children}
        </div>
      </InlineEditContext.Provider>
    );
  },
);

export { Cancel, Input, Rename, Root, Trigger };
