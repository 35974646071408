import { useParams } from "react-router-dom";
import CreatePromptTemplate from "./CreatePromptTemplate";
import LoadingSpinner from "./components/LoadingSpinner";
import NotFoundMessage from "./components/ui/not-found";
import { useAuth } from "./context/auth-context";
import { useUser } from "./context/user-context";
import { usePromptRegistry, usePromptVersion } from "./queries";

export default function EditPromptTemplate() {
  // Get ID from URL requestId
  const { promptId, versionNumber } = useParams();
  const promptRegistryId = Number(promptId);
  const promptVersionNumber = Number(versionNumber);
  const userContext = useUser();
  const auth = useAuth();
  const userToken = auth?.userToken!;
  const promptRegistryQuery = usePromptRegistry(userToken, promptRegistryId);
  const promptVersionQuery = usePromptVersion(
    userToken,
    promptRegistryId,
    promptVersionNumber,
  );
  const promptInfo = promptRegistryQuery.data;
  const promptTemplateVersion = promptVersionQuery.data;

  if (promptRegistryQuery.isLoading || promptVersionQuery.isLoading) {
    return <LoadingSpinner size={5} />;
  }
  if (!promptId) {
    return (
      <NotFoundMessage
        title="Template not found"
        subtitle="We couldn't find the prompt template you were looking for."
        backLink="/prompt"
        backLinkMessage="Back to Prompt Registry"
      />
    );
  }

  if (!promptInfo) {
    return (
      <NotFoundMessage
        title="Template not found"
        subtitle="We couldn't find the prompt template you were looking for."
        backLink="/prompt"
        backLinkMessage="Back to Prompt Registry"
      />
    );
  }

  if (!promptTemplateVersion) {
    return (
      <NotFoundMessage
        title="Template version not found"
        subtitle="We couldn't find the prompt template version you were looking to edit."
        backLink={`/workspace/${userContext?.activeWorkspaceId}/prompt/${promptId}`}
        backLinkMessage="Back to Prompt Template"
      />
    );
  }

  return (
    <CreatePromptTemplate
      startingPromptTemplate={promptInfo}
      startingPromptTemplateVersion={promptTemplateVersion}
      editMode
    />
  );
}
