import { Button } from "@/components/ui/button";
import { classNames } from "@/utils/strings";
import { Listbox, Transition } from "@headlessui/react";
import { TagIcon } from "@heroicons/react/outline";
import { Fragment, useState } from "react";

export default function PromptTemplateTagsEditor({
  templateTags,
  tags,
  setTags,
}: {
  templateTags: string[];
  tags: string[];
  setTags: (tags: string[]) => void;
}) {
  const possibleTags = [...new Set(templateTags.concat(tags))].sort((a, b) =>
    a.localeCompare(b),
  );
  const [newTag, setNewTag] = useState("");

  const handleNewTag = () => {
    if (newTag.length > 0) {
      if (!tags.includes(newTag)) {
        handleTagClick(newTag);
      }
    }
    setNewTag("");
  };

  const handleTagClick = (tag: string) => {
    if (tags.includes(tag)) {
      setTags(tags.filter((t: string) => t !== tag));
    } else {
      setTags([...tags, tag]);
    }
  };
  return (
    <div>
      <Listbox
        as="div"
        value={tags}
        onChange={(tagsList: string[]) => tagsList.forEach(handleTagClick)}
        className="flex-shrink-0"
      >
        {({ open }) => (
          <>
            <Listbox.Label className="sr-only"> Tags </Listbox.Label>
            <div className="relative">
              <Listbox.Button className="relative inline-flex items-center whitespace-nowrap rounded-full bg-gray-50 px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 sm:px-3">
                {tags.length === 0 ? (
                  <TagIcon
                    className="h-5 w-5 flex-shrink-0 text-gray-300 sm:-ml-1"
                    aria-hidden="true"
                  />
                ) : (
                  <TagIcon
                    className="h-5 w-5 flex-shrink-0 text-blue-700 sm:-ml-1"
                    aria-hidden="true"
                  />
                )}

                <span
                  className={classNames(
                    tags.length === 0 ? "" : "text-blue-700",
                    "hidden truncate sm:ml-2 sm:block",
                  )}
                >
                  {tags.length === 0 ? "No Tags" : "Tags"}
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  key={"__select_tag"}
                  className="absolute z-40 mb-1 mt-1 max-h-56 w-52 overflow-auto rounded-lg bg-white py-3 text-base shadow ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                >
                  <div className="px-3 pb-2">
                    <input
                      type="text"
                      placeholder="Add a tag..."
                      className="w-full rounded-md border border-gray-300 px-2 py-1"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleNewTag();
                          e.preventDefault();
                        }
                      }}
                      value={newTag}
                      onChange={(e) => setNewTag(e.target.value)}
                    />
                    <div className="text-center">
                      <Button
                        className="mt-2"
                        onClick={(e) => {
                          handleNewTag();
                          e.preventDefault();
                        }}
                        size="sm"
                      >
                        Create Tag
                      </Button>
                    </div>
                  </div>
                  {possibleTags.map((tag: string) => (
                    <Listbox.Option
                      key={tag}
                      className={({ active }) =>
                        classNames(
                          active ? "bg-gray-100" : "bg-white",
                          "relative cursor-default select-none px-3 py-2",
                        )
                      }
                      value={[tag]}
                    >
                      <div className="flex items-center">
                        <input
                          id={tag}
                          type="checkbox"
                          checked={tags.includes(tag)}
                          readOnly
                          className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                        />
                        <span className="ml-3 block truncate font-medium">
                          {tag}
                        </span>
                      </div>
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
}
