import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { useDeleteReport } from "@/queries";
import { useNavigate } from "react-router-dom";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  reportName: string;
  reportId: number;
};

export const DeleteReport = (props: Props) => {
  const authContext = useAuth();
  const userToken = authContext?.userToken || "";
  const activeWorkspaceId = useUser()?.activeWorkspaceId;
  const navigate = useNavigate();
  const { mutate: deleteReport, isLoading: deleteReportIsLoading } =
    useDeleteReport(userToken, props.reportId);

  const onSubmit = async () => {
    deleteReport();
    navigate(`/workspace/${activeWorkspaceId}/evaluate`);
    props.setOpen(false);
  };

  return (
    <Dialog open={props.open} onOpenChange={props.setOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            Are you sure you want to archive this report?
          </DialogTitle>
        </DialogHeader>
        <DialogDescription>
          Report "
          <span className="font-mono font-semibold">{`${props.reportName}`}</span>
          " will be archived.
        </DialogDescription>
        <DialogFooter className="mt-5">
          <Button
            type="button"
            onClick={() => props.setOpen(false)}
            disabled={deleteReportIsLoading}
            variant="outline"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            onClick={onSubmit}
            variant="destructive"
            isLoading={deleteReportIsLoading}
          >
            Archive Report
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
