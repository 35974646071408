import styled from "styled-components";

import { fonts } from "styles";

export const Container = styled.div`
  font-family: ${fonts.family.marketing};
`;

export const NotFoundWrapper = styled.div`
  margin: 120px 0;
`;

export const Overlay = styled.div<{
  $menuOpen: boolean;
}>`
  background: rgba(0, 0, 0, 0.7);
  bottom: 0;
  left: 0;
  opacity: ${({ $menuOpen }) => ($menuOpen ? 1 : 0)};
  position: fixed;
  right: 0;
  top: 0;
  transition:
    opacity 0.3s ease,
    visibility 0.3s ease;
  visibility: ${({ $menuOpen }) => ($menuOpen ? "visible" : "hidden")};
  z-index: 1000;
`;
