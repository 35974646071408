import { useMemo, useState } from "react";
import {
  DocumentReportIcon,
  EmojiSadIcon,
  SearchIcon,
} from "@heroicons/react/outline";

import { useUser } from "@/context/user-context";
import { DatasetGroupRead } from "@/types/dataset-groups";

interface DatasetGroupGridProps {
  handleDatasetGroupClick: (datasetGroupId: number) => void;
}

const NewDatasetGroupGrid = ({
  handleDatasetGroupClick,
}: DatasetGroupGridProps) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const userContext = useUser();

  const datasetGroupList = useMemo(() => {
    return userContext.newDatasetGroups
      .filter(
        ({ name }) =>
          !searchQuery ||
          name.toLowerCase().includes(searchQuery.toLowerCase()),
      )
      .sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
      );
  }, [searchQuery, userContext.newDatasetGroups]);

  const renderContent = () => {
    if (datasetGroupList.length === 0) {
      return (
        <div className="flex h-full flex-col items-center justify-center">
          <div className="pt-8 text-2xl text-gray-500">
            No datasets found{" "}
            <EmojiSadIcon className="-mt-1 inline h-6 w-auto text-gray-500" />
          </div>
        </div>
      );
    }

    return renderDatasetGroupGrid();
  };

  const renderDatasetGroupGrid = () => {
    return (
      <ul className="mb-5 mt-4 flex flex-col gap-2 pb-3">
        {datasetGroupList.map(renderDatasetGroupGridItem)}
      </ul>
    );
  };

  const renderDatasetGroupGridItem = (datasetGroupRead: DatasetGroupRead) => {
    return (
      <li
        className="flex flex-col rounded-md border border-gray-200 bg-white hover:border-blue-300"
        key={datasetGroupRead.id}
        onClick={() => handleDatasetGroupClick(datasetGroupRead.id)}
      >
        <div className="flex w-full cursor-pointer px-4 py-2 text-gray-900 hover:text-blue-500">
          <div className="flex flex-grow items-center py-2">
            <DocumentReportIcon
              aria-hidden="true"
              className="mr-2 h-4 w-4 flex-shrink-0"
            />
            <h3
              className="title text-md cursor-pointer break-all font-medium"
              title={datasetGroupRead.name}
            >
              {datasetGroupRead.name}
            </h3>
          </div>
          <div className="flex items-center justify-center text-xs text-gray-400">
            {new Date(datasetGroupRead.created_at).toLocaleDateString()}
          </div>
        </div>
      </li>
    );
  };

  const renderSearchBar = () => {
    return (
      <div className="border-b py-2">
        <div className="flex items-center">
          <SearchIcon
            aria-hidden="true"
            className="mr-2 h-5 w-auto text-gray-400"
          />
          <input
            className="block w-full rounded-sm border-0 border-transparent focus:border-transparent focus:ring-0 sm:text-sm"
            id="search"
            name="search"
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search datasets..."
            type="text"
            value={searchQuery}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      {renderSearchBar()}
      {renderContent()}
    </>
  );
};

export default NewDatasetGroupGrid;
