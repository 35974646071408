import { PromptTemplateConfiguration } from "@/types/evaluate";

// CONFIG GETTERS

export const getSelectedTemplateName = (
  config: Partial<PromptTemplateConfiguration>,
) => config.template?.name;

export const getSelectedVersionNumber = (
  config: Partial<PromptTemplateConfiguration>,
) => config.template?.version_number;

export const getSelectedLabel = (
  config: Partial<PromptTemplateConfiguration>,
) => config.template?.label;

// PROMPT REGISTRY GETTERS

export const _getPromptTemplateRegistryObject = (
  name: string,
  availablePromptTemplates: any[],
) => {
  const promptTemplate = availablePromptTemplates.find(
    (template: any) => template.prompt_name === name,
  );
  return promptTemplate;
};

export const getSelectedPromptTemplate = (
  config: Partial<PromptTemplateConfiguration>,
  availablePromptTemplates: any[],
) => {
  const templateName: string | undefined = getSelectedTemplateName(config);
  if (!templateName) {
    return null;
  }
  return _getPromptTemplateRegistryObject(
    templateName,
    availablePromptTemplates,
  );
};

export const getLabelsForSelectedTemplate = (
  config: Partial<PromptTemplateConfiguration>,
  availablePromptTemplates: any[],
) => {
  const selectedPromptTemplate = getSelectedPromptTemplate(
    config,
    availablePromptTemplates,
  );
  return (selectedPromptTemplate?.versions || [])
    .map((v: any) => v.labels)
    .filter((v: any[]) => v.length > 0)
    .map((v: any[]) => v.map((l: any) => l.name))
    .flat();
};
