import {
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
  DialogTrigger,
} from "../ui/dialog";
import { LockClosedIcon } from "@heroicons/react/outline";

export function UpgradeForFineTuning() {
  return (
    <>
      <DialogHeader>
        <DialogTitle>Model Fine-Tuning</DialogTitle>
        <DialogDescription>
          Use the sidebar search, filters, and tags to select the data you want
          to use to train a new model.
        </DialogDescription>
      </DialogHeader>
      <div className="border-l border-gray-400 p-4">
        <p className="pb-2 text-lg font-semibold">
          <LockClosedIcon className="mr-1 inline-block h-4 w-4" />
          Upgrade Required
        </p>
        <p className="text-md">
          The workspace admin must upgrade to a paid plan to fine-tune a model.
          Upgrade in settings on the top right corner.
        </p>
      </div>
      <DialogFooter>
        <DialogTrigger>
          {/* This button triggers the modal */}
          <div className="inline-flex items-center justify-center rounded-md border border-input bg-background px-4 py-2 text-sm font-medium text-gray-800 shadow-sm hover:bg-accent hover:text-gray-600 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50">
            Close
          </div>
        </DialogTrigger>
      </DialogFooter>
    </>
  );
}
