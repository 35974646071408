import { FC, FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LockClosedIcon } from "@heroicons/react/outline";
import { useCreateWorkspace } from "@/queries";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";

type WorkspaceModalProps = {
  setOpen: (isOpen: boolean) => void;
};

const WorkspaceModal: FC<WorkspaceModalProps> = ({ setOpen }) => {
  const [name, setName] = useState("");
  const authContext = useAuth();
  const userToken = authContext!.userToken;
  const createWorkspace = useCreateWorkspace(userToken!);
  const navigate = useNavigate();
  const userContext = useUser();

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const { workspace } = await createWorkspace.mutateAsync({
        name,
      });
      userContext!.setWorkspaceId(workspace.id);
      navigate(`/workspace/${workspace.id}/settings`);
      setOpen(false);
    } catch (error: unknown) {
      console.log(error);
    }
  };

  const renderContent = () => {
    if (!userContext || userContext.userIsFreePlan)
      return (
        <>
          <div className="border-l border-gray-400 p-4">
            <p className="pb-2 text-lg font-semibold">
              <LockClosedIcon className="mr-1 inline-block h-4 w-4" />
              Upgrade Required
            </p>
            <p className="text-md">
              You must upgrade to a pro plan to create new workspaces. Upgrade
              by clicking "Billing" in the settings cog on the top right corner.
            </p>
          </div>
          <DialogFooter>
            <DialogTrigger>
              <div className="inline-flex items-center justify-center rounded-md border border-input bg-background px-4 py-2 text-sm font-medium text-gray-800 shadow-sm hover:bg-accent hover:text-gray-600 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50">
                Close
              </div>
            </DialogTrigger>
          </DialogFooter>
        </>
      );

    return (
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label
            className="block text-sm font-medium text-gray-700"
            htmlFor="workspace_name"
          >
            Workspace Name
          </label>
          <input
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            id="workspace_name"
            name="workspace_name"
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter workspace name"
            required
            type="text"
            value={name}
          />
        </div>
        <div className="mt-4 text-center">
          <button
            className="inline-flex justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            type="submit"
          >
            Submit
          </button>
        </div>
      </form>
    );
  };

  return (
    <Dialog open={true} onOpenChange={handleClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create Workspace</DialogTitle>
        </DialogHeader>
        {renderContent()}
      </DialogContent>
    </Dialog>
  );
};

export default WorkspaceModal;
