import { CalculatorIcon } from "@heroicons/react/outline";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { useRequest } from "@/queries";
import HistoryPageNavbar from "./HistoryPageNavbar";

const RequestContentNavbar = ({
  onHistoryPage,
  setSidebarOpen,
}: {
  onHistoryPage: boolean;
  setSidebarOpen: (open: boolean) => void;
}) => {
  const { requestId } = useParams();
  const authContext = useAuth();
  const userContext = useUser();

  const requestFromUserContext = requestId
    ? userContext.getRequest(requestId)
    : null;

  const request = useRequest(
    requestId!,
    authContext!.userToken!,
    !requestFromUserContext && !!requestId,
    userContext?.activeWorkspaceId!,
  );

  const requestInfo = useMemo(() => {
    let info;
    if (request.data?.items?.[0]) {
      info = request.data?.items?.[0];
    } else {
      info = requestFromUserContext;
    }
    return info;
  }, [requestFromUserContext, request]);

  return (
    <div
      className={`top-0 flex h-16 ${
        !onHistoryPage && "sm:hidden"
      } flex-shrink-0 border-b border-gray-200 bg-white md:border-0`}
    >
      <button
        className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500 md:hidden"
        onClick={() => setSidebarOpen(true)}
        type="button"
      >
        <span className="sr-only">Open sidebar</span>
        <CalculatorIcon className="h-6 w-6" aria-hidden="true" />
      </button>
      {onHistoryPage && requestId ? (
        <HistoryPageNavbar requestId={requestId} requestInfo={requestInfo} />
      ) : null}
    </div>
  );
};

export default RequestContentNavbar;
