import { MessageCircle } from "lucide-react";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

import CommentForm from "@/components/CommentForm";
import { OPEN_THREADS_SEARCH_PARAM } from "@/constants";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { useCreateThread, useThreads } from "@/queries";
import { XIcon } from "@heroicons/react/outline";
import { useSearchParams } from "react-router-dom";
import Thread from "./Thread";

interface ThreadsOverlayProps {
  latestCommentDate: string | null;
  promptRegistryId: number;
  promptVersionNumber: number;
}

const ThreadsOverlay: FC<ThreadsOverlayProps> = ({
  latestCommentDate,
  promptRegistryId,
  promptVersionNumber,
}) => {
  const [showAllVersions, setShowAllVersions] = useState<boolean>(false);
  const trayRef = useRef<HTMLDivElement | null>(null);
  const authContext = useAuth();
  const userContext = useUser();
  const userToken = authContext?.userToken!;
  const workspaceId = userContext.activeWorkspaceId!;
  const [searchParams, setSearchParams] = useSearchParams();
  const isOpen = searchParams.get(OPEN_THREADS_SEARCH_PARAM) === "true";
  const setIsOpen = useCallback(
    (check: boolean) => {
      if (check) {
        setSearchParams((prev) => {
          prev.set(OPEN_THREADS_SEARCH_PARAM, "true");
          return prev;
        });
      } else {
        setSearchParams((prev) => {
          prev.delete(OPEN_THREADS_SEARCH_PARAM);
          return prev;
        });
      }
    },
    [setSearchParams],
  );

  const { data } = useThreads(
    userToken,
    promptRegistryId,
    latestCommentDate,
    showAllVersions ? undefined : promptVersionNumber,
  );
  const createThread = useCreateThread(userToken);

  const handleEscapeKey = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setIsOpen(false);
      }
    },
    [setIsOpen],
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        trayRef.current &&
        event.target instanceof Node &&
        !trayRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("keydown", handleEscapeKey);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [handleEscapeKey, setIsOpen]);

  const handleCreateThread = (initialComment: string) => {
    createThread.mutate({
      initial_comment: initialComment,
      prompt_registry_id: promptRegistryId,
      prompt_version_number: promptVersionNumber,
      workspace_id: workspaceId,
    });
  };

  const renderNewThreadForm = () => {
    return (
      <>
        <div className="mb-2 flex justify-center p-2">
          <div className="flex items-center">
            <input
              type="checkbox"
              id="show-all-versions"
              checked={showAllVersions}
              onChange={(e) => setShowAllVersions(e.target.checked)}
              className="form-checkbox h-5 w-5 text-blue-600"
            />
            <label
              htmlFor="show-all-versions"
              className="ml-2 cursor-pointer text-sm text-gray-500 hover:text-gray-800"
            >
              Show threads from all versions
            </label>
          </div>
        </div>
        <div className="mb-4 rounded-lg border border-gray-200 bg-white px-2 pt-2 shadow-md">
          <CommentForm
            inThread={false}
            onSubmit={handleCreateThread}
            placeholder="Start a new thread..."
          />
        </div>
      </>
    );
  };

  const renderThreads = () => {
    if (!data || !data.threads) return null;

    return data.threads.map((thread) => (
      <Thread
        isOverlayOpen={isOpen}
        key={thread.id}
        latestCommentDate={latestCommentDate}
        showVersionNumber={showAllVersions}
        thread={thread}
      />
    ));
  };

  const renderToggleButton = () => {
    return (
      <button
        className="rounded-full border border-gray-200 bg-white p-3 text-gray-600 shadow-lg transition-all duration-200 hover:border-gray-300 hover:bg-gray-100"
        onClick={toggleTray}
      >
        <MessageCircle className="h-6 w-6" />
      </button>
    );
  };

  const toggleTray = () => {
    setIsOpen(!isOpen);
  };

  const renderTray = () => (
    <div
      className={`fixed right-0 top-0 h-full w-96 transform overflow-y-auto bg-gray-100 transition-transform duration-300 ease-in-out ${
        isOpen ? "translate-x-0" : "translate-x-full"
      }`}
      ref={trayRef}
    >
      <div className="p-4">
        <div className="flex justify-end">
          <XIcon
            className="h-6 w-6 cursor-pointer text-gray-500"
            onClick={toggleTray}
          />
        </div>
        {renderNewThreadForm()}
        {renderThreads()}
      </div>
    </div>
  );

  return (
    <>
      {renderToggleButton()}
      {createPortal(
        <>
          <div
            className={`fixed inset-0 bg-black transition-opacity duration-300 ${
              isOpen ? "opacity-10" : "pointer-events-none opacity-0"
            }`}
          ></div>
          {renderTray()}
        </>,
        document.body,
      )}
    </>
  );
};

export default ThreadsOverlay;
