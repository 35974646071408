import { FC, useState, useRef, useEffect } from "react";

import DeleteProviderBaseURLModal from "@/components/DeleteProviderBaseURLModal";
import ProviderBaseURLModal from "@/components/ProviderBaseURLModal";
import { useUser } from "@/context/user-context";
import {
  TableWithoutWrapper,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { TrashIcon } from "@heroicons/react/outline";

const ProviderBaseURLs: FC = () => {
  const [isDeleteProviderBaseURLModalOpen, setDeleteProviderBaseURLModalOpen] =
    useState<boolean>(false);
  const [isProviderBaseURLModalOpen, setProviderBaseURLModalOpen] =
    useState<boolean>(false);
  const [selectedProviderBaseURLId, setSelectedProviderBaseURLId] = useState<
    number | null
  >(null);

  const userContext = useUser();
  const providerBaseURLs = userContext.providerBaseURLs;

  const handleCreate = () => {
    setProviderBaseURLModalOpen(true);
  };

  const handleDelete = (id: number) => {
    setDeleteProviderBaseURLModalOpen(true);
    setSelectedProviderBaseURLId(id);
  };

  const tableRef = useRef<HTMLTableElement>(null);

  useEffect(() => {
    if (tableRef.current) {
      const table = tableRef.current;
      const columns = table.getElementsByTagName("th");
      const columnWidth = 100 / columns.length + "%";

      for (let i = 0; i < columns.length; i++) {
        columns[i].style.width = columnWidth;
      }
    }
  }, []);

  return (
    <>
      <div className="mt-4 overflow-x-auto rounded-md border">
        <TableWithoutWrapper ref={tableRef} className="min-w-full">
          <TableHeader className="sticky top-0 z-10 bg-white shadow">
            <TableRow>
              <TableHead>Name</TableHead>
              <TableHead>URL</TableHead>
              <TableHead>Provider</TableHead>
              <TableHead>API Key</TableHead>
              <TableHead />
            </TableRow>
          </TableHeader>
          <TableBody>
            {providerBaseURLs.length > 0 ? (
              providerBaseURLs.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.url}</TableCell>
                  <TableCell>{item.provider}</TableCell>
                  <TableCell>********</TableCell>
                  <TableCell>
                    <Button
                      className="inline-flex items-center"
                      onClick={() => handleDelete(item.id)}
                      size="sm"
                      variant="destructiveOutline"
                    >
                      <TrashIcon
                        className="h-4 w-auto pr-1"
                        aria-hidden="true"
                      />
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} className="px-4 py-2 text-center">
                  No provider base URLs found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </TableWithoutWrapper>
      </div>
      <div className="mt-4 inline-flex items-center">
        <Button onClick={handleCreate}>Create new</Button>
      </div>
      {isDeleteProviderBaseURLModalOpen && selectedProviderBaseURLId && (
        <DeleteProviderBaseURLModal
          open={isDeleteProviderBaseURLModalOpen}
          providerBaseURLId={selectedProviderBaseURLId}
          setOpen={setDeleteProviderBaseURLModalOpen}
        />
      )}
      {isProviderBaseURLModalOpen && (
        <ProviderBaseURLModal setOpen={setProviderBaseURLModalOpen} />
      )}
    </>
  );
};

export default ProviderBaseURLs;
