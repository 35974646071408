import useDynamicWidth from "@/components/DatasetViewerTable/DatasetViewerTableCell/hooks/useDynamicWidth";

import { ColumnType, ReportCell, ReportColumn } from "@/types/evaluate";

import { useReportContext } from "../../report-context";
import { CompletedCell } from "./CellTypes/CompletedCell";
import { FailedCell } from "./CellTypes/FailedCell";
import { HumanInputCell } from "./CellTypes/HumanInputCell";
import { StatusCell } from "./CellTypes/StatusCell";
import { useRetryReportCell } from "./hooks/useRetryReportCell";

const RowCell = ({ rowId, columnId }: { rowId: number; columnId: number }) => {
  const reportContext = useReportContext();
  const cell: ReportCell = reportContext.getCellByRowAndColumn(rowId, columnId);
  const width = useDynamicWidth(cell?.display_value?.value || "");

  return (
    <div className={`${width} mx-auto max-h-[250px] max-w-[40vw]`}>
      <RowCellContent rowId={rowId} columnId={columnId} />
    </div>
  );
};

const RowCellContent = ({
  rowId,
  columnId,
}: {
  rowId: number;
  columnId: number;
}) => {
  const reportContext = useReportContext();
  const cell: ReportCell = reportContext.getCellByRowAndColumn(rowId, columnId);
  const column: ReportColumn = reportContext.getColumnById(columnId);
  const isLastColumn: boolean =
    reportContext?.lastColumnId && reportContext.lastColumnId === columnId;

  const { retryReportCell, isLoading: retryReportCellIsLoading } =
    useRetryReportCell(cell?.id);

  if (!cell) {
    return <StatusCell status="loading" />;
  }

  if (column.column_type === ColumnType.HUMAN) {
    return (
      <HumanInputCell
        key={`${cell?.id}-${column.configuration.data_type}-${column.configuration.ui_element.component}-${cell.value?.value}`}
        cell={cell}
        column={column}
        isLastColumn={isLastColumn}
      />
    );
  }

  const status = cell.status.toLowerCase();

  if (status === "queued" || status === "running") {
    return <StatusCell status={status} />;
  } else if (status === "completed") {
    return (
      <CompletedCell
        cell={cell}
        column={column}
        retryReportCell={retryReportCell}
        retryReportCellIsLoading={retryReportCellIsLoading}
      />
    );
  } else if (status === "failed") {
    return (
      <FailedCell
        cell={cell}
        retryReportCell={retryReportCell}
        retryReportCellIsLoading={retryReportCellIsLoading}
      />
    );
  }

  return null;
};

export default RowCell;
