import codeCircleIcon from "@/assets/code-circle.svg";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useUser } from "@/context/user-context";
import { PromptVersionSnippet } from "@/types/apiGetters";
import { TooltipArrow, TooltipPortal } from "@radix-ui/react-tooltip";
import React from "react";
import { Link } from "react-router-dom";
import Snippet from "./Snippet";

type HighlightSnippetsProps = {
  template: string;
  mode: string;
  isXrayMode?: boolean;
  invalidSnippets?: string[];
  sourcedSnippets?: Array<PromptVersionSnippet>;
  renderedTemplate?: string | null;
};

const getTemplate = (
  promptName: string,
  sourcedSnippets: Array<PromptVersionSnippet>,
  version?: number,
  label?: string,
) => {
  const snippet = sourcedSnippets.find(
    (snippet) =>
      snippet.prompt_template_name === promptName &&
      (version ? snippet.prompt_version_number === version : true) &&
      (label ? snippet.label === label : true),
  );
  return snippet;
};

function mapSnippets(
  promptTemplate: string,
  renderedTemplate: string,
): { [key: string]: string } {
  // Regular expression to find placeholders like @@@<snippet_name>@@@
  const placeholderRegex = /@@@.*?@@@/g;

  // Find all placeholders in promptTemplate
  const placeholders = promptTemplate.match(placeholderRegex);
  if (!placeholders) return {};

  // Split promptTemplate by the placeholders
  const parts = promptTemplate.split(placeholderRegex);
  const snippetMap: { [key: string]: string } = {};

  // Initialize the starting index for searching in renderedTemplate
  let startIndex = 0;

  // Build the map of placeholders to snippets
  for (let i = 0; i < parts.length - 1; i++) {
    // Move the startIndex to the end of the current part
    startIndex += parts[i].length;

    // Calculate the end index in renderedTemplate by finding the start of the next part
    const nextPart = parts[i + 1];
    const endIndex = nextPart
      ? renderedTemplate.indexOf(nextPart, startIndex)
      : renderedTemplate.length;

    // Extract the snippet from renderedTemplate
    if (endIndex !== -1) {
      const snippet = renderedTemplate.slice(startIndex, endIndex).trim();
      snippetMap[placeholders[i]] = snippet;
      startIndex = endIndex; // Move the start index forward
    } else {
      // If the next part is not found, break the loop
      break;
    }
  }

  return snippetMap;
}

export const HighlightSnippets = ({
  template,
  mode,
  isXrayMode = false,
  renderedTemplate = null,
  invalidSnippets,
  sourcedSnippets = [],
}: HighlightSnippetsProps) => {
  const userContext = useUser();
  const regex =
    /@@@((?:.|[\s])+?)(@label:((?:.|[\s])+?))?(@version_number:(\d+))?@@@/g;
  let match;
  const matches = [];
  let lastIndex = 0;

  const allRenderedSnippets = mapSnippets(template, renderedTemplate || "");

  while ((match = regex.exec(template)) !== null) {
    const templateName = match[1];
    const label = match[3];
    const version = match[4]?.replace("@version_number:", "");

    if (match.index > lastIndex) {
      matches.push(template.substring(lastIndex, match.index));
    }
    const linked_template = getTemplate(
      templateName,
      sourcedSnippets,
      Number(version),
      label,
    );

    let snippetText = null;
    if (isXrayMode && renderedTemplate) {
      snippetText = allRenderedSnippets[match[0]];
    }

    if (mode === "view") {
      matches.push(
        <React.Fragment key={match[0]}>
          {snippetText &&
          linked_template &&
          !invalidSnippets?.includes(match[0]) ? (
            <>
              <TooltipProvider>
                <Tooltip delayDuration={0}>
                  <TooltipTrigger asChild>
                    <span className="w-fit bg-gray-100 font-medium text-gray-700 hover:bg-gray-200 hover:text-gray-900">
                      <Link
                        key={match[0]}
                        to={`/workspace/${userContext.activeWorkspaceId}/prompt/${linked_template.prompt_registry_id}/version/${linked_template.prompt_version_number}`}
                        style={{ width: "fit-content" }}
                      >
                        {snippetText}
                      </Link>
                    </span>
                  </TooltipTrigger>
                  <TooltipPortal>
                    <TooltipContent
                      className="rounded bg-gray-800 p-2 text-sm text-white"
                      side="top"
                      sideOffset={10}
                    >
                      {linked_template.prompt_template_name}
                      <TooltipArrow className="fill-current text-gray-800" />
                    </TooltipContent>
                  </TooltipPortal>
                </Tooltip>
              </TooltipProvider>
            </>
          ) : linked_template && !invalidSnippets?.includes(match[0]) ? (
            <Link
              key={match[0]}
              to={`/workspace/${userContext.activeWorkspaceId}/prompt/${linked_template.prompt_registry_id}/version/${linked_template.prompt_version_number}`}
            >
              <Snippet
                value={match[0]}
                label={label}
                template_name={linked_template.prompt_template_name}
              />
            </Link>
          ) : (
            <button
              key={match[0]}
              className={`rounded-full border border-red-100 bg-red-50 px-2 py-1 font-semibold text-red-700`}
              disabled
            >
              <img
                src={codeCircleIcon}
                alt="Code Circle Icon"
                className="-mt-1 mr-2 inline h-4 w-auto"
              />
              {match[0]
                .replace(/@@@/g, "")
                .replace(/@label:/g, ":")
                .replace(/@version_number:(\w+)/g, "[#$1]")}
            </button>
          )}
        </React.Fragment>,
      );
    } else {
      matches.push(
        <span
          key={match[0]}
          className="font-bold text-blue-500"
          style={{ color: "#ffc00c" }}
        >
          {match[0]}
        </span>,
      );
    }

    lastIndex = regex.lastIndex;
  }

  if (lastIndex < template.length) {
    matches.push(template.substring(lastIndex));
  }

  return (
    <div className="font-inherit whitespace-pre-wrap break-words">
      {matches}
    </div>
  );
};
