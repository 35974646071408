import folderGraphic from "@/assets/folder.svg";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { cn } from "@/lib/utils";
import { usePromptRegistryObjects } from "@/queries";
import { PromptRegistry } from "@/types/prompt-registry";

export default function TemplateSelectDropdown({
  selectedTemplateName,
  handleTemplateSelection,
  dropdownTriggerclassName,
  sourceTemplateId = null,
  isSnippet = false,
  disabled = false,
}: {
  selectedTemplateName: string | undefined;
  handleTemplateSelection: ({ id, name }: { id: number; name: string }) => void;
  dropdownTriggerclassName?: string;
  sourceTemplateId?: number | null;
  isSnippet?: boolean;
  disabled?: boolean;
}) {
  const userContext = useUser();
  const auth = useAuth();
  const userToken = auth?.userToken!;
  const workspaceId = userContext.activeWorkspaceId!;

  const templateSet = usePromptRegistryObjects(userToken, {
    workspaceId,
    perPage: Number.MAX_SAFE_INTEGER,
    templateType: isSnippet ? "completion" : undefined,
  });

  const availablePromptTemplates =
    templateSet.data?.pages
      .flatMap((page) => page.items)
      .filter((item) => item.id !== sourceTemplateId) || [];

  const folders = userContext.folders.filter((f: any) => !f.deleted) || [];

  const folderIdsToFolders: { [key: number]: any } = folders.reduce(
    (acc: { [key: number]: any }, folder: any) => {
      acc[folder.id] = folder;
      return acc;
    },
    {},
  );

  const folderIdToTemplateList = availablePromptTemplates.reduce<{
    [key: number]: Array<PromptRegistry>;
  }>((acc, template) => {
    const folderId = template.folder_id;
    if (folderId) {
      if (!acc[folderId]) {
        acc[folderId] = [];
      }
      acc[folderId].push(template);
    }
    return acc;
  }, {});

  const templatesWithoutFolders = availablePromptTemplates.filter(
    (template) => !template.folder_id,
  );

  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        disabled={disabled}
        className={cn("w-full", dropdownTriggerclassName)}
      >
        {selectedTemplateName || (
          <span className="font-normal text-gray-500">
            {availablePromptTemplates?.length > 0
              ? "Select a template..."
              : "No templates found"}
          </span>
        )}
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {Object.keys(folderIdToTemplateList).map((folderId) => {
          const folder = folderIdsToFolders[Number(folderId)];
          return (
            <div className="py-1" key={folderId}>
              <div className="flex gap-x-2 px-1 pb-1 text-xs uppercase text-gray-600">
                <img
                  alt="Folder graphic"
                  className="w-4 text-blue-600"
                  src={folderGraphic}
                />
                {folder.name}
              </div>
              <div className="my-2 ml-1 border-l border-gray-200 pl-1">
                {folderIdToTemplateList[Number(folderId)]
                  .sort((a, b) => a.prompt_name.localeCompare(b.prompt_name))
                  .map((template) => (
                    <DropdownMenuItem
                      key={template.prompt_name}
                      onSelect={() =>
                        handleTemplateSelection({
                          id: template.id,
                          name: template.prompt_name,
                        })
                      }
                    >
                      {template.prompt_name}
                    </DropdownMenuItem>
                  ))}
              </div>
            </div>
          );
        })}
        {templatesWithoutFolders
          .sort((a, b) => a.prompt_name.localeCompare(b.prompt_name))
          .map((template) => (
            <DropdownMenuItem
              key={template.prompt_name}
              onSelect={() =>
                handleTemplateSelection({
                  id: template.id,
                  name: template.prompt_name,
                })
              }
            >
              {template.prompt_name}
            </DropdownMenuItem>
          ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
