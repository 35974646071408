import { Table } from "@tanstack/react-table";
import {
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
} from "lucide-react";

import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

interface DatasetTablePaginationProps<TData> {
  handlePageSizeChange: (newPageSize: number) => void;
  page: number;
  pageSize: number;
  pageSizeOptions: number[];
  pages: number;
  setPageIndex: (pageIndex: number) => void;
  table: Table<TData>;
  total: number;
}

const DatasetTablePagination = <TData,>({
  handlePageSizeChange,
  page,
  pageSize,
  pageSizeOptions,
  pages,
  setPageIndex,
  table,
  total,
}: DatasetTablePaginationProps<TData>) => {
  const renderArrows = () => {
    return (
      <div className="flex items-center space-x-2">
        <Button
          className="hidden h-8 w-8 p-0 lg:flex"
          disabled={page === 1}
          onClick={() => setPageIndex(1)}
          variant="outline"
        >
          <span className="sr-only fixed">Go to first page</span>
          <ChevronsLeft className="h-4 w-4" />
        </Button>
        <Button
          className="h-8 w-8 p-0"
          disabled={page === 1}
          onClick={() => setPageIndex(page - 1)}
          variant="outline"
        >
          <span className="sr-only fixed">Go to previous page</span>
          <ChevronLeft className="h-4 w-4" />
        </Button>
        <Button
          className="h-8 w-8 p-0"
          disabled={page === pages}
          onClick={() => setPageIndex(page + 1)}
          variant="outline"
        >
          <span className="sr-only fixed">Go to next page</span>
          <ChevronRight className="h-4 w-4" />
        </Button>
        <Button
          className="hidden h-8 w-8 p-0 lg:flex"
          disabled={page === pages}
          onClick={() => setPageIndex(pages)}
          variant="outline"
        >
          <span className="sr-only fixed">Go to last page</span>
          <ChevronsRight className="h-4 w-4" />
        </Button>
      </div>
    );
  };

  const renderDropdown = () => {
    return (
      <div className="flex items-center space-x-2">
        <p className="text-sm font-medium text-gray-500">Rows per page</p>
        <Select
          onValueChange={(value) => {
            if (parseInt(value) !== pageSize) {
              table.setPageSize(Number(value));
              handlePageSizeChange(Number(value));
            }
          }}
          value={pageSize.toString()}
        >
          <SelectTrigger className="h-8 w-[70px] bg-white">
            <SelectValue placeholder={table.getState().pagination.pageSize} />
          </SelectTrigger>
          <SelectContent side="top">
            {pageSizeOptions.map((pageSize) => (
              <SelectItem key={pageSize} value={`${pageSize}`}>
                {pageSize}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    );
  };

  const renderPageIndicator = () => {
    return (
      <div className="flex w-[100px] items-center justify-center text-sm font-medium">
        Page {page} of {pages}
      </div>
    );
  };

  if (!total) return null;

  return (
    <div className="flex w-full items-center justify-between p-2">
      <div className="flex items-center space-x-6 lg:space-x-8">
        {renderDropdown()}
        {renderPageIndicator()}
        {renderArrows()}
      </div>
    </div>
  );
};

export default DatasetTablePagination;
