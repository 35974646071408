import * as SliderPrimitive from "@radix-ui/react-slider";
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from "react";

import { cn } from "@/lib/utils";

type SliderProps = ComponentPropsWithoutRef<typeof SliderPrimitive.Root> & {
  label?: any;
};

const Slider = forwardRef<ElementRef<typeof SliderPrimitive.Root>, SliderProps>(
  ({ className, label, ...props }, ref) => {
    const sliderComponent = (
      <SliderPrimitive.Root
        ref={ref}
        className={cn(
          "relative flex w-full touch-none select-none items-center",
          className,
        )}
        {...props}
      >
        <SliderPrimitive.Track className="relative h-1.5 w-full grow overflow-hidden rounded-full bg-primary/20">
          <SliderPrimitive.Range className="absolute h-full bg-primary" />
        </SliderPrimitive.Track>
        <SliderPrimitive.Thumb className="block h-4 w-4 rounded-full border border-primary/50 bg-background shadow transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50" />
      </SliderPrimitive.Root>
    );
    if (label === undefined) return sliderComponent;
    else {
      return (
        <div className="flex items-center text-xs text-gray-500">
          {sliderComponent}
          <div className="w-[40px] pl-2 text-right">{label}</div>
        </div>
      );
    }
  },
);

Slider.displayName = SliderPrimitive.Root.displayName;

export { Slider };
