import { KeyIcon } from "@heroicons/react/outline";
import { zodResolver } from "@hookform/resolvers/zod";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { forgotPassword, login } from "./api/application-api";
import AuthSubmitButton from "./components/AuthSubmitButton";
import { useAuth } from "./context/auth-context";
import { getWorkspaces } from "./queries";

const loginSchema = z.object({
  email: z
    .string()
    .email({ message: "Invalid email format" })
    .nonempty({ message: "Email is required" }),
  password: z.string().nonempty({ message: "Password is required" }),
});

const Login: FC = () => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [sentResetPasswordEmail, setResetSentEmail] = useState<boolean>(false);
  const auth = useAuth();
  const navigate = useNavigate();

  const form = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: zodResolver(loginSchema),
  });

  const handleForgotPasswordClick = async () => {
    const email = form.watch("email");
    const emailSchema = z
      .string()
      .email({ message: "Invalid email format" })
      .nonempty({ message: "Email is required" });
    const emailResult = emailSchema.safeParse(email);

    if (!emailResult.success) {
      setError("Please provide a valid email.");
      return;
    }

    try {
      const response: any = await forgotPassword({ email });

      if (response.ok) {
        setError(null);
        setResetSentEmail(true);
        return;
      }

      const { message } = await response.json();
      setError(
        typeof message === "string"
          ? message
          : "There was an error resetting your password. Please try again later.",
      );
    } catch (err) {
      console.error(err);
      setError("Something went wrong. Please try again later.");
    }
  };

  const onSubmit = async (values: any) => {
    setLoading(true);

    try {
      const response: any = await login({
        email: values.email,
        password: values.password,
      });

      if (response.ok) {
        const { access_token, user } = await response.json();
        const { workspaces } = await getWorkspaces(access_token);
        if (workspaces.length === 0) {
          setError("You do not have access to any workspaces.");
          return;
        }
        const activeWorkspace =
          workspaces.find(({ is_default }) => is_default) || workspaces[0];
        auth?.login(access_token, user);
        navigate(`/workspace/${activeWorkspace.id}/home`);
        return;
      }

      const { message } = await response.json();
      setError(
        typeof message === "string"
          ? message
          : "Unexpected server response format. Please try again later.",
      );
    } catch (err) {
      console.error(err);
      setError("Something went wrong. Please try again later.");
    }

    setLoading(false);
  };

  const renderForgotPasswordLink = () => {
    if (sentResetPasswordEmail) {
      return (
        <span className="rounded-md bg-blue-50 px-3 py-1 text-gray-800">
          Password reset email sent.
        </span>
      );
    }

    return (
      <button
        className="text-blue-500 hover:text-blue-400"
        onClick={handleForgotPasswordClick}
        type="button"
      >
        Forgot password?
      </button>
    );
  };

  return (
    <>
      <div className="mx-auto max-w-lg gap-8 rounded-sm border bg-gray-50 px-12 py-5 text-left">
        <div className="text-center text-lg font-light text-gray-500">
          <KeyIcon className="my-4 inline h-5 w-5" aria-hidden="true" />
        </div>
        <form
          className="flex flex-col gap-3 py-3"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <input
            className="rounded-sm border border-gray-400 py-3"
            type="text"
            placeholder="Enter email"
            {...form.register("email")}
          />
          {form.formState.errors.email && (
            <div className="text-sm text-red-500">
              {form.formState.errors.email.message}
            </div>
          )}
          <input
            className="rounded-sm border border-gray-400 py-3"
            type="password"
            placeholder="Enter password"
            {...form.register("password")}
          />
          {form.formState.errors.password && (
            <div className="text-sm text-red-500">
              {form.formState.errors.password.message}
            </div>
          )}
          {error ? (
            <div className="py-2 text-center text-sm text-black">
              <span className="rounded-md bg-red-200 px-3 py-1">{error}</span>
            </div>
          ) : null}
          <div className="py-2 text-center text-sm text-gray-500">
            {renderForgotPasswordLink()}
          </div>
          <AuthSubmitButton loading={loading} text="Login" />
        </form>
        <div className="text-center font-light text-gray-800">
          Don't have an account yet?{" "}
          <button
            className="text-blue-500 hover:text-blue-400"
            onClick={() => navigate("/create-account")}
          >
            Create an account.
          </button>
        </div>
      </div>
    </>
  );
};

export default Login;
