import { openai } from "@/schemas";
import { z } from "zod";

const providers = ["openai", "langchain", "anthropic"] as const;

const base = z.object({
  id: z.number(),
  function_name: z.string(),
  request_start_time: z.string(),
  request_end_time: z.string(),
  engine: z.string(),
  tags: z.string().array(),
  is_starred: z.boolean(),
  prompt_id: z.number().nullable(),
  response_string: z.string(),
  workspace_id: z.number().nullable(),
  prompt_version_number: z.number().nullable(),
  metadata: z.union([
    z.null(),
    z.array(z.null()),
    z.array(z.record(z.string())),
  ]),
});

const completion = base.extend({
  provider_type: z.enum([providers[0], providers[1]]),
  is_completion: z.literal(true),
  is_chat: z.undefined(),
  request_text: z.string(),
  response_text: z.string().optional(),
  type: z.enum(["request", "group"]),
  cardinality: z.undefined(),
});

const chatCompletion = base.extend({
  provider_type: z.enum(providers),
  is_completion: z.undefined(),
  is_chat: z.literal(true),
  request_text: z
    .object({
      role: z.enum(openai.roles),
      content: z.string(),
      parts: z
        .object({
          text: z.string().optional(),
        })
        .array()
        .optional(),
    })
    .array(),
  response_text: openai.message.or(
    z.object({
      parts: z
        .object({
          text: z.string().optional(),
        })
        .array(),
    }),
  ),
  type: z.enum(["request", "group"]),
  cardinality: z.undefined(),
});

const group = base.extend({
  provider_type: z.undefined(),
  is_completion: z.undefined(),
  is_chat: z.literal(false),
  request_text: z.string(),
  response_text: z.string().optional(),
  type: z.enum(["request", "group"]),
  cardinality: z.number(),
});

const schema = z.discriminatedUnion("is_chat", [
  completion,
  chatCompletion,
  group,
]);

const promptTemplate = z
  .discriminatedUnion("is_chat", [completion, chatCompletion])
  .and(
    z.object({
      prompt_input_variables: z.record(z.any()),
      score: z.number(),
      request_response: openai.message,
    }),
  );

type PromptTemplate = z.infer<typeof promptTemplate>;

export { PromptTemplate, providers, schema };
