import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { ToastType } from "@/enums";
import { useIntersectionObserver } from "@/hooks";
import {
  useBranchToWorkspace,
  usePromptRegistryObjects,
  usePromptVersions,
} from "@/queries";
import { ListPromptVersion } from "@/types";
import { PromptRegistry } from "@/types/prompt-registry";
import { Workspace } from "@/types/workspaces";
import { displayToast } from "@/utils/toast";
import { ChatIcon } from "@heroicons/react/outline";
import { FC, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner";
import { Switch } from "./switch";

type BranchToWorkspaceModalProps = {
  open: boolean;
  prompt: PromptRegistry;
  setOpen: (open: boolean) => void;
};

export const BranchToWorkspaceModal: FC<BranchToWorkspaceModalProps> = ({
  open,
  prompt,
  setOpen,
}) => {
  const [selectedWorkspace, setSelectedWorkspace] = useState<Workspace | null>(
    null,
  );

  const [isLoading, setIsLoading] = useState(false);
  const [branchMode, setBranchMode] = useState<"new" | "existing">("new");
  const authContext = useAuth();
  const userToken = authContext!.userToken;
  const branchToWorkspace = useBranchToWorkspace(userToken!);

  const [errorMessage, setErrorMessage] = useState("");
  const [selectedTemplate, setSelectedTemplate] =
    useState<PromptRegistry | null>(null);

  const [selectedVersion, setSelectedVersion] =
    useState<ListPromptVersion | null>(null);
  const [newTemplateName, setNewTemplateName] = useState<string>("");

  const navigate = useNavigate();
  const handleBranch = async () => {
    let response;
    try {
      if (!selectedWorkspace?.id) return;
      if (!selectedVersion?.id) return;

      setIsLoading(true);
      response = await branchToWorkspace.mutateAsync({
        workspace_id: selectedWorkspace?.id,
        prompt_template_id: prompt.id.toString(),
        version_id: selectedVersion?.id,
        new_template_name: branchMode === "new" ? newTemplateName : null,
        existing_template_id:
          branchMode === "existing" ? selectedTemplate?.id || null : null,
      });
      if (response.success) {
        displayToast("Prompt template version copied", ToastType.success);
        navigate(
          `/workspace/${selectedWorkspace?.id}/prompt/${response?.prompt_template?.id}`,
        );
        setOpen(false);
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      setErrorMessage(error.toString());
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogContent className="overflow-x-hidden px-10 transition-all duration-500 ease-in-out">
        <DialogHeader>
          <DialogTitle>Copy a version of "{prompt?.prompt_name}"</DialogTitle>
          <DialogDescription className="mt-1 text-sm text-gray-500">
            Branch out your prompt template by copying a single version into an
            existing template or into a brand new template.
          </DialogDescription>
        </DialogHeader>
        <div className="mt-2 border-b border-gray-200 pb-1 text-sm font-semibold text-gray-800">
          Source to Copy
        </div>
        <SelectVersion
          selectedVersion={selectedVersion}
          setSelectedVersion={setSelectedVersion}
          promptRegistryId={prompt.id}
        />
        {selectedVersion && (
          <Destination
            selectedWorkspace={selectedWorkspace}
            setSelectedWorkspace={setSelectedWorkspace}
          />
        )}
        {selectedVersion && selectedWorkspace?.id && (
          <>
            <div className="flex items-center justify-center gap-4 px-2 pb-2 text-xs transition-all duration-500 ease-in-out">
              <span>New template</span>
              <Switch
                checked={branchMode === "existing"}
                onChange={(checked) =>
                  setBranchMode(checked ? "existing" : "new")
                }
              />
              <span>Existing template</span>
            </div>
            <>
              {branchMode === "new" ? (
                <div className="my-2 grid h-10 grid-cols-3 items-center gap-2 overflow-y-auto px-2 transition-all duration-500 ease-in-out">
                  <label className="col-span-1 mr-2 text-sm">Name</label>
                  <div className="col-span-2">
                    <input
                      className="flex w-full items-center justify-between rounded-sm border border-gray-300 bg-white px-4 py-2 text-sm text-gray-700 shadow-sm hover:bg-gray-50"
                      placeholder={`New template name`}
                      value={newTemplateName}
                      onChange={(e) => setNewTemplateName(e.target.value)}
                    />
                  </div>
                </div>
              ) : (
                <SelectPromptRegistryObject
                  selectedTemplate={selectedTemplate}
                  selectedWorkspace={selectedWorkspace}
                  setSelectedTemplate={setSelectedTemplate}
                />
              )}
            </>
          </>
        )}
        {/* Error Message */}
        {errorMessage && (
          <div
            className="relative mx-auto mb-4 w-full max-w-xl rounded border border-red-400 px-2 py-2 text-red-700 transition-all duration-500 ease-in-out"
            role="alert"
          >
            <span className="block sm:inline">{errorMessage}</span>
          </div>
        )}
        <DialogFooter>
          <Button
            className="bg-transparent text-sm"
            onClick={() => setOpen(false)}
            variant="outline"
          >
            Cancel
          </Button>
          <Button
            onClick={handleBranch}
            disabled={
              isLoading ||
              !selectedVersion ||
              !selectedWorkspace ||
              (branchMode === "existing" && !selectedTemplate) ||
              (branchMode === "new" && !newTemplateName) ||
              (branchMode === "new" && newTemplateName.trim() === "")
            }
            className="w-36 rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-700"
          >
            {isLoading ? <LoadingSpinner size={5} /> : "Copy Version"}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

interface SelectVersionProps {
  promptRegistryId: number;
  selectedVersion: ListPromptVersion | null;
  setSelectedVersion: (version: ListPromptVersion | null) => void;
}

function SelectVersion({
  promptRegistryId,
  selectedVersion,
  setSelectedVersion,
}: SelectVersionProps) {
  const authContext = useAuth();
  const userContext = useUser();
  const userToken = authContext?.userToken!;
  const workspaceId = userContext?.activeWorkspaceId!;
  const ref = useRef<HTMLDivElement>(null);
  const entry = useIntersectionObserver(ref, {});
  const promptVersionsQuery = usePromptVersions(userToken, {
    workspaceId,
    promptRegistryId,
  });

  const versionsList =
    promptVersionsQuery.data?.pages.flatMap((page) => page.items) || [];

  if (entry?.isIntersecting) promptVersionsQuery.fetchNextPage();

  const versionName = `Version ${selectedVersion?.number}`;
  return (
    <div className="my-2 grid grid-cols-3 items-center gap-2 overflow-y-auto px-2 transition-all duration-500 ease-in-out">
      <label className="col-span-1 mr-2 flex items-center text-sm">
        Version
      </label>
      <div className="col-span-2 self-center">
        {" "}
        <DropdownMenu>
          <DropdownMenuTrigger className="w-full">
            {selectedVersion?.number && versionName.length > 30 ? (
              versionName.substring(0, 30) + "..."
            ) : selectedVersion ? (
              versionName
            ) : (
              <span className="text-gray-500">Select a version...</span>
            )}
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {versionsList.map((version, index) => (
              <DropdownMenuItem
                key={index}
                onSelect={() => setSelectedVersion(version)}
              >
                <div className="items-start">
                  <div className="">
                    <div className="flex flex-row items-center">
                      <div>{`Version ${version.number}`}</div>
                      <div className="pl-1 text-xs font-semibold">
                        {`${
                          version.labels && version.labels.length > 0
                            ? `[${version.labels
                                .map((v) => v.name)
                                .join(", ")}]`
                            : ""
                        }`}
                      </div>
                    </div>
                  </div>
                  <div className="flex text-xs font-light text-gray-600">
                    {version.commit_message && (
                      <>
                        <ChatIcon className="mr-1 inline h-4 w-4 text-gray-400" />
                        {version.commit_message}
                      </>
                    )}
                  </div>
                </div>
              </DropdownMenuItem>
            ))}
            {promptVersionsQuery.hasNextPage && (
              <div ref={ref} className="flex justify-center py-2">
                <LoadingSpinner size={5} />
              </div>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
}

interface SelectPromptRegistryObjectProps {
  selectedTemplate: PromptRegistry | null;
  setSelectedTemplate: (template: PromptRegistry | null) => void;
  selectedWorkspace: Workspace | null;
}

function SelectPromptRegistryObject({
  selectedTemplate,
  setSelectedTemplate,
  selectedWorkspace,
}: SelectPromptRegistryObjectProps) {
  const auth = useAuth();
  const ref = useRef<HTMLDivElement>(null);
  const entry = useIntersectionObserver(ref, {});
  const userToken = auth!.userToken!;
  const workspaceId = selectedWorkspace!.id;
  const promptRegistryObjectsQuery = usePromptRegistryObjects(userToken, {
    workspaceId,
  });
  const templatesForWorkspaceIsLoading = promptRegistryObjectsQuery.isLoading;

  const templatesForWorkspace =
    promptRegistryObjectsQuery.data?.pages.flatMap((page) => page.items) || [];

  if (entry?.isIntersecting) promptRegistryObjectsQuery.fetchNextPage();
  return (
    <div className="my-2 grid h-10 grid-cols-3 items-center gap-2 overflow-y-auto px-2 transition-all duration-500 ease-in-out">
      <label className="col-span-1 mr-2 text-sm">Template Destination</label>
      <div className="col-span-2">
        <DropdownMenu>
          <DropdownMenuTrigger
            className="w-full"
            disabled={
              templatesForWorkspaceIsLoading ||
              templatesForWorkspace?.length === 0
            }
          >
            {templatesForWorkspaceIsLoading
              ? "Loading..."
              : selectedTemplate?.prompt_name &&
                selectedTemplate.prompt_name.length > 30
              ? selectedTemplate.prompt_name.substring(0, 30) + "..."
              : selectedTemplate?.prompt_name || (
                  <span className="text-gray-500">
                    {templatesForWorkspace?.length > 0
                      ? "Select a template..."
                      : "No templates found"}
                  </span>
                )}
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {templatesForWorkspace?.map((template, index) => (
              <DropdownMenuItem
                key={index}
                onSelect={() => setSelectedTemplate(template)}
              >
                {template.prompt_name}
              </DropdownMenuItem>
            ))}
            {promptRegistryObjectsQuery.hasNextPage && (
              <div ref={ref} className="flex justify-center py-2">
                <LoadingSpinner size={5} />
              </div>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
}

interface DestinationProps {
  selectedWorkspace: Workspace | null;
  setSelectedWorkspace: (workspace: Workspace | null) => void;
}

function Destination({
  selectedWorkspace,
  setSelectedWorkspace,
}: DestinationProps) {
  const user = useUser();
  const workspacesList = user.workspaces;
  const activeWorkspaceId = selectedWorkspace?.id;

  return (
    <div>
      <div className="mt-2 border-b border-gray-200 pb-1 text-sm font-semibold text-gray-800">
        Destination
      </div>
      <div className="my-2  grid h-10 grid-cols-3 items-center gap-2 overflow-y-auto px-2 transition-all duration-500 ease-in-out">
        <label className="col-span-1 mr-2 text-sm">Workspace</label>
        <div className="col-span-2">
          <DropdownMenu>
            <DropdownMenuTrigger className="w-full">
              {selectedWorkspace?.name || (
                <span className="text-gray-500">Select a workspace...</span>
              )}
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              {workspacesList
                .sort((workspace) =>
                  workspace.id === activeWorkspaceId ? -1 : 1,
                )
                .map((workspace) => (
                  <DropdownMenuItem
                    key={workspace.id}
                    onSelect={() => setSelectedWorkspace(workspace)}
                  >
                    {workspace.name}
                    {workspace.id === activeWorkspaceId ? " (current)" : ""}
                  </DropdownMenuItem>
                ))}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
    </div>
  );
}
