import GroupContentNavbar from "./GroupContentNavbar";
import RequestContentNavbar from "./RequestContentNavbar";

export const ContentNavbar = ({
  isGroup,
  onHistoryPage,
  setSidebarOpen,
}: {
  isGroup: boolean;
  onHistoryPage: boolean;
  setSidebarOpen: (open: boolean) => void;
}) => {
  return (
    <>
      {isGroup ? (
        <GroupContentNavbar
          onHistoryPage={onHistoryPage}
          setSidebarOpen={setSidebarOpen}
        />
      ) : (
        <RequestContentNavbar
          onHistoryPage={onHistoryPage}
          setSidebarOpen={setSidebarOpen}
        />
      )}
    </>
  );
};
