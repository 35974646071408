import {
  CheckCircleIcon,
  ClockIcon,
  ExclamationIcon,
  RefreshIcon,
} from "@heroicons/react/outline";

export const fineTuneJobStatus = (status: string) => {
  let icon, color;
  switch (status) {
    case "finished":
      color = "text-green-400";
      icon = <CheckCircleIcon className={`h-4 w-4 ${color}`} />;
      break;
    case "running":
      color = "text-blue-300";
      icon = <RefreshIcon className={`h-4 w-4 animate-spin ${color}`} />;
      break;
    case "queued":
      color = "text-gray-400";
      icon = <ClockIcon className={`h-4 w-4 ${color}`} />;
      break;
    case "failed":
      color = "text-red-300";
      icon = <ExclamationIcon className={`h-4 w-4 ${color}`} />;
      break;
    default:
      return {};
  }
  return {
    icon: icon,
    color: color,
    status: status,
  };
};
