import { FC, useEffect, useState } from "react";
import { z } from "zod";
import { XIcon } from "@heroicons/react/outline";
import { ENDPOINTS } from "@/api/application-api";
import { Button } from "@/components/ui/button";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { useCreateWorkspaceMember } from "@/queries";

const emailSchema = z.string().email();

type AddWorkspaceMemberProps = {
  setOpen: (isOpen: boolean) => void;
};

const AddWorkspaceMemberModal: FC<AddWorkspaceMemberProps> = ({ setOpen }) => {
  const [addRequestPending, setAddRequestPending] = useState(false);
  const [displayEmailForm, setDisplayEmailForm] = useState(true);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState<string | null>(null);
  const [sendInviteRequestPending, setSendInviteRequestPending] =
    useState(false);
  const [displayProPlanWarning, setDisplayProPlanWarning] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);

  const authContext = useAuth();
  const userContext = useUser();
  const userToken = authContext!.userToken;
  const createWorkspaceMember = useCreateWorkspaceMember(userToken!);

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setOpen(false);
      }
    };

    window.addEventListener("keydown", handleEscape);
    return () => {
      window.removeEventListener("keydown", handleEscape);
    };
  }, [setOpen]);

  const handleAddClick = async () => {
    const validation = emailSchema.safeParse(email);

    if (!validation.success) {
      const message = validation.error.issues
        .map((issue) => issue.message)
        .join(", ");
      setEmailError(message);
      return;
    }

    try {
      setAddRequestPending(true);
      const response = await fetch(ENDPOINTS.get_user_by_email, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({ email: email.trim().toLowerCase() }),
      });

      const data = await response.json();

      if (response.ok) {
        if (!data.is_pro_plan) {
          setSelectedUserId(data.user.id);
          setDisplayEmailForm(false);
          setDisplayProPlanWarning(true);
          return;
        }

        await handleConfirmAddUser(data.user.id);
        setOpen(false);
      } else {
        setDisplayEmailForm(false);
        setDisplayProPlanWarning(false);
        setEmailError(null);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setAddRequestPending(false);
    }
  };

  const handleConfirmAddUser = async (userId: string) => {
    await createWorkspaceMember.mutateAsync({
      user_id: parseInt(userId),
      workspace_id: userContext?.activeWorkspaceId!,
    });
    setOpen(false);
  };

  const handleCancel = () => {
    setDisplayEmailForm(true);
    setDisplayProPlanWarning(false);
    setSelectedUserId(null);
    setEmail("");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSendInvite = async () => {
    try {
      setSendInviteRequestPending(true);
      await fetch(ENDPOINTS.workspace_invite, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({
          email: email.trim().toLowerCase(),
          workspace_id: userContext?.activeWorkspaceId,
        }),
      });
      setOpen(false);
    } catch (error) {
      console.error(error);
    } finally {
      setSendInviteRequestPending(false);
    }
  };

  const renderEmailForm = () => {
    if (!displayEmailForm) return null;

    return (
      <div className="mb-4">
        <label
          className="block text-sm font-medium text-gray-700"
          htmlFor="member_email"
        >
          Member Email
        </label>
        <input
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          id="member_email"
          name="member_email"
          onChange={(e) => {
            setEmail(e.target.value);
            if (emailError) setEmailError(null);
          }}
          placeholder="Enter member's email"
          required
          type="email"
          value={email}
        />
        {emailError && (
          <p className="text-xs italic text-red-500">{emailError}</p>
        )}
        <div className="mt-4 text-center">
          <Button isLoading={addRequestPending} onClick={handleAddClick}>
            Add
          </Button>
        </div>
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <>
        <div className="absolute right-0 top-0 p-2">
          <button
            className="text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            onClick={handleClose}
            type="button"
          >
            <span className="sr-only">Close</span>
            <XIcon aria-hidden="true" className="h-6 w-6" />
          </button>
        </div>
        <div className="mb-4 text-2xl font-semibold">Add Workspace Member</div>
      </>
    );
  };

  const renderSendInviteForm = () => {
    if (displayEmailForm || displayProPlanWarning) return null;

    return (
      <div className="text-center">
        <p className="mb-4 text-sm text-gray-500">
          This person is not signed up yet. They need to be invited to create an
          account first.
        </p>
        <div className="flex justify-center gap-2">
          <Button onClick={handleCancel} variant="outline">
            Cancel
          </Button>
          <Button
            isLoading={sendInviteRequestPending}
            onClick={handleSendInvite}
          >
            Send Invite
          </Button>
        </div>
      </div>
    );
  };

  const renderProPlanWarning = () => {
    if (!displayProPlanWarning) return null;

    return (
      <div className="text-center">
        <p className="mb-4 text-sm text-gray-500">
          This user is not on a pro plan. By adding them to this workspace, you
          will be charged for another pro seat on your account.
        </p>
        <div className="flex justify-center gap-2">
          <Button onClick={handleCancel} variant="outline">
            Cancel
          </Button>
          <Button onClick={() => handleConfirmAddUser(selectedUserId!)}>
            Confirm
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center"
      onClick={handleClose}
    >
      <div className="fixed inset-0 bg-gray-800/30 backdrop-blur-sm"></div>
      <div
        className="relative z-10 mx-4 bg-white p-6 shadow-lg md:mx-auto md:w-full md:max-w-md md:rounded-lg lg:max-w-lg"
        onClick={(e) => e.stopPropagation()}
      >
        {renderHeader()}
        {renderEmailForm()}
        {renderSendInviteForm()}
        {renderProPlanWarning()}
      </div>
    </div>
  );
};

export default AddWorkspaceMemberModal;
