import { cn } from "@/lib/utils";
import { Maximize2 } from "lucide-react";
import { forwardRef } from "react";

export default forwardRef<HTMLButtonElement, React.ComponentProps<"button">>(
  ({ children, className, ...props }, ref) => (
    <button
      ref={ref}
      className={cn("text-gray-500 hover:text-gray-700", className)}
      {...props}
    >
      <Maximize2 className="h-4 w-4" />
    </button>
  ),
);
