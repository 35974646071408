import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./context/auth-context";
import { useUser } from "./context/user-context";

const Logout: FC = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const userContext = useUser();

  useEffect(() => {
    auth?.logout();
    navigate("/login");
  }, [auth, navigate, userContext]);

  return null;
};

export default Logout;
