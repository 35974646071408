import { FC } from "react";
import aiEvent from "@/assets/ai_event.jpg";
import cake from "@/assets/landing-carousel/cake.jpg";
import observeTalk from "@/assets/landing-carousel/observe_jared_talk.jpg";
import wolframInterview from "@/assets/wolfram_interview.png";
import jonDemo from "@/assets/landing-carousel/jon_demo.jpg";
import hat from "@/assets/landing-carousel/hat.jpg";
import tournamentTwo from "@/assets/landing-carousel/tournament_two.jpg";
import buckyCouch from "@/assets/landing-carousel/bucky_couch.jpg";
import logo from "@/assets/logo/logo.png";

const Press: FC = () => {
  return (
    <div className="container mx-auto mb-[300px] max-w-7xl px-4 py-8">
      <div className="grid grid-cols-6 gap-4 md:grid-cols-5">
        <div className="col-span-2 flex flex-col gap-4">
          <div className="h-[300px] w-full overflow-hidden rounded-lg">
            <img
              src={aiEvent}
              alt="AI Event"
              className="h-full w-full object-cover transition-transform duration-300 hover:scale-110 hover:brightness-105"
            />
          </div>
          <div className="h-[150px] w-full overflow-hidden rounded-lg">
            <a
              href="https://www.youtube.com/watch?v=dYmB-87FFpM"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={observeTalk}
                alt="Observe Talk"
                className="h-full w-full object-cover transition-transform duration-300 hover:scale-110 hover:brightness-105"
              />
            </a>
          </div>
          <div className="h-[250px] w-full overflow-hidden rounded-lg">
            <img
              src={tournamentTwo}
              alt="Tournament Two"
              className="h-full w-full object-cover transition-transform duration-300 hover:scale-110 hover:brightness-105"
            />
          </div>
        </div>
        <div className="col-span-2 flex flex-col gap-4 md:col-span-1">
          <div className="flex h-[200px] items-center justify-center rounded-lg bg-[#feec9f]">
            <img
              src={logo}
              alt="PromptLayer Logo"
              className="h-[195px] w-[195px] object-contain"
            />
          </div>
          <div className="h-[300px] w-full overflow-hidden rounded-lg">
            <img
              src={hat}
              alt="Hat"
              className="h-full w-full object-cover transition-transform duration-300 hover:scale-110 hover:brightness-105"
            />
          </div>
          <div className="h-[200px] w-full overflow-hidden rounded-lg">
            <img
              src={cake}
              alt="Cake"
              className="h-full w-full object-cover transition-transform duration-300 hover:scale-110 hover:brightness-105"
            />
          </div>
        </div>
        <div className="col-span-2 flex flex-col gap-4">
          <div className="h-[300px] w-full overflow-hidden rounded-lg">
            <img
              src={jonDemo}
              alt="Jon Demo"
              className="h-full w-full object-cover transition-transform duration-300 hover:scale-110 hover:brightness-105"
            />
          </div>
          <div className="h-[150px] w-full overflow-hidden rounded-lg">
            <a
              href="https://www.youtube.com/watch?v=xKV8_N1b5RI"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={wolframInterview}
                alt="Wolfram Interview"
                className="h-full w-full object-cover transition-transform duration-300 hover:scale-110 hover:brightness-105"
              />
            </a>
          </div>
          <div className="h-[250px] w-full overflow-hidden rounded-lg">
            <img
              src={buckyCouch}
              alt="Bucky Couch"
              className="h-full w-full object-cover transition-transform duration-300 hover:scale-110 hover:brightness-105"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Press;
