import React, { FC, useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import anthropicLogo from "@/assets/integrations/anthropic.png";
import azureLogo from "@/assets/integrations/azure.png";
import cohereLogo from "@/assets/integrations/cohere.png";
import huggingFaceLogo from "@/assets/integrations/hugging_face.png";
import nomicLogo from "@/assets/integrations/nomic.png";
import openaiLogo from "@/assets/integrations/openai.png";
import replicateLogo from "@/assets/integrations/replicate.png";
import llamaMetaLogo from "@/assets/integrations/llama-meta.png";
import geminiLogo from "@/assets/integrations/gemini.png";
import mistralLogo from "@/assets/integrations/mistral.png";
import cloudflareLogo from "@/assets/integrations/cloudflare.png";
import bedrockLogo from "@/assets/integrations/bedrock.png";
import * as S from "./Styles";

const topCarouselItems = [
  { src: anthropicLogo },
  { src: llamaMetaLogo },
  { src: geminiLogo },
  { src: mistralLogo },
  { src: azureLogo },
  { src: openaiLogo },
];

const bottomCarouselItems = [
  { src: nomicLogo },
  { src: huggingFaceLogo },
  { src: cloudflareLogo },
  { src: replicateLogo },
  { src: cohereLogo },
  { src: bedrockLogo },
];

const responsive = {
  large: {
    breakpoint: { max: 2000, min: 1500 },
    items: 6,
  },
  medium: {
    breakpoint: { max: 1500, min: 1100 },
    items: 5,
  },
  small: {
    breakpoint: { max: 1100, min: 750 },
    items: 4,
  },
  extraSmall: {
    breakpoint: { max: 750, min: 550 },
    items: 3,
  },
  extraExtraSmall: {
    breakpoint: { max: 550, min: 0 },
    items: 2,
  },
};

interface CarouselItem {
  isComingSoon?: boolean;
  src: string;
}

const Integrations: FC = () => {
  const [showBottomCarousel, setShowBottomCarousel] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowBottomCarousel(true);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  const renderCarouselContainer = (items: CarouselItem[]) => {
    return (
      <S.CarouselContainer>
        <Carousel
          arrows={false}
          autoPlay
          autoPlaySpeed={3000}
          infinite
          responsive={responsive}
          transitionDuration={700}
        >
          {items.map((item, index) => (
            <S.Item key={index}>
              <S.Image draggable={false} src={item.src} alt={item.src} />
              {item.isComingSoon ? (
                <S.ItemBadge>Coming soon</S.ItemBadge>
              ) : null}
            </S.Item>
          ))}
        </Carousel>
      </S.CarouselContainer>
    );
  };

  return (
    <S.Container>
      <h2 className="mb-2 pl-4 text-2xl font-normal tracking-tight text-white sm:pl-[calc((100%-540px)/2)] md:pl-[calc((100%-720px)/2)] md:text-3xl lg:pl-[calc((100%-960px)/2)] lg:text-4xl xl:pl-[calc((100%-1150px)/2)]">
        Model agnostic
      </h2>
      <p className="mb-5 pl-4 text-base font-light tracking-tight text-gray-200 sm:pl-[calc((100%-540px)/2)] md:pl-[calc((100%-720px)/2)] md:text-lg lg:pl-[calc((100%-960px)/2)] lg:text-xl xl:pl-[calc((100%-1150px)/2)]">
        One prompt template for every model.
      </p>
      {renderCarouselContainer(topCarouselItems)}
      {showBottomCarousel && renderCarouselContainer(bottomCarouselItems)}
    </S.Container>
  );
};

export default Integrations;
