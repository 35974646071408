import { CreatePromptLabel } from "@/components";
import { Breadcrumbs } from "@/components/Breadcrumbs";
import { DeleteTemplate } from "@/components/DeleteTemplate";
import { ReportScore } from "@/types/evaluate";
import { Folder } from "@/types/folders";
import { PromptRegistry } from "@/types/prompt-registry";
import { getStringContent, getTemplateFormat } from "@/utils/utils";
import { Tab } from "@headlessui/react";
import { SortingState } from "@tanstack/react-table";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DeleteVersionLabel } from "../../components/DeleteVersionLabel";
import EmptyTableMessage from "../../components/EmptyTableMessage";
import LoadingSpinner from "../../components/LoadingSpinner";
import { DataTable } from "../../components/ui/data-table";
import { TablePagination } from "../../components/ui/table-pagination";
import { useAuth } from "../../context/auth-context";
import { useUser } from "../../context/user-context";
import {
  useEvaluationScoresForVersions,
  usePromptLabelsDelete,
  usePromptVersion,
  usePromptVersions,
  useRequestCountsForVersions,
  useRequestPrompt,
  useRequests,
} from "../../queries";
import { PromptTemplateHeader } from "./PromptTemplateHeader";
import { PromptTemplateVersionContent } from "./PromptTemplateVersionContent";
import { PromptTemplateVersionList } from "./PromptTemplateVersionList";
import { PromptTemplateVersionRequestsTable } from "./PromptTemplateVersionRequestsTable";
import { PromptTemplateVersionStats } from "./PromptTemplateVersionStats";

type PromptTemplatePageProps = {
  deletePrompt: any;
  page: number;
  pageSize: number;
  promptId: string;
  versionId?: string;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  setSorting: Dispatch<SetStateAction<SortingState>>;
  sorting: SortingState;
  promptRegistry: PromptRegistry;
};

export const PromptTemplatePage = ({
  deletePrompt,
  page,
  pageSize,
  promptId,
  versionId,
  setPage,
  setPageSize,
  setSorting,
  sorting,
  promptRegistry,
}: PromptTemplatePageProps) => {
  const authContext = useAuth();
  const userContext = useUser();
  const navigate = useNavigate();
  const [params, setSearchParams] = useSearchParams();

  const userToken = authContext?.userToken!;
  const workspaceId = userContext.activeWorkspaceId!;
  const promptRegistryId = Number(promptId);
  const promptVersionNumber = Number(versionId);
  const promptVersionsQuery = usePromptVersions(userToken, {
    promptRegistryId: parseInt(promptId),
    workspaceId,
  });

  const promptTemplateVersions =
    promptVersionsQuery.data?.pages.flatMap((page) => page.items) || [];
  const promptVersionQuery = usePromptVersion(
    userToken,
    promptRegistryId,
    promptVersionNumber,
  );
  const handleSetPage = (page: number) => {
    setPage(page);
    const searchParams = new URLSearchParams(params);
    searchParams.set("page", page.toString());
    setSearchParams(searchParams.toString());
  };
  const handleSetPageSize = (pageSize: number) => {
    setPageSize(pageSize);
    const searchParams = new URLSearchParams(params);
    searchParams.set("per_page", pageSize.toString());
    setSearchParams(searchParams.toString());
  };

  const [showVersionList, setShowVersionList] = useState(true);
  const [openDeleteLabelModal, setOpenDeleteLabelModal] = useState(false);
  const [labelToDelete, setLabelToDelete] = useState<{
    labelName: string;
    labelId: number;
    promptId: number;
    versionId: number;
  } | null>(null);
  const deleteLabel = usePromptLabelsDelete(userToken || "");
  const [openLabelForm, setOpenLabelForm] = useState(false);
  const [versionIdForLabel, setVersionIdForLabel] = useState<number | null>(
    null,
  );
  const [openDeleteForm, setOpenDeleteForm] = useState(false);
  const [isXrayMode, setIsXrayMode] = useState(false);
  const promptName = promptRegistry.prompt_name;

  useRequests(userToken, params, workspaceId);

  const requestCounts = useRequestCountsForVersions(promptId, userToken || "");
  const requestCountsData = useMemo(
    () => requestCounts.data ?? [],
    [requestCounts.data],
  );

  const versionEvaluationScores = useEvaluationScoresForVersions(
    promptId,
    userToken || "",
  );
  const versionEvaluationScoresData: { [key: number]: ReportScore | null } =
    useMemo(
      () => versionEvaluationScores.data ?? {},
      [versionEvaluationScores.data],
    );

  const requestsForPrompt = useRequestPrompt(
    promptId,
    promptVersionNumber ? promptVersionNumber.toString() : "",
    userToken || "",
    pageSize,
    page,
    sorting?.[0]?.id,
    sorting?.[0]?.desc ? "desc" : "asc",
  );

  const data = useMemo(() => {
    if (requestsForPrompt?.data) {
      return {
        ...requestsForPrompt.data,
        items: requestsForPrompt.data.items?.map((item: any) => {
          const defaultScore = item.scores
            ? item.scores.find((score: any) => score.default !== undefined)
            : null;
          const otherScores = item.scores
            ? item.scores
                .filter((score: any) => !("default" in score))
                .reduce((acc: any, score: any) => ({ ...acc, ...score }), {})
            : {};
          return {
            ...item,
            score: {
              default: defaultScore ? defaultScore.default : null,
              others: otherScores,
            },
          };
        }),
      };
    }
    return [];
  }, [requestsForPrompt.data]);

  const requests = PromptTemplateVersionRequestsTable({
    data,
    setSorting,
    sorting,
  });

  const stats = PromptTemplateVersionStats({
    promptId,
    versionNumber: promptVersionNumber ? promptVersionNumber : 0,
  });

  const folder = useMemo((): Folder | null => {
    return (
      userContext.folders.find(
        (folder: Folder) => folder.id === promptRegistry.folder_id,
      ) || null
    );
  }, [promptRegistry.folder_id, userContext.folders]);

  const selectedPromptTemplateVersion = promptVersionQuery.data;
  const isChat = selectedPromptTemplateVersion?.prompt_template.type === "chat";
  const isSnippetAvailable =
    (selectedPromptTemplateVersion?.sourced_snippets?.length ?? 0) > 0;
  const templateFormat = selectedPromptTemplateVersion?.prompt_template
    ? getTemplateFormat(selectedPromptTemplateVersion?.prompt_template)
    : "f-string";
  const chatPromptTemplate =
    selectedPromptTemplateVersion?.prompt_template.type === "chat"
      ? selectedPromptTemplateVersion.prompt_template
      : null;
  promptTemplateVersions.sort((a, b) => b.number - a.number);

  const handleDeletePrompt = async () => {
    if (!authContext) return;
    const response = await deletePrompt.mutateAsync({
      id: parseInt(promptId),
    });
    if (response.status === 200 && authContext) {
      const data = await response.json();
      if (data.success) {
        navigate(`/workspace/${workspaceId}/prompt`);
        return;
      }
    } else {
      const errorMessage = await response.json();
      console.log(errorMessage);
    }
  };

  const handleDeleteVersionLabel = async (
    labelName: string,
    labelId: number,
    promptId: number,
    versionId: number,
  ) => {
    setLabelToDelete({ labelName, labelId, promptId, versionId });
    setOpenDeleteLabelModal(true);
  };

  const generatePlaygroundUrl = () => {
    if (!selectedPromptTemplateVersion) return "";
    return `/workspace/${workspaceId}/playground?prompt_version_id=${selectedPromptTemplateVersion.id}`;
  };

  if (promptVersionsQuery.isLoading)
    return (
      <div className="p-5">
        <LoadingSpinner size={5} />
      </div>
    );

  return (
    <div className="p-1">
      <div className="pb-3">
        <Breadcrumbs
          items={
            [
              "Registry",
              "Prompts",
              folder ? folder.name : null,
              promptRegistry.prompt_name,
            ].filter(Boolean) as string[]
          }
          navigateUrl={
            folder
              ? `/workspace/${workspaceId}/prompt-folder/${folder.id}`
              : `/workspace/${workspaceId}/prompt`
          }
        />
      </div>
      <div className="mx-auto lg:pb-5">
        <PromptTemplateHeader
          promptName={promptName}
          templateFormat={templateFormat}
          setOpenDeleteForm={setOpenDeleteForm}
          promptId={promptId}
          prompt={promptRegistry}
          setShowVersionList={setShowVersionList}
          showVersionList={showVersionList}
          promptTemplateVersions={promptTemplateVersions}
          version={promptTemplateVersions.find(
            (version) => version.number === promptVersionNumber,
          )}
          generatePlaygroundUrl={generatePlaygroundUrl}
          total={promptVersionsQuery.data?.pages?.[0].total || 0}
          templateTags={promptRegistry.tags}
          isSnippetAvailable={isSnippetAvailable}
          isXrayMode={isXrayMode}
          setIsXrayMode={setIsXrayMode}
        />
        <div className="grid grid-cols-1 gap-x-6 pb-4 lg:grid-cols-4">
          <Tab.Group vertical>
            {showVersionList ? (
              <PromptTemplateVersionList
                requestCountsData={requestCountsData}
                versionEvaluationScoresData={versionEvaluationScoresData}
                requestsForPrompt={requestsForPrompt}
                selectedVersionNumber={promptVersionNumber || 1}
                handleDeleteVersionLabel={handleDeleteVersionLabel}
                promptId={promptId}
                setOpenLabelForm={setOpenLabelForm}
                setShowVersionList={setShowVersionList}
                versionId={versionId}
                promptTemplateVersions={promptTemplateVersions}
                setVersionIdForLabel={setVersionIdForLabel}
                {...promptVersionsQuery}
              />
            ) : null}
            <Tab.Panels
              className={`max-h-[500px] overflow-y-scroll ${
                showVersionList === true ? "lg:col-span-3" : "lg:col-span-4"
              }`}
            >
              <div className="grid h-full grid-cols-1 grid-rows-2 gap-4">
                <div className="row-span-2">
                  <Tab.Panel className="h-full" static>
                    {promptVersionQuery.isLoading ? (
                      <div className="flex items-center justify-center py-8">
                        <LoadingSpinner size={8} />
                      </div>
                    ) : null}
                    {selectedPromptTemplateVersion === undefined ? null : (
                      <PromptTemplateVersionContent
                        initialProviderBaseURLName={
                          selectedPromptTemplateVersion.provider_base_url_name
                        }
                        text={
                          selectedPromptTemplateVersion.prompt_template.type ===
                          "chat"
                            ? selectedPromptTemplateVersion.prompt_template.messages
                                .map((m) => getStringContent(m))
                                .join("\n")
                            : getStringContent(
                                selectedPromptTemplateVersion.prompt_template,
                              )
                        }
                        model={selectedPromptTemplateVersion.metadata?.model}
                        version_metadata={
                          selectedPromptTemplateVersion.metadata
                            ? Object.fromEntries(
                                Object.entries(
                                  selectedPromptTemplateVersion.metadata,
                                ).filter(([key]) => key !== "model"),
                              )
                            : {}
                        }
                        isChat={isChat}
                        input_variables={
                          selectedPromptTemplateVersion.rendered_template
                            ?.input_variables
                        }
                        chatPromptTemplate={chatPromptTemplate}
                        templateFormat={templateFormat}
                        invalid_snippets={
                          selectedPromptTemplateVersion?.invalid_snippets
                        }
                        sourcedSnippets={
                          selectedPromptTemplateVersion.sourced_snippets
                        }
                        renderedTemplate={
                          selectedPromptTemplateVersion?.rendered_template
                        }
                        isXrayMode={isXrayMode}
                        promptRegistryId={
                          selectedPromptTemplateVersion.prompt_id
                        }
                        promptVersionNumber={
                          selectedPromptTemplateVersion.number
                        }
                      />
                    )}
                  </Tab.Panel>
                </div>
              </div>
            </Tab.Panels>
          </Tab.Group>
        </div>
        <div className="pb-3">{stats}</div>
        <div className="space-y-2 rounded border border-gray-300 p-4">
          <div className="text-lg">
            <div className="space-y-2">
              <DataTable
                table={requests}
                emptyMessage={<EmptyTableMessage />}
                isLoading={requestsForPrompt.isLoading}
              />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <TablePagination
                  table={requests}
                  total={data.total}
                  pages={data.pages}
                  page={data.page}
                  pageSize={data.per_page || pageSize}
                  pageSizeOptions={[5, 10, 20, 30, 40, 50]}
                  setPageIndex={handleSetPage}
                  setPageSize={handleSetPageSize}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {versionIdForLabel ? (
        <CreatePromptLabel
          open={openLabelForm}
          setOpen={setOpenLabelForm}
          promptId={Number(promptId)}
          versionId={versionIdForLabel}
        />
      ) : null}
      {labelToDelete && (
        <DeleteVersionLabel
          open={openDeleteLabelModal}
          setOpen={setOpenDeleteLabelModal}
          labelName={labelToDelete.labelName}
          setDelete={() =>
            deleteLabel.mutate({
              id: labelToDelete.labelId,
              promptId: labelToDelete.promptId,
              versionId: labelToDelete.versionId,
            })
          }
        />
      )}
      <DeleteTemplate
        open={openDeleteForm}
        setOpen={setOpenDeleteForm}
        setDelete={() => handleDeletePrompt()}
        templateName={promptRegistry.prompt_name}
      />
    </div>
  );
};
