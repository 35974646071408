import { Menu, Transition } from "@headlessui/react";
import { TagIcon } from "@heroicons/react/outline";
import { Fragment } from "react";
import { classNames } from "@/utils/strings";

export default function TagsDropdown({
  allTags,
  clearFilters,
  selectedTags,
  toggleTag,
}: {
  allTags: string[];
  clearFilters: () => void;
  selectedTags: string[];
  toggleTag: (tag: string) => void;
}) {
  const isSelected = (tag: string) => selectedTags.includes(tag);

  const renderMenuButton = () => {
    return (
      <div>
        <Menu.Button
          type="button"
          className="mt-2 inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-3 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
          <TagIcon
            className="mr-1 h-4 w-auto text-gray-600"
            aria-hidden="true"
          />
          Tags
        </Menu.Button>
      </div>
    );
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      {renderMenuButton()}
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-2">
            <div className="mb-1 flex border-b pb-2">
              <div className="flex-1">
                <h1 className="px-3 text-sm font-semibold text-gray-900">
                  Filter by tags:
                </h1>
              </div>
              <div className="">
                <h1
                  className="mr-2 cursor-pointer text-sm text-blue-600 hover:text-blue-500"
                  onClick={clearFilters}
                >
                  Clear
                </h1>
              </div>
            </div>
            <div className="max-h-80 overflow-y-auto">
              {allTags.map((tag: string) => (
                <Menu.Item key={tag}>
                  <div
                    className="flex cursor-pointer space-x-2 px-3 py-1 hover:bg-gray-50"
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleTag(tag);
                    }}
                  >
                    <div className="flex h-5 items-center">
                      <input
                        id={tag}
                        type="checkbox"
                        checked={isSelected(tag)}
                        readOnly
                        className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                      />
                    </div>
                    <div
                      className={classNames(
                        isSelected(tag)
                          ? "font-semibold text-gray-900"
                          : "text-gray-700",
                        "block flex-1 text-sm",
                      )}
                    >
                      {tag}
                    </div>
                  </div>
                </Menu.Item>
              ))}
              {allTags.length === 0 && (
                <div className="px-3 py-2 text-xs text-gray-500">
                  No tags found. Use the argument `tags` when publishing a
                  template.
                </div>
              )}
            </div>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
