import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import useActiveWorkspace from "@/hooks/useActiveWorkspace";
import { ReactNode, useState } from "react";
import { FineTuneJobInfo } from "./FineTuneJobInfo";
import { FineTuneJobsList } from "./FineTuneJobsList";
import { FineTuneStepApiKeys } from "./FineTuneStepApiKeys";
import { FineTuneStepHowToFilter } from "./FineTuneStepHowToFilter";
import { FineTuneStepKickOff } from "./FineTuneStepKickOff";
import { UpgradeForFineTuning } from "./UpgradeForFineTuning";

const FineTuneModal = ({ children }: { children: ReactNode }) => {
  const [open, setOpen] = useState(false);
  const [currentModalNavigation, setCurrentModalNavigation] = useState("home");
  const [jobId, setJobId] = useState("");
  const activeWorkspace = useActiveWorkspace();

  const fineTuneModalNavigation: { [key: string]: () => ReactNode } = {
    home: () =>
      !activeWorkspace?.admin_is_free ? (
        <FineTuneJobsList
          navigateTrainNewModel={() => setCurrentModalNavigation("how-to")}
          navigateJobInfoModel={(jobId: string) => {
            setJobId(jobId);
            setCurrentModalNavigation("job-info");
          }}
        />
      ) : (
        <UpgradeForFineTuning />
      ),
    "job-info": () => (
      <FineTuneJobInfo
        navigateFineTuneHome={() => setCurrentModalNavigation("home")}
        jobId={jobId}
      />
    ),
    "how-to": () => (
      <FineTuneStepHowToFilter
        navigateFineTuneHome={() => setCurrentModalNavigation("home")}
        navigateNextStep={() => setCurrentModalNavigation("kick-off")}
      />
    ),
    "kick-off": () => (
      <FineTuneStepKickOff
        navigateFineTuneHome={() => setCurrentModalNavigation("home")}
        navigateNextStep={() => setCurrentModalNavigation("kick-off")}
        navigateApiKeys={() => setCurrentModalNavigation("api-keys")}
        navigateJobInfoModel={(jobId: string) => {
          setJobId(jobId);
          setCurrentModalNavigation("job-info");
        }}
      />
    ),
    "api-keys": () => (
      <FineTuneStepApiKeys
        navigateBack={() => setCurrentModalNavigation("kick-off")}
      />
    ),
  };

  const handleOpenChange = (open: boolean) => {
    setOpen(open);
    setCurrentModalNavigation("home");
  };

  return (
    <Dialog open={open} onOpenChange={handleOpenChange}>
      <DialogTrigger>{children}</DialogTrigger>
      <DialogContent className="max-h-full overflow-auto">
        {fineTuneModalNavigation[currentModalNavigation]()}
      </DialogContent>
    </Dialog>
  );
};

export default FineTuneModal;
