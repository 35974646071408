import { ModelConfig, useAccessibleModels } from "../../modelConfig";
import { useUser } from "@/context/user-context";
import { SelectModelDropdown } from "../ui/custom-dropdown";

export function SelectModelFromConfigsDropdown({
  provider,
  model,
  setModel,
  filterOptions,
}: {
  provider: string | null;
  model: string | null;
  setModel: (model: string) => void;
  filterOptions?: { is_chat?: boolean };
}) {
  const userContext = useUser();
  const userId = userContext.user?.id;
  const modelConfig = useAccessibleModels(userId);
  let models: { [model: string]: ModelConfig } =
    modelConfig[provider ?? "openai"];
  if (filterOptions) {
    for (let modelName in models) {
      // Chat Filter
      if (
        "is_chat" in filterOptions &&
        models[modelName].metadata.isChat !== filterOptions.is_chat
      ) {
        delete models[modelName];
      }
    }
  }
  const modelNames = Object.keys(models);

  return (
    <SelectModelDropdown
      model={model || null}
      setModel={(name) => {
        if (!name) return;
        setModel(name);
      }}
      options={modelNames}
      width="full"
      size="lg"
    />
  );
}
