import { useEffect } from "react";

const useInputRef = (inputRef: React.RefObject<HTMLInputElement>) => {
  useEffect(() => {
    // Remove focus from input on enter
    const handleKeydown = (e: KeyboardEvent) => {
      if (e.key === "Enter" && inputRef.current) {
        inputRef.current.blur();
      }
    };

    document.addEventListener("keydown", handleKeydown);
    return () => {
      document.removeEventListener("keydown", handleKeydown);
    };
  }, [inputRef]);
};

export default useInputRef;
