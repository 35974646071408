import { DatasetEditorContext } from "@/components/DatasetEditor";
import ProgressBar from "@/components/ProgressBar";
import { Button } from "@/components/ui/button";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { useMultipartUpload } from "@/queries";
import { displayErrorToast } from "@/utils/toast";
import { DocumentAddIcon } from "@heroicons/react/solid";
import { useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

const UploadDataButton = () => {
  const { datasetId } = useParams();
  const authContext = useAuth();
  const userContext = useUser();
  const workspaceId = userContext!.activeWorkspaceId;
  const {
    uploadProgress,
    setUploadProgress,
    isUploadLoading,
    setIsUploadLoading,
  } = useContext(DatasetEditorContext);
  const userToken = authContext!.userToken;
  const fileInputRef = useRef<HTMLInputElement>(null);

  const multipartFileUpload = useMultipartUpload(userToken!, workspaceId!);

  const startUpload = async (fileName: string) => {
    const response = await multipartFileUpload.mutateAsync({
      fileName: fileName,
      datasetId: parseInt(datasetId!, 10),
    });
    const { success, uploadId } = await response;
    if (!success) {
      throw new Error("File uploading failed");
    }
    return uploadId;
  };

  const uploadPart = async (
    uploadId: string,
    partNumber: number,
    part: Blob,
    fileName: string,
  ) => {
    const reader = new FileReader();
    reader.readAsDataURL(part);
    return new Promise((resolve, reject) => {
      reader.onload = async () => {
        if (reader.result === null) return;
        try {
          const [, base64] = (reader.result as string).split(",");
          const response = await multipartFileUpload.mutateAsync({
            uploadId,
            fileName,
            partNumber,
            part: base64,
            datasetId: parseInt(datasetId!, 10),
          });
          const { success, ETag } = await response;
          if (!success) {
            reject("File uploading failed");
          }
          resolve(ETag);
        } catch (error) {
          reject("File uploading failed");
        }
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const completeUpload = async (
    fileName: string,
    uploadId: string,
    parts: { ETag: unknown; PartNumber: number }[],
  ) => {
    const response = await multipartFileUpload.mutateAsync({
      uploadId,
      fileName,
      parts,
      datasetId: parseInt(datasetId!, 10),
    });
    const { success } = await response;
    if (!success) {
      throw new Error("File uploading failed");
    }
  };

  const uploadFile = async (file: { size: any; slice: any; name: any }) => {
    const partSize = 5 * 1024 * 1024; // 5MB
    const totalParts = Math.ceil(file.size / partSize);
    let uploadedParts = 0;

    const fileExtension = file.name.split(".").pop();
    const uuidFileName = `${uuidv4()}.${fileExtension}`;

    try {
      const uploadId: string = await startUpload(uuidFileName);

      const parts = [];
      for (let partNumber = 1; partNumber <= totalParts; partNumber++) {
        const start = (partNumber - 1) * partSize;
        const end = Math.min(start + partSize, file.size);
        const part = file.slice(start, end);

        const ETag = await uploadPart(uploadId, partNumber, part, uuidFileName);
        parts.push({ ETag, PartNumber: partNumber });
        uploadedParts += 1;
        const progress = Math.round((uploadedParts / totalParts) * 100);
        setUploadProgress(progress);
      }

      await completeUpload(uuidFileName, uploadId, parts);
    } catch (error) {
      throw error;
    }
  };

  const handleFileChange = async (event: any) => {
    const file = event.target.files[0];
    if (!file) return;
    setUploadProgress(0);
    if (file?.size > 2.5e8) {
      displayErrorToast("File size must not exceed 250 MB.");
      return;
    }

    if (
      file &&
      (file.type === "application/json" || file.type === "text/csv")
    ) {
      setIsUploadLoading(true);
      if (file.type === "application/json") {
        try {
          const fileContent = await file.text();
          const jsonContent = JSON.parse(fileContent);

          if (
            !Array.isArray(jsonContent) ||
            jsonContent.length === 0 ||
            typeof jsonContent[0] !== "object"
          ) {
            displayErrorToast("JSON file must contain an array of objects.");
            return;
          }
        } catch (error) {
          displayErrorToast("Invalid JSON file.");
          return;
        }
      }
      try {
        await uploadFile(file);
      } catch (error) {
        displayErrorToast("There was an error uploading the file");
        setIsUploadLoading(false);
      }
    } else {
      displayErrorToast("Only .json and .csv files are accepted.");
    }
  };

  const handleImportDataClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    } else {
      console.error("File input is not available");
    }
  };
  return (
    <>
      <Button
        disabled={multipartFileUpload.isLoading || isUploadLoading}
        onClick={handleImportDataClick}
        size="sm"
        variant="outline"
      >
        {isUploadLoading ? (
          <ProgressBar isLoading={isUploadLoading} progress={uploadProgress} />
        ) : (
          <>
            <DocumentAddIcon aria-hidden="true" className="h-4 w-auto pr-1" />
            Upload data
          </>
        )}
      </Button>
      <input
        onChange={handleFileChange}
        ref={fileInputRef}
        style={{ display: "none" }}
        type="file"
      />
    </>
  );
};

export default UploadDataButton;
