import styled from "styled-components";

export const FeatureContainer = styled.div`
  padding: 50px 0;
  background: #fff;

  text-align: center;

  margin-left: auto;
  margin-right: auto;
  width: 100%;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1150px;
  }
`;

export const FeatureHeading = styled.h1`
  color: #090b1c;
  font-size: 3rem;
  font-style: normal;
  font-weight: 500;
  line-height: 5.0625rem;
  letter-spacing: -0.06rem;
  text-transform: capitalize;
  text-align: center;
`;

export const FeatureGridContainer = styled.section`
  display: flex;
  flex-direction: column;
  margin: 40px 0px;

  @media (max-width: 991px) {
    margin: 30px 10px;
  }
`;

export const FeatureGridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;

  @media (max-width: 991px) {
    grid-template-columns: 1fr;
  }
`;

export const FeatureCardWrapper = styled.div`
  border-radius: 15px;
  background-color: rgba(239, 240, 248, 1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 18px;
  border: 1px solid rgba(208, 209, 216, 0.46);
  text-align: left;

  &:hover {
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.09);
    border: 1px solid rgba(208, 209, 216, 0.46);
  }

  @media (max-width: 991px) {
    margin-top: 15px;
  }
`;

export const FeatureCardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

export const FeatureCardIcon = styled.span`
  color: #5167f0;
  margin-right: 10px;
`;

export const FeatureCardTitle = styled.h3`
  color: rgba(22, 25, 35, 1);
  font-size: 18px;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: -0.36px;
  margin: 0;
`;

export const FeatureCardDescription = styled.p`
  color: rgba(102, 102, 102, 1);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.14px;
  margin: 0;
  text-align: left;
`;
