import { DatasetEditorContext } from "@/components/DatasetEditor";
import { Button } from "@/components/ui/button";
import { useAuth } from "@/context/auth-context";
import { ToastType } from "@/enums";
import { downloadDataset } from "@/queries";
import { displayToast } from "@/utils/toast";
import { DownloadIcon } from "lucide-react";
import { useCallback, useContext, useState } from "react";
import { useParams } from "react-router-dom";

const DownloadButton = () => {
  const { datasetId } = useParams<{
    datasetId: string;
  }>();
  const auth = useAuth();
  const userToken = auth?.userToken;
  const { rows } = useContext(DatasetEditorContext);
  const [isDownloading, setIsDownloading] = useState(false);
  const handleDownloadClick = useCallback(async () => {
    try {
      setIsDownloading(true);
      await downloadDataset(userToken!, datasetId!);
      displayToast("Dataset downloaded", ToastType.success);
    } catch (error) {
      console.error("Failed to initiate dataset download:", error);
    } finally {
      setIsDownloading(false);
    }
  }, [datasetId, userToken]);

  return (
    (!!rows.length && (
      <Button
        isLoading={isDownloading}
        onClick={handleDownloadClick}
        size="sm"
        variant="outline"
      >
        <DownloadIcon aria-hidden="true" className="h-4 w-auto pr-1" />
        Download
      </Button>
    )) ||
    null
  );
};

export default DownloadButton;
