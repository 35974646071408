import React from "react";
import * as RadioGroup from "@radix-ui/react-radio-group";

export const ScoreRadioGroup = ({
  resetScore,
  setResetScore,
}: {
  resetScore: boolean;
  setResetScore: (value: boolean) => void;
}) => (
  <form>
    <RadioGroup.Root
      className="flex flex-col space-y-6"
      defaultValue="true"
      aria-label="View density"
      value={resetScore.toString()}
      onValueChange={(value) => {
        setResetScore(value === "true");
      }}
    >
      <div className="flex items-center justify-start">
        <RadioGroup.Item
          className="border-red-500::selection flex h-[20px] w-[20px] items-center justify-center rounded-full border border-gray-500 bg-white focus:border-2 focus:border-blue-500"
          value="true"
          id="r1"
        >
          <RadioGroup.Indicator className="relative inline-block h-[8px] w-[8px] rounded-full bg-blue-500" />
        </RadioGroup.Item>
        <label className="ml-2" htmlFor="r1">
          No Score
        </label>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <RadioGroup.Item
          className="flex h-[20px] w-[20px] items-center justify-center rounded-full border border-gray-500 bg-white focus:border-2 focus:border-blue-500"
          value="false"
          id="r2"
        >
          <RadioGroup.Indicator className="relative inline-block h-[8px] w-[8px] rounded-full bg-blue-500" />
        </RadioGroup.Item>
        <label className="ml-2" htmlFor="r2">
          Score
        </label>
      </div>
    </RadioGroup.Root>
  </form>
);
