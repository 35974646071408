import styled from "styled-components";

export const Container = styled.button`
  background-color: #5167f0;
  color: white;
  padding: 0.5rem 1.5rem;
  border-radius: 0.375rem;
  transition: background-color 0.3s ease;
  box-shadow: 0px 2px 4px 0px rgba(50, 62, 135, 0.19);

  &:hover {
    background-color: #6b7ef5;
  }
`;
