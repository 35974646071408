import { FC } from "react";
import * as S from "./Styles";

type OutlineButtonProps = {
  children: string;
};

const OutlineButton: FC<OutlineButtonProps> = ({ children }) => {
  return <S.Container>{children}</S.Container>;
};

export default OutlineButton;
