import DatasetColumnModal from "@/components/DatasetColumnModal";
import { Button } from "@/components/ui/button";
import { PlusIcon } from "@heroicons/react/solid";
import { useState } from "react";
import { useParams } from "react-router-dom";

const AddColumnButton = () => {
  const { datasetId } = useParams();
  const [isDatasetColumnModalOpen, setDatasetColumnModalOpen] = useState(false);
  return (
    <>
      <Button
        className="my-2 whitespace-nowrap"
        onClick={() => setDatasetColumnModalOpen(true)}
        size="sm"
        variant="outline"
      >
        <PlusIcon aria-hidden="true" className="h-4 w-auto pr-1" />
        Add Column
      </Button>
      {datasetId && isDatasetColumnModalOpen && (
        <DatasetColumnModal
          dataset_id={parseInt(datasetId)}
          setOpen={setDatasetColumnModalOpen}
        />
      )}
    </>
  );
};

export default AddColumnButton;
