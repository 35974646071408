import { ChooseTemplateModal } from "@/components/ChooseTemplateModal";
import { PromptTemplateConfiguration } from "@/types/evaluate";
import { Link } from "lucide-react";
import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";

export function ChooseSnippetTemplateModal({
  currentPromptText,
  setCurrentPromptText,
  open,
  setOpen,
  cursorPosition,
  sourceTemplateId = null,
}: {
  currentPromptText: string;
  setCurrentPromptText: (newPromptText: string) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
  cursorPosition: number | null;
  sourceTemplateId?: number | null;
}) {
  const [configuration, setConfiguration] = useState<
    Partial<PromptTemplateConfiguration>
  >({
    template: {
      name: "",
    },
  });

  const handleSetConfiguration = (
    data:
      | Partial<PromptTemplateConfiguration>
      | ((
          prevState: Partial<PromptTemplateConfiguration>,
        ) => Partial<PromptTemplateConfiguration>),
  ) => {
    if (typeof data === "function") {
      setConfiguration(data(configuration || {}));
    } else {
      setConfiguration(data);
    }
  };

  const buildSnippet = () => {
    // NOTE: It should start with @@ and end with @@@ because there's already an @
    // when this gets triggered.
    let snippet = `@@${configuration.template?.name}`;
    if (configuration.template?.label) {
      snippet += `@label:${configuration.template?.label}`;
    }
    if (configuration.template?.version_number) {
      snippet += `@version_number:${configuration.template?.version_number}`;
    }
    snippet += "@@@";
    console.log(snippet);
    return snippet;
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="overflow-x-hidden px-10 transition-all duration-500 ease-in-out">
        <DialogHeader>
          <DialogTitle>Link a snippet</DialogTitle>
          <DialogDescription className="mt-1 text-sm text-gray-500">
            Use a prompt template as a reference snippet in your current
            template, compiled at runtime.
            <a
              href="https://docs.promptlayer.com/features/prompt-registry/snippets"
              target="_blank"
              rel="noreferrer"
              className="text-sm text-blue-500 hover:text-blue-400"
            >
              {" "}
              Learn more. <Link className="inline h-4 w-4 pl-1" />
            </a>
          </DialogDescription>
        </DialogHeader>
        <div className="py-2">
          <ChooseTemplateModal
            navigateAway={() => {
              setOpen(false);
              if (cursorPosition && configuration?.template?.name) {
                setCurrentPromptText(
                  currentPromptText.slice(0, cursorPosition) +
                    buildSnippet() +
                    currentPromptText.slice(cursorPosition),
                );
              }
            }}
            navigateBack={() => setOpen(false)}
            submitText="Add"
            cancelText="Cancel"
            editable={true}
            configuration={configuration}
            setConfiguration={handleSetConfiguration}
            showSnippetWarning={true}
            sourceTemplateId={sourceTemplateId}
            isSnippet={true}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}
