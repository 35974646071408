import styled from "styled-components";

import footerBg from "@/assets/footer-bg.png";

export const Button = styled.button`
  background: #5167f0;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(50, 62, 135, 0.19);
  color: #ffffff;
  display: inline-block;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.01em;
  padding: 10px 40px;
  transition: 0.2s all ease;
  white-space: nowrap;

  &:hover {
    opacity: 0.75;
  }

  @media (max-width: 575px) {
    margin-top: 30px;
  }

  @media (max-width: 767px) {
    font-size: 16px;
    padding: 10px 30px;
  }
`;

export const Container = styled.footer`
  background: #e8e9f2;
  padding: 10px 0 130px;

  @media (max-width: 767px) {
    padding-bottom: 70px;
  }
`;

export const Copyright = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-top: 36px;

  p {
    color: #666666;
    font-size: 16px;
    font-weight: 400;
    margin: 0;

    @media (max-width: 767px) {
      font-size: 15px;
    }
  }
`;

export const InnerContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1150px;
  }
`;

export const FooterItem = styled.div`
  white-space: nowrap;

  a {
    color: #757566;
    display: inline-block;
    font-size: 18px;
    font-weight: 400;
    margin-top: 10px;
    transition: 0.2s all ease;

    &:hover {
      color: #000;
    }

    @media (max-width: 767px) {
      font-size: 16px;
    }
  }

  h4 {
    color: #000000;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.01em;
    margin-bottom: 20px;

    @media (max-width: 767px) {
      font-size: 18px;
    }
  }

  &:nth-child(2) {
    @media (max-width: 767px) {
      grid-column: 1;
      grid-row: 2;
    }
  }

  &:nth-child(3) {
    @media (max-width: 767px) {
      grid-column: 2;
      grid-row: 2;
    }
  }

  &:nth-child(4) {
    @media (max-width: 767px) {
      grid-column: 1;
      grid-row: 3;
    }
  }
`;

export const FooterLeft = styled.div`
  a {
    color: #5167f0;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    transition: 0.2s all ease;
  }

  p {
    color: #666666;
    font-size: 18px;
    font-weight: 400;
    margin: 20px 0 10px;
  }

  @media (max-width: 767px) {
    grid-column: span 2;
    grid-row: 1;

    a,
    p {
      font-size: 16px;
    }
  }
`;

export const FooterMain = styled.div`
  border-bottom: 1px solid rgba(117, 117, 117, 0.25);
  display: grid;
  gap: 30px;
  grid-template-columns: 3fr 2fr 2fr 2fr;
  padding: 140px 0 70px;

  @media (max-width: 767px) {
    gap: 45px;
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const FooterUpper = styled.div`
  align-items: center;
  background-image: url(${footerBg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px;
  box-shadow: 6px 6px 15px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  margin-top: -230px;
  padding: 85px 150px 85px 80px;
  position: relative;
  z-index: 9;

  h2 {
    color: #8f97c6;
    font-size: 60px;
    font-weight: 400;
    letter-spacing: -0.02em;
    line-height: 1.2;
    margin: 0;
    max-width: 560px;
    text-transform: capitalize;
    width: 100%;

    span {
      color: #fff;
    }

    @media (max-width: 992px) {
      font-size: 30px;
      max-width: 270px;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 36px;
      max-width: 320px;
    }
  }

  @media (max-width: 575px) {
    align-items: flex-start;
    flex-direction: column;
    padding: 54px 21px;
  }

  @media (max-width: 767px) {
    padding: 60px 25px;
  }

  @media (max-width: 991px) {
    padding: 70px 30px;
  }
`;

export const Link = styled.a``;

export const Logo = styled.img`
  max-width: 192px;

  @media (max-width: 767px) {
    max-width: 160px;
  }
`;

export const SocialIcons = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
    padding-right: 10px;

    a {
      color: #757566;
      display: inline-block;
      font-size: 18px;
      font-weight: 400;
      margin-top: 10px;
      transition: 0.2s all ease;

      img {
        background-color: #666666;
        border-radius: 50%;
        border: 2px solid #666666;
        max-width: 35px;
        padding: 5px;
        transition: 0.2s all ease;
      }
    }

    @media (max-width: 767px) {
      padding-left: 8px;
    }
  }
`;
