import { PropsWithChildren } from "react";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";
import { UserProvider } from "@/context/user-context";

export default function SharableRequestLayout({
  children,
}: PropsWithChildren<{}>) {
  const { shareHash } = useParams();

  return (
    <>
      <UserProvider>
        <Helmet>
          <meta
            property="og:image"
            content={"%PUBLIC_URL%/preview.png?share_hash=" + shareHash}
          />
          <meta property="og:title" content="PromptLayer - Shared prompt" />
        </Helmet>
        <div className="h-full bg-gray-100">
          <div className="h-full w-full">
            <div className="mx-auto h-full max-w-6xl lg:py-5">{children}</div>
          </div>
        </div>
      </UserProvider>
    </>
  );
}
