import { ExclamationCircleIcon } from "@heroicons/react/outline";
import React from "react";

import { HomeIcon } from "@heroicons/react/solid";
import { useRouteError } from "react-router-dom";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "./accordion";

interface ErrorFallbackProps {
  error?: Error;
  resetErrorBoundary?: () => void;
}

function standardizeRouteError(error: unknown) {
  if (error instanceof Error) {
    return {
      message: error.message,
      stack: error.stack,
      name: error.name,
    };
  }

  if (typeof error === "string") {
    return {
      message: error,
      name: "Unknown Error",
    };
  }

  if (typeof error === "object" && error !== null) {
    return {
      message:
        "statusText" in error
          ? String(error.statusText)
          : "message" in error
          ? String(error.message)
          : "An unknown error occurred",
      stack: "stack" in error ? String(error.stack) : undefined,
      name: "name" in error ? String(error.name) : "UnknownError",
    };
  }

  return {
    message: "An unknown error occurred",
    name: "Unknown Error",
  };
}

export const RouteErrorFallback = () => {
  const error: { message: string; stack?: string; name: string } =
    standardizeRouteError(useRouteError());

  if (error.stack) {
    return <ErrorFallback error={error} />;
  }
  return error as unknown as JSX.Element;
};

const Footer: React.FC = () => {
  return (
    <footer className="body-font mx-auto bg-white text-center text-gray-600">
      <div className="container mx-auto flex flex-col items-center px-5 py-8 sm:flex-row">
        <p className="sm:pluck-0 text-sm text-gray-500 sm:ml-4">
          <a
            href="https://promptlayer.com"
            className="text-gray-500 hover:text-blue-400"
          >
            🍰 PromptLayer
          </a>{" "}
          · Made in NYC · © {new Date().getFullYear()} All Rights Reserved
        </p>
      </div>
    </footer>
  );
};

const ErrorFallback: React.FC<ErrorFallbackProps> = (props) => {
  let stackTrace: string = "";
  const message = props.error?.message;
  const name = props.error?.name || "Error";
  if (props.error) {
    stackTrace = props.error.stack || "";
  }

  return (
    <div className="mx-auto flex h-full max-w-md flex-col ">
      <div className="flex h-full flex-col items-center justify-center">
        <ExclamationCircleIcon className="h-10 w-10 text-red-500" />
        <h1 className="mt-4 text-2xl font-semibold text-gray-800">
          An error occured
        </h1>
        <p className="mt-2 inline-grid w-auto text-sm text-gray-500">
          Something went wrong! Try again or contact us at{` `}
          <a
            className="text-blue-500 hover:text-blue-400"
            href="mailto:hello@promptlayer.com"
          >
            hello@promptlayer.com
          </a>
        </p>
        {stackTrace && stackTrace.length > 0 && (
          <Accordion
            type="single"
            collapsible
            className="flex  w-full items-start "
          >
            <AccordionItem value="stacktrace" className="float-left border-b-0">
              <AccordionTrigger className="text-xs text-gray-500 hover:text-gray-700">
                View Stack Trace
              </AccordionTrigger>
              <AccordionContent className="max-h-64 overflow-y-auto">
                {(message && `${name}: ${message}`) || ""}
                <pre className="whitespace-pre-wrap break-all text-sm text-gray-500">
                  {stackTrace}
                </pre>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        )}
        <div className="mt-4">
          <button
            className="rounded bg-gray-200 px-4 py-2 font-semibold text-gray-700 hover:bg-gray-300"
            onClick={() => (window.location.href = "https://promptlayer.com")}
          >
            <HomeIcon className="-mt-1 inline-block h-4 w-4 align-middle" />
            <span className="pl-2">Go Home</span>
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ErrorFallback;
