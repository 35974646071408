import styled from "styled-components";
import { MenuIcon as UMenuIcon } from "@heroicons/react/outline";

export const Container = styled.div<{ $isScrolled: boolean }>`
  background: #fff;
  box-shadow: ${({ $isScrolled }) =>
    $isScrolled ? "0 15px 10px -15px rgba(0, 0, 0, 0.2)" : "none"};
  display: flex;
  justify-content: center;
  padding: 10px 0;
  position: sticky;
  top: 0;
  transition: 0.3s all ease;
  width: 100%;
  z-index: 999;
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 8px 15px;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1150px;
  }
`;

export const Logo = styled.img`
  max-width: 192px;
`;

export const MenuButton = styled.button`
  background: none;
  border: none;
  color: #000000;
  display: none;
  font-size: 32px;

  @media (max-width: 992px) {
    display: block;
  }
`;

export const MenuIcon = styled(UMenuIcon)`
  width: 40px;
`;

export const NavItems = styled.ul`
  display: flex;
  flex-direction: row;
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    padding: 10px 22px;

    a {
      color: #000000;
      font-size: 18px;
      font-weight: 400;
      letter-spacing: -0.02em;
      transition: 0.2s all ease;

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }

  @media (max-width: 992px) {
    display: none;
  }
`;

export const RightItems = styled.nav`
  align-items: baseline;
  display: flex;
  gap: 20px;

  a {
    color: #666666;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: -0.02em;
    transition: background-color 0.3s;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }

  @media (max-width: 992px) {
    display: none;
  }
`;
