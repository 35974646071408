import { Button } from "@/components/ui/button";
import { useUser } from "@/context/user-context";

import { useEffect, useRef, useState } from "react";

import {
  TableWithoutWrapper,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { TrashIcon } from "@heroicons/react/outline";
import DeletePromptLayerApiKeyModal from "./DeletePromptLayerApiKeyModal";
import moment from "moment";

export const PromptLayerApiKeyTable = () => {
  const userContext = useUser();

  const [isDeleteApiKeyModalOpen, setDeleteApiKeyModalOpen] =
    useState<boolean>(false);
  const [selectedApiKeyId, setSelectedApiKeyId] = useState<number | null>(null);

  const apiKeys = userContext.promptLayerWorkspaceApiKeys;

  const tableRef = useRef<HTMLTableElement>(null);

  useEffect(() => {
    if (tableRef.current) {
      const table = tableRef.current;
      const columns = table.getElementsByTagName("th");
      const columnWidth = 100 / columns.length + "%";

      for (let i = 0; i < columns.length; i++) {
        columns[i].style.width = columnWidth;
      }
    }
  }, []);

  const handleDelete = (id: number) => {
    setDeleteApiKeyModalOpen(true);
    setSelectedApiKeyId(id);
  };

  return (
    <>
      <div className="mt-4 max-h-[450px] overflow-x-auto overflow-y-scroll rounded-md border">
        <TableWithoutWrapper ref={tableRef} className="min-w-full">
          <TableHeader className="sticky top-0 z-10 bg-white shadow">
            <TableRow>
              <TableHead>API Key</TableHead>
              <TableHead>User</TableHead>
              <TableHead>Created At</TableHead>
              <TableHead>Last Active</TableHead>
              <TableHead />
            </TableRow>
          </TableHeader>
          <TableBody>
            {apiKeys.length > 0 ? (
              apiKeys
                .sort(
                  (a, b) =>
                    new Date(b.created_at).getTime() -
                    new Date(a.created_at).getTime(),
                )
                .map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>********</TableCell>
                    <TableCell className="pr-6">{item.email}</TableCell>
                    <TableCell>
                      <span
                        title={moment(item.created_at).format(
                          "MMMM D, YYYY, HH:mm:ss",
                        )}
                      >
                        {moment(item.created_at).format("MMMM D, YYYY")}
                      </span>
                    </TableCell>
                    <TableCell>
                      <span
                        title={moment(item.last_active).format(
                          "MMMM D, YYYY, HH:mm:ss",
                        )}
                      >
                        {moment(item.last_active).format("MMMM D, YYYY")}
                      </span>
                    </TableCell>
                    <TableCell className="text-right">
                      <Button
                        className="inline-flex items-center"
                        onClick={() => handleDelete(item.id)}
                        size="sm"
                        variant="destructiveOutline"
                      >
                        <TrashIcon
                          className="h-4 w-auto pr-1"
                          aria-hidden="true"
                        />
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} className="px-4 py-2 text-center">
                  No PromptLayer API keys found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </TableWithoutWrapper>
      </div>
      {isDeleteApiKeyModalOpen && selectedApiKeyId && (
        <DeletePromptLayerApiKeyModal
          open={isDeleteApiKeyModalOpen}
          promptLayerApiKeyId={selectedApiKeyId}
          setOpen={setDeleteApiKeyModalOpen}
        />
      )}
    </>
  );
};

export const reformatDate = (datetime: string) => {
  const formattedDate = moment(
    datetime,
    "ddd, DD MMM YYYY HH:mm:ss [GMT]",
  ).format("MMMM D, YYYY");
  return formattedDate;
};
