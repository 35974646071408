const ProgressBar = ({
  isLoading,
  progress,
}: {
  isLoading: boolean;
  progress: number;
}) => {
  return (
    <div
      className={`ml-auto w-[200px] px-4 ${isLoading ? "animate-pulse" : ""}`}
    >
      <div
        className={`flex h-2 overflow-hidden rounded text-xs ${
          isLoading ? "bg-blue-200" : "bg-red-400"
        }`}
      >
        <div
          style={{
            width: `${progress}%`,
          }}
          className={`flex flex-col justify-center whitespace-nowrap text-center text-white shadow-none ${
            isLoading ? "bg-blue-500" : "bg-green-400"
          }`}
        ></div>
      </div>
    </div>
  );
};

export default ProgressBar;
