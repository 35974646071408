import { ChatTemplate, Message } from "@/types";
import { useEffect, useState } from "react";
import { MessageDisplay } from "./components/Chat/MessageDisplay";
import { MessageHeader } from "./components/Chat/MessageHeader";
import { highlightRequestVariables } from "./components/InputVariableHighlighting/highlightRequestVariables";
import { preStyles } from "./utils/preStyles";
import { getStringContent } from "./utils/utils";

export const Chat = ({
  inputVariables,
  startOpen = false,
  highlight = false,
  isOpen,
  onToggle,
  message,
  chatTemplate,
}: {
  inputVariables?: Record<string, string>;
  startOpen?: boolean;
  highlight?: boolean;
  isOpen?: boolean;
  onToggle?: () => void;
  message?: Message;
  chatTemplate: ChatTemplate;
}) => {
  const [componentIsOpen, setComponentIsOpen] = useState<boolean>(startOpen);

  const handleToggle = () => {
    if (onToggle) {
      onToggle();
    } else {
      setComponentIsOpen(!componentIsOpen);
    }
  };
  useEffect(() => {
    if (isOpen !== undefined && !!isOpen !== componentIsOpen) {
      setComponentIsOpen(!!isOpen);
    }
  }, [isOpen, setComponentIsOpen, componentIsOpen]);

  const role = message?.raw_request_display_role || message?.role || "";
  const content = message ? getStringContent(message) : "";
  const highlightedContent = inputVariables
    ? highlightRequestVariables(content, inputVariables)
    : content;

  return (
    <MessageHeader
      componentIsOpen={componentIsOpen}
      handleToggle={handleToggle}
      message={message}
      chatTemplate={chatTemplate}
    >
      {componentIsOpen ? (
        <pre
          className={`whitespace-pre-wrap rounded-md ${preStyles(
            role,
          )} p-2 text-black`}
        >
          <MessageDisplay
            componentIsOpen={true}
            highlightedContent={highlightedContent}
            highlight={highlight}
            message={message}
            chatTemplate={chatTemplate}
          />
        </pre>
      ) : (
        <MessageDisplay
          componentIsOpen={false}
          highlightedContent={highlightedContent}
          highlight={highlight}
          message={message}
          chatTemplate={chatTemplate}
        />
      )}
    </MessageHeader>
  );
};
