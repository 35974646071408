import { TrashIcon } from "@heroicons/react/outline";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useCallback, useContext, useMemo, useState } from "react";

import DatasetColumnToJsonModal from "@/components/DatasetColumnToJsonModal";
import EditDatasetRowModal from "@/components/EditDatasetRowModal";
import {
  DropdownMenu,
  DropdownMenuBasicTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from "@/components/ui/dropdown-menu";
import { useAuth } from "@/context/auth-context";
import { ToastType } from "@/enums";
import { useDeleteDatasetRow } from "@/queries";
import { DatasetRead } from "@/types/datasets";
import { displayToast } from "@/utils/toast";
import { ChevronDownIcon } from "@heroicons/react/solid";
import DatasetTablePagination from "components/DatasetTablePagination";
import DatasetViewerTable from "components/DatasetViewerTable";
import { EditIcon } from "lucide-react";
import { DatasetEditorContext } from "../..";
import AddColumnButton from "./AddColumnButton";
import { RenameColumn } from "./RenameColumn";

interface DatasetTableProps {
  activeDataset: DatasetRead | undefined;
  page: number;
  pageSize: number;
  pages: number;
  rows: any[];
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  total: number;
}

const columnHelper = createColumnHelper<any>();

const DatasetTable = ({
  activeDataset,
  rows,
  page,
  pageSize,
  pages,
  setPage,
  setPageSize,
  total,
}: DatasetTableProps) => {
  const { isLoading, isDraft, isUploadLoading } =
    useContext(DatasetEditorContext);

  const [activeColumnName, setActiveColumnName] = useState<string | null>(null);
  const [activeDatasetRowId, setActiveDatasetRowId] = useState<number | null>(
    null,
  );
  const [isDatasetColumnToJsonModalOpen, setDatasetColumnToJsonModalOpen] =
    useState(false);
  const [isDatasetRenameModalOpen, setDatasetRenameModalOpen] = useState(false);
  const [isEditDatasetRowModalOpen, setEditDatasetRowModalOpen] =
    useState(false);
  const [variables, setVariables] = useState<Record<string, any>>({});
  const authContext = useAuth();
  const deleteDatasetRow = useDeleteDatasetRow(authContext!.userToken!);

  const handleConvertToJsonClick = (columnName: string) => {
    setActiveColumnName(columnName);
    setDatasetColumnToJsonModalOpen(true);
  };

  const handleRenameClick = (columnName: string) => {
    setActiveColumnName(columnName);
    setDatasetRenameModalOpen(true);
  };

  const handleDeleteClick = useCallback(
    async (datasetRowId: number) => {
      await deleteDatasetRow.mutateAsync(datasetRowId);
      displayToast("Row deleted", ToastType.success);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleEditClick = useCallback((rowData: any) => {
    const { dataset_row_id, ...cleanRowData } = rowData;
    setActiveDatasetRowId(rowData.dataset_row_id);
    setVariables(cleanRowData);
    setEditDatasetRowModalOpen(true);
  }, []);

  const columns = useMemo(() => {
    const keys = rows.length ? Object.keys(rows[0]) : [];
    const filteredKeys = keys.filter((key) => key !== "dataset_row_id");

    const baseColumns = filteredKeys.map((key) =>
      columnHelper.accessor(
        (row) => {
          return row[key];
        },
        {
          id: key,
          header: () => {
            return (
              <DropdownMenu>
                <DropdownMenuBasicTrigger>
                  <div className="flex items-center justify-between">
                    <span>{key}</span>
                    <ChevronDownIcon className="h-4 w-4 text-gray-500" />
                  </div>
                </DropdownMenuBasicTrigger>
                <DropdownMenuContent align="start">
                  <DropdownMenuItem
                    className={"w-full"}
                    onClick={() => handleRenameClick(key)}
                  >
                    Rename
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    className={"w-full"}
                    onClick={() => handleConvertToJsonClick(key)}
                  >
                    Convert to JSON
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            );
          },
          cell: (props) => {
            const value = props.renderValue();

            if (typeof value === "object" || Array.isArray(value)) {
              return JSON.stringify(value);
            } else {
              return value;
            }
          },
        },
      ),
    );

    // For draft datasets, add a new column for the action button
    if (activeDataset?.version_number === -1) {
      baseColumns.push(
        columnHelper.accessor("_addCol", {
          header: () => <AddColumnButton />,
          cell: (props) => <div className="fixed flex space-x-2"></div>,
        }),
      );
      baseColumns.unshift(
        columnHelper.accessor("_", {
          header: "",
          cell: (props) =>
            baseColumns.length > 2 && (
              <div className="hidden items-center  justify-center space-x-2 group-hover:flex">
                <EditIcon
                  aria-hidden="true"
                  className="h-4 w-auto cursor-pointer hover:text-blue-500"
                  onClick={() => handleEditClick(props.row.original)}
                />
                <TrashIcon
                  aria-hidden="true"
                  className="h-4 w-auto cursor-pointer hover:text-red-500"
                  onClick={() =>
                    handleDeleteClick(props.row.original.dataset_row_id)
                  }
                />
              </div>
            ),
        }),
      );
    }

    return baseColumns;
  }, [activeDataset?.version_number, handleDeleteClick, handleEditClick, rows]);

  const table = useReactTable({
    data: rows,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handlePageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
    setPage(1);
  };

  return (
    <div className={`min-h-[50vh] flex-1 rounded-lg py-4 ${isDraft && "px-4"}`}>
      <DatasetViewerTable
        emptyMessage={isLoading ? "Loading..." : "Empty"}
        isLoading={isLoading}
        key={page}
        table={table}
      />
      {total > 10 && !isUploadLoading && (
        <DatasetTablePagination
          handlePageSizeChange={handlePageSizeChange}
          page={page}
          pageSize={pageSize}
          pageSizeOptions={[10, 20, 50, 100]}
          pages={pages}
          setPageIndex={setPage}
          table={table}
          total={total}
        />
      )}
      {activeColumnName && activeDataset && isDatasetColumnToJsonModalOpen && (
        <DatasetColumnToJsonModal
          column_name={activeColumnName}
          dataset_id={activeDataset.id || 123}
          setOpen={setDatasetColumnToJsonModalOpen}
        />
      )}
      {activeColumnName && activeDataset && isDatasetRenameModalOpen && (
        <RenameColumn
          column_name={activeColumnName}
          dataset_id={activeDataset.id || 123}
          setOpen={setDatasetRenameModalOpen}
        />
      )}
      {activeDatasetRowId && isEditDatasetRowModalOpen && (
        <EditDatasetRowModal
          datasetRowId={activeDatasetRowId}
          setOpen={setEditDatasetRowModalOpen}
          variables={variables}
        />
      )}
    </div>
  );
};

export default DatasetTable;
