import { FC, useEffect, useState, useRef } from "react";

import CommentForm from "@/components/CommentForm";
import UserAvatar from "@/components/UserAvatar";
import { useAuth } from "@/context/auth-context";
import { useComments, useCreateComment } from "@/queries";
import { Thread as TThread } from "@/types/threads";
import Comment from "./Comment";

interface ThreadProps {
  isOverlayOpen: boolean;
  latestCommentDate: string | null;
  showVersionNumber: boolean;
  thread: TThread;
}

const Thread: FC<ThreadProps> = ({
  isOverlayOpen,
  latestCommentDate,
  showVersionNumber,
  thread,
}) => {
  const [showAllComments, setShowAllComments] = useState(false);
  const authContext = useAuth();
  const isInitialMount = useRef(true);
  const userToken = authContext?.userToken!;

  const createComment = useCreateComment(userToken);
  const { data: commentsData } = useComments(
    userToken,
    thread.id,
    latestCommentDate,
  );

  useEffect(() => {
    if (isInitialMount.current && isOverlayOpen && commentsData?.comments) {
      setShowAllComments(commentsData.comments.length <= 4);
      isInitialMount.current = false;
    }
  }, [isOverlayOpen, commentsData?.comments]);

  useEffect(() => {
    if (!isOverlayOpen) {
      isInitialMount.current = true;
    }
  }, [isOverlayOpen]);

  const handleCreateComment = (text: string) => {
    createComment.mutate({ thread_id: thread.id, text });
  };

  const renderComments = () => {
    if (!commentsData?.comments) return null;

    const comments = commentsData.comments;
    const commentCount = comments.length;

    if (commentCount <= 4 || showAllComments) {
      return comments.map((comment) => (
        <div key={comment.id} className="mb-3 flex items-start">
          <div className="mr-3">
            <UserAvatar email={comment.user_email} />
          </div>
          <Comment comment={comment} />
        </div>
      ));
    } else {
      const firstComment = comments[0];
      const lastComment = comments[commentCount - 1];
      const hiddenCommentCount = commentCount - 2;

      return (
        <>
          <div key={firstComment.id} className="mb-3 flex items-start">
            <div className="mr-3">
              <UserAvatar email={firstComment.user_email} />
            </div>
            <Comment comment={firstComment} />
          </div>
          <div
            className="mb-3 cursor-pointer rounded-lg py-1 pl-11 hover:bg-gray-100"
            onClick={() => setShowAllComments(true)}
          >
            <p className="text-sm text-gray-400">
              Show {hiddenCommentCount} comments
            </p>
          </div>
          <div key={lastComment.id} className="mb-3 flex items-start">
            <div className="mr-3">
              <UserAvatar email={lastComment.user_email} />
            </div>
            <Comment comment={lastComment} />
          </div>
        </>
      );
    }
  };

  return (
    <div className="mb-4 rounded-lg border bg-white p-4 pb-1 shadow-md transition-transform hover:-translate-x-1 hover:border-gray-200 hover:shadow-lg">
      {showVersionNumber && (
        <div className="-mt-2 pb-2">
          <div className="flex justify-center">
            <span className="text-xs font-medium text-gray-400">
              Version {thread.prompt_version_number}
            </span>
          </div>
        </div>
      )}
      {renderComments()}
      <div className="flex items-start">
        <div className="mr-1 mt-1">
          <UserAvatar email={authContext?.user?.email || ""} />
        </div>
        <div className="flex-grow">
          <CommentForm
            inThread
            onClick={() => setShowAllComments(true)}
            onSubmit={handleCreateComment}
            placeholder="Type a comment..."
          />
        </div>
      </div>
    </div>
  );
};

export default Thread;
