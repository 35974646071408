import React, { FC, FormEvent, useState } from "react";

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useAuth } from "@/context/auth-context";
import { ToastType } from "@/enums";
import useActiveWorkspace from "@/hooks/useActiveWorkspace";
import { modelConfigs } from "@/modelConfig";
import { displayErrorToast, displayToast } from "@/utils/toast";
import { useCreateProviderBaseURL } from "@/queries";
import { SelectProviderFromConfigs } from "@/components/ModelProviderSelection/SelectProviderFromConfigs";

type ProviderBaseURLModalProps = {
  setOpen: (isOpen: boolean) => void;
};

const ProviderBaseURLModal: FC<ProviderBaseURLModalProps> = ({ setOpen }) => {
  const [apiKey, setApiKey] = useState("");
  const [name, setName] = useState("");
  const [provider, setProvider] = useState("");
  const [url, setURL] = useState("");
  const activeWorkspace = useActiveWorkspace();
  const authContext = useAuth();
  const userToken = authContext?.userToken;
  const createProviderBaseURL = useCreateProviderBaseURL(userToken!);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const response = await createProviderBaseURL.mutateAsync({
        api_key: apiKey,
        name,
        provider,
        url,
        workspace_id: activeWorkspace!.id,
      });

      if (response.success === true) {
        displayToast("Provider Base URL created", ToastType.success);
        setOpen(false);
      } else if ("message" in response) {
        displayErrorToast(response.message);
      } else {
        displayErrorToast("Error creating Provider Base URL");
      }
    } catch (error: unknown) {
      console.error("Error creating Provider Base URL:", error);
      displayErrorToast(error);
    }
  };

  const renderForm = () => {
    return (
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label
            className="block text-sm font-medium text-gray-700"
            htmlFor="name"
          >
            Name
          </label>
          <input
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            id="name"
            name="name"
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter name"
            required
            type="text"
            value={name}
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-sm font-medium text-gray-700"
            htmlFor="url"
          >
            URL
          </label>
          <input
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            id="url"
            name="url"
            onChange={(e) => setURL(e.target.value)}
            placeholder="Enter provider URL"
            required
            type="url"
            value={url}
          />
        </div>
        <div className="mb-4">
          <label className="block pb-4 text-xs font-medium">LLM Provider</label>
          <div className="relative">
            <SelectProviderFromConfigs
              handleSelectProvider={setProvider}
              modelConfigs={modelConfigs}
              selectedProvider={provider}
            />
          </div>
        </div>
        <div className="mb-4">
          <label
            className="block text-sm font-medium text-gray-700"
            htmlFor="api_key"
          >
            API Key
          </label>
          <input
            autoComplete="off"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            id="api_key"
            name="api_key"
            onChange={(e) => setApiKey(e.target.value)}
            placeholder="Enter API key"
            required
            type="text"
            value={apiKey}
          />
        </div>
        <div className="mt-4 text-center">
          <button
            className="inline-flex justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            type="submit"
          >
            Submit
          </button>
        </div>
      </form>
    );
  };

  return (
    <Dialog open={true} onOpenChange={handleClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create Provider Base URL</DialogTitle>
        </DialogHeader>
        {renderForm()}
      </DialogContent>
    </Dialog>
  );
};

export default ProviderBaseURLModal;
