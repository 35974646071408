import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { XIcon } from "@heroicons/react/outline";
import { Input } from "../../ui/input";
import { ItemProperties, Properties } from "./Types";

export const RegularParamForm = ({
  index,
  parameter,
  parameters,
  setParameters,
  handleParameterChange,
  handleRemoveRow,
  depth,
  isItemDescription,
  children,
}: {
  index: number;
  parameter: any;
  parameters: any[];
  setParameters: React.Dispatch<React.SetStateAction<any[]>>;
  handleParameterChange: <K extends keyof Properties>(
    index: number,
    key: K,
    val: Properties[K] | ItemProperties[K],
    parameters: any[],
    setParameters: (params: any[]) => void,
    isItemDescription?: boolean,
  ) => void;
  handleRemoveRow: (
    index: number,
    parameters: any[],
    setParameters: React.Dispatch<React.SetStateAction<any[]>>,
  ) => void;
  depth: number;
  isItemDescription?: boolean;
  children: React.ReactNode;
}) => {
  return (
    <div
      key={index}
      className={`${index % 2 === 1 ? "bg-gray-50" : ""} rounded-md px-2`}
    >
      <div className="grid grid-cols-8 items-center gap-4 py-2">
        <div className="col-span-1 flex justify-center">
          <XIcon
            className="h-5 w-5 cursor-pointer text-red-500"
            onClick={() => handleRemoveRow(index, parameters, setParameters)}
          />
        </div>
        {depth === 0 && (
          <div className="col-span-1 flex justify-center">
            <input
              type="checkbox"
              className="rounded text-blue-600 focus:ring-blue-500"
              checked={parameter.required}
              onChange={(e) =>
                handleParameterChange(
                  index,
                  "required",
                  e.target.checked,
                  parameters,
                  setParameters,
                  isItemDescription,
                )
              }
            />
          </div>
        )}
        <div className="col-span-2">
          <Input
            className="rounded-sm border-gray-300 text-xs focus:border-blue-500 focus:ring-blue-500"
            placeholder="Name"
            value={parameter.name}
            onChange={(e) =>
              handleParameterChange(
                index,
                "name",
                e.target.value,
                parameters,
                setParameters,
                isItemDescription,
              )
            }
            autoComplete="off"
          />
        </div>
        <div className="relative col-span-2">
          <DropdownMenu>
            <DropdownMenuTrigger className="w-full border-gray-300 text-sm font-normal text-gray-500 focus:border-blue-500 focus:ring-blue-500">
              {parameter.type}
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              {["string", "number", "boolean", "object", "array"].map(
                (format) => (
                  <DropdownMenuItem
                    className="w-full text-center"
                    key={format}
                    onSelect={() => {
                      handleParameterChange(
                        index,
                        "type",
                        format,
                        parameters,
                        setParameters,
                        isItemDescription,
                      );
                    }}
                  >
                    {format}
                  </DropdownMenuItem>
                ),
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
        <div className="col-span-2">
          <Input
            className="rounded-sm border-gray-300 text-xs shadow-none focus:border-blue-500 focus:ring-blue-500"
            placeholder="Title"
            value={parameter.title}
            onChange={(e) =>
              handleParameterChange(
                index,
                "title",
                e.target.value,
                parameters,
                setParameters,
                isItemDescription,
              )
            }
            autoComplete="off"
          />
        </div>
        {parameter.type !== "array" && parameter.type !== "object" && (
          <div
            className={
              depth === 0 ? "col-span-6 col-start-3" : "col-span-6 col-start-2"
            }
          >
            <textarea
              className="w-full rounded-sm border-gray-300 text-xs focus:border-blue-500 focus:ring-blue-500"
              placeholder="Description"
              value={parameter.description}
              onChange={(e) =>
                handleParameterChange(
                  index,
                  "description",
                  e.target.value,
                  parameters,
                  setParameters,
                  isItemDescription,
                )
              }
            />
          </div>
        )}
        {children}
      </div>
    </div>
  );
};
