import { ArrowLeftIcon, HomeIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";

interface BreadcrumbsProps {
  items: string[];
  navigateUrl: string;
  tabsAboveTitle?: any;
  pageTitle?: any;
  pageSubtitle?: any;
  rightTitleContent?: any;
  backLink?: string;
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  items,
  navigateUrl,
  tabsAboveTitle,
  pageTitle,
  pageSubtitle,
  rightTitleContent,
  backLink,
}) => {
  return (
    <>
      <div className="items-center pt-10 text-xs text-gray-500">
        <Link
          to={navigateUrl}
          className="inline-flex items-center hover:text-gray-700"
        >
          <span className="font-medium">
            <HomeIcon className="-mt-1 inline h-4 w-auto" aria-hidden="true" />
          </span>
          {items.map((item, index) => (
            <span key={index}>
              <span className="px-2">/</span>
              <span>{item}</span>
            </span>
          ))}
        </Link>
      </div>
      {tabsAboveTitle && tabsAboveTitle}
      {pageTitle && (
        <div className="flex pt-4">
          <div className="flex-1 pt-4">
            <h1 className="text-2xl text-gray-900">{pageTitle}</h1>
            {pageSubtitle && (
              <h2 className="pt-1 text-lg text-gray-500">
                {pageSubtitle}
                {backLink && (
                  <>
                    <br />
                    <Link
                      className="text-blue-500 hover:text-blue-400"
                      to={backLink}
                    >
                      <ArrowLeftIcon
                        className="-mt-1 mr-1 inline h-4 w-auto"
                        aria-hidden="true"
                      />
                      Go back
                    </Link>
                  </>
                )}
              </h2>
            )}
          </div>
          {rightTitleContent && (
            <div className="px-1 pt-4">{rightTitleContent}</div>
          )}
        </div>
      )}
    </>
  );
};
