// ErrorBoundary.tsx
import React from "react";
import ErrorFallback from "./ui/error-fallback";

interface ErrorBoundaryState {
  hasError: boolean;
  error?: Error;
  errorInfo?: React.ErrorInfo;
}

export class ErrorBoundary extends React.Component<
  React.PropsWithChildren<{}>,
  ErrorBoundaryState
> {
  constructor(props: React.PropsWithChildren<{}>) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // Log the stack trace
    this.setState({ errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorFallback
          error={{
            name: this.state.error?.name || "Error",
            message: this.state.error?.message || "An error occurred",
            stack: this.state.errorInfo?.componentStack,
          }}
        />
      );
    }
    return this.props.children;
  }
}
