import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useAuth } from "@/context/auth-context";
import { ToastType } from "@/enums";
import { useRenameDatasetColumn } from "@/queries";
import { displayErrorToast, displayToast } from "@/utils/toast";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

type Props = {
  column_name: string;
  dataset_id: number;
  setOpen: (isOpen: boolean) => void;
};

export const RenameColumn = (props: Props) => (
  <Dialog open={true} onOpenChange={props.setOpen}>
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Rename Column</DialogTitle>
      </DialogHeader>
      <RenameColumnForm {...props} onSuccess={() => props.setOpen(false)} />
    </DialogContent>
  </Dialog>
);

const RenameColumnForm = (
  props: Props & {
    onSuccess: () => void;
  },
) => {
  const form = useForm({
    defaultValues: {
      name: "",
    },
  });
  const [isDisabled, setIsDisabled] = useState(true);
  const authContext = useAuth();
  const userToken = authContext!.userToken;
  const { mutateAsync: renameDatasetColumn, isLoading } =
    useRenameDatasetColumn(userToken!);

  const submitHandler: SubmitHandler<{ name: string }> = async (values) => {
    try {
      const response = await renameDatasetColumn({
        dataset_id: props.dataset_id,
        old_name: props.column_name,
        new_name: values.name,
      });

      if (response.success) {
        displayToast("Column Renamed", ToastType.success);
      } else {
        displayErrorToast(
          response.error || "There was an error renaming the column",
        );
      }
    } catch (error: unknown) {
      displayErrorToast("There was an error renaming the column");
      console.log(error);
    } finally {
      props.onSuccess();
    }
  };
  return (
    <>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(submitHandler)}>
          <FormField
            name="name"
            control={form.control}
            render={({ field }) => (
              <FormControl>
                <FormItem>
                  <Input
                    className="italic"
                    placeholder={props.column_name}
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                      setIsDisabled(
                        e.target.value === props.column_name ||
                          e.target.value?.trim() === "",
                      );
                    }}
                  />
                  <FormMessage />
                </FormItem>
              </FormControl>
            )}
          />
          <DialogFooter className="mt-5">
            <Button
              type="button"
              onClick={() => props.setOpen(false)}
              className="bg-transparent text-sm"
              variant="outline"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="default"
              disabled={isDisabled}
              isLoading={isLoading}
            >
              Save
            </Button>
          </DialogFooter>
        </form>
      </Form>
    </>
  );
};
