import { FC } from "react";
import { ArrowNarrowRightIcon } from "@heroicons/react/outline";

interface AuthSubmitButtonProps {
  loading: boolean;
  text: string;
}

const AuthSubmitButton: FC<AuthSubmitButtonProps> = ({ loading, text }) => {
  const buttonContent = loading ? (
    "Loading..."
  ) : (
    <>
      {text}{" "}
      <ArrowNarrowRightIcon
        aria-hidden="true"
        className="ml-1 inline h-4 w-4"
      />
    </>
  );

  return (
    <button
      className={`w-full rounded-sm px-4 py-2 text-white ${
        loading ? "bg-gray-400" : "bg-blue-500 hover:bg-blue-400"
      }`}
      disabled={loading}
      type="submit"
    >
      {buttonContent}
    </button>
  );
};

export default AuthSubmitButton;
