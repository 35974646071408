import { useChannel, useConnectionStateListener } from "ably/react";
import { FC, useContext } from "react";

import { ENDPOINTS } from "@/api/application-api";
import { DatasetEditorContext } from "@/components/DatasetEditor";
import { WEBSOCKET_MESSAGE_NAME } from "@/constants";
import { useUser } from "@/context/user-context";
import { ToastType } from "@/enums";
import { WebSocketMessage } from "@/types/websockets";
import { displayErrorToast, displayToast } from "@/utils/toast";
import { useQueryClient } from "@tanstack/react-query";

const DatasetWebSocketListener: FC = () => {
  const { user } = useUser();
  const { setIsUploadLoading } = useContext(DatasetEditorContext);

  useConnectionStateListener("connected", () => {
    console.log("Connected to Ably!");
  });

  const queryClient = useQueryClient();

  useChannel(
    `user:${user.id}`,
    WEBSOCKET_MESSAGE_NAME.DATASET_FILE_PROCESSING_COMPLETE,
    (socketMessage: WebSocketMessage) => {
      try {
        const { data: websocketData } = socketMessage;
        if (!websocketData) return;

        const { success, message } = JSON.parse(websocketData);

        if (success) {
          displayToast(message, ToastType.success);
        } else {
          displayErrorToast(message);
        }
      } catch (error) {
        displayErrorToast("Error processing file.");
      } finally {
        queryClient.invalidateQueries([ENDPOINTS.dataset_rows]);
        setIsUploadLoading(false);
      }
    },
  );

  return null;
};

export default DatasetWebSocketListener;
