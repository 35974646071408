import { PlaygroundConfig } from "@/components/Playground";

export const areValidJsonInputVariables = (value: any) => {
  try {
    JSON.stringify(value);
    return isObject(value);
  } catch (error) {
    return false;
  }
};

export const isObject = (value: any) => {
  return value !== null && typeof value === "object";
};

export const isObjectOrArray = (value: any) => {
  return isObject(value) || Array.isArray(value);
};

export function configCanRun(config: PlaygroundConfig): boolean {
  const hasAtLeastOneNonEmptyMessage = config.messages.some(
    (message) => !!message.content?.length,
  );

  return config.type === "chat"
    ? hasAtLeastOneNonEmptyMessage
    : !!config.template;
}
