import { CodeIcon } from "@heroicons/react/outline";
import { MinusCircleIcon, PlusIcon } from "@heroicons/react/solid";
import { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Button, DivButton } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import useActiveWorkspace from "@/hooks/useActiveWorkspace";
import { usePromptVersions } from "@/queries";
import { ListPromptVersion } from "@/types";
import {
  Conditional,
  DynamicReleaseLabel,
  OperationConditional,
} from "@/types/conditionals";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { PromptRegistryName } from ".";

interface ReleaseLabelTableProps {
  conditional: Conditional;
  dynamicReleaseLabels: DynamicReleaseLabel[];
  promptLabelName: string;
  promptRegistryId: string;
  setConditionals: (conditionals: any) => void;
}

const ReleaseLabelTable = ({
  conditional,
  dynamicReleaseLabels,
  promptLabelName,
  promptRegistryId,
  setConditionals,
}: ReleaseLabelTableProps) => {
  const auth = useAuth();
  const userToken = auth?.userToken!;
  const [selectedPromptVersion, setSelectedPromptVersion] =
    useState<ListPromptVersion | null>(null);
  const activeWorkspace = useActiveWorkspace();
  const userContext = useUser();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const getIsRealLabel = () => {
    const promptVersionLabelNames = selectedPromptVersion?.labels.map(
      ({ name }) => name,
    );
    return promptVersionLabelNames?.includes(promptLabelName) || false;
  };

  const totalPercentageIsCorrect = useMemo(() => {
    const percentages = dynamicReleaseLabels.map(
      ({ percentage }) => percentage,
    );
    const total = percentages.reduce((total, current) => total + current, 0);
    return total === 100;
  }, [dynamicReleaseLabels]);

  const handleDeleteClick = useCallback(
    (promptVersionId: number, isRealLabel: boolean) => {
      setConditionals((prevConditionals: Conditional[]) => {
        return prevConditionals.map((prevConditional: Conditional) => {
          if (prevConditional === conditional) {
            return {
              ...prevConditional,
              dynamic_release_labels:
                prevConditional.dynamic_release_labels.filter(
                  (label) =>
                    label.prompt_version_id !== promptVersionId ||
                    label.is_real_label !== isRealLabel,
                ),
            };
          }
          return prevConditional;
        });
      });
    },
    [conditional, setConditionals],
  );

  const handleDynamicReleaseLabelPercentageChange = useCallback(
    (promptVersionId: number, newPercentage: number) => {
      setConditionals((prevConditionals: Conditional[]) =>
        prevConditionals.map((prevConditional) => {
          if (prevConditional === conditional) {
            const updatedLabels = prevConditional.dynamic_release_labels.map(
              (label) => {
                if (label.prompt_version_id === promptVersionId) {
                  return { ...label, percentage: newPercentage };
                }
                return label;
              },
            );
            return {
              ...prevConditional,
              dynamic_release_labels: updatedLabels,
            };
          }
          return prevConditional;
        }),
      );
    },
    [conditional, setConditionals],
  );

  const handleDynamicReleaseLabelSave = () => {
    setConditionals((prevConditionals: any) => {
      return prevConditionals.map((prevConditional: Conditional) => {
        if (
          (prevConditional.is_default && conditional.is_default) ||
          (!prevConditional.is_default &&
            !conditional.is_default &&
            (prevConditional as OperationConditional).field ===
              (conditional as OperationConditional).field &&
            (prevConditional as OperationConditional).operator ===
              (conditional as OperationConditional).operator &&
            (prevConditional as OperationConditional).value ===
              (conditional as OperationConditional).value)
        ) {
          const newDynamicReleaseLabel: DynamicReleaseLabel = {
            is_real_label: getIsRealLabel(),
            percentage: 0,
            prompt_registry_id: parseInt(promptRegistryId),
            prompt_version_id: selectedPromptVersion!.id,
            prompt_version_number: selectedPromptVersion!.number,
          };

          return {
            ...prevConditional,
            dynamic_release_labels: [
              ...prevConditional.dynamic_release_labels,
              newDynamicReleaseLabel,
            ],
          };
        }

        return prevConditional;
      });
    });

    setIsModalOpen(false);
    setSelectedPromptVersion(null);
  };

  const workspaceId = userContext.activeWorkspaceId!;

  const promptVersionsQuery = usePromptVersions(userToken, {
    workspaceId,
    promptRegistryId: parseInt(promptRegistryId),
    perPage: Number.MAX_SAFE_INTEGER,
  });

  const promptVersionOptions =
    promptVersionsQuery.data?.pages.flatMap((page) => page.items) ?? [];

  const renderAddElement = () => {
    if (promptVersionOptions.length === 0) return null;

    const getPromptVersionNames = (promptVersion: ListPromptVersion) => {
      const namesList =
        promptVersion.labels.length > 0
          ? promptVersion.labels.map((label) => label.name)
          : [];
      return namesList.map((label) => (
        <span
          key={label}
          className="mx-1 inline-block rounded-sm border border-gray-200 px-2 py-0.5 text-xs font-medium text-gray-500"
        >
          {label}
        </span>
      ));
    };

    return (
      <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
        <div className="mt-2 flex items-center">
          <DialogTrigger asChild>
            <DivButton variant="link">
              <PlusIcon className="h-4 w-4" />
              <span className="text-sm">Add Version</span>
            </DivButton>
          </DialogTrigger>
        </div>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Choose Prompt Version</DialogTitle>
            <DialogDescription>
              Add a dynamic release label to a new prompt template version.
            </DialogDescription>
          </DialogHeader>
          <div className="mt-4">
            <DropdownMenu>
              <DropdownMenuTrigger className="w-full">
                {selectedPromptVersion ? (
                  <span>
                    Version {selectedPromptVersion?.number}
                    {getPromptVersionNames(selectedPromptVersion)}
                  </span>
                ) : (
                  <span className="text-gray-400">Select version...</span>
                )}
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                {promptVersionOptions.map((promptVersion) =>
                  dynamicReleaseLabels.some(
                    (label) => label.prompt_version_id === promptVersion.id,
                  ) ? (
                    <DropdownMenuItem key={promptVersion.id} disabled>
                      Version {promptVersion.number} (already added){" "}
                      {getPromptVersionNames(promptVersion)}
                    </DropdownMenuItem>
                  ) : (
                    <DropdownMenuItem
                      key={promptVersion.id}
                      onSelect={() => setSelectedPromptVersion(promptVersion)}
                    >
                      Version {promptVersion.number}{" "}
                      {getPromptVersionNames(promptVersion)}
                    </DropdownMenuItem>
                  ),
                )}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
          <div className="mt-4 flex justify-end gap-2">
            <Button onClick={() => setIsModalOpen(false)} variant="outline">
              Cancel
            </Button>
            <Button
              disabled={!selectedPromptVersion}
              onClick={handleDynamicReleaseLabelSave}
            >
              Add
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  const dynamicReleaseLabelRows = useMemo(() => {
    return dynamicReleaseLabels
      .sort((a, b) => a.prompt_version_number - b.prompt_version_number)
      .sort((a, b) => Number(b.is_real_label) - Number(a.is_real_label))
      .map((dynamicReleaseLabel, index) => (
        <tr
          className={`${
            dynamicReleaseLabel.is_real_label ? "h-24" : "h-12"
          } border border-gray-200 hover:bg-gray-50`}
          key={`${dynamicReleaseLabel.prompt_registry_id}-${dynamicReleaseLabel.prompt_version_id}`}
        >
          <td className="p-3">
            {dynamicReleaseLabel.is_real_label ? (
              <div className="group w-[300px] rounded-md border-none px-3 py-2 text-gray-600 transition-all hover:border hover:border-dashed hover:border-gray-200">
                <div className="block flex hidden flex-col space-y-2 text-center text-xs text-gray-500 group-hover:block">
                  <div className="">Tied to original label</div>
                  <div className="">
                    Currently:{" "}
                    <span className="font-semibold">
                      Version #{dynamicReleaseLabel.prompt_version_number}
                    </span>
                  </div>
                </div>
                <Link
                  className="cursor-pointer transition-all group-hover:hidden"
                  to={
                    activeWorkspace
                      ? `/workspace/${activeWorkspace.id}/prompt/${dynamicReleaseLabel.prompt_registry_id}/version/${dynamicReleaseLabel.prompt_version_number}`
                      : "/"
                  }
                >
                  <div className="flex items-center gap-x-2 py-2">
                    <CodeIcon className="h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-blue-500" />
                    <div className="flex flex-col">
                      <span className="flex items-center gap-x-2 text-sm font-semibold text-gray-700 group-hover:text-blue-600">
                        <div>Original</div>
                        <div className="flex-1">
                          <div className="mx-auto inline-block rounded-sm border border-gray-200 px-2 py-0.5 text-xs font-medium text-gray-500">
                            {promptLabelName}
                          </div>
                        </div>
                      </span>
                      <span className="text-xs text-gray-500 group-hover:text-blue-600">
                        <PromptRegistryName
                          id={dynamicReleaseLabel.prompt_registry_id}
                        />
                      </span>
                    </div>
                  </div>
                </Link>
              </div>
            ) : (
              <Link
                className="group flex w-[300px] cursor-pointer items-center gap-x-2 rounded-md px-3 py-2 transition-all"
                to={
                  activeWorkspace
                    ? `/workspace/${activeWorkspace.id}/prompt/${dynamicReleaseLabel.prompt_registry_id}/version/${dynamicReleaseLabel.prompt_version_number}`
                    : "/"
                }
              >
                <CodeIcon className="h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-blue-500" />
                <div className="flex flex-col">
                  <span className="text-sm font-semibold text-gray-700 group-hover:text-blue-600">
                    Version {dynamicReleaseLabel.prompt_version_number}
                  </span>
                  <span className="text-xs text-gray-500 group-hover:text-blue-600">
                    <PromptRegistryName
                      id={dynamicReleaseLabel.prompt_registry_id}
                    />
                  </span>
                </div>
              </Link>
            )}
          </td>
          <td className="px-4 py-2 text-center">
            <input
              className={`w-[100px] rounded-sm px-2 py-1 focus:outline-none focus:ring-2 ${
                totalPercentageIsCorrect
                  ? "border-gray-300 focus:border-blue-600 focus:ring-blue-500"
                  : "border-red-500 focus:border-red-600 focus:ring-red-500"
              }`}
              id="percentage"
              onChange={(e) =>
                handleDynamicReleaseLabelPercentageChange(
                  dynamicReleaseLabel.prompt_version_id,
                  Number(e.target.value),
                )
              }
              placeholder="%"
              type="number"
              value={dynamicReleaseLabel.percentage ?? ""}
              min={0}
              max={100}
            />
          </td>
          <td className="h-12 p-2 text-center">
            {!(conditional.is_default && dynamicReleaseLabel.is_real_label) && (
              <Button
                variant="ghost"
                size="sm"
                onClick={() =>
                  handleDeleteClick(
                    dynamicReleaseLabel.prompt_version_id,
                    dynamicReleaseLabel.is_real_label,
                  )
                }
              >
                <MinusCircleIcon className="h-5 w-5 text-red-500 hover:text-red-400" />
              </Button>
            )}
          </td>
        </tr>
      ));
  }, [
    activeWorkspace,
    conditional.is_default,
    dynamicReleaseLabels,
    handleDeleteClick,
    handleDynamicReleaseLabelPercentageChange,
    promptLabelName,
    totalPercentageIsCorrect,
  ]);

  return (
    <>
      <table className="w-full max-w-2xl table-auto border-collapse rounded-2xl border border-gray-200">
        {dynamicReleaseLabels.length > 0 && (
          <thead>
            <tr className="bg-gray-100 text-xs font-medium uppercase tracking-wider text-gray-500">
              <th className="px-4 py-3 text-left">Template Version</th>
              <th className="px-4 py-3 text-center">Release %</th>
              <th className="px-4 py-3 text-right">&nbsp;</th>
            </tr>
          </thead>
        )}
        <tbody className="divide-y divide-gray-200 bg-white">
          {dynamicReleaseLabelRows}
        </tbody>
      </table>
      {renderAddElement()}
    </>
  );
};

export default ReleaseLabelTable;
