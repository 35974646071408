import styled from "styled-components";

import leftBackgroundImage from "@/assets/hero/icon1.png";
import rightBackgroundImage from "@/assets/hero/icon2.png";

export const Container = styled.div`
  position: relative;
  width: 100%;
  min-height: 50vh;
  background: linear-gradient(
    0deg,
    #eff0f8 7.99%,
    rgba(239, 240, 248, 0) 74.7%
  );
  overflow: hidden;
  padding-top: 78px;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
  }

  &::before {
    left: 0;
    background-image: url(${leftBackgroundImage});
    background-position: left 10%;
  }

  &::after {
    right: 0;
    background-image: url(${rightBackgroundImage});
    background-position: right 10%;
  }

  @media (max-width: 767px) {
    &::before {
      background-position: left 25%;
    }
    &::after {
      background: none;
    }
  }
`;

export const Content = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  padding: 0 20px;
  background-color: transparent;
`;

export const Title = styled.h1`
  font-size: 3.5rem;
  font-weight: 500;
  margin-bottom: 1rem;

  span {
    color: #5167f0;
    font-size: 4.25rem;
  }
`;

export const Subtitle = styled.p`
  color: #666;
  font-size: 1.25rem;
  text-align: center;
  margin-bottom: 1.5rem;
`;

interface HeroImgProps {
  imageUrl?: string;
}

export const HeroImg = styled.div<HeroImgProps>`
  border-radius: 0.75rem;
  background: url(${(props) => props.imageUrl}) lightgray left center / cover
    no-repeat;
  background-color: transparent;
  height: 75vh;
  width: 72%;
  margin: 50px 0;

  @media (max-width: 767px) {
    height: 50vh;
    width: 100%;
  }
`;
