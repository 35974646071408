import { ChangeEvent, FC, FormEvent, useState } from "react";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { ConditionalType } from "@/enums/release-label-groups";
import { OperationConditional } from "@/types/conditionals";
import TagInput from "./TagInput";
import { CONDITIONAL_OPERATOR_TO_STRING } from "../ReleaseLabelGroup";

type ConditionalModalProps = {
  editingConditional: OperationConditional | null;
  onConditionalModalSubmit: (conditional: OperationConditional) => void;
  setOpen: (isOpen: boolean) => void;
};

const ConditionalModal: FC<ConditionalModalProps> = ({
  editingConditional,
  onConditionalModalSubmit,
  setOpen,
}) => {
  const [conditional, setConditional] = useState<OperationConditional>({
    dynamic_release_labels: editingConditional?.dynamic_release_labels || [],
    field: editingConditional?.field || "",
    is_default: false,
    operator: editingConditional?.operator || "=",
    position: 0,
    type: ConditionalType.operation,
    value: editingConditional?.value || "",
  });
  const [valueInputType, setValueInputType] = useState<"normal" | "tag">(
    ["=", "!="].includes(editingConditional?.operator || "=")
      ? "normal"
      : "tag",
  );

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setConditional((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSelectChange = (name: string, value: string) => {
    setConditional((prev) => ({
      ...prev,
      [name]: value,
    }));

    const newValueInputType = ["=", "!="].includes(value) ? "normal" : "tag";
    if (newValueInputType !== valueInputType) {
      setValueInputType(newValueInputType);
      setConditional((prev) => ({
        ...prev,
        value: "",
      }));
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onConditionalModalSubmit(conditional);
    setOpen(false);
  };

  const handleTagInputChange = (tags: string[]) => {
    setConditional((prev) => ({
      ...prev,
      value: tags.join(","),
    }));
  };

  return (
    <Dialog open={true} onOpenChange={() => setOpen(false)}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Enter Segmentation</DialogTitle>
          <DialogDescription>
            Segment prompt template retrieval using metadata filters.
          </DialogDescription>
        </DialogHeader>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              className="block text-sm font-medium text-gray-700"
              htmlFor="field"
            >
              Field
            </label>
            <input
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              id="field"
              name="field"
              type="text"
              value={conditional.field}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-sm font-medium text-gray-700"
              htmlFor="operator"
            >
              Operator
            </label>
            <Select
              value={conditional.operator}
              onValueChange={(value) => handleSelectChange("operator", value)}
              required
            >
              <SelectTrigger
                id="operator"
                name="operator"
                className="mt-1 flex w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              >
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                {["=", "!=", "in", "not_in"].map((operator) => (
                  <SelectItem key={operator} value={operator}>
                    {CONDITIONAL_OPERATOR_TO_STRING[operator]}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="mb-4">
            <label
              className="block text-sm font-medium text-gray-700"
              htmlFor="value"
            >
              Value
            </label>
            {valueInputType === "normal" ? (
              <input
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                id="value"
                name="value"
                onChange={handleInputChange}
                required
                type="text"
                value={conditional.value}
              />
            ) : (
              <TagInput
                initialTags={
                  conditional.value === "" ? [] : conditional.value.split(",")
                }
                onTagsChange={handleTagInputChange}
              />
            )}
          </div>
          <div className="flex justify-end gap-2">
            <Button
              type="button"
              variant="outline"
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
            <Button type="submit">Create</Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ConditionalModal;
