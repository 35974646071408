const EmptyTableMessage = () => {
  return (
    <div className="flex items-center justify-center space-x-1">
      <p>There is no usage yet, start tracking usage.</p>
      <a
        className="cursor-pointer text-blue-500 hover:text-blue-300"
        rel="noreferrer"
        target={"_blank"}
        href="https://docs.promptlayer.com/features/prompt-history/tracking-templates"
      >
        Learn more here
      </a>
    </div>
  );
};

export default EmptyTableMessage;
