import { useNavigate, useParams } from "react-router-dom";
import { DatasetRead } from "@/types/datasets";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { HistoryIcon } from "lucide-react";
import { useMemo } from "react";

interface VersionHistoryMenuProps {
  datasets: DatasetRead[];
  sortedDatasets: DatasetRead[];
  currentVersion?: number;
  isDraft: boolean;
}

export const getVersionTitle = (version_number?: number) =>
  !isNaN(version_number!) && version_number !== -1
    ? `Version #${version_number}`
    : (version_number && "Draft") || "Loading...";

const VersionHistoryMenu = ({
  sortedDatasets,
  currentVersion,
  isDraft,
}: VersionHistoryMenuProps) => {
  const { datasetGroupId, workspaceId, datasetId } = useParams();
  const navigate = useNavigate();

  const title = useMemo(
    () => getVersionTitle(currentVersion),
    [currentVersion],
  );

  return (
    <div className="flex h-8 min-w-[156px] ">
      <DropdownMenu>
        <DropdownMenuTrigger disabled={false} className="w-full">
          <HistoryIcon size={15} className="mr-1" />{" "}
          {!isDraft ? currentVersion && title : "Version History"}
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {sortedDatasets.map(({ id, version_number }) => (
            <DropdownMenuItem
              onClick={() =>
                navigate(
                  `/workspace/${workspaceId}/dataset-groups/${datasetGroupId}/dataset/${id}`,
                )
              }
              className={`${
                String(id) === datasetId && "border border-blue-200 bg-blue-50"
              } whitespace-nowrap`}
              key={id}
            >
              {getVersionTitle(version_number)}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export default VersionHistoryMenu;
