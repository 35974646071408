import {
  CalculatorIcon,
  CheckIcon,
  DocumentDuplicateIcon,
  DotsHorizontalIcon,
  HomeIcon,
} from "@heroicons/react/outline";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import DropdownButton from "@/components/DropdownButton";
import { useAuth } from "@/context/auth-context";
import { useGroupRequests } from "@/queries";

const GroupContentNavbar = ({
  onHistoryPage,
  setSidebarOpen,
}: {
  onHistoryPage: boolean;
  setSidebarOpen: (open: boolean) => void;
}) => {
  const authContext = useAuth();
  const { groupId } = useParams();

  const group = useGroupRequests(groupId || "1", authContext?.userToken || "");

  const data = useMemo(() => group?.data ?? [], [group.data]);
  const navigate = useNavigate();

  const loggedTimeRef = useRef<HTMLHeadingElement>(null);
  const [loggedTimeHover, setLoggedTimeHover] = useState(false);
  const [loggedTimeCopied, setLoggedTimeCopied] = useState(false);

  const [loggedTimeElement, setLoggedTimeElement] = useState<
    string | JSX.Element
  >();

  useEffect(() => {
    const time = data?.last_used_at;
    const formattedTime = time
      ? moment(time).format("YYYY-MM-DD HH:mm:ss")
      : "";

    if (loggedTimeCopied) {
      setLoggedTimeElement(
        <span>
          Copied
          <CheckIcon className="-mt-0.5 inline h-5 w-5 pl-1 text-gray-500" />
        </span>,
      );
    } else if (loggedTimeHover) {
      setLoggedTimeElement(
        <span
          onClick={() => {
            navigator.clipboard.writeText(formattedTime);
            setLoggedTimeCopied(true);
            setTimeout(() => {
              setLoggedTimeCopied(false);
            }, 1000);
          }}
          className="cursor-pointer text-gray-700"
        >
          {formattedTime}
        </span>,
      );
    } else {
      setLoggedTimeElement(
        data.cardinality > 0
          ? `Updated ${moment.utc(time).fromNow()}`
          : `Created ${moment.utc(time).fromNow()}`,
      );
    }
  }, [loggedTimeHover, loggedTimeCopied, data]);

  const handleMouseEnter = () => {
    setLoggedTimeHover(true);
  };

  const handleMouseLeave = () => {
    setLoggedTimeHover(false);
  };

  const historyPageNavbar = data ? (
    <div className="flex flex-1 justify-between px-4 md:px-0">
      <div className="ml-auto flex items-center space-x-8 text-gray-800 hover:text-gray-700 md:mr-6">
        <h1
          className="hidden py-1 text-sm text-gray-500 lg:block"
          id="logged-time"
          ref={loggedTimeRef}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {loggedTimeElement}
        </h1>
        <h1 className="hidden py-1 text-sm text-gray-500 lg:block">
          Group Size: {data.cardinality}
        </h1>
        <h1 className="text-md cursor-pointer py-1">
          <DropdownButton
            dropdownContent={null}
            dropdownItems={[
              {
                name: `Copy g_id`,
                icon: DocumentDuplicateIcon,
                onClick: () => {
                  navigator.clipboard.writeText(data?.id);
                },
              },
              {
                name: "Go Home",
                icon: HomeIcon,
                onClick: () => {
                  navigate("/");
                },
              },
            ]}
          >
            <DotsHorizontalIcon className="inline h-6" />
          </DropdownButton>
        </h1>
      </div>
    </div>
  ) : (
    <div className="flex flex-1 justify-between px-4 md:px-0"></div>
  );

  return (
    <div
      className={`top-0 flex h-16 ${
        !onHistoryPage && "sm:hidden"
      } flex-shrink-0 border-b border-gray-200 bg-white md:border-0`}
    >
      <button
        type="button"
        className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500 md:hidden"
        onClick={() => setSidebarOpen(true)}
      >
        <span className="sr-only">Open sidebar</span>
        <CalculatorIcon className="h-6 w-6" aria-hidden="true" />
      </button>
      {onHistoryPage ? historyPageNavbar : null}
    </div>
  );
};

export default GroupContentNavbar;
