import { useCallback, useState } from "react";

interface SwitchProps {
  options: string[];
  on?: boolean;
  onChange?: (checked: boolean) => void;
  toggle: () => void;
}

interface SwitchButtonProps {
  active: boolean;
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
}
const SwitchButton = (props: SwitchButtonProps) => {
  const { children, active, onClick, className } = props;
  const activeStyle = "text-black";
  return (
    <div className="flex flex-1 cursor-pointer select-none items-center justify-center">
      <button
        className={`${
          (active && activeStyle) || "text-gray-400"
        } z-[1]  rounded-full p-1 text-sm  ${className || ""}`}
        onClick={onClick}
      >
        {children}
      </button>
    </div>
  );
};

const Switch = (props: SwitchProps) => {
  const { options, on, toggle } = props;

  const slider =
    "before:content-[''] before:select-none before:z-0 before:pointer-events-none before:absolute before:w-[50%] before:h-[100%] before:rounded-md before:bg-blue-100 before:transition-all";

  const sliderActive = on
    ? "before:translate-x-[0%]"
    : "before:translate-x-[100%]";

  return (
    <div className="relative box-border flex w-full  flex-row items-center gap-x-1 px-2 pb-4 ">
      <div
        className={` relative mx-auto box-border flex w-auto min-w-[200px] items-center gap-x-1 rounded-md border bg-white px-1 py-1 shadow-sm`}
      >
        <div
          className={`relative flex w-full items-center  ${slider} ${sliderActive}`}
        >
          {options.map((option, index) => {
            const isActive = (on && index === 0) || (!on && index === 1);
            return (
              <SwitchButton
                key={index}
                onClick={(!isActive && toggle) || undefined}
                active={isActive}
              >
                {option}
              </SwitchButton>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export const useSwitch = (options: string[], reverseOrder = false) => {
  const [isSwitchOn, setSwitchOn] = useState(!reverseOrder);
  const toggle = useCallback(() => setSwitchOn((prev) => !prev), []);

  const SwitchComponent = (
    <Switch options={options} on={isSwitchOn} toggle={toggle} />
  );

  return { SwitchComponent, isSwitchOn, toggle };
};
