import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { RegexConfiguration, ReportColumn } from "@/types/evaluate";
import { formatInputVariable } from "@/utils/evaluate";
import { useState } from "react";
import { ModalStep } from "./ModalRouter";

const RegexBuilder = ({
  columnData,
  availableColumns,
  navigatePrevious,
  saveColumnAction,
  editable,
}: {
  columnData: Partial<ReportColumn>;
  availableColumns: ReportColumn[];
  navigatePrevious: () => void;
  saveColumnAction: (newColumnDataArg: ReportColumn) => void;
  editable: boolean;
}) => {
  const [name, setName] = useState<string | null>(columnData.name || null);
  const [regexPattern, setRegexPattern] = useState<string | null>(
    columnData.configuration?.regex_pattern || null,
  );
  const [targetColumn, setTargetColumn] = useState<string | null>(
    columnData.configuration?.target_column || null,
  );

  const [dataIsValid, setDataIsValid] = useState<{
    name: boolean;
    targetColumn: boolean;
    regexPattern: boolean;
  }>({
    name: true,
    targetColumn: true,
    regexPattern: true,
  });

  const saveEndpointAction = () => {
    if (!name) {
      setDataIsValid({ name: false, targetColumn: true, regexPattern: true });
      return;
    } else if (!targetColumn) {
      setDataIsValid({ name: true, targetColumn: false, regexPattern: true });
      return;
    } else if (!regexPattern) {
      setDataIsValid({ name: true, targetColumn: true, regexPattern: false });
      return;
    }

    setDataIsValid({ name: true, targetColumn: true, regexPattern: true });

    saveColumnAction({
      ...columnData,
      name: name,
      configuration: {
        regex_pattern: regexPattern,
        target_column: targetColumn,
      } as RegexConfiguration,
    } as ReportColumn);
  };

  return (
    <ModalStep
      navigatePrevious={navigatePrevious}
      navigateNext={saveEndpointAction}
      nextButtonText={editable ? "Save Step" : "Done"}
    >
      <div className="grid grid-cols-3 items-center gap-4">
        <div className="col-span-3">
          <div className="text-lg font-semibold">Configure Regex Pattern</div>
          <div className="max-w-md text-sm text-gray-500">
            This step will search for a regex pattern in the target column and
            return true if the pattern is found.
          </div>
        </div>
        <label htmlFor="name" className="col-span-1">
          Column name:
        </label>
        <input
          id="name"
          className={`col-span-2 rounded border ${
            !dataIsValid.name ? "border-red-500" : "border-gray-300"
          } px-2 py-1 disabled:cursor-not-allowed disabled:bg-gray-50`}
          value={name || ""}
          onChange={(e) => setName(e.target.value)}
          disabled={!editable}
        />
        {!dataIsValid.name && (
          <>
            <div className="col-span-1 -mt-4">&nbsp;</div>
            <div className="col-span-2 -mt-4 text-left text-xs text-red-500">
              Name is required
            </div>
          </>
        )}
        <label htmlFor="regex" className="col-span-1">
          Regex Pattern:
        </label>
        <input
          id="regex"
          placeholder="[A-Za-z0-9]"
          className={`col-span-2 rounded border ${
            !dataIsValid.regexPattern ? "border-red-500" : "border-gray-300"
          } px-2 py-1 disabled:cursor-not-allowed disabled:bg-gray-50`}
          value={regexPattern || ""}
          onChange={(e) => setRegexPattern(e.target.value)}
          disabled={!editable}
        />
        {!dataIsValid.regexPattern && (
          <>
            <div className="col-span-1 -mt-4">&nbsp;</div>
            <div className="col-span-2 -mt-4 text-left text-xs text-red-500">
              Regex pattern is required
            </div>
          </>
        )}
        <div className="col-span-1">Column:</div>
        <DropdownMenu>
          <DropdownMenuTrigger
            disabled={!editable}
            className="col-span-2 w-full"
          >
            {targetColumn || (
              <span className="font-normal text-gray-500">
                Select a column...
              </span>
            )}
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {availableColumns.map((column) => (
              <DropdownMenuItem
                key={column.name}
                onSelect={() => setTargetColumn(column.name)}
              >
                {formatInputVariable(column.column_type, column.name)}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
        {!dataIsValid.targetColumn && (
          <>
            <div className="col-span-1 -mt-4">&nbsp;</div>
            <div className="col-span-2 -mt-4 text-left text-xs text-red-500">
              Target column input is required for comparison
            </div>
          </>
        )}
      </div>
    </ModalStep>
  );
};

export default RegexBuilder;
