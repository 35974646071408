import { DatasetEditorContext } from "@/components/DatasetEditor";
import { isObjectOrArray } from "@/utils/playground";
import { Cell } from "@tanstack/react-table";
import { useContext, useMemo } from "react";

const useCellType = (cell?: Cell<any, any>, index = -1) => {
  const { isDraft, rows } = useContext(DatasetEditorContext);
  const tableSize = useMemo(() => rows.length, [rows.length]);
  const lastRowIndex = useMemo(
    () => (isDraft ? tableSize - 1 : 0),
    [isDraft, tableSize],
  ); // Index for cell of 'addRow' row using last row index

  const isAddRowRow = useMemo(
    () => cell?.row.index === lastRowIndex && lastRowIndex > 0,
    [cell?.row?.index, lastRowIndex],
  );

  const columnId = useMemo(() => cell?.column.id, [cell?.column?.id]);

  const isPlaceholder = useMemo(
    () => (cell && isAddRowRow && index >= 0) || columnId === "_addCol",
    [cell, columnId, index, isAddRowRow],
  );

  const isAddRowCell = useMemo(
    () => cell && isAddRowRow && index === 0,
    [cell, isAddRowRow, index],
  );

  const isAddColCol = useMemo(() => columnId === "_addCol", [columnId]);

  const isEditable = useMemo(
    () => !isPlaceholder && cell?.column.id !== "_",
    [isPlaceholder, cell?.column.id],
  );

  const isActionsCell = useMemo(
    () => columnId === "_" && !isAddRowRow,
    [columnId, isAddRowRow],
  );

  const isJSONCell = useMemo(() => isObjectOrArray(cell?.getValue()), [cell]);

  return {
    isAddRowCell,
    isPlaceholder,
    isAddRowRow,
    isEditable,
    isActionsCell,
    isAddColCol,
    isJSONCell,
  };
};

export default useCellType;
