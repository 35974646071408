import { Button } from "@/components/ui/button";
import { useAuth } from "@/context/auth-context";
import { ToastType } from "@/enums";
import { useCreateDatasetRow } from "@/queries";
import { displayErrorToast, displayToast } from "@/utils/toast";
import { PlusIcon } from "lucide-react";
import { useCallback, useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import { DatasetEditorContext } from "../..";

const AddRowButton = () => {
  const { datasetId } = useParams();
  const { rows } = useContext(DatasetEditorContext);

  const auth = useAuth();
  const userToken = auth?.userToken;
  const { mutateAsync: createDatasetRow, isLoading } = useCreateDatasetRow(
    userToken!,
  );
  const handleAddRowClick = useCallback(async () => {
    try {
      const response = await createDatasetRow({
        dataset_id: Number(datasetId),
        variables: Object.keys(rows[0])
          .filter((key) => key !== "dataset_row_id")
          .reduce(
            (acc, key) => {
              acc[key] = "";
              return acc;
            },
            {} as Record<string, string>,
          ),
      });

      if (response.success) {
        displayToast("Row created", ToastType.success);
      } else {
        displayErrorToast(
          response.error || "There was an error creating the row",
        );
      }
    } catch (error: unknown) {
      console.log(error);
    }
  }, [createDatasetRow, datasetId, rows]);

  const columnNames = useMemo(() => {
    return rows.length > 0
      ? Object.keys(rows[0]).filter((key) => key !== "dataset_row_id")
      : [];
  }, [rows]);
  return (
    (!!columnNames.length && (
      <Button
        className="mt-3 whitespace-nowrap"
        onClick={handleAddRowClick}
        size="sm"
        variant="outline"
        disabled={isLoading}
      >
        <PlusIcon aria-hidden="true" className="h-4 w-auto pr-1" />
        Add Row
      </Button>
    )) ||
    null
  );
};

export default AddRowButton;
