import { FC, useEffect, useMemo, useState } from "react";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { useAvailableReleaseLabels } from "@/queries";
import { InformationCircleIcon } from "@heroicons/react/outline";

type SelectReleaseLabelModalProps = {
  isOpen: boolean;
  onSubmit: (promptLabelName: string) => void;
  setOpen: (isOpen: boolean) => void;
  currentExperimentsCount: number;
};

const SelectReleaseLabelModal: FC<SelectReleaseLabelModalProps> = ({
  isOpen,
  onSubmit,
  setOpen,
  currentExperimentsCount,
}) => {
  const [selectedReleaseLabel, setSelectedReleaseLabel] = useState("");
  const authContext = useAuth();
  const userToken = authContext!.userToken;
  const userContext = useUser();
  const { data } = useAvailableReleaseLabels(
    userToken!,
    userContext.activeWorkspaceId!,
  );

  useEffect(() => {
    // Reset selected release label when modal is closed
    if (!isOpen) {
      setSelectedReleaseLabel("");
    }
  }, [isOpen]);

  const availableReleaseLabelsList = useMemo(() => {
    if (!data || !data.available_prompt_label_names) return [];
    return [...data.available_prompt_label_names].sort();
  }, [data]);

  const handleSubmit = () => {
    try {
      onSubmit(selectedReleaseLabel);
      setOpen(false);
    } catch (error: unknown) {
      console.error(error);
    }
  };

  const renderContent = () => {
    if (availableReleaseLabelsList.length === 0) {
      return (
        <div className="flex flex-col items-center gap-y-2 rounded-lg border border-gray-100 bg-gray-50 p-4 text-center text-sm">
          <InformationCircleIcon className="h-6 w-6 text-gray-500" />
          {currentExperimentsCount === 0 ? (
            <div>
              You do not have any release labels.
              <br />
              <a
                href="https://docs.promptlayer.com/features/prompt-registry/release-labels"
                className="text-blue-500 hover:text-blue-600"
              >
                Learn more about release labels on the docs.
              </a>
            </div>
          ) : (
            <div>
              You have already configured experiments for all available release
              labels.
            </div>
          )}
        </div>
      );
    }

    return (
      <div className="space-y-2">
        <label
          htmlFor="release-label-select"
          className="block text-sm font-medium text-gray-700"
        >
          Release Label
        </label>
        <DropdownMenu>
          <DropdownMenuTrigger className="w-full rounded-md border border-gray-300 bg-white px-3 py-2 text-left shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500">
            {selectedReleaseLabel || (
              <span className="text-gray-500">Select a release label</span>
            )}
          </DropdownMenuTrigger>
          <DropdownMenuContent className="mt-1 w-full">
            {availableReleaseLabelsList.map((releaseLabel) => (
              <DropdownMenuItem
                key={releaseLabel}
                onSelect={() => setSelectedReleaseLabel(releaseLabel)}
                className="cursor-pointer px-3 py-2 hover:bg-blue-50"
              >
                {releaseLabel}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    );
  };

  return (
    <Dialog onOpenChange={setOpen} open={isOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Select Release Label</DialogTitle>
          <DialogDescription>
            <span className="max-w-md">
              Choose a release label to build an experiment on top of. The
              release label must already be configured on one of your prompt
              templates.
            </span>
          </DialogDescription>
        </DialogHeader>
        {renderContent()}
        <DialogFooter>
          <Button onClick={() => setOpen(false)} variant="secondary">
            Cancel
          </Button>
          {availableReleaseLabelsList.length > 0 && (
            <Button onClick={handleSubmit}>Submit</Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default SelectReleaseLabelModal;
