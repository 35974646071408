import styled from "styled-components";

export const Button = styled.button`
  background: #5167f0;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(50, 62, 135, 0.19);
  color: #ffffff;
  display: block;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: -0.01em;
  padding: 15px 25px;
  transition: 0.2s all ease;
  margin: 0 auto;

  &:hover {
    background: #6b7ef5;
  }

  @media (max-width: 575px) {
    font-size: 18px;
    padding: 8px 35px 10px;
  }
`;

export const Container = styled.div`
  background: #f2f5f5;
  padding: 90px 0;
`;

export const Image = styled.img`
  max-width: 100%;
  width: 100%;
`;

export const ImageWrapper = styled.div`
  max-width: 100%;
  padding: 0 12px;

  @media (min-width: 992px) {
    max-width: 50%;
  }
`;

export const InnerContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 50px;
  justify-content: space-around;
  margin: 0 auto;
  width: 100%;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    flex-direction: column;
    gap: 0;
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1150px;
  }
`;

export const Text = styled.div`
  padding: 0 16px;
  width: 100%;

  h2 {
    color: #090b1c;
    font-size: 38px;
    font-weight: 400;
    letter-spacing: -0.02em;
    line-height: 1.2;
    margin: 0;
    width: 100%;
    text-align: center;
    padding-bottom: 20px;

    @media (min-width: 767px) {
      font-size: 52px;
    }

    @media (min-width: 992px) {
      font-size: 56px;
    }
  }

  @media (min-width: 992px) {
    max-width: 725px;
  }
`;

export const TutorialCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  background-color: #fff;
  box-shadow:
    0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s ease-in-out;
  border: 1px solid #f1f5f9;

  &:hover {
    box-shadow:
      0 30px 35px -5px rgba(0, 0, 0, 0.1),
      0 15px 15px -5px rgba(0, 0, 0, 0.04);
    border: 1px solid #dbeafe;

    img {
      transform: scale(1.05);
    }
  }
`;

export const TutorialCardImageWrapper = styled.div`
  overflow: hidden;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  height: 200px;
`;

export const TutorialCardImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  transition: transform 0.3s ease-in-out;
`;

export const TutorialCardContent = styled.div`
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: 1.5rem;
`;

export const TutorialCardTag = styled.div`
  font-size: 0.875rem;
  text-transform: uppercase;
  color: #2563eb;
  font-weight: 500;
`;

export const TutorialCardTitle = styled.h2`
  font-size: 1.875rem;
  font-weight: 400;
  line-height: 1.25;
  text-transform: capitalize;
`;

export const TutorialCardDate = styled.p`
  font-size: 1rem;
  font-weight: 300;
  color: #9ca3af;
`;

export const TutorialCardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 2.5rem;
  margin-top: 5rem;
  margin-left: 2rem;
  margin-right: 2rem;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;
