import { Message } from "@/types";
import { truncate } from "@/utils/strings";
import { getStringContent } from "@/utils/utils";
import { ReactNode, useMemo } from "react";
import { CopyButton } from "../ui/copy-button";

export const NotAction = ({
  componentIsOpen,
  highlight,
  highlightedContent,
  message,
}: {
  componentIsOpen: boolean;
  highlight: boolean;
  highlightedContent: string | React.ReactNode | null;
  message?: Message;
}) => {
  const displayedMessageContent = useMemo(():
    | ReactNode
    | ReactNode[]
    | null => {
    return message?.content?.map((content, index) => {
      if (content.type === "text") {
        return (
          <div key={`text.${index}`}>
            {highlightedContent ? highlightedContent : content.text}
          </div>
        );
      } else if (componentIsOpen && content.type === "image_url") {
        return (
          <img
            key={`image.${index}`}
            src={content.image_url.url}
            alt="content"
          />
        );
      }
      return null;
    });
  }, [highlightedContent, componentIsOpen, message]);

  const getPreviewContent = (): ReactNode => {
    const contentPart = message?.content?.at(0);
    if (!contentPart) return null;

    if (contentPart["type"] === "image_url") {
      return <span className="italic">[Image]</span>;
    }

    if (contentPart["type"] === "text") {
      return truncate(contentPart["text"], 200);
    }
  };

  return (
    <>
      {!componentIsOpen ? (
        <div className="group-hover:inherit pt-2 text-left">
          {getPreviewContent()}
        </div>
      ) : (
        <div className="flex items-start justify-between">
          <div className="w-full">
            <span className={highlight ? "bg-blue-100 " : ""}>
              {displayedMessageContent}
            </span>
          </div>
          <div>
            <CopyButton text={message ? getStringContent(message) : ""} />
          </div>
        </div>
      )}
    </>
  );
};
