import { ReactNode } from "react";
import { toast } from "react-toastify";

import Toast from "@/components/Toast";
import { ToastType } from "@/enums";

export const displayErrorToast = (error: any) => {
  let errorStr: string;

  if (typeof error === "string") {
    errorStr = error;
  } else if (error?.response?.data) {
    errorStr = JSON.stringify(error.response.data);
  } else if (error?.message) {
    errorStr = error.message;
  } else {
    errorStr = JSON.stringify(error);
  }

  displayToast(errorStr, ToastType.error);
};

export const displayToast = (
  message: ReactNode,
  type: ToastType,
  url?: string,
): void => {
  toast(
    <Toast type={type} url={url}>
      {message}
    </Toast>,
  );
};
