interface DefineInputVariablesMessageProps {
  templateFormat: string;
}

export const DefineInputVariablesMessage: React.FC<
  DefineInputVariablesMessageProps
> = ({ templateFormat }) => {
  return (
    <span className="italic">{`Define an input variable with ${
      templateFormat === "f-string" ? `{brackets}` : `{{brackets}}`
    }`}</span>
  );
};
