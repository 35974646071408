import { FC, FormEvent, useState } from "react";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useAuth } from "@/context/auth-context";
import { ToastType } from "@/enums";
import { useCreateDatasetColumn } from "@/queries";
import { displayErrorToast, displayToast } from "@/utils/toast";

type DatasetColumnModalProps = {
  dataset_id: number;
  setOpen: (isOpen: boolean) => void;
};

const DatasetColumnModal: FC<DatasetColumnModalProps> = ({
  dataset_id,
  setOpen,
}) => {
  const [name, setName] = useState("");
  const authContext = useAuth();
  const userToken = authContext!.userToken;
  const { mutateAsync: createDatasetColumn, isLoading } =
    useCreateDatasetColumn(userToken!);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const response = await createDatasetColumn({
        dataset_id,
        name,
      });

      if (response.success) {
        displayToast("Column created", ToastType.success);
        setOpen(false);
      } else {
        displayErrorToast(
          response.error || "There was an error creating the column",
        );
      }
    } catch (error: unknown) {
      console.log(error);
    }
  };

  const renderForm = () => {
    return (
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label
            className="block text-sm font-medium text-gray-700"
            htmlFor="name"
          >
            Name
          </label>
          <input
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            id="name"
            name="name"
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter column name"
            required
            type="text"
            value={name}
          />
        </div>
        <div className="mt-4 text-center">
          <Button isLoading={isLoading} type="submit">
            Submit
          </Button>
        </div>
      </form>
    );
  };

  return (
    <Dialog open={true} onOpenChange={handleClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create Column</DialogTitle>
        </DialogHeader>
        {renderForm()}
      </DialogContent>
    </Dialog>
  );
};

export default DatasetColumnModal;
