import styled from "styled-components";

export const CarouselContainer = styled.div`
  color: #ffffff;
  padding-top: 35px;
`;

export const Container = styled.section`
  background: #090b1c;
  padding: 50px 0;
`;

export const Image = styled.img`
  max-width: 220px;
  padding: 0 40px;
  width: 100%;
`;

export const Item = styled.div`
  align-items: center;
  background: #171a32;
  border-radius: 90px;
  display: flex;
  height: 120px;
  justify-content: center;
  position: relative;
  width: 240px;

  @media (max-width: 1200px) {
    height: 120px;
    width: 200px;
  }

  @media (max-width: 992px) {
    height: 90px;
    width: 180px;
  }

  @media (max-width: 768px) {
    height: 80px;
    width: 170px;
  }
`;

export const ItemBadge = styled.span`
  background: #b5bded;
  border-radius: 50px;
  bottom: 0;
  color: #5167f0;
  display: inline-block;
  font-size: 20px;
  font-weight: 500;
  left: 50%;
  letter-spacing: -0.013em;
  padding: 0 12px 3px;
  position: absolute;
  text-align: center;
  transform: translateX(-50%);
  width: 144px;

  @media (max-width: 767px) {
    font-size: 14px;
    padding: 0 10px 2px;
    width: 105px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 14px;
    padding: 0 10px 2px;
    width: 110px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 16px;
    padding: 0 10px 2px;
    width: 120px;
  }
`;
