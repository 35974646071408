import { flexRender, Header, Table as TableType } from "@tanstack/react-table";

import LoadingSpinner from "@/components/LoadingSpinner";
import { DivButton } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import useCollapsibleColumns from "@/components/ui/table/collapsible-columns/useCollapsibleColumns";
import TableCell from "@/components/ui/table/core/TableCell";
import { formatInputVariable } from "@/utils/evaluate";
import { ChevronsUpDown, SortAsc, SortDesc } from "lucide-react";
import { useCallback, useMemo, useState } from "react";
import { useReportContext } from "../report-context";

interface Props<TData> {
  table: TableType<TData>;
  isLoading?: boolean;
  emptyMessage: any;
  currentPage?: number;
  pageSize?: number;
}

const FormattedTable = <TData,>({
  table,
  isLoading = true,
  emptyMessage,
  currentPage,
  pageSize,
}: Props<TData>) => {
  const hasRows = !!table.getRowModel().rows?.length;
  const { sortedColumns } = useReportContext();
  const allRows = table.getRowModel().rows;
  const rows = useMemo(
    () =>
      currentPage !== undefined && pageSize !== undefined
        ? allRows?.slice((currentPage - 1) * pageSize, currentPage * pageSize)
        : allRows,
    [allRows, currentPage, pageSize],
  );

  const {
    ColumnStateProvider,
    isColumnCollapsed,
    toggleColumn,
    totalHiddenColumns,
    showAllColumns,
  } = useCollapsibleColumns((hasRows && sortedColumns) || []);

  const headers = table
    .getHeaderGroups()
    .flatMap((headerGroup) => headerGroup.headers);

  const inactiveHeaders = useMemo(
    () => headers.filter((header) => isColumnCollapsed(header.column.id)),
    [headers, isColumnCollapsed],
  );

  const renderHeader = useCallback(
    (header: Header<any, unknown>, hide = false) => {
      return (
        <TableHead
          key={header.column.id}
          className={`h-auto whitespace-nowrap border-gray-200 ${
            hide && "hidden"
          }`}
        >
          <>
            {header.column.getCanSort() ? (
              <DivButton
                variant="ghost"
                className="-ml-3 h-8"
                onClick={header.column.getToggleSortingHandler()}
              >
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext(),
                )}
                {header.column.getIsSorted() === "desc" ? (
                  <SortDesc className="ml-2 h-4 w-4" />
                ) : header.column.getIsSorted() === "asc" ? (
                  <SortAsc className="ml-2 h-4 w-4" />
                ) : (
                  <ChevronsUpDown className="ml-2 h-4 w-4" />
                )}
              </DivButton>
            ) : (
              <div
                className={`group relative flex h-full w-full flex-col items-center justify-center rounded-md align-middle`}
              >
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext(),
                )}
              </div>
            )}
          </>
        </TableHead>
      );
    },
    [],
  );

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const Headers = useMemo(
    () =>
      headers.map((header, i) =>
        renderHeader(header, isColumnCollapsed(header.column.id)),
      ),
    [headers, isColumnCollapsed, renderHeader],
  );

  return (
    <ColumnStateProvider>
      <div className="rounded-md border-b ">
        <Table className="min-h-[250px]">
          <TableHeader>
            <TableRow>
              {inactiveHeaders.length > 0 && (
                <TableHead key={"hidden_columns"}>
                  <DropdownMenu
                    onOpenChange={setDropdownOpen}
                    open={dropdownOpen}
                  >
                    <DropdownMenuTrigger className="m-2">
                      <div className=" flex w-fit flex-col whitespace-nowrap rounded-md p-2 ">
                        <span>{`Hidden (${inactiveHeaders.length})`}</span>
                      </div>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="mx-auto flex w-full flex-col items-center justify-center">
                      <DivButton
                        variant={"link"}
                        size={"sm"}
                        onClick={showAllColumns}
                      >
                        Show All
                      </DivButton>
                      <div className="mx-3 mb-3 flex flex-col  gap-y-1">
                        {inactiveHeaders?.map((item) => {
                          const column = sortedColumns.find(
                            ({ id }: { id: number }) =>
                              item && String(id) === item.column.id,
                          );

                          if (!column) return null;
                          const { name, column_type } = column;

                          return (
                            <DropdownMenuItem
                              key={item?.column.id}
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                return item && toggleColumn(item?.column.id);
                              }}
                              className="box-border flex w-full flex-row items-center gap-2 overflow-ellipsis"
                            >
                              <input
                                id={item?.column.id}
                                type="checkbox"
                                checked={
                                  item && isColumnCollapsed(item?.column.id)
                                }
                                readOnly
                                className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                              />
                              <p
                                className={`relative box-border flex w-auto  `}
                              >
                                {formatInputVariable(column_type, name)}
                              </p>
                            </DropdownMenuItem>
                          );
                        })}
                      </div>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </TableHead>
              )}
              {Headers}
            </TableRow>
          </TableHeader>
          <TableBody>
            {hasRows ? (
              rows.map((row) => (
                <TableRow
                  key={row.id}
                  className="group"
                  data-state={row.getIsSelected() && "selected"}
                >
                  {totalHiddenColumns > 0 && (
                    <TableCell className="w-1"></TableCell>
                  )}
                  {row
                    .getVisibleCells()
                    .filter((cell) => !isColumnCollapsed(cell.column.id))

                    .map((cell, i) => {
                      return (
                        <TableCell key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </TableCell>
                      );
                    })}
                </TableRow>
              ))
            ) : (
              <TableRow key={"is_loading"}>
                <TableCell
                  colSpan={table.getAllColumns().length}
                  className="relative h-24 text-center"
                >
                  {isLoading ? (
                    <LoadingSpinner key={"loading_spinner"} />
                  ) : (
                    emptyMessage
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </ColumnStateProvider>
  );
};

export default FormattedTable;
