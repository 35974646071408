import { ChatTemplate, Message } from "@/types";
import { buttonStyle } from "@/utils/buttonStyle";
import { ChevronUpIcon, TerminalIcon } from "@heroicons/react/outline";

export const MessageHeader = ({
  componentIsOpen,
  handleToggle,
  children,
  message,
  chatTemplate,
}: {
  componentIsOpen: boolean;
  handleToggle: () => void;
  children: React.ReactNode;
  message?: Message;
  chatTemplate: ChatTemplate;
}) => {
  let role = message?.raw_request_display_role || message?.role || "";
  const isToolResponse = message?.role === "tool";
  const isFunctionResponse = message?.role === "function";
  const functions = chatTemplate.functions;
  const tools = chatTemplate.tools;
  const isToolDeclarations = tools && tools?.length > 0;
  if (isToolDeclarations && !message) role = "Tools";
  const isFunctionDeclarations = functions && functions?.length > 0;
  if (isFunctionDeclarations && !message) role = "Functions";
  const isToolCall =
    message?.role === "assistant" &&
    message.tool_calls &&
    message?.tool_calls.length > 0 &&
    !message?.content;
  const isFunctionCall =
    message?.role === "assistant" &&
    message?.function_call &&
    !message?.content;
  const functionName =
    message?.role === "function"
      ? message?.name || "Function"
      : message?.role === "tool"
      ? message?.name || "Tool"
      : "";
  const border = (open: boolean) => {
    let border = `${
      !open && "hover:border-gray-400 hover:shadow"
    } border-gray-300 `;
    if (isToolResponse || isFunctionResponse) {
      border += "border-dashed shadow-inner";
    } else if (
      (isToolDeclarations || isFunctionDeclarations) &&
      message === undefined
    ) {
      border += "border-dashed";
    } else if (isToolCall || isFunctionCall) {
      border += "";
    }
    return border;
  };

  return (
    <div
      className={`group rounded border-b border-l border-r ${border(
        componentIsOpen,
      )}`}
    >
      <button
        className={`sticky top-0 w-full justify-between rounded p-2 text-gray-500 ${
          buttonStyle(role, componentIsOpen) || ""
        } border-t ${border(componentIsOpen)}`}
        onClick={handleToggle}
      >
        <div className="flex">
          <div className="flex-1">
            <div
              className={`flex items-center space-x-1 ${
                isToolResponse && isFunctionResponse && "uppercase"
              } group-hover:text-gray-900`}
            >
              <div
                className={`group-hover:inherit text-xs ${
                  componentIsOpen && "text-gray-900"
                }`}
              >
                {isFunctionResponse || isToolResponse ? (
                  <div className="flex items-center">
                    <TerminalIcon className="mr-1 inline h-4 w-4" />
                    <div className="font-mono text-sm">{functionName}</div>
                    <div className="pl-1 text-sm font-light italic text-gray-500">
                      (response)
                    </div>
                  </div>
                ) : (
                  <div className="uppercase">{role}</div>
                )}
              </div>
              {!isToolResponse && !isFunctionResponse && message?.name && (
                <>
                  <div className="h-1 w-1 rounded-full group-hover:bg-gray-300"></div>
                  <div className="group-hover:inherit">{message?.name}</div>
                </>
              )}
            </div>
          </div>
          <div className="group-hover:text-gray-800">
            <ChevronUpIcon
              className={`${
                !componentIsOpen ? "rotate-180 transform" : ""
              } h-4 w-4`}
            />
          </div>
        </div>
        {!componentIsOpen && children}
      </button>
      <div>{componentIsOpen && children}</div>
    </div>
  );
};
