import { TagsDropdown } from "@/components/TagsDropdown";
import { URL_PARAM_KEYS } from "@/utils/utils";
import { useSearchParams } from "react-router-dom";

const FilterByTags = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const clearFilters = () => {
    setSearchParams((previous) => {
      previous.delete(URL_PARAM_KEYS.TAGS);
      previous.delete(URL_PARAM_KEYS.FAVORITES);
      return previous;
    });
  };

  const toggleTag = (tag: string) => {
    let newTags: Array<string> = [];
    if (selectedTags.includes(tag)) {
      newTags = selectedTags.filter((t: string) => t !== tag);
    } else {
      newTags = [...selectedTags, tag];
    }
    setSearchParams((previous) => {
      if (newTags.length === 0) {
        previous.delete(URL_PARAM_KEYS.TAGS);
      } else {
        previous.set(URL_PARAM_KEYS.TAGS, JSON.stringify(newTags));
      }
      return previous;
    });
  };

  const showFavorites = searchParams.get(URL_PARAM_KEYS.FAVORITES) === "true";
  const selectedTagsParam = searchParams.get(URL_PARAM_KEYS.TAGS) ?? "[]";
  const selectedTags = JSON.parse(selectedTagsParam) as Array<string>;

  return (
    <TagsDropdown
      selectedTags={selectedTags}
      toggleTag={toggleTag}
      showFavorites={showFavorites}
      setShowFavorites={(show) => {
        setSearchParams((previous) => {
          if (show) {
            previous.set(URL_PARAM_KEYS.FAVORITES, "true");
          } else {
            previous.delete(URL_PARAM_KEYS.FAVORITES);
          }
          return previous;
        });
      }}
      clearFilters={clearFilters}
    />
  );
};

export default FilterByTags;
