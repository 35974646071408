import { useEffect, useRef } from "react";
function getGclidParameter(): string | null {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get("gclid");
}
const useTracking = () => {
  useEffect(() => {
    const param = getGclidParameter();
    if (param) localStorage.setItem("gclid", param);
  }, []);
};

export const useGclid = () => {
  const gclid = useRef<string | null>(null);
  useEffect(() => {
    if (!gclid.current) {
      gclid.current = localStorage.getItem("gclid");
    }
    const urlObject = new URL(window.location.href);
    const param = urlObject.searchParams.get("gclid");
    if (param) {
      gclid.current = param;
    }
  }, []);
  return gclid.current || undefined;
};

export default useTracking;
