import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";

import { classNames } from "@/utils/strings";

export default function DropdownButton({
  dropdownContent,
  dropdownItems,
  width,
  children,
  testid,
}: {
  dropdownContent?: React.ReactNode;
  dropdownItems: {
    icon?: React.ElementType;
    name: string;
    onClick: () => void;
    disabled?: boolean;
  }[];
  width?: string;
  children: React.ReactNode;
  testid?: string;
}) {
  return (
    <Menu as="div" className="z-70">
      <Menu.Button
        className="flex max-w-xs items-center rounded-full text-sm text-gray-700 hover:text-gray-600"
        data-testid={testid}
      >
        {children}
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`absolute right-4 mt-2 ${
            width || "w-48"
          } z-20 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
        >
          {/* <Menu.Item> */}
          {dropdownContent}
          {/* </Menu.Item> */}

          {dropdownItems &&
            dropdownItems.map(
              (
                item: {
                  icon?: React.ElementType;
                  name: string;
                  onClick: () => void;
                  disabled?: boolean;
                },
                idx: number,
              ) => {
                return (
                  <Menu.Item key={idx}>
                    {({ active }) => (
                      <div
                        className={classNames(
                          active ? "bg-gray-100 text-gray-600" : "",
                          item.disabled
                            ? "cursor-not-allowed text-gray-500"
                            : "cursor-pointer text-gray-700",
                          "flex items-center px-4 py-2 text-sm",
                        )}
                        onClick={item.onClick}
                      >
                        {item.icon && (
                          <item.icon className="inline h-4 w-auto pr-2" />
                        )}
                        {item.name}
                      </div>
                    )}
                  </Menu.Item>
                );
              },
            )}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
