import { FC } from "react";
import { Switch } from "../switch";

type ToggleSwitchProps = {
  value: string;
  left: string;
  right: string;
  onChange: (value: string) => void;
  disabled: boolean;
};

const ToggleSwitch: FC<ToggleSwitchProps> = ({
  value,
  left,
  right,
  onChange,
  disabled = false,
}) => {
  return (
    <div className="flex items-center gap-4 p-2 text-sm ">
      <div>{left}</div>
      <Switch
        checked={value === right}
        onChange={(checked) => onChange(checked ? right : left)}
        disabled={disabled}
      />
      <div>{right}</div>
    </div>
  );
};

export default ToggleSwitch;
