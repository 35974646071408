import styled from "styled-components";

export const Button = styled.button`
  background: #5167f0;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(50, 62, 135, 0.19);
  color: #ffffff;
  display: inline-block;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.01em;
  padding: 10px 40px;
  transition: 0.2s all ease;

  &:hover {
    background: #6b7ef5;
  }
`;

export const Container = styled.section`
  background: #ffffff;
  min-height: 380px;
  padding: 70px 0 0;
  position: relative;
`;

export const Content = styled.div`
  margin: 0 auto;
  max-width: 936px;
  padding: 0 15px;
  position: relative;
  text-align: center;
  width: 100%;

  h2 {
    color: #090b1c;
    font-size: 56px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.02em;

    @media (max-width: 767px) {
      font-size: 45px;
    }

    @media (max-width: 575px) {
      font-size: 38px;
    }
  }

  p {
    max-width: 485px;
    color: #090b1c;
    font-size: 19px;
    font-weight: 400;
    letter-spacing: -0.01em;
    line-height: 1.5;
    margin: 10px auto 35px;
    text-align: center;

    a {
      color: #0d6efd;
    }

    @media (max-width: 767px) {
      font-size: 18px;
    }
  }
`;

export const Shape1 = styled.img`
  left: 0;
  max-width: 100%;
  position: absolute;
  top: 136px;
`;

export const Shape2 = styled.img`
  max-width: 100%;
  position: absolute;
  right: 0;
  top: 265px;
`;
