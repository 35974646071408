import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useAuth } from "@/context/auth-context";
import { ToastType } from "@/enums";
import useActiveWorkspace from "@/hooks/useActiveWorkspace";
import { useDeleteReleaseLabelGroup } from "@/queries";
import { displayErrorToast, displayToast } from "@/utils/toast";

type DeleteReleaseLabelGroupModalProps = {
  open: boolean;
  releaseLabelGroupId: number;
  setOpen: (open: boolean) => void;
};

const DeleteReleaseLabelGroupModal: FC<DeleteReleaseLabelGroupModalProps> = ({
  open,
  releaseLabelGroupId,
  setOpen,
}) => {
  const activeWorkspace = useActiveWorkspace();
  const authContext = useAuth();
  const deleteReleaseLabelGroup = useDeleteReleaseLabelGroup(
    authContext!.userToken!,
  );
  const navigate = useNavigate();

  const handleDeleteClick = async () => {
    try {
      await deleteReleaseLabelGroup.mutateAsync(releaseLabelGroupId);
      displayToast("A/B release deleted", ToastType.success);
      navigate(`/workspace/${activeWorkspace!.id}/ab-releases`);
      setOpen(false);
    } catch (error: unknown) {
      console.log(error);
      displayErrorToast("Failed to delete A/B release");
    }
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            Are you sure you want to delete this A/B release?
          </DialogTitle>
        </DialogHeader>
        <div className="text-gray-700">This action cannot be undone.</div>
        <DialogFooter className="mt-5">
          <Button
            className="bg-transparent text-sm"
            onClick={() => setOpen(false)}
            type="button"
            variant="outline"
          >
            Cancel
          </Button>
          <Button onClick={handleDeleteClick} variant="destructive">
            Delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteReleaseLabelGroupModal;
