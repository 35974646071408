import { FC, useEffect, useRef, useState } from "react";
import { ExclamationCircleIcon } from "@heroicons/react/outline";

import LoadingSpinner from "@/components/LoadingSpinner";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useAuth } from "@/context/auth-context";
import { ToastType } from "@/enums";
import { useDatasetColumnToJson } from "@/queries";
import { displayToast } from "@/utils/toast";

type DatasetColumnToJsonModalProps = {
  column_name: string;
  dataset_id: number;
  setOpen: (isOpen: boolean) => void;
};

const DatasetColumnToJsonModal: FC<DatasetColumnToJsonModalProps> = ({
  column_name,
  dataset_id,
  setOpen,
}) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const authContext = useAuth();
  const userToken = authContext!.userToken;
  const { mutateAsync: datasetColumnToJson } = useDatasetColumnToJson(
    userToken!,
  );
  const hasMutationTriggered = useRef(false);

  useEffect(() => {
    if (hasMutationTriggered.current) return;
    hasMutationTriggered.current = true;

    (async () => {
      try {
        setIsLoading(true);
        const response = await datasetColumnToJson({
          column_name,
          dataset_id,
        });

        if (response.success) {
          displayToast("Conversion successful!", ToastType.success);
          setErrorMessage(null);
          setOpen(false);
        } else {
          setErrorMessage(response.error || "Error in conversion");
        }
      } catch (error: unknown) {
        setErrorMessage("Network error or bad server response");
      } finally {
        setIsLoading(false);
      }
    })();
  }, [column_name, datasetColumnToJson, dataset_id, setIsLoading, setOpen]);

  return (
    <Dialog open={true} onOpenChange={setOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Convert Dataset Column to JSON</DialogTitle>
        </DialogHeader>
        {isLoading && (
          <div className="mt-4 text-center">
            <LoadingSpinner size={5} />
          </div>
        )}
        {errorMessage && (
          <div className="mt-6 max-h-[500px] overflow-y-auto rounded-md border border-red-300 bg-red-50 p-4">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <ExclamationCircleIcon
                  className="h-6 w-6 text-red-500"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-4">
                <h3 className="text-lg font-medium text-red-800">Error</h3>
                <div className="mt-2 text-base text-red-700">
                  <p>{errorMessage}</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default DatasetColumnToJsonModal;
