import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Form,
  FormDescription,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import useDefaultWorkspace from "@/hooks/useDefaultWorkspace";
import { useDeleteWorkspaceMember } from "@/queries";

type DeleteWorkspaceMemberModalProps = {
  member: any;
  open: boolean;
  setOpen: (open: boolean) => void;
};

const DeleteWorkspaceMemberForm = (props: DeleteWorkspaceMemberModalProps) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const authContext = useAuth();
  const userContext = useUser();
  const defaultWorkspace = useDefaultWorkspace();
  const deleteWorkspaceMember = useDeleteWorkspaceMember(
    authContext!.userToken!,
  );
  const form = useForm({
    defaultValues: {
      member_email: "",
    },
  });

  const onSubmit = async () => {
    try {
      // If they deleted themselves from the workspace
      if (props.member.user_id === authContext!.user?.id) {
        userContext!.setWorkspaceId(defaultWorkspace!.id);
      }

      await deleteWorkspaceMember.mutateAsync(props.member.id);
      props.setOpen(false);
    } catch (error: unknown) {
      console.log(error);
    }
  };

  if (!props.member) return null;

  return (
    <>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            name="member_email"
            render={({ field }) => (
              <FormItem>
                <FormDescription className="text-gray-700">
                  This action cannot be undone.
                </FormDescription>
                <Input
                  className="italic"
                  placeholder={props.member.email}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    setIsDisabled(e.target.value !== props.member.email);
                  }}
                />
                <FormDescription>
                  To confirm, type <b>{props.member.email}</b> in the box above.
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <DialogFooter className="mt-5">
            <Button
              type="button"
              onClick={() => props.setOpen(false)}
              className="bg-transparent text-sm"
              variant="outline"
            >
              Cancel
            </Button>
            <Button type="submit" variant="destructive" disabled={isDisabled}>
              Confirm Deletion
            </Button>
          </DialogFooter>
        </form>
      </Form>
    </>
  );
};

const DeleteWorkspaceMemberModal: FC<DeleteWorkspaceMemberModalProps> = (
  props,
) => (
  <Dialog open={props.open} onOpenChange={props.setOpen}>
    <DialogContent>
      <DialogHeader>
        <DialogTitle>
          Are you sure you want to delete this workspace member?
        </DialogTitle>
      </DialogHeader>
      <DeleteWorkspaceMemberForm {...props} />
    </DialogContent>
  </Dialog>
);

export default DeleteWorkspaceMemberModal;
