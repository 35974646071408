import codeCircleIcon from "@/assets/code-circle.svg";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { useCheckDynamicReleaseLabel } from "@/queries";

const useDynamicReleaseLabelIndicator = (
  label: string,
  template_name: string,
) => {
  const userToken = useAuth()?.userToken || "";
  const userContext = useUser();

  const { data, isLoading } = useCheckDynamicReleaseLabel(
    label,
    template_name,
    userContext.activeWorkspaceId!,
    userToken,
  );

  return !isLoading && data && data.exists;
};

const Snippet = ({
  value,
  template_name,
  label,
}: {
  value: string;
  template_name: string;
  label: string;
}) => {
  const isDynamicReleaseLabel = useDynamicReleaseLabelIndicator(
    label,
    template_name,
  );

  return (
    <button
      className={`${
        (isDynamicReleaseLabel &&
          "border-1  border-dashed border-blue-600 shadow-[0_0_8px_1px] shadow-blue-500/25 ") ||
        "border-gray-100 hover:bg-gray-100"
      } text-gray relative left-1 rounded-full border bg-gray-50 px-2 py-1 font-semibold hover:border-blue-200  hover:text-blue-600`}
    >
      <img
        src={codeCircleIcon}
        alt="Code Circle Icon"
        className="-mt-1 mr-2 inline h-4 w-auto"
      />
      {value
        .replace(/@@@/g, "")
        .replace(/@label:/g, ":")
        .replace(/@version_number:(\w+)/g, "[#$1]")}
    </button>
  );
};

export default Snippet;
