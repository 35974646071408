import batchScreenshot from "@/assets/evaluate/spreadsheet.png";
import { Button } from "@/components/ui/button";
import { useUser } from "@/context/user-context";
import { FC } from "react";
import { Link } from "react-router-dom";
import Feature from "./Feature";

type BatchFeatureProps = {
  handleCloseClick: () => void;
};

const BatchFeature: FC<BatchFeatureProps> = ({ handleCloseClick }) => {
  const userContext = useUser();
  return (
    <Feature onCloseClick={handleCloseClick}>
      <div className="flex">
        <div className="w-1/2">
          <img
            alt="Feature"
            className="mx-auto w-full max-w-[400px] rounded-lg"
            src={batchScreenshot}
          />
        </div>
        <div className="ml-6 w-1/2">
          <h2 className="mt-6 text-2xl font-medium">Batch runs</h2>
          <p className="mt-2 text-base text-gray-500">
            Visually build pipelines to evaluate your prompts. Use this to
            compare outputs, chain prompts together, or just run one-off
            batches.
            <br />
            <br />
            Trigger regression tests to run every time a prompt template is
            updated. Score prompts with human graders, golden datasets, and AI
            evaluators. Or use your own custom endpoint!
          </p>
          <Link to={`/workspace/${userContext?.activeWorkspaceId}/evaluate`}>
            <Button className="mt-4" variant="secondaryLightOutline">
              Kick off a batch
            </Button>
          </Link>
        </div>
      </div>
    </Feature>
  );
};

export default BatchFeature;
