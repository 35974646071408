import { generateNewApiKey } from "@/api/application-api";
import { Button } from "@/components/ui/button";
import { AuthContextType, useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { Workspace } from "@/types/workspaces";
import {
  CheckIcon,
  DocumentDuplicateIcon,
  XIcon,
} from "@heroicons/react/outline";
import { useMemo, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import SyntaxHighlighter from "react-syntax-highlighter";
import { dracula } from "react-syntax-highlighter/dist/esm/styles/hljs";
import LoadingSpinner from "./LoadingSpinner";
import { useRefreshPromptLayerApiKeys } from "@/queries";

export function CodeCopyBlock({
  code,
  python = false,
}: {
  code: string;
  python?: boolean;
}) {
  const [isCopied, setIsCopied] = useState(false);

  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const copyButton = (
    <span className="absolute right-1 top-1 text-gray-200 hover:text-gray-100">
      <CopyToClipboard text={code} onCopy={onCopyText}>
        <span className="cursor-pointer">
          {isCopied ? (
            <CheckIcon className="h-5 w-auto" />
          ) : (
            <DocumentDuplicateIcon className="h-5 w-auto" />
          )}
        </span>
      </CopyToClipboard>
    </span>
  );

  const codeBlock = (
    <div className="relative h-full w-full">
      {copyButton}
      <SyntaxHighlighter
        language={python ? "python" : "javascript"}
        style={dracula}
        customStyle={{ padding: "15px" }}
        wrapLines={true}
      >
        {code}
      </SyntaxHighlighter>
    </div>
  );

  return (
    <div
      className="max-h-90 mt-2 h-full rounded-sm px-2"
      style={{ overflowX: "hidden" }}
    >
      {codeBlock}
    </div>
  );
}

export function ApiKeyComponent() {
  const [refreshingToken] = useState(false);
  const [loading, setLoading] = useState(false);
  const [apiKey, setApiKey] = useState(null);
  const [isCopied, setIsCopied] = useState(false);
  const authContext: AuthContextType = useAuth()!;
  const userContext = useUser();

  const refreshPromptLayerApiKeys = useRefreshPromptLayerApiKeys();

  const onCopyKey = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const activeWorkspaceName = useMemo(() => {
    if (!authContext || !userContext) return null;
    const workspace = userContext.workspaces.find(
      ({ id }: Workspace) => id === userContext.activeWorkspaceId,
    );
    return workspace?.name;
  }, [authContext, userContext]);

  const handleGenerateNewApiKey = async () => {
    setLoading(true);
    const response = await generateNewApiKey(
      authContext!,
      userContext?.activeWorkspaceId!,
    );
    if (response.status === 200) {
      const data = await response.json();
      if (data.success) {
        setApiKey(data.api_key);
        refreshPromptLayerApiKeys();
      }
    } else {
      console.log("Error generating new API key");
    }
    setLoading(false);
  };

  const codeBlock = `from promptlayer import PromptLayer
pl_client = PromptLayer(api_key = "${apiKey || "<API_KEY>"}")`;

  if (!apiKey)
    return (
      <div className="my-4">
        <Button
          onClick={handleGenerateNewApiKey}
          size="default"
          variant="outline"
        >
          Generate New API Key
        </Button>
      </div>
    );

  return (
    <>
      {loading ? (
        <div className="px-32 py-12 text-center">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="mt-4 rounded-lg border border-gray-200 shadow-sm">
          <div className="px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mt-3 px-2 text-center sm:mt-0 sm:text-left">
                <h3 className="mb-3 text-lg font-semibold leading-6 text-gray-900">
                  Your PromptLayer API Key
                </h3>
                <h4 className="my-3 text-sm font-light leading-6 text-gray-800">
                  You will only see this API Key once. Make sure to save it
                  somewhere safe. If you lose it, you can generate a new one by
                  clicking on the user icon on the top left.
                </h4>
                {!refreshingToken ? (
                  <>
                    <div className="grid grid-cols-5 items-baseline py-3">
                      <div className="col-span-1 py-2 text-sm font-light text-gray-800">
                        WORKSPACE:
                      </div>
                      <div className="col-span-4 text-sm font-semibold text-gray-800">
                        {activeWorkspaceName}
                      </div>
                      <div className="col-span-1 py-2 text-sm font-light text-gray-800">
                        API KEY:
                      </div>
                      <div className="col-span-4">
                        <div className="flex">
                          <input
                            value={apiKey || "Loading..."}
                            type="text"
                            className="block w-full rounded-md border-gray-300 text-gray-600 hover:text-gray-700 focus:border-indigo-500 focus:text-gray-800 focus:ring-indigo-500 sm:text-sm"
                          />
                          <span className="ml-2 cursor-pointer rounded-md border hover:bg-gray-50">
                            <CopyToClipboard
                              text={apiKey || "Loading..."}
                              onCopy={onCopyKey}
                            >
                              <span className="cursor-pointer">
                                {isCopied ? (
                                  <CheckIcon className="mt-2 h-5 w-auto flex-shrink-0 px-2 text-gray-500 hover:text-gray-700" />
                                ) : (
                                  <DocumentDuplicateIcon className="mt-2 h-5 w-auto flex-shrink-0 px-2 text-gray-500 hover:text-gray-700" />
                                )}
                              </span>
                            </CopyToClipboard>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="px-1">
                      <h4 className="mt-2 text-sm font-light leading-6 text-gray-800">
                        Setting up your API key in Python
                      </h4>
                      <div className="max-w-xl" style={{ overflowX: "hidden" }}>
                        <CodeCopyBlock code={codeBlock} />
                      </div>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          <a
                            href="https://docs.promptlayer.com"
                            target="_blank"
                            className="hover:underline"
                            rel="noreferrer"
                          >
                            See the docs for more information
                          </a>
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="w-full py-6 text-center">
                    <LoadingSpinner size="10" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export function ApiKeyModal({
  showModal,
  setShowModal,
}: {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
}) {
  if (!showModal) {
    return null;
  }

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none focus:outline-none">
        <div className="relative mx-auto my-6 w-auto max-w-2xl rounded-md bg-white shadow-lg">
          {/*content*/}
          <div className="relative flex w-full flex-col rounded-md border-0 outline-none focus:outline-none">
            <div className="sm:flex sm:flex-row-reverse ">
              <XIcon
                className="mr-3 mt-3 h-5 w-auto flex-shrink-0 cursor-pointer text-gray-500 hover:text-gray-700"
                aria-hidden="true"
                onClick={() => setShowModal(false)}
              />
            </div>
            <ApiKeyComponent />
          </div>
          {/*footer*/}
          <div className="rounded-md p-4 sm:flex sm:flex-row-reverse sm:px-6">
            <Button onClick={() => setShowModal(false)}>Close</Button>
          </div>
        </div>
      </div>
      <div className="fixed inset-0 z-40 bg-black opacity-75"></div>
    </>
  );
}
