import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  FilterIcon,
  InformationCircleIcon,
  XIcon,
} from "@heroicons/react/outline";
import { TooltipArrow } from "@radix-ui/react-tooltip";

import { PopoverContent } from "@/components/ui/popover";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import { useRequest, useUpdateRequestMetadata } from "@/queries";
import { MetadataItem } from "@/types/requests";
import { handleAddMetadataToSearchParams } from "@/utils/metadata";
import { Button } from "./ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip";

export const MetadataPopoverContent = ({
  requestId,
}: {
  requestId: string;
}) => {
  const auth = useAuth();
  const userContext = useUser();
  let requestInfo = userContext.getRequest(requestId);
  const request = useRequest(
    requestId,
    auth?.userToken || "",
    !requestInfo,
    userContext?.activeWorkspaceId!,
  );
  requestInfo = requestInfo ?? request.data?.items?.[0];
  const metadataFromRequest = requestInfo?.metadata;
  const metadataItems: MetadataItem[] =
    !metadataFromRequest ||
    metadataFromRequest === null ||
    metadataFromRequest[0] === null
      ? []
      : (metadataFromRequest as MetadataItem[]).filter((item) => !!item);

  return (
    <PopoverContent className="mr-1 w-[500px]">
      <MetadataPopoverContentInner
        requestId={requestId}
        metadataItems={metadataItems || []}
      />
    </PopoverContent>
  );
};

export const MetadataPopoverContentInner = ({
  requestId,
  metadataItems,
}: {
  requestId: string;
  metadataItems: MetadataItem[];
}) => {
  const [currentMetadata, setCurrentMetadata] =
    useState<MetadataItem[]>(metadataItems);

  const [newKey, setNewKey] = useState("");
  const [newValue, setNewValue] = useState("");
  const [newKeyError, setNewKeyError] = useState<string | null>(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const userToken = useAuth()!.userToken;
  const {
    mutate: updateRequestMetadata,
    isLoading: updateRequestMetadataIsLoading,
  } = useUpdateRequestMetadata(userToken || "");

  const handleUpdateRequestMetadata = () => {
    if (newKey) {
      if (!newMetadataKeyIsValid()) {
        setNewKeyError("Key already exists");
        return;
      } else if (newKey === "") {
        setNewKeyError("Key cannot be empty");
        return;
      } else {
        setNewKeyError(null);
        const newMetadata = { [newKey]: newValue };
        setNewKey("");
        setNewValue("");
        submitNewMetadata([...currentMetadata, newMetadata]);
        setCurrentMetadata([...currentMetadata, newMetadata]);
      }
    } else {
      setNewKeyError(null);
      submitNewMetadata(currentMetadata);
    }
  };

  const submitNewMetadata = (metadataToSubmit?: MetadataItem[]) => {
    const metadataObject: Record<string, string> = (
      metadataToSubmit || []
    ).reduce((acc, item) => {
      const [key, value] = Object.entries(item)[0];
      acc[key] = value;
      return acc;
    }, {});
    updateRequestMetadata({ request_id: requestId, metadata: metadataObject });
  };

  const newMetadataKeyIsValid = () => {
    return !(currentMetadata || []).some(
      (item) => Object.keys(item)[0] === newKey,
    );
  };

  const handleEditMetadata = (index: number, key: string, value: string) => {
    const updatedMetadata = [...currentMetadata];
    updatedMetadata[index] = { [key]: value };
    setCurrentMetadata(updatedMetadata);
  };

  // Function to handle adding metadata to URL search parameters
  const handleAddToSearchParams = (key: string, value: string) => {
    if (key && value) {
      handleAddMetadataToSearchParams(
        searchParams,
        setSearchParams,
        key,
        value,
      );
    }
  };

  return (
    <TooltipProvider>
      <div className="flex flex-col space-y-3 px-4 py-3">
        <div className="flex items-center space-x-2">
          <div>
            <InformationCircleIcon className="inline h-6 text-gray-600" />
          </div>
          <div className="flex-1">
            <div className="text-sm text-gray-800">Request Metadata</div>
            <div className="text-xs text-gray-600">
              See{" "}
              <a
                href="https://docs.promptlayer.com/features/prompt-history/metadata"
                className="text-blue-500 hover:text-blue-400"
                target="_blank"
                rel="noreferrer"
              >
                docs
              </a>{" "}
              on how to add attributes programmatically.
            </div>
          </div>
        </div>
        <div className="overflow-x-auto">
          <div className="min-w-full divide-y divide-gray-200">
            <div className="grid grid-cols-2 gap-4 px-4 py-2 font-mono text-xs font-medium uppercase tracking-wider text-gray-600">
              <div>Key</div>
              <div>Value</div>
            </div>
            <div className="divide-y divide-gray-200">
              <div className="max-h-60 divide-y divide-gray-200 overflow-y-auto bg-white">
                {currentMetadata
                  .sort((a, b) => {
                    const aKey = Object.keys(a)[0];
                    const bKey = Object.keys(b)[0];
                    return aKey.localeCompare(bKey);
                  })
                  .map((item: MetadataItem, index: number) =>
                    Object.entries(item).map(([key, value]) => (
                      <div
                        key={`${key}-${index}`}
                        className="grid grid-cols-2 gap-4 px-4 py-2 hover:bg-gray-100"
                      >
                        <div className="flex items-center whitespace-nowrap align-middle text-gray-600">
                          <input
                            type="text"
                            readOnly
                            className="w-full border-none bg-transparent text-xs text-gray-600"
                            value={key}
                          />
                        </div>
                        <div className="flex flex-row gap-x-2 break-words align-middle text-gray-600">
                          <textarea
                            value={value}
                            onChange={(e) =>
                              handleEditMetadata(index, key, e.target.value)
                            }
                            className="w-full rounded border border-gray-300 bg-transparent p-1 text-xs"
                          ></textarea>
                          <Tooltip delayDuration={0}>
                            <TooltipTrigger asChild>
                              <Button
                                size="tinyIcon"
                                variant="outline"
                                className="mt-1"
                                onClick={() =>
                                  handleAddToSearchParams(key, value)
                                }
                              >
                                <FilterIcon className="h-3 w-4 text-gray-500" />
                              </Button>
                            </TooltipTrigger>
                            <TooltipContent
                              side="top"
                              sideOffset={10}
                              className="max-w-sm rounded bg-gray-800 p-2 text-sm text-white"
                            >
                              Add to search
                              <TooltipArrow className="fill-current text-gray-800" />
                            </TooltipContent>
                          </Tooltip>
                        </div>
                      </div>
                    )),
                  )}
              </div>
              <div className="grid grid-cols-2 gap-x-4 p-4">
                <div className="flex items-center whitespace-nowrap align-middle text-gray-600">
                  <div className="flex w-full gap-x-2">
                    <Button
                      onClick={() => {
                        setNewKey("");
                        setNewValue("");
                      }}
                      variant="ghost"
                      size="tinyIcon"
                      className="flex-shrink-0"
                    >
                      <XIcon className="h-4 w-4 text-red-500" />
                    </Button>
                    <textarea
                      value={newKey}
                      onChange={(e) => setNewKey(e.target.value)}
                      placeholder="Key"
                      className="flex-1 rounded border border-gray-300 p-1 text-xs placeholder-gray-400"
                    ></textarea>
                  </div>
                </div>
                <div className="break-words align-middle text-gray-600">
                  <div className="flex w-full gap-x-2">
                    <textarea
                      value={newValue}
                      onChange={(e) => setNewValue(e.target.value)}
                      placeholder="Value"
                      className="w-full rounded border border-gray-300 p-1 text-xs placeholder-gray-400"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {newKeyError && (
            <div className="text-xs text-red-500">{newKeyError}</div>
          )}
          <Button
            onClick={handleUpdateRequestMetadata}
            isLoading={updateRequestMetadataIsLoading}
            className="mt-4 w-full"
          >
            {currentMetadata.length === 0 ? "Add Metadata" : "Update Metadata"}
          </Button>
        </div>
      </div>
    </TooltipProvider>
  );
};
