import { Function_, FunctionsType, Message } from "@/types";

// FIXME: Infer from `modelConfigs`
type ModelProvider = string;
type ModelName = string;
export type ModelSelection = [ModelProvider, ModelName];

export type PromptTemplateParams = {
  functions?: Function_[];
  function_call?: "auto" | "none" | { name: string };
} & { [keyword: string]: number };

export type PlaygroundConfig = {
  functionsType: FunctionsType;
  messages: Message[];
  model: ModelSelection;
  params: PromptTemplateParams;
  promptTemplateId?: string;
  promptTemplateVersionNumber?: string;
  provider_base_url_name: string | null;
  requestId?: number;
  template: string;
  templateFormat: TemplateFormat;
  type: PromptTemplateType;
};

// Prompt template types
export type PromptTemplateType = "completion" | "chat";

// Template formats
export type TemplateFormat = (typeof TEMPLATE_FORMATS)[number];
export const TEMPLATE_FORMATS = ["f-string", "jinja2"];
