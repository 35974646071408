import { ButtonCopy, ButtonMaximize } from "@/components";
import { ChooseSnippetTemplateModal } from "@/components/PromptTemplate/ChooseSnippetTemplateModal";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { Textarea } from "@/components/ui/textarea";
import { Message, Template, TemplateFormat } from "@/types";
import { PromptVersionSnippet } from "@/types/apiGetters";
import { HighlightSnippets } from "@/utils/HighlightSnippets";
import {
  copyTextToClipboard,
  getStringContent,
  getSystemMessage,
} from "@/utils/utils";
import { PlusIcon } from "@heroicons/react/solid";
import { useCallback, useState } from "react";
import MessagePromptTemplate from "./MessagePromptTemplate";

type ChatTemplateProps = {
  template: string;
  template_id?: string;
  setTemplate?: (template: string) => void;
  messageTemplates: Message[];
  setMessageTemplates?: (messageTemplates: Message[]) => void;
  templateFormat: TemplateFormat;
  invalidSnippets?: string[];
  invalidPromptTemplate?: boolean;
  invalidPromptIndex?: number | null;
  sourcedSnippets?: Array<PromptVersionSnippet>;
  renderedTemplate?: Template;
  isXrayMode?: boolean;
};

const ChatTemplate = (props: ChatTemplateProps) => {
  const edit = !!props.setMessageTemplates && !!props.setTemplate;
  const setMessageTemplates = props.setMessageTemplates;

  const setTemplate = props.setTemplate;
  const handleChange = useCallback(
    (prompt: Message, index: number) => {
      const messageTemplates = [...props.messageTemplates];
      messageTemplates[index] = prompt;
      if (setMessageTemplates) setMessageTemplates(messageTemplates);
    },
    [props.messageTemplates, setMessageTemplates],
  );

  const createNewMessage = useCallback(() => {
    const length = props.messageTemplates.length;
    const lastMessage = length > 0 ? props.messageTemplates[length - 1] : null;
    const isLastRoleUser = lastMessage?.role === "user";
    const messageTemplates = [...props.messageTemplates];
    messageTemplates.push({
      role: isLastRoleUser ? "assistant" : "user",
      content: [{ type: "text", text: "" }],
      input_variables: [],
      template_format: props.templateFormat,
    });
    if (setMessageTemplates) setMessageTemplates(messageTemplates);
  }, [props.messageTemplates, setMessageTemplates, props.templateFormat]);

  const handleRemove = useCallback(
    (index: number) => {
      const messageTemplates = [...props.messageTemplates];
      messageTemplates.splice(index, 1);
      if (setMessageTemplates) setMessageTemplates(messageTemplates);
    },
    [props.messageTemplates, setMessageTemplates],
  );

  const handleKeyUp = (event: React.KeyboardEvent) => {
    if (event.key === "@") {
      const newCursorPosition = (event.target as HTMLInputElement)
        .selectionStart;
      setSystemMsgCursorPosition(newCursorPosition || 0);
      setOpenSystemMsgLinkEditor(true);
    }
  };

  const [openSystemMsgLinkEditor, setOpenSystemMsgLinkEditor] = useState(false);
  const [systemMsgCursorPosition, setSystemMsgCursorPosition] = useState(0);
  const isXrayMode = props.isXrayMode ?? false;
  const renderedTemplate = props.renderedTemplate;

  const renderedMessageTemplates =
    renderedTemplate?.type === "chat"
      ? renderedTemplate?.messages?.slice(1)
      : [];

  const renderedSystemMessage =
    renderedTemplate?.type === "chat"
      ? getSystemMessage(renderedTemplate)
      : null;
  const renderedSystemTextContent = renderedSystemMessage
    ? getStringContent(renderedSystemMessage)
    : "";

  return (
    <div className="grid h-full grid-cols-1 overflow-hidden sm:text-sm md:grid-cols-2">
      <div className="h-full p-2">
        <div
          className={`flex h-full flex-col gap-2 border p-2 ${
            props.invalidPromptTemplate && props.invalidPromptIndex === 0
              ? "border-red-400"
              : ""
          }`}
        >
          <div className="flex items-center justify-between">
            <div className="font-bold">SYSTEM</div>
            <Dialog>
              <DialogTrigger asChild>
                <ButtonMaximize />
              </DialogTrigger>
              <DialogContent className="h-full max-w-full">
                <div className="flex h-full flex-col gap-2">
                  <div className="font-bold">SYSTEM</div>
                  {edit ? (
                    <Textarea
                      className="h-full rounded-none disabled:cursor-auto disabled:opacity-100"
                      placeholder="You are a helpful assistant"
                      value={props.template}
                      onKeyUp={handleKeyUp}
                      onChange={(e) => {
                        if (setTemplate) setTemplate(e.target.value);
                      }}
                    />
                  ) : (
                    <div className="flex h-full max-h-[90vh] min-h-[60px] w-full overflow-auto rounded-none border border-input bg-transparent px-3 py-2 text-sm shadow-sm placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-auto disabled:opacity-100">
                      <HighlightSnippets
                        template={props.template}
                        mode="view"
                        invalidSnippets={props.invalidSnippets}
                        sourcedSnippets={props.sourcedSnippets}
                        isXrayMode={isXrayMode}
                        renderedTemplate={renderedSystemTextContent}
                      />
                    </div>
                  )}
                </div>
              </DialogContent>
            </Dialog>
          </div>
          {edit ? (
            <div className="grid h-full grid-rows-[auto]">
              <textarea
                className="h-full resize-none border-none p-0 text-sm focus:ring-0"
                placeholder={edit ? "You are a helpful assistant" : ""}
                value={props.template}
                onKeyUp={handleKeyUp}
                onChange={(e) => {
                  if (setTemplate) setTemplate(e.target.value);
                }}
                disabled={!edit}
              />
            </div>
          ) : (
            <div className="grid h-full max-h-[314px] min-h-[20vh] flex-shrink-0 grid-rows-[auto] overflow-auto whitespace-pre-wrap text-sm">
              <div className="max-h-full overflow-y-auto">
                <HighlightSnippets
                  template={props.template}
                  mode="view"
                  invalidSnippets={props.invalidSnippets}
                  sourcedSnippets={props.sourcedSnippets}
                  isXrayMode={isXrayMode}
                  renderedTemplate={renderedSystemTextContent}
                />
              </div>
            </div>
          )}
          <div className="mt-auto flex justify-end">
            <ButtonCopy
              onClick={async () => await copyTextToClipboard(props.template)}
            />
          </div>
          <ChooseSnippetTemplateModal
            currentPromptText={props.template}
            setCurrentPromptText={(value: string) => {
              if (setTemplate) setTemplate(value);
            }}
            open={openSystemMsgLinkEditor}
            setOpen={setOpenSystemMsgLinkEditor}
            cursorPosition={systemMsgCursorPosition}
          />
        </div>
      </div>
      <div className="flex h-full flex-col overflow-auto p-2">
        <div className="flex h-full flex-col overflow-auto">
          {props.messageTemplates.map((messageTemplate, i) => (
            <MessagePromptTemplate
              key={i}
              messageTemplate={messageTemplate}
              onChange={(prompt) => handleChange(prompt, i)}
              onRemove={() => handleRemove(i)}
              edit={edit}
              templateFormat={props.templateFormat}
              invalidSnippets={props.invalidSnippets}
              highlightInvalid={
                props.invalidPromptIndex === i + 1 &&
                props.invalidPromptTemplate
              }
              sourcedSnippets={props.sourcedSnippets}
              renderedMessageTemplate={renderedMessageTemplates[i]}
              isXrayMode={isXrayMode}
            />
          ))}
          {edit && (
            <div className="mt-4">
              <Button onClick={createNewMessage} variant="ghost">
                <PlusIcon className="mr-2 h-4 w-4" /> New Message
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatTemplate;
