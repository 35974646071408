import { FC } from "react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useAuth } from "@/context/auth-context";
import { ToastType } from "@/enums";
import { useDeleteProviderBaseURL } from "@/queries";
import { displayToast } from "@/utils/toast";

export type DeleteProviderBaseURLModalProps = {
  open: boolean;
  providerBaseURLId: number;
  setOpen: (open: boolean) => void;
};

const DeleteProviderBaseURLModal: FC<DeleteProviderBaseURLModalProps> = ({
  providerBaseURLId,
  open,
  setOpen,
}) => {
  const authContext = useAuth();
  const deleteProviderBaseURL = useDeleteProviderBaseURL(
    authContext!.userToken!,
  );

  const handleClick = async () => {
    try {
      await deleteProviderBaseURL.mutateAsync(providerBaseURLId);
      displayToast("Provider Base URL deleted", ToastType.success);
      setOpen(false);
    } catch (error: unknown) {
      console.error("Error deleting Provider Base URL:", error);
      displayToast("Error deleting Provider Base URL", ToastType.error);
    }
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Are you sure you want to delete this URL?</DialogTitle>
        </DialogHeader>
        <div className="text-gray-700">This action cannot be undone.</div>
        <DialogFooter className="mt-5">
          <Button
            className="bg-transparent text-sm"
            onClick={() => setOpen(false)}
            type="button"
            variant="outline"
          >
            Cancel
          </Button>
          <Button onClick={handleClick} variant="destructive">
            Delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteProviderBaseURLModal;
