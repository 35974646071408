import { FC, useState } from "react";

import UserChannelProvider from "@/components/UserChannelProvider";
import ThreadsOverlay from "./ThreadsOverlay";
import ThreadsWebSocketListener from "./ThreadsWebSocketListener";

interface ThreadsOverlayManagerProps {
  promptRegistryId: number;
  promptVersionNumber: number;
}

const ThreadsOverlayManager: FC<ThreadsOverlayManagerProps> = ({
  promptRegistryId,
  promptVersionNumber,
}) => {
  const [latestCommentDate, setLatestCommentDate] = useState<string | null>(
    null,
  );

  return (
    <>
      <ThreadsOverlay
        latestCommentDate={latestCommentDate}
        promptRegistryId={promptRegistryId}
        promptVersionNumber={promptVersionNumber}
      />
      <UserChannelProvider>
        <ThreadsWebSocketListener
          latestCommentDate={latestCommentDate}
          promptRegistryId={promptRegistryId}
          setLatestCommentDate={setLatestCommentDate}
        />
      </UserChannelProvider>
    </>
  );
};

export default ThreadsOverlayManager;
