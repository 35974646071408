import React from "react";
import { Switch } from "..";
import { PromptTemplateType } from "./types";

export type TemplateTypeSwitchProps = {
  onChange: (v: PromptTemplateType) => void;
  value: PromptTemplateType;
};

export function TemplateTypeSwitch({
  onChange,
  value,
}: TemplateTypeSwitchProps) {
  const handleChange = React.useCallback(
    (on: boolean) => {
      onChange(on ? "chat" : "completion");
    },
    [onChange],
  );

  return (
    <div className="flex items-center justify-center gap-4 pb-2 pt-6">
      <div>Chat</div>
      <Switch checked={value === "completion"} onChange={handleChange} />
      <div>Completion</div>{" "}
    </div>
  );
}
