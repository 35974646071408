import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { HomeIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";

export default function NotFoundMessage({
  backLink,
  backLinkMessage,
  title,
  subtitle,
}: {
  backLink: string;
  backLinkMessage: string;
  title: string;
  subtitle: string;
}) {
  const navigate = useNavigate();

  return (
    <div className="flex h-full flex-col items-center justify-center">
      <ExclamationCircleIcon className="h-10 w-10 text-red-500" />
      <h1 className="mt-4 text-2xl font-semibold text-gray-800">{title}</h1>
      <p className="mt-2 text-sm text-gray-500">{subtitle}</p>
      <div className="mt-4 flex items-center">
        <button
          className="rounded bg-gray-200 px-4 py-2 font-semibold text-gray-700 hover:bg-gray-300"
          onClick={() => navigate(backLink)}
        >
          <HomeIcon className="mb-1 inline-block h-4 w-4 align-middle" />
          <span className="pl-2">{backLinkMessage}</span>
        </button>
      </div>
    </div>
  );
}
