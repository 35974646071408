import DatasetGroupSelectorModal from "@/components/DatasetGroupSelectorModal";
import { Button, DivButton } from "@/components/ui/button";
import { useAuth } from "@/context/auth-context";
import { useUser } from "@/context/user-context";
import {
  downloadReport,
  usePromptRegistry,
  useRenameReport,
  useReplaceDataset,
} from "@/queries";
import { CogIcon, DownloadIcon, TrashIcon } from "@heroicons/react/outline";
import { LockClosedIcon } from "@heroicons/react/solid";
import { WrenchIcon } from "lucide-react";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { InlineEdit } from "..";
import { Breadcrumbs } from "../Breadcrumbs";
import LoadingSpinner from "../LoadingSpinner";
import ConfigureScoreCardModal from "./ConfigureScoreCardModal";
import { DeleteReport } from "./DeleteReport";

export const ReportHeader = ({
  createdAt,
  datasetInfo,
  isBlueprint,
  isDeleted,
  lastUpdated,
  parentReportId,
  parentReportName,
  promptId,
  promptVersionNumber,
  reportId,
  reportName,
}: {
  children?: React.ReactNode;
  createdAt: string;
  datasetInfo: any;
  isBlueprint: boolean;
  isDeleted: boolean;
  lastUpdated: string;
  parentReportId?: number;
  parentReportName?: string;
  promptId: number | null;
  promptVersionNumber: number | null;
  reportId: number;
  reportName: string;
}) => {
  const [editReportName, setEditReportName] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [isDatasetGroupSelectorModalOpen, setDatasetGroupSelectorModalOpen] =
    useState(false);
  const [openDeleteForm, setOpenDeleteForm] = useState(false);
  const [renameReportLoading, setRenameReportLoading] = useState(false);
  const auth = useAuth();
  const userToken = auth!.userToken!;
  const user = useUser();
  const activeWorkspaceId = user?.activeWorkspaceId;

  const replaceDataset = useReplaceDataset(
    userToken,
    activeWorkspaceId || -1,
    reportId,
  );

  const handleDatasetGroupIdClick = (datasetGroupId: number) => {
    replaceDataset.mutate({ dataset_group_id: datasetGroupId });
  };

  const handleDownload = async () => {
    setExportLoading(true);
    try {
      await downloadReport(userToken, reportName, reportId);
      setExportLoading(false);
      alert("Report download initiated. Please wait...");
    } catch (error) {
      setExportLoading(false);
      console.error("Failed to initiate report download:", error);
      alert("Failed to initiate report download. Please try again.");
    }
  };

  const promptRegistryQuery = usePromptRegistry(
    userToken,
    promptId ? promptId : undefined,
  );
  const promptName = promptRegistryQuery.data?.prompt_name;

  // Rename report
  const { mutate: renameReport, data: renameReportResponse } = useRenameReport(
    userToken,
    reportId,
  );

  const datasetName = datasetInfo?.name || "";
  const datasetTotal = datasetInfo?.total || 0;

  const handleRenameReport = (newName: string) => {
    setRenameReportLoading(true);
    renameReport(newName);
  };

  useEffect(() => {
    if (renameReportResponse && renameReportResponse.success) {
      setEditReportName(false);
      setRenameReportLoading(false);
    }
  }, [renameReportResponse]);

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setEditReportName(false);
      }
    };

    if (editReportName) {
      window.addEventListener("keydown", handleEscape);
    }

    return () => {
      window.removeEventListener("keydown", handleEscape);
    };
  }, [editReportName]);

  return (
    <>
      <div className="bg-white p-1">
        <Breadcrumbs
          items={[
            "Evaluate",
            isBlueprint ? "Pipelines" : "Batch Runs",
            reportName,
          ]}
          navigateUrl={`/workspace/${activeWorkspaceId}/evaluate`}
        />

        <div className="flex pt-4">
          <div className="flex-1 space-y-2 pb-3">
            <div className="flex items-center">
              {!isBlueprint && (
                <LockClosedIcon className="mr-1 h-6 cursor-default text-gray-400" />
              )}
              <InlineEdit.Root
                initialValue={reportName}
                onEdit={handleRenameReport}
              >
                <InlineEdit.Trigger>
                  <h1 className="text-3xl font-semibold text-gray-900">
                    {reportName}
                  </h1>
                </InlineEdit.Trigger>
                <InlineEdit.Input className="text-base" />
                <InlineEdit.Rename>Rename</InlineEdit.Rename>
                <InlineEdit.Cancel>Cancel</InlineEdit.Cancel>
              </InlineEdit.Root>
              {renameReportLoading && <LoadingSpinner size={5} />}
            </div>
            {isBlueprint ? (
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  {datasetName && datasetTotal !== 0 && (
                    <>
                      <span className="inline-flex items-center text-sm text-gray-600">
                        Dataset:{" "}
                        <span className="w-full text-blue-500 hover:text-blue-600">
                          <Button
                            className="inline-flex items-center p-1"
                            onClick={() =>
                              setDatasetGroupSelectorModalOpen(true)
                            }
                            size="sm"
                            variant="link"
                          >
                            <WrenchIcon
                              className="mr-1 h-4 w-auto text-gray-500"
                              aria-hidden="true"
                            />
                            {`${datasetName} (${datasetTotal})`}
                          </Button>
                        </span>
                      </span>
                      <div className="h-1 w-1 rounded-full bg-gray-300"></div>
                    </>
                  )}
                  <h4
                    className="text-sm text-gray-600"
                    title={lastUpdated ? lastUpdated : createdAt}
                  >
                    {lastUpdated ? "Last Updated: " : "Created: "}
                    {lastUpdated
                      ? moment(lastUpdated).fromNow()
                      : moment(createdAt).format("MMM Do, YYYY")}
                  </h4>
                  {isBlueprint && (
                    <>
                      <div className="h-1 w-1 rounded-full bg-gray-300"></div>
                      {isDeleted ? (
                        <span className="text-sm text-gray-400">Archived</span>
                      ) : (
                        <div
                          onClick={() => setOpenDeleteForm(true)}
                          className="ml-2 inline-flex cursor-pointer items-center text-xs text-red-600 hover:text-red-500"
                        >
                          <TrashIcon
                            className="h-4 w-auto pr-1"
                            aria-hidden="true"
                          />
                          Archive
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            ) : (
              <>
                <div className="flex flex-col items-start space-x-0 2xl:flex-row 2xl:space-x-2">
                  <div className="flex items-center space-x-2">
                    {datasetName && datasetTotal !== 0 && (
                      <>
                        <span className="text-sm text-gray-600">
                          Dataset:{" "}
                          <span className="">
                            {datasetName} ({datasetTotal})
                          </span>
                        </span>
                        <div className="h-1 w-1 rounded-full bg-gray-300"></div>
                      </>
                    )}
                    <h4 className="text-sm text-gray-600" title={createdAt}>
                      {"Created: "}
                      {moment(createdAt).format("MMM Do, YYYY")}
                    </h4>
                  </div>
                  <div className="flex items-center 2xl:space-x-2">
                    {parentReportId && parentReportName && (
                      <>
                        <div className="hidden h-1 w-1 rounded-full bg-gray-300 2xl:block" />
                        <h4 className="text-sm text-gray-600">
                          Pipeline:{" "}
                          <Link
                            to={`/workspace/${user.activeWorkspaceId}/evaluate/blueprints/${parentReportId}`}
                          >
                            <span className="text-blue-500 hover:text-blue-600">
                              {parentReportName}
                            </span>
                          </Link>
                        </h4>
                      </>
                    )}
                    {promptId && promptVersionNumber && promptName && (
                      <>
                        <div className="ml-2 mr-2 h-1 w-1 rounded-full bg-gray-300 2xl:m-0"></div>
                        <h4 className="text-sm text-gray-600">
                          Prompt Template:{" "}
                          <Link
                            to={`/workspace/${user.activeWorkspaceId}/prompt/${promptId}/version/${promptVersionNumber}`}
                          >
                            <span className="text-blue-500 hover:text-blue-600">
                              {promptName}
                            </span>
                          </Link>
                        </h4>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
          {!isBlueprint && (
            <div className="flex items-center gap-x-2">
              <ConfigureScoreCardModal>
                <DivButton
                  variant="outline"
                  size="sm"
                  className={`inline-flex items-center`}
                  disabled={isDeleted}
                >
                  <CogIcon className="h-4 w-auto pr-1" aria-hidden="true" />
                  Score
                </DivButton>
              </ConfigureScoreCardModal>
              <Button
                onClick={() => setOpenDeleteForm(true)}
                variant="destructiveOutline"
                size="sm"
                className={`inline-flex items-center`}
                disabled={isDeleted}
              >
                <TrashIcon className="h-4 w-auto pr-1" aria-hidden="true" />
                Archive
              </Button>
              <Button
                variant="default"
                size="sm"
                onClick={handleDownload}
                isLoading={exportLoading}
              >
                <DownloadIcon className="mr-2 inline h-4 w-auto" />
                Export to CSV
              </Button>
            </div>
          )}
        </div>
        <DeleteReport
          open={openDeleteForm}
          setOpen={setOpenDeleteForm}
          reportName={reportName}
          reportId={reportId}
        />
      </div>
      {isDatasetGroupSelectorModalOpen && (
        <DatasetGroupSelectorModal
          handleDatasetGroupIdClick={handleDatasetGroupIdClick}
          setOpen={setDatasetGroupSelectorModalOpen}
        />
      )}
    </>
  );
};
