import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { SubmitHandler, useForm } from "react-hook-form";

interface Props {
  name: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  onSubmit: (name: string) => void;
}

export const RenamePrompt = (props: Props) => {
  return (
    <Dialog open={props.open} onOpenChange={props.setOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Rename Prompt</DialogTitle>
        </DialogHeader>
        <RenamePromptForm {...props} />
      </DialogContent>
    </Dialog>
  );
};

function RenamePromptForm(props: Props) {
  const form = useForm({
    defaultValues: {
      name: props.name,
    },
  });
  const submitHandler: SubmitHandler<{ name: string }> = (values) => {
    props.onSubmit(values.name);
  };
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(submitHandler)} className="space-y-8">
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Name</FormLabel>
              <FormControl>
                <Input placeholder="Prompt Name" {...field} />
              </FormControl>
            </FormItem>
          )}
        />
        <Button type="submit">Submit</Button>
      </form>
    </Form>
  );
}
